import React from 'react';
import {Header, Loader} from "semantic-ui-react";


export default ({company, loading}) => {

    if (loading) {
        return <Loader active inline='centered'/>
    }

    return (
        <Header as='h3'>
            {company.company_name}
            <Header.Subheader>
                {company.exchange_ticker}
            </Header.Subheader>
        </Header>
    )
}