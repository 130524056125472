import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Statistic, Header} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import {fetchCompany} from "../../../actions/companyActions";
import {columns} from './dividendCols'
import RdgTable from "../../../components/rdg/RdgTable";
import {fetchSecurityListingDividendRecordsForCompany} from "../../../features/securityListingDividendsFeatureSlice";


export default () => {

    const dispatch = useDispatch();

    const {securityListingDividendsForCompany, loadingSecurityListingDividendsForCompany} = useSelector(state => state.securityListingDividends, shallowEqual);
    const {company, loadingCompany} = useSelector(state => state.companyStore, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: [],
        requestData
    });

    const sidebar = (
        <div>
            <Header>Dividends</Header>
            <Header>{loadingCompany ? null: company.company_name}</Header>

        </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             records={securityListingDividendsForCompany}
                                             loading={loadingSecurityListingDividendsForCompany}
        />} sidebarView={sidebar}/>
    )

};

const requestData = ({dispatch, searchProps, matchParams}) => {

    const {id} = matchParams;
    fetchCompany({dispatch, id});

    dispatch(fetchSecurityListingDividendRecordsForCompany({companyId: id}));
};