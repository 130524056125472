import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import moment from "moment";
import {formatSharePrice, formatFullDate} from "../../../common/format";
import {getMonthCategory, getXValue, series1ChartSpecificationByName} from "./common";
import {getTopContributorsSeries} from "./topContributors";
import {Loader} from "semantic-ui-react";

const ReactHighcharts = require('react-highcharts');


class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();

        if (this.refs.chart) {
            let chart = this.refs.chart.getChart();
            chart.setSize(undefined, chartHeight);
        }


    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        if (this.refs.chart) {
            let chart = this.refs.chart.getChart();
            chart.setSize(undefined, chartHeight);
        }
    }

    render() {

        const portfolioPeriodReportsWithSecurityInvestmentPeriodReports = this.props.portfolioPeriodReportsWithSecurityInvestmentPeriodReports;
        const loading = this.props.loading;
        const longShortType = this.props.longShortType;

        if (this.props.loading) {
            return <Loader active inline='centered'/>
        }

        const topPositionCount = this.props.topPositionCount;

        const series1ChartSpec = this.props.series1ChartSpec;

        const topContributorsSeries = getTopContributorsSeries({
            periodReports: portfolioPeriodReportsWithSecurityInvestmentPeriodReports,
            topPositionCount,
            longShortType: longShortType,
            getYValue: series1ChartSpec.getPositionReportY,
            formatY: series1ChartSpec.formatY
        });

        const series = [
            {
                name: series1ChartSpec.name,
                data: portfolioPeriodReportsWithSecurityInvestmentPeriodReports.map(r => {
                    return {
                        x: getXValue(r.end_date),
                        y: series1ChartSpec.getPeriodReportY(r)
                    }
                }),
                groupPadding: 0.1,
                borderWidth: 2,
                borderColor: 'black',
                color: 'white'

            },
            ...topContributorsSeries
        ];

        const config = {
            chart: {
                type: 'column',
                zoomType: 'xy'
            },
            title: {
                text: series1ChartSpec.name
            },
            xAxis: {
                title: {
                    text: 'Month End'
                },
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return moment.utc(this.value).format('MMM-YY');
                    }
                }
            },
            yAxis: {
                title: {
                    text: series1ChartSpec.getYAxisLabel()
                },
                labels: {
                    formatter: function () {
                        return series1ChartSpec.formatY(this.value);
                    }

                }
            },
            tooltip: {
                useHTML: true,
                shared: false,
                headerFormat: '',
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td> Date </td><td>' + moment.utc(this.x).format('MMM-YY') + '</td></tr>' +
                        '<tr><td>' + series1ChartSpec.getYAxisLabel() + '</td><td>' + series1ChartSpec.formatY(this.y) + '</td></tr>' +
                        '</table>'
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                    grouping: false,
                },
                column: {
                    maxPointWidth: 100,
                    pointPadding: 0
                }
            },
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
            },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}



export default ChartComponent