import React from 'react'
import {
    Icon,
    Button,
    Table,
    Label,
    Header,
    Message,
    List,
    Form,
    Popup,
    Grid,
    Container,
    Divider
} from 'semantic-ui-react'
import {
    validMoment,
    formatOneDecimal,
    formatNoDecimal,
    formatTwoDecimalNoNull,
    formatPercentOneDecimalNoNull,
    formatOneDecimalNoNull,
    formatPercentTwoDecimalNoNull,
    formatTwoDecimalCurrencyNoZero,
    formatTwoDecimalCurrency
} from '../../common/format'
import {formatDateTimeUtcToLocal, formatFullDateTimeUtcToLocal, formatSimpleDate} from "../../common/formatDate";
import CompanySingleSelect from "../../components/CompanySingleSelect";
import QueueTables from "./QueueTables";
import moment from 'moment'
import {parseBoolean, parseSearchProps} from "../../common/query/parse";
import {modifyCompany} from "../../actions/companyActions";
import {createAddValueToQuery} from "../../common/query/add";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {
    addNewCompanyFromCiqId,
    startDataImportProcess,
} from "../../features/adminFeatureSlice";
import {fetchQueueData} from "../../features/adminFeatureSlice";
import CompanyValidationTable from "./CompanyValidationTable";


const fieldSpecs = {
    new_company_ciq_id: {
        parse: (v) => v == null ? 'IQ' : v
    },
}


export default function AdminMetadataTable({
                                               valuationMetadata,
                                               loadingValuationMetadata,
                                               portfolioMetadata,
                                               onStartUpdatePortfolioFromTradeFiles,
                                               onStartUpdateGoldmanFTPAndPortfolio,
                                               processing,
                                               companyValidationSummaries
                                           }) {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const {queueData, loadingQueueData} = useSelector(state => state.admin, shallowEqual);

    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const buttonsDisabled = processing;

    const gsCashMatchesIcon = getCheckIcon(portfolioMetadata.gs_cash_by_currency_reconciles_with_point_sur);

    const gsTradeFileUploadIcon = getCheckIcon(portfolioMetadata.latest_gs_trade_upload_is_successful);

    let latestGsPortfolioFtpDate = '';
    if (portfolioMetadata.latest_gs_portfolio_ftp_date != null) {
        latestGsPortfolioFtpDate = moment(portfolioMetadata.latest_gs_portfolio_ftp_date).format('M/D/YYYY (dddd)');
    }

    let latestFullDataImportTradeDate = '';
    if (valuationMetadata.last_full_data_import_close_prices_date != null) {
        latestFullDataImportTradeDate = moment(valuationMetadata.last_full_data_import_close_prices_date).format('M/D/YYYY (dddd)');
    }

    return (<div>
        <Grid columns={2}>
            <Grid.Row>


                <Grid.Column width={10}>

                    <Grid.Row style={{paddingBottom: 5}}>
                        <Grid.Column>
                            <Button.Group>
                                <Button icon labelPosition='left'
                                        onClick={() => dispatch(startDataImportProcess()).then(() => {
                                            return fetchQueueData({dispatch});
                                        })}
                                        color={processing ? 'grey' : 'blue'}
                                        loading={buttonsDisabled}>
                                    <Icon name='refresh'/>
                                    {processing ? 'Processing...' : 'Run Data Import'}
                                </Button>
                                <Button icon>
                                    <Icon name='clock'/>
                                </Button>
                            </Button.Group>

                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row style={{paddingBottom: 5}}>
                        <Grid.Column>
                            <Button icon labelPosition='left' onClick={onStartUpdateGoldmanFTPAndPortfolio}
                                    color={processing ? 'grey' : 'brown'}
                                    loading={buttonsDisabled}>
                                <Icon name='globe'/>
                                {processing ? 'Processing...' : 'Get GS FTP & Update Portfolio'}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingBottom: 5}}>
                        <Grid.Column>
                            <Button icon labelPosition='left' onClick={onStartUpdatePortfolioFromTradeFiles}
                                    color={processing ? 'grey' : 'black'}
                                    loading={buttonsDisabled}>
                                <Icon name='globe'/>
                                {processing ? 'Processing...' : 'Import Portfolio'}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <br/>
                            <CompanySingleSelect/>
                            <div>Select to run data import for single company</div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <br/>
                            <a href='https://accel-kkr.app.box.com/folder/113281995275'>Profile Output</a>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <br/>
                            <br/>
                            <QueueTables queueData={queueData}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Divider horizontal>CapIQ Service</Divider>
                        <Grid.Column>
                            <br/>
                            <br/>
                            <QueueTables queueData={queueData.ciq}/>
                        </Grid.Column>
                    </Grid.Row>


                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Divider horizontal>New Company</Divider>
                                <Form>
                                    <Form.Group>
                                        <Form.Input
                                            placeholder='CIQ ID (ex: IQ141189)'
                                            value={searchProps.new_company_ciq_id || ''}
                                            onChange={createAddValueToQuery({
                                                history,
                                                searchProps,
                                                fieldName: 'new_company_ciq_id'
                                            })}
                                        />
                                        <Form.Button loading={processing} onClick={() => {
                                            dispatch(addNewCompanyFromCiqId({ciq_id: searchProps.new_company_ciq_id})).then(() => {
                                                return dispatch(fetchQueueData());
                                            });
                                        }
                                        }>Create</Form.Button>
                                    </Form.Group>


                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>


                </Grid.Column>
                <Grid.Column width={6}>
                    <Grid.Row>
                        <Table compact='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={2}>
                                        Data Import
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Last Imported Trade Date
                                    </Table.Cell>
                                    <Table.Cell>
                                        {latestFullDataImportTradeDate}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Table compact='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={3}>
                                        GS PB Reconciliations
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        GS Custody Shares Match
                                    </Table.Cell>
                                    <Table.Cell>
                                        {getCheckIcon(portfolioMetadata.gs_custody_shares_match)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatSimpleDate(portfolioMetadata.gs_custody_shares_standard_date)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        GS Custody Cash Matches
                                    </Table.Cell>
                                    <Table.Cell>
                                        {gsCashMatchesIcon}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatSimpleDate(portfolioMetadata.gs_cash_by_currency_reconciles_as_of_standard_date)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        GS NAV $m
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatOneDecimalNoNull(portfolioMetadata.gs_net_asset_value ? portfolioMetadata.gs_net_asset_value / 1000000 : null)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatSimpleDate(portfolioMetadata.gs_net_asset_value_standard_date)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        PS NAV % vs GS NAV
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatPercentTwoDecimalNoNull(portfolioMetadata.ps_net_asset_value_vs_gs_percent)}
                                    </Table.Cell>
                                    <Table.Cell/>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        GS Min NAV (margin) $m
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatOneDecimalNoNull(portfolioMetadata.gs_required_net_asset_value ? portfolioMetadata.gs_required_net_asset_value / 1000000 : null)}
                                    </Table.Cell>
                                    <Table.Cell/>
                                </Table.Row>


                            </Table.Body>

                        </Table>

                        <Table compact='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={2}>
                                        GS FTP Download
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Latest Date
                                    </Table.Cell>
                                    <Table.Cell>
                                        {latestGsPortfolioFtpDate}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                        <Table compact='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={2}>
                                        GS Trade File Upload
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Upload Date
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatFullDateTimeUtcToLocal(portfolioMetadata.latest_gs_trade_upload_datetime)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Trade Date
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatSimpleDate(portfolioMetadata.latest_gs_trade_upload_standard_date)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Was Successful Upload
                                    </Table.Cell>
                                    <Table.Cell>
                                        {gsTradeFileUploadIcon}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                        <CompanyValidationTable companyValidations={companyValidationSummaries}/>

                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>


        </Grid>


        <Grid>


        </Grid>

        <br/>


        <br/>


        <br/>


    </div>)

}


const isStaleRecord = ({record, profilingMetadatas}) => {

    return profilingMetadatas.filter(pRecord => {
        return pRecord.start_time > record.start_time && pRecord.index < record.index
    }).length > 0;

}

const ButtonWithProfiling = ({
                                 func,
                                 iconName,
                                 buttonText,
                                 buttonColor,
                                 processing,
                                 buttonsDisabled
                             }) => {

    const dispatch = useDispatch();

    const runWithProfile = ({saveProfile}) => dispatch(func({saveProfile})).then(() => {
        return dispatch(fetchQueueData());
    });

    const onClickNoProfile = () => runWithProfile({saveProfile: false});
    const onClickWithProfile = () => runWithProfile({saveProfile: true});

    return (
        <Grid.Row style={{paddingBottom: 5}}>
            <Grid.Column>
                <Button.Group>
                    <Button icon
                            labelPosition='left'
                            onClick={onClickNoProfile}
                            color={processing ? 'grey' : buttonColor}
                            loading={buttonsDisabled}>
                        <Icon name={iconName}/>
                        {processing ? 'Processing...' : buttonText}
                    </Button>
                    <Button icon onClick={onClickWithProfile}>
                        <Icon name='clock'/>
                    </Button>
                </Button.Group>
            </Grid.Column>
        </Grid.Row>
    )
}


const getCheckIcon = (value) => {
    if (value === true) {
        return <Icon color={'green'} name="check square" size="large"/>;
    }
    if (value === false) {
        return <Icon color={'red'} name="exclamation" size="large"/>;
    }
    return <div/>;
}