import React, {useEffect} from 'react';
import {Button, Form, Grid, Header, Icon, Statistic, List} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../../common/request/convert';
import ViewWithSidebar from '../../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseBoolean, parseSearchProps} from "../../../../common/query/parse";
import {
    fetchDoradoValuationRecords,
    fetchPointSurDoradoValuationRecords
} from '../../../../features/dorado/doradoValuationRecords'
import ChartComponent from "./DoradoGrowthChart";
import DoradoSidebar from "../../CommonFilters";
import {createAddCheckedValueToQuery, createAddValueToQuery} from "../../../../common/query/add";
import {series1ChartSpecifications, fieldSpecs, forwardYearOptions, historicalExitYearOptions} from "./common";
import doradoAudit, {fetchDoradoRevenueGrowthAuditRecords} from "../../../../features/dorado/doradoAudit";



const chartOptions = series1ChartSpecifications.map(spec => {
    return {
        key: spec.name,
        value: spec.name,
        text: spec.name
    }
});



export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {doradoValuationRecords, loadingDoradoValuationRecords} = useSelector(state => {
        return {
            doradoValuationRecords: state.doradoValuation.records,
            loadingDoradoValuationRecords: state.doradoValuation.loading
        }
    }, shallowEqual);

    const {doradoRevenueGrowthAuditRecords, loadingDoradoRevenueGrowthAuditRecords} = useSelector(
        state => state.doradoAudit, shallowEqual);

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['filters', 'sort', 'forwardYears', 'historicalsExitYear'],
        requestData
    });

    const sidebar = (
        <div>

            <Header>Filters</Header>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={doradoValuationRecords.length} label='Records' size="tiny"/>
                </Grid.Row>
            </Grid>
            <DoradoSidebar/>
            <br/>
            <Form>
                <Form.Checkbox label="Show Growth Lines"
                               checked={searchProps.showStandardGrowthLines}
                               onChange={createAddCheckedValueToQuery({
                                   history,
                                   searchProps,
                                   fieldName: 'showStandardGrowthLines'
                               })}
                />
                <Form.Checkbox label="Show Historical Data Points"
                               checked={searchProps.showHistoricals}
                               onChange={createAddCheckedValueToQuery({
                                   history,
                                   searchProps,
                                   fieldName: 'showHistoricals'
                               })}
                />
                <Form.Select
                    fluid
                    inline
                    options={chartOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'series1ChartName'})}
                    value={searchProps.series1ChartName}
                    label="Y Axis"
                />
                <Form.Select
                    fluid
                    inline
                    options={forwardYearOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'forwardYears'})}
                    value={searchProps.forwardYears}
                    label="Forward Years"
                />
                <Form.Select
                    fluid
                    inline
                    options={historicalExitYearOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'historicalsExitYear'})}
                    value={searchProps.historicalsExitYear}
                    label="Historicals: Exit NTM Year"
                />
            </Form>
            <br/>
            <br/>
            <Grid>
                <Grid.Column>
                    <Header as={'h4'}>Definitions</Header>
                    <p>
                        <b>Entry LTM Growth:</b> Trailing 4-quarter organic recurring growth.
                        If ARR is provided we use ARR growth, otherwise recurring growth.
                    </p>
                    <p>
                        <b>Exit NTM Growth:</b> Revenue growth for NTM exit period.  Uses total revenue growth,
                        not ARR/recurring growth as max margins are based on total revenue as well.  Difference
                        is very minor except in a few situations.
                    </p>
                </Grid.Column>
            </Grid>
        </div>
    );

    return (
        <ViewWithSidebar mainView={
            <ChartComponent doradoValuationRecords={doradoValuationRecords}
                            doradoRevenueGrowthAuditRecords={doradoRevenueGrowthAuditRecords}
                            showHistoricals={searchProps.showHistoricals}
                            series1ChartName={searchProps.series1ChartName}
                            forwardYears={searchProps.forwardYears}
                            showStandardGrowthLines={searchProps.showStandardGrowthLines}
            />} sidebarView={sidebar}/>
    )

}


const requestData = ({dispatch, searchProps}) => {
    const sort = {};
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    dispatch(fetchPointSurDoradoValuationRecords({sort, filters}));

    const auditFilters = {
        years: searchProps.forwardYears
    }

    if (searchProps.historicalsExitYear != null) {
        auditFilters.exit_ntm_fiscal_year = searchProps.historicalsExitYear
    }

    dispatch(fetchDoradoRevenueGrowthAuditRecords({filters: auditFilters}));

};
