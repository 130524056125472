import React, {Component, useEffect} from 'react';
import {connect, shallowEqual, useSelector, useDispatch} from 'react-redux';
import {withRouter} from 'react-router'
import {Form, Grid, Statistic, Divider} from 'semantic-ui-react'
import {parseSearchProps} from "../../common/query/parse";
import {chartFieldSpecs, createAddCustomAxesSizeToValuationChartQuery} from './common';
import {fetchValuationMetadata} from '../../actions/metadataActions'
import RadioGroup from '../../components/RadioGroup'
import {chartSpecificationByName, periodSelectionOptions} from './chartCommon'
import {validMoment} from "../../common/format";
import {colorSpecByName} from "../../common/chart/chartColorers";
import {createAddValueToQuery, createSetFieldValueOnQuery, createAddCheckedValueToQuery} from '../../common/query/add'
import AxesSizer from './AxesSizer'
import FiltersComponent from '../nonDoradoValuationTable/TableFiltersComponent'
import {useHistory, useLocation} from "react-router-dom";


const labels = [
    'Name',
    'Ticker',
    'None'
];

const chartOptions = Object.keys(chartSpecificationByName).map((chartName) => {
    return {
        key: chartName,
        text: chartName,
        value: chartName,
    }
});

const periodOptions = periodSelectionOptions.map((period) => {
    return {
        key: period,
        text: period,
        value: period
    }
});

const labelOptions = labels.map(name => {
    return {
        label: name,
        value: name
    }
});

const colorOptions = Object.keys(colorSpecByName).map(key => {
    return {
        key: key,
        text: key,
        value: key
    }
});


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const metadata = useSelector(state => state.valuationMetadataStore.metadata);
    const records = useSelector(state => state.valuationTableStore.records);

    useEffect(() => {
        fetchValuationMetadata({dispatch});
    }, [dispatch]);

    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs: chartFieldSpecs});
    const period = searchProps.period;

    const chartSpec = chartSpecificationByName[searchProps.chartName];

    // valid record has both x and y values
    const validRecordCount = records.filter(record => {
        return chartSpec.getX({record, period}) != null && chartSpec.getY({record, period}) != null
    }).length;

    return (
        <div>
            <Form>
                <Form.Select
                    fluid
                    inline
                    options={chartOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'chartName'})}
                    value={searchProps.chartName}
                    label="Chart"
                />
                <Form.Select
                    fluid
                    inline
                    options={periodOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'period'})}
                    value={searchProps.period}
                    label="Period"
                />
                <Form.Select
                    fluid
                    inline
                    options={colorOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'color'})}
                    value={searchProps.color}
                    label="Color"
                />
                <Form.Checkbox label="Use Diluted Growth"
                               checked={searchProps.useDilutedGrowth}
                               onChange={createAddCheckedValueToQuery({
                                   history,
                                   searchProps,
                                   fieldName: 'useDilutedGrowth'
                               })}
                />

            </Form>
            <p className="italic">Prices as of {validMoment(metadata.share_price_date, 'M/D/YYYY')}</p>
            <Divider horizontal>Chart Options</Divider>
            <Form>
                <RadioGroup
                    title="Labels"
                    radioObjects={labelOptions}
                    selectedValue={searchProps.labels}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'labels'})}
                    horizontal={true}
                />
                <AxesSizer
                    selectedAxesSize={searchProps.axesSize}
                    onChangeAxesSize={createAddValueToQuery({history, searchProps, fieldName: 'axesSize'})}
                    selectedAxesSpec={searchProps.axesSpec}
                    onChangeAxesSpec={createAddCustomAxesSizeToValuationChartQuery({history, searchProps})}
                    onClearAxesSpec={createSetFieldValueOnQuery({
                        history,
                        searchProps,
                        fieldName: 'axesSpec',
                        value: {}
                    })}
                />
                <Form.Checkbox
                    label="Valuation Lines"
                    checked={searchProps.valuationLinesEnabled}
                    onChange={createAddCheckedValueToQuery({
                        history,
                        searchProps,
                        fieldName: 'valuationLinesEnabled'
                    })}
                />
                <Form.Checkbox
                    label="Regression Line"
                    checked={searchProps.regressionLineEnabled}
                    onChange={createAddCheckedValueToQuery({
                        history,
                        searchProps,
                        fieldName: 'regressionLineEnabled'
                    })}
                />
            </Form>
            <Divider horizontal>Filters</Divider>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={records.length} label='Companies' size="tiny"/>
                </Grid.Row>
                <Grid.Row>
                    <Statistic horizontal value={validRecordCount} label='Valid Chart Points' size="tiny"/>
                </Grid.Row>
            </Grid>
            <FiltersComponent/>

        </div>
    )


}