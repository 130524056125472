import React, {Component} from 'react'
import ReactDataGrid from 'react-data-grid';
import {
  StringFormatter,
  BoolToYesFormatter,
  CostCenterFormatter
} from '../../../common/reactDataGrid/formatters';
import {getWindowHeightLessMenuBar} from '../../../common/common'
import {
  getMostRecentSortColumnAndDirectionForRDG,
  getRdgRowsFromRecords,
  makeHandleReactDataGridSort
} from '../../../common/reactDataGrid/common'
import {parseSearchProps} from "../../../common/query/parse";
import {createAppendSortToQuery} from "../../../common/query/add";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {deleteRole} from '../../../actions/employees/roleActions';
import DeleteModalButton from '../../../components/DeleteModal'
import {Link} from 'react-router-dom';


class EditLinkFormatter extends React.Component {
  render() {

    // dont allow editing of standard costs
    if (this.props.value.company_id == null) {
      return <div/>
    }

    return <div className="align-center">
        <Link className="align-center" to={'/employee_analysis/edit_role/' + this.props.value.company_id + "/" + this.props.value.id}>Edit</Link>
    </div>

  }
}


class DeleteLinkFormatter extends React.Component {
  render() {

    const onClick = (e, d) => this.props.deleteRole({id: this.props.value}).then(this.props.refresh());

    return (
      <div className='align-center'>
        <DeleteModalButton
        buttonText='Delete'
        onDelete={onClick}
        headerContent="Delete Role"
        content="Are you sure you want to delete this role?"
        size="mini"
      >
      </DeleteModalButton>
      </div>
    )
  }
}


class RoleTableView extends Component {

  render() {
    const searchProps = parseSearchProps({searchString: this.props.location.search});
    const roles = this.props.roles;

    const onChangeSort = createAppendSortToQuery({history: this.props.history, searchProps: searchProps});
    const handleReactDataGridSort = makeHandleReactDataGridSort(onChangeSort);
    const {sortColumn, sortDirection} = getMostRecentSortColumnAndDirectionForRDG(searchProps);

    const columns = [
      {
        key: 'name',
        name: 'Role',
        width: 200,
        frozen: true,
        sortable: true,
        formatter: StringFormatter,
      },
      {
        key: 'minor_cost_center',
        name: 'Minor Cost Center',
        sortable: true,
        width: 150,
        formatter: StringFormatter
      },
      {
        key: 'major_cost_center',
        name: 'Major Cost Center',
        sortable: true,
        width: 120,
        formatter: CostCenterFormatter
      },
      {
        key: 'is_standard',
        name: 'Is Standard?',
        sortable: true,
        width: 100,
        formatter: BoolToYesFormatter
      },
      {
        key: 'edit',
        name: '',
        width: 100,
        formatter: EditLinkFormatter,
        _getValueFromRecord: (r) => {
          return {id: r.id, company_id: r.company_id}
        }
      },
      {
        key: 'delete',
        name: '',
        width: 100,
        formatter: <DeleteLinkFormatter deleteRole={this.props.deleteRole} refresh={this.props.refreshRecords}/>,
        _getValueFromRecord: (r) => {
          return r.id
        }
      }

    ];


    const rows = getRdgRowsFromRecords({records: roles, columns: columns});

    let className = 'financials-size';
    if (this.props.loadingRoles) {
      className += ' loading-grid'
    }

    return (

      <div className={className}>
        <ReactDataGrid
          columns={columns}
          onGridSort={handleReactDataGridSort}
          rowGetter={index => rows[index]}
          rowsCount={rows.length}
          rowHeight={22}
          headerRowHeight={50}
          minHeight={getWindowHeightLessMenuBar()}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />

      </div>

    )
  }

}


function mapStateToProps(state) {
  return {
    roles: state.roleStore.roles,
    loadingRoles: state.roleStore.loadingRoles,


  }
}


export default withRouter(connect(mapStateToProps,
  {deleteRole})(RoleTableView));



