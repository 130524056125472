import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Statistic, Button, Icon} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import TradesSidebar from '../TradesSidebar';
import {
    fetchPortfolios,
    fetchTradeAnalysisRecords
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfoliosSummaryTable from "../PortfoliosSummaryTable";
import {columns} from "./common";
import RdgTable from "../../../components/rdg/RdgTable";



export default () => {

    const {
        tradeAnalysisRecords,
        loadingTradeAnalysisRecords,
        portfolios,
        loadingPortfolios
    } = useSelector(state => state.portfolio, shallowEqual);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPortfolios({
            filters: {
                is_backtest: false,
            },
            sort: {
                name: 1
            }
        }));
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Statistic horizontal value={tradeAnalysisRecords.length}
                                   label='Trades Count' size="tiny"/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <TradesSidebar/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    );


    return (
        <ViewWithSidebar mainView={<RdgTable loading={loadingTradeAnalysisRecords}
                                             records={tradeAnalysisRecords}
                                             columns={columns} />}
            sidebarView={sidebar}/>
    )

};

const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: {
            ...searchProps.filters,
        },
        parseSpecByField: {}
    });

    console.log(filters)

    dispatch(fetchTradeAnalysisRecords({sort, filters}));

};
