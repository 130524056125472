import React, {useEffect} from 'react';
import {Button, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../../common/request/convert';
import ViewWithSidebar from '../../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import {
    fetchDoradoValuationRecords,
    fetchPointSurDoradoValuationRecords
} from '../../../../features/dorado/doradoValuationRecords'
import ChartComponent from "./DoradoExitMultipleChart";
import DoradoSidebar from "../../CommonFilters";
import {createAddCheckedValueToQuery, createAddValueToQuery} from "../../../../common/query/add";
import {fieldSpecs, xSpecs, xChartOptions, yChartOptions, xSpecByLabel, ySpecByLabel} from "./common";







export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {doradoValuationRecords, loadingDoradoValuationRecords} = useSelector(state => state.doradoValuation, shallowEqual);

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    console.log(doradoValuationRecords)



    const sidebar = (
        <div>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={doradoValuationRecords.length} label='Records' size="tiny"/>
                </Grid.Row>
            </Grid>
            <DoradoSidebar/>
            <br/>
            <Form>
                <Form.Select
                    fluid
                    inline
                    options={xChartOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'xAxisLabel'})}
                    value={searchProps.xAxisLabel}
                    label="X Axis"
                />
                <Form.Select
                    fluid
                    inline
                    options={yChartOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'yAxisLabel'})}
                    value={searchProps.yAxisLabel}
                    label="Y Axis"
                />
                {/*<Form.Checkbox label="Show Multiple Sensitivities"*/}
                {/*               checked={searchProps.showMultipleSensitivities}*/}
                {/*               onChange={createAddCheckedValueToQuery({*/}
                {/*                   history,*/}
                {/*                   searchProps,*/}
                {/*                   fieldName: 'showMultipleSensitivities'*/}
                {/*               })}*/}
                {/*/>*/}
            </Form>
        </div>
    );

    return (
        <ViewWithSidebar mainView={<ChartComponent doradoValuationRecords={doradoValuationRecords}
                                                   xSpec={xSpecByLabel[searchProps.xAxisLabel]}
                                                   ySpec={ySpecByLabel[searchProps.yAxisLabel]}

        />} sidebarView={sidebar}/>
    )

}


const requestData = ({dispatch, searchProps}) => {
    const sort = {};
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    dispatch(fetchDoradoValuationRecords({sort, filters}));

};