import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Container, Form, Grid, Header} from 'semantic-ui-react'
import {fetchAkkrCompany, fetchFinancialsForAkkrCompany} from '../../../actions/akkr/akkrActions';
import AkkrCurrencySelect from '../../../components/currency/AkkrCurrencySelect';
import AkkrFinancialsComponent from "./AkkrFinancials"
import {periodOptions} from "./common";
import PeriodOptionSelect from "./PeriodOptionSelect";
import {useReloadOnChange} from "../../../common/hooks";
import {fieldSpecs} from './common';


export default () => {

    const {company, loadingCompany, financialRecords, loadingFinancialRecords} = useSelector(state => state.akkrCompaniesStore, shallowEqual);

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['currencyType'],
        matchFields: ['id'],
        requestData: requestAllData
    });

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['periodOption'],
        matchFields: [],
        requestData: requestInterimData
    });


    return (
        <Container fluid>
            <Grid padded>

                <Grid.Column width={4}>
                    <Grid.Row>
                        <Grid>
                            <Grid.Column width={14}>
                                <Header as='h3'>
                                    {company.short_name}
                                </Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Row>

                    <br/>
                    <Grid.Row>
                        <Form>
                            <AkkrCurrencySelect/>
                            <PeriodOptionSelect/>
                        </Form>
                        <br/>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                        </Grid.Column>
                    </Grid.Row>

                </Grid.Column>

                <Grid.Column width={12}>
                    <AkkrFinancialsComponent financialRecords={financialRecords}
                                             loading={loadingFinancialRecords}/>


                </Grid.Column>

            </Grid>
        </Container>
    )


}

const requestAllData = ({dispatch, searchProps, matchParams}) => {

    const {akkr_company_id} = matchParams;
    const currencyType = searchProps.currencyType;

    const periodOption = periodOptions[searchProps.periodOption];
    const periodType = periodOption.periodType;
    const isLtm = periodOption.isLtm;

    fetchAkkrCompany({dispatch, id: akkr_company_id});
    fetchFinancialsForAkkrCompany({dispatch, akkr_company_id, currencyType, periodType, isLtm});

};

const requestInterimData = ({dispatch, searchProps, matchParams}) => {
    const {akkr_company_id} = matchParams;
    const currencyType = searchProps.currencyType;

    const periodOption = periodOptions[searchProps.periodOption];
    const periodType = periodOption.periodType;
    const isLtm = periodOption.isLtm;

    fetchFinancialsForAkkrCompany({dispatch, akkr_company_id, currencyType, periodType, isLtm});

};