import {Button, Header, Popup, Table} from "semantic-ui-react";
import {formatAbbreviatedDateTimeUtcToLocal, formatFullDateTimeUtcToLocal} from "../../common/formatDate";
import {formatOneDecimalNoNull} from "../../common/format";
import React from "react";


export default ({queueData}) => {

    const queuedAndStartedRecords = queueData.started_jobs.concat(queueData.queued_jobs);

    const finishedJobRows = queueData.finished_jobs.map(record => {
        return (
            <Table.Row key={record.id} style={{color: 'green'}}>
                <Table.Cell>{record.description}</Table.Cell>
                <Table.Cell>{formatAbbreviatedDateTimeUtcToLocal(record.started_at)}</Table.Cell>
                <Table.Cell>{formatAbbreviatedDateTimeUtcToLocal(record.ended_at)}</Table.Cell>
                <Table.Cell>{formatOneDecimalNoNull(record.runtime_minutes)}</Table.Cell>
            </Table.Row>
        )
    });


    const queueRows = queuedAndStartedRecords.map(record => {

        const statusStyle = {};

        return (
            <Table.Row key={record.id}>
                <Table.Cell>{record.description}</Table.Cell>
                <Table.Cell style={statusStyle}>{record.status}</Table.Cell>
                <Table.Cell>{formatAbbreviatedDateTimeUtcToLocal(record.started_at)}</Table.Cell>
                <Table.Cell>{formatAbbreviatedDateTimeUtcToLocal(record.ended_at)}</Table.Cell>
                <Table.Cell>{formatOneDecimalNoNull(record.runtime_minutes)}</Table.Cell>
            </Table.Row>
        )
    });

    const failedRows = queueData.failed_jobs.map(record => {

        return (
            <Table.Row key={record.id}>
                <Table.Cell>{record.description}</Table.Cell>
                <Table.Cell>
                    <Popup hoverable={true} flowing content={formatErrorMessage(record.exc_info)}
                           trigger={<Button icon='archive'/>}/>
                </Table.Cell>
                <Table.Cell>{formatAbbreviatedDateTimeUtcToLocal(record.started_at)}</Table.Cell>
                <Table.Cell>{formatAbbreviatedDateTimeUtcToLocal(record.ended_at)}</Table.Cell>
                <Table.Cell>{formatOneDecimalNoNull(record.runtime_minutes)}</Table.Cell>
            </Table.Row>
        )
    });

    return (
        <div>
            <Header as="h2">Started and Queued Jobs</Header>
            <Table compact collapsing={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Started</Table.HeaderCell>
                        <Table.HeaderCell>Ended</Table.HeaderCell>
                        <Table.HeaderCell>Runtime mins</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {queueRows}
                </Table.Body>
            </Table>

            <Header as="h2">Finished Jobs</Header>
            <Table compact collapsing={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Started</Table.HeaderCell>
                        <Table.HeaderCell>Ended</Table.HeaderCell>
                        <Table.HeaderCell>Runtime mins</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {finishedJobRows}
                </Table.Body>
            </Table>

            <Header as="h2">Failed Jobs</Header>
            <Table compact collapsing={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Detail</Table.HeaderCell>
                        <Table.HeaderCell>Started</Table.HeaderCell>
                        <Table.HeaderCell>Ended</Table.HeaderCell>
                        <Table.HeaderCell>Runtime mins</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {failedRows}
                </Table.Body>
            </Table>


        </div>
    )


}


const formatErrorMessage = (errorMessage) => {
    return errorMessage.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
    });
};