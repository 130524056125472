import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
const targetPriceFeedService = client.service('target_price_feed');
const benchmarkMetadataFeedService = client.service('backtest_model_feed');


const feedFeatureSlice = createSlice({
    name: 'feed',
    initialState: getInitialStateWithLoading({
        targetPriceRecords: [],
        benchmarkMetadataRecords: []
    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchTargetPriceFeedRecords', destinationVariable: 'targetPriceRecords'}),
        ...makeStandardReducers({prefix: 'fetchBenchmarkMetadataFeedRecords', destinationVariable: 'benchmarkMetadataRecords'}),


    }
});

export const fetchTargetPriceFeedRecords = ({dateLimit, limit}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: feedFeatureSlice,
        prefix: 'fetchTargetPriceFeedRecords',
        getPayload: () => targetPriceFeedService.find({
            query: {
                $limit: limit,
                last_modified: {
                    $gte: dateLimit.format('YYYY-MM-DD')
                }

            }
        })
    })
};


export const fetchBenchmarkMetadataFeedRecords = () => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: feedFeatureSlice,
        prefix: 'fetchBenchmarkMetadataFeedRecords',
        getPayload: () => benchmarkMetadataFeedService.find({
            query: {
            }
        })
    })
};


export default feedFeatureSlice.reducer

