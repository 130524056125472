import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Statistic, Header} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import {fetchCompany} from "../../../actions/companyActions";
import {fetchAcquisitionsForCompany, patchAcquisitionEvent} from "../../../features/eventsFeatureSlice";
import {cleanUpdated} from "../../../components/rdg/helpers";
import {fetchConvertibleDebtForCompany, patchConvertibleDebtRecord} from "../../../features/debtFeatureSlice";
import RdgTable from "../../../components/rdg/RdgTable";
import {columns} from "./columns";


export default () => {

    const dispatch = useDispatch();

    const {company, loadingCompany} = useSelector(state => state.companyStore, shallowEqual);
    const {acquisitionEvents, loadingAcquisitionEvents} = useSelector(state => state.events, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: [],
        requestData
    });

    const sidebar = (
        <div>
            <Header>{loadingCompany ? null: company.company_name}</Header>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Statistic horizontal value={acquisitionEvents.length} label='Records Count' size="tiny"/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p>
                            Shares issued: include RSUs assumed, and (for now), the treasury method dilution from
                            options assumed.
                        </p>
                        <p>
                            Cash Component: Cash cost of the acquisition, net of cash assumed.
                        </p>
                        <p>
                            Debt Assumed: Debt assumed as part of the acquisition.
                        </p>
                        <p>
                            For acquisitions, all values should be positive, unless cash assumed was higher than
                            cash spent on the acquisition (typical of an all stock deal).
                        </p>
                        <p>
                            Note for sales, values should still be positive.
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );

    const onGridRowsUpdated = ({fromRow, toRow, updated}) => {

        updated = cleanUpdated(updated);

        const acquisitionEvent = acquisitionEvents[fromRow];

        dispatch(patchAcquisitionEvent({id: acquisitionEvent.id, patchData: updated})).then(() => {
            dispatch(fetchAcquisitionsForCompany({companyId: acquisitionEvent.company_id}));
        });

    };

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             records={acquisitionEvents}
                                             loading={loadingAcquisitionEvents}
                                             onGridRowsUpdated={onGridRowsUpdated}
        />} sidebarView={sidebar}/>
    )

};

const requestData = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    fetchCompany({dispatch, id});
    dispatch(fetchAcquisitionsForCompany({companyId: id}));
};