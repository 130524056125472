import React, {Component} from 'react'
import ReactDataGrid from 'react-data-grid';
import {
  StringFormatter
} from '../../../common/reactDataGrid/formatters';
import {getWindowHeightLessMenuBar} from '../../../common/common'
import {
  getRdgRowsFromRecords,
} from '../../../common/reactDataGrid/common'
import {parseSearchProps} from "../../../common/query/parse";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {formatNoDecimalNoNullOrZero, formatPercentNoNull} from "../../../common/format";
import {fieldSpecs} from './common'
import {associateUrlSuffix, getAssociateUrl} from '../urls'
import {Link} from 'react-router-dom';


class AssociateRoleLinkFormatter extends React.Component {
  render() {
    const suffix = associateUrlSuffix + '&filters%5Brole_id%5D%5Btype%5D=term&filters%5Brole_id%5D%5Bterm%5D=' + this.props.value.id;
    return <Link to={getAssociateUrl({scrapeId: this.props.value.scrapeId, suffix})}>{this.props.value.role}</Link>
  }
}


class BoldStringFormatter extends React.Component {
  render() {
    return <div className="align-left bold-value">{this.props.value}</div>
  }
}

export class BoldPercentNoNullFormatter extends React.Component {
  render() {
    let className = "align-right";
    if (this.props.value.major_cost_center) {
      className += " bold-value";
    }

    return <div className={className}>{formatPercentNoNull(this.props.value.percentage)}</div>
  }
}

class BoldNoDecimalNoNullFormatter extends React.Component {
  render() {

    let className = "align-right";
    if (this.props.value.major_cost_center) {
      className += " bold-value";
    }

    return <div className={className}>{formatNoDecimalNoNullOrZero(this.props.value.count)}</div>
  }
}


const geoHashFromLocationRecords = (records) => {
  return records.reduce((obj, record) => {
    obj[record.name] = record.count;
    return obj
  }, {})
};


class EmployeeAnalysisTable extends Component {

  render() {
    const searchProps = parseSearchProps({searchString: this.props.location.search, fieldSpecs});
    const analysisResult = this.props.analysisResult;
    const {scrape_id} = this.props.match.params;

    let records = [];
    if (!this.props.loading) {
      records = analysisResult.major_cost_centers.reduce((l, record) => {
        const majorCostCenter = {
          major_cost_center: record.name,
          count: record.count,
          percentage: record.percentage,
          ...geoHashFromLocationRecords(record.location_counts)
        };

        const minorCostCenters = record.minor_cost_centers.reduce((sl, mc) => {

          if (!searchProps.showRoles) {
            return sl.concat([
              {
                count: mc.count,
                percentage: mc.percentage,
                minor_cost_center: mc.name,
                ...geoHashFromLocationRecords(mc.location_counts)
              }
            ])
          }

          const roles = mc.roles.map(role => {
            return {
              role: role.name,
              id: role.id,
              count: role.count,
              percentage: role.percentage,
              minor_cost_center: mc.name,
              ...geoHashFromLocationRecords(role.location_counts)
            }
          });

          return sl.concat(roles)

        }, []);

        return l.concat(minorCostCenters, [majorCostCenter])
      }, []);

      records.push({
        major_cost_center: 'Total',
        count: analysisResult.major_cost_centers_total.count,
        percentage: analysisResult.major_cost_centers_total.percentage,
        ...geoHashFromLocationRecords(analysisResult.location_groups)
      });
    }

    let descriptiveColumns = [
      {
        key: 'major_cost_center',
        name: 'Major Cost Center',
        width: 120,
        frozen: true,
        formatter: BoldStringFormatter,
        _getValueFromRecord: (r) => {
          return r.major_cost_center
        }
      },
      {
        key: 'minor_cost_center',
        name: 'Minor Cost Center',
        width: 180,
        frozen: true,
        formatter: StringFormatter,
        _getValueFromRecord: (r) => {
          return r.minor_cost_center
        }
      },
      {
        key: 'role',
        name: 'Role',
        width: 230,
        frozen: true,
        formatter: AssociateRoleLinkFormatter,
        _getValueFromRecord: (r) => {
          return {role: r.role, id: r.id, scrapeId: scrape_id}
        }
      }
    ];

    let geoColumns = [];
    if (!this.props.loading && analysisResult.location_groups.length > 1) {
      geoColumns = analysisResult.location_groups.map(group => {
        return {
          key: group.name,
          name: group.name,
          width: 100,
          formatter: BoldNoDecimalNoNullFormatter,
          _getValueFromRecord: (r) => {
            return {count: r[group.name], major_cost_center: r.major_cost_center}
          }
        }
      })
    }

    let suffixColumns = [

      {
        key: 'count',
        name: 'Count',
        width: 70,
        formatter: BoldNoDecimalNoNullFormatter,
        _getValueFromRecord: (r) => {
          return {count: r.count, major_cost_center: r.major_cost_center}
        }
      },
      {
        key: 'percentage',
        name: '%',
        width: 70,
        formatter: BoldPercentNoNullFormatter,
        _getValueFromRecord: (r) => {
          return {percentage: r.percentage, major_cost_center: r.major_cost_center}
        }
      },
    ];

    let columns = descriptiveColumns.concat(geoColumns, suffixColumns);


    if (!searchProps.showRoles) {
      columns = columns.filter(c => c.key !== 'role')
    }

    const rows = getRdgRowsFromRecords({records: records, columns: columns});

    let className = 'financials-size';
    if (this.props.loading) {
      className += ' loading-grid'
    }

    return (

      <div className={className}>
        <ReactDataGrid
          columns={columns}
          rowGetter={index => rows[index]}
          rowsCount={rows.length}
          rowHeight={22}
          headerRowHeight={100}
          minHeight={getWindowHeightLessMenuBar()}
        />
      </div>

    )
  }

}


function mapStateToProps(state) {
  return {
    analysisResult: state.employeeAnalysisStore.analysisResult,
    loading: state.employeeAnalysisStore.loading,
  }
}


export default withRouter(connect(mapStateToProps,
  {})(EmployeeAnalysisTable));



