import React from 'react'
import {chartSpecificationByName, legendSpecs} from './chartCommon'
import {formatMultipleOneDecimal, formatPercentNoNull, formatNoDecimalNoNullOrZero} from '../../common/format';
import {colorSpecByName, getColorTextFromRgbAndOpacity} from '../../common/chart/chartColorers'
import regression from 'regression';
import {getWindowHeightLessMenuBar} from '../../common/common'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {chartFieldSpecs} from "./common";
import {parseSearchProps} from "../../common/query/parse";
import {getTableField} from "../../common/reactDataGrid/valuationColumns";

const ReactHighcharts = require('react-highcharts');


const getLabelFunction = (labelType) => {
    // load the correct function which will grab the label for each record

    switch (labelType) {

        case "None": {
            return (record) => {
            }
        }

        case "Name": {
            return (record) => {
                return record.short_name
            }
        }

        case "Ticker": {
            return (record) => {
                return record.ticker
            }
        }

        default: {
            return (record) => {

            }
        }
    }

};


const dataLabelsFromLabelType = (name) => {

    switch (name) {

        case "None": {

            return {
                enabled: false
            }
        }

        default: {
            return {
                enabled: true,
                formatter: function () {
                    return this.point.label
                }
            }
        }

    }
};

const getColorTooltip = ({selectedColor, colorSpec, record, period, color}) => {

    if (!selectedColor) {
        return ''
    }

    return '<tr><td>' + selectedColor + '</td><td style="background-color: ' + color + '">' + colorSpec.formatLegendItem(colorSpec.getValue({
        record,
        period
    })) + '</td></tr>'

};


const getRegressionSeries = (allRecords) => {
    const result = regression.linear(allRecords);
    const gradient = result.equation[0];
    const yIntercept = result.equation[1];

    const xValues = allRecords.map(item => {
        return item[0]
    });

    const minX = Math.min(...xValues);
    const maxX = Math.max(...xValues);

    return {
        name: 'Regression: ' + result.string + ' r2: ' + formatPercentNoNull(result.r2),
        type: 'line',
        color: 'rgba(0, 0, 0, .5)',
        enableMouseTracking: false,
        marker: {
            enabled: false
        },
        data: [
            {
                x: minX,
                y: gradient * minX + yIntercept
            },
            {
                x: maxX,
                y: gradient * maxX + yIntercept
            }
        ]
    };
};


class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const searchProps = parseSearchProps({searchString: this.props.location.search, fieldSpecs: chartFieldSpecs});

        const chartName = searchProps.chartName;
        const chartSpec = chartSpecificationByName[chartName];
        const period = searchProps.period;
        const useDilutedGrowth = searchProps.useDilutedGrowth;
        const records = this.props.records;

        const title = chartName;

        const getLabel = getLabelFunction(searchProps.labels);
        const colorSpec = colorSpecByName[searchProps.color];
        const selectedColor = searchProps.color;

        // create a separate series for each color
        const seriesByName = colorSpec.legendItems.reduce((d, item) => {

            d[item.name] = {
                data: [],
                dataLabels: dataLabelsFromLabelType(searchProps.labels),
                color: getColorTextFromRgbAndOpacity(item.color, .75),
                name: item.name
            };

            return d
        }, {});

        const allRecords = records.reduce((list, record) => {
            const name = colorSpec.assignRecordToSeriesName({record, period});
            const color = seriesByName[name].color;

            const x = chartSpec.getX({record, period, useDilutedGrowth});
            const y = chartSpec.getY({record, period});

            if (x != null && y != null) {

                list.push([x, y]);

                const revenueValue = record[getTableField({baseField: 'revenue_total', period})];

                seriesByName[name].data.push({
                    x: x,
                    y: y,
                    label: getLabel(record),
                    companyName: record.short_name,
                    colorToolTip: getColorTooltip({selectedColor, colorSpec, record, period, color}),

                    // organic_constant_currency_revenue_growth: record.financial_period_record.organic_constant_currency_revenue_growth,
                    revenue_growth: record[getTableField({baseField: 'revenue_growth', period})],
                    revenue_recurring_percent: record[getTableField({baseField: 'revenue_recurring_percent', period})],
                    gross_margin: record[getTableField({baseField: 'gross_margin', period})],
                    ebitda_margin: record[getTableField({baseField: 'ebitda_margin', period})],
                    revenue_total: revenueValue ? revenueValue / 1000 : null,
                    ev_to_revenue_total: record[getTableField({baseField: 'ev_to_revenue_total', period})],
                    ev_to_ebitda: record[getTableField({baseField: 'ev_to_ebitda', period})]
                });
            }
            return list


        }, []);

        const xAxisLabel = chartSpec.getXAxisLabel({period: searchProps.period, useDilutedGrowth});
        const yAxisLabel = chartSpec.getYAxisLabel(searchProps.period);

        const legendSpec = legendSpecs['Top Left'];

        let legend = {
            enabled: true,
            reversed: true
        };
        if (searchProps.color) {
            legend = {
                ...legend,
                ...legendSpec,
                title: {
                    text: searchProps.color,
                    style: {
                        fontStyle: 'italic',
                        fontSize: "12px",
                    }
                },

            };
        }

        let allSeries = Object.values(seriesByName);

        if (searchProps.valuationLinesEnabled) {
            allSeries = allSeries.concat(chartSpec.lines || []);
        }

        if (searchProps.regressionLineEnabled) {
            allSeries = allSeries.concat(getRegressionSeries(allRecords));
        }

        allSeries.reverse();

        let yAxisFormatter = function () {
            return chartSpec.formatY(this.value)
        };

        if (chartSpec.yAxisFormatter) {
            yAxisFormatter = chartSpec.yAxisFormatter;
        }

        const config = {
            chart: {
                type: 'scatter',
                zoomType: 'xy',
                style: {
                    fontFamily: "Lato,\"Helvetica Neue\",Arial,Helvetica,sans-serif"
                }
            },
            title: {
                text: title
            },
            legend: legend,
            xAxis: {
                title: {
                    enabled: true,
                    text: xAxisLabel
                },
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true,
                labels: {
                    formatter: function () {
                        return chartSpec.formatX(this.value)
                    }
                }
            },
            yAxis: {
                title: {
                    text: yAxisLabel
                },
                labels: {
                    formatter: yAxisFormatter
                },
                reversed: chartSpec.reversedYAxis ? true : undefined
            },
            tooltip: {
                useHTML: true,
                shared: false,
                headerFormat: '',
                pointFormatter: function () {
                    return '<span style="font-weight: bold">' + this.companyName + '</span>' +
                        '<table>' +
                        '<tr><td>' + xAxisLabel + '</td><td>' + chartSpec.formatX(this.x) + '</td></tr>' +
                        '<tr><td>' + yAxisLabel + '</td><td>' + chartSpec.formatY(this.y) + '</td></tr>' +
                        this.colorToolTip +
                        '<tr><td></td><td></td></tr>' +
                        '<tr><td></td><td></td></tr>' +
                        '<tr><td>Revenue $m</td><td>' + formatNoDecimalNoNullOrZero(this.revenue_total) + '</td></tr>' +
                        '<tr><td>% Recurring</td><td>' + formatPercentNoNull(this.revenue_recurring_percent) + '</td></tr>' +
                        // '<tr><td>' + 'Organic CC Revenue Growth' + '</td><td>' + formatPercentNoNull(this.organic_constant_currency_revenue_growth) + '</td></tr>' +
                        '<tr><td>Revenue Growth</td><td>' + formatPercentNoNull(this.revenue_growth) + '</td></tr>' +
                        '<tr><td>Gross Margin</td><td>' + formatPercentNoNull(this.gross_margin) + '</td></tr>' +
                        '<tr><td>EBITDA Margin</td><td>' + formatPercentNoNull(this.ebitda_margin) + '</td></tr>' +
                        // '<tr><td>' + 'Maximized EBITDA Margin' + '</td><td>' + formatPercentNoNull(this.maximized_ebitda_margin) + '</td></tr>' +
                        '<tr><td></td><td></td></tr>' +
                        '<tr><td>EV/Rev</td><td>' + formatMultipleOneDecimal(this.ev_to_revenue_total) + '</td></tr>' +
                        '<tr><td>EV/EBITDA</td><td>' + formatMultipleOneDecimal(this.ev_to_ebitda) + '</td></tr>' +
                        '</table>'
                },
                followPointer: false,
            },
            plotOptions: {
                series: {
                    animation: false,
                    stickyTracking: false,
                },
                scatter: {
                    dataLabels: {
                        allowOverlap: true,
                        style: {
                            fontSize: "9px",
                            fontWeight: 'normal',
                            textOutline: '1px',
                            color: 'rgba(0, 0, 0, .5)'
                        }
                    },
                    marker: {
                        symbol: 'circle',
                        radius: 4,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },

                }
            },
            series: allSeries

        };

        let axesSpec = null;
        if (searchProps.axesSize === 'Default') {
            axesSpec = chartSpec.defaultAxes;
        } else if (searchProps.axesSize === 'Custom') {
            axesSpec = searchProps.axesSpec;
        }

        if (axesSpec) {
            const fields = [
                ['xAxis', 'max', 'maxX'],
                ['xAxis', 'ceiling', 'ceilingX'],
                ['yAxis', 'max', 'maxY'],
                ['yAxis', 'ceiling', 'ceilingY'],
                ['xAxis', 'min', 'minX'],
                ['xAxis', 'floor', 'floorX'],
                ['yAxis', 'min', 'minY'],
                ['yAxis', 'floor', 'floorY'],
            ];

            for (let fieldList of fields) {
                let [axis, hField, specField] = fieldList;

                const specValue = parseFloat(axesSpec[specField]);

                if (!isNaN(specValue)) {
                    config[axis][hField] = specValue;
                }

            }

        }

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        records: state.valuationTableStore.records,
    }
}

export default withRouter(connect(mapStateToProps,
    {})(ChartComponent));
