import {formatNoDecimalNoNullOrZero, formatPercentNoNull} from "../../../../common/format";
import {parseBoolean} from "../../../../common/query/parse";
import {optionsFromValues} from "../../../../common/common";


export const series1ChartSpecifications = [
    {
        name: 'Exit NTM Growth vs. Entry LTM Growth',
        getY: ({record, forwardYears}) => {

            if (forwardYears === 3) {
                return record.three_year_exit_ntm_revenue_total_growth
            }

            // return the five year in default
            return record.exit_ntm_revenue_total_growth
        },
        getYAxisLabel: () => 'Exit NTM Growth',
        formatY: formatPercentNoNull,
    },
    {
        name: 'Exit NTM Revenue Multiple of Entry NTM',
        getY: ({record, forwardYears}) => record.exit_vs_entry_revenue_total + 1,
        getYAxisLabel: () => 'Exit NTM Revenue Multiple of Entry NTM',
        yMin: 0,
        formatY: formatNoDecimalNoNullOrZero,
    },
];

export const series1ChartSpecificationByName = series1ChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});


export const fieldSpecs = {
    series1ChartName: {
        parse: (v) => v == null ? series1ChartSpecifications[0].name : v
    },
    showHistoricals: {
        parse: v => v != null ? parseBoolean(v) : false
    },
    showStandardGrowthLines: {
        parse: v => v != null ? parseBoolean(v) : true
    },
    forwardYears: {
        parse: v => v != null ? parseInt(v) : 5
    },
    historicalsExitYear: {
        parse: v => v != null ? parseInt(v) : v
    },
};


const forwardYears = [2, 3, 5];
export const forwardYearOptions = optionsFromValues(forwardYears);

export const historicalExitYearOptions = optionsFromValues([null, 2014, 2015, 2016, 2017, 2018, 2019, 2020]);


export const dollarGrowthX = [0, .025, 0.05, .075, 0.1, .125, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, .5, .55, .6, .65, .7, .75, .8, 1];
export const dollarGrowthOfGrowths = [0.0, .1, .2];

export const getOutYearDollarGrowth = (growth, years, growthOfGrowth) => {
    if (years === 0) {
        return growth
    }
    return getOutYearDollarGrowth(growth * (1 + growthOfGrowth) / (1 + growth), years - 1, growthOfGrowth)
};


export const getExitNtmGrowthfromEntryNTM = (growth, years, growthOfGrowth) => {

    // dont need first year as we are doing a multiple of NTM growth
    const annualGrowths = [2, 3, 4, 5, 6].map(v => {
        return getOutYearDollarGrowth(growth, v, growthOfGrowth)
    })

    const exitNtmRevMultiple = annualGrowths.reduce((total, value) => {
        return total * (1 + value)
    }, 1);

    return exitNtmRevMultiple - 1

}
export const growthSeriesByForwardYears = forwardYears.reduce((obj, forwardYear) => {

    const dollarGrowthPoints = dollarGrowthX.map(v => {
        return {
            x: v,
            exit_ntm_revenue_total_growth: getOutYearDollarGrowth(v, forwardYear + 1, 0),
            exit_vs_entry_revenue_total: getExitNtmGrowthfromEntryNTM(v, forwardYear + 1, 0)
        }
    });
    const dollarGrowthPlus10percentPoints = dollarGrowthX.map(v => {
        return {
            x: v,
            exit_ntm_revenue_total_growth: getOutYearDollarGrowth(v, forwardYear + 1, .1),
            exit_vs_entry_revenue_total: getExitNtmGrowthfromEntryNTM(v, forwardYear + 1, .1)
        }
    });
    const dollarGrowthPlus20percentPoints = dollarGrowthX.map(v => {
        return {
            x: v,
            exit_ntm_revenue_total_growth: getOutYearDollarGrowth(v, forwardYear + 1, .2),
            exit_vs_entry_revenue_total: getExitNtmGrowthfromEntryNTM(v, forwardYear + 1, .2)
        }
    });


    obj[forwardYear] = [
        dollarGrowthPoints,
        dollarGrowthPlus10percentPoints,
        dollarGrowthPlus20percentPoints
    ];
    return obj
}, {});



let dollarGrowthPoints = dollarGrowthX.map(v => {
    return {
        x: v,
        exit_ntm_revenue_total_growth: getOutYearDollarGrowth(v, 6, 0),
        exit_vs_entry_revenue_total: getExitNtmGrowthfromEntryNTM(v, 6, 0)
    }
});



