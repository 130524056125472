import React from 'react';
import {
    CompanyLinkFormatter,
    MultipleOneDecimalFormatter,
    MultipleTwoDecimalFormatter,
    PercentNoDecimalNoNullFormatter,
    PercentOneDecimalNoNullFormatter,
    SharePriceFormatter
} from "../../../common/reactDataGrid/formatters";
import {BackupLinkFormatter} from "../common";
import {CurrencySharePriceFormatter} from "../../portfolio/common";
import {CompanyLinkFromCompany, CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";


export const columns = [
    CompanyLinkFromCompany,
    // {
    //     key: 'dorado_valuation_record_id',
    //     name: 'Detail',
    //     width: 60,
    //     frozen: true,
    //     formatter: BackupLinkFormatter,
    //     _getValueFromRecord: (r) => {
    //         return {
    //             company_id: r.company.id,
    //             dorado_valuation_record_id: r.id
    //         }
    //     }
    // },
    {
        key: 'entry_price_per_share_trading',
        name: 'Entry Price per Share',
        sortable: true,
        formatter: CurrencySharePriceFormatter,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.entry_price_per_share_trading,
                trading_currency: r.trading_currency
            }
        }
    },


    {
        key: 'irr',
        name: 'Expected IRR',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'no_multiple_expansion_irr',
        name: 'No Multiple Expansion IRR',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'no_multiple_change_irr',
        name: 'No Multiple Change IRR',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'ltm_relevant_organic_growth',
        name: 'LTM Relevant Organic Growth',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'entry_ntm_organic_arr_growth',
        name: 'NTM Organic ARR Growth',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'exit_ntm_organic_arr_growth',
        name: 'Exit ARR Growth',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'revenue_total_cagr',
        name: 'Forecast Revenue CAGR',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
    },


    {
        key: 'exit_arr_gross_retention',
        name: 'Gross Retention',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'entry_average_implemented_arr_to_sm',
        name: '5-yr avg Impl. ARR / S&M',
        sortable: true,
        formatter: MultipleTwoDecimalFormatter,
        width: 80,
    },
    {
        key: 'exit_maximized_pretax_fcf_margin_ntm',
        name: 'Exit Max Pretax FCF Margin',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
    },

    {
        key: 'entry_ev_to_revenue_total_ntm',
        name: 'Entry EV/NTM Revenue',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },
    {
        key: 'entry_ev_to_pretax_fcf_ntm',
        name: 'Entry EV/NTM Pretax FCF',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },
    {
        key: 'entry_ev_to_maximized_pretax_fcf_ntm',
        name: 'Entry EV/NTM Max FCF',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },



    {
        key: 'exit_ev_to_revenue_total_ntm',
        name: 'Exit EV/NTM Revenue',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },
    {
        key: 'exit_ev_to_gross_profit_total_ntm',
        name: 'Exit EV/NTM GP',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },
    {
        key: 'exit_ev_to_maximized_pretax_fcf_ntm',
        name: 'Exit EV/Max Pretax FCF',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },
    {
        key: 'exit_multiple_net_premium',
        name: 'Exit Mult. Premium (Discount)',
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'unaffected_exit_ev_to_revenue_total_ntm',
        name: 'Unaffected Exit EV/NTM Rev.',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 90,
    },
    {
        key: 'ltm_reported_ebit_margin',
        name: 'LTM EBIT Margin',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'trading_currency',
        name: 'Trading Currency',
        sortable: true,
        width: 80,
    },
    {
        key: 'exit_price_per_share',
        name: 'Exit Price per Share',
        sortable: true,
        formatter: SharePriceFormatter,
        width: 80,
    },
    {
        key: 'total_gain',
        name: 'Expected Gain',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },



];
