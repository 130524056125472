import {
    formatMultipleOneDecimal,
    formatNoDecimalNoNullOrZero,
    formatPercentNoNull,
    formatPercentOneDecimalNoNull
} from "../../../common/format";
import moment from "moment";


export const chartSpecs = [
    {
        getSeries: (doradoUniverseValuationRecords) => {
            return [
                {
                    name: 'Median IRR',
                    data: doradoUniverseValuationRecords.map(r => {
                        return [moment(r.as_of_datetime).valueOf(), r.median_irr]

                    }),
                    color: 'black'
                },
                {
                    name: '80th % IRR',
                    data: doradoUniverseValuationRecords.map(r => {
                        return [moment(r.as_of_datetime).valueOf(), r.irr_80th_percentile]
                    }),
                    color: '#1b7837'
                },
                {
                    name: '20th % IRR',
                    data: doradoUniverseValuationRecords.map(r => {
                        return [moment(r.as_of_datetime).valueOf(), r.irr_20th_percentile]
                    }),
                    color: '#e08214'
                }
            ]

        },
        yAxisTitle: 'IRR',
        yAxisFormat: formatPercentOneDecimalNoNull,
    },
    {
        getSeries: (doradoUniverseValuationRecords) => {
            return [
                {
                    name: 'Median EV/NTM Revenue',
                    data: doradoUniverseValuationRecords.map(r => {
                        // return {
                        //     x: moment(r.as_of_datetime).valueOf(),
                        //     y: r.median_ev_to_revenue_total_ntm,
                        // }
                        return [moment(r.as_of_datetime).valueOf(), r.median_ev_to_revenue_total_ntm]
                    }),
                    color: 'black',
                    yAxis: 0,
                },
                {
                    name: '>30% Growth EV/NTM Revenue',
                    data: doradoUniverseValuationRecords.map(r => {
                        // return {
                        //     x: moment(r.as_of_datetime).valueOf(),
                        //     y: r.median_ev_to_revenue_total_ntm_growth_gte_30,
                        // }
                        return [moment(r.as_of_datetime).valueOf(), r.median_ev_to_revenue_total_ntm_growth_gte_30]
                    }),
                    color: '#1b7837',
                    yAxis: 0,
                },
                {
                    name: '>30% Growth Company Count',
                    data: doradoUniverseValuationRecords.map(r => {
                        // return {
                        //     x: moment(r.as_of_datetime).valueOf(),
                        //     y: r.median_organic_arr_growth_for_growth_gte_30,
                        // }
                        return [moment(r.as_of_datetime).valueOf(), r.record_count_ntm_growth_gte_30]
                    }),
                    color: '#1b7837',
                    yAxis: 1,
                },
            ]

        },
        yAxisTitle: 'EV/NTM Revenue',
        yAxisFormat: formatMultipleOneDecimal,
    },
];


export const chartOptions = chartSpecs.map(spec => {
    return {
        key: spec.yAxisTitle,
        value: spec.yAxisTitle,
        text: spec.yAxisTitle
    }
});

export const chartSpecByName = chartSpecs.reduce((obj, spec) => {
    obj[spec.yAxisTitle] = spec;
    return obj
}, {});

export const fieldSpecs = {
    chartName: {
        parse: (v) => v == null ? chartSpecs[1].yAxisTitle : v
    },
}
