import React from 'react'
import ReactDataGrid from 'react-data-grid';
import {Editors} from 'react-data-grid-addons';
import {getWindowHeightLessMenuBar, optionsFromValues} from '../../common/common'
import {
    boolOptions,
    getMostRecentSortColumnAndDirectionForRDG,
    getRdgRowsFromRecords,
    getTableClassName,
    makeHandleReactDataGridSort
} from '../../common/reactDataGrid/common'
import {commonColumnsByName} from "../../common/reactDataGrid/columns"
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {createAppendSortToQuery} from "../../common/query/add";
import {parseSearchProps} from "../../common/query/parse";
import {patchCompany} from "../../actions/tagActions";
import {coverageOptions, Currencies, QualityText} from '../../common/constants'
import {benchNullOption} from "../../common/query/null";
import {useHistory, useLocation} from "react-router-dom";
import {
    BoolToYesFormatter,
    BoolToYesNoFormatter,
    CompanyLinkFormatter,
    DateFormatter, PercentNoDecimalNoNullFormatter
} from '../../common/reactDataGrid/formatters';
import {cleanUpdated} from "../../components/rdg/helpers";
import {IsEditableFieldRenderer} from "../../components/rdg/rdgComponents";
import {getRoundedPercentageTextFromFloat, grossRetentionOptions, netRetentionOptions} from "../companyNote/options";

const {DropDownEditor} = Editors;


const businessQualityOptions = optionsFromValues([null, QualityText.great, QualityText.good, QualityText.average, QualityText.bad, QualityText.terrible]);

const ManagementQualityOptions = [
    benchNullOption,
    {
        key: QualityText.great,
        value: 5,
        text: QualityText.great
    },
    {
        key: QualityText.good,
        value: 4,
        text: QualityText.good
    },
    {
        key: QualityText.average,
        value: 3,
        text: QualityText.average
    },
    {
        key: QualityText.bad,
        value: 2,
        text: QualityText.bad
    },
    {
        key: QualityText.terrible,
        value: 1,
        text: QualityText.terrible
    },
];


const manualCurrencyOptions = optionsFromValues([
    null,
    ...Currencies
])

const monthOptions = optionsFromValues([null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);


const trackingRankOptions = optionsFromValues(['1: Core', '2: Track', '3: Low Probability', '4: Not interesting']);

const geographyGroupOptions = optionsFromValues(['Core', 'Ancillary', 'NonCore']);

const primaryRevenueModelOptions = optionsFromValues([
    null, 'Perpetual License/Maintenance', 'Term License', 'Recurring Transactional', 'Subscription', 'Hardware', 'Services']);

const primaryMarketTypeOptions = optionsFromValues([null, 'Horizontal', 'Vertical']);



export class TextFormatter extends React.Component {
    render() {
        return <div>{this.props.value}</div>
    }
}



const columns = [
    {
        key: 'company_name',
        name: 'Name',
        width: 200,
        frozen: true,
        sortable: true,
        formatter: CompanyLinkFormatter,
        _getValueFromRecord: (r) => {
            return {
                display_name: r.company_name,
                company_id: r.id
            }
        }
    },
    commonColumnsByName['exchange_ticker'],


    {
        key: 'date_added',
        name: 'Date Added',
        formatter: <DateFormatter/>,
        width: 100,
        sortable: true,
    },
    {
        key: 'lbo_track',
        name: 'LBO Track',
        width: 100,
        formatter: BoolToYesNoFormatter,
        sortable: true,
        editable: true,
        editor: <DropDownEditor options={boolOptions}/>,
    },
    {
        ...commonColumnsByName['business_quality'],
        editable: true,
        editor: <DropDownEditor options={businessQualityOptions}/>,
    },
    {
        ...commonColumnsByName['management_score'],
        editable: true,
        editor: <DropDownEditor options={ManagementQualityOptions}/>,
    },
    {
        ...commonColumnsByName['geography_group'],
        editable: true,
        editor: <DropDownEditor options={geographyGroupOptions}/>,
    },
    {
        ...commonColumnsByName['tracking_rank'],
        editable: true,
        editor: <DropDownEditor options={trackingRankOptions}/>,
    },

    {
        key: 'primary_revenue_model',
        name: 'Primary Revenue Model',
        formatter: <TextFormatter/>,
        width: 200,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={primaryRevenueModelOptions}/>
    },
    {
        key: 'primary_market_type',
        name: 'Primary Market Type',
        formatter: <TextFormatter/>,
        width: 200,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={primaryMarketTypeOptions}/>
    },
    {
        key: 'cap_sw_and_excess_capex_percent_of_revenue',
        name: 'Cap SW and Excess Capex % Rev',
        formatter: <PercentNoDecimalNoNullFormatter/>,
        width: 100,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={grossRetentionOptions}/>,
    },
    {
        key: 'normalized_gross_retention',
        name: 'Normalized Gross Retention',
        formatter: <PercentNoDecimalNoNullFormatter/>,
        width: 100,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={grossRetentionOptions}/>,
    },
    {
        key: 'normalized_net_retention',
        name: 'Normalized Net Retention',
        formatter: <PercentNoDecimalNoNullFormatter/>,
        width: 100,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={netRetentionOptions}/>,
    },
    {
        key: 'coverage',
        name: 'Coverage',
        formatter: <TextFormatter/>,
        width: 100,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={coverageOptions}/>
    },
    {
        key: 'in_dorado_universe',
        name: 'In Dorado Universe',
        formatter: BoolToYesFormatter,
        width: 70,
        editable: true,
        editor: <DropDownEditor options={boolOptions}/>,
    },
    {
        key: 'is_public',
        name: 'Is Public?',
        formatter: BoolToYesNoFormatter,
        width: 70,
    },
    {
        key: 'is_or_was_public',
        name: 'Is or Was Public?',
        formatter: BoolToYesNoFormatter,
        width: 70,
    },
    {
        key: 'is_public_override',
        name: 'Override: Is Public',
        formatter: BoolToYesNoFormatter,
        width: 70,
        editable: true,
        editor: <DropDownEditor options={boolOptions}/>,
    },
    {
        key: 'is_pre_ipo',
        name: 'Is pre-IPO?',
        formatter: BoolToYesFormatter,
        width: 70,
        editable: true,
        editor: <DropDownEditor options={boolOptions}/>,
    },
    {
        key: 'has_valid_period_info',
        name: 'Has Valid Period Info?',
        formatter: BoolToYesNoFormatter,
        width: 70,
    },
    {
        key: 'halt_ciq_data_updates',
        name: 'Halt CIQ Data Updates?',
        formatter: BoolToYesFormatter,
        width: 70,
        editable: true,
        editor: <DropDownEditor options={boolOptions}/>,
    },
    {
        key: 'has_valid_share_classes',
        name: 'Has Valid Share Classes?',
        formatter: BoolToYesNoFormatter,
        width: 70,
    },

    commonColumnsByName['country_name'],

    {
        key: 'filing_currency',
        name: 'Filing Currency',
        formatter: <TextFormatter/>,
        width: 70,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={manualCurrencyOptions}/>
    },
    {
        key: 'trading_currency',
        name: 'Trading Currency',
        formatter: <TextFormatter/>,
        width: 70,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={manualCurrencyOptions}/>
    },


    {
        key: 'in_dorado_short_universe',
        name: 'In Dorado Short Universe',
        formatter: BoolToYesFormatter,
        width: 70,
        editable: true,
        editor: <DropDownEditor options={boolOptions}/>,
    },


    {
        key: 'fiscal_year_end_month_override',
        name: 'Fiscal Year End Month Override',
        formatter: <TextFormatter/>,
        width: 80,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={monthOptions}/>
    },
    {
        key: 'fiscal_year_end_month',
        name: 'Fiscal Year End Month',
        width: 90,
        sortable: true,
    },
    {
        key: 'interim_period_frequency',
        name: 'Interim Period Frequency',
        width: 90,
        sortable: true,
    },
    {
        key: 'last_reported_period',
        name: 'Last Reported Period',
        width: 90,
        sortable: true,
    },
    {
        key: 'last_reported_period_end_date',
        name: 'Last Reported Period End Date',
        width: 90,
        sortable: true,
        formatter: DateFormatter
    },

    {
        key: 'has_valid_period_info_error_text',
        name: 'Period Info Error Text',
        width: 200,
        sortable: true,
        resizable: true
    },
    {
        key: 'has_valid_share_classes_error_text',
        name: 'Share Classes Error Text',
        width: 200,
        sortable: true,
        resizable: true
    },
    {
        key: 'box_benchmark_file_id',
        name: 'Box File ID: Current Forecast',
        width: 200,
        sortable: true,
        resizable: true
    },
    {
        key: 'box_backtest_folder_id',
        name: 'Box Folder ID: Backtest Forecasts',
        width: 200,
        sortable: true,
        resizable: true,
        editable: true
    },
    {
        key: 'ciq_id',
        name: 'CIQ ID',
        width: 130,
        sortable: true,
    },







];


export default () => {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const searchProps = parseSearchProps({searchString: location.search});

    const {companies, loading} = useSelector(state => state.tagsStore, shallowEqual);

    const onChangeSort = createAppendSortToQuery({history, searchProps: searchProps});
    const handleReactDataGridSort = makeHandleReactDataGridSort(onChangeSort);
    const {sortColumn, sortDirection} = getMostRecentSortColumnAndDirectionForRDG(searchProps);

    const rows = getRdgRowsFromRecords({records: companies, columns});

    const onGridRowsUpdated = ({fromRow, toRow, updated}) => {

        // double update is a big in reactDataGrid
        // https://github.com/adazzle/react-data-grid/issues/1061
        console.log('gridrowsupdated ' + fromRow + ' ' + toRow + ' ' + updated);
        const company = companies[fromRow];

        // convert '' to null
        updated = cleanUpdated(updated);

        patchCompany({dispatch, id: company.id, patchData: updated});

    };

    return (

        <div className={getTableClassName(loading)}>

            <ReactDataGrid
                columns={columns}
                onGridSort={handleReactDataGridSort}
                rowGetter={index => rows[index]}
                rowsCount={rows.length}
                headerRowHeight={100}
                rowHeight={22}
                minHeight={getWindowHeightLessMenuBar()}
                enableCellSelect={true}
                onGridRowsUpdated={onGridRowsUpdated}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            />

        </div>

    )


};