import React from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {createAppendSortToQuery} from '../../../common/query/add';
import {
    getMostRecentSortColumnAndDirectionForRDG,
    getRdgRowsFromRecords,
    makeHandleReactDataGridSort
} from "../../../common/reactDataGrid/common";
import {parseSearchProps} from "../../../common/query/parse";
import ReactDataGrid from 'react-data-grid';
import {getWindowHeightLessMenuBar} from '../../../common/common'

import {
    BoolToYesCheckFormatter,
    NoDecimalNoNullOrZeroFormatter,
    UtcDateFormatter,
    BoolToYesNoCheckFormatter, StringFormatter, UtcToLocalFullDateTimeFormatter
} from "../../../common/reactDataGrid/formatters";
import {AkkrCompanyLinkFormatter} from "../common";
import {CurrencyFormatter} from "./currencyFormatter";
import {useHistory, useLocation} from "react-router-dom";
import {getTableClassName} from '../../../common/reactDataGrid/common'
import {columnGroups, groupFieldSpecs} from "./common";
import {getFilteredColumns} from "../../../components/showHideColumns/common";


const tableColumns = [
    {
        key: 'name',
        name: 'Name',
        width: 200,
        frozen: true,
        sortable: true,
        formatter: AkkrCompanyLinkFormatter,
        _getValueFromRecord: (r) => {
            return {
                name: r.name,
                akkr_company_id: r.id
            }
        }
    },
    {
        key: 'box_file_name',
        name: 'Box File Name',
        frozen: true,
        sortable: true,
        formatter: StringFormatter,
        width: 150
    },
    {
        key: 'last_modified_date',
        name: 'Last Modified',
        sortable: true,
        formatter: UtcToLocalFullDateTimeFormatter,
        width: 150
    },


    {
        key: 'is_active_investment',
        name: 'Is Active Investment?',
        sortable: true,
        formatter: BoolToYesCheckFormatter,
        width: 90
    },
    {
        key: 'is_rollover_investment',
        name: 'Is Rollover Investment?',
        sortable: true,
        formatter: BoolToYesCheckFormatter,
        width: 90
    },
    {
        key: 'last_reported_period_end_date',
        name: 'Last Reported Period Date',
        sortable: true,
        formatter: UtcDateFormatter,
        width: 100
    },
    {
        key: 'last_reported_period',
        name: 'Last Reported Period',
        sortable: true,
        width: 80
    },
    {
        key: 'is_buyout',
        name: 'Buyout Fund',
        sortable: true,
        formatter: BoolToYesCheckFormatter,
        width: 70
    },
    {
        key: 'is_growth',
        name: 'Growth Fund',
        sortable: true,
        formatter: BoolToYesCheckFormatter,
        width: 70
    },
    {
        key: 'is_credit',
        name: 'Credit Fund',
        sortable: true,
        formatter: BoolToYesCheckFormatter,
        width: 70
    },
    {
        key: 'filing_currency',
        name: 'Filing Currency',
        sortable: true,
        width: 90,

    },
    {
        key: 'filing_exchange_rate_to_usd',
        name: 'Filing Exch. Rt. to USD',
        sortable: true,
        width: 90,
        formatter: CurrencyFormatter
    },
    {
        key: 'fiscal_year_end_month',
        name: 'Fiscal Year End Month',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 50
    },


    {
        key: 'buyout_original_investment_date',
        name: 'Buyout Investment Date',
        sortable: true,
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'growth_original_investment_date',
        name: 'Growth Investment Date',
        sortable: true,
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'credit_original_investment_date',
        name: 'Credit Investment Date',
        sortable: true,
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'has_revenue_composition',
        name: 'Has Revenue Detail',
        sortable: true,
        formatter: BoolToYesNoCheckFormatter,
        width: 90
    },
    {
        key: 'has_gross_profit_composition',
        name: 'Has COGS Detail',
        sortable: true,
        formatter: BoolToYesNoCheckFormatter,
        width: 90
    },
    {
        key: 'has_opex_composition',
        name: 'Has OPEX Detail',
        sortable: true,
        formatter: BoolToYesNoCheckFormatter,
        width: 90
    },
    {
        key: 'has_bookings_composition',
        name: 'Has Bookings Detail',
        sortable: true,
        formatter: BoolToYesNoCheckFormatter,
        width: 90
    },
    {
        key: 'has_budget_revenue_composition',
        name: 'Has Budget Revenue Detail',
        sortable: true,
        formatter: BoolToYesNoCheckFormatter,
        width: 90
    },
    {
        key: 'has_budget_bookings_composition',
        name: 'Has Budget Bookings Detail',
        sortable: true,
        formatter: BoolToYesNoCheckFormatter,
        width: 90
    },
    {
        key: 'has_gross_retention',
        name: 'Has Gross Retention',
        sortable: true,
        formatter: BoolToYesNoCheckFormatter,
        width: 90
    },
    {
        key: 'has_net_retention',
        name: 'Has Net Retention',
        sortable: true,
        formatter: BoolToYesNoCheckFormatter,
        width: 90
    },

];


export default () => {

    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs: groupFieldSpecs});

    const {records, loading} = useSelector(state => state.akkrCompaniesStore, shallowEqual);

    const onChangeSort = createAppendSortToQuery({history, searchProps: searchProps});
    const handleReactDataGridSort = makeHandleReactDataGridSort(onChangeSort);
    const {sortColumn, sortDirection} = getMostRecentSortColumnAndDirectionForRDG(searchProps);

    const rows = getRdgRowsFromRecords({records: records, columns: tableColumns});

    return (

        <div className={getTableClassName(loading)}>

            <ReactDataGrid
                columns={tableColumns}
                onGridSort={handleReactDataGridSort}
                rowGetter={index => rows[index]}
                rowsCount={rows.length}
                rowHeight={22}
                headerRowHeight={100}
                minHeight={getWindowHeightLessMenuBar()}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            />

        </div>

    )

}

