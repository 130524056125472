import {
    formatMultipleOneDecimal,
    formatPercentNoNull,
    formatNoDecimalNoNullOrZero,
    formatPercentOneDecimalNoNull,
    formatNoDecimalNoNull
} from "../../../common/format";
import moment from "moment";
import {indicesFieldSpecs} from "./indices";



const totalReturnSpec = {
    name: 'Total Return',
    getY: (record) => record.dorado_portfolio_state_report.indexed_return,
    formatY: formatPercentNoNull,
}


export const series1ChartSpecifications = [
    {
        name: 'Total Return',
        chartSpecs: [
            totalReturnSpec
        ],
        getYAxisLabel: () => 'Total Return',

    },
    {
        name: 'Net Asset Value',
        getYAxisLabel: () => 'Net Asset Value',
        yMin: 0,
        chartSpecs: [
            {
                name: 'Net Asset Value',
                getY: (record) => record.net_asset_value,
                formatY: formatNoDecimalNoNullOrZero,
            },
            {
                name: 'Long Securities',
                getY: (record) => record.securities_long_market_value,
                formatY: formatNoDecimalNoNullOrZero,
                color: '#4393c3',
                dashStyle: 'ShortDash'
            },
            {
                name: 'Short Securities',
                getY: (record) => -record.securities_short_market_value,
                formatY: formatNoDecimalNoNullOrZero,
                color: '#d6604d'
            }
        ]
    },
    {
        name: 'NAV Exposures',
        getYAxisLabel: () => '$',
        yMin: 0,
        chartSpecs: [
            {
                name: 'Long Securities % NAV',
                getY: (record) => record.securities_long_market_value_percent,
                formatY: formatPercentOneDecimalNoNull,
                color: '#4393c3'
            },
            {
                name: 'Short Securities % NAV',
                getY: (record) => -record.securities_short_market_value_percent,
                formatY: formatPercentOneDecimalNoNull,
                color: '#d6604d'
            },
            {
                name: 'Cash + Short Securities % NAV',
                getY: (record) => -record.securities_short_market_value_percent + record.cash_unencumbered_percent,
                color: '#9970ab',
                formatY: formatPercentOneDecimalNoNull,

            },
        ]
    },
    {
        name: 'Expected 5-Yr IRR',
        getYAxisLabel: () => 'Expected 5-Yr IRR',
        chartSpecs: [

            {
                name: 'Portfolio Expected 5-Yr IRR',
                getY: (record) => record.dorado_portfolio_state_report.expected_long_irr,

                formatY: formatPercentOneDecimalNoNull,
            },
            {
                name: 'Universe Median Expected IRR',
                getY: (record) => record.dorado_portfolio_state_report.universe_median_expected_irr,
                formatY: formatPercentOneDecimalNoNull,
            }
        ]
    },
    {
        name: 'Execution Score',
        getYAxisLabel: () => 'Execution Score',
        chartSpecs: [

            {
                name: 'Portfolio Median Execution Score',
                getY: (record) => record.dorado_portfolio_state_report.median_execution_score,

                formatY: formatPercentOneDecimalNoNull,
            },
            {
                name: 'Universe Median Execution Score',
                getY: (record) => record.dorado_portfolio_state_report.universe_median_execution_score,
                formatY: formatPercentOneDecimalNoNull,
            }
        ]
    },
    {
        name: 'EV/ NTM Revenue',
        getYAxisLabel: () => 'EV/ NTM Revenue',
        chartSpecs: [

            {
                name: 'Portfolio EV/ NTM Revenue',
                getY: (record) => record.dorado_portfolio_state_report.median_entry_ev_to_revenue_total_ntm,

                formatY: formatMultipleOneDecimal,
            },
            {
                name: 'Universe EV/ NTM Revenue',
                getY: (record) => record.dorado_portfolio_state_report.universe_median_entry_ev_to_revenue_total_ntm,
                formatY: formatMultipleOneDecimal,
            }
        ]
    },

    {
        name: 'Cash Balance',
        getYAxisLabel: () => 'Cash',
        chartSpecs: [
            {
                name: 'Cash',
                getY: (record) => record.cash,
                formatY: formatNoDecimalNoNullOrZero,
            }
        ]

    },
    // {
    //     name: 'Top Position % of Equity',
    //     getY: (record) => record.dorado_portfolio_state_report.top_position_weight,
    //     getYAxisLabel: () => 'Top Position % of Equity',
    //     yMin: 0,
    //     formatY: formatPercentNoNull,
    // },
    // {
    //     name: 'Top 5 Positions % of Equity',
    //     getY: (record) => record.dorado_portfolio_state_report.top_five_positions_weight,
    //     getYAxisLabel: () => 'Top 5 Positions % of Equity',
    //     yMin: 0,
    //     formatY: formatPercentNoNull,
    // },

    // {
    //     name: 'Portfolio Avg Execution Score',
    //     getY: (record) => record.dorado_portfolio_state_report.average_execution_score,
    //     getYAxisLabel: () => 'Portfolio Avg Execution Score',
    //     formatY: formatPercentOneDecimalNoNull,
    // },
    // {
    //     name: 'Portfolio Median Execution Score',
    //     getY: (record) => record.dorado_portfolio_state_report.median_execution_score,
    //     getYAxisLabel: () => 'Portfolio Median Execution Score',
    //     formatY: formatPercentOneDecimalNoNull,
    // },
    // {
    //     name: 'Portfolio 25th Percentile Execution Score',
    //     getY: (record) => record.dorado_portfolio_state_report.twenty_fifth_percentile_execution_score,
    //     getYAxisLabel: () => 'Portfolio 25th Percentile Execution Score',
    //     formatY: formatPercentOneDecimalNoNull,
    // },
    // {
    //     name: 'Gross Long Trades % Equity',
    //     getY: (record) => record.dorado_portfolio_state_report.gross_long_trades_percent_of_equity,
    //     getYAxisLabel: () => 'Gross Long Trades % Equity',
    //     formatY: formatPercentOneDecimalNoNull,
    //     yMax: .2,
    //     type: 'column'
    // },
    // {
    //     name: 'Cumul. Long Trades % Equity',
    //     getY: (record) => record.dorado_portfolio_state_report.cumul_gross_total_trades_percent_of_equity,
    //     getYAxisLabel: () => 'Cumul. Long Trades % Equity',
    //     formatY: formatPercentOneDecimalNoNull,
    //     type: 'line'
    // },
    // {
    //     name: 'Cumul. Hard Trading Cost % Avg Equity',
    //     getY: (record) => record.dorado_portfolio_state_report.cumul_hard_trading_costs_percent_average_equity,
    //     getYAxisLabel: () => 'Cumul. Hard Trading Cost % Avg Equity',
    //     formatY: formatPercentOneDecimalNoNull,
    //     type: 'line'
    // },
    // {
    //     name: 'Cumul. Soft Trading Cost % Avg Equity',
    //     getY: (record) => record.dorado_portfolio_state_report.cumul_soft_trading_costs_percent_average_equity,
    //     getYAxisLabel: () => 'Cumul. Soft Trading Cost % Avg Equity',
    //     formatY: formatPercentOneDecimalNoNull,
    //     type: 'line'
    // },
    // {
    //     name: 'Cumul. Total Trading Cost % Avg Equity',
    //     getY: (record) => record.dorado_portfolio_state_report.cumul_total_trading_costs_percent_average_equity,
    //     getYAxisLabel: () => 'Cumul. Total Trading Cost % Avg Equity',
    //     formatY: formatPercentOneDecimalNoNull,
    //     type: 'line'
    // },
    // {
    //     name: 'Hard Trading Costs $',
    //     getY: (record) => record.dorado_portfolio_state_report.hard_trading_costs,
    //     getYAxisLabel: () => 'Hard Trading Costs $',
    //     formatY: formatNoDecimalNoNullOrZero,
    //     type: 'column'
    // },
    // {
    //     name: 'Cumul. Hard Trading Costs $',
    //     getY: (record) => record.dorado_portfolio_state_report.cumul_hard_trading_costs,
    //     getYAxisLabel: () => 'Hard Trading Costs $',
    //     formatY: formatNoDecimalNoNullOrZero,
    //     type: 'column'
    // },
    // {
    //     name: '# of Positions',
    //     getY: (record) => record.dorado_portfolio_state_report.position_count,
    //     getYAxisLabel: () => '# of Positions',
    //     formatY: formatNoDecimalNoNullOrZero,
    // },
    // {
    //     name: 'Herfindahl Index',
    //     getY: (record) => record.dorado_portfolio_state_report.herfindahl_index,
    //     getYAxisLabel: () => 'Herfindahl Index',
    //     formatY: formatNoDecimalNoNull,
    // },
    //







];

export const series1ChartSpecificationByName = series1ChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});


export const fieldSpecs = {
    series1ChartName: {
        parse: (v) => v == null ? series1ChartSpecifications[0].name : v
    },
    ...indicesFieldSpecs
};

