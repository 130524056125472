import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
const companiesSearchService = client.service('search/search/companies');



const companySearchFeatureSlice = createSlice({
    name: 'companySearch',
    initialState: {

        searchText: '',
        searchResults: [],
        loadingSearchResults: false,

        searchRequestKey: null,

        ...getInitialStateWithLoading({
        })
    },
    reducers: {

        searchForCompanyPending: (state) => {
            state.loadingSearchResults = true;
        },
        searchForCompanyFulfilled: (state, action) => {
            state.loadingSearchResults = false;
            const data = action.payload.data;

            const newRequestKey = data.request_key;
            const existingRequestKey = state.searchRequestKey;

            // only apply results if it was a later search so we are sequentially in order
            if (existingRequestKey === null || newRequestKey > existingRequestKey) {
                state.searchRequestKey = newRequestKey;
                state.searchResults = data.results;
            }
        },
        changeCompanySearchValue: (state, action) => {

            state.searchText = action.payload.text;
        },
        clearCompanySearchResults: (state, action) => {
            state.searchText = '';
            state.loadingSearchResults = false;
            state.searchResults = [];
        },
    }
});


export const {changeCompanySearchValue, clearCompanySearchResults} = companySearchFeatureSlice.actions;

export const searchForCompany = ({text}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: companySearchFeatureSlice,
        prefix: 'searchForCompany',
        getPayload: () => companiesSearchService.find({
            query: {
                text: text,
                request_key: new Date().getTime()
            }
        })
    })


};


// export const changeCompanySearchValue = ({text}) => (dispatch, getState) => {
//
//     dispatch(companySearchFeatureSlice.changeCompanySearchValue(text))
//     return text
// }
//
// export const clearCompanySearchResults = () => (dispatch, getState) => {
//
//     dispatch(companySearchFeatureSlice.clearCompanySearchResults())
//
// }





export default companySearchFeatureSlice.reducer