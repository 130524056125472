import React from "react";
import {
    BoolToYesCheckFormatter,
    BoolToYesNoFormatter,
    DateFormatter,
    getCompanyLink, MultipleOneDecimalFormatter, NoDecimalNoNullFormatter,
    NoDecimalNoNullOrZeroFormatter, OneDecimalNoNullFormatter, PercentGainOneDecimalFormatter, PercentGainsFormatter,
    PercentOneDecimalNoNullFormatter, PercentTwoDecimalNoNullFormatter, PricePercentChangeOneDecimalFormatter,
    RelativeWeightFormatter, SharePriceFormatter,
    ThreeDecimalNoNullFormatter,
    TwoDecimalNoNullFormatter, UtcToLocalFullDateTimeFormatter,
    WeightFormatter
} from "../../../common/reactDataGrid/formatters";
import {divideValueBy1000, divideValueBy1000000} from "../../../common/reactDataGrid/valuationColumns";
import {portfolioHeaderColumn} from "../../../components/portfolio/portfolioColumns";
import {CurrencySharePriceFormatter} from "../../portfolio/common";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";


export const fieldSpecs = {
    portfolio_id: {
        parse: (v) => v == null ? null : parseInt(v)
    },
}


export const columns = [
    CompanyLinkFromSecurityListing,
    {
        key: 'first_trade_date',
        name: 'First Trade Date',
        formatter: DateFormatter,
        width: 90,
        sortable: true,
    },
    {
        key: 'last_valuation_date',
        name: 'Valuation Date / Close Date',
        formatter: DateFormatter,
        width: 90,
        sortable: true,
    },

    {
        key: 'is_open_position',
        name: 'Is Open Position?',
        formatter: BoolToYesCheckFormatter,
        width: 70,
        sortable: true,
    },
    // {
    //     key: 'is_long',
    //     name: 'Is Long?',
    //     formatter: BoolToYesNoFormatter,
    //     width: 70,
    //     sortable: true,
    // },
    {
        key: '_relative_weight',
        name: 'Relative Gain',
        formatter: RelativeWeightFormatter,
        width: 90,
    },

    {
        key: 'total_gain_usd',
        name: 'Total Gain $kUSD',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000(r.total_gain_usd)
        },
        sortable: true,
    },
    {
        key: 'total_dividends_usd',
        name: 'Total Dividends $kUSD',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000(r.total_dividends_usd)
        },
        sortable: true,
    },
    {
        key: 'total_gain_percent_usd',
        name: 'Total Gain %',
        formatter: PercentGainOneDecimalFormatter,
        width: 85,
        sortable: true,
    },
    // {
    //     key: 'deannualized_irr_usd',
    //     name: 'IRR (not annualized)',
    //     formatter: PercentOneDecimalNoNullFormatter,
    //     sortable: true,
    //     width: 90,
    // },

    {
        key: 'realized_capital_gain_usd',
        name: 'Realized Cap Gain $mUSD',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000(r.realized_capital_gain_usd)
        },
        sortable: true,
    },
    {
        key: 'unrealized_capital_gain_usd',
        name: 'Unrealized Gain $mUSD',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000(r.unrealized_capital_gain_usd)
        },
        sortable: true,
    },

    {
        key: 'realized_capital_gain_percent_usd',
        name: 'Realized Cap Gain %',
        formatter: PercentGainsFormatter,
        width: 85,
        sortable: true,
    },
    {
        key: 'unrealized_capital_gain_percent_usd',
        name: 'Unrealized Gain %',
        formatter: PercentGainsFormatter,
        width: 85,
        sortable: true,
    },

    {
        key: 'total_cost_basis_usd',
        name: 'Total Cost Basis $kUSD',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000(r.total_cost_basis_usd)
        },
        sortable: true,
    },
    {
        key: 'realized_cost_basis_usd',
        name: 'Realized Cost Basis $kUSD',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000(r.realized_cost_basis_usd)
        },
        sortable: true,
    },
    {
        key: 'unrealized_cost_basis_usd',
        name: 'Unrealized Cost Basis $kUSD',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000(r.unrealized_cost_basis_usd)
        },
        sortable: true,
    },
    {
        key: 'total_cost_basis_per_share_trading',
        name: 'Total Cost $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 90,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.total_cost_basis_per_share_trading,
                trading_currency: r.security_listing ? r.security_listing.trading_currency : null
            }
        },
        sortable: true,
    },
    {
        key: 'realized_cost_basis_per_share_trading',
        name: 'Realized Cost $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 90,
        _getValueFromRecord: (r) => {

            return {
                price_per_share: r.realized_cost_basis_per_share_trading,
                trading_currency: r.security_listing ? r.security_listing.trading_currency : null
            }
        },
        sortable: true,
    },
    {
        key: 'unrealized_cost_basis_per_share_trading',
        name: 'Unrealized Cost $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 90,
        _getValueFromRecord: (r) => {

            return {
                price_per_share: r.unrealized_cost_basis_per_share_trading,
                trading_currency: r.security_listing ? r.security_listing.trading_currency : null
            }
        },
        sortable: true,
    },
    {
        key: 'realized_capital_value_per_share_trading',
        name: 'Realized Sale $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 90,
        _getValueFromRecord: (r) => {

            return {
                price_per_share: r.realized_capital_value_per_share_trading,
                trading_currency: r.security_listing ? r.security_listing.trading_currency : null
            }
        },
        sortable: true,
    },
    {
        key: 'unrealized_capital_value_per_share_trading',
        name: 'Unrealized $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 90,
        _getValueFromRecord: (r) => {

            return {
                price_per_share: r.unrealized_capital_value_per_share_trading,
                trading_currency: r.security_listing ? r.security_listing.trading_currency : null
            }
        },
        sortable: true,
    },

    {
        key: 'total_net_shares',
        name: 'Total Shares',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.total_net_shares
        },
        sortable: true,
    },
    {
        key: 'realized_net_shares',
        name: 'Realized Shares',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.realized_net_shares
        },
        sortable: true,
    },
    {
        key: 'unrealized_net_shares',
        name: 'Unrealized Shares',
        formatter: NoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.unrealized_net_shares
        },
        sortable: true,
    },
    {
        key: 'security_listing_id',
        name: 'Security Listing ID',
        width: 90,
        sortable: true,
    },
    {
        key: 'id',
        name: 'ID',
        width: 90,
    },

]