import React from 'react';
import {
    DateFormatter,
    NoDecimalNoNullOrZeroFormatter,
    SharePriceFormatter,
    PercentNoDecimalNoNullFormatter,
    BoolToYesNoCheckFormatter, NoDecimalNoNullFormatter, BoolToYesNoFormatter
} from "../../../common/reactDataGrid/formatters";
import {boolOptions} from "../../../common/reactDataGrid/common";
import {Editors} from 'react-data-grid-addons';
import {IsEditableFieldRenderer} from "../../../components/rdg/rdgComponents";

const {DropDownEditor} = Editors;



export const columns = [
    {
        key: 'id',
        name: 'ID',
        width: 80
    },
    {
        key: 'ciq_transaction_id',
        name: 'CIQ Transaction ID',
        width: 130,
        editable: true
    },
    {
        key: 'is_validated',
        name: 'Is Validated?',
        width: 70,
        formatter: BoolToYesNoCheckFormatter,
        editor: <DropDownEditor options={boolOptions}/>,
        editable: true,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'is_custom',
        name: 'Is Custom?',
        width: 70,
        formatter: BoolToYesNoFormatter,
    },
    {
        key: 'is_ipo',
        name: 'Is IPO?',
        width: 70,
        formatter: BoolToYesNoFormatter,
        editor: <DropDownEditor options={boolOptions}/>,
        editable: true,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'announce_date',
        name: 'Announce Date',
        width: 100,
        formatter: DateFormatter,
        editable: true,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'close_date',
        name: 'Close Date',
        width: 100,
        formatter: DateFormatter,
        editable: true,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'primary_features',
        name: 'Features',
        width: 250,
    },
    {
        key: 'currency',
        name: 'Currency',
        width: 70,
    },
    {
        key: 'shares_offered',
        name: 'Shares Offered (thousands)',
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter,
        editable: true,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'price_per_share',
        name: 'Price per Share',
        width: 100,
        formatter: SharePriceFormatter,
        editable: true,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'gross_proceeds',
        name: 'Gross Proceeds ($thousands)',
        width: 100,
        formatter: NoDecimalNoNullFormatter,
    },
    {
        key: 'percent_primary',
        name: '% Primary',
        width: 80,
        formatter: PercentNoDecimalNoNullFormatter,
        editable: true,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'net_primary_proceeds',
        name: 'Net Primary Proceeds ($thousands)',
        width: 100,
        formatter: NoDecimalNoNullFormatter,
        editable: true
    },
    {
        key: 'shares_post_offering',
        name: 'Proforma Shares Post-Offering (thousands)',
        width: 100,
        formatter: NoDecimalNoNullFormatter,
        editable: true
    },
    {
        key: 'unvested_rsus_post_offering',
        name: 'Proforma RSUs Post-Offering (thousands)',
        width: 100,
        formatter: NoDecimalNoNullFormatter,
        editable: true
    },
    {
        key: 'unvested_rs_post_offering',
        name: 'Proforma Restr. Stock Post-Offering (thousands)',
        width: 100,
        formatter: NoDecimalNoNullFormatter,
        editable: true
    },
    {
        key: 'options_post_offering',
        name: 'Proforma Options Post-Offering (thousands)',
        width: 100,
        formatter: NoDecimalNoNullFormatter,
        editable: true
    },
    {
        key: 'options_weighted_average_strike_price_post_offering',
        name: 'Proforma Options Strike Price',
        width: 100,
        formatter: SharePriceFormatter,
        editable: true
    },
    {
        key: 'cash_post_offering',
        name: 'Proforma Cash Post-Offering (optional)',
        width: 100,
        formatter: NoDecimalNoNullFormatter,
        editable: true
    },
    {
        key: 'non_convertible_debt_post_offering',
        name: 'Proforma non-convert debt (optional)',
        width: 100,
        formatter: NoDecimalNoNullFormatter,
        editable: true
    },
    {
        key: 'convertible_debt_post_offering',
        name: 'Proforma convertible debt (optional)',
        width: 100,
        formatter: NoDecimalNoNullFormatter,
        editable: true
    },
    {
        key: 'security_listing_id',
        name: 'Security Listing ID',
        width: 80,
        editable: true
    },
    {
        key: 'share_class_security_id',
        name: 'Security ID',
        width: 80,
        editable: true
    }





];

