import React from 'react';
import App from "./App";
import { useAuth0 } from "./auth/react-auth0-wrapper";
import { Dimmer, Loader } from 'semantic-ui-react'


// my hack
// cannot use auth0 components like PrivateRoute until auth0 is loaded
// therefore need a top level switch to track this


const AppWrapper = () => {
    const { loading, user } = useAuth0();

    if (loading) {
        return (
            <div>
                <Dimmer active>
                    <Loader/>
                </Dimmer>
            </div>)
    }

    return <App user={user}/>
};

// client={feathersClient}

export default AppWrapper
