import React from 'react';
import {useAuth0} from "./react-auth0-wrapper";
import {Button, Menu} from 'semantic-ui-react';


const LoginNavBar = () => {
  const {isAuthenticated, loginWithRedirect, logout} = useAuth0();

  return (
      <Menu.Item>
        {!isAuthenticated && (
            <Button primary
                onClick={() =>
                    loginWithRedirect({})}

            >
              Log in
            </Button>
        )}

        {isAuthenticated && <Button primary onClick={() => logout()}>Log out</Button>}
      </Menu.Item>
  )
};

export default LoginNavBar