import client from '../benchClient';

const companiesService = client.service('companies');
const companiesFacetsService = client.service('search/facets/companies');


export function fetchCompaniesForTags({dispatch, sort, filters}) {

    return dispatch({
        type: 'FETCH_COMPANIES_FOR_TAGS',
        payload: companiesService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    });

}


export function patchCompany({dispatch, id, patchData}) {

    return dispatch({
        type: 'PATCH_COMPANY',
        payload: companiesService.patch(id, patchData)
    });

}


export function fetchFacetsForTags({dispatch}) {
    return dispatch({
        type: 'FETCH_COMPANY_FACETS_FOR_TAGS',
        payload: companiesFacetsService.find()
    });

}
