import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic, Table} from 'semantic-ui-react'

import {formatNoDecimalNoNull, formatPercentOneDecimalNoNull} from "../../common/format";


export default ({portfolioState}) => {

    const rows = [
        {
            field: 'Long Securities',
            value: portfolioState.securities_long_market_value,
            percent: portfolioState.securities_long_market_value_percent
        },
        {
            field: 'Short Securities',
            value: portfolioState.securities_short_market_value,
            percent: portfolioState.securities_short_market_value_percent
        },
        {
            field: 'Net Securities',
            value: portfolioState.securities_net_market_value,
            percent: portfolioState.securities_net_market_value_percent
        },
        {
            field: 'Cash (net of short collateral)',
            value: portfolioState.cash_unencumbered,
            percent: portfolioState.cash_unencumbered_percent
        },
        {
            field: 'Cash (short collateral)',
            value: portfolioState.cash_encumbered,
        },
        {
            field: 'Net Asset Value',
            value: portfolioState.net_asset_value,
        },

    ].map(row => {
        return (
            <Table.Row key={row.field}>
                <Table.Cell textAlign={'left'}>
                    {row.field}
                </Table.Cell>
                <Table.Cell>
                    {formatNoDecimalNoNull(row.value)}
                </Table.Cell>
                <Table.Cell>
                    {formatPercentOneDecimalNoNull(row.percent)}
                </Table.Cell>
            </Table.Row>
        )
    });


    return (
        <React.Fragment>
            <Divider horizontal>Portfolio</Divider>
            <Table compact collapsing basic={'very'} textAlign={'right'}>
                <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>

                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                $
                            </Table.HeaderCell>
                            <Table.HeaderCell>%</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>

            </Table>

        </React.Fragment>

    )
}