import React from 'react'
import {
    formatFullDate,
    formatNoDecimal,
    formatNoDecimalNoNullOrZero,
    formatPercentNoNull,
    formatPercentOneDecimalNoNull,
    formatMultipleTwoDecimals
} from "../../../common/format";
import {createRow, LeftAlignHeaderRenderer, HeaderContentRenderer} from "../../../components/rdg/helpers";
import {ModelRowRenderer} from "../../../components/rdg/rdgComponents";
import {getWindowHeightLessMenuBar} from "../../../common/common";
import ReactDataGrid from 'react-data-grid';
import {createHeaderRow, createSectionHeaderRow, emptyRow} from '../../company/historicalFinancials/common'
import {
    functionalOpexMarginSpecs,
    functionalOpexSpecs,
    getCostDetailType,
    getNonNullCompositionFieldNames,
    hasGrossProfit,
    sgaMarginSpecs,
    sgaSpecs
} from '../../../components/companyValuation/financials/common'
import {
    GrossMarginCompositionMappingByDbField,
    GrossProfitCompositionMappingByDbField,
    RevenueCompositionMappingByDbField,
    SourceType
} from '../../../common/constants'
import {getTableClassName} from "../../../common/reactDataGrid/common";


const rowHeaderWidth = 200;
const cellWidth = 85;


export default ({financialRecords, loading}) => {

    const emptyRow = () => {
        return {}
    };

    const periodRow = [
        {
            key: 'rowHeader',
            name: 'Period End',
            width: rowHeaderWidth,
            frozen: true,
            headerRenderer: <LeftAlignHeaderRenderer/>
        }
    ].concat(
        financialRecords.map(record => {


            return {
                key: record.period_end_date,
                name: formatFullDate(record.period_end_date, true),
                width: cellWidth,
                headerRenderer: <HeaderContentRenderer/>
            }

        })
    );

    let dataRows = [];

    const revenueCompositionFields = getNonNullCompositionFieldNames(financialRecords, RevenueCompositionMappingByDbField);
    const revenueCompositionRows = revenueCompositionFields.map(dbFieldName => {
        return createRow({
            headerText: RevenueCompositionMappingByDbField[dbFieldName],
            indent: 1,
            records: financialRecords,
            getCellValue: record => record[dbFieldName],
            formatValue: formatNoDecimal
        });
    });

    const revenueTotalRow = createRow({
        headerText: 'Revenue',
        records: financialRecords,
        getCellValue: record => record.revenue_total,
        underline: true
    });

    const costDetailType = getCostDetailType(financialRecords);
    const grossProfitCompositionFields = getNonNullCompositionFieldNames(financialRecords, GrossProfitCompositionMappingByDbField);
    const grossMarginCompositionFields = getNonNullCompositionFieldNames(financialRecords, GrossMarginCompositionMappingByDbField);

    const grossProfitCompositionRows = grossProfitCompositionFields.map(dbFieldName => {
        return createRow({
            headerText: GrossProfitCompositionMappingByDbField[dbFieldName],
            indent: 1,
            records: financialRecords,
            getCellValue: record => record[dbFieldName],
            formatValue: formatNoDecimal
        });
    });

    const grossProfitTotalRow = createRow({
        headerText: 'Gross Profit',
        records: financialRecords,
        getCellValue: record => record.gross_profit_total,
        underline: true
    });

    const opexRows = functionalOpexSpecs.map(spec => {

        return createRow({
            records: financialRecords,
            headerText: spec.displayName,
            indent: 1,
            getCellValue: record => record[spec.field]
        });

    });

    const grossMarginCompositionRows = grossMarginCompositionFields.map(dbFieldName => {

      return createRow({
        headerText: GrossMarginCompositionMappingByDbField[dbFieldName],
        indent: 1,
        records: financialRecords,
        getCellValue: record => record[dbFieldName],
        formatValue: formatPercentNoNull
      });
    });

    const grossMarginTotalRow = {
      headerText: 'Gross Margin',
      records: financialRecords,
      getCellValue: record => record.gross_margin,
      formatValue: formatPercentNoNull
    };

    if (grossProfitCompositionRows.length) {
      grossMarginTotalRow.underline = true;
    }

    dataRows = dataRows.concat(
        [
            createHeaderRow({headerText: 'Revenue'})
        ],
        revenueCompositionRows,
        [
            revenueTotalRow,
            emptyRow()
        ],
        [
            createHeaderRow({headerText: 'Gross Profit'})
        ],
        grossProfitCompositionRows,
        [
            grossProfitTotalRow,
        ],
        opexRows,
        [
            createRow({
                headerText: 'EBITDA',
                records: financialRecords,
                getCellValue: record => record.ebitda,
                underline: true
            }),
            createRow({
                headerText: 'Capital Expenditures',
                records: financialRecords,
                getCellValue: record => record.capital_expenditures,
                indent: 1
            }),
            createRow({
                headerText: 'Pretax FCF',
                records: financialRecords,
                getCellValue: record => record.pretax_free_cash_flow,
                underline: true
            }),
            createRow({
                headerText: 'Change in Deferred Rev.',
                records: financialRecords,
                getCellValue: record => record.change_in_deferred_revenue,
                indent: 1
            }),
            createRow({
                headerText: 'Pretax FCF on Billings',
                records: financialRecords,
                getCellValue: record => record.pretax_free_cash_flow_on_billings,
                underline: true
            }),
            emptyRow(),

            createHeaderRow({headerText: 'Recurring Growth Metrics'}),
            createRow({
                headerText: '% Recurring',
                records: financialRecords,
                getCellValue: record => record.revenue_recurring_percent,
                formatValue: formatPercentNoNull
            }),
            createRow({
                headerText: 'Recurring Growth',
                records: financialRecords,
                getCellValue: record => record.revenue_recurring_growth,
                formatValue: formatPercentOneDecimalNoNull
            }),
            createRow({
                headerText: 'Recurring Dollar Growth',
                records: financialRecords,
                getCellValue: record => record.revenue_recurring_dollar_growth,
                formatValue: formatNoDecimalNoNullOrZero
            }),

            emptyRow(),

            createHeaderRow({headerText: 'Total Rev Growth Metrics'}),
            createRow({
                headerText: 'Revenue Growth',
                records: financialRecords,
                getCellValue: record => record.revenue_growth,
                formatValue: formatPercentOneDecimalNoNull
            }),
            createRow({
                headerText: 'Revenue Dollar Growth',
                records: financialRecords,
                getCellValue: record => record.revenue_dollar_growth,
                formatValue: formatNoDecimalNoNullOrZero
            }),
            emptyRow(),
            createHeaderRow({headerText: 'Margins'}),
            ...grossMarginCompositionRows,
            createRow(grossMarginTotalRow),
            ...functionalOpexMarginSpecs.map(spec => {
                return createRow({
                    records: financialRecords,
                    headerText: spec.displayName,
                    indent: 1,
                    getCellValue: record => record[spec.field],
                    formatValue: formatPercentNoNull
                });
            }),
            createRow({
                headerText: 'EBITDA',
                records: financialRecords,
                getCellValue: record => record.ebitda_margin,
                formatValue: formatPercentNoNull,
                underline: true
            }),

            emptyRow(),
            createSectionHeaderRow({headerText: 'Retention'}),
            emptyRow(),
            createRow({
                headerText: 'LTM Gross Retention',
                records: financialRecords,
                getCellValue: record => record.gross_retention_ltm,
                formatValue: formatPercentOneDecimalNoNull
            }),
            createRow({
                headerText: 'LTM Net Retention',
                records: financialRecords,
                getCellValue: record => record.net_retention_ltm,
                formatValue: formatPercentOneDecimalNoNull
            }),

            emptyRow(),
            createSectionHeaderRow({headerText: 'Bookings'}),
            emptyRow(),
            createRow({
                headerText: 'Bookings Total',
                records: financialRecords,
                getCellValue: record => record.bookings_total,
                formatValue: formatNoDecimalNoNullOrZero
            }),
            emptyRow(),
            createRow({
                headerText: 'Recurring Bookings',
                records: financialRecords,
                getCellValue: record => record.bookings_recurring,
                formatValue: formatNoDecimalNoNullOrZero
            }),
            emptyRow(),
            createRow({
                headerText: 'Bookings / S&M',
                records: financialRecords,
                getCellValue: record => record.bookings_to_sales_and_marketing,
                formatValue: formatMultipleTwoDecimals
            }),
            createRow({
                headerText: 'Recurring Bookings / S&M',
                records: financialRecords,
                getCellValue: record => record.bookings_recurring_to_sales_and_marketing,
                formatValue: formatMultipleTwoDecimals
            }),

            emptyRow(),
            createSectionHeaderRow({headerText: 'Budget'}),
            emptyRow(),
            createRow({
                headerText: 'Budget Revenue Total',
                records: financialRecords,
                getCellValue: record => record.budget_revenue_total,
                formatValue: formatNoDecimalNoNullOrZero
            }),
            emptyRow(),
            createRow({
                headerText: 'Budget EBITDA',
                records: financialRecords,
                getCellValue: record => record.budget_ebitda,
                formatValue: formatNoDecimalNoNullOrZero
            }),
            emptyRow(),
            createRow({
                headerText: 'Budget Bookings Total',
                records: financialRecords,
                getCellValue: record => record.budget_bookings_total,
                formatValue: formatNoDecimalNoNullOrZero
            }),
            emptyRow(),


            createSectionHeaderRow({headerText: 'Balance Sheet'}),
            emptyRow(),
            createRow({
                headerText: 'Cash',
                records: financialRecords,
                getCellValue: record => record.cash,
                formatValue: formatNoDecimalNoNullOrZero
            }),
            createRow({
                headerText: 'Debt',
                records: financialRecords,
                getCellValue: record => record.debt,
                formatValue: formatNoDecimalNoNullOrZero
            }),
            emptyRow(),
            createRow({
                headerText: 'Accounts Receivable',
                records: financialRecords,
                getCellValue: record => record.accounts_receivable,
                formatValue: formatNoDecimalNoNullOrZero
            }),
            createRow({
                headerText: 'Accounts Payable',
                records: financialRecords,
                getCellValue: record => record.accounts_payable,
                formatValue: formatNoDecimalNoNullOrZero
            }),



        ]
    );

    return (
        <div className={getTableClassName(loading) + ' align-right'}>

            <ReactDataGrid
                columns={periodRow}
                rowGetter={index => dataRows[index]}
                rowsCount={dataRows.length}
                rowRenderer={ModelRowRenderer}
                rowHeight={22}
                headerRowHeight={30}
                minHeight={getWindowHeightLessMenuBar()}

            />

        </div>

    )


}