import React, {Component} from 'react'
import ReactDataGrid from 'react-data-grid';
import {
  StringFormatter,
  NoDecimalNoNullOrZeroFormatter, UtcToLocalFullDateTimeFormatter
} from '../../../common/reactDataGrid/formatters';
import {getWindowHeightLessMenuBar} from '../../../common/common'
import {
  getMostRecentSortColumnAndDirectionForRDG,
  getRdgRowsFromRecords,
  makeHandleReactDataGridSort
} from '../../../common/reactDataGrid/common'
import {parseSearchProps} from "../../../common/query/parse";
import {createAppendSortToQuery, createSetFieldValuesOnQuery} from "../../../common/query/add";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {Button, Icon} from 'semantic-ui-react'
import AssociateModal from './AssociateModal'
import {convertQuerySortToFeathersSort} from "../../../common/request/convert";
import {setRecordBeingAssociated, associateDescriptionWithRole} from '../../../actions/employees/associationActions'
import {fetchRolesForScrapeId} from "../../../actions/employees/roleActions";
import {fieldSpecs} from './common'



class EmployeeLinkFormatter extends React.Component {
  render() {
    if (!this.props.value.linkedin_id) {
      return <span color='grey'>(Anonymous)</span>
    }
    return <a href={"http://www.linkedin.com" + this.props.value.linkedin_id}>{this.props.value.name}</a>
  }
}


class AssociateButtonFormatter extends React.Component {
  render() {

    const {history, searchProps, record, loadRoles, setRecordBeingAssociated, lastAssociatedRole, refresh, associateDescriptionWithRole, loading} = this.props.value;

    const openAssociateModal = createSetFieldValuesOnQuery({history, searchProps, changes: {
      associateModalOpen: true, modalType: 'single'}});

    const openAssociateDescriptionModal = createSetFieldValuesOnQuery({history, searchProps, changes: {
      associateModalOpen: true, modalType: 'description'}});

    const onClickAssociate = () => {
      setRecordBeingAssociated(record);
      openAssociateModal();
      loadRoles();
    };

    const onClickAssociateDescription = () => {
      setRecordBeingAssociated(record);
      openAssociateDescriptionModal();
      loadRoles();
    };

    let lastButton = null;
    if (lastAssociatedRole != null) {

      let text = lastAssociatedRole.name;
      if (text.length > 16) {
        text = text.slice(0, 16) + "..."
      }

      const onClick = () => {
        associateDescriptionWithRole({roleId: lastAssociatedRole.id, employeeId: record.id}).then(response => {refresh();});
      };

      lastButton = (
        <Button size="tiny" color="teal" loading={loading} onClick={onClick}>
          <Icon name='redo'/>
          {text}
        </Button>
      )
    }

    return (
      <div>
        <Button size="tiny" color="green" icon onClick={onClickAssociate}>
          <Icon name='exchange'/>
          Associate
        </Button>
        <Button size="tiny" color="blue" onClick={onClickAssociateDescription}>
          <Icon name='sort amount down'/>
          Description
        </Button>
        {lastButton}
      </div>
      )

  }
}




class EmployeeRecordTable extends Component {

  loadRoles = () => {

    const matchParams = this.props.match.params;

    // always sort a specific way for this modal
    const rawSort = [
      ["name", "1"],
      ["minor_cost_center", "1"],
      ["major_cost_center", "1"]
    ];

    const {scrape_id} = matchParams;
    const sort = convertQuerySortToFeathersSort(rawSort);
    const filters = {};

    this.props.fetchRolesForScrapeId({sort, filters, scrapeId: scrape_id});

  };

  render() {
    const history = this.props.history;
    const searchProps = parseSearchProps({searchString: this.props.location.search, fieldSpecs});
    const records = this.props.records;

    const onChangeSort = createAppendSortToQuery({history, searchProps});
    const handleReactDataGridSort = makeHandleReactDataGridSort(onChangeSort);
    const {sortColumn, sortDirection} = getMostRecentSortColumnAndDirectionForRDG(searchProps);

    let columns = [
      {
        key: 'name',
        name: 'Name',
        width: 150,
        frozen: true,
        sortable: true,
        formatter: EmployeeLinkFormatter,
        _getValueFromRecord: (r) => {
          return {
            linkedin_id: r.linkedin_id,
            name: r.name
          }
        }
      },
      {
        key: 'description',
        name: 'Description',
        sortable: true,
        width: 400,
        formatter: StringFormatter
      },
      {
        key: 'description_occurrence_count',
        name: 'Desc. Count',
        sortable: true,
        width: 80,
        formatter: NoDecimalNoNullOrZeroFormatter
      },
      {
        key: 'location',
        name: 'Location',
        sortable: true,
        width: 300,
        formatter: StringFormatter
      },
      {
        key: 'role.name',
        name: 'Associated Role',
        sortable: true,
        width: 150,
        _getValueFromRecord: (r) => {

          if (r.role == null) {
            return ''
          }
          return r.role.name
        }
      },
      {
        key: 'associate',
        name: 'Actions',
        sortable: false,
        width: 400,
        formatter: AssociateButtonFormatter,
        _getValueFromRecord: (r) => {
          return {
            history,
            searchProps,
            record: r,
            setRecordBeingAssociated: this.props.setRecordBeingAssociated,
            loadRoles: this.loadRoles,
            lastAssociatedRole: this.props.lastAssociatedRole,
            refresh: this.props.refresh,
            associateDescriptionWithRole: this.props.associateDescriptionWithRole,
            loading: this.props.loadingAssociation
          }
        }
      },
      {
        key: 'date_associated',
        name: 'Date Associated',
        sortable: true,
        width: 150,
        formatter: UtcToLocalFullDateTimeFormatter
      }


    ];

    if (! searchProps.showLocation) {
      columns = columns.filter(c => c.key !== 'location')
    }
    if (! searchProps.showDateAssociated) {
      columns = columns.filter(c => c.key !== 'date_associated')
    }


    const rows = getRdgRowsFromRecords({records: records, columns: columns});

    let className = 'financials-size';
    if (this.props.loading) {
      className += ' loading-grid'
    }

    return (

      <div className={className}>
        <AssociateModal refresh={this.props.refresh}/>
        <ReactDataGrid
          columns={columns}
          onGridSort={handleReactDataGridSort}
          rowGetter={index => rows[index]}
          rowsCount={rows.length}
          rowHeight={22}
          headerRowHeight={50}
          minHeight={getWindowHeightLessMenuBar()}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />

      </div>

    )
  }

}


function mapStateToProps(state) {
  return {
    records: state.employeeRecordStore.employeeRecords,
    loading: state.employeeRecordStore.loadingEmployeeRecords,
    lastAssociatedRole: state.associationStore.lastAssociatedRole,
    loadingAssociation: state.associationStore.loadingAssociation
  }
}


export default withRouter(connect(mapStateToProps,
  {fetchRolesForScrapeId, setRecordBeingAssociated, associateDescriptionWithRole})(EmployeeRecordTable));



