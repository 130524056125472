export const grossRetentionOptions = [
    {
        key: '',
        value: '',
        text: ''
    },
    ...[...Array(101).keys()].map(key => {
        return {
            key: key,
            value: key / 100,
            text: key + "%"
        }
    })
];

export const netRetentionOptions = [
    {
        key: '',
        value: '',
        text: ''
    },
    ...[...Array(201).keys()].map(key => {
        return {
            key: key,
            value: key / 100,
            text: key + "%"
        }
    })
];


export const getRoundedPercentageTextFromFloat = (percentage) => {

    if (percentage == null) {
        return ''
    }

    return Math.round(percentage * 100)
};


export const getFloatFromPercentageText = (text) => {

    if (text === '') {
        return null
    }

    return parseFloat(text) / 100

};

