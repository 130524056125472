import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Table} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {createAddValueToQuery, createSetFieldValuesOnQuery} from "../../../common/query/add";
import {fieldSpecs, columns} from './common'
import {useReloadOnChange} from "../../../common/hooks";
import {
    fetchCurrentPortfolioState, fetchLiveAndBacktestPortfolios, fetchPortfolios,
    fetchPortfoliosExMembers,
    fetchSecurityInvestments
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfolioStateTable from "../../../components/portfolio/PortfolioStateTable";
import RdgTable from "../../../components/rdg/RdgTable";
import {sortFunctional} from "../../../common/tableHelpers";
import {formatDateTimeUtcToLocal, formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import {formatNoDecimalNoNull, formatPercentOneDecimalNoNull} from "../../../common/format";
import {PricePercentChangeOneDecimalFormatter} from "../../../common/reactDataGrid/formatters";
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from "../../../common/request/convert";
import {sumArray} from "../../../common/common";
import qs from 'qs';
import {getPortfolioOptionText} from "../../../common/portfolio";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        portfolios,
        loadingPortfolios,

        currentPortfolioState,
        loadingCurrentPortfolioState,

        securityInvestments,
        loadingSecurityInvestments,

    } = useSelector(state => state.portfolio, shallowEqual);

    useEffect(() => {
        dispatch(fetchLiveAndBacktestPortfolios())
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['portfolio_id', 'sort', 'filters'],
        requestData: loadCurrentPortfolioState
    });

    if (searchProps.portfolio_id == null) {
        if (!loadingPortfolios && portfolios.length > 0) {

            searchProps.portfolio_id = portfolios[0].id;
            const url = location.pathname + qs.stringify(searchProps, {addQueryPrefix: true})
            return <Redirect to={url}/>

        }
    }

    const portfolioOptions = portfolios.map(p => {
        return {
            value: p.id,
            text: getPortfolioOptionText(p),
            key: p.id
        }
    })

    // may be null if not yet loaded
    const report = currentPortfolioState.portfolio_state_report;
    const totalReturn = report != null ? report.time_weighted_return : null;


    //  add percent of max to records

    const maxGain = Math.max(...securityInvestments.map(s => Math.abs(s.total_gain_usd)));

    const modifiedSecurityInvestments = securityInvestments.map(s => {
        return {
            ...s,
            _relative_weight: s.total_gain_usd / maxGain
        }
    })

    const longRecords = sortFunctional({
        array: modifiedSecurityInvestments.filter(r => r.is_long === true),
        sortKey: 'market_value',
        sortDirection: -1
    });
    const shortRecords = sortFunctional({
        array: modifiedSecurityInvestments.filter(r => r.is_long !== true),
        sortKey: 'market_value',
        sortDirection: 1
    });

    const longRealizedGain = sumArray(longRecords.map(r => r.realized_capital_gain_usd));
    const longUnrealizedGain = sumArray(longRecords.map(r => r.unrealized_capital_gain_usd));

    const shortRealizedGain = sumArray(shortRecords.map(r => r.realized_capital_gain_usd));
    const shortUnrealizedGain = sumArray(shortRecords.map(r => r.unrealized_capital_gain_usd));

    const sidebar = (
        <div>
            <br/>
            <Form>
                <Form.Select
                    fluid
                    inline
                    options={portfolioOptions}
                    onChange={
                        (e, d) => {
                            createSetFieldValuesOnQuery({
                                history,
                                searchProps,
                                changes: {
                                    portfolio_id: d.value,
                                    // portfolio_state_id: null
                                }
                            })()
                        }
                    }
                    value={searchProps.portfolio_id}
                    label="Portfolio"
                />
            </Form>
            <Table compact collapsing basic={'very'}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            As of Date:
                        </Table.Cell>
                        <Table.Cell>
                            {formatFullDateTimeUtcToLocal(currentPortfolioState.as_of_datetime_utc)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Is End of Day:
                        </Table.Cell>
                        <Table.Cell>
                            {currentPortfolioState.is_end_of_day ? 'Yes' : 'No'}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <PortfolioStateTable portfolioState={currentPortfolioState}/>
            <Divider horizontal>Security Investment Totals</Divider>
            <Table compact collapsing basic={'very'}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Long Realized Cap Gain
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(longRealizedGain)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Long Unrealized Cap Gain
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(longUnrealizedGain)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Long Total Cap Gain
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(longUnrealizedGain + longRealizedGain)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Short Realized Cap Gain
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(shortRealizedGain)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Short Unrealized Cap Gain
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(shortUnrealizedGain)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Short Total Cap Gain
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(shortUnrealizedGain + shortRealizedGain)}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>



        </div>
    );



    const rows = [
        {
            _name: 'Long',
            _isHeader: true,
            security_investment: {}
        },
        ...longRecords,
        {
            _name: '',
            _isHeader: true,
            security_investment: {}
        },
        {
            _name: 'Short',
            _isHeader: true,
            security_investment: {}
        },
        ...shortRecords,


    ];

    return (
        <ViewWithSidebar mainView={<RdgTable records={rows}
                                             loading={loadingSecurityInvestments}
                                             columns={columns}

        />}

                         sidebarView={sidebar}/>
    )

}


const loadCurrentPortfolioState = ({dispatch, searchProps}) => {

    const portfolio_id = searchProps.portfolio_id;

    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    if (portfolio_id != null) {
        dispatch(fetchCurrentPortfolioState({
            portfolioId: portfolio_id,
            isEndOfDay: true
        })).then((result) => {

            const portfolioState = result.data;

            dispatch(fetchSecurityInvestments({
                sort,
                filters: {
                    portfolio_state_id: portfolioState.id,
                    ...filters
                }
            }));
        });
    }

}

