import {targetPortfolioColumns} from "./targetPortfolioColumns";
import {realTradeOrdersColumns, tradeOrderColumns} from "./tradeOrderColumns";

export const fieldSpecs = {
    viewOption: {
        parse: (v) => v == null ? viewSpecs[0].name : v
    }
};

export const getOrderValueMultiplerWithDefault = (v) => {

    if (v == null) {
        return 1.0
    }

    v = parseFloat(v);

    if (isNaN(v)) {
        return 1.0
    }

    return v


}



export const viewSpecs = [
    {
        name: 'Target Portfolio',
        columns: targetPortfolioColumns
    },
    {
        name: 'Target Trades',
        columns: tradeOrderColumns
    },
    {
        name: 'Real Orders',
        columns: realTradeOrdersColumns
    },

];


export const getRecordsAndColumnsForView = ({viewOption, doradoTargetPortfolioPositions, doradoTargetSecurityOrders}) => {

    if (viewOption === 'Target Portfolio') {
        return [doradoTargetPortfolioPositions, targetPortfolioColumns]
    }

    if (viewOption === 'Real Orders') {

        const realOrders = doradoTargetSecurityOrders.map(r => {
            const realOrder = {
                ...r
            }

            if (realOrder._include_trade_order === undefined) {
                realOrder._include_trade_order = true;
            }

            return realOrder

        })

        return [realOrders, realTradeOrdersColumns]

    }

    return [doradoTargetSecurityOrders, tradeOrderColumns]

}


export const viewOptions = viewSpecs.map(spec => {
    return {
        key: spec.name,
        value: spec.name,
        text: spec.name
    }
});

