import {
    getValuationPremiumDetails
} from "../../../../components/companyFinancials/components";
import {getCostDetailType} from "../../../../components/companyValuation/financials/common";
import {
    cellWidth,
    createSectionHeaderRow,
    emptyRow,
    headerRowHeight, rowHeaderWidth,
    rowHeight
} from "../../../../components/companyFinancials/common";
import {getTableClassName} from "../../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {getWindowHeightLessMenuBar} from "../../../../common/common";
import React from "react";
import {ModelRowRenderer} from "../../../../components/rdg/rdgComponents";
import {createRow, HeaderContentRenderer, LeftAlignHeaderRenderer} from "../../../../components/rdg/helpers";
import {
    formatFullDate, formatMultipleOneDecimal, formatMultipleTwoDecimals, formatNoDecimal,
    formatOneDecimal, formatOneDecimalNoNull,
    formatPercentOneDecimalNoNull,

    formatSharePrice, formatTwoDecimalNoNull
} from "../../../../common/format";
import {createHeaderRow} from "../../historicalFinancials/common";

const labels = [
    'Entry',
    'Exit'
];

const columnHeaderFieldName = 'label';


const getEntryAndExitRecords = ({doradoValuationRecord, loading}) => {
    if (loading) {
        return []
    }

    const entryRecord = {
        label: 'Entry',
        date: doradoValuationRecord.as_of_date,

        share_price_trading: doradoValuationRecord.entry_price_per_share_trading,
        share_price_filing: doradoValuationRecord.entry_price_per_share_filing,

        basic_shares: doradoValuationRecord.entry_shares_ending,
        unvested_rsus: doradoValuationRecord.entry_unvested_rsus_ending,
        option_treasury_method_dilution: doradoValuationRecord.entry_option_treasury_method_dilution,
        convertible_debt_net_dilution: doradoValuationRecord.entry_convertible_debt_net_dilution,
        convertible_debt_conversion_price: doradoValuationRecord.entry_convertible_debt_conversion_price,

        diluted_shares: doradoValuationRecord.entry_diluted_shares,
        market_value: doradoValuationRecord.entry_market_value_filing,

        cash: doradoValuationRecord.entry_cash,

        convertible_debt: doradoValuationRecord.entry_convertible_debt,
        non_convertible_debt: doradoValuationRecord.entry_non_convertible_debt,

        other_bs: doradoValuationRecord.entry_other_bs,

        net_cash: doradoValuationRecord.entry_net_cash,
        enterprise_value: doradoValuationRecord.entry_ev_filing,

        ntm_revenue_total: doradoValuationRecord.entry_ntm_revenue_total,
        ntm_gross_profit_total: doradoValuationRecord.entry_ntm_gross_profit_total,
        ntm_ebitda: doradoValuationRecord.entry_ntm_ebitda,
        ntm_pretax_fcf: doradoValuationRecord.entry_ntm_pretax_fcf,
        ntm_max_pretax_fcf: doradoValuationRecord.entry_ntm_maximized_pretax_fcf,

        ev_to_revenue_total_ntm: doradoValuationRecord.entry_ev_to_revenue_total_ntm,
        ev_to_gross_profit_total_ntm: doradoValuationRecord.entry_ev_to_gross_profit_total_ntm,
        ev_to_ebitda_ntm: doradoValuationRecord.entry_ev_to_ebitda_ntm,
        ev_to_pretax_fcf_ntm: doradoValuationRecord.entry_ev_to_pretax_fcf_ntm,
        ev_to_max_pretax_fcf_ntm: doradoValuationRecord.entry_ev_to_maximized_pretax_fcf_ntm,

        entry_average_implemented_arr_to_sm: doradoValuationRecord.entry_average_implemented_arr_to_sm,
        years_in_trailing_implemented_arr_to_sm: doradoValuationRecord.years_in_trailing_implemented_arr_to_sm,

        entry_vs_exit_margin: doradoValuationRecord.entry_ntm_ebit_margin,



    };

    const exitRecord = {
        label: 'Exit',
        date: doradoValuationRecord.exit_date,

        share_price_trading: doradoValuationRecord.exit_price_per_share_trading,
        share_price_filing: doradoValuationRecord.exit_price_per_share_filing,

        basic_shares: doradoValuationRecord.exit_shares_ending,
        unvested_rsus: doradoValuationRecord.exit_unvested_rsus_ending,
        option_treasury_method_dilution: doradoValuationRecord.exit_option_treasury_method_dilution,
        convertible_debt_net_dilution: doradoValuationRecord.exit_convertible_debt_net_dilution,


        diluted_shares: doradoValuationRecord.exit_diluted_shares,
        market_value: doradoValuationRecord.exit_market_value_filing,
        net_cash: doradoValuationRecord.exit_net_cash,
        enterprise_value: doradoValuationRecord.exit_ev_filing,

        cash: doradoValuationRecord.exit_cash,
        convertible_debt: doradoValuationRecord.exit_convertible_debt,
        non_convertible_debt: doradoValuationRecord.exit_non_convertible_debt,

        other_bs: doradoValuationRecord.exit_other_bs,

        // returns
        return: doradoValuationRecord.total_gain,
        irr: doradoValuationRecord.irr,
        irr_years: doradoValuationRecord.irr_years,

        ntm_revenue_total: doradoValuationRecord.exit_ntm_revenue_total,
        ntm_gross_profit_total: doradoValuationRecord.exit_ntm_gross_profit_total,
        ntm_ebitda: doradoValuationRecord.exit_ntm_ebitda,
        ntm_pretax_fcf: doradoValuationRecord.exit_ntm_pretax_fcf,
        ntm_max_pretax_fcf: doradoValuationRecord.exit_ntm_maximized_pretax_fcf,

        ev_to_revenue_total_ntm: doradoValuationRecord.exit_ev_to_revenue_total_ntm,
        ev_to_gross_profit_total_ntm: doradoValuationRecord.exit_ev_to_gross_profit_total_ntm,
        ev_to_ebitda_ntm: doradoValuationRecord.exit_ev_to_ebitda_ntm,
        ev_to_pretax_fcf_ntm: doradoValuationRecord.exit_ev_to_pretax_fcf_ntm,
        ev_to_max_pretax_fcf_ntm: doradoValuationRecord.exit_ev_to_maximized_pretax_fcf_ntm,

        revenue_total_cagr: doradoValuationRecord.revenue_total_cagr,
        exit_vs_entry_revenue_total: doradoValuationRecord.exit_vs_entry_revenue_total,
        exit_vs_entry_ev_to_revenue_total: doradoValuationRecord.exit_vs_entry_ev_to_revenue_total,
        exit_vs_entry_diluted_shares: doradoValuationRecord.exit_vs_entry_diluted_shares,

        current_margin_percent_of_exit_max_margin: doradoValuationRecord.current_margin_percent_of_exit_max_margin,
        current_exec_score_percent_of_exit_max_margin: doradoValuationRecord.current_exec_score_percent_of_exit_max_margin,
        current_exec_score_percent_of_exit_exec_score: doradoValuationRecord.current_exec_score_percent_of_exit_exec_score,
        premium_score: doradoValuationRecord.premium_score,

        entry_vs_exit_margin: doradoValuationRecord.exit_maximized_pretax_fcf_margin_ntm,
        pre_discount_exit_ev_to_revenue_total_ntm: doradoValuationRecord.unaffected_exit_ev_to_revenue_total_ntm,
        exit_multiple_net_premium: doradoValuationRecord.exit_multiple_net_premium,

        cumulative_free_cash_flow: doradoValuationRecord.cumulative_free_cash_flow,

        cumulative_repurchase_spend: doradoValuationRecord.cumulative_repurchase_spend,
        cumulative_repurchase_shares: doradoValuationRecord.cumulative_repurchase_shares,
        cumulative_repurchase_price_filing: doradoValuationRecord.cumulative_repurchase_price_filing,

    };

    return [entryRecord, exitRecord]
};


export default ({company, doradoValuationRecord, loading}) => {

    const records = getEntryAndExitRecords({doradoValuationRecord, loading});

    const priceRows = [
        createRow({
            headerText: '$/share - trading',
            records: records,
            getCellValue: record => record.share_price_trading,
            formatValue: formatSharePrice,
            columnHeaderFieldName
        }),

    ];
    if (company.trading_currency !== company.filing_currency) {
        priceRows.push(createRow({
            headerText: '$/share - filing',
            records: records,
            getCellValue: record => record.share_price_filing,
            formatValue: formatSharePrice,
            columnHeaderFieldName
        }),)
    }


    const labelRow = [
        {
            key: 'rowHeader',
            name: '',
            width: rowHeaderWidth + 25,
            frozen: true,
            headerRenderer: <LeftAlignHeaderRenderer/>
        }
    ].concat(
        labels.map(label => {
            return {
                key: label,
                name: label,
                width: cellWidth + 10,
                headerRenderer: <HeaderContentRenderer/>
            }

        })
    );

    const dataRows = [
        emptyRow(),
        createRow({
            headerText: 'Date',
            records: records,
            getCellValue: record => record.date,
            formatValue: formatFullDate,
            columnHeaderFieldName
        }),
        emptyRow(),
        ...priceRows,
        createRow({
            headerText: 'Basic Shares',
            records: records,
            getCellValue: record => record.basic_shares,
            formatValue: formatNoDecimal,
            indent: 2,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Unvested RSUs',
            records: records,
            getCellValue: record => record.unvested_rsus,
            formatValue: formatNoDecimal,
            indent: 2,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Option Dilution',
            records: records,
            getCellValue: record => record.option_treasury_method_dilution,
            formatValue: formatNoDecimal,
            indent: 2,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net Convertible Debt Dilution',
            records: records,
            getCellValue: record => record.convertible_debt_net_dilution,
            formatValue: formatNoDecimal,
            indent: 2,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Diluted Shares',
            records: records,
            getCellValue: record => record.diluted_shares,
            formatValue: formatNoDecimal,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Market Value',
            records: records,
            getCellValue: record => record.market_value,
            formatValue: formatNoDecimal,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: '(-) Cash',
            records: records,
            getCellValue: record => record.cash,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: '(+) Convertible Debt',
            records: records,
            getCellValue: record => record.convertible_debt,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: '(+) Non-Convertible Debt',
            records: records,
            getCellValue: record => record.non_convertible_debt,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: '(-) Other Net Liabilities',
            records: records,
            getCellValue: record => record.other_bs,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: 'EV',
            records: records,
            getCellValue: record => record.enterprise_value,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            underline: true,
        }),
        createRow({
            headerText: 'Cumulative 5yr FCF',
            records: records,
            getCellValue: record => record.cumulative_free_cash_flow,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            indent: true,
        }),


        emptyRow(),
        createRow({
            headerText: 'NTM Revenue',
            records: records,
            getCellValue: record => record.ntm_revenue_total,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'NTM Gross Profit',
            records: records,
            getCellValue: record => record.ntm_gross_profit_total,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'NTM EBITDA',
            records: records,
            getCellValue: record => record.ntm_ebitda,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'NTM Pretax FCF',
            records: records,
            getCellValue: record => record.ntm_pretax_fcf,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'NTM Maximized Pretax FCF',
            records: records,
            getCellValue: record => record.ntm_max_pretax_fcf,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Entry EBIT vs Max Margin',
            records: records,
            getCellValue: record => record.entry_vs_exit_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),


        emptyRow(),

        createRow({
            headerText: 'Pre-Net Premium EV/NTM Revenue',
            records: records,
            getCellValue: record => record.pre_discount_exit_ev_to_revenue_total_ntm,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Net Valuation Premium',
            records: records,
            getCellValue: record => record.exit_multiple_net_premium,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),

        emptyRow(),

        createRow({
            headerText: 'EV/NTM Revenue',
            records: records,
            getCellValue: record => record.ev_to_revenue_total_ntm,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'EV/NTM Gross Profit',
            records: records,
            getCellValue: record => record.ev_to_gross_profit_total_ntm,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'EV/NTM EBITDA',
            records: records,
            getCellValue: record => record.ev_to_ebitda_ntm,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'EV/NTM Pretax FCF',
            records: records,
            getCellValue: record => record.ev_to_pretax_fcf_ntm,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'EV/NTM Max. Pretax FCF',
            records: records,
            getCellValue: record => record.ev_to_max_pretax_fcf_ntm,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName,
        }),


        emptyRow(),

        createRow({
            headerText: 'Return',
            records: records,
            getCellValue: record => record.return,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'IRR',
            records: records,
            getCellValue: record => record.irr,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'IRR Years',
            records: records,
            getCellValue: record => record.irr_years,
            formatValue: formatOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Growth from Entry to Exit'}),
        createRow({
            headerText: 'Revenue',
            records: records,
            getCellValue: record => record.exit_vs_entry_revenue_total,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'EV/Revenue',
            records: records,
            getCellValue: record => record.exit_vs_entry_ev_to_revenue_total,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Diluted Shares',
            records: records,
            getCellValue: record => record.exit_vs_entry_diluted_shares,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Max Margin Drivers'}),
        createRow({
            headerText: '5-Yr Avg Bookings/S&M',
            records: records,
            getCellValue: record => record.entry_average_implemented_arr_to_sm,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Years in S&M Efficiency Avg.',
            records: records,
            getCellValue: record => record.years_in_trailing_implemented_arr_to_sm,
            formatValue: formatTwoDecimalNoNull,
            columnHeaderFieldName,
        }),
        emptyRow(),
        ...getValuationPremiumDetails({financialRecords: records, columnHeaderFieldName}),
        emptyRow(),
        createRow({
            headerText: 'Total Share Repurchase $',
            records: records,
            getCellValue: record => record.cumulative_repurchase_spend,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Total Repurchased Shares',
            records: records,
            getCellValue: record => record.cumulative_repurchase_shares,
            formatValue: formatOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Avg Repur. $/share filing',
            records: records,
            getCellValue: record => record.cumulative_repurchase_price_filing,
            formatValue: formatSharePrice,
            columnHeaderFieldName,
        }),

        emptyRow(),
        emptyRow()

    ];

    return (
        <div className={getTableClassName(loading) + ' align-right'}>

            <ReactDataGrid
                columns={labelRow}
                rowGetter={index => dataRows[index]}
                rowsCount={dataRows.length}
                headerRowHeight={headerRowHeight}
                rowHeight={rowHeight}
                minHeight={getWindowHeightLessMenuBar()}
                rowRenderer={ModelRowRenderer}
            />

        </div>
    )

}