import React from "react";
import {Link} from 'react-router-dom';


export class AkkrCompanyLinkFormatter extends React.Component {
    render() {
        return getAkkrCompanyLink({companyId: this.props.value.akkr_company_id, companyName: this.props.value.name})
    }
}

const getAkkrCompanyLink = ({companyId, companyName}) => {
  return <Link to={'/akkr/akkr_companies/' + companyId}>{companyName}</Link>
};
