import {Button, Divider, Form, Grid, Header, Table} from "semantic-ui-react";
import CompanyHeader from "../../../../components/CompanyHeader";
import React from 'react';
import {formatFourDecimalNoNullNoZero, formatNoDecimalNoNull, formatTwoDecimalNoNull} from "../../../../common/format";
import {Link} from "react-router-dom";
import {
    createAddValueToQuery,
    createSetFieldValueOnQuery,
    createSetFieldValuesOnQuery
} from "../../../../common/query/add";
import {predictionConstraints} from "./common";
import {YearLegend} from "./yearLegend";


const headerByPredictor = {
    'irr': 'IRR',
    'exit_price_percent_of_entry_price': 'Exit Price % Entry',
    'fair_price_percent_of_entry_price': 'Exit PV (6%) % Entry',
    'buy_price_percent_of_entry_price': 'Exit PV (15%) % Entry'
};


const headerSpecs = [
    {
        label: '1 Day',
        y: 'return_1_day'
    },
    {
        label: '15 Day',
        y: 'return_15_day'
    },
    {
        label: '30 Day',
        y: 'return_30_day'
    },
    {
        label: '60 Day',
        y: 'return_60_day'
    },
    {
        label: '90 Day',
        y: 'return_90_day'
    },
    {
        label: '180 Day',
        y: 'return_180_day'
    }

];

const predictionConstraintOptions = predictionConstraints.map(v => {
    return {
        key: v,
        value: v,
        text: v
    }
});




export default ({
                    company,
                    loadingCompany,
                    doradoRegressionSummaries,
                    history,
                    searchProps
                }) => {

    const resultTables = doradoRegressionSummaries.map(summary => {

        const headerButtons = headerSpecs.map(spec => {
            return (
                <Table.HeaderCell key={spec.label}>
                    <RegressionButton label={spec.label}
                                      onClick={createSetFieldValuesOnQuery({
                                          history,
                                          searchProps,
                                          changes: {
                                              y: spec.y,
                                              x: summary.predictor
                                          }
                                      })}
                    />
                </Table.HeaderCell>

            )

        })


        return (
            <div key={summary.predictor}>
                <Header as={'h4'}>
                    {headerByPredictor[summary.predictor]}
                </Header>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>

                            </Table.HeaderCell>
                            {headerButtons}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                R<sup>2</sup>
                            </Table.Cell>
                            <Table.Cell>
                                {formatTwoDecimalNoNull(summary.r2_return_1_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatTwoDecimalNoNull(summary.r2_return_15_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatTwoDecimalNoNull(summary.r2_return_30_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatTwoDecimalNoNull(summary.r2_return_60_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatTwoDecimalNoNull(summary.r2_return_90_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatTwoDecimalNoNull(summary.r2_return_180_day)}
                            </Table.Cell>

                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Slope
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.slope_return_1_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.slope_return_15_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.slope_return_30_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.slope_return_60_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.slope_return_90_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.slope_return_180_day)}
                            </Table.Cell>

                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Intercept
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.intercept_return_1_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.intercept_return_15_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.intercept_return_30_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.intercept_return_60_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.intercept_return_90_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatFourDecimalNoNullNoZero(summary.intercept_return_180_day)}
                            </Table.Cell>

                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Records
                            </Table.Cell>
                            <Table.Cell>
                                {formatNoDecimalNoNull(summary.record_count_return_1_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatNoDecimalNoNull(summary.record_count_return_15_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatNoDecimalNoNull(summary.record_count_return_30_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatNoDecimalNoNull(summary.record_count_return_60_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatNoDecimalNoNull(summary.record_count_return_90_day)}
                            </Table.Cell>
                            <Table.Cell>
                                {formatNoDecimalNoNull(summary.record_count_return_180_day)}
                            </Table.Cell>

                        </Table.Row>
                    </Table.Body>
                </Table>
                <br/>
            </div>
        )
    })




    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <CompanyHeader company={company} loading={loadingCompany}/>
                    <Form>
                        <Form.Select
                            fluid
                            inline
                            width={14}
                            options={predictionConstraintOptions}
                            onChange={createAddValueToQuery({history, searchProps, fieldName: 'predictionConstraint'})}
                            value={searchProps.predictionConstraint}
                            label="Period Constraint"
                        />
                    </Form>
                </Grid.Column>
                <Grid.Column width={8}>
                    <YearLegend/>
                </Grid.Column>

            </Grid.Row>
            <Grid.Row>
                <Grid.Column>

                    <Divider horizontal>
                        Regression Results
                    </Divider>
                    {resultTables}
                </Grid.Column>
            </Grid.Row>

        </Grid>
    );
}


const RegressionButton = ({label, onClick}) => {
    return (
        <Button basic compact color={'blue'} onClick={onClick} key={label}>
            {label}
        </Button>
    )
}


