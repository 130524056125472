

export const nullValueOption = {
    key: '<null>',
    value: '<null>',
    text: 'No Value'
};


export const noSelectionOption = {
    key: '<no selection>',
    value: '<no selection>',
    text: ''
};


// from DB, value will come in as null
// if showing a select box, this will render properly
export const benchNullOption = {
    key: '',
    value: null,
    text: ''
};
