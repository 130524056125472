import React from 'react';
import {Form, Divider} from 'semantic-ui-react'
import {parseSearchProps} from "../../common/query/parse";
import {createAddValueToQuery} from '../../common/query/add';
import {useLocation, useHistory} from "react-router-dom";


export default ({columnGroups}) => {

    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const columnGroupName = searchProps.columnGroup || null;

    const columnOptions = [{
        key: '',
        value: '',
        text: ''
    }].concat(
        columnGroups.map(group => {
            return {
                key: group.name,
                value: group.name,
                text: group.name
            }
        }));

    return (
        <div>
            <Divider horizontal>Columns</Divider>
            <Form>
                <Form.Select
                    options={columnOptions}
                    value={columnGroupName}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'columnGroup'})}
                    label="Column View"
                />
            </Form>
        </div>
    )

}
