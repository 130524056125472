import {
    CompanyLinkFormatter,
    MultipleOneDecimalFormatter,
    NoDecimalNoNullOrZeroFormatter,
    PercentFourDecimalNoNullFormatter,
    PercentOneDecimalNoNullFormatter,
    PercentTwoDecimalNoNullFormatter, PricePercentChangeOneDecimalFormatter
} from "../../common/reactDataGrid/formatters";
import {CurrencySharePriceFormatter} from "../../common/columns";
import React from "react";
import {changeFromLastClosePriceCol} from "../dorado/commonColumns";
import {CompanyLinkFromSecurityListing} from "../../common/reactDataGrid/columns";


export const columns = [
    CompanyLinkFromSecurityListing,
    {
        key: 'weight',
        name: 'Index Weight',
        sortable: true,
        formatter: PercentFourDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'cumulative_weight',
        name: 'Cumul. Index Weight',
        sortable: true,
        formatter: PercentFourDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'last_price',
        name: 'Price $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.trade_assessment_record.last_price,
                trading_currency: r.trade_assessment_record.trading_currency
            }
        }
    },
    {
        key: 'price_change_from_last_close',
        name: '% Change (day)',
        sortable: true,
        formatter: PricePercentChangeOneDecimalFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.trade_assessment_record.price_change_from_last_close
        }
    },
    {
        key: 'contribution_percent',
        name: 'Contribution %',
        sortable: false,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 85,
    },
    {
        key: 'cumulative_contribution',
        name: 'Cumul. Contribution %',
        sortable: false,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 85,
    },
    {
        key: 'irr',
        name: 'Expected IRR',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        _getValueFromRecord: (r) => {
            return r.trade_assessment_record.irr
        }
    },
    {
        key: 'entry_ev_to_revenue_total_ntm',
        name: 'Entry EV/NTM Revenue',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.trade_assessment_record.entry_ev_to_revenue_total_ntm
        }
    },
    {
        key: 'execution_score',
        name: 'Execution Score',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.trade_assessment_record.execution_score
        }
    },
    {
        key: 'entry_ntm_organic_arr_growth',
        name: 'NTM Organic ARR Growth',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.trade_assessment_record.entry_ntm_organic_arr_growth
        }
    },
    {
        key: 'entry_ev_to_pretax_fcf_ntm',
        name: 'Entry EV/NTM Pretax FCF',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.trade_assessment_record.entry_ev_to_pretax_fcf_ntm
        }
    },
    {
        key: 'entry_ev_to_maximized_pretax_fcf_ntm',
        name: 'Entry EV/NTM Max FCF',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.trade_assessment_record.entry_ev_to_maximized_pretax_fcf_ntm
        }
    },
];