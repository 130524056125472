import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
const coverageRecordsService = client.service('coverage_records');
const dataQualityRecordsService = client.service('data_quality_records');


const dataQualityFeatureSlice = createSlice({
    name: 'dataQuality',
    initialState: getInitialStateWithLoading({
        coverageRecords: [],
        dataQualityRecords: [],
    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchCoverageRecords', destinationVariable: 'coverageRecords'}),
        ...makeStandardReducers({prefix: 'fetchDataQualityRecordsForCompany', destinationVariable: 'dataQualityRecords'}),
    }
});


export const fetchCoverageRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: dataQualityFeatureSlice,
        prefix: 'fetchCoverageRecords',
        getPayload: () => {return coverageRecordsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}

    })
};


export const fetchDataQualityRecordsForCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: dataQualityFeatureSlice,
        prefix: 'fetchDataQualityRecordsForCompany',
        getPayload: () => {return dataQualityRecordsService.find({
            query: {
                company_id: companyId
            }
        })}

    })
};


export default dataQualityFeatureSlice.reducer