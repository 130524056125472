import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {Button, Grid, Header, Icon, Statistic, Table} from "semantic-ui-react";
import React from "react";
import {
    formatFullDateTimeUtcToLocal,
    formatDateTimeUtcToLocal,
    formatAbsoluteDateString, formatSimpleDate
} from "../../../common/formatDate";
import {
    formatBooleanYesNo,
    formatPercentOneDecimalNoNull,
    formatOneDecimalNoNull,
    formatPercentFourDecimalNoNull, formatNoDecimalNoNull, formatTwoDecimalNoNull
} from "../../../common/format";


export default ({doradoPortfolio, loading}) => {

    const report = doradoPortfolio.dorado_portfolio_report || {};

    return (
        <Table compact collapsing>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='2'>Report</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body >
                <Table.Row>
                    <Table.Cell>Total Return</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.indexed_return)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>IRR</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.irr)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Standard Deviation</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.standard_deviation)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Sharpe Ratio</Table.Cell>
                    <Table.Cell>{formatTwoDecimalNoNull(report.sharpe_ratio)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Short Avg % of NAV</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(-report.average_securities_short_market_value_percent)}</Table.Cell>
                </Table.Row>


                <Table.Row>
                    <Table.Cell>Max. Daily Return</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.maximum_daily_return)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Min. Daily Return</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.minimum_daily_return)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Max Drawdown</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.maximum_drawdown)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Avg. # of Positions</Table.Cell>
                    <Table.Cell>{formatNoDecimalNoNull(report.average_position_count)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Top Position Avg Weight</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.average_top_position_weight)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Top 5 Positions Avg Weight</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.average_top_five_positions_weight)}</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Top Position Max Weight</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.maximum_top_position_weight)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Top-5 Positions Max Weight</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.maximum_top_five_positions_weight)}</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Backtest Days</Table.Cell>
                    <Table.Cell>{report.days}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Avg Daily % of Equity Traded (Gross/2)</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.avg_trading_day_gross_trades_percent_of_equity / 2)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Gross Trades % Equity (Portfolio Turnover)</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.cumul_gross_total_trades_percent_of_equity)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Hard Trading Costs % Avg Equity</Table.Cell>
                    <Table.Cell>{formatPercentFourDecimalNoNull(report.cumul_hard_trading_costs_percent_average_equity)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Soft Trading Costs % Avg Equity</Table.Cell>
                    <Table.Cell>{formatPercentFourDecimalNoNull(report.cumul_soft_trading_costs_percent_average_equity)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Total Trading Costs % Avg Equity</Table.Cell>
                    <Table.Cell>{formatPercentFourDecimalNoNull(report.cumul_total_trading_costs_percent_average_equity)}</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Avg. Herfindahl Index</Table.Cell>
                    <Table.Cell>{formatNoDecimalNoNull(report.average_herfindahl_index)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Max. Herfindahl Index</Table.Cell>
                    <Table.Cell>{formatNoDecimalNoNull(report.maximum_herfindahl_index)}</Table.Cell>
                </Table.Row>


            </Table.Body>


        </Table>
    )

}