import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {connect} from 'react-redux';
import {newRole, createNewRole, fetchRole, patchRole, fetchRoleFacets, modifyRole } from '../../../actions/employees/roleActions';
import {Container, Header, Form} from 'semantic-ui-react'
import {fetchCompanyOld} from "../../../actions/companyActions";
import {getRolesByCompanyUrl} from '../../../common/links'


class RoleFormPage extends Component {

  state = {
    redirect: false
  };

  componentDidMount() {

    // form can show for new role or to edit a role
    // if new, will not have an id

    this.props.fetchRoleFacets();

    const {id, company_id} = this.props.match.params;
    this.props.fetchCompany(company_id);

    if (this.props.isNew) {
      this.props.newRole();
    } else {
      this.props.fetchRole({id});
    }

  }

  render() {

    const matchParams = this.props.match.params;

    const {company_id} = matchParams;

    if (this.state.redirect) {
      return <Redirect to={getRolesByCompanyUrl({companyId: company_id})}/>
    }

    const companyName = this.props.loadingCompany ? '' : this.props.company.company_name;

    let majorCostCenterOptions = [];
    let minorCostCenterOptions = [];
    if (! this.props.loadingFacets) {
      majorCostCenterOptions = this.props.facets.major_cost_center.map(r => {
        return {
          key: r,
          value: r,
          text: r
        }
      });
      minorCostCenterOptions = this.props.facets.minor_cost_center.map(r => {
        return {
          key: r.name,
          value: r.name,
          text: r.major_cost_center  + " : " + r.name
        }
      });
    }

    let onSubmit = null;
    if (this.props.isNew) {
      onSubmit = () => {
        return this.props.createNewRole({
          ...this.props.role,
          company_id: company_id
        }).then(response => this.setState({redirect: true}));
      }
    } else {
      onSubmit = () => {
        return this.props.patchRole(this.props.role).then(response => {this.setState({redirect: true})});
      }
    }

    return (
      <Container text>
        <Header>
          <Header.Content>
            {this.props.isNew ? 'Add New Role' : 'Edit Role' }
            <Header.Subheader>{companyName}</Header.Subheader>
          </Header.Content>
        </Header>
        <Form onSubmit={onSubmit} loading={this.props.loadingRole}>
          <Form.Select options={majorCostCenterOptions}
                       loading={this.props.loadingFacets}
                       value={this.props.role.major_cost_center}
                       onChange={(e, d) => {
                         this.props.modifyRole({fieldName: 'major_cost_center', value: d.value})
                       }}
                       label="Major Cost Center"/>
          <Form.Select options={minorCostCenterOptions}
                       loading={this.props.loadingFacets}
                       value={this.props.role.minor_cost_center}
                       onChange={(e, d) => {
                         this.props.modifyRole({fieldName: 'minor_cost_center', value: d.value})
                       }}
                       label="Minor Cost Center"/>
          <Form.Input label="Role Name"
                      value={this.props.role.name || ''}
                      onChange={(e, d) => {
                        this.props.modifyRole({fieldName: 'name', value: d.value})
                      }}
          />
          <Form.TextArea
            label='Description'
            value={this.props.role.description || ''}
            onChange={(e, d) => {
              this.props.modifyRole({fieldName: 'description', value: d.value})
            }}
          />
          <Form.Button type='submit' primary>
            {this.props.isNew ? 'Create' : 'Save' }
          </Form.Button>

        </Form>

      </Container>
    )

  }
}

function mapStateToProps(state) {
  return {
    role: state.roleStore.role,
    loadingRole: state.roleStore.loadingRole,

    company: state.companyStore.company,
    loadingCompany: state.companyStore.loadingCompany,

    loadingFacets: state.roleStore.loadingFacets,
    facets: state.roleStore.facets,
  }
}


export default connect(mapStateToProps, {
  fetchCompanyOld, newRole, fetchRole, fetchRoleFacets, modifyRole, createNewRole, patchRole
})(RoleFormPage);
