import React from 'react'
import {Button, Grid, Icon, Progress, Label} from "semantic-ui-react";
import {shallowEqual, useSelector} from "react-redux";


export default ({onClick, expectedCompletionSeconds, buttonColor, iconName, buttonText, hidePolling=false}) => {

    const {
        refreshingRecords,
        processRefreshCount
    } = useSelector(state => {return state.polling}, shallowEqual);

    const isError = processRefreshCount === -1;

    const incrementSeconds = 3;
    const incrementPercentInteger = Math.round(Math.min((incrementSeconds / expectedCompletionSeconds) * 100, 100));

    buttonColor = buttonColor || 'blue';
    iconName = iconName || 'refresh';
    buttonText = buttonText || 'Refresh Prices';

    let alert = <div/>;

    if (isError) {
        alert = (
            <Label basic color={'red'}>
                <Icon name={'exclamation circle'}/>
                Error!
            </Label>)
    } else {
        if (refreshingRecords) {
            alert = <Progress percent={processRefreshCount * incrementPercentInteger} indicating label={'Refreshing...'} width={8} />;
        }
    }

    return (
        <Grid.Row style={{paddingBottom: 0}}>
            <Grid.Column width={8}>
                <Button basic color={buttonColor} loading={refreshingRecords}
                        onClick={onClick}>
                    <Icon name={iconName}/>
                    {buttonText}
                </Button>
            </Grid.Column>
            <Grid.Column width={8}>
                {hidePolling ? null : alert}
            </Grid.Column>

        </Grid.Row>
    )
}