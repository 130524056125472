import { createSlice } from '@reduxjs/toolkit'
import client from '../../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";
const flexOutstandingUnfilledOrdersService = client.service('outstanding_unfilled_orders');
const currentStreetOrdersService = client.service('current_street_orders');
const flagNonDoradoOrderService = client.service('flag_non_dorado_order');



const flexFeatureSlice = createSlice({
    name: 'flex',
    initialState: {
        ...getInitialStateWithLoading({
            outstandingUnfilledOrders: [],
            currentStreetOrdersAnalysis: {
                trade_value_by_trade_type: {},
                flex_orders_by_trade_type: {}
            },
            flaggedNonDoradoOrder: {}

        }),
        ...{
            loadingFlaggedNonDoradoOrder: false
        }
    },
    reducers: {
        ...makeStandardReducers({
            prefix: 'fetchFlexOutstandingUnfilledOrders',
            destinationVariable: 'outstandingUnfilledOrders'
        }),
        ...makeStandardReducers({
            prefix: 'fetchFlexCurrentStreetOrders',
            destinationVariable: 'currentStreetOrdersAnalysis'
        }),
        ...makeStandardReducers({
            prefix: 'flagNonDoradoOrder',
            destinationVariable: 'flaggedNonDoradoOrder'
        }),


    }
});

export const fetchFlexOutstandingUnfilledOrders = ({sort = {}, filters = {}}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: flexFeatureSlice,
        prefix: 'fetchFlexOutstandingUnfilledOrders',
        getPayload: () => {
            return flexOutstandingUnfilledOrdersService.find({
                query: {
                    $sort: sort,
                    ...filters,
                    $limit: 500,
                }
            })
        }
    })
};


export const fetchFlexCurrentStreetOrders = ({customDate}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: flexFeatureSlice,
        prefix: 'fetchFlexCurrentStreetOrders',
        getPayload: () => {
            return currentStreetOrdersService.find({
                query: {
                    custom_date: customDate
                }
            })
        }
    })
};


export const flagNonDoradoOrder = ({flexTradeOrder}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: flexFeatureSlice,
        prefix: 'flagNonDoradoOrder',
        getPayload: () => {
            return flagNonDoradoOrderService.create({
                flextrade_order: flexTradeOrder
            })
        }
    })
};


export default flexFeatureSlice.reducer