import React, {useEffect} from 'react';
import {Form, Grid} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {
    fetchDoradoBacktestPeriodReportsWithCompanies,
    getDoradoPortfolio
} from "../../../features/dorado/doradoPortfolio";
import {createAddValueToQuery} from "../../../common/query/add";
import {
    fieldSpecs,
    longShortTypeOptions,
    series1chartOptions,
    series1ChartSpecificationByName,
    topPositionCountOptions
} from './common'
import Chart from './Chart'
import PortfolioSelect from "../../dorado/PortfolioSelect";
import {
    fetchPortfolioPeriodReportsWithSecurityInvestmentPeriodReports,
    fetchPortfolios
} from "../../../features/portfolio/portfolioFeatureSlice";
import {useReloadOnChange} from "../../../common/hooks";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});
    const match = useRouteMatch();

    const {
        portfolioPeriodReportsWithSecurityInvestmentPeriodReports,
        loadingPortfolioPeriodReportsWithSecurityInvestmentPeriodReports,

        portfolios,
        loadingPortfolios,

    } = useSelector(state => {return state.portfolio}, shallowEqual);

    useEffect(() => {
        const sort = {
            type: 1,
            is_backtest: 1
        }

        const filters = {
            type: {
                $in: [
                    'Point Sur',
                    'Special Opps V',
                    'Dorado',
                    'Backtest Sensitivity',
                    'Backtest Benchmark'
                ]
            },
        }

        dispatch(fetchPortfolios({sort, filters}));
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['portfolio_id'],
        requestData
    });

    if (searchProps.portfolio_id == null) {
        if (!loadingPortfolios && portfolios.length > 0) {
            return <Redirect to={location.pathname + "?portfolio_id=" + portfolios[0].id}/>
        }
    }

    const sidebar = (
        <div>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        Click a company in the legend to focus on its returns.
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <PortfolioSelect/>
                            <Form.Select
                                fluid
                                inline
                                options={series1chartOptions}
                                onChange={createAddValueToQuery({history, searchProps, fieldName: 'series1ChartName'})}
                                value={searchProps.series1ChartName}
                                label="Chart"
                            />
                            <Form.Select
                                fluid
                                inline
                                options={topPositionCountOptions}
                                onChange={createAddValueToQuery({history, searchProps, fieldName: 'topPositionCount'})}
                                value={searchProps.topPositionCount}
                                label="Top Contributors per Period"
                            />
                            <Form.Select
                                fluid
                                inline
                                options={longShortTypeOptions}
                                onChange={createAddValueToQuery({history, searchProps, fieldName: 'longShortType'})}
                                value={searchProps.longShortType}
                                label="Long/Short"
                            />
                        </Form>

                    </Grid.Column>
                </Grid.Row>

            </Grid>



        </div>
    );

    return (
        <ViewWithSidebar mainView={<Chart
            portfolioPeriodReportsWithSecurityInvestmentPeriodReports={portfolioPeriodReportsWithSecurityInvestmentPeriodReports}
            topPositionCount={searchProps.topPositionCount}
            series1ChartSpec={series1ChartSpecificationByName[searchProps.series1ChartName]}
            longShortType={searchProps.longShortType}
            loading={loadingPortfolioPeriodReportsWithSecurityInvestmentPeriodReports}
        />}
                         sidebarView={sidebar}/>
    )

}



const requestData = ({dispatch, searchProps}) => {

    if (searchProps.portfolio_id != null) {
        dispatch(fetchPortfolioPeriodReportsWithSecurityInvestmentPeriodReports({
            sort: {
                end_date: 1,
            },
            filters: {
                portfolio_id: searchProps.portfolio_id,
                period_type: 'Monthly'
            }

        }))
    }



}
