import React, {useEffect} from 'react';
import {Button, Label, Divider, Form, Grid, Header, Icon, Statistic, Table} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {getFilterComponentsFromSpecs} from "../../../common/filter/add";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {formatDateTimeUtcToLocal, formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import moment from "moment";
import {requestDoradoValuationRecords} from "../common";
import RdgTable from "../../../components/rdg/RdgTable";
import {fetchValuationMetadata} from "../../../features/adminFeatureSlice";
import {
    approveDoradoParentSecurityOrders, approveDoradoStreetSecurityOrders, clearApprovalRequest,
    fetchDoradoSecurityOrderApprovalRequest, patchDoradoSecurityOrderAwaitingApproval
} from "../../../features/dorado/tradeApproval";
import {BoolToYesNoCheckFormatter} from "../../../common/reactDataGrid/formatters";
import {TradeType} from "../../../common/constants";
import {sortFunctional} from "../../../common/tableHelpers";
import {columns} from './common'
import TargetTradeOrdersTable from "../targetCommon/TargetTradeOrdersTable";
import {cleanUpdated} from "../../../components/rdg/helpers";
import {updateDoradoTargetSecurityOrder} from "../../../features/dorado/doradoTargetPortfolio";


const TradeApprovalStatus = {
    cancelled: 'Cancelled',
    awaiting_approval: 'Awaiting Approval',
    approved: 'Approved'
};


export default ({email}) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {
        doradoSecurityOrderApprovalRequest,
        loadingDoradoSecurityOrderApprovalRequest,
        loadingDoradoOrderApproval
    } = useSelector(state => {
        return state.tradeApproval
    }, shallowEqual);

    useEffect(() => {
            dispatch(fetchDoradoSecurityOrderApprovalRequest());
        }, [dispatch]
    )

    const dt = loadingDoradoSecurityOrderApprovalRequest ? null : formatFullDateTimeUtcToLocal(doradoSecurityOrderApprovalRequest.request_date);

    const loadingButton = loadingDoradoSecurityOrderApprovalRequest || loadingDoradoOrderApproval;

    const buttonDisabled = doradoSecurityOrderApprovalRequest.status !== TradeApprovalStatus.awaiting_approval;

    const onApproveParentOrder = () => {
        dispatch(approveDoradoParentSecurityOrders({
            uuid: doradoSecurityOrderApprovalRequest.uuid,
            email: email
        }));
    }

    const onApproveStreetOrder = () => {
        dispatch(approveDoradoStreetSecurityOrders({
            uuid: doradoSecurityOrderApprovalRequest.uuid,
            email: email
        }));
    }

    const onClearApprovalRequest = () => {
        dispatch(clearApprovalRequest());
    }

    const orders = doradoSecurityOrderApprovalRequest.dorado_security_awaiting_approval_orders;

    const buyOrders = sortFunctional({
        array: orders.filter(r => r.trade_type === TradeType.buy),
        sortKey: 'gross_value_before_transaction_cost_usd',
        sortDirection: -1
    });

    const sellOrders = sortFunctional({
        array: orders.filter(r => r.trade_type !== TradeType.buy),
        sortKey: 'gross_value_before_transaction_cost_usd',
        sortDirection: -1
    });

    const buyLongOrders = buyOrders.filter(order => order.is_short_sale_or_cover === false);
    const buyCoverOrders = buyOrders.filter(order => order.is_short_sale_or_cover === true);

    const sellLongOrders = sellOrders.filter(order => order.is_short_sale_or_cover === false);
    const sellShortOrders = sellOrders.filter(order => order.is_short_sale_or_cover === true);




    const records = [
        ...buyLongOrders,
        ...getFillerRows(buyLongOrders),
        ...sellLongOrders,
        ...getFillerRows(sellLongOrders),
        ...buyCoverOrders,
        ...getFillerRows(buyCoverOrders),
        ...sellShortOrders
    ];

    const sidebar = (
        <div>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Table compact basic='very' collapsing>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        UUID:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {doradoSecurityOrderApprovalRequest.uuid}
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                        Request Created:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {dt}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Status
                                    </Table.Cell>
                                    <Table.Cell>
                                        <StatusLabel loading={loadingDoradoSecurityOrderApprovalRequest}
                                                     status={doradoSecurityOrderApprovalRequest.status}/>

                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Approved by Weston:
                                    </Table.Cell>
                                    <Table.Cell>
                                        <BoolToYesNoCheckFormatter
                                            value={doradoSecurityOrderApprovalRequest.approved_by_weston}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Approved by Tyler:
                                    </Table.Cell>
                                    <Table.Cell>
                                        <BoolToYesNoCheckFormatter
                                            value={doradoSecurityOrderApprovalRequest.approved_by_tyler}/>
                                    </Table.Cell>
                                </Table.Row>

                            </Table.Body>
                        </Table>

                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <TargetTradeOrdersTable buyLongOrders={buyLongOrders}
                                                buyCoverOrders={buyCoverOrders}
                                                sellShortOrders={sellShortOrders}
                                                sellLongOrders={sellLongOrders}

                                                loading={loadingDoradoSecurityOrderApprovalRequest}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button primary loading={loadingButton} onClick={onApproveParentOrder} disabled={buttonDisabled}>
                            Approve as Parent Order
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button basic color={'orange'} loading={loadingButton} onClick={onApproveStreetOrder} disabled={buttonDisabled}>
                            Approve as Street Order
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button basic color='grey' loading={loadingButton} onClick={onClearApprovalRequest}>
                            Clear Request
                        </Button>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </div>
    );

    const onGridRowsUpdated = ({fromRow, toRow, updated}) => {

        updated = cleanUpdated(updated);

        const record = records[fromRow];
        const newRecord = {id: record.id};

        for (let key of Object.keys(updated)) {
            newRecord[key] = updated[key];
        }

        console.log(newRecord)

        dispatch(patchDoradoSecurityOrderAwaitingApproval(newRecord));

    }

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             loading={loadingDoradoSecurityOrderApprovalRequest}
                                             records={records}
                                             onGridRowsUpdated={onGridRowsUpdated}

        />}
                         sidebarView={sidebar}/>
    )

}


const colorByStatus = {
    'Cancelled': 'grey',
    'Awaiting Approval': 'orange',
    'Approved': 'green'
}

const getFillerRows = (recordsAbove) => {

    if (recordsAbove.length > 0) {
        return [{company: {}}]
    }
    return []

}


export const StatusLabel = ({status, loading}) => {
    if (loading) {
        return <div/>
    }

    return <Label color={colorByStatus[status]}>{status}</Label>
}