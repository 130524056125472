import moment from 'moment';
import {getDateOptionsToPresent} from "../../../common/dateHelpers";
import {
    formatPercentFourDecimalNoNull,
    formatPercentNoNull,
    formatPercentOneDecimalNoNull, formatPercentTwoDecimalNoNull
} from "../../../common/format";
import {Form} from "semantic-ui-react";
import React from "react";
import {shortStrategies} from "../../../common/constants";


export const earliestBacktestDate = moment("20181102", "YYYYMMDD");
export const doradoStartDate = moment("20200809", "YYYYMMDD");


export const startEndDateOptions = getDateOptionsToPresent(earliestBacktestDate);


export const roundToFourDigits = (v) => {
    if (v == null) {
        return v
    }
    return Math.round(v * 10000) / 10000
};

export const roundToFiveDigits = (v) => {
    if (v == null) {
        return v
    }
    return Math.round(v * 100000) / 100000
};


export const tradeCostPercentOptions = [0, .0002, .0005, .001, .0015, .002, .0025, .003, .0035].map(v => {

    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentFourDecimalNoNull(v)
    }
});


export const returnConcentrationTradeoffOptions = [0, .0001, .00015, .0002, .0003, .0004].map(v => {

    v = roundToFiveDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentOneDecimalNoNull(v * 100)
    }
});


export const maximumLimitPercentOptions = [.01, .015, .02, .025, .03].map(v => {

    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentFourDecimalNoNull(v)
    }
});


export const longConcentrationScoreOptions = [600, 700, 750, 800, 825, 850, 875, 900, 1000, 1200, 1500].map(v => {
    return {
        key: v,
        value: v,
        text: v
    }
});


export const shortMinimumMarketValueOptions = [500, 1000, 1500, 2000, 3000, 5000].map(v => {
    return {
        key: v,
        value: v * 1000,
        text: v
    }
});



export const shortConcentrationScoreOptions = [100, 200, 300, 400, 500, 600].map(v => {
    return {
        key: v,
        value: v,
        text: v
    }
});



export const targetCashOptions = [0, .05, .1, .2, .3].map(v => {
    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentOneDecimalNoNull(v)
    }
});

export const longMaxSinglePositionWeightOptions = [.05, .075, .1, .125, .15].map(v => {
    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentOneDecimalNoNull(v)
    }
});

export const shortMaxSinglePositionWeightOptions = [.01, .015, .02, .03, .05].map(v => {
    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentOneDecimalNoNull(v)
    }
});



export const assumedImplementationCostOptions = [0, .0025, .005, .01, .02, .04, .05, .06, .07, .08, .09, .1, .2, .25].map(v => {
    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentFourDecimalNoNull(v)
    }
});


export const maxTradePercentOfVolumeOptions = [0, .05, .1, .15, .2].map(v => {
    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentOneDecimalNoNull(v)
    }
});


export const targetShortPercentOptions = [0, .05, .1, .2, .3, .33, .35, .37, .4, .5, .6, .7, .8, .9, 1.0].map(v => {
    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentOneDecimalNoNull(v)
    }
});

export const noMultipleExpansionIrrWeightOptions = [0.0, .25, .5, .75, 1.0].map(v => {

    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentOneDecimalNoNull(v)
    }
});


export const shortCollateralNetInterestRateOptions = [-0.005, -.004, -0.0025, 0, .0025, .005].map(v => {
    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentTwoDecimalNoNull(v)
    }
})


export const universeIrrOptions = Array.from({length: 60}, (x, i) => i * .5 /100 - .05).map(v => {
    v = roundToFourDigits(v);
    return {
        key: v,
        value: v,
        text: formatPercentOneDecimalNoNull(v)
    }
});

export const etfOptions = [
    {
        key: 5,
        value: 5,
        text: 'VBR (Vanguard Small Cap Value)'
    },
    {
        key: 4,
        value: 4,
        text: 'VTV (Vanguard Value)'
    },
    {
        key: 3,
        value: 3,
        text: 'Russell 2000'
    },
    {
        key: 2,
        value: 2,
        text: 'IGV'
    },
];


export const shortStrategyOptions = shortStrategies.map(v => {
    return {
        key: v,
        value: v,
        text: v
    }
})





export const FormItemWithDesc = ({children}) => {

    return (
        <Form.Group>
            {children[0]}
            {/*{formItem}*/}
            <div className={"one wide field"}/>
            <div className={"five wide field"}>
                <i>{children[1]}</i>
            </div>

        </Form.Group>
    )
};
