import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from "../../common/request/convert";
import {
    fetchMissingDoradoValuationRecordCount,
    fetchPointSurDoradoValuationRecords
} from "../../features/dorado/doradoValuationRecords";
import React from "react";
import {Link} from "react-router-dom";
import {parseBoolean} from "../../common/query/parse";
import {formatFullDateTimeUtcToLocal} from "../../common/formatDate";
import {Grid} from "semantic-ui-react";
import {formatTwoDecimalPercentNoZero} from "../../common/reactDataGrid/formatters";


export const requestDoradoValuationRecords = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = {
        ...convertFiltersToFeathersFilters({
            filters: searchProps.filters,
            parseSpecByField: {}
        })
    };

    dispatch(fetchPointSurDoradoValuationRecords({sort, filters}));
    dispatch(fetchMissingDoradoValuationRecordCount({filters}));

};


export class CompanyBacktestValuationsLinkFormatter extends React.Component {
    render() {

        const startDate = this.props.startDate;
        const endDate = this.props.endDate;

        let link = '/company_detail/dorado_backtest_valuations/' + this.props.value.company_id;

        if (startDate) {
            link += '?highlightStartDate=' + startDate + "&highlightEndDate=" + endDate
        }

        return <Link
            to={link}>
            {this.props.value.company_name}
        </Link>
    }
}

export const companyBacktestLinkColumn = {
    key: 'company_name',
    name: 'Name',
    width: 200,
    frozen: true,
    sortable: true,
    formatter: CompanyBacktestValuationsLinkFormatter,
    _getValueFromRecord: (r) => {

        if (r.company != null) {
            return {
                company_name: r.company.company_name,
                company_id: r.company.id
            }
        }

        return {
            company_name: r.company_name,
            company_id: r.company_id
        }


    }
};


export const companyOrEtfBacktestLinkColumn = {
    key: 'company_or_etf_name',
    name: 'Name',
    width: 300,
    frozen: true,
    sortable: false,
    formatter: CompanyBacktestValuationsLinkFormatter,
    _getValueFromRecord: (r) => {

        if (r.company != null) {
            return {
                company_name: r.company.company_name,
                company_id: r.company.id
            }
        }

        if (r.exchange_traded_fund != null) {

            return {
                company_name: r.exchange_traded_fund.short_name,
                company_id: null
            }
        }

        return {
            company_name: r.company_name,
            company_id: r.company_id
        }


    }
};

export class BackupLinkFormatter extends React.Component {
    render() {

        const companyId = this.props.value.company_id;
        const doradoValuationRecordId = this.props.value.dorado_valuation_record_id;

        return (
            <Link to={'/company_detail/dorado_valuations/' + companyId + "/" + doradoValuationRecordId}>
                Detail
            </Link>)
    }
}


export const getPricesAsOfText = (doradoPortfolioTradeMetadata) => {
    return 'Prices as of: ' + formatFullDateTimeUtcToLocal(doradoPortfolioTradeMetadata.prices_as_of_date)
}

export const getPortfolioSharesAsOfText = (doradoPortfolioTradeMetadata) => {
    return 'Portfolio Shares as of: ' + formatFullDateTimeUtcToLocal(doradoPortfolioTradeMetadata.portfolio_shares_as_of_date)
}

export const getTradeRecommendationsAsOfText = (doradoPortfolioTradeMetadata) => {
    return 'Trade Recommendations as of: ' + formatFullDateTimeUtcToLocal(doradoPortfolioTradeMetadata.trade_recommendations_as_of_date)
}

export class NoMultipleExpansionIRRFormatter extends React.Component {
    render() {

        const irr = this.props.value.irr;
        const irrNoMultipleExpansion = this.props.value.no_multiple_expansion_irr;

        const style = {

        };

        if (irr === irrNoMultipleExpansion) {
            style.color = 'white';
        }

        return <div style={style} className="align-right">{formatTwoDecimalPercentNoZero(irrNoMultipleExpansion)}</div>
    }
}