import React, {useEffect} from 'react'
import {Divider, Form} from 'semantic-ui-react'
import {getFilterComponentsFromSpecs} from '../../common/filter/add'
import {parseSearchProps} from "../../common/query/parse";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {ForecastType, PeriodType} from "../../common/constants";
import {
    createAddCheckedValueToQuery,
    createAddTermFilterToQuery, createAddTrueOnlyCheckboxFilterToQuery,
    createAddValueToQuery,
    setTermFilterValuesToQuery
} from "../../common/query/add";
import TermFilter from "../../components/filter/TermFilter";
import {formatAbsoluteDateString} from "../../common/formatDate";
import {fetchFacetsForDoradoValuationRecords} from "../../features/dorado/doradoValuationRecords";


const forecastTypeOptions = [ForecastType.currentModel, ForecastType.backtestModel].map(v => {
    return {
        text: v,
        value: v,
        key: v
    }
});

const commonDoradoFieldSpecs = {
    // forecast_type: {
    //     parse: (v) => v == null ? ForecastType.currentModel : v
    // }
};



const filterSpecs = [
    {
        field: 'coverage',
        label: 'Coverage',
        type: 'terms',
        allowNullSelection: true,
    },

];


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs: commonDoradoFieldSpecs});

    useEffect(() => {
        dispatch(fetchFacetsForDoradoValuationRecords());
    }, [dispatch]);

    const {
        doradoValuationFacetsByField,
        loadingDoradoValuationFacetsByField
    } = useSelector(state => state.doradoValuation, shallowEqual);

    const filters = getFilterComponentsFromSpecs({
            filterSpecs,
            facetsByField: doradoValuationFacetsByField,
            history,
            searchProps
        }
    );

    let asOfDates = loadingDoradoValuationFacetsByField ? [] : doradoValuationFacetsByField.as_of_date.terms;
    let dateOptions = [];

    if (!loadingDoradoValuationFacetsByField) {
        if (searchProps.filters && searchProps.filters.forecast_type && searchProps.filters.forecast_type.term === ForecastType.backtestModel) {
            dateOptions = asOfDates.map(dt => {
                return {
                    text: formatAbsoluteDateString({dateString: dt, format: 'M/D/YYYY'}),
                    key: dt,
                    value: dt
                }
            });
        }
    }

    let asOfDate = null;
    if (searchProps.filters && searchProps.filters.as_of_date) {
        asOfDate = searchProps.filters.as_of_date.term;
    }
    let forecastType = null;
    if (searchProps.filters && searchProps.filters.forecast_type) {
        forecastType = searchProps.filters.forecast_type.term;
    }

    return (
        <div>
            <Divider horizontal>Filters</Divider>
            <Form loading={loadingDoradoValuationFacetsByField}>
                <TermFilter allowNullSelection={false}
                            label='Forecast Type'
                            options={forecastTypeOptions}
                            value={forecastType}
                            onChange={(e, d) => {

                                let selectedBacktestDate = null;
                                if (searchProps.filters && searchProps.filters.as_of_date) {
                                    selectedBacktestDate = searchProps.filters.as_of_date.term;
                                }
                                if (selectedBacktestDate === null) {
                                    selectedBacktestDate = asOfDates[asOfDates.length - 1];
                                }

                                setTermFilterValuesToQuery({
                                    history,
                                    searchProps,
                                    valueByfieldName: {
                                        as_of_date: selectedBacktestDate,
                                        forecast_type: d.value
                                    }
                                });

                            }}
                />
                <TermFilter allowNullSelection={false}
                            label='Backtest Entry Date'
                            options={dateOptions}
                            value={asOfDate}
                            onChange={createAddTermFilterToQuery({
                                history,
                                searchProps,
                                fieldName: 'entry_date'
                            })}
                            />
                <Form.Checkbox label="Show Evaluation Companies"
                               checked={searchProps.dorado_evaluation_only}
                               onChange={createAddTrueOnlyCheckboxFilterToQuery({
                                   history,
                                   searchProps,
                                   fieldName: 'dorado_evaluation_only',
                                   invertTrueFalse: true,
                                   invertCheckedAction: true
                               })}
                />

                {filters}





            </Form>
        </div>

    )

}
