import React, {useEffect, useState} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Loader, Statistic} from 'semantic-ui-react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../common/query/parse";
import {
    fetchDoradoBacktestPortfolio,
} from "../../features/portfolio/portfolioFeatureSlice";
import {fieldSpecs, series1ChartSpecifications} from './backtestCharts/common'
import {clearBacktestDoradoPortfolio} from "../../features/dorado/doradoPortfolio";




export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});
    const match = useRouteMatch();

    const [state, setState] = useState({loading: true});

    useEffect(() => {
        dispatch(fetchDoradoBacktestPortfolio()).then(payload => {

            const portfolio = payload.data;

            setState({
                loading: false,
                portfolioId: portfolio.id
            });


        });

    }, [location, dispatch]);

    if (state.loading) {
        return <Loader active inline='centered'/>
    }

    return <Redirect to={location.pathname + "/" + state.portfolioId + location.search}/>

}