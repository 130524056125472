import React from 'react'
import {
    formatFullDate,
    formatMultipleTwoDecimals,
    formatNoDecimal, formatNoDecimalNoNull,
    formatNoDecimalNoNullOrZero,
    formatPercentNoNull,
    formatPercentOneDecimalNoNull,
    formatString,
    formatBooleanYesNo, formatBooleanYes
} from '../../../common/format'
import {GrossMarginCompositionMappingByDbField} from '../../../common/constants'
import {getCostDetailType, getNonNullCompositionFieldNames} from '../../../components/companyValuation/financials/common'
import ReactDataGrid from 'react-data-grid';
import {getWindowHeightLessMenuBar} from "../../../common/common";
import {ModelRowRenderer} from '../../../components/rdg/rdgComponents'
import {createRow} from "../../../components/rdg/helpers";
import {
    getClassToColorCapex,
    getClassToColorChangeInDeferred,
    getClassToColorDilution,
    getClassToColorEBITDA,
    getClassToColorGrossProfit,
    getClassToColorRevenue, makeGetEmphasizeYesBoolean
} from "../../../common/colors";
import {
    abbreviateSource,
    createHeaderRow,
    createSectionHeaderRow,
    emptyRow
} from "../../../components/companyFinancials/common";
import {
    getAcquiredRevenueCompositionAndTotalRows,
    getTotalImputedArrBridge,
    getBalanceSheetRows,
    getGrossProfitAndExpenseRows,
    getMarginRows,
    getPeriodRow,
    getRevenueCompositionAndTotalRows,
    getShareComponents,
    getArrCompositionBridges,
    getSalesAndMarketingDetail,
    getEBITRows,
    getExecutionAssessmentRows,
    getPretaxFCFRows,
    getActualArrBridge,
    get605ReconciliationRows,
    getDilutionAnalysisRows, getCapitalAllocationRows, getGrossMarginRows, getRevenueGrowthRows
} from "../../../components/companyFinancials/components";
import {getTableClassName} from "../../../common/reactDataGrid/common";
import {BoolToYesFormatter} from "../../../common/reactDataGrid/formatters";


export default function FinancialsComponent({financialRecords, loading}) {


    const periodRow = getPeriodRow({financialRecords});

    const costDetailType = getCostDetailType(financialRecords);

    let dataRows = [
        ...getRevenueCompositionAndTotalRows({financialRecords}),
        emptyRow(),
        ...getGrossProfitAndExpenseRows({
            records: financialRecords,
            costDetailType: costDetailType
        }),

    ];
    dataRows = dataRows.concat(
        [

            ...getEBITRows({financialRecords}),
            emptyRow(),
            ...getPretaxFCFRows({financialRecords}),
            emptyRow(),
            ...getRevenueGrowthRows({financialRecords}),
        ],
        emptyRow(),
        createSectionHeaderRow({headerText: 'Margins'}),
        emptyRow(),

        ...getGrossMarginRows({financialRecords}),
        ...getMarginRows({financialRecords: financialRecords}),
    );

    dataRows = dataRows.concat(
        [
            emptyRow(),
            ...getSalesAndMarketingDetail({financialRecords}),
            emptyRow(),
            ...get605ReconciliationRows({financialRecords}),
            emptyRow(),
            ...getTotalImputedArrBridge({financialRecords}),
            emptyRow(),
            ...getActualArrBridge({financialRecords}),
            emptyRow(),
            ...getArrCompositionBridges({financialRecords})
        ]
    );

    dataRows = dataRows.concat(
        [
            ...getExecutionAssessmentRows({financialRecords}),
            emptyRow(),
            ...getBalanceSheetRows({financialRecords}),
            emptyRow(),
            createRow({
                headerText: 'US Federal NOL',
                records: financialRecords,
                getCellValue: record => record.us_federal_nol,
                formatValue: formatNoDecimalNoNull,
            }),
            createRow({
                headerText: 'US Federal NOL Expiration',
                records: financialRecords,
                getCellValue: record => record.us_federal_nol_expiration_start_year,
                formatValue: formatString
            }),
            createRow({
                headerText: 'NOL is Roll-Forward?',
                records: financialRecords,
                getCellValue: record => record.us_federal_nol_is_roll_forward,
                formatValue: formatBooleanYesNo,
                getCellClassName: makeGetEmphasizeYesBoolean({
                    field: 'us_federal_nol_is_roll_forward',
                    colorOverride: 'black'
                }),
            }),


            emptyRow(),
            ...getCapitalAllocationRows({financialRecords})

        ]
    );


    dataRows = dataRows.concat(
        [
            emptyRow(),
            ...getDilutionAnalysisRows({financialRecords}),

            emptyRow(),

            ...getShareComponents({financialRecords}),


            emptyRow(),
            createSectionHeaderRow({headerText: 'Metadata'}),
            emptyRow(),

            createRow({
                headerText: 'Filing Date',
                records: financialRecords,
                getCellValue: record => record.filing_date,
                formatValue: formatFullDate
            }),
            createRow({
                headerText: 'Filing Date is Estimated?',
                records: financialRecords,
                getCellValue: record => record.filing_date_is_estimated,
                formatValue: formatBooleanYesNo,
                getCellClassName: makeGetEmphasizeYesBoolean({
                    field: 'filing_date_is_estimated',
                    colorOverride: 'black'
                }),
            }),
            emptyRow(),
            createRow({
                headerText: 'Cost Detail Type',
                records: financialRecords,
                getCellValue: record => record.cost_detail_type,
                formatValue: formatString
            }),



        ]
    );

    return (
        <div className={getTableClassName(loading) + ' align-right'}>

            <ReactDataGrid
                columns={periodRow}
                rowGetter={index => dataRows[index]}
                rowsCount={dataRows.length}
                rowRenderer={ModelRowRenderer}
                rowHeight={22}
                headerRowHeight={30}
                minHeight={getWindowHeightLessMenuBar()}

            />

        </div>

    )

}


