import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Container, Divider, Form, Header, Icon, Table} from 'semantic-ui-react'
import {
    fetchDoradoConstants,
    fetchDoradoPortfolioParameters, patchDoradoPortfolioParameters
} from "../../../features/dorado/doradoPortfolio";
import {formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import {
    assumedImplementationCostOptions,
    longConcentrationScoreOptions,
    FormItemWithDesc, maximumLimitPercentOptions,
    longMaxSinglePositionWeightOptions,
    roundToFourDigits,
    startEndDateOptions,
    targetCashOptions,
    tradeCostPercentOptions
} from "./common";
import PortfolioParameters from "./PortfolioParameters";
import {formatPercentOneDecimalNoNull} from "../../../common/format";


export default () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDoradoPortfolioParameters());
        dispatch(fetchDoradoConstants());
    }, [dispatch]);

    const {
        doradoPortfolioParameters,
        loadingDoradoPortfolioParameters,
        doradoConstants,
        loadingDoradoConstants
    } = useSelector(state => state.doradoPortfolio, shallowEqual);

    const dispatchPatchObject = (patchObject) => dispatch(patchDoradoPortfolioParameters(patchObject));

    return (
        <Container>
            <Header as='h1'>Live Portfolio Parameters</Header>
            <p>Last modified {formatFullDateTimeUtcToLocal(doradoPortfolioParameters.last_modified)}</p>

            <Form loading={loadingDoradoPortfolioParameters}>
                <Divider horizontal>
                    <Header as='h4'>
                        <Icon name='bolt'/>
                        Live-Only Portfolio Parameters
                    </Header>
                </Divider>
                <br/>
                <FormItemWithDesc>
                    <Form.Select options={maximumLimitPercentOptions}
                                 value={roundToFourDigits(doradoPortfolioParameters.order_maximimum_limit_vs_last_price_percent)}
                                 onChange={(e, d) => {
                                     const patchObject = {
                                         id: doradoPortfolioParameters.id,
                                         order_maximimum_limit_vs_last_price_percent: d.value
                                     };

                                     dispatchPatchObject(patchObject);
                                 }}
                                 label={'Orders: Max Limit % vs Last Price'}
                                 width={3}
                    />
                    <p>
                        Limit max difference vs. last price. Even if we are using a high implementation cost
                        algorithm to create trades (ex: 5%), we don't want to set our limits that high for trading
                        purposes. This will limit our orders accordingly.
                    </p>
                </FormItemWithDesc>
                <PortfolioParameters dispatchPatchObject={dispatchPatchObject}
                                     parameters={doradoPortfolioParameters}

                />


            </Form>
            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='globe'/>
                    Dorado Constants
                </Header>
            </Divider>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Value
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Description
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell width={4}>
                            Market After-Tax Required Return
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(doradoConstants.market_after_tax_required_return)}
                        </Table.Cell>
                        <Table.Cell>
                            Required return used as baseline for exit multiple calculations.
                            <br/>A 5% after-tax market return would provide a 0% growth business a 20x exit earnings
                            multiple (1/(.05 - 0)).
                            <br/>A 6% after-tax market return would provide a 0% growth business a 16.7x exit earnings
                            multiple.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Tax Rate for Required Return
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(doradoConstants.market_tax_rate_for_required_return)}
                        </Table.Cell>
                        <Table.Cell>
                            Global standard tax rate used to calculate pretax multiples at exit. A 20x exit earnings
                            multiple
                            would be 15x pretax with a 25% tax rate.
                            Logic for a standard rate is by exit we really can't differentiate the long-term tax rate
                            between businesses.
                            For example, we don't tend to use different exit multiples for US vs. Canada even though the
                            tax
                            rates are slightly different.
                        </Table.Cell>
                    </Table.Row>


                    <Table.Row>
                        <Table.Cell>
                            Buy Price IRR
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(doradoConstants.buy_price_irr)}
                        </Table.Cell>
                        <Table.Cell>
                            Discount Rate for Buy Price Calculation
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Dorado Forecast: % of FCF used for debt paydown
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(doradoConstants.dorado_forecast_percent_of_fcf_used_for_debt_paydown)}
                        </Table.Cell>
                        <Table.Cell>
                            In our dorado forecasts, if a company has debt, we allow them to pay down debt with up to
                            x% of FCF.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Dorado Forecast: Max % of FCF used for share repurchase
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(doradoConstants.maximum_percent_of_fcf_used_for_share_repurchase)}
                        </Table.Cell>
                        <Table.Cell>
                            Max % of FCF a company can use to repurchase shares. Repurchase will be the min of this
                            value and the
                            company's historical average % repurchase + dividends.
                        </Table.Cell>
                    </Table.Row>


                </Table.Body>

            </Table>
            <br/>
            <br/>
            <br/>
            <br/>


        </Container>
    )
}
