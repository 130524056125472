import React from 'react'
import {Button, Table, Label, Message, Input, Form} from 'semantic-ui-react'
import {formatSharePrice, formatPercentNoNull} from '../../../common/format'
import {useAuth0} from "../../../auth/react-auth0-wrapper";
import {getAbbreviation} from "../../../common/users/users"
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {createSetFieldValueOnQuery} from '../../../common/query/add'
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {
    createNewTargetPriceRecord,
    editTargetPrice
} from "../../../actions/companyNoteActions"
import {fieldSpecs} from '../common'


const targetPriceFieldSpecs = [
    {field: 'buy_price', color: 'green', label: 'Buy', isLong: true},
    {field: 'akkr_coverage_buy_price', color: 'purple', label: 'AKKR Coverage Buy', isLong: true},
];


export default ({selectedCurrency}) => {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {user} = useAuth0();
    const {
        company,
        loadingCompany,
        sharePriceTrading,
        loadingSharePriceTrading} = useSelector(state => state.company, shallowEqual);

    const {
        targetPriceRecords, loadingTargetPriceRecords, newTargetPriceRecord
    } = useSelector(state => state.companyStore, shallowEqual);

    const loading = loadingCompany || loadingSharePriceTrading || loadingTargetPriceRecords;

    if (loading) {
        return <div/>
    }

    if (!company.is_public) {
        return <div/>
    }

    if (!searchProps.isEditingTargetPrice) {
        return (
            <div>
                <TargetPricesView targetPriceRecords={targetPriceRecords}
                                  primarySharePrice={sharePriceTrading}/>

                <Button onClick={createSetFieldValueOnQuery({
                    history,
                    searchProps,
                    fieldName: 'isEditingTargetPrice',
                    value: true
                })}>
                    Edit Targets
                </Button>
            </div>);
    }


    const addNewRecord = (e, d) => {

        const closeCreateRecord = createSetFieldValueOnQuery({
            history,
            searchProps,
            fieldName: 'isEditingTargetPrice',
            value: false
        });

        // add name and currency to record
        newTargetPriceRecord.user_name = getAbbreviation(user.name);
        newTargetPriceRecord.currency = selectedCurrency;
        newTargetPriceRecord.company_id = company.id;

        createNewTargetPriceRecord({dispatch, record: newTargetPriceRecord});

        closeCreateRecord();
    };

    return (
        <div>
            <Message info><Message.Header>Target Prices in Edit Mode</Message.Header></Message>
            <EditTargetPricesView newTargetPriceRecord={newTargetPriceRecord}
                                  primarySharePrice={sharePriceTrading}/>

            <Form>
                <Form.TextArea
                    label='Rationale'
                    value={newTargetPriceRecord.rationale || ''}
                    onChange={(e, d) => {
                        editTargetPrice({dispatch, recordPatches: {rationale: d.value}})
                    }}
                />
            </Form>
            <br/>
            <Button primary onClick={addNewRecord}>
                Save Targets
            </Button>
        </div>
    )


}


const TargetPricesView = ({targetPriceRecords, primarySharePrice}) => {

    if (targetPriceRecords.length === 0) {
        return <div/>
    }

    const mostRecentTargetPriceRecord = targetPriceRecords[0];

    let rows = targetPriceFieldSpecs.map(fieldSpec => {

        const targetPrice = mostRecentTargetPriceRecord[fieldSpec.field];

        if (targetPrice == null || primarySharePrice == null) {
            return null
        }

        const priceText = formatSharePrice(targetPrice) + (fieldSpec.isLong ? ' or lower' : ' or higher');

        let priceDistance = '';

        let percentFromTarget = null;
        if (fieldSpec.isLong) {
            percentFromTarget = targetPrice / primarySharePrice - 1;
            if (percentFromTarget < 0) {
                priceDistance = formatPercentNoNull(-percentFromTarget) + ' decrease from current';
            } else {
                priceDistance = 'In Range';
            }
        } else {

            percentFromTarget = targetPrice / primarySharePrice - 1;
            if (percentFromTarget > 0) {
                priceDistance = formatPercentNoNull(percentFromTarget) + ' increase from current';
            } else {
                priceDistance = 'In Range';
            }

        }

        return (
            <Table.Row key={fieldSpec.label}>
                <Table.Cell>
                    <Label color={fieldSpec.color}>{fieldSpec.label}</Label>
                </Table.Cell>
                <Table.Cell>
                    {priceText}
                </Table.Cell>
                <Table.Cell>
                    {priceDistance}
                </Table.Cell>
            </Table.Row>
        )
    });

    rows = rows.filter(row => row != null);

    return (
        <Table textAlign='left' basic='very' collapsing compact>
            <Table.Body>
                {rows}
            </Table.Body>
        </Table>
    )
};


const EditTargetPricesView = ({newTargetPriceRecord, primarySharePrice}) => {

    const dispatch = useDispatch();

    let rows = targetPriceFieldSpecs.map(fieldSpec => {

        const targetPrice = newTargetPriceRecord[fieldSpec.field];

        const priceInput = <Input placeholder={'Enter price...'}
                                  defaultValue={targetPrice}
                                  onChange={(e, d) => {
                                      editTargetPrice({dispatch, recordPatches: {[fieldSpec.field]: d.value}})
                                  }}
        />;

        let priceDistance = '';
        let percentFromTarget = null;

        if (targetPrice != null) {
            percentFromTarget = targetPrice / primarySharePrice - 1;
        }

        if (percentFromTarget != null) {
            if (fieldSpec.isLong) {
                if (percentFromTarget < 0) {
                    priceDistance = formatPercentNoNull(-percentFromTarget) + ' decrease from current';
                } else {
                    priceDistance = 'In Range';
                }
            } else {
                if (percentFromTarget > 0) {
                    priceDistance = formatPercentNoNull(percentFromTarget) + ' increase from current';
                } else {
                    priceDistance = 'In Range';
                }
            }
        }


        return (
            <Table.Row key={fieldSpec.label}>
                <Table.Cell>
                    <Label color={fieldSpec.color}>{fieldSpec.label}</Label>
                </Table.Cell>
                <Table.Cell>
                    {priceInput}
                </Table.Cell>
                <Table.Cell>
                    {priceDistance}
                </Table.Cell>
            </Table.Row>
        )
    });

    // rows = rows.filter(row => row != null);

    return (
        <Table textAlign='left' basic='very' collapsing compact>
            <Table.Body>
                {rows}
            </Table.Body>
        </Table>
    )
};

