import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Statistic, Table} from 'semantic-ui-react'
import {validMoment} from "../../common/format";

export default ({portfolios, loading}) => {

    const portfolioRows = portfolios.map(p => {
        return (
            <Table.Row key={p.id}>
                <Table.Cell>
                    {p.type}
                </Table.Cell>
                <Table.Cell>
                    {validMoment(p.trade_data_as_of_standard_date, 'M/D/YYYY')}
                </Table.Cell>
            </Table.Row>
        )
    })

    return (
        <Table compact collapsing basic={'very'}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Portfolio
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Trade Data as of
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {portfolioRows}
            </Table.Body>
        </Table>
    )
}