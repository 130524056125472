import {
    formatMultipleTwoDecimals,
    formatNoDecimalNoNull,
    formatPercentOneDecimalNoNull
} from "../../../../common/format";
import moment from "moment";
import {extendedBacktestFieldSpecs} from '../../../dorado/companyViews/common'
import {parseBoolean} from "../../../../common/query/parse";


export const valuationMetricChartSpecifications = [

    {
        name: 'IRR (using prior day forecast)',
        getData: ({doradoValuationRecords}) => {
            return doradoValuationRecords.map(record => {
                return [moment(record.as_of_date).valueOf(), record.irr_using_prior_day_forecast]
            })
        },
        getYAxisLabel: () => 'IRR (using prior day forecast)',
        formatY: formatPercentOneDecimalNoNull,
        type: 'line'
    },
    {
        name: 'IRR (post-market close)',
        getData: ({doradoValuationRecords}) => {
            return doradoValuationRecords.map(record => {
                return [moment(record.as_of_date).valueOf(), record.irr]
            })
        },
        getYAxisLabel: () => 'IRR (post-market close)',
        formatY: formatPercentOneDecimalNoNull,
        type: 'line'
    },
    {
        name: 'Relative IRR Percentile',
        getData: ({doradoValuationRecords}) => {
            return doradoValuationRecords.map(record => {
                return [moment(record.as_of_date).valueOf(), record.relative_irr_percentile]
            })
        },
        getYAxisLabel: () => 'Relative IRR Percentile',
        formatY: formatPercentOneDecimalNoNull,
        type: 'line',
        yMin: 0,
        yMax: 1
    },

    {
        name: 'Dorado Exit Multiple Execution Discount',
        getData: ({doradoValuationRecords}) => {
            return doradoValuationRecords.map(record => {
                return [moment(record.as_of_date).valueOf(), record.exit_multiple_discount]
            })
        },
        getYAxisLabel: () => 'Dorado Exit Multiple Execution Discount',
        formatY: formatPercentOneDecimalNoNull,
        type: 'line',
        yMin: 0,
        yMax: 1,
    },
    {
        name: '5yr Avg Sales Efficiency',
        getData: ({doradoValuationRecords}) => {
            return doradoValuationRecords.map(record => {
                return [moment(record.as_of_date).valueOf(), record.entry_average_implemented_arr_to_sm]
            })
        },
        getYAxisLabel: () => '5yr Avg Sales Efficiency',
        formatY: formatMultipleTwoDecimals,
        type: 'line',
        yMin: 0,
    },



];


export const tradeMetricChartSpecifications = [
    {
        name: 'Daily Trade % Equity',
        getData: ({portfolioPositions}) => {
            return portfolioPositions.map(record => {
                return [moment(record.standard_date).valueOf(), record.daily_trade_net_value_percent_of_equity]
            })
        },
        getYAxisLabel: () => 'Daily Trade % Equity',
        formatY: formatPercentOneDecimalNoNull,
        type: 'column'
    },
    {
        name: '% of Equity',
        getData: ({doradoValuationRecords, portfolioPositions}) => {
            return portfolioPositions.map(record => {
                return [moment(record.standard_date).valueOf(), record.percent_of_net_asset_value]
            })
        },
        getYAxisLabel: () => '% of Equity',
        yMin: 0,
        formatY: formatPercentOneDecimalNoNull,
        type: 'column'
    },
    {
        name: 'Daily Trade $',
        getData: ({portfolioPositions}) => {
            return portfolioPositions.map(record => {
                return [moment(record.standard_date).valueOf(), record.daily_trade_net_value]
            })
        },
        getYAxisLabel: () => 'Daily Trade $',
        formatY: formatNoDecimalNoNull,
        type: 'column'
    },

    {
        name: 'Position Value',
        getData: ({portfolioPositions}) => {
            return portfolioPositions.map(record => {
                return [moment(record.standard_date).valueOf(), record.market_value]
            })
        },
        getYAxisLabel: () => 'Market Value',
        formatY: formatNoDecimalNoNull,
        type: 'column'
    },

    {
        name: 'Net Shares',
        getData: ({doradoValuationRecords, portfolioPositions}) => {
            return portfolioPositions.map(record => {
                return [moment(record.standard_date).valueOf(), record.net_shares]
            })
        },
        getYAxisLabel: () => 'Net Shares',
        formatY: formatNoDecimalNoNull,
        type: 'column'
    },
];


export const series2ChartSpecificationByName = valuationMetricChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});


export const tradeMetricChartSpecificationByName = tradeMetricChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});


export const fieldSpecs = {
    series2ChartName: {
        parse: (v) => v == null ? valuationMetricChartSpecifications[0].name : v
    },
    tradeMetricChartName: {
        parse: (v) => v == null ? tradeMetricChartSpecifications[0].name : v
    },
    showBuyPrice: {
        parse: parseBoolean
    },
    showFairPrice: {
        parse: parseBoolean
    },
    showFairPriceScaled: {
        parse: parseBoolean
    },
    showBuyPriceScaled: {
        parse: parseBoolean
    },
    portfolio_id: {
        parse: v => v == null || v === '<null>' ? null : parseInt(v)
    },
    ...extendedBacktestFieldSpecs
};


export const valuationMetricChartOptions = valuationMetricChartSpecifications.map(spec => {
    return {
        key: spec.name,
        value: spec.name,
        text: spec.name
    }
});
export const positionChartOptions = tradeMetricChartSpecifications.map(spec => {
    return {
        key: spec.name,
        value: spec.name,
        text: spec.name
    }
});