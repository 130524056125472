import React from 'react';
import {formatSharePrice} from "../../common/format";
import {shallowEqual, useSelector} from "react-redux";
import {Loader, Label} from 'semantic-ui-react'


export default () => {

    const {company, loadingCompany, sharePriceTrading, loadingSharePriceTrading} = useSelector(state => state.company, shallowEqual);

    if (loadingCompany) {
        return <Loader active inline='centered'/>
    }

    if (company.is_public) {
        return <Label color="teal" horizontal>
            Public
            <Label.Detail>
                {formatSharePrice(sharePriceTrading)}
            </Label.Detail>
        </Label>;
    } else {
        return <Label color="grey">Private</Label>;
    }


}