import {EstimateType, PeriodType} from "../../../common/constants";

export const createHeaderRow = ({headerText}) => {

    return {
        rowHeader: headerText,
        _header: true
    }
};

export const createSectionHeaderRow = ({headerText}) => {
    return {
        rowHeader: headerText,
        _sectionHeader: true
    }
};

export const emptyRow = () => {
    return {}
};

export const fieldSpecs = {
    currencyType: {
        parse: (value) => {
            if (value) {
                return value
            }
            return 'Trading'
        }
    },
    periodType: {
        parse: (value) => {
            if (value) {
                return value
            }
            return PeriodType.annual
        }
    },
    estimateType: {
        parse: (v) => {
            if (v == null) {
                return EstimateType.pointSurEstimate
            }
            return v
        }
    }
};
