import React, { Component} from 'react';
import { connect } from 'react-redux';
import {Container, Header, Segment, Image} from 'semantic-ui-react';



class DocumentationPage extends Component {


  render() {
    return (
      <Container>

        <Header as='h1'>Overview</Header>

        <p>Today, most employees of software companies have a linkedin profile.  This profile contains a few
          key pieces of information, the <b>company</b> they work for and their <b>job title</b>.
        </p>
        <p>This information can be used to create a census for the company if we believe a few key assumptions:</p>
        <ul>
          <li>
            A representative sample of company employees have created linkedin profiles and maintain current profiles
          </li>
          <li>
            The self-reported titles are reasonably accurate
          </li>
        </ul>


        <Header as='h1'>Company Analysis</Header>
        <p>A linkedin census is created through the following steps:</p>
        <ol>
          <li>
            Acquire linkedin profiles for every employee of the company and upload to database.  Raw profiles can be viewed at <b>Employee Records</b>.
          </li>
          <li>
            Create standard <b>Roles</b> for employees of the company.  Roles can be created by researching employee self reported titles.  We can
            also use job listings posted on various websites (<b>Jobs</b>) to understand the core positions of the company.  Each role is assigned a cost center
            (COGS, R&D, S&M, G&A) and a cost subcategory (new business sales vs. account management, for example) to organize the role for analysis.
          </li>
          <li>
            Based on an employee's profile, particularly his/her self-reported title and job description, assign that employee to a Role.  Multiple employees with the same
            exact job title can be assigned to a role at once through the <b>Titles</b> page.
          </li>
          <li>
            After the majority of employees have been assigned a role, we can view the <b>Company Analysis</b> page to view employee counts aggregated by role,
             essentially an employee census.
          </li>
        </ol>

        <br/>
        <Header as='h1'>Entities</Header>

        <Header as='h2' attached='top'>
          Employee Record
        </Header>
        <Segment attached>
          <p>Linkedin records for each individual who self-reports being a current employee of the company.</p>
          <p>Individuals typically report two fields describing their job. "Title" appears directly below
            the record. "Current" is also reported for some people, which may be a repeat of Title or a different
            description.
          </p>
          <p>Example below</p>
          <Image bordered src='/assets/static/media/linkedin_profile.png'/>
        </Segment>

        <Header as='h2' attached='top'>
          Title
        </Header>
        <Segment attached>
          <p>A "title" is the self-reported job description of the employee.  It may refer to the exact job (Software Engineer II)
          or it may refer to the general role (Sales), or it may be something else ("Dynamic, Entrepreneurial individual")
          </p>
          <p>Our goal is to classify each employee into their true job title/role at the company.
          </p>
        </Segment>

      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {

  }
}



export default connect(mapStateToProps, {})(DocumentationPage);
