import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Form, Header} from 'semantic-ui-react'
import {parseSearchProps} from "../../common/query/parse";
import {useHistory, useLocation} from "react-router-dom";
import {fieldSpecs, ySpecs} from './common'
import {createAddValueToQuery} from "../../common/query/add";


const yOptions = ySpecs.map((spec) => {
    return {
        key: spec.name,
        text: spec.name,
        value: spec.name,
    }
});

// allow second y value to be null
const yOptions2 = yOptions.slice(0);
yOptions2.unshift({key: null, text: null, value: null});

export default () => {

    const location = useLocation();
    const history = useHistory();

    const {company, loadingCompany} = useSelector(state => state.companyStore, shallowEqual);

    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs: fieldSpecs});

    return (
        <div>
            <Header>
                {loadingCompany ? null : company.company_name}
            </Header>
            <Form>
                <Form.Select
                    fluid
                    inline
                    options={yOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'yAxis1'})}
                    value={searchProps.yAxis1}
                    label="Y Axis: Series 1"
                />
                <Form.Select
                    fluid
                    inline
                    options={yOptions2}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'yAxis2'})}
                    value={searchProps.yAxis2}
                    label="Y Axis: Series 2"
                />
            </Form>
        </div>
    )


}