import React from 'react';
import {
    DateFormatter, NoDecimalNoNullFormatter,
    PositiveNegativeTwoDecimalFormatter,
    SharePriceFormatter, SharePriceFourDecimalFormatter,
    UtcDateFormatter
} from "../../../common/reactDataGrid/formatters";


export const columns = [
    {
        key: 'standard_date',
        name: 'Date / Payment Date',
        sortable: true,
        formatter: DateFormatter,
        width: 90
    },
    {
        key: 'trade_type',
        name: 'Trade Type',
        sortable: true,
        width: 200
    },
    {
        key: 'net_value',
        name: 'Net Value',
        sortable: true,
        width: 120,
        formatter: PositiveNegativeTwoDecimalFormatter,
    },
    {
        key: 'currency',
        name: 'Currency',
        sortable: true,
        width: 90
    },
    {
        key: 'net_value_usd',
        name: 'Net Value $USD',
        sortable: true,
        width: 120,
        formatter: PositiveNegativeTwoDecimalFormatter,
    },
    {
        key: 'source_account_type',
        name: 'Source Account Type',
        sortable: true,
        width: 200
    },

];

