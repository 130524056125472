import React, {useEffect} from 'react'
import {Form} from 'semantic-ui-react'
import {getFilterComponentsFromSpecs} from '../../common/filter/add'
import {commonFilterSpecsByName} from '../../common/filter/common';
import {parseSearchProps} from "../../common/query/parse";
import CompanySelectBox from '../../components/CompanySelectBox'
import {useDispatch} from "react-redux";
import {fetchDataQualityFacets} from "../../actions/benchmarkSummaryAction";
import {useHistory, useLocation} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";


const filterSpecs = [
    {
        field: 'coverage',
        label: 'Coverage',
        type: 'terms',
        allowNullSelection: true,
    },
];


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    useEffect(() => {
        fetchDataQualityFacets({dispatch})
    }, [dispatch]);

    const {facetsByField, loadingFacets} = useSelector(state => state.coverageSummaryStore, shallowEqual);

    const filters = getFilterComponentsFromSpecs({
            filterSpecs,
            facetsByField: facetsByField,
            history,
            searchProps
        }
    );

    return (
        <Form loading={loadingFacets}>
            <CompanySelectBox/>
            {filters}
        </Form>
    )

}
