import React, {Component} from 'react'
import ReactDataGrid from 'react-data-grid';
import {
  StringFormatter,
  UtcDateFormatter,
  NoDecimalNoNullOrZeroFormatter,
  PercentNoDecimalNoNullFormatter
} from '../../../common/reactDataGrid/formatters';
import {getWindowHeightLessMenuBar} from '../../../common/common'
import {
  getMostRecentSortColumnAndDirectionForRDG,
  getRdgRowsFromRecords,
  makeHandleReactDataGridSort
} from '../../../common/reactDataGrid/common'
import {parseSearchProps} from "../../../common/query/parse";
import {createAppendSortToQuery} from "../../../common/query/add";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {getRolesByCompanyUrl} from '../../../common/links'
import DeleteModalButton from '../../../components/DeleteModal'
import {deleteEmployeeScrapeRecord} from '../../../actions/employees/scrapeRecordActions'
import {downloadRecordsForScrapeId} from '../../../actions/employees/exportActions'
import {Button, Icon} from 'semantic-ui-react'
import {associateUrlSuffix, getAssociateUrl} from '../urls'


class AnalyzeLinkFormatter extends React.Component {
  render() {
    return <Link to={'/employee_analysis/analyze/' + this.props.value}>Analyze</Link>
  }
}


class AssociateLinkFormatter extends React.Component {
  render() {
    return <Link to={getAssociateUrl({scrapeId: this.props.value, suffix: associateUrlSuffix})}>Associate</Link>
  }
}

class ViewRolesFormatter extends React.Component {
  render() {
    return <Link to={getRolesByCompanyUrl({companyId: this.props.value})}>View Roles</Link>
  }
}

class DeleteLinkFormatter extends React.Component {
  render() {

    const onClick = () => this.props.deleteEmployeeScrapeRecord({id: this.props.value}).then(this.props.refresh());

    return (
      <DeleteModalButton
        buttonText='Delete'
        onDelete={onClick}
        headerContent="Delete Employee Records"
        content="Are you sure you want to delete this set of scraped employee records?"
        size="mini"
      >
      </DeleteModalButton>
    )
  }
}


class DownloadFormatter extends React.Component {
  render() {

    if (this.props.loading) {
      return <Button loading size="mini" basic color="blue"/>
    }

    return (
      <Button icon loading={this.props.loading} size="mini" basic color="blue"
              onClick={() => this.props.downloadRecordsForScrapeId(this.props.value)}>
        <Icon name="cloud download">
        </Icon>
      </Button>
    )


  }

  onClickDownload() {
    this.exportForm.submit()
  }
}


class EmployeeScrapeTable extends Component {

  render() {
    const searchProps = parseSearchProps({searchString: this.props.location.search});
    const records = this.props.records;

    const onChangeSort = createAppendSortToQuery({history: this.props.history, searchProps: searchProps});
    const handleReactDataGridSort = makeHandleReactDataGridSort(onChangeSort);
    const {sortColumn, sortDirection} = getMostRecentSortColumnAndDirectionForRDG(searchProps);

    const columns = [
      {
        key: 'company_name',
        name: 'Company',
        width: 200,
        frozen: true,
        sortable: true,
        formatter: StringFormatter,
        _getValueFromRecord: (r) => {
          return r.company.company_name
        }
      },
      {
        key: 'date_acquired',
        name: 'Date Acquired',
        sortable: true,
        width: 100,
        formatter: UtcDateFormatter
      },
      {
        key: 'total_employee_count',
        name: 'Stated Total Employees',
        sortable: true,
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter
      },
      {
        key: 'scraped_employee_count',
        name: 'Scraped Records',
        sortable: true,
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter
      },
      {
        key: 'associated_percent',
        name: '% Associated',
        sortable: false,
        width: 80,
        formatter: PercentNoDecimalNoNullFormatter
      },
      {
        key: 'analyze',
        name: '',
        width: 90,
        sortable: false,
        formatter: AnalyzeLinkFormatter,
        _getValueFromRecord: (r) => {
          return r.id
        }
      },
      {
        key: 'associate',
        name: '',
        width: 90,
        sortable: false,
        formatter: AssociateLinkFormatter,
        _getValueFromRecord: (r) => {
          return r.id
        }
      },
      {
        key: 'roles',
        name: '',
        width: 90,
        sortable: false,
        formatter: ViewRolesFormatter,
        _getValueFromRecord: (r) => {
          return r.company.id
        }
      },
      {
        key: 'delete',
        name: '',
        width: 90,
        formatter: <DeleteLinkFormatter deleteEmployeeScrapeRecord={this.props.deleteEmployeeScrapeRecord}
                                        refresh={this.props.refreshRecords}/>,
        _getValueFromRecord: (r) => {
          return r.id
        }
      },
      {
        key: 'download',
        name: '',
        width: 50,
        formatter: <DownloadFormatter downloadRecordsForScrapeId={this.props.downloadRecordsForScrapeId}
                                      loading={this.props.loadingDownloadRecords}/>,
        _getValueFromRecord: (r) => {
          return r.id
        }
      }
    ];

    const rows = getRdgRowsFromRecords({records: records, columns: columns});

    let className = 'financials-size';
    if (this.props.loading) {
      className += ' loading-grid'
    }

    return (

      <div className={className}>
        <ReactDataGrid
          columns={columns}
          onGridSort={handleReactDataGridSort}
          rowGetter={index => rows[index]}
          rowsCount={rows.length}
          rowHeight={28}
          headerRowHeight={50}
          minHeight={getWindowHeightLessMenuBar()}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      </div>

    )
  }

}


function mapStateToProps(state) {
  return {
    records: state.employeeRecordStore.employeesScrapeRecords,
    loading: state.employeeRecordStore.loadingEmployeesScrapeRecords,
    loadingDownloadRecords: state.employeeRecordStore.loadingDownloadRecords,
  }
}


export default withRouter(connect(mapStateToProps,
  {deleteEmployeeScrapeRecord, downloadRecordsForScrapeId})(EmployeeScrapeTable));



