import client from '../../benchClient';
import {PeriodType, SourceType} from "../../common/constants";


const doradoFinancialPeriodRecordsService = client.service('dorado_financial_period_records');
const doradoAuditMetadataRecordsService = client.service('dorado_audit_metadata_records');



export function fetchDoradoFinancialPeriodRecordsForMetadata({dispatch, doradoForecastMetadataId, periodType}) {

    let periodTypes = [periodType];
    if (periodType === PeriodType.interim) {
        periodTypes = [PeriodType.quarterly, PeriodType.half];
    }

    return dispatch({
        type: 'FETCH_DORADO_FINANCIAL_PERIOD_RECORDS_FOR_METADATA',
        payload: doradoFinancialPeriodRecordsService.find({
            query: {
                dorado_forecast_metadata_id: doradoForecastMetadataId,
                $sort: {
                    period_end_date: 1
                },
                period_type: {
                    $in: periodTypes
                }
            }

        })
    })
}


export function fetchAllAnnualDoradoFinancialPeriodRecordsForCompany({dispatch, companyId}) {

    return dispatch({
        type: 'FETCH_ALL_DORADO_FINANCIAL_PERIOD_RECORDS_FOR_COMPANY',
        payload: doradoFinancialPeriodRecordsService.find({
            query: {
                company_id: companyId,
                period_type: PeriodType.annual,
                record_type: {
                    $in: [SourceType.dorado, SourceType.pointSurEstimate]
                },
            }

        })
    })
}


export const fetchDoradoAuditRecordsForCompany = ({dispatch, companyId}) => {

    return dispatch({
        type: 'FETCH_DORADO_AUDIT_RECORDS_FOR_COMPANY',
        payload: doradoAuditMetadataRecordsService.find({
            query: {
                company_id: companyId,
                $sort: {
                    ntm_year: 1
                },

            }

        })
    })
}