import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Button, Container, Divider, Form, Grid, Header, Table} from 'semantic-ui-react'
import {fetchCompany} from '../../../../actions/companyActions';
import {useReloadOnChange} from "../../../../common/hooks";
import CompanyHeader from "../../../../components/CompanyHeader";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import ViewWithSidebar from "../../../../components/ViewWithSidebar";
import {
    fetchDoradoPredictionsForCompany,
    fetchDoradoRegressionSummariesForCompany
} from "../../../../features/dorado/doradoPredictions";
import {
    applyConstraintToPredictions,
    chartFieldSpecs,
    xAxisChartSpecificationByName,
    xAxisChartSpecifications, yAxisChartSpecificationByName,
    yAxisChartSpecifications
} from "./common";
import PredictionsChart from "./PredictionsChart";
import SummarySidebar from "./SummarySidebar";


const fieldSpecs = {
    ...chartFieldSpecs
}


export default () => {

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['predictionConstraint'],
        matchFields: ['id'],
        requestData: requestCompanyData
    });

    const {
        company,
        loadingCompany
    } = useSelector(state => state.companyStore, shallowEqual);

    const {
        doradoPredictions,
        loadingDoradoPredictions,
        doradoRegressionSummaries,
        loadingDoradoRegressionSummaries
    } = useSelector(state => state.doradoPredictions, shallowEqual);


    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const sidebar = <SummarySidebar company={company}
                                    loadingCompany={loadingCompany}
                                    doradoRegressionSummaries={doradoRegressionSummaries}
                                    history={history}
                                    searchProps={searchProps}
    />

    const xAxisChartSpecification = xAxisChartSpecificationByName[searchProps.x];
    const yAxisChartSpecification = yAxisChartSpecificationByName[searchProps.y];

    const filteredPredictions = applyConstraintToPredictions({
        doradoPredictions,
        constraint: searchProps.predictionConstraint,
        daysText: yAxisChartSpecification.suffix
    });

    const selectedRegressionParams = getSelectedRegressionParams({
        doradoRegressionSummaries,
        loadingDoradoRegressionSummaries,
        predictor: searchProps.x,
        y: searchProps.y
    })

    const latestPredictionRecord = doradoPredictions.length === 0 ? null : doradoPredictions[doradoPredictions.length - 1];

    return (
        <ViewWithSidebar mainView={
            <PredictionsChart doradoPredictions={filteredPredictions}
                              xAxisChartSpecification={xAxisChartSpecification}
                              yAxisChartSpecification={yAxisChartSpecification}
                              selectedRegressionParams={selectedRegressionParams}
                              latestPredictionRecord={latestPredictionRecord}
            />}
                         sidebarView={sidebar}
                         sidebarOpenWidth={7}
        />
    )

}


const getSelectedRegressionParams = ({
                                         doradoRegressionSummaries,
                                         loadingDoradoRegressionSummaries,
                                         predictor,
                                         y
                                      }) => {

    if (loadingDoradoRegressionSummaries) {
        return null
    }

    const doradoRegressionSummary = doradoRegressionSummaries.filter(s => s.predictor === predictor)[0];

    return {
        r2: doradoRegressionSummary['r2_' + y],
        intercept: doradoRegressionSummary['intercept_' + y],
        slope: doradoRegressionSummary['slope_' + y]
    }


}



const requestCompanyData = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    fetchCompany({dispatch, id});
    dispatch(fetchDoradoPredictionsForCompany({companyId: id}));
    dispatch(fetchDoradoRegressionSummariesForCompany({
        companyId: id,
        predictionConstraint: searchProps.predictionConstraint
    }));
};