import React from "react";
import {getWindowHeightLessMenuBar} from "../../../../common/common";
import moment from 'moment'
import Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import {Loader} from "semantic-ui-react";
import {ForecastType} from "../../../../common/constants";
import {formatSharePrice, formatPercentNoNull, formatPercentOneDecimalNoNull} from "../../../../common/format";
import {series2ChartSpecificationByName, tradeMetricChartSpecificationByName} from "./common";
const ReactHighstock = require('react-highcharts/ReactHighstock');
Exporting(Highcharts);



class ChartComponent extends React.Component {

    componentDidMount() {
        if (!this.props.loading) {
            const chartHeight = getWindowHeightLessMenuBar();
            let chart = this.refs.chart.getChart();
            chart.setSize(undefined, chartHeight);
        }

    }

    componentDidUpdate() {
        if (!this.props.loading) {
            const chartHeight = getWindowHeightLessMenuBar();
            let chart = this.refs.chart.getChart();
            chart.setSize(undefined, chartHeight);
        }
    }

    render() {

        if (this.props.loading) {
            return <Loader active inline='centered'/>
        }

        const doradoValuationRecords = this.props.doradoValuationRecords;
        const revisedBacktestDoradoValuationRecords = this.props.revisedBacktestDoradoValuationRecords;
        const portfolioPositions = this.props.portfolioPositions;
        const showBuyPrice = this.props.showBuyPrice;
        const showFairPrice = this.props.showFairPrice;
        const showFairPriceScaled = this.props.showFairPriceScaled;
        const showBuyPriceScaled = this.props.showBuyPriceScaled;

        const highlightStartDate = this.props.highlightStartDate;
        const highlightEndDate = this.props.highlightEndDate;

        const valuationChartSpec = series2ChartSpecificationByName[this.props.series2ChartName];
        const positionChartSpec = tradeMetricChartSpecificationByName[this.props.tradeMetricChartName];
        const tradeMetricEnabled = this.props.tradeMetricEnabled;

        const priceSeries = {
            name: 'Share Price',
            data: doradoValuationRecords.map(record => {
                return [moment(record.as_of_date).valueOf(), record.entry_price_per_share]
            }),
            tooltip: {
                pointFormatter: function() {
                    return this.series.name + ": " + formatSharePrice(this.y)
                }
            }
        };

        const xAxisPlotBands = [];

        if (highlightStartDate != null) {
            xAxisPlotBands.push({
                from: moment(highlightStartDate).valueOf(),
                to: moment(highlightEndDate).valueOf(),
                color: 'rgba(68, 170, 213, .2)'
            });
        }

        const backtestRecords = doradoValuationRecords.filter(r => r.forecast_type === ForecastType.backtestModel);

        let pointSurRecords = doradoValuationRecords.filter(r => r.forecast_type === ForecastType.currentModel);

        if (pointSurRecords.length === 0) {
            pointSurRecords = backtestRecords.length ? [backtestRecords[backtestRecords.length - 1]] : [];
        }

        const upperValuationSeries = [];

        if (showBuyPrice) {
            upperValuationSeries.push(
                {
                    name: '15% IRR Buy Price (prior day model)',
                    data: backtestRecords.map(record => {
                        return [moment(record.as_of_date).valueOf(), record.discounted_exit_price_per_share_using_prior_day_forecast]
                    }),
                    color: 'black',
                    tooltip: {
                        pointFormatter: function () {
                            return this.series.name + ": " + formatSharePrice(this.y)
                        }
                    }
                }

            )
            if (revisedBacktestDoradoValuationRecords.length > 0) {
                upperValuationSeries.push(
                {
                    name: 'Methodology-Adj 15% IRR Buy Price',
                    data: revisedBacktestDoradoValuationRecords.map(record => {
                        return [moment(record.as_of_date).valueOf(), record.discounted_exit_price_per_share_using_prior_day_forecast]
                    }),
                    color: 'purple',
                    tooltip: {
                        pointFormatter: function () {
                            return this.series.name + ": " + formatSharePrice(this.y)
                        }
                    }
                }

            )
            }

        }

        if (showFairPrice) {
            upperValuationSeries.push(
                {
                    name: '6% IRR Fair Price (prior day model)',
                    data: backtestRecords.map(record => {
                        return [moment(record.as_of_date).valueOf(), record.fair_price_per_share_using_prior_day_forecast]
                    }),
                    color: '#8856a7',
                    tooltip: {
                        pointFormatter: function () {
                            return this.series.name + ": " + formatSharePrice(this.y)
                        }
                    }
                }

            )
        }

        if (showBuyPriceScaled) {
            upperValuationSeries.push(
                {
                    name: 'Buy Price: Scaled to 1yr vs Price',
                    data: backtestRecords.map(record => {

                        const scaleFactor = record.buy_price_vs_price_1_year_avg_using_prior_day_forecast;

                        const scaledPrice = record.discounted_exit_price_per_share_using_prior_day_forecast / scaleFactor;

                        return {
                            x: moment(record.as_of_date).valueOf(),
                            y: scaledPrice,
                            scaleFactor
                        }
                    }).filter(r => r.y != null),
                    color: 'black',
                    dashStyle: 'ShortDash',
                    tooltip: {
                        pointFormatter: function () {
                            return [
                                this.series.name + ": " + formatSharePrice(this.y),
                                '1yr Avg Buy Price / Price: ' + formatPercentNoNull(this.scaleFactor)
                            ].join("<br/>")
                        }
                    }
                }
            )

        }

        if (showFairPriceScaled) {
            upperValuationSeries.push(
                {
                    name: 'Fair Price: Scaled to 1yr vs Price',
                    data: backtestRecords.map(record => {

                        const scaleFactor = record.fair_price_vs_price_1_year_avg_using_prior_day_forecast;

                        const scaledPrice = record.fair_price_per_share_using_prior_day_forecast / scaleFactor;

                        return {
                            x: moment(record.as_of_date).valueOf(),
                            y: scaledPrice,
                            scaleFactor
                        }
                    }).filter(r => r.y != null),
                    color: '#8856a7',
                    dashStyle: 'ShortDash',
                    tooltip: {
                        pointFormatter: function () {
                            return [
                                this.series.name + ": " + formatSharePrice(this.y),
                                '1yr Avg Fair Price / Price: ' + formatPercentNoNull(this.scaleFactor)
                            ].join("<br/>")
                        }
                    }
                }
            )

        }



        const pointSurSeries = {
            name: 'Point Sur Valuation',
            data: pointSurRecords.map(record => {
                return [moment(record.as_of_date).valueOf(), record.discounted_exit_price_per_share]
            }),
            id: 'Point Sur Valuation',
            lineWidth: 0,
            label: {
                enabled: true,

            },
            marker: {
                enabled: true,
                radius: 5,
                symbol: 'circle'
            },
            opacity: .7,
            color: '#de2d26',
            tooltip: {
                pointFormatter: function() {
                    return this.series.name + ": " + formatSharePrice(this.y)
                }
            }

        };

        const lowerChartSeries = [{
            name: valuationChartSpec.name,
            data: valuationChartSpec.getData({doradoValuationRecords}),
            yAxis: 1,
            type: valuationChartSpec.type,
            color: '#005824',
            tooltip: {
                pointFormatter: function() {
                    return this.series.name + ": " + valuationChartSpec.formatY(this.y)
                }
            }
        }];

        if (revisedBacktestDoradoValuationRecords.length > 0) {
            lowerChartSeries.push(
                {
                    name: 'Methodology-Adj ' + valuationChartSpec.name,
                    data: valuationChartSpec.getData({doradoValuationRecords: revisedBacktestDoradoValuationRecords}),
                    color: 'purple',
                    tooltip: {
                        pointFormatter: function () {
                            return this.series.name + ": " + valuationChartSpec.formatY(this.y)
                        }
                    },
                    yAxis: 1
                }
            )
        }

        let positionSeries = {};

        if (tradeMetricEnabled) {
            positionSeries = {
                name: positionChartSpec.name,
                data: positionChartSpec.getData({portfolioPositions}),
                yAxis: 2,
                type: positionChartSpec.type,
                color: '#bababa',
                tooltip: {
                    pointFormatter: function () {
                        return this.series.name + ": " + positionChartSpec.formatY(this.y)
                    }
                }
            };

        }

        const yAxis = [
            {
                title: 'Stock Price',
                height: '50%',
                labels: {
                    format: '${value:.0f}'
                }

            },
            {
                title: '5-year IRR',
                top: '50%',
                height: '50%',
                labels: {
                    formatter: function () {
                        return valuationChartSpec.formatY(this.value)
                    }
                },
                min: valuationChartSpec.yMin,
                max: valuationChartSpec.yMax
            }
        ];

        const series = [
            priceSeries,
            ...upperValuationSeries,
            ...lowerChartSeries,
            pointSurSeries
        ];

        if (tradeMetricEnabled) {
            yAxis.push({
                title: 'Trade Metric',
                top: '75%',
                height: '25%',
                labels: {
                    formatter: function () {
                        return positionChartSpec.formatY(this.value)
                    }
                },
                min: positionChartSpec.yMin,
                max: positionChartSpec.yMax
            });

            yAxis[1].height = '25%';

            series.push(positionSeries);

        }






        const config = {

            series: series,
            legend: {
                enabled: true
            },
            xAxis: [
                {
                    plotBands: xAxisPlotBands,
                }
            ],
            yAxis: yAxis,
            tooltip: {
                xDateFormat: '%b-%d-%Y',
                shared: true
            },
            plotOptions: {
                series: {
                    dataGrouping: {
                        enabled: false
                    }
                }
            },
        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighstock config={config} ref="chart"></ReactHighstock>
            </div>
        )

    }
}


export default ChartComponent