import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {fetchDoradoUniverseValuationRecords} from "../../../features/dorado/doradoValuationRecords";
import {fieldSpecs} from "./common";
import {
    createAddCheckedValueToQuery,
    createAddValueToQuery,
    createSetFieldValuesOnQuery
} from "../../../common/query/add";
import UniverseIrrChart from "./UniverseIrrChart";
import {
    fetchPortfolios,
    fetchPortfoliosExMembers,
    fetchPortfolioStates, fetchPortfolioStateWithReports
} from "../../../features/portfolio/portfolioFeatureSlice";
import qs from 'qs';


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        doradoUniverseValuationRecords,
        loadingDoradoUniverseValuationRecords
    } = useSelector(state => state.doradoValuation, shallowEqual);

    const {
        portfolios,
        loadingPortfolios,
        portfolioStateWithReports,
        loadingPortfolioStateWithReports,
    } = useSelector(state => state.portfolio, shallowEqual);

    useEffect(() => {
        dispatch(fetchPortfolios({
            sort: {
                name: 1
            },
            filters: {
                name: {
                    $in: ['IGV']
                },
                type: 'Benchmark'
            }
        }))
        requestDoradoUniverseValuationRecords({dispatch})

    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort', 'portfolio_id'],
        requestData: loadPortfolioStates
    });

    if (searchProps.portfolio_id == null) {
        if (!loadingPortfolios && portfolios.length > 0) {

            searchProps.portfolio_id = portfolios[0].id;

            const newUrl = history.location.pathname + qs.stringify(searchProps, {addQueryPrefix: true});

            return <Redirect to={newUrl}/>
        }
    }

    const portfolioOptions = portfolios.map(p => {
        return {
            value: p.id,
            text: p.name,
            key: p.id
        }
    })

    const sidebar = (
        <div>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Header>
                            Dorado Universe
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <Divider horizontal={true}>Series</Divider>
                            <Form.Checkbox label="80th Percentile IRR"
                                           checked={searchProps.show80thPercentile}
                                           onChange={createAddCheckedValueToQuery({
                                               history,
                                               searchProps,
                                               fieldName: 'show80thPercentile'
                                           })}
                            />
                            <Form.Checkbox label="Median IRR"
                                           checked={searchProps.showMedian}
                                           onChange={createAddCheckedValueToQuery({
                                               history,
                                               searchProps,
                                               fieldName: 'showMedian'
                                           })}
                            />
                            <Form.Checkbox label="20th Percentile IRR"
                                           checked={searchProps.show20thPercentile}
                                           onChange={createAddCheckedValueToQuery({
                                               history,
                                               searchProps,
                                               fieldName: 'show20thPercentile'
                                           })}
                            />
                            <Divider horizontal={true}>Adjustments</Divider>
                            <Form.Checkbox label="Show Original IRR"
                                           checked={searchProps.showOriginal}
                                           onChange={createAddCheckedValueToQuery({
                                               history,
                                               searchProps,
                                               fieldName: 'showOriginal'
                                           })}
                            />
                            <Form.Checkbox label="Show Methodlogy-Adj IRR"
                                           checked={searchProps.showMethodologyAdjusted}
                                           onChange={createAddCheckedValueToQuery({
                                               history,
                                               searchProps,
                                               fieldName: 'showMethodologyAdjusted'
                                           })}
                            />

                            <Form.Select
                                fluid
                                inline
                                options={portfolioOptions}
                                onChange={
                                    (e, d) => {
                                        createSetFieldValuesOnQuery({
                                            history,
                                            searchProps,
                                            changes: {
                                                portfolio_id: d.value,
                                            }
                                        })()
                                    }
                                }
                                value={searchProps.portfolio_id}
                                label="Benchmark"
                            />

                        </Form>

                    </Grid.Column>

                </Grid.Row>

            </Grid>
        </div>
    );

    return (
        <ViewWithSidebar mainView={<UniverseIrrChart doradoUniverseValuationRecords={doradoUniverseValuationRecords}
                                                     loading={loadingDoradoUniverseValuationRecords}
                                                     show20thPercentile={searchProps.show20thPercentile}
                                                     showMedian={searchProps.showMedian}
                                                     show80thPercentile={searchProps.show80thPercentile}
                                                     showMethodologyAdjusted={searchProps.showMethodologyAdjusted}
                                                     showOriginal={searchProps.showOriginal}

                                                     benchmarkPortfolioStates={portfolioStateWithReports}

        />}
                         sidebarView={sidebar}/>
    )

}

const requestDoradoUniverseValuationRecords = ({dispatch}) => {

    dispatch(fetchDoradoUniverseValuationRecords({
        sort: {
            as_of_datetime: 1,
        },
        filters: {
            is_valid: true
        }
    }));
}


const loadPortfolioStates = ({dispatch, searchProps}) => {

    const sort = {
        standard_date: 1
    }

    const filters = {
        portfolio_id: searchProps.portfolio_id,
        is_end_of_day: true,
        is_initial_state: false
    }

    if (searchProps.portfolio_id != null && searchProps.portfolio_id !== "") {
        dispatch(fetchPortfolioStateWithReports({sort, filters}));
    }

}