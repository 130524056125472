import client from '../../empClient';
const employeeRecordsService = client.service('source_employee_records');
const associateDescriptionService = client.service('associate_description');
const associationCountService = client.service('association_counts');



export function associateEmployeeWithRole({employeeId, roleId}) {
    return dispatch => {
        return dispatch({
            type: 'ASSOCIATE_EMPLOYEE_WITH_ROLE',
            payload: employeeRecordsService.patch(employeeId, {role_id: roleId})
        });
    };
}

export function setRecordBeingAssociated(record) {
  return {
    type: 'SET_RECORD_BEING_ASSOCIATED',
    payload: record
  }
}

export function associateDescriptionWithRole({employeeId, roleId}) {
    return dispatch => {
        return dispatch({
            type: 'ASSOCIATE_DESCRIPTION_WITH_ROLE',
            payload: associateDescriptionService.create({role_id: roleId, employee_id: employeeId})
        });
    };
}


export function setLastAssociatedRole(role) {
  return {
    type: 'SET_LAST_ASSOCIATED_ROLE',
    payload: role
  }
}


export function getAssociationCountsForScrape({scrapeId}) {
    return dispatch => {
        return dispatch({
            type: 'GET_ASSOCIATION_COUNTS_FOR_SCRAPE',
            payload: associationCountService.get(scrapeId)
        });
    };
}
