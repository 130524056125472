import ReactDataGrid from 'react-data-grid';
import React from "react";


// In order to provide custom formatting by cell
// Need to provide a Row Renderer with a custom cell renderer


export const ModelRowRenderer = ({renderBaseRow, ...canvasProps}) => {
  // console.log(canvasProps)

  return renderBaseRow({...canvasProps, cellRenderer: CustomCellRenderer})
};


class CustomCellRenderer extends ReactDataGrid.Cell {

  // overriding an arrow method is tricky
  // https://stackoverflow.com/questions/38368203/override-arrow-function-in-child-class
  superGetCellClass = this.getCellClass;

  getCellClass = () => {
    let cellClass = this.superGetCellClass();

    const pointSurClass = getPointSurClassName(this.props);

    return pointSurClass + ' ' + cellClass;

  }


}


const getPointSurClassName = ({idx, rowData}) => {
    //
    //  1st column is always aligned left
    // if _indent provided, indent added to first column


    let className = 'react-grid-Cell-data';

    if (idx === 0) {

      className += ' align-left';

      const indent = rowData['_indent'];


      if (indent === 1) {
        className += ' indent-1'
      } else if (indent === 2) {
        className += ' indent-2'
      }


    }

    if (rowData['_underline']) {
      className += ' underline-row'
    }

    if (rowData['_italic']) {
      className += ' italic-row'
    }

    if (rowData['_header']) {
      className += ' financials-header'
    }

    if (rowData['_sectionHeader']) {
      className += ' financials-section-header'
    }

    if (rowData['_cellClassNames']) {
      const cellClassName = rowData['_cellClassNames'][idx - 1];
      if (cellClassName != null) {
        className += ' ' + cellClassName;
      }

    }


    return className

  };


export class IsEditableFieldRenderer extends React.Component {
    render() {
        return (
            <div className={'financials-forecast-header'}>
                {this.props.column.name}
            </div>
        )
    }
}