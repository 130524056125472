import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Container, Divider, Form, Grid, Table} from 'semantic-ui-react'
import {useReloadOnChange} from "../../../../common/hooks";
import CompanyHeader from "../../../../components/CompanyHeader";
import {Link, useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import {
    fetchDoradoValuationRecords,
    fetchRevisedBacktestDoradoValuationRecordsForCompany
} from "../../../../features/dorado/doradoValuationRecords";
import {
    fetchDoradoForecastMetadatasForCompany,
    fetchDoradoValuationRecordsForCompany
} from "../../../../features/dorado/doradoCompanyDetail";
import {formatFullDate} from "../../../../common/format";
import BacktestChart from './ValuationComparisonChart'
import {ForecastType} from "../../../../common/constants";
import {fieldSpecs, positionChartOptions, valuationMetricChartOptions} from './common'
import {
    createAddCheckedValueToQuery,
    createAddValueToQuery,
    createSetFieldValuesOnQuery
} from "../../../../common/query/add";
import {ShowExtendedBacktestCheckbox} from "../../../dorado/companyViews/common";
import {fetchPortfolioPositions, fetchPortfolios} from "../../../../features/portfolio/portfolioFeatureSlice";
import {fetchCompany} from "../../../../features/companyFeatureSlice";


export default () => {

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: [],
        matchFields: ['id'],
        requestData: requestCompany
    });

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['showExtendedBacktest'],
        matchFields: ['id'],
        requestData: requestDoradoValuationRecords
    });

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['portfolio_id'],
        matchFields: ['id'],
        requestData: requestPortfolioPositionRecords
    });

    const {
        company,
        loadingCompany,
    } = useSelector(state => state.company, shallowEqual);

    const {
        doradoForecastMetadatas,
        loadingDoradoForecastMetadatas,
        doradoValuationRecords,
        loadingDoradoValuationRecords
    } = useSelector(state => state.doradoCompanyDetail, shallowEqual);

    const {
        portfolioPositions,
        loadingPortfolioPositions
    } = useSelector(state => state.portfolio, shallowEqual);

    const {
        revisedBacktestDoradoValuationRecords,
        loadingRevisedBacktestDoradoValuationRecords,
    } = useSelector(state => state.doradoValuation, shallowEqual);

    const {
        portfolios,
        loadingPortfolios,
    } = useSelector(state => state.portfolio, shallowEqual);

    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPortfolios({
            sort: {
                type: 1,
                is_backtest: 1
            }}))
    }, [dispatch]);

    const portfolioOptions = [{
        value: "<null>",
        text: "",
        key: ""
    }].concat(portfolios.map(p => {

        let text = '';
        if (p.is_backtest === false) {
            text = p.type;
        } else {
            text = 'Backtest: ' + p.type + " " + p.name;
        }

        return {
            value: p.id,
            text: text,
            key: p.id
        }
    }));

    const loading = loadingCompany || loadingDoradoValuationRecords || loadingDoradoForecastMetadatas;

    const tradeMetricEnabled = searchProps.portfolio_id != null;

    return (
        <Container fluid>
            <Grid padded>

                <Grid.Column width={4}>
                    <Grid.Row>
                        <Grid.Column width={14}>
                            <CompanyHeader company={company} loading={loadingCompany}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Divider horizontal>Chart Options</Divider>
                        <Form>
                            <Form.Checkbox label="15% IRR Buy Price"
                                           checked={searchProps.showBuyPrice}
                                           onChange={createAddCheckedValueToQuery({
                                               history,
                                               searchProps,
                                               fieldName: 'showBuyPrice'
                                           })}
                            />

                            <Form.Select
                                fluid
                                inline
                                options={valuationMetricChartOptions}
                                onChange={createAddValueToQuery({history, searchProps, fieldName: 'series2ChartName'})}
                                value={searchProps.series2ChartName}
                                label="Valuation Metrics"
                            />

                            <Divider horizontal>Trades View</Divider>

                            <Form.Select
                                fluid
                                inline
                                options={portfolioOptions}
                                onChange={
                                    (e, d) => {
                                        createSetFieldValuesOnQuery({
                                            history,
                                            searchProps,
                                            changes: {
                                                portfolio_id: d.value,
                                                // portfolio_state_id: null
                                            }
                                        })()
                                    }
                                }
                                value={searchProps.portfolio_id}
                                label="Portfolio"
                            />
                            <Form.Select
                                fluid
                                inline
                                options={positionChartOptions}
                                onChange={createAddValueToQuery({history, searchProps, fieldName: 'tradeMetricChartName'})}
                                value={searchProps.tradeMetricChartName}
                                label="Trade Metric"
                            />


                        </Form>
                    </Grid.Row>

                </Grid.Column>
                <Grid.Column width={12}>
                    <BacktestChart loading={loading}
                                   doradoValuationRecords={doradoValuationRecords}
                                   revisedBacktestDoradoValuationRecords={revisedBacktestDoradoValuationRecords}
                                   portfolioPositions={portfolioPositions}

                                   series2ChartName={searchProps.series2ChartName}
                                   tradeMetricChartName={searchProps.tradeMetricChartName}
                                   tradeMetricEnabled={tradeMetricEnabled}

                                   showBuyPrice={searchProps.showBuyPrice}
                                   showFairPrice={searchProps.showFairPrice}
                                   showFairPriceScaled={searchProps.showFairPriceScaled}
                                   showBuyPriceScaled={searchProps.showBuyPriceScaled}

                                   highlightStartDate={searchProps.highlightStartDate}
                                   highlightEndDate={searchProps.highlightEndDate}
                    />
                </Grid.Column>
            </Grid>
        </Container>
    )

}


export const MetadatasView = ({doradoForecastMetadatas, doradoValuationRecords, loading}) => {
    const rows = loading ? [] : doradoForecastMetadatas.map(record => {

        const matchingRecord = doradoValuationRecords.find(r => r.dorado_forecast_metadata_id === record.id);
        const matchingId = matchingRecord ? matchingRecord.id: null;

        let link = formatFullDate(record.as_of_date);
        if (matchingId != null) {
            link = (
                <Link to={'/company_detail/dorado_valuations/' + record.company_id + "/" + matchingId}>
                    {formatFullDate(record.as_of_date)}
                </Link>)
        }

        const forecastTypeStyle = {};
        if (record.is_synthetic) {
            forecastTypeStyle.color = 'red';
        }

        return (
            <Table.Row key={record.id}>
                <Table.Cell>
                    <Link to={'/company_detail/dorado_forecasts/' + record.company_id + "?doradoForecastMetadataId=" + record.id + "&metadataType=" + record.forecast_type}>
                        {record.event_description}
                    </Link>
                </Table.Cell>
                <Table.Cell>
                    <div style={forecastTypeStyle}>
                        {record.forecast_type}
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {link}
                </Table.Cell>
                <Table.Cell>
                    {formatFullDate(record.first_valid_trade_date)}
                </Table.Cell>
                <Table.Cell>
                    {formatFullDate(record.last_valid_trade_date)}
                </Table.Cell>
            </Table.Row>
        );
    });

    return (
        <Table compact={"very"} size="small">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Forecast
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Type (Synthetic in Red)
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        As Of
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        First Date
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Last Date
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rows}
            </Table.Body>
        </Table>
    )

};


const requestDoradoValuationRecords = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;

    const sort = {as_of_date: 1};
    const filters = {
        forecast_type: {
            $in: [ForecastType.currentModel, ForecastType.backtestModel]
        },
    };

    dispatch(fetchDoradoValuationRecordsForCompany({companyId: id, sort, filters}));
    dispatch(fetchDoradoForecastMetadatasForCompany({filters, companyId: id}));

    // dispatch(fetchRevisedBacktestDoradoValuationRecordsForCompany({company_id: id}));
};


const requestPortfolioPositionRecords = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;

    const sort = {standard_date: 1};
    const filters = {
        company_id: id,
        portfolio_id: searchProps.portfolio_id
    };

    if (searchProps.portfolio_id != null) {
        dispatch(fetchPortfolioPositions({sort, filters}));
    }

};


const requestCompany = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    dispatch(fetchCompany({companyId: id}));
}