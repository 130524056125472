import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import rootReducer from './reducers';
import { configureStore } from "@reduxjs/toolkit";


export default configureStore({
    reducer: rootReducer,
    middleware: [promise, thunk]
})


