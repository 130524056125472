import React from 'react';
import {Link, NavLink, Switch, useRouteMatch, Redirect} from 'react-router-dom';
import {Dropdown, Menu, Icon} from 'semantic-ui-react';
import RoleFormPage from './pages/employee/newEditRole/RoleFormPage'
import EmployeeAnalysisPage from './pages/employee/analyze/EmployeeAnalysisPage'
import DocumentationPage from './pages/documentation/DocumentationPage'
import ValuationChartPage from './pages/valuationChart/ValuationChartPage'
import ValuationTablePage from './pages/nonDoradoValuationTable/ValuationTablePage'
import HistoricalFinancialsPage from './pages/company/historicalFinancials/HistoricalFinancialsPage'
import CompanySearchBox from './components/CompanySearchBox'
import ValuationDocumentationPage from './pages/documentation/ValuationDocumentationPage'
import AdminPage from './pages/admin/AdminPage'
import CoveragePage from './pages/coverage/CoverageSummaryPage'
import TagPage from './pages/tag/TagPage'
import CompanyNotePage from './pages/companyNote/CompanyNotePage'
import TagDocumentationPage from './pages/documentation/TagDocumentationPage'
import LogoutPage from './pages/LogoutPage'
import ScrapesPage from './pages/employee/scrapes/EmployeeScrapesPage'
import EmployeeRecordPage from './pages/employee/associate/EmployeeRecordPage'
import RoleTablePage from './pages/employee/rolesView/RoleTablePage'
import TargetPriceFeedPage from './pages/feed/FeedPage'
import PrivateRoute from "./auth/PrivateRoute";
import LoginNavBar from './auth/LoginNavBar';
import {Profile} from "./auth/Profile";
import AkkrAdminPage from "./pages/akkr/AkkrAdminPage"
import AkkrCompaniesPage from './pages/akkr/companiesTable/AkkrCompaniesPage'
import AkkrCompanyPage from './pages/akkr/company/AkkrCompanyPage'
import AkkrDataPage from './pages/akkr/dataTable/AkkrDataPage'
import PortfolioSecurityTradesPage from './pages/portfolio/trades/PortfolioTradesPage'
import PortfolioDividendsPage from './pages/portfolio/dividends/DividendsPage'
import DoradoValuationTablePage from "./pages/dorado/historicalValuationTable/HistoricalValuationTablePage"
import CompanySecuritiesPage from './pages/company/securities/SecuritiesPage'
import CompanyHistoricalValuationPage from './pages/companyHistoricalValuation/CompanyHistoricalValuationPage'
import CompanyEquityEventsPage from './pages/company/equityOfferings/CompanyEquityEventsPage'
import DoradoCompanyPage from "./pages/company/dorado/forecasts/DoradoForecastsPage";
import {getCoverageSearch} from "./common/coverage";
import DoradoCompanyAuditPage from "./pages/dorado/companyViews/companyAudit/DoradoCompanyAuditPage";
import CompanyConvertibleDebtPage from "./pages/company/convertibleDebt/CompanyConvertibleDebtPage";
import CompanyAcquisitionsPage from "./pages/company/acquisitions/CompanyAcquisitionsPage";
import MaxMarginChartPage from "./pages/dorado/audits/maxMarginChart/MaxMarginChartPage";
import DilutionChartPage from "./pages/dorado/audits/dilutionChart/DilutionChartPage";
import DoradoGrowthChartPage from "./pages/dorado/audits/growthChart/DoradoGrowthChartPage";
import DoradoIrrAuditChartPage from './pages/dorado/irrAuditChart/IrrAuditChartPage';
import DoradoExitMultipleAuditPage from "./pages/dorado/audits/exitMultiple/DoradoExitMultipleAuditPage";
import DoradoBacktestComparisonPage from "./pages/dorado/companyViews/backtestForecastComparisons/DoradoBacktestComparisonPage";
import BacktestValuationsPage from "./pages/company/dorado/valuationComparisonChart/DoradoValuationComparisonChartPage";
import BacktestAuditPage from "./pages/dorado/backtestAudit/BacktestAuditPage";
import RuleOf40ChartPage from "./pages/dorado/audits/ruleOf40Chart/RuleOf40ChartPage";
import MaxMarginExecutionGapPage from "./pages/dorado/audits/executionGap/MaxMarginExecutionGapPage";
import RealTimeDoradoValuationPage from "./pages/dorado/realTimeValuation/RealTimeDoradoValuationPage";
import DoradoPortfolioBacktestPage from "./pages/portfolioAnalysis/backtestDetail/DoradoBacktestDetailPage";
import BacktestReturnsPage from "./pages/dorado/backtestCharts/BacktestReturnsPage";
import BacktestValuationAccuracyPage from "./pages/dorado/backtestValuationAccuracy/BacktestValuationAccuracyPage";
import BacktestDashboardPage from "./pages/dorado/portfolioParams/BacktestDashboardPage";
import BacktestReportPage from "./pages/dorado/backtestReport/BacktestReportPage";
import EtfsPage from "./pages/etfs/EtfsPage";
import DoradoValuationComparisonsPage from "./pages/company/dorado/valuationComparisonTable/DoradoValuationComparisonTablePage";
import BacktestQuestionsPage from "./pages/documentation/BacktestQuestionsPage";
import DoradoPredictionsPage from "./pages/dorado/companyViews/predictions/DoradoPredictionsPage";
import Highcharts from 'highcharts';
import DoradoTargetPortfolioPage from "./pages/dorado/portfolioTarget/DoradoTargetPortfolioPage";
import PortfolioParamsPage from "./pages/dorado/portfolioParams/PortfolioParamsPage";
import PortfolioSummaryTable from "./pages/portfolioAnalysis/summaryTable/PortfolioSummaryTable";
import CurrentBacktestReturnsRedirectPage from "./pages/dorado/CurrentBacktestRedirectPage";
import ReturnsTablePage from "./pages/portfolioAnalysis/returnsTable/ReturnsTablePage";
import ContributionChartPage from "./pages/portfolioAnalysis/contributionChart/ContributionChartPage";
import TradeApprovalPage from "./pages/dorado/tradeApproval/TradeApprovalPage";
import RepurchaseChartPage from "./pages/dorado/audits/repurchaseChart/RepurchaseChartPage";
import DoradoDocumentationPage from "./pages/documentation/DoradoDocumentationPage";
import ValuationPremiumPage from "./pages/dorado/audits/valuationPremium/ValuationPremiumPage";
import PortfolioExposurePage from "./pages/portfolioAnalysis/exposure/PortfolioExposurePage";
import PortfolioHistoryPage from "./pages/portfolio/portfolioHistory/PortfolioHistoryPage";
import FlexOrdersPage from "./pages/dorado/flexOrders/FlexOrdersPage";
import DoradoUniverseValuationPage from './pages/dorado/universeValuation/UniverseValuationPage'
import CurrentValuationAuditPage from "./pages/dorado/audits/currentValuation/CurrentValuationAuditPage";
import CurrentPortfolioPage from './pages/portfolio/currentPortfolio/CurrentPortfolioPage';
import IndexConstituentsPage from "./pages/indexConstituents/IndexConstituentsPage";
import SecurityInvestmentsPage from "./pages/portfolioAnalysis/securityInvestments/SecurityInvestmentsPage";
import NavBridgePage from "./pages/portfolio/navBridge/NavBridgePage";
import PortfolioBorrowPage from "./pages/portfolio/portfolioBorrow/PortfolioBorrowPage";
import TradeReviewPage from "./pages/portfolio/tradeReview/TradeReviewPage";
import DailyTradeReviewPage from "./pages/portfolio/tradeDailyReview/DailyTradeReviewPage";
import ReturnsChartPage from "./pages/portfolioAnalysis/returnsChart/ReturnsChartPage";
import IgvContributionPage from "./pages/portfolioAnalysis/igvContribution/IgvContributionPage";
import PreIpoPage from "./pages/documentation/PreIpoPage";
import LongShortReturnsChartPage from "./pages/portfolioAnalysis/longShortReturnsChart/LongShortReturnsChartPage";
import LongShortCorrelationPage from "./pages/portfolioAnalysis/longShortCorrelation/LongShortCorrelationPage";
import CurrentValuationVsGrowthAuditPage from "./pages/dorado/audits/currentValuationVsGrowth/CurrentValuationVsGrowthAuditPage"
import CompanyDividendsPage from "./pages/company/dividends/CompanyDividendsPage";
import CurrencyConversionPage from "./pages/portfolio/currencyConversions/CurrencyConversionPage";
import OtherRecordsPage from "./pages/portfolio/otherRecord/OtherRecordsPage";
import RevisedModelsPage from "./pages/dorado/audits/revisedModels/RevisedModelsPage";
import UniverseIRRPage from "./pages/dorado/universeIRR/UniverseIRRPage";
import BacktestDataQualityPage from "./pages/backtestDataQuality/BacktestDataQualityPage";
import CurrentDoradoValuationPage from './pages/company/dorado/currentDoradoValuation/CurrentDoradoValuationPage'
import DoradoCompanyOverviewPage from "./pages/company/dorado/overview/DoradoCompanyOverviewPage";


// NavLink A special version of the <Link> that will add styling attributes to the rendered element when it matches the current URL.
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/NavLink.md
// exact: When true, the active class/style will only be applied if the location is matched exactly.

const valuationTableUrl = '/valuation/table?screenGroup=Business%20Quality&sort%5B0%5D%5B0%5D=percent_from_buy_price&sort%5B0%5D%5B1%5D=1&sort%5B1%5D%5B0%5D=percent_from_nearest_recommendation&sort%5B1%5D%5B1%5D=1&filters%5Bgeography_group%5D%5Btype%5D=terms&filters%5Bgeography_group%5D%5Bterms%5D%5B0%5D=Ancillary&filters%5Bgeography_group%5D%5Bterms%5D%5B1%5D=Core&filters%5Btracking_rank%5D%5Btype%5D=terms&filters%5Btracking_rank%5D%5Bterms%5D%5B0%5D=1%3A%20Core&filters%5Btracking_rank%5D%5Bterms%5D%5B1%5D=2%3A%20Track&filters%5Bquick_market_value%5D%5Btype%5D=terms&filters%5Bquick_market_value%5D%5Bterms%5D%5B0%5D=500%20-%205B&filters%5Bquick_market_value%5D%5Bterms%5D%5B1%5D=75%20-%20500&filters%5Bquick_market_value%5D%5Bterms%5D%5B2%5D=>5B&columnGroup=Business%20Quality&estimatesType=Sell-Side';
const valuationChartUrl = '/valuation/charts?filters%5Bgeography_group%5D%5Btype%5D=terms&filters%5Bgeography_group%5D%5Bterms%5D%5B0%5D=Ancillary&filters%5Bgeography_group%5D%5Bterms%5D%5B1%5D=Core&filters%5Btracking_rank%5D%5Btype%5D=terms&filters%5Btracking_rank%5D%5Bterms%5D%5B0%5D=1%3A%20Core&filters%5Bquick_market_value%5D%5Btype%5D=terms&filters%5Bquick_market_value%5D%5Bterms%5D%5B0%5D=500%20-%205B&filters%5Bquick_market_value%5D%5Bterms%5D%5B1%5D=75%20-%20500&filters%5Bquick_market_value%5D%5Bterms%5D%5B2%5D=>5B&chartName=EV%2FRevenue%20vs%20Revenue%20Growth&period=2020&labels=Ticker&color=%25%20Recurring&axesSize=Default&valuationLinesEnabled=false&regressionLineEnabled=false&useDilutedGrowth=false';

const doradoValuationTableUrl = '/dorado_valuation_table?sort%5B0%5D%5B0%5D=no_multiple_expansion_irr&sort%5B0%5D%5B1%5D=-1';
const realTimeDoradoValuationTableUrl = '/real_time_dorado_valuation?sort%5B0%5D%5B0%5D=no_multiple_expansion_irr&sort%5B0%5D%5B1%5D=-1';
const doradoMaxMarginChartUrl = '/dorado_max_margin_chart?sort%5B0%5D%5B0%5D=exit_maximized_pretax_fcf_margin_ntm&sort%5B0%5D%5B1%5D=-1&filters%5Bforecast_type%5D%5Btype%5D=term&filters%5Bforecast_type%5D%5Bterm%5D=Point%20Sur%20Estimate';

const employeeScrapesUrl = '/employee_analysis/scrapes?sort%5B0%5D%5B0%5D=company_name&sort%5B0%5D%5B1%5D=1&sort%5B1%5D%5B0%5D=date_acquired&sort%5B1%5D%5B1%5D=-1';
const akkrCompaniesUrl = "/akkr/akkr_companies_table?sort%5B0%5D%5B0%5D=short_name&sort%5B0%5D%5B1%5D=1&columnGroup=Data%20Status";
const akkrDataUrl = '/akkr/akkr_data_table?filters%5Bcurrency_type%5D%5Btype%5D=terms&filters%5Bcurrency_type%5D%5Bterms%5D%5B0%5D=USD&filters%5Bis_calendarized%5D%5Btype%5D=term&filters%5Bis_calendarized%5D%5Bterm%5D=false&filters%5Bis_ltm%5D%5Btype%5D=term&filters%5Bis_ltm%5D%5Bterm%5D=true&filters%5Bperiod_type%5D%5Btype%5D=terms&filters%5Bperiod_type%5D%5Bterms%5D%5B0%5D=Annual&filters%5Bis_most_recent_ltm%5D%5Btype%5D=term&filters%5Bis_most_recent_ltm%5D%5Bterm%5D=true&sort%5B0%5D%5B0%5D=fiscal_year&sort%5B0%5D%5B1%5D=-1&sort%5B1%5D%5B0%5D=bookings_recurring_to_sales_and_marketing&sort%5B1%5D%5B1%5D=-1&sort%5B2%5D%5B0%5D=short_name&sort%5B2%5D%5B1%5D=1&columnGroup=Sales%20Efficiency&sidebarOpen=true';

const feedUrl = '/valuation/feed';



export default ({user}) => {
    const isAdmin = user ? user['https://akkr-bench.herokuapp.com/role'] === 'admin' : true;
    const email = user ? user.email : null;

    return (
        <div>
            <Menu inverted>
                <Dropdown item icon='bars' simple>
                    <Dropdown.Menu>
                        <MenuLink to={isAdmin ? feedUrl : valuationTableUrl}>Point Sur</MenuLink>
                        <MenuLink to={'/akkr'}>AKKR</MenuLink>
                    </Dropdown.Menu>
                </Dropdown>

                <Switch>
                    <PrivateRoute path="/akkr">
                        <AkkrMenuItems/>
                    </PrivateRoute>

                    <PrivateRoute path="/">
                        <PointSurMenuItems isAdmin={isAdmin} email={email}/>
                    </PrivateRoute>

                </Switch>


                <PrivateRoute path="/company_detail/:resource/:id">
                    <CompanyDetailMenu/>
                </PrivateRoute>

                <Menu.Menu position="right">
                    <Menu.Item className="mainNavItem">
                        <CompanySearchBox/>
                    </Menu.Item>
                    <Menu.Item>
                        <Profile/>
                    </Menu.Item>
                    <LoginNavBar/>

                </Menu.Menu>


            </Menu>

            <div>
                <Switch>

                    <PrivateRoute exact path="/">
                        {isAdmin ? <Redirect to={feedUrl}/> : <Redirect to={akkrCompaniesUrl}/>}
                    </PrivateRoute>
                    <PrivateRoute path={"/company_detail"}>
                        <CompanyDetailPage/>
                    </PrivateRoute>

                    <PrivateRoute path="/employee_analysis/scrapes">
                        <ScrapesPage/>
                    </PrivateRoute>
                    <PrivateRoute path="/employee_analysis/records/:scrape_id">
                        <EmployeeRecordPage/>
                    </PrivateRoute>
                    <PrivateRoute path="/employee_analysis/roles_by_company/:company_id">
                        <RoleTablePage/>
                    </PrivateRoute>

                    <PrivateRoute exact path={"/employee_analysis/new_role/:company_id"}>
                        <RoleFormPage isNew={true}/>
                    </PrivateRoute>
                    <PrivateRoute path={"/employee_analysis/edit_role/:company_id/:id"}>
                        <RoleFormPage isNew={false}/>
                    </PrivateRoute>

                    <PrivateRoute path="/employee_analysis/analyze/:scrape_id">
                        <EmployeeAnalysisPage/>
                    </PrivateRoute>

                    <PrivateRoute path="/valuation/charts">
                        <ValuationChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path="/valuation/table">
                        <ValuationTablePage/>
                    </PrivateRoute>

                    <PrivateRoute path="/etfs_list">
                        <EtfsPage/>
                    </PrivateRoute>

                    <PrivateRoute path="/dorado_backtest_report/:dorado_portfolio_id">
                        <BacktestReportPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_backtest_report'}>
                        <CurrentBacktestReturnsRedirectPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_portfolio_backtest_detail'}>
                        <DoradoPortfolioBacktestPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_backtest_params'}>
                        <BacktestDashboardPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_universe_valuation'}>
                        <DoradoUniverseValuationPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_universe_irr'}>
                        <UniverseIRRPage/>
                    </PrivateRoute>


                    <PrivateRoute path={'/dorado_valuation_vs_execution_score'}>
                        <CurrentValuationAuditPage/>
                    </PrivateRoute>


                    {/*<PrivateRoute path={'/dorado_backtest_sensitivity'}>*/}
                    {/*    <BacktestSensitivityPage/>*/}
                    {/*</PrivateRoute>*/}


                    <PrivateRoute path={'/dorado_backtest_returns'}>
                        <BacktestReturnsPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/period_reports'}>
                        <ReturnsTablePage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/contribution_chart'}>
                        <ContributionChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/trade_approval'}>
                        <TradeApprovalPage email={email}/>
                    </PrivateRoute>


                    <PrivateRoute path={'/dorado_valuation_return_audits'}>
                        <BacktestValuationAccuracyPage/>
                    </PrivateRoute>

                    <PrivateRoute path="/real_time_dorado_valuation">
                        <RealTimeDoradoValuationPage/>
                    </PrivateRoute>

                    <PrivateRoute path="/dorado_valuation_table">
                        <DoradoValuationTablePage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/dorado_max_margin_chart"}>
                        <MaxMarginChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/dorado_dilution_chart"}>
                        <DilutionChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/dorado_share_repurchase_chart"}>
                        <RepurchaseChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/dorado_growth_chart"}>
                        <DoradoGrowthChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/dorado_irr_audit"}>
                        <DoradoIrrAuditChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/dorado_rule_of_40_audit"}>
                        <RuleOf40ChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/dorado_rule_of_40_max_margin_audit"}>
                        <MaxMarginExecutionGapPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/dorado_exit_multiple_audit"}>
                        <DoradoExitMultipleAuditPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_backtest_audit'}>
                        <BacktestAuditPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_valuation_premium_audit'}>
                        <ValuationPremiumPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_target_portfolio'}>
                        <DoradoTargetPortfolioPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_portfolio_params'}>
                        <PortfolioParamsPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/portfolio_summary_table'}>
                        <PortfolioSummaryTable/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_documentation'}>
                        <DoradoDocumentationPage/>
                    </PrivateRoute>

                    <PrivateRoute path={feedUrl}>
                        <TargetPriceFeedPage/>
                    </PrivateRoute>

                    <PrivateRoute path="/current_portfolio">
                        <CurrentPortfolioPage/>
                    </PrivateRoute>

                    <PrivateRoute path="/security_investments">
                        <SecurityInvestmentsPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/coverage_summary"}>
                        <CoveragePage/>
                    </PrivateRoute>


                    <PrivateRoute path={"/tags"}>
                        <TagPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/documentation/tags"}>
                        <TagDocumentationPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/documentation/employees"}>
                        <DocumentationPage/>
                    </PrivateRoute>
                    <PrivateRoute path={"/documentation/valuation"}>
                        <ValuationDocumentationPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/documentation/backtest"}>
                        <BacktestQuestionsPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/documentation/pre_ipo"}>
                        <PreIpoPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/admin"}>
                        <AdminPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/security_trades"}>
                        <PortfolioSecurityTradesPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/security_dividends"}>
                        <PortfolioDividendsPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/other_portfolio_records"}>
                        <OtherRecordsPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/portfolio_currency_conversions"}>
                        <CurrencyConversionPage/>
                    </PrivateRoute>

                    <PrivateRoute path="/logout">
                        <LogoutPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/akkr/akkr_admin"}>
                        <AkkrAdminPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/akkr/akkr_companies_table"}>
                        <AkkrCompaniesPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/akkr/akkr_data_table"}>
                        <AkkrDataPage/>
                    </PrivateRoute>
                    <PrivateRoute path={"/akkr/akkr_companies/:akkr_company_id"}>
                        <AkkrCompanyPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/portfolio_exposure"}>
                        <PortfolioExposurePage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/portfolio_history"}>
                        <PortfolioHistoryPage/>
                    </PrivateRoute>

                    <PrivateRoute path={"/trade_orders"}>
                        <FlexOrdersPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/index_constituents/:etf_id'}>
                        <IndexConstituentsPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/nav_bridges'}>
                        <NavBridgePage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/portfolio_borrow'}>
                        <PortfolioBorrowPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/trade_review'}>
                        <TradeReviewPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/daily_trade_review'}>
                        <DailyTradeReviewPage/>
                    </PrivateRoute>


                    <PrivateRoute path={"/akkr"}>
                        <Redirect to={akkrCompaniesUrl}/>
                    </PrivateRoute>

                    <PrivateRoute path={'/portfolio_returns_chart'}>
                        <ReturnsChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/igv_contribution'}>
                        <IgvContributionPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/portfolio_long_short_chart'}>
                        <LongShortReturnsChartPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/portfolio_long_short_regression'}>
                        <LongShortCorrelationPage/>
                    </PrivateRoute>

                    <PrivateRoute path={'/dorado_valuation_vs_growth'}>
                        <CurrentValuationVsGrowthAuditPage/>
                    </PrivateRoute>


                    <PrivateRoute path={'/revised_models'}>
                        <RevisedModelsPage/>
                    </PrivateRoute>


                </Switch>
            </div>


        </div>


    );

};


const PointSurMenuItems = ({isAdmin, email}) => {

    const feedLink = isAdmin ? <MenuLink key="aa" to={feedUrl}>Feed</MenuLink> : null;

    return [
        feedLink,

        <Dropdown key="val" item text='Valuation' simple>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={realTimeDoradoValuationTableUrl}>
                    Table
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/valuation/table?sort%5B0%5D%5B0%5D=lfq_revenue_growth&sort%5B0%5D%5B1%5D=-1&sort%5B1%5D%5B0%5D=percent_from_buy_price&sort%5B1%5D%5B1%5D=1&filters%5Btracking_rank%5D%5Btype%5D=terms&filters%5Btracking_rank%5D%5Bterms%5D%5B0%5D=1%3A%20Core&filters%5Btracking_rank%5D%5Bterms%5D%5B1%5D=2%3A%20Track&filters%5Btracking_rank%5D%5Bterms%5D%5B2%5D=3%3A%20Low%20Probability&filters%5Bmarket_value_usd%5D%5Btype%5D=range&filters%5Bmarket_value_usd%5D%5B%24gte%5D=50000&filters%5Bmarket_value_usd%5D%5B%24lte%5D=5000000&filters%5Bgeography_group%5D%5Btype%5D=terms&filters%5Bgeography_group%5D%5Bterms%5D%5B0%5D=Ancillary&filters%5Bgeography_group%5D%5Bterms%5D%5B1%5D=Core"}>
                    Non-Dorado Table
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={Link} to={"/dorado_universe_valuation"}>
                    Dorado Universe
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={Link} to={"/index_constituents/2?sort%5B0%5D%5B0%5D=weight&sort%5B0%5D%5B1%5D=-1"}>
                    IGV
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/index_constituents/8?sort%5B0%5D%5B0%5D=weight&sort%5B0%5D%5B1%5D=-1"}>
                    WCLD
                </Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>,

        <MenuLink key="ad" to={{pathname: '/coverage_summary', search: getCoverageSearch({email})}}>Coverage</MenuLink>,

        <Dropdown key="ah" item text='Portfolio' simple>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/current_portfolio'}>
                    Current
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/portfolio_borrow'}>
                    Short Borrow
                </Dropdown.Item>
                <Dropdown.Divider/>




                <Dropdown.Item as={Link} to={"/daily_trade_review?sort%5B0%5D%5B0%5D=standard_date&sort%5B0%5D%5B1%5D=-1"}>
                    Daily Trade Summary
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/trade_review?sort%5B0%5D%5B0%5D=standard_date&sort%5B0%5D%5B1%5D=-1"}>
                    Trade Detail
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={Link} to={"/portfolio_history"}>
                    Historical Positions
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/nav_bridges?units=Thousands"}>
                    NAV Bridge
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/security_trades?sort%5B0%5D%5B0%5D=standard_date&sort%5B0%5D%5B1%5D=-1'}>
                    Trades
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/security_dividends?sort%5B0%5D%5B0%5D=standard_date&sort%5B0%5D%5B1%5D=-1'}>
                    Dividends
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/other_portfolio_records?sort%5B0%5D%5B0%5D=standard_date&sort%5B0%5D%5B1%5D=-1'}>
                    Other Records
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/portfolio_currency_conversions?sort%5B0%5D%5B0%5D=standard_date&sort%5B0%5D%5B1%5D=-1'}>
                    Currency Conversions
                </Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>,

        <Dropdown key="drdo" item text='Trade' simple>
            <Dropdown.Menu>

                <Dropdown.Item as={Link} to={'/dorado_portfolio_params'}>
                    Live Portfolio Params
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/dorado_target_portfolio?sort%5B0%5D%5B0%5D=target_percent_of_equity&sort%5B0%5D%5B1%5D=1'}>
                    Target Portfolio
                </Dropdown.Item>

                <Dropdown.Divider/>
                <Dropdown.Item as={Link} to={'/trade_approval'}>
                    Trade Approval
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/trade_orders'}>
                    Trade Orders
                </Dropdown.Item>

            </Dropdown.Menu>

        </Dropdown>,
        <Dropdown key="drado" item text='Dorado' simple>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/dorado_backtest_params'}>
                    Backtest Params
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/dorado_documentation'}>
                    Documentation
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={doradoValuationTableUrl}>
                    Historical Valuation
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={Link} to={'/dorado_growth_chart?filters%5Bforecast_type%5D%5Btype%5D=term&filters%5Bforecast_type%5D%5Bterm%5D=Point%20Sur%20Estimate'}>
                    Growth Audit
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={doradoMaxMarginChartUrl}>
                    Max Margin Audit
                </Dropdown.Item>

                <Dropdown.Item as={Link} to={'/dorado_valuation_premium_audit'}>
                    Valuation Premium Audit
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/dorado_dilution_chart?filters%5Bforecast_type%5D%5Btype%5D=term&filters%5Bforecast_type%5D%5Bterm%5D=Point%20Sur%20Estimate'}>
                    Dilution Audit
                </Dropdown.Item>

                <Dropdown.Item as={Link} to={'/dorado_share_repurchase_chart'}>
                    Share Repurchase Audit
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/revised_models?sort%5B0%5D%5B0%5D=backtest_as_of_date&sort%5B0%5D%5B1%5D=-1'}>
                    Revised Models
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={Link} to={'/dorado_exit_multiple_audit?filters%5Bforecast_type%5D%5Btype%5D=term&filters%5Bforecast_type%5D%5Bterm%5D=Point%20Sur%20Estimate'}>
                    Valuation Multiples
                </Dropdown.Item>

                <Dropdown.Item as={Link} to={'/dorado_irr_audit'}>
                    IRR Audit
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/dorado_backtest_audit?sort%5B0%5D%5B0%5D=discounted_exit_value_coefficient_of_variation&sort%5B0%5D%5B1%5D=-1'}>
                    Backtest Audit
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>,
        <Dropdown key="back" item text='Portfolio Analysis' simple>
            <Dropdown.Menu>

                <Dropdown.Item as={Link} to={'/portfolio_returns_chart?isFirstLoad=true&?startDate=2021-01-01'}>
                    Returns Chart
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/period_reports?sort%5B0%5D%5B0%5D=total_gain&sort%5B0%5D%5B1%5D=-1'}>
                    Returns Table
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/security_investments?sort%5B0%5D%5B0%5D=total_gain_usd&sort%5B0%5D%5B1%5D=-1&sort%5B1%5D%5B0%5D=last_valuation_date&sort%5B1%5D%5B1%5D=-1&sort%5B2%5D%5B0%5D=is_open_position&sort%5B2%5D%5B1%5D=-1"}>
                    Returns by Investment
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={Link} to={'/portfolio_summary_table'}>
                    Portfolios
                </Dropdown.Item>
                <Dropdown.Divider/>

                <Dropdown.Item as={Link} to={"/portfolio_exposure?Net%20Exposure=true&Gross%20Long=true&Gross%20Short=true"}>
                    Exposures
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/contribution_chart?series1ChartName=Monthly%20%25%20Gain&topPositionCount=3'}>
                    Monthly Contribution Chart
                </Dropdown.Item>
                <Dropdown.Divider/>



                {/*<Dropdown.Item as={Link} to={'/dorado_backtest_report?sort%5B0%5D%5B0%5D=relative_gain_vs_spy&sort%5B0%5D%5B1%5D=-1&backtestBenchmark=SPY'}>*/}
                {/*    Report*/}
                {/*</Dropdown.Item>*/}


                {/*<Dropdown.Item as={Link} to={'/dorado_backtest_returns'}>*/}
                {/*    Charts*/}
                {/*</Dropdown.Item>*/}
                <Dropdown.Item as={Link} to={'/dorado_portfolio_backtest_detail?sort%5B0%5D%5B0%5D=target_percent_of_equity&sort%5B0%5D%5B1%5D=1'}>
                    Backtest Detail
                </Dropdown.Item>




            </Dropdown.Menu>


        </Dropdown>,

        <MenuLink key="cd" to={'/tags?sort%5B0%5D%5B0%5D=company_name&sort%5B0%5D%5B1%5D=1&sort%5B1%5D%5B0%5D=date_added&sort%5B1%5D%5B1%5D=-1'}>Tags</MenuLink>,
        <MenuLink key="ba" to={'/admin'}>Admin</MenuLink>,

        <Dropdown key="ag" item text='Other' simple>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/etfs_list'}>
                    ETFs
                </Dropdown.Item>
                <Dropdown item text='Docs'>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/documentation/tags" className='menu-dropdown-black'>
                            Tags
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/documentation/valuation"
                                       className='menu-dropdown-black'>
                            Valuation
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/documentation/employees"
                                       className='menu-dropdown-black'>
                            Employees
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={"/documentation/backtest"}>
                            Backtest Questions
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={"/documentation/pre_ipo"}>
                            Pre-IPO Companies
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>


            </Dropdown.Menu>
        </Dropdown>

    ]

};

const AkkrMenuItems = () => {
    return [
        <MenuLink key="a" to="/akkr/akkr_admin">Admin</MenuLink>,
        <MenuLink key="b"
                  to={akkrCompaniesUrl}>Companies</MenuLink>,
        <MenuLink key="c" to={akkrDataUrl}>Data</MenuLink>,

    ]
};


const CompanyDetailMenu = () => {
    const match = useRouteMatch("/company_detail/:resource/:id");

    return [
        <Menu.Item key="1h" header><Icon name='chevron right' color='teal' size='large' fitted={true}/></Menu.Item>,
        <SubMenuLink key="1" to={"/company_detail/overview/" + match.params.id}>Overview</SubMenuLink>,

        <Dropdown key="8" item text='Dorado' simple>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/company_detail/current_dorado_valuation/' + match.params.id}>
                    Current Valuation
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/company_detail/dorado_company_valuation_comparisons/' + match.params.id}>
                    Backtest Valuation Comparisons
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/company_detail/dorado_backtest_valuations/' + match.params.id + '?series2ChartName=IRR%20%28using%20prior%20day%20forecast%29&showBuyPrice=true&showFairPrice=false&showExtendedBacktest=false&showFairPriceScaled=false'}>
                    Backtest Valuations Chart
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/company_detail/dorado_forecasts/' + match.params.id}>
                    Forecasts
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/company_detail/dorado_forecast_comparison/" + match.params.id}>
                    Forecast Comparisons
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/company_detail/historical_financials/' + match.params.id}>
                    Historical Financials
                </Dropdown.Item>



                {/*<Dropdown.Item as={Link} to={'/company_detail/dorado_audit_records/' + match.params.id}>*/}
                {/*    Audit Records*/}
                {/*</Dropdown.Item>*/}
            </Dropdown.Menu>
        </Dropdown>,
        <Dropdown key="5" item text='Other' simple>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/company_detail/company_acquisitions/' + match.params.id}>
                    Acquisitions
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/company_detail/equity_offering_events/' + match.params.id}>
                    Equity Offerings
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/company_detail/convertible_debt/' + match.params.id}>
                    Convertible Debt
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/company_detail/securities/' + match.params.id}>
                    Securities
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/company_detail/dividends/' + match.params.id}>
                    Dividends
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={'/company_detail/notes/' + match.params.id}>
                    Notes
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>,
    ]
};


const CompanyDetailPage = () => {
    return (
        <Switch>
            <PrivateRoute path={"/company_detail/historical_financials/:id"}>
                <HistoricalFinancialsPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/notes/:id"}>
                <CompanyNotePage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/securities/:id"}>
                <CompanySecuritiesPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/dividends/:id"}>
                <CompanyDividendsPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/historical_valuations/:id"}>
                <CompanyHistoricalValuationPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/equity_offering_events/:id"}>
                <CompanyEquityEventsPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/company_acquisitions/:id"}>
                <CompanyAcquisitionsPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/dorado_forecasts/:id"}>
                <DoradoCompanyPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/current_dorado_valuation/:id"}>
                <CurrentDoradoValuationPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/overview/:id"}>
                <DoradoCompanyOverviewPage/>
            </PrivateRoute>


            <PrivateRoute path={"/company_detail/dorado_backtest_valuations/:id"}>
                <BacktestValuationsPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/dorado_audit_records/:id"}>
                <DoradoCompanyAuditPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/dorado_forecast_comparison/:id"}>
                <DoradoBacktestComparisonPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/convertible_debt/:id"}>
                <CompanyConvertibleDebtPage/>
            </PrivateRoute>
            <PrivateRoute path={"/company_detail/current_dorado_valuation/:id"}>
                <CurrentDoradoValuationPage/>
            </PrivateRoute>
            <PrivateRoute path={'/company_detail/dorado_company_valuation_comparisons/:id'}>
                <DoradoValuationComparisonsPage/>
            </PrivateRoute>
            <PrivateRoute path={'/company_detail/dorado_predictions/:id'}>
                <DoradoPredictionsPage/>
            </PrivateRoute>

            <PrivateRoute path={"/company_detail/backtest_data_quality/:company_id"}>
                <BacktestDataQualityPage/>
            </PrivateRoute>


        </Switch>)
};


// links not showing active correctly
// well known bug https://github.com/ReactTraining/react-router/issues/6201
// when match is provided it looks like a parsing bug

const MenuLink = ({to, children, ...rest}) => {

    return (
        <Menu.Item as={NavLink} to={to} activeClassName={"activeMenuLink"}>
            {children}
        </Menu.Item>

    )
};


const SubMenuLink = ({to, children, ...rest}) => (
    // companyFinancials sublink

    <PrivateRoute path={to} children={({match}) => (
        <Menu.Item active={!!match} color={"teal"}>
            <NavLink to={to}>
                {children}
            </NavLink>
        </Menu.Item>
    )}/>
);

