import React from "react";
import {getWindowHeightLessMenuBar} from "../../common/common";
import {parseSearchProps} from "../../common/query/parse";
import moment from 'moment'
import Highcharts from 'highcharts/highstock';
import {fieldSpecs, ySpecsByName} from './common'
import Exporting from 'highcharts/modules/exporting';
import {createAddValueToQuery, createSetFieldValueOnQuery} from "../../common/query/add";
import {Loader} from "semantic-ui-react";

const ReactHighstock = require('react-highcharts/ReactHighstock');
Exporting(Highcharts);


const rangeSelectorButtonSpecs = [
    {
        type: 'month',
        count: 1,
        text: '1m'
    },
    {
        type: 'month',
        count: 6,
        text: '6m'
    },
    {
        type: 'ytd',
        text: 'YTD'
    },
    {
        type: 'year',
        count: 1,
        text: '1y'
    },
    {
        type: 'year',
        count: 2,
        text: '2y'
    },
    {
        type: 'year',
        count: 3,
        text: '3y'
    },
    {
        type: 'all',
        text: 'All'
    }
];

class ChartComponent extends React.Component {

    componentDidMount() {
        if (!this.props.loadingRecords) {
            const chartHeight = getWindowHeightLessMenuBar();
            let chart = this.refs.chart.getChart();
            chart.setSize(undefined, chartHeight);
        }

    }

    componentDidUpdate() {
        if (!this.props.loadingRecords) {
            const chartHeight = getWindowHeightLessMenuBar();
            let chart = this.refs.chart.getChart();
            chart.setSize(undefined, chartHeight);
        }
    }

    render() {
        const history = this.props.history;
        const searchProps = parseSearchProps({searchString: this.props.location.search, fieldSpecs});
        const records = this.props.records;

        const ySpec1 = ySpecsByName[searchProps.yAxis1];
        const ySpec2 = ySpecsByName[searchProps.yAxis2];

        if (this.props.loadingRecords) {
            return <Loader active inline='centered'/>
        }

        const series1 = seriesFromRecords({records, ySpec: ySpec1});
        const series2 = seriesFromRecords({records, ySpec: ySpec2});

        const series = [series1];
        if (series2 != null) {
            series.push(series2);
        }

        const buttons = rangeSelectorButtonSpecs.map((button, index) => {
            return {
                ...button,
                events: {
                    click: function (e) {

                        console.log(e)

                        const setRangeSelectorIndex = createSetFieldValueOnQuery({
                            history,
                            searchProps, fieldName: 'rangeSelectorIndex',
                            value: index
                        });

                        // currently throws an error.
                        // not sure what is wrong

                        // setRangeSelectorIndex();

                        // return false

                    }
                }
            }
        });

        const config = {
            rangeSelector: {
                selected: 6,
                buttons: buttons
            },
            title: {
                text: getChartTitle({ySpec1, ySpec2})
            },
            series: series,
            yAxis: {
                min: 0
            },
            plotOptions: {
                series: {
                    gapSize: 5
                }
            },
        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighstock config={config} ref="chart"></ReactHighstock>
            </div>
        )

    }

}

const getChartTitle = ({ySpec1, ySpec2}) => {

    let title = ySpec1.name;

    if (ySpec2 != null) {
        title += " and " + ySpec2.name;
    }

    return title

};

const seriesFromRecords = ({ySpec, records}) => {
    if (ySpec == null) {
        return null
    }

    return {
        name: ySpec.name,
        data: records.map(record => {
            return [moment(record.as_of_date).valueOf(), ySpec.getValue(record)]
        })
    }
};

export default ChartComponent

