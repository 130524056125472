import {
    BoolToYesCheckFormatter,
    BoolToYesNoFormatter,
    DateFormatter,
    SharePriceFormatter,
    StringFormatter,
    UtcDateFormatter
} from "../../../common/reactDataGrid/formatters";
import {boolOptions} from "../../../common/reactDataGrid/common";
import React from "react";
import {Editors} from "react-data-grid-addons";
import {IsEditableFieldRenderer} from "../../../components/rdg/rdgComponents";

const {DropDownEditor} = Editors;

export const columns = [
    {
        key: 'type',
        name: 'Type',
        width: 130,
        _getValueFromRecord: (r) => {

            // dont show if share class, as gets confusing
            if (r.is_share_class === true) {
                return 'Share Class'
            }
            if (r.is_security_listing === true) {
                return <div style={{paddingLeft: '10px'}}>Security Listing</div>
                // return ' &#x09 Security Listing'
            }
            return ''
        },
        frozen: true
    },
    {
        key: 'share_class_id',
        name: 'Share Class ID',
        width: 70,
        _getValueFromRecord: (r) => {

            // dont show if share class, as gets confusing
            if (r.is_share_class === true) {
                return r.share_class_id
            }
        },
        frozen: true
    },
    {
        key: 'security_listing_id',
        name: 'Security Listing ID',
        width: 70,
        frozen: true
    },
    {
        key: 'index',
        name: 'Index',
        width: 50,
        frozen: true
    },
    {
        key: 'name',
        name: 'Name',
        width: 200,
        editable: true,
        frozen: true
    },
    {
        key: 'exchange_ticker',
        name: 'Exchange: Ticker',
        width: 150,
        frozen: true,
        editable: true,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'exchange',
        name: 'Exchange',
        width: 80
    },

    {
        key: 'ciq_trading_id',
        editable: true,
        name: 'Security CIQ ID',
        width: 120
    },
    {
        key: 'is_primary',
        name: 'Is Primary Security?',
        formatter: BoolToYesCheckFormatter,
        _getValueFromRecord: (r) => {

            // dont show if share class, as gets confusing
            if (r.is_share_class === true) {
                return null
            }
            return r.is_primary
        }

    },
    {
        key: 'convert_to_primary_share_class_security_multiplier',
        name: 'Conversion to Primary',
        width: 80
    },
    {
        key: 'is_custom_alternative_listing',
        name: 'Is Custom Alt Listing?',
        formatter: BoolToYesNoFormatter,
    },
    {
        key: 'is_live_trading_alternative',
        name: 'Is Live Trading Alt?',
        formatter: BoolToYesNoFormatter,
        editable: true,
        editor: <DropDownEditor options={boolOptions}/>,
    },

    {
        key: 'last_share_split_date',
        name: 'Last Share Split Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'inactive_date',
        name: 'Inactive Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'first_ciq_price_date',
        name: 'First CIQ Price Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'last_ciq_price_date',
        name: 'Last CIQ Price Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'first_cached_price_date',
        name: 'First Cached Price Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'last_cached_price_date',
        name: 'Last Cached Price Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'last_ex_dividend_date',
        name: 'Last Ex-Dividend Date',
        formatter: UtcDateFormatter,
        width: 90
    },


    {
        key: 'trading_currency',
        name: 'Trading Currency',
        width: 90
    },
    {
        key: 'convert_to_share_class_id',
        name: 'Converts to Share Class ID',
        width: 100
    },
    {
        key: 'cusip',
        name: 'CUSIP',
        editable: true,
        formatter: StringFormatter,
        width: 100,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'gs_product_id',
        name: 'GS Product ID',
        editable: true,
        formatter: StringFormatter,
        width: 100,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'eikon_instrument_code',
        name: 'Eikon Instrument Code',
        editable: true,
        formatter: StringFormatter,
        width: 100,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'isin',
        name: 'ISIN',
        editable: true,
        formatter: StringFormatter,
        width: 130,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'sedol',
        name: 'SEDOL',
        editable: true,
        formatter: StringFormatter,
        width: 100,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'yahoo_ticker',
        name: 'Yahoo Finance Ticker',
        editable: true,
        formatter: StringFormatter,
        width: 100,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'iex_symbol',
        name: 'IEX Symbol',
        editable: true,
        formatter: StringFormatter,
        width: 100,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'convert_to_cash_date',
        name: 'Convert to Cash / Go Private Date',
        editable: true,
        formatter: DateFormatter,
        width: 100,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'convert_to_cash_price_per_share',
        name: 'Convert to Cash $/share',
        editable: true,
        formatter: SharePriceFormatter,
        width: 100,
        headerRenderer: <IsEditableFieldRenderer/>,
    },
    {
        key: 'primary_security_listing_min_date',
        name: 'Min Date as Primary Security',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'primary_security_listing_max_date',
        name: 'Max Date as Primary Security',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'is_inactive',
        name: 'Is Inactive',
        formatter: BoolToYesNoFormatter,
        width: 90
    },
    {
        key: 'ui_display_name',
        name: 'Display Name',
        formatter: StringFormatter,
        width: 90
    },



];