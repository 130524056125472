import {DateFormatter, ThreeDecimalNoNullFormatter} from "../../../common/reactDataGrid/formatters";

export const columns = [
    {
        key: 'id',
        name: 'ID',
        width: 80
    },
    {
        key: 'security_listing_id',
        name: 'Security Listing ID',
        width: 100
    },
    {
        key: 'record_date',
        name: 'Record Date',
        width: 100,
        formatter: DateFormatter,
    },
    {
        key: 'ex_dividend_date',
        name: 'Ex-Dividend Date',
        width: 100,
        formatter: DateFormatter,
    },
    {
        key: 'payment_date',
        name: 'Payment Date',
        width: 100,
        formatter: DateFormatter,
    },
    {
        key: 'amount_per_share',
        name: '$/share',
        width: 100,
        formatter: ThreeDecimalNoNullFormatter,
    },
    {
        key: 'payment_type',
        name: 'Payment Type',
        width: 100,
    },

]