import React from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {Grid, Progress, Statistic, Divider} from 'semantic-ui-react'
import {fetchCompaniesForTags} from '../../actions/tagActions';
import TagTable from './TagTable';
import TagFilters from './TagFilters'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from "../../common/request/convert";
import {useReloadOnChange} from "../../common/hooks";
import {QualityText} from '../../common/constants'
import ViewWithSidebar from '../../components/ViewWithSidebar'


export default () => {

    const {companies} = useSelector(state => state.tagsStore, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    console.log(companies)

    const companiesCount = companies.length;
    const greatBusinessCount = getCount({records: companies, fieldName: 'business_quality', value: QualityText.great});
    const goodBusinessCount = getCount({records: companies, fieldName: 'business_quality', value: QualityText.good});
    const averageBusinessCount = getCount({
        records: companies,
        fieldName: 'business_quality',
        value: QualityText.average
    });
    const badBusinessCount = getCount({records: companies, fieldName: 'business_quality', value: QualityText.bad});
    const terribleBusinessCount = getCount({
        records: companies,
        fieldName: 'business_quality',
        value: QualityText.terrible
    });

    const coreTrackCount = getCount({records: companies, fieldName: 'tracking_rank', value: '1: Core'});
    const trackTrackCount = getCount({records: companies, fieldName: 'tracking_rank', value: '2: Track'});

    const greatManagementCount = getCount({records: companies, fieldName: 'management_score', value: 5});
    const goodManagementCount = getCount({records: companies, fieldName: 'management_score', value: 4});
    const averageManagementCount = getCount({
        records: companies,
        fieldName: 'management_score',
        value: 3
    });
    const badManagementCount = getCount({records: companies, fieldName: 'management_score', value: 2});
    const terribleManagementCount = getCount({
        records: companies,
        fieldName: 'management_score',
        value: 1
    });

    const sideBar = (
        <Grid centered columns={1} relaxed>
            <Grid.Row>
                <Statistic horizontal value={companiesCount} label='Companies'
                           size="tiny"/>
                <br/>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <p>
                        Blue fields are editable
                    </p>
                    <Divider horizontal>Business Quality</Divider>
                    <Progress value={greatBusinessCount} total={companiesCount} color='green'
                              progress='value'
                              label='Great Business'/>
                    <Progress value={goodBusinessCount} total={companiesCount} color='blue'
                              progress='value'
                              label='Good Business'/>
                    <Progress value={averageBusinessCount} total={companiesCount} color='grey'
                              progress='value'
                              label='Avg Business'/>
                    <Progress value={badBusinessCount} total={companiesCount} color='orange'
                              progress='value'
                              label='Bad Business'/>
                    <Progress value={terribleBusinessCount} total={companiesCount} color='red'
                              progress='value'
                              label='Terrible Business'/>
                    <br/>
                    <Divider horizontal>Tracking Rank</Divider>
                    <Progress value={coreTrackCount} total={companiesCount} color='green'
                              progress='value'
                              label='Track: Core (Tier 1)'/>
                    <Progress value={trackTrackCount} total={companiesCount} color='blue'
                              progress='value'
                              label='Track: Track (Tier 2)'/>
                    <br/>
                    <Divider horizontal>Management Quality</Divider>
                    <Progress value={greatManagementCount} total={companiesCount} color='green'
                              progress='value'
                              label='Great Management'/>
                    <Progress value={goodManagementCount} total={companiesCount} color='blue'
                              progress='value'
                              label='Good Management'/>
                    <Progress value={averageManagementCount} total={companiesCount} color='grey'
                              progress='value'
                              label='Avg Management'/>
                    <Progress value={badManagementCount} total={companiesCount} color='orange'
                              progress='value'
                              label='Bad Management'/>
                    <Progress value={terribleManagementCount} total={companiesCount} color='red'
                              progress='value'
                              label='Terrible Management'/>
                    <br/>

                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Divider horizontal> Filters </Divider>
                    <TagFilters/>
                    <p>
                        Apply the "Override: Is Public" and reimport if CapIQ data is incorrect.  For Bentley, CapIQ
                        reclassified the company to public prior to its IPO.  We can manually force it to remain a private
                        company until the IPO.
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>

    );

    return (
        <ViewWithSidebar mainView={<TagTable/>} sidebarView={sideBar}/>

    )
};

const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });
    fetchCompaniesForTags({dispatch, sort, filters});
};

const getCount = ({records, fieldName, value}) => {
    return records.reduce((total, record) => {
        if (record[fieldName] === value) {
            total += 1;
        }
        return total
    }, 0);
};
