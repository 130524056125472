import {formatMultipleOneDecimal, formatPercentNoNull} from "../../../common/format";


export const chartSpecifications = [
    {
        name: '5-Year Gain vs. Entry EV/NTM Revenue',
        getX: (record) => record.entry_ev_to_revenue_total_ntm,
        getY: (record) => record.total_gain,
        getXAxisLabel: () => 'Entry EV/NTM Revenue',
        getYAxisLabel: () => '5-Year Gain',
        formatX: formatMultipleOneDecimal,
        formatY: formatPercentNoNull,
        logicText: 'While we are not directly targeting lower multiples, like-for-like we are selecting the less inexpensive businesses and avoiding egregious valuations.  We would expect to usually see a negative correlation between entry multiples and expected returns.'
    },
    {
        name: '5-Year Gain vs. Gross Retention',
        getX: (record) => record.exit_arr_gross_retention,
        getY: (record) => record.total_gain,
        getXAxisLabel: () => 'Gross Retention',
        getYAxisLabel: () => '5-Year Gain',
        formatX: formatPercentNoNull,
        formatY: formatPercentNoNull,
        logicText: 'We believe public investors spend less time than us thinking about the signals from high gross retention (better competitive position, higher margins, more sustainable growth, etc.).  We should expect to see a positive correlation between gross retention and expected returns.'
    },
    {
        name: '5-Year Gain vs. Historical Avg. Sales Efficiency',
        getX: (record) => record.entry_average_implemented_arr_to_sm,
        getY: (record) => record.total_gain,
        getXAxisLabel: () => 'Historical Avg. Sales Efficiency',
        getYAxisLabel: () => '5-Year Gain',
        formatX: formatMultipleOneDecimal,
        formatY: formatPercentNoNull,
        logicText: 'Sales efficiency is an important measure of both business quality and operating efficiency but less persistent than gross retention.'
    },
    {
        name: '5-Year Gain vs. Max Pretax FCF Margin',
        getX: (record) => record.exit_maximized_pretax_fcf_margin_ntm,
        getY: (record) => record.total_gain,
        getXAxisLabel: () => 'Max Pretax FCF Margin',
        getYAxisLabel: () => '5-Year Gain',
        formatX: formatPercentNoNull,
        formatY: formatPercentNoNull,
        logicText: 'Max margin incorporates multiple differentiating factors we focus on that are not as focused on by investors (gross margin, retention, sales efficiency).  We would expect max margin to be correlated with higher expected returns.'
    },
    {
        name: '5-Year Gain vs. Entry LTM ARR Growth',
        getX: (record) => record.entry_ltm_organic_arr_growth,
        getY: (record) => record.total_gain,
        getXAxisLabel: () => 'Entry LTM ARR Growth',
        getYAxisLabel: () => '5-Year Gain',
        formatX: formatPercentNoNull,
        formatY: formatPercentNoNull,
        logicText: 'Growth may be overvalued or undervalued by the market at different points in time.  If our return algorithm is fair, we should see a mix of returns at different growth rates.'
    }
];

export const chartSpecByName = chartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});