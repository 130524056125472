import React from "react";
import {
    BoolToNoFlagFormatter,
    BoolToYesCheckFormatter,
    PercentNoDecimalNoNullFormatter, PercentOneDecimalNoNullFormatter,
    UtcDateFormatter
} from "../../common/reactDataGrid/formatters";
import {boolOptions} from "../../common/reactDataGrid/common";
import {Editors} from 'react-data-grid-addons';
import moment from 'moment';
import {getDateOptionsToPresent} from "../../common/dateHelpers";
import {formatPercentOneDecimalNoNull} from "../../common/format";

const {DropDownEditor} = Editors;


const earliestDoradoUniverseEntryDate = moment("20180601", "YYYYMMDD");
export const startEndDateOptions = getDateOptionsToPresent(earliestDoradoUniverseEntryDate).map(d => {
    const s = d.format('YYYY-MM-DDTHH:mm:ss');
    return {
        key: s,
        value: s,
        text: d.format('MM-DD-YYYY')
    }

});




export const inDoradoUniverseColumn = {
    key: 'in_dorado_universe',
    name: 'In Dorado Universe',
    formatter: <BoolToYesCheckFormatter/>,
    width: 70,
    editable: true,
    sortable: true,
    editor: <DropDownEditor options={boolOptions}/>
}


export const doradoEvaluationOnlyColumn = {
    key: 'dorado_evaluation_only',
    name: 'Evaluation Only?',
    formatter: <BoolToYesCheckFormatter color={'blue'}/>,
    width: 70,
    editable: true,
    sortable: true,
    editor: <DropDownEditor options={boolOptions}/>
}


export const pointSurIsValidColumn = {
    key: 'ps_is_valid',
    name: 'PS Dorado Valid?',
    sortable: true,
    formatter: <BoolToNoFlagFormatter color={'red'}/>,
    width: 70,
    _getValueFromRecord: (r) => {
        if (!r.in_dorado_universe) {
            return true
        }
        return r.ps_is_valid
    }
}


export const psErrorTextColumn = {
    key: 'ps_error_text',
    name: 'PS: Error Text',
    sortable: true,
    resizable: true,
    width: 350,
    _getValueFromRecord: (r) => {
        if (!r.in_dorado_universe) {
            return ""
        }
        return r.ps_error_text
    }
};




export const inDoradoUniverseStartDateColumn = {
    key: 'in_dorado_universe_start_date',
    name: 'In Dorado Universe Start Date',
    sortable: true,
    formatter: UtcDateFormatter,
    width: 90,
    editable: true,
    editor: <DropDownEditor options={startEndDateOptions}/>
};


export const inDoradoUniverseEndDateColumn = {
    key: 'in_dorado_universe_end_date',
    name: 'In Dorado Universe End Date',
    sortable: true,
    formatter: UtcDateFormatter,
    width: 90,
    editable: true,
    editor: <DropDownEditor options={startEndDateOptions}/>
};



export const backtestLockedStartDateColumn = {
    key: 'dorado_is_locked_start_date',
    name: 'Backtest: Locked Start',
    sortable: true,
    formatter: UtcDateFormatter,
    width: 90,
    editable: true,
    editor: <DropDownEditor options={startEndDateOptions}/>
};


export const backtestLockedEndDateColumn = {
    key: 'dorado_is_locked_end_date',
    name: 'Backtest: Locked End',
    sortable: true,
    formatter: UtcDateFormatter,
    width: 90,
    editable: true,
    editor: <DropDownEditor options={startEndDateOptions}/>
};



const customLongConcentrationOptions = [
    {
        key: '<null>',
        text: '',
        value: null
    },
    ...[.01, .02, .025, .03, .04, .05, .06, .07, .075, .08, .09].map(v => {
        return {
            key: v,
            text: formatPercentOneDecimalNoNull(v),
            value: v
        }
    })
];





export const doradoLongConcentrationCol = {
    key: 'dorado_max_long_percent_of_equity',
    name: 'Max Long % Equity',
    sortable: true,
    formatter: PercentOneDecimalNoNullFormatter,
    width: 80,
    editable: true,
    editor: <DropDownEditor options={customLongConcentrationOptions}/>

}


const doradoShortWeightMultiplierOptions = [
    {
        key: '<null>',
        text: '',
        value: null
    },
    ...[.25, .33, .5, 1.5, 2.0].map(v => {
        return {
            key: v,
            text: formatPercentOneDecimalNoNull(v),
            value: v
        }
    })
];


export const doradoShortWeightMultiplierColumn = {
    key: 'dorado_short_weight_multiplier',
    name: 'Short Weight Multiplier',
    sortable: true,
    formatter: PercentNoDecimalNoNullFormatter,
    width: 80,
    editable: true,
    editor: <DropDownEditor options={doradoShortWeightMultiplierOptions}/>

}

