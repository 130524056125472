const defaultState = {
    doradoValuationRecords: [],
    loadingDoradoValuationRecords: true,

    doradoValuationFacetsByField: {},
    loadingDoradoValuationFacetsByField: true,


    // company valuation backup
    doradoInterpolatedFinancialPeriodRecords: [],
    loadingDoradoInterpolatedFinancialPeriodRecords: true,

    // single record
    doradoValuationRecord: {},
    loadingDoradoValuationRecord: true


};

export default (state = defaultState, action = {}) => {
    switch (action.type) {

        case "FETCH_DORADO_VALUATION_RECORDS_PENDING" : {
            return {
                ...state,
                loadingDoradoValuationRecords: true
            }
        }

        case "FETCH_DORADO_VALUATION_RECORDS_FULFILLED" : {

            return {
                ...state,
                loadingDoradoValuationRecords: false,
                doradoValuationRecords: action.payload.data
            }
        }

        case "FETCH_DORADO_VALUATION_RECORDS_FACETS_PENDING" : {

            return {
                ...state,
                loadingDoradoValuationFacetsByField: true
            }
        }

        case "FETCH_DORADO_VALUATION_RECORDS_FACETS_FULFILLED" : {

            return {
                ...state,
                loadingDoradoValuationFacetsByField: false,
                doradoValuationFacetsByField: action.payload.data
            }
        }

        case "GET_DORADO_VALUATION_RECORD_PENDING": {
            return {
                ...state,
                loadingDoradoValuationRecord: true
            }
        }

        case "GET_DORADO_VALUATION_RECORD_FULFILLED": {
            return {
                ...state,
                loadingDoradoValuationRecord: false,
                doradoValuationRecord: action.payload.data
            }
        }

        case "FETCH_DORADO_INTERPOLATED_FINANCIAL_PERIOD_RECORDS_PENDING": {
            return {
                ...state,
                loadingDoradoInterpolatedFinancialPeriodRecords: true
            }
        }

        case "FETCH_DORADO_INTERPOLATED_FINANCIAL_PERIOD_RECORDS_FULFILLED": {
            return {
                ...state,
                loadingDoradoInterpolatedFinancialPeriodRecords: false,
                doradoInterpolatedFinancialPeriodRecords: action.payload.data
            }
        }


        default:
            return state;
    }
}