import React from 'react'
import {
    BoolToNoFlagFormatter, BoolToNoTextFormatter,
    BoolToYesCheckFormatter,
    BoolToYesFlagFormatter,
    DoradoUniverseTypeFormatter,
    NoDecimalNoNullOrZeroFormatter,
    TwoDecimalNoNullFormatter,
    UtcDateFormatter
} from '../../common/reactDataGrid/formatters';
import {commonColumnsByName, companyColumnFromCompanyId} from "../../common/reactDataGrid/columns"
import {Button, Icon} from "semantic-ui-react";
import {startImportProcessForCompany} from "../../features/adminFeatureSlice";
import {Editors} from 'react-data-grid-addons';
import {boolOptions} from "../../common/reactDataGrid/common";
import {
    doradoLongConcentrationCol,
    doradoEvaluationOnlyColumn,
    inDoradoUniverseColumn,
    inDoradoUniverseStartDateColumn,
    pointSurIsValidColumn,
    psErrorTextColumn,
    doradoShortWeightMultiplierColumn,
    backtestLockedStartDateColumn,
    backtestLockedEndDateColumn,
    inDoradoUniverseEndDateColumn
} from "../../components/rdg/dataQuality";
import {benchNullOption} from "../../common/query/null";
import {DoradoUniverseType} from "../../common/constants";
import {Link} from "react-router-dom";

const {DropDownEditor} = Editors;


class RefreshCompanyFormatter extends React.Component {
    render() {

        const dispatch = this.props.dispatch;
        const companyId = this.props.value.company_id;
        const loadingImportStartedAcknowledgement = this.props.value.loadingImportStartedAcknowledgement;
        const buttonStyle = {"paddingTop": 4, "paddingBottom": 4};

        return (
            <div>
                <Button loading={loadingImportStartedAcknowledgement} icon basic size="small" color="green" style={buttonStyle}
                        onClick={() => dispatch(startImportProcessForCompany({companyId}))}
                >
                    <Icon name="sync"/>
                </Button>
            </div>

        )

    }
}

export const doradoUniverseTypeOptions = [
    benchNullOption,
    {
        key: DoradoUniverseType.alphaLong,
        value: 1,
        text: DoradoUniverseType.alphaLong
    },
    {
        key: DoradoUniverseType.largeIndexWeight,
        value: 2,
        text: DoradoUniverseType.largeIndexWeight
    },
    {
        key: DoradoUniverseType.predictableShort,
        value: 3,
        text: DoradoUniverseType.predictableShort
    },
];




const psDoradoBacktestColumns = [
    inDoradoUniverseStartDateColumn,
    inDoradoUniverseEndDateColumn,
    backtestLockedStartDateColumn,
    backtestLockedEndDateColumn,

    {
        key: 'backtest_forecast_error_text',
        name: 'Backtest Error Detail',
        sortable: true,
        width: 350,
        resizable: true,
        // _getValueFromRecord: (r) => {
        //     if (!r.in_dorado_universe) {
        //         return ""
        //     }
        //     return r.ps_backtest_error_text
        // }
    },
];

class DetailLinkFormatter extends React.Component {
    render() {
        return <Link to={'/company_detail/backtest_data_quality/' + this.props.value}>Audit</Link>
    }
}

export const getColumns = ({dispatch, loadingImportStartedAcknowledgement}) => {
    return [
        companyColumnFromCompanyId,
        {
            key: 'refresh_company',
            name: '',
            formatter: <RefreshCompanyFormatter dispatch={dispatch}/>,
            width: 55,
            frozen: true,
            _getValueFromRecord: (r) => {
                return {
                    company_id: r.company_id,
                    loadingImportStartedAcknowledgement: loadingImportStartedAcknowledgement
                }
            }
        },
        {
            key: 'backtest_link',
            name: "  ",
            formatter: DetailLinkFormatter,
            width: 50,
            frozen: true,
            _getValueFromRecord: (r) => {
                return r.company_id
            }

        },
        {
            key: 'historical_periods_count',
            name: 'Historical Records',
            sortable: true,
            formatter: NoDecimalNoNullOrZeroFormatter,
            width: 90
        },
        {
            key: 'current_forecast_is_valid',
            name: 'Dorado Valid',
            sortable: true,
            formatter: <BoolToNoFlagFormatter color={'red'}/>,
            width: 70,
            _getValueFromRecord: (r) => {
                // dont want to raise a flag if out of universe
                if (r.in_dorado_universe_end_date != null) {
                    return true
                }
                return r.current_forecast_is_valid
            }
        },
        {
            key: 'backtest_forecast_is_valid',
            name: 'Backtest Valid',
            sortable: true,
            formatter: <BoolToNoFlagFormatter color={'blue'}/>,
            width: 80,
            resizable: true,
            // _getValueFromRecord: (r) => {
            //     if (!r.in_dorado_universe) {
            //         return true
            //     }
            //     return r.ps_backtest_is_valid
            // }
        },

        inDoradoUniverseColumn,

        {
            key: 'has_eikon_ric_and_sedol_or_isin',
            name: 'Has Eikon/ SEDOL',
            formatter: <BoolToNoFlagFormatter/>,
            width: 70,
        },
        doradoEvaluationOnlyColumn,
        {
            key: 'dorado_is_locked',
            name: 'Locked Position?',
            formatter: <BoolToYesCheckFormatter color={'red'} iconName={'lock'}/>,
            width: 70,
            editable: true,
            sortable: true,
            editor: <DropDownEditor options={boolOptions}/>
        },


        doradoLongConcentrationCol,

        {
            key: 'max_margin_not_applicable',
            name: 'Max Margin Not Applicable',
            sortable: true,
            formatter: <BoolToYesCheckFormatter color={'grey'} iconName={'circle'}/>,
            width: 75,
            editable: true,
            editor: <DropDownEditor options={boolOptions}/>
        },
        {
            key: 'arr_not_applicable',
            name: 'ARR Not Applicable',
            sortable: true,
            formatter: <BoolToYesCheckFormatter color={'grey'} iconName={'circle'}/>,
            width: 75,
            editable: true,
            editor: <DropDownEditor options={boolOptions}/>
        },
        {
            key: 'valuation_premium_not_applicable',
            name: 'Valuation Premium Not Applicable',
            sortable: true,
            formatter: <BoolToYesCheckFormatter color={'grey'} iconName={'circle'}/>,
            width: 75,
            editable: true,
            editor: <DropDownEditor options={boolOptions}/>
        },

        // point sur validations
        {
            key: 'current_forecast_error_text',
            name: 'Dorado Error Detail',
            sortable: true,
            resizable: true,
            width: 350,
        },

        ...psDoradoBacktestColumns,
        {
            key: 'dorado_disable_short',
            name: 'Disable Short (live)?',
            formatter: <BoolToYesCheckFormatter color={'red'} iconName={'lock'}/>,
            width: 70,
            editable: true,
            sortable: true,
            editor: <DropDownEditor options={boolOptions}/>
        },
        doradoShortWeightMultiplierColumn,
        {
            key: 'dorado_backtest_disable_short',
            name: 'Disable Short (backtest)?',
            formatter: <BoolToYesCheckFormatter color={'red'} iconName={'lock'}/>,
            width: 70,
            editable: true,
            sortable: true,
            editor: <DropDownEditor options={boolOptions}/>
        },
        {
            key: 'has_asc_605_overrides',
            name: 'Has ASC 605 Revenue Override',
            sortable: true,
            formatter: <BoolToYesFlagFormatter color={'blue'}/>,
            width: 90
        },
        {
            key: 'is_public',
            name: 'Is Public?',
            formatter: BoolToNoTextFormatter,
            width: 70,
        },

    ];
};