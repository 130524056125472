import {getDateOptionsToPresent} from "../../../common/dateHelpers";
import {doradoStartDate, earliestBacktestDate} from "../../dorado/portfolioParams/common";
import {formatAbsoluteDateString} from "../../../common/formatDate";
import moment from "moment";
import {
    PercentGainOneDecimalFormatter,
    PercentOneDecimalNoNullFormatter,
    SharePriceFormatter,
    WeightFormatter
} from "../../../common/reactDataGrid/formatters";


const isWeekday = (d) => {
    const weekDay = d.isoWeekday();
    return !(weekDay === 6 || weekDay === 7);

}




export const igvDates = getDateOptionsToPresent(earliestBacktestDate).filter(d => isWeekday(d));
export const preDoradoDate = moment("20200807", "YYYYMMDD");


export const fieldSpecs = {
    startDate: {
        parse: (v) => v == null ? preDoradoDate.format('YYYY-MM-DD') : v
    },
    endDate: {
        parse: (v) => v == null ? igvDates[igvDates.length - 1].format('YYYY-MM-DD') : v
    },

}


export const igvDateOptions = igvDates.map(v => {
    let text = formatAbsoluteDateString({dateString: v, format: 'M/D/YYYY (ddd)'});
    const value = v.format('YYYY-MM-DD');

    return {
        text: text,
        key: value,
        value: value
    }
});


export const columns = [
    {
        key: 'short_name',
        name: 'Name',
        width: 200,
        frozen: true
    },
    {
        key: 'beginning_weight',
        name: 'Beginning Weight',
        width: 70,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'ending_weight',
        name: 'Ending Weight',
        width: 70,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'price_change_percent',
        name: 'Price % Chng',
        width: 70,
        formatter: PercentGainOneDecimalFormatter
    },
    {
        key: 'return_contribution',
        name: 'Return Contrib.',
        width: 80,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'cumulative_return_contribution',
        name: 'Cumul. Return Contrib.',
        width: 80,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'beginning_price',
        name: 'Beginning Price',
        width: 70,
        formatter: SharePriceFormatter
    },
    {
        key: 'ending_price',
        name: 'Ending Weight',
        width: 70,
        formatter: SharePriceFormatter
    },




];

