import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {Button, Grid, Header, Icon, Statistic} from "semantic-ui-react";
import React from "react";


export default () => {

    const {
        doradoValuationRecords,
        loadingDoradoValuationRecords,
        // loadingMissingRecordCount,
        // missingRecordCount
    } = useSelector(state => state.doradoValuation, shallowEqual);

    // const missingColor = missingRecordCount > 0 ? 'red' : 'black';

    return (
        <Grid.Row key={"record_ct"}>
            <Statistic.Group>
                <Statistic horizontal value={doradoValuationRecords.length} label='Records' size="tiny"/>
                {/*<Statistic horizontal value={missingRecordCount} label='Missing Records' size="tiny" color={missingColor}/>*/}
            </Statistic.Group>
        </Grid.Row>)

}