const defaultState = {
    records: [],
    loadingRecords: true
};


export default (state = defaultState, action = {}) => {
    switch (action.type) {

        case "FETCH_HISTORICAL_VALUATION_RECORDS_FOR_COMPANY_PENDING": {
            return {
                ...state,
                loadingRecords: true
            }
        }

        case "FETCH_HISTORICAL_VALUATION_RECORDS_FOR_COMPANY_FULFILLED": {
            return {
                ...state,
                loadingRecords: false,
                records: action.payload.data
            }
        }

        default:
            return state;
    }
}
