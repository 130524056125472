


export const getTradeOrderIds = ({doradoTargetSecurityOrders}) => {

    doradoTargetSecurityOrders = doradoTargetSecurityOrders.filter(r => {
        if (r._include_trade_order == null) {
            return true
        }
        return r._include_trade_order
    })

    return doradoTargetSecurityOrders.map(r => r.id);

}