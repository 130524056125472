import client from '../../empClient';
const exportService = client.service('download_records');

export function downloadRecordsForScrapeId(id) {

  return dispatch => {
    dispatch({
      type: 'DOWNLOAD_EMPLOYEE_RECORDS',
      payload: exportService.get(id, {responseType: 'blob'}).then((response, other) => {

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', id + '.csv');
        document.body.appendChild(link);
        link.click();


      })
    });
  };


}


