import React from "react";
import {
    getCompanyLink, MultipleOneDecimalFormatter,
    NoDecimalNoNullOrZeroFormatter, OneDecimalNoNullFormatter, PercentGainsFormatter,
    PercentOneDecimalNoNullFormatter, PercentTwoDecimalNoNullFormatter, PricePercentChangeOneDecimalFormatter,
    RelativeWeightFormatter, SharePriceFormatter,
    ThreeDecimalNoNullFormatter,
    TwoDecimalNoNullFormatter, UtcToLocalFullDateTimeFormatter,
    WeightFormatter
} from "../../../common/reactDataGrid/formatters";
import {divideValueBy1000000} from "../../../common/reactDataGrid/valuationColumns";
import {portfolioHeaderColumn} from "../../../components/portfolio/portfolioColumns";
import {CurrencySharePriceFormatter} from "../common";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";


export const fieldSpecs = {
    portfolio_id: {
        parse: (v) => v == null ? null : parseInt(v)
    },
}

const getDvrValueOrNull = (r, field) => {
    return r.dorado_valuation_record == null ? null : r.dorado_valuation_record[field]
}

const getSiValueOrNull = (r, field) => {
    return r.security_investment == null ? null : r.security_investment[field]
}



export const columns = [
    CompanyLinkFromSecurityListing,
    {
        key: 'percent_of_net_asset_value',
        name: '% of NAV',
        formatter: WeightFormatter,
        width: 65,
        frozen: true
    },
    {
        key: 'percent_of_securities_long_market_value',
        name: '% of Long Portfolio',
        formatter: PercentOneDecimalNoNullFormatter,
        width: 65,
        frozen: true
    },
    {
        key: 'market_value',
        name: 'Market Value $m',
        formatter: ThreeDecimalNoNullFormatter,
        width: 70,
        frozen: true,
        _getValueFromRecord: (r) => {
            return divideValueBy1000000(r.market_value)
        }
    },
    {
        key: 'price_per_share_trading',
        name: 'Price $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        frozen: true,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.price_per_share_trading,
                trading_currency: r.trading_currency
            }
        }
    },
    {
        key: 'last_trade_datetime',
        name: 'Last Trade Time',
        formatter: UtcToLocalFullDateTimeFormatter,
        width: 150,
        _getValueFromRecord: (r) => {
            return getDvrValueOrNull(r, 'last_trade_datetime')
        }
    },
    {
        key: 'price_change_from_last_close',
        name: '% Change (day)',
        formatter: PricePercentChangeOneDecimalFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return getDvrValueOrNull(r, 'price_change_from_last_close')
        }
    },

    {
        key: 'daily_dollar_volume_usd',
        name: 'Volume in Day ($m)',
        formatter: OneDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000000(
                getDvrValueOrNull(r, 'daily_dollar_volume_usd'))
        }
    },

    {
        key: 'irr',
        name: 'Expected IRR',
        width: 90,
        formatter: PercentOneDecimalNoNullFormatter,
        _getValueFromRecord: (r) => {
            return getDvrValueOrNull(r, 'irr')
        }
    },
    {
        key: 'no_multiple_expansion_irr',
        name: 'No Multiple Expansion IRR',
        width: 90,
        formatter: PercentOneDecimalNoNullFormatter,
        _getValueFromRecord: (r) => {
            return getDvrValueOrNull(r, 'no_multiple_expansion_irr')
        }
    },
    {
        key: 'last_close_price',
        name: 'Previous Close Price',
        width: 90,
        formatter: <CurrencySharePriceFormatter/>,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: getDvrValueOrNull(r, 'last_close_price'),
                trading_currency: r.trading_currency
            }
        }
    },
    {
        key: 'daily_volume',
        name: 'Volume in Day (shares)',
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return getDvrValueOrNull(r, 'daily_volume')
        }
    },
    {
        key: 'daily_trade_net_value',
        name: '$ Traded by Dorado Today',
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 80,
    },
    {
        key: 'daily_trade_net_value_percent_of_equity',
        name: '% of NAV Traded by Dorado Today',
        formatter: PercentTwoDecimalNoNullFormatter,
        width: 90,
        _getValueFromRecord: (r) => {
            return r.daily_trade_net_value_percent_of_equity === 0 ? null : r.daily_trade_net_value_percent_of_equity
        }
    },
    {
        key: 'entry_ev_to_revenue_total_ntm',
        name: 'Entry EV/NTM Revenue',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return getDvrValueOrNull(r, 'entry_ev_to_revenue_total_ntm')
        }
    },
    {
        key: 'entry_ev_to_pretax_fcf_ntm',
        name: 'Entry EV/NTM Pretax FCF',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return getDvrValueOrNull(r, 'entry_ev_to_pretax_fcf_ntm')
        }
    },
    {
        key: 'entry_ev_to_maximized_pretax_fcf_ntm',
        name: 'Entry EV/NTM Max FCF',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return getDvrValueOrNull(r, 'entry_ev_to_maximized_pretax_fcf_ntm')
        }
    },
    {
        key: 'entry_ntm_organic_arr_growth',
        name: 'NTM Organic ARR Growth',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return getDvrValueOrNull(r, 'entry_ntm_organic_arr_growth')
        }
    },
    {
        key: 'net_shares',
        name: 'Shares',
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 90,
    },
    {
        key: 'unrealized_capital_gain_percent_usd',
        name: 'Unrealized Gain %',
        formatter: PercentGainsFormatter,
        width: 85,
        _getValueFromRecord: (r) => {
            return getSiValueOrNull(r, 'unrealized_capital_gain_percent_usd')
        }
    },
    {
        key: 'unrealized_capital_gain_percent_trading',
        name: 'Unrealized CC Gain %',
        formatter: PercentGainsFormatter,
        width: 85,
        _getValueFromRecord: (r) => {

            if (r.trading_currency === 'USD') {
                return null
            }
            return getSiValueOrNull(r, 'unrealized_capital_gain_percent_trading')
        }
    },
    {
        key: 'unrealized_capital_gain_usd',
        name: 'Unrealized Gain $m',
        formatter: ThreeDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000000(
                getSiValueOrNull(r, 'unrealized_capital_gain_usd')
            )
        }
    },
    {
        key: 'unrealized_cost_basis_per_share_trading',
        name: 'Cost $/share (prior day)',
        formatter: <CurrencySharePriceFormatter/>,
        width: 90,
        _getValueFromRecord: (r) => {

            return {
                price_per_share: getSiValueOrNull(r, 'unrealized_cost_basis_per_share_trading'),
                trading_currency: r.trading_currency
            }
        }
    },
    {
        key: 'security_listing_id',
        name: 'Security Listing ID',
        width: 90,
    },
    {
        key: 'id',
        name: 'ID',
        width: 90,
    },

]