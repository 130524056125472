import {abbreviateSource, cellWidth, createHeaderRow, createSectionHeaderRow, emptyRow, rowHeaderWidth} from "./common";
import {createRow, HeaderContentRenderer, LeftAlignHeaderRenderer} from "../rdg/helpers";
import {
    formatBooleanYes,
    formatBooleanYesNo,
    formatFullDate, formatMultipleTwoDecimals,
    formatNoDecimal, formatNoDecimalNoNull,
    formatNoDecimalNoNullOrZero,
    formatPercentNoNull,
    formatPercentOneDecimalNoNull, formatPercentTwoDecimalNoNull, formatSharePrice, formatString, formatTwoDecimalNoNull
} from "../../common/format";
import React from "react";
import {
    functionalOpexMarginSpecs,
    functionalOpexSpecs,
    getNonNullArrSpecs,
    getNonNullCompositionFieldNames,
    getNonNullSpecs,
    hasGrossProfit,
    sgaMarginSpecs,
    sgaSpecs
} from "../companyValuation/financials/common";
import {
    ArrCompositionSpecs, colorBySourceType,
    GrossMarginCompositionMappingByDbField,
    GrossProfitCompositionMappingByDbField,
    RevenueCompositionMappingByDbField,
    RevenueTypeSpecs
} from "../../common/constants";
import {
    getClassToColorCapex, getClassToColorChangeInDeferred,
    getClassToColorDilution,
    getClassToColorEBITDA,
    getClassToColorGrossProfit,
    getClassToColorOpex,
    getClassToColorRevenue,
    getClassToColorRevenueComposition,
    makeGetClassToColorBoolean,
    makeGetEmphasizeYesBoolean
} from "../../common/colors";
import {BoolToYesFormatter} from "../../common/reactDataGrid/formatters";


export const getPeriodRow = ({financialRecords}) => {

    return [
        {
            key: 'rowHeader',
            name: 'Period End',
            width: rowHeaderWidth,
            frozen: true,
            headerRenderer: <LeftAlignHeaderRenderer/>,
            _isForecast: false
        }
    ].concat(
        financialRecords.map(record => {
            return {
                key: record.period_end_date,
                name: formatFullDate(record.period_end_date),
                width: cellWidth,
                _isForecast: record.is_forecast,
                _isPublicPeriod: record.is_public_period,
                headerRenderer: <HeaderContentRenderer/>
            }

        })
    );

};


export const getRevenueCompositionAndTotalRows = ({financialRecords, columnHeaderFieldName}) => {

    const revenueCompositionFields = getNonNullCompositionFieldNames(financialRecords, RevenueCompositionMappingByDbField);
    const revenueCompositionRows = revenueCompositionFields.map(dbFieldName => {
        return createRow({
            headerText: RevenueCompositionMappingByDbField[dbFieldName],
            indent: 1,
            records: financialRecords,
            getCellValue: record => record[dbFieldName],
            getCellClassName: getClassToColorRevenueComposition,
            formatValue: formatNoDecimal,
            columnHeaderFieldName
        });
    });

    const revenueTotalRow = createRow({
        headerText: 'Revenue',
        records: financialRecords,
        getCellValue: record => record.revenue_total,
        underline: true,
        getCellClassName: getClassToColorRevenue,
        columnHeaderFieldName
    });

    return [

        createHeaderRow({headerText: 'Revenue'}),
        ...revenueCompositionRows,
        revenueTotalRow,
    ]

};


export const getGrossProfitAndExpenseRows = ({records, costDetailType}) => {

    const grossProfitCompositionFields = getNonNullCompositionFieldNames(records, GrossProfitCompositionMappingByDbField);

    let rows = [];

    if (hasGrossProfit(costDetailType)) {

        const grossProfitCompositionRows = grossProfitCompositionFields.map(dbFieldName => {
            return createRow({
                headerText: GrossProfitCompositionMappingByDbField[dbFieldName],
                indent: 1,
                records: records,
                getCellValue: record => record[dbFieldName],
                getCellClassName: getClassToColorGrossProfit,
                formatValue: formatNoDecimal
            });

        });


        const grossProfitTotalRow = createRow({
            headerText: 'Gross Profit',
            records: records,
            getCellValue: record => record.gross_profit_total,
            getCellClassName: getClassToColorGrossProfit,
            underline: true
        });

        rows.push(
            createHeaderRow({headerText: 'Gross Profit'})
        );
        rows = rows.concat(grossProfitCompositionRows);
        rows.push(grossProfitTotalRow);


    }

    if (costDetailType === 'Functional') {

        const opexRows = functionalOpexSpecs.map(spec => {

            return createRow({
                records: records,
                headerText: spec.displayName,
                indent: 1,
                getCellValue: record => record[spec.field],
                getCellClassName: getClassToColorOpex
            });

        });
        rows = rows.concat(opexRows);
    }

    if (costDetailType === 'SG&A') {
        const opexRows = sgaSpecs.map(spec => {
            return createRow({
                records: records,
                headerText: spec.displayName,
                indent: 1,
                getCellValue: record => record[spec.field],
                getCellClassName: getClassToColorOpex
            });

        });
        rows = rows.concat(opexRows);
    }

    if (costDetailType === 'Operating Costs') {
        rows.push(
            createRow({
                records: records,
                headerText: 'Operating Costs',
                indent: 1,
                getCellValue: record => record.total_operating_expenses,
                getCellClassName: getClassToColorOpex
            })
        )
    }

    if (costDetailType === 'Total Costs') {
        rows.push(
            createRow({
                records: records,
                headerText: 'Total Costs',
                indent: 1,
                getCellValue: record => record.total_costs,
            })
        )
    }

    return rows

};


export const getTotalImputedArrBridge = ({financialRecords, columnHeaderFieldName}) => {

    return [
        createSectionHeaderRow({headerText: 'ARR'}),
        emptyRow(),
        createHeaderRow({headerText: 'Imputed ARR Bridge'}),
        createRow({
            headerText: 'Beginning ARR',
            records: financialRecords,
            getCellValue: record => record.arr_beginning,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName

        }),
        createRow({
            headerText: 'Implemented ARR',
            records: financialRecords,
            getCellValue: record => record.arr_implemented,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Acquired ARR',
            records: financialRecords,
            getCellValue: record => record.arr_acquired,
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Gross Churn',
            records: financialRecords,
            getCellValue: record => record.arr_gross_churn_dollars,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Ending ARR',
            records: financialRecords,
            getCellValue: record => record.arr_ending,
            formatValue: formatNoDecimalNoNullOrZero,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Gross Retention %',
            records: financialRecords,
            getCellValue: record => record.arr_gross_retention,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Implemented. ARR $ Growth',
            records: financialRecords,
            getCellValue: record => record.arr_implemented_dollar_growth,
            formatValue: formatNoDecimal,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Organic YoY Growth',
            records: financialRecords,
            getCellValue: record => record.arr_organic_growth,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Valid ARR Bridge?',
            records: financialRecords,
            getCellValue: record => record.arr_bridge_is_valid,
            formatValue: formatBooleanYesNo,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Period Should have Acquired ARR?',
            records: financialRecords,
            getCellValue: record => record.arr_is_acquisition_period,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetEmphasizeYesBoolean({field: 'arr_is_acquisition_period'}),
            columnHeaderFieldName
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Bookings Detail'}),
        createRow({
            headerText: 'Implemented ARR',
            records: financialRecords,
            getCellValue: record => record.arr_implemented,
            indent: true,
            columnHeaderFieldName
        }),

        createRow({
            headerText: 'Perp License ARR Equiv.',
            records: financialRecords,
            getCellValue: record => record.perpetual_license_arr_equivalent_bookings,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Hardware ARR Equiv.',
            records: financialRecords,
            getCellValue: record => record.hardware_arr_equivalent_bookings,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Total ARR Equiv. Bookings',
            records: financialRecords,
            getCellValue: record => record.arr_total_equivalent_bookings,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            underline: true
        }),
        createRow({
            headerText: 'Actual ARR: Total Equiv. Bookings',
            records: financialRecords,
            getCellValue: record => record.actual_arr_total_equivalent_bookings,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        emptyRow(),

        createRow({
            headerText: 'LTM Impl. ARR / S&M',
            records: financialRecords,
            getCellValue: record => record.arr_ltm_total_equiv_bookings_to_sales_and_marketing,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Actual ARR: LTM ARR-Equiv. / S&M',
            records: financialRecords,
            getCellValue: record => record.actual_arr_ltm_total_equiv_bookings_to_sales_and_marketing,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName
        }),
    ]


};


export const getActualArrBridge = ({financialRecords, columnHeaderFieldName}) => {
    return [
        createHeaderRow({headerText: 'Actual ARR Bridge'}),
        createRow({
            headerText: 'Beginning ARR',
            records: financialRecords,
            getCellValue: record => record.actual_arr_beginning,
            formatValue: formatNoDecimalNoNullOrZero,
            columnHeaderFieldName

        }),
        createRow({
            headerText: 'Bookings',
            records: financialRecords,
            getCellValue: record => record.actual_arr_bookings,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Acquired',
            records: financialRecords,
            getCellValue: record => record.actual_arr_acquired,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Gross Churn',
            records: financialRecords,
            getCellValue: record => record.actual_arr_gross_churn_dollars,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Ending ARR',
            records: financialRecords,
            getCellValue: record => record.actual_arr_ending,
            formatValue: formatNoDecimalNoNullOrZero,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Gross Retention %',
            records: financialRecords,
            getCellValue: record => record.actual_arr_gross_retention,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Bookings $ Growth',
            records: financialRecords,
            getCellValue: record => record.actual_arr_bookings_dollar_growth,
            formatValue: formatNoDecimal,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Organic YoY Growth',
            records: financialRecords,
            getCellValue: record => record.actual_arr_organic_growth,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Valid ARR Bridge?',
            records: financialRecords,
            getCellValue: record => record.actual_arr_bridge_is_valid,
            formatValue: formatBooleanYesNo,
            columnHeaderFieldName
        }),

    ]
}


export const getArrCompositionBridges = ({financialRecords, columnHeaderFieldName}) => {

    const arrSpecs = getNonNullArrSpecs({financialRecords: financialRecords, specs: ArrCompositionSpecs});

    const groups = arrSpecs.map(spec => {
        return [
            createHeaderRow({headerText: 'Imputed ARR: ' + spec.revenueType}),
            createRow({
                headerText: 'Beginning ARR',
                records: financialRecords,
                getCellValue: record => record['arr_beginning_' + spec.dbRevenueType],
                formatValue: formatNoDecimalNoNull,
                columnHeaderFieldName

            }),
            createRow({
                headerText: 'Implemented ARR',
                records: financialRecords,
                getCellValue: record => record['arr_implemented_' + spec.dbRevenueType],
                indent: true,
                formatValue: formatNoDecimalNoNull,
                columnHeaderFieldName
            }),
            createRow({
                headerText: 'Acquired ARR',
                records: financialRecords,
                getCellValue: record => record['arr_acquired_' + spec.dbRevenueType],
                formatValue: formatNoDecimalNoNull,
                indent: true,
                columnHeaderFieldName
            }),
            createRow({
                headerText: 'Gross Churn',
                records: financialRecords,
                getCellValue: record => record['arr_gross_churn_dollars_' + spec.dbRevenueType],
                indent: true,
                formatValue: formatNoDecimalNoNull,
                columnHeaderFieldName
            }),
            createRow({
                headerText: 'Ending ARR',
                records: financialRecords,
                getCellValue: record => record['arr_ending_' + spec.dbRevenueType],
                formatValue: formatNoDecimalNoNull,
                underline: true,
                columnHeaderFieldName
            }),
            createRow({
                headerText: 'Gross Retention %',
                records: financialRecords,
                getCellValue: record => record['arr_gross_retention_' + spec.dbRevenueType],
                formatValue: formatPercentOneDecimalNoNull,
                columnHeaderFieldName
            }),
            createRow({
                headerText: 'Implm. ARR $ Growth',
                records: financialRecords,
                getCellValue: record => record['arr_implemented_dollar_growth_' + spec.dbRevenueType],
                formatValue: formatNoDecimal,
                columnHeaderFieldName
            }),
            createRow({
                headerText: 'Organic YoY Growth',
                records: financialRecords,
                getCellValue: record => record['arr_organic_growth_' + spec.dbRevenueType],
                formatValue: formatPercentOneDecimalNoNull,
                columnHeaderFieldName
            }),
            createRow({
                headerText: 'Is Valid?',
                records: financialRecords,
                getCellValue: record => record['arr_bridge_is_valid_' + spec.dbRevenueType],
                formatValue: formatBooleanYesNo,
                columnHeaderFieldName
            }),
            emptyRow()
        ]
    });

    return [
        createSectionHeaderRow({headerText: 'ARR by Revenue Type'}),
        emptyRow(),
        ...groups.flat()
    ]

};


export const getRevenueCompositionAndTotalGrowthRows = ({financialRecords}) => {

    const revenueSpecs = getNonNullSpecs({financialRecords, specs: RevenueTypeSpecs, fieldPrefix: 'revenue'});

    const revenueCompositionRows = revenueSpecs.map(spec => {
        return createRow({
            headerText: spec.revenueType,
            indent: 1,
            records: financialRecords,
            getCellValue: record => record['revenue_growth_' + spec.dbRevenueType],
            getCellClassName: getClassToColorRevenueComposition,
            formatValue: formatPercentOneDecimalNoNull
        });
    });

    const revenueTotalRow = createRow({
        headerText: 'Revenue',
        records: financialRecords,
        getCellValue: record => record.revenue_growth,
        underline: true,
        getCellClassName: getClassToColorRevenue,
        formatValue: formatPercentOneDecimalNoNull
    });

    return [
        createHeaderRow({headerText: 'Revenue Growth'}),
        ...revenueCompositionRows,
        revenueTotalRow,
    ]

};


export const getRevenueCompositionAndTotalDollarGrowthRows = ({financialRecords}) => {

    const revenueSpecs = getNonNullSpecs({financialRecords, specs: RevenueTypeSpecs, fieldPrefix: 'revenue'});

    const revenueCompositionRows = revenueSpecs.map(spec => {
        return createRow({
            headerText: spec.revenueType,
            indent: 1,
            records: financialRecords,
            getCellValue: record => record['revenue_dollar_growth_' + spec.dbRevenueType],
            getCellClassName: getClassToColorRevenueComposition,
            formatValue: formatNoDecimalNoNullOrZero
        });
    });

    const revenueTotalRow = createRow({
        headerText: 'Revenue',
        records: financialRecords,
        getCellValue: record => record.revenue_dollar_growth,
        underline: true,
        getCellClassName: getClassToColorRevenue,
        formatValue: formatNoDecimalNoNullOrZero
    });

    return [
        createHeaderRow({headerText: 'Revenue $ Growth'}),
        ...revenueCompositionRows,
        revenueTotalRow,
    ]

};


export const getGrossMarginRows = ({financialRecords, columnHeaderFieldName}) => {

    const revenueTypeSpecs = getNonNullSpecs({financialRecords, specs: RevenueTypeSpecs, fieldPrefix: 'gross_profit'});

    if (revenueTypeSpecs.length > 0) {

        const compositionRows = revenueTypeSpecs.map(spec => {
            return createRow({
                headerText: spec.revenueType,
                indent: 1,
                records: financialRecords,
                getCellValue: record => record['gross_margin_' + spec.dbRevenueType],
                getCellClassName: getClassToColorRevenueComposition,
                formatValue: formatPercentOneDecimalNoNull,
                columnHeaderFieldName
            });
        });

        return [
            createHeaderRow({headerText: 'Gross Margin'}),
            ...compositionRows,
            createRow({
                headerText: 'Total',
                records: financialRecords,
                getCellValue: record => record.gross_margin,
                underline: true,
                getCellClassName: getClassToColorRevenue,
                formatValue: formatPercentOneDecimalNoNull,
                columnHeaderFieldName
            }),
            emptyRow()
        ]


    }

    return []

};


export const getMarginRows = ({financialRecords, columnHeaderFieldName}) => {

    return [
        createHeaderRow({headerText: 'Margins'}),
        createRow({
            headerText: 'Gross Margin',
            records: financialRecords,
            getCellValue: record => record.gross_margin,
            getCellClassName: getClassToColorRevenue,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        ...functionalOpexSpecs.map(spec => {
            return createRow({
                records: financialRecords,
                headerText: spec.displayName,
                indent: 1,
                getCellValue: record => record[spec.field + "_margin"],
                formatValue: formatPercentOneDecimalNoNull,
                columnHeaderFieldName
            });
        }),
        createRow({
            headerText: 'EBITDA',
            records: financialRecords,
            getCellValue: record => record.ebitda_margin,
            formatValue: formatPercentOneDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Depreciation',
            records: financialRecords,
            getCellValue: record => record.depreciation_percent_of_sales,
            indent: 1,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'EBIT',
            records: financialRecords,
            getCellValue: record => record.ebit_margin,
            underline: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
        createRow({
            headerText: 'EBITDA',
            records: financialRecords,
            getCellValue: record => record.ebitda_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Capital Expenditures',
            records: financialRecords,
            getCellValue: record => record.capital_expenditures_percent_of_sales,
            formatValue: formatPercentOneDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Pretax FCF',
            records: financialRecords,
            getCellValue: record => record.pretax_free_cash_flow_percent_of_sales,
            formatValue: formatPercentOneDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Change in Deferred Rev.',
            records: financialRecords,
            getCellValue: record => record.change_in_deferred_revenue_percent_of_sales,
            formatValue: formatPercentOneDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Pretax FCF on Billings',
            records: financialRecords,
            getCellValue: record => record.pretax_free_cash_flow_on_billings_percent_of_sales,
            formatValue: formatPercentOneDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),
    ]

};


export const getSalesAndMarketingDetail = ({financialRecords}) => {
    return [
        createHeaderRow({headerText: 'Sales and Marketing'}),
        createRow({
            headerText: 'S&M Expensed',
            records: financialRecords,
            getCellValue: record => record.sales_and_marketing_before_cap,
            getCellClassName: getClassToColorEBITDA,
            indent: true
        }),
        createRow({
            headerText: 'Net Capitalized S&M',
            records: financialRecords,
            getCellValue: record => record.net_capitalized_sales_commissions,
            getCellClassName: getClassToColorEBITDA,
            indent: true
        }),
        createRow({
            headerText: 'Total S&M',
            records: financialRecords,
            getCellValue: record => record.sales_and_marketing,
            getCellClassName: getClassToColorEBITDA,
            underline: true
        }),
    ]

};


export const get605ReconciliationRows = ({financialRecords}) => {
    return [
        createHeaderRow({headerText: 'ASC 605 Revenue Override'}),
        createRow({
            headerText: 'Is 605 Revenue Override?',
            records: financialRecords,
            getCellValue: record => record.is_asc_605_override,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetEmphasizeYesBoolean({
                field: 'is_asc_605_override',
            }),
        }),
        createRow({
            headerText: 'ASC 606 Revenue (CIQ)',
            records: financialRecords,
            getCellValue: record => record.is_asc_605_override ? record.asc_606_revenue : null,
            formatValue: formatNoDecimalNoNull
        }),
        createRow({
            headerText: '+ 606 Chng. Deferred Rev',
            records: financialRecords,
            getCellValue: record => record.is_asc_605_override ? record.asc_606_change_in_deferred_revenue : null,
            formatValue: formatNoDecimalNoNull,
            indent: true
        }),
        createRow({
            headerText: '+ 606 Chng. Contract Assets',
            records: financialRecords,
            getCellValue: record => record.is_asc_605_override ? record.asc_606_change_in_contract_assets : null,
            formatValue: formatNoDecimalNoNull,
            indent: true
        }),
        createRow({
            headerText: '606 "Billings"',
            records: financialRecords,
            getCellValue: record => record.is_asc_605_override ? record.billings : null,
            formatValue: formatNoDecimalNoNull,
            underline: true
        }),
        createRow({
            headerText: '- 605 Revenue',
            records: financialRecords,
            getCellValue: record => record.is_asc_605_override ? record.revenue_total : null,
            formatValue: formatNoDecimalNoNull,
            indent: true
        }),
        createRow({
            headerText: '605 Chng Deferred Rev',
            records: financialRecords,
            getCellValue: record => record.is_asc_605_override ? record.change_in_deferred_revenue : null,
            formatValue: formatNoDecimalNoNull,
            underline: true
        }),


    ]
}


export const getEBITRows = ({financialRecords}) => {
    return [
        createRow({
            headerText: 'EBITDA',
            records: financialRecords,
            getCellValue: record => record.ebitda,
            getCellClassName: getClassToColorEBITDA,
            underline: true
        }),
        createRow({
            headerText: 'Depreciation',
            records: financialRecords,
            getCellValue: record => record.depreciation,
            indent: 1
        }),
        createRow({
            headerText: 'EBIT',
            records: financialRecords,
            getCellValue: record => record.ebit,
            getCellClassName: getClassToColorCapex,
            underline: true
        })

    ]

};


export const getPretaxFCFRows = ({financialRecords}) => {
    return [
        createHeaderRow({headerText: 'Pretax FCF'}),
        createRow({
            headerText: 'EBITDA',
            records: financialRecords,
            getCellValue: record => record.ebitda,
            getCellClassName: getClassToColorEBITDA,
            underline: false
        }),
        createRow({
            headerText: 'Capital Expenditures',
            records: financialRecords,
            getCellValue: record => record.capital_expenditures,
            getCellClassName: getClassToColorCapex,
            indent: 1
        }),
        createRow({
            headerText: 'Pretax FCF',
            records: financialRecords,
            getCellValue: record => record.pretax_free_cash_flow,
            underline: true
        }),

        createRow({
            headerText: 'Change in Deferred Rev.',
            records: financialRecords,
            getCellClassName: getClassToColorChangeInDeferred,
            getCellValue: record => record.change_in_deferred_revenue,
            indent: 1
        }),
        createRow({
            headerText: 'Pretax FCF on Billings',
            records: financialRecords,
            getCellValue: record => record.pretax_free_cash_flow_on_billings,
            underline: true
        }),
        emptyRow(),
        createRow({
            headerText: 'Change in ST. Deferred Rev.',
            records: financialRecords,
            getCellClassName: getClassToColorChangeInDeferred,
            getCellValue: record => record.change_in_short_term_deferred_revenue,
            indent: 1,
            italic: true
        }),
        createRow({
            headerText: 'Change in LT. Deferred Rev.',
            records: financialRecords,
            getCellClassName: getClassToColorChangeInDeferred,
            getCellValue: record => record.change_in_long_term_deferred_revenue,
            indent: 1,
            italic: true
        }),


    ]
};


export const getCashFlowRows = ({financialRecords}) => {

    return [
        createHeaderRow({headerText: 'Cash Flow'}),
        createRow({
            headerText: 'EBITDA',
            records: financialRecords,
            getCellValue: record => record.ebitda,
            getCellClassName: getClassToColorEBITDA,
        }),
        createRow({
            headerText: 'Capital Expenditures',
            records: financialRecords,
            getCellValue: record => record.capital_expenditures ? -record.capital_expenditures : null,
            indent: true
        }),
        createRow({
            headerText: 'Interest Expense',
            records: financialRecords,
            getCellValue: record => record.interest_expense != null ? -record.interest_expense : null,
            indent: true
        }),
        createRow({
            headerText: 'Cash Taxes',
            records: financialRecords,
            getCellValue: record => record.cash_tax_expense != null ? -record.cash_tax_expense : null,
            indent: true
        }),
        createRow({
            headerText: 'Change in Deferred Revenue',
            records: financialRecords,
            getCellValue: record => record.change_in_deferred_revenue,
            indent: true
        }),
        createRow({
            headerText: 'Levered After-Tax FCF',
            records: financialRecords,
            getCellValue: record => record.levered_after_tax_free_cash_flow,
            underline: true
        }),
    ]

};


export const getExecutionAssessmentRows = ({financialRecords, columnHeaderFieldName}) => {

    return [
        createSectionHeaderRow({headerText: 'Execution Assessment'}),
        emptyRow(),
        createHeaderRow({headerText: 'Execution Score'}),
        createRow({
            headerText: 'LTM Relevant Organic Growth',
            records: financialRecords,
            getCellValue: record => record.ltm_relevant_organic_growth,
            indent: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: '(-) Normalized Dilution',
            records: financialRecords,
            getCellValue: record => record.equiv_shares_normalized_net_granted_percent,
            indent: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'LTM EBIT Margin',
            records: financialRecords,
            getCellValue: record => record.ltm_reported_ebit_margin,
            indent: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'LTM Margin + Dil. Growth',
            records: financialRecords,
            getCellValue: record => record.ltm_reported_ebit_margin_plus_dil_org_growth,
            formatValue: formatPercentOneDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Excess Dilution Penalty',
            records: financialRecords,
            getCellValue: record => record.excess_dilution_penalty,
            formatValue: formatPercentOneDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'LTM % ST. Deferred Rev.',
            records: financialRecords,
            getCellValue: record => record.ltm_reported_change_st_deferred_rev_margin,
            formatValue: formatPercentOneDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Override Effect',
            records: financialRecords,
            getCellValue: record => record.execution_score_override_effect,
            formatValue: formatPercentOneDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Execution Score',
            records: financialRecords,
            getCellValue: record => record.execution_score,
            formatValue: formatPercentOneDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),
        emptyRow(),
        createRow({
            headerText: 'LTM Growth Source',
            records: financialRecords,
            getCellValue: record => record.ltm_relevant_organic_growth_source,
            formatValue: formatString,
            columnHeaderFieldName
        }),
        emptyRow(),

        createHeaderRow({headerText: 'Max Margin Drivers'}),
        createRow({
            headerText: 'Normalized Gross Retention',
            records: financialRecords,
            getCellValue: record => record.normalized_gross_retention,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),

        createRow({
            headerText: '5-yr avg Bookings/S&M',
            records: financialRecords,
            getCellValue: record => record.average_trailing_implemented_arr_to_sm,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Years in S&M Avg.',
            records: financialRecords,
            getCellValue: record => record.years_in_trailing_implemented_arr_to_sm,
            formatValue: formatTwoDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Current Max Margin'}),
        createRow({
            headerText: 'LTM Gross Margin',
            records: financialRecords,
            getCellValue: record => record.ltm_gross_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: '(-) Min OPEX',
            records: financialRecords,
            getCellValue: record => record.max_margin_min_opex_percent_of_sales,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: '(-) S&M/Churn Modifier',
            records: financialRecords,
            getCellValue: record => record.max_margin_churn_sales_efficiency_modifier,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Maximized EBITDA',
            records: financialRecords,
            getCellValue: record => record.maximized_ebitda_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            underline: true
        }),
        createRow({
            headerText: '(-) LTM Depreciation',
            records: financialRecords,
            getCellValue: record => record.ltm_depreciation_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Max Pretax FCF',
            records: financialRecords,
            getCellValue: record => record.maximized_pretax_fcf_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            underline: true
        }),
        emptyRow(),
        createRow({
            headerText: 'Execution Discount',
            records: financialRecords,
            getCellValue: record => record.execution_valuation_discount,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),


    ]

};


export const getTaxableIncomeRows = ({financialRecords, columnHeaderFieldName}) => {
    return [
        createSectionHeaderRow({headerText: 'Taxes'}),
        emptyRow(),
        createHeaderRow({headerText: 'Taxable Income'}),
        createRow({
            headerText: 'EBITDA less capex/interest',
            records: financialRecords,
            getCellValue: record => record.ebitda - record.capital_expenditures - record.interest_expense,
            indent: false,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Stock Comp Deduction',
            records: financialRecords,
            getCellValue: record => record.taxable_stock_comp_expense,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Taxable Income',
            records: financialRecords,
            getCellValue: record => record.taxable_income,
            underline: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Cash Taxes',
            records: financialRecords,
            getCellValue: record => record.cash_tax_expense,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
        createHeaderRow({headerText: 'US NOL'}),
        createRow({
            headerText: 'Beginning',
            records: financialRecords,
            getCellValue: record => record.us_federal_nol_beginning,
            indent: false,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net Change',
            records: financialRecords,
            getCellValue: record => record.us_federal_nol_net_change,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Ending',
            records: financialRecords,
            getCellValue: record => record.us_federal_nol_ending,
            underline: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),

    ]
}


export const getChangeInCashRows = ({financialRecords, columnHeaderFieldName}) => {

    return [
        createSectionHeaderRow({headerText: 'Change in Cash'}),
        emptyRow(),
        createHeaderRow({headerText: 'Change in Cash (Forecast-Only)'}),
        createRow({
            headerText: 'Beginning Cash',
            records: financialRecords,
            getCellValue: record => record.beginning_cash_and_short_term_investments,
            indent: false,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Free Cash Flow',
            records: financialRecords,
            getCellValue: record => record.levered_after_tax_free_cash_flow,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net Increase in Debt',
            records: financialRecords,
            getCellValue: record => record.net_cash_from_change_in_debt,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Equity Issuance',
            records: financialRecords,
            getCellValue: record => record.cash_from_equity_issuance,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Share Repurchase',
            records: financialRecords,
            getCellValue: record => record.cash_from_share_repurchase,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Dividends',
            records: financialRecords,
            getCellValue: record => record.cash_from_dividends,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Acq. / Divestitures',
            records: financialRecords,
            getCellValue: record => record.net_cash_from_acquisitions,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Other (IPO adj)',
            records: financialRecords,
            getCellValue: record => record.net_cash_from_other,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Ending Cash',
            records: financialRecords,
            getCellValue: record => record.cash_and_short_term_investments,
            underline: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
        createRow({
            headerText: 'Share Repurchase: $/share filing',
            records: financialRecords,
            getCellValue: record => record.share_repurchase_price_filing_currency,
            underline: false,
            formatValue: formatSharePrice,
            columnHeaderFieldName
        }),
    ]

};


export const getBalanceSheetRows = ({financialRecords, columnHeaderFieldName}) => {

    return [
        createSectionHeaderRow({headerText: 'Balance Sheet'}),
        emptyRow(),
        createRow({
            headerText: 'Balance Sheet Valid?',
            records: financialRecords,
            getCellValue: record => record.balance_sheet_is_valid,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetClassToColorBoolean({field: 'balance_sheet_is_valid'}),
            columnHeaderFieldName
        }),
        emptyRow(),
        createRow({
            headerText: 'Cash',
            records: financialRecords,
            getCellValue: record => record.cash_and_short_term_investments,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Long Term Investments',
            records: financialRecords,
            getCellValue: record => record.long_term_investments,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Preferred Stock',
            records: financialRecords,
            getCellValue: record => record.preferred_stock,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Pension',
            records: financialRecords,
            getCellValue: record => record.pension_liability,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Minority Interest',
            records: financialRecords,
            getCellValue: record => record.minority_interest,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Debt - Face'}),
        createRow({
            headerText: 'Convertible Debt',
            records: financialRecords,
            getCellValue: record => record.convertible_debt,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Non-Convertible Debt',
            records: financialRecords,
            getCellValue: record => record.non_convertible_debt,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Total Debt',
            records: financialRecords,
            getCellValue: record => record.total_debt,
            underline: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Total Debt - Book',
            records: financialRecords,
            getCellValue: record => record.total_debt_book,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Debt Valid (has face)?',
            records: financialRecords,
            getCellValue: record => record.debt_is_valid,
            indent: true,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetClassToColorBoolean({field: 'debt_is_valid'}),
            columnHeaderFieldName
        }),

        emptyRow(),

        createRow({
            headerText: 'Source: Cash',
            records: financialRecords,
            getCellValue: record => record.cash_and_short_term_investments_source,
            formatValue: abbreviateSource
        }),
        createRow({
            headerText: 'Source: LT Investments',
            records: financialRecords,
            getCellValue: record => record.long_term_investments_source,
            formatValue: abbreviateSource
        }),
        createRow({
            headerText: 'Source: Preferred',
            records: financialRecords,
            getCellValue: record => record.preferred_stock_source,
            formatValue: abbreviateSource
        }),
        createRow({
            headerText: 'Source: Pension',
            records: financialRecords,
            getCellValue: record => record.pension_liability_source,
            formatValue: abbreviateSource
        }),
        createRow({
            headerText: 'Source: Minority Interest',
            records: financialRecords,
            getCellValue: record => record.minority_interest_source,
            formatValue: abbreviateSource
        }),
        createRow({
            headerText: 'Source: Debt',
            records: financialRecords,
            getCellValue: record => record.total_debt_source,
            formatValue: abbreviateSource
        }),

    ]
};


export const getShareComponents = ({financialRecords}) => {

    return [
        createSectionHeaderRow({headerText: 'Shares and Dilution'}),
        emptyRow(),
        createRow({
            headerText: 'Is Public Period?',
            records: financialRecords,
            getCellValue: record => record.is_public_period,
            indent: false,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetEmphasizeYesBoolean({
                field: 'is_public_period',
                colorOverride: 'black'
            }),
        }),
        createRow({
            headerText: 'Valid Dilution Bridge?',
            records: financialRecords,
            getCellValue: record => record.dilution_valid_bridge,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetClassToColorBoolean({field: 'dilution_valid_bridge'}),
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Basic Share Bridge'}),
        createRow({
            headerText: 'Beginning',
            records: financialRecords,
            getCellValue: record => record.shares_beginning,
            indent: false,
            formatValue: formatNoDecimalNoNull
        }),
        createRow({
            headerText: 'RSUs Vested',
            records: financialRecords,
            getCellValue: record => record.shares_rsu_vesting,
            indent: true
        }),
        createRow({
            headerText: 'Restricted Stock Grants',
            records: financialRecords,
            getCellValue: record => record.shares_rs_net_grant,
            indent: true
        }),
        createRow({
            headerText: 'Option Exercises',
            records: financialRecords,
            getCellValue: record => record.shares_option_exercise,
            indent: true
        }),
        createRow({
            headerText: 'Repurchases',
            records: financialRecords,
            getCellValue: record => record.shares_repurchase,
            indent: true
        }),
        createRow({
            headerText: 'Equity Issuance',
            records: financialRecords,
            getCellValue: record => record.shares_issuance,
            indent: true
        }),
        createRow({
            headerText: 'Issued for Acq.',
            records: financialRecords,
            getCellValue: record => record.shares_issued_for_acquisition,
            indent: true
        }),
        createRow({
            headerText: 'Other',
            records: financialRecords,
            getCellValue: record => record.shares_other,
            indent: true,
            formatValue: formatNoDecimalNoNull
        }),
        createRow({
            headerText: 'Ending',
            records: financialRecords,
            getCellValue: record => record.shares_ending,
            indent: false,
            underline: true,
            formatValue: formatNoDecimalNoNull
        }),
        createRow({
            headerText: 'Valid Bridge?',
            records: financialRecords,
            getCellValue: record => record.shares_valid_bridge,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetClassToColorBoolean({
                field: 'shares_valid_bridge',
                deEmphasizePrePublic: true
            }),
            indent: true
        }),
        createRow({
            headerText: 'Source',
            records: financialRecords,
            getCellValue: record => record.shares_ending_source,
            indent: true,
            underline: false,
            getCellClassName: (record) => colorBySourceType[record.shares_ending_source],
            formatValue: abbreviateSource
        }),

        emptyRow(),
        createHeaderRow({headerText: 'RSU Bridge'}),
        createRow({
            headerText: 'Beginning',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_beginning,
            indent: false
        }),
        createRow({
            headerText: 'Granted',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_granted,
            indent: true
        }),
        createRow({
            headerText: 'Vested',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_vested,
            indent: true
        }),
        createRow({
            headerText: 'Forfeited',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_forfeited,
            indent: true
        }),
        createRow({
            headerText: 'Issued for Acquisition',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_issued_for_acquisition,
            indent: true
        }),
        createRow({
            headerText: 'Performance Adj.',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_performance_adjustment,
            indent: true
        }),
        createRow({
            headerText: 'Other',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_other,
            indent: true
        }),
        createRow({
            headerText: 'Ending',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_ending,
            indent: false,
            underline: true
        }),
        createRow({
            headerText: 'Valid Bridge?',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_valid_bridge,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetClassToColorBoolean({
                field: 'unvested_rsus_valid_bridge',
                deEmphasizePrePublic: true
            }),
            indent: true
        }),
        createRow({
            headerText: 'Source',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_ending_source,
            indent: true,
            underline: false,
            getCellClassName: getClassToColorDilution,
            formatValue: abbreviateSource
        }),

        emptyRow(),
        createHeaderRow({headerText: 'Restricted Stock Bridge'}),
        createRow({
            headerText: 'Beginning',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_beginning,
            indent: false
        }),
        createRow({
            headerText: 'Granted',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_granted,
            indent: true
        }),
        createRow({
            headerText: 'Vested',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_vested,
            indent: true
        }),
        createRow({
            headerText: 'Forfeited',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_forfeited,
            indent: true
        }),
        createRow({
            headerText: 'Issued for Acquisition',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_issued_for_acquisition,
            indent: true
        }),
        createRow({
            headerText: 'Performance Adj.',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_performance_adjustment,
            indent: true
        }),
        createRow({
            headerText: 'Other',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_other,
            indent: true
        }),
        createRow({
            headerText: 'Ending',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_ending,
            indent: false,
            underline: true
        }),
        createRow({
            headerText: 'Valid Bridge?',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_valid_bridge,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetClassToColorBoolean({
                field: 'unvested_rs_valid_bridge',
                deEmphasizePrePublic: true
            }),
            indent: true
        }),
        createRow({
            headerText: 'Source',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_ending_source,
            indent: true,
            underline: false,
            getCellClassName: getClassToColorDilution,
            formatValue: abbreviateSource
        }),

        emptyRow(),
        createHeaderRow({headerText: 'Option Bridge'}),
        createRow({
            headerText: 'Beginning',
            records: financialRecords,
            getCellValue: record => record.options_beginning,
            indent: false
        }),
        createRow({
            headerText: 'Awards',
            records: financialRecords,
            getCellValue: record => record.options_awards,
            indent: true
        }),
        createRow({
            headerText: 'Exercised',
            records: financialRecords,
            getCellValue: record => record.options_exercised,
            indent: true
        }),
        createRow({
            headerText: 'Forfeited/Expired',
            records: financialRecords,
            getCellValue: record => record.options_forfeited_or_expired,
            indent: true
        }),
        createRow({
            headerText: 'Issued for Acquisition',
            records: financialRecords,
            getCellValue: record => record.options_issued_for_acquisition,
            indent: true
        }),
        createRow({
            headerText: 'Performance Adj.',
            records: financialRecords,
            getCellValue: record => record.options_performance_adjustment,
            indent: true
        }),
        createRow({
            headerText: 'Other',
            records: financialRecords,
            getCellValue: record => record.options_other,
            indent: true
        }),
        createRow({
            headerText: 'Ending',
            records: financialRecords,
            getCellValue: record => record.options_ending,
            indent: false,
            underline: true
        }),
        createRow({
            headerText: 'Valid Bridge?',
            records: financialRecords,
            getCellValue: record => record.options_valid_bridge,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetClassToColorBoolean({
                field: 'options_valid_bridge',
                deEmphasizePrePublic: true
            }),
            indent: true
        }),
        createRow({
            headerText: 'Source',
            records: financialRecords,
            getCellValue: record => record.options_ending_source,
            indent: true,
            underline: false,
            getCellClassName: getClassToColorDilution,
            formatValue: abbreviateSource
        }),
        createRow({
            headerText: 'Option Tiers Source',
            records: financialRecords,
            getCellValue: record => record.pce_option_pool_tiers_source,
            indent: true,
            underline: false,
            getCellClassName: getClassToColorDilution,
            formatValue: abbreviateSource
        }),
        createRow({
            headerText: 'Options Match Tier Total?',
            records: financialRecords,
            getCellValue: record => record.options_ending_matches_tier_count,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetClassToColorBoolean({field: 'options_ending_matches_tier_count'}),
            indent: true
        }),

    ]

};


export const getDilutionAnalysisRows = ({financialRecords}) => {

    return [
        createSectionHeaderRow({headerText: 'Dilution Analysis'}),
        emptyRow(),
        createRow({
            headerText: 'Is Public Period?',
            records: financialRecords,
            getCellValue: record => record.is_public_period,
            indent: false,
            formatValue: formatBooleanYesNo,
            getCellClassName: makeGetEmphasizeYesBoolean({
                field: 'is_public_period',
                colorOverride: 'black'
            }),
        }),
        emptyRow(),
        createHeaderRow({headerText: 'RSU-equiv. Grant Dilution'}),
        createRow({
            headerText: 'RSU Grants',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_granted,
            indent: true,
            getCellClassName: getClassToColorDilution,
            formatValue: formatNoDecimalNoNull
        }),
        createRow({
            headerText: 'Restricted Stock Grants',
            records: financialRecords,
            getCellValue: record => record.unvested_rs_granted,
            indent: true,
            getCellClassName: getClassToColorDilution,
            formatValue: formatNoDecimalNoNull
        }),
        createRow({
            headerText: 'Options / 3',
            records: financialRecords,
            getCellValue: record => record.options_awards != null ? record.options_awards / 3 : null,
            indent: true,
            getCellClassName: getClassToColorDilution,
            formatValue: formatNoDecimalNoNull
        }),
        createRow({
            headerText: 'Gross Dilution',
            records: financialRecords,
            getCellValue: record => record.equiv_shares_gross_granted,
            indent: false,
            underline: true,
            getCellClassName: getClassToColorDilution,
            formatValue: formatNoDecimalNoNull
        }),
        createRow({
            headerText: 'Actual Forfeiture',
            records: financialRecords,
            getCellValue: record => record.equiv_shares_forfeited,
            indent: true,
            underline: false,
            formatValue: formatNoDecimalNoNull
        }),
        createRow({
            headerText: 'Net Dilution (actual)',
            records: financialRecords,
            getCellValue: record => record.equiv_shares_net_granted,
            indent: false,
            underline: true,
            getCellClassName: getClassToColorDilution,
            formatValue: formatNoDecimalNoNull
        }),

        emptyRow(),

        createRow({
            headerText: 'Gross Dilution %',
            records: financialRecords,
            getCellValue: record => record.equiv_shares_gross_granted_percent,
            getCellClassName: getClassToColorDilution,
            formatValue: formatPercentTwoDecimalNoNull
        }),

        createRow({
            headerText: 'Net Dilution (actual) %',
            records: financialRecords,
            getCellValue: record => record.equiv_shares_net_granted_percent,
            getCellClassName: getClassToColorDilution,
            formatValue: formatPercentTwoDecimalNoNull
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Normalized Dilution'}),
        createRow({
            headerText: 'Normalized Gross Dilution %',
            records: financialRecords,
            getCellValue: record => record.equiv_shares_normalized_gross_granted_percent,
            formatValue: formatPercentTwoDecimalNoNull
        }),
        createRow({
            headerText: 'Normalized Forfeiture %',
            records: financialRecords,
            getCellValue: record => record.equiv_shares_normalized_forfeited_percent,
            formatValue: formatPercentNoNull
        }),
        createRow({
            headerText: 'Normalized Net Dilution %',
            records: financialRecords,
            getCellValue: record => record.equiv_shares_normalized_net_granted_percent,
            formatValue: formatPercentTwoDecimalNoNull
        })
    ]
}


export const getAcquiredRevenueCompositionAndTotalRows = ({financialRecords, columnHeaderFieldName}) => {

    const revenueCompositionFields = getNonNullCompositionFieldNames(financialRecords, RevenueCompositionMappingByDbField);
    const revenueCompositionRows = revenueCompositionFields.map(dbFieldName => {
        return createRow({
            headerText: RevenueCompositionMappingByDbField[dbFieldName],
            indent: 1,
            records: financialRecords,
            getCellValue: record => record['acquired_' + dbFieldName],
            getCellClassName: getClassToColorRevenueComposition,
            formatValue: formatNoDecimal,
            columnHeaderFieldName
        });
    });

    const revenueTotalRow = createRow({
        headerText: 'Revenue',
        records: financialRecords,
        getCellValue: record => record.acquired_revenue_total,
        underline: true,
        getCellClassName: getClassToColorRevenue,
        columnHeaderFieldName
    });

    return [
        createSectionHeaderRow({headerText: 'Acquisition Detail'}),
        emptyRow(),
        createRow({
            headerText: 'Period Should have Acquired ARR?',
            records: financialRecords,
            getCellValue: record => record.arr_is_acquisition_period,
            formatValue: formatBooleanYesNo
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Acquired Revenue'}),
        ...revenueCompositionRows,
        revenueTotalRow,
    ]

};


export const getValuationPremiumDetails = ({financialRecords, columnHeaderFieldName}) => {
    return [
        createHeaderRow({headerText: 'Execution Valuation Premium'}),
        createRow({
            headerText: 'Curr Margin % Exit Max Margin',
            records: financialRecords,
            getCellValue: record => record.current_margin_percent_of_exit_max_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: 'Curr Exec Score % Exit Max Margin',
            records: financialRecords,
            getCellValue: record => record.current_exec_score_percent_of_exit_max_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: 'Curr Exec Score % Exit Exec Score',
            records: financialRecords,
            getCellValue: record => record.current_exec_score_percent_of_exit_exec_score,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: 'Premium Score',
            records: financialRecords,
            getCellValue: record => record.execution_premium_score,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            underline: true
        }),
        createRow({
            headerText: 'Execution Premium',
            records: financialRecords,
            getCellValue: record => record.exit_multiple_execution_premium,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,

        }),
        emptyRow(),
        createHeaderRow({headerText: 'High Growth Premium'}),
        createRow({
            headerText: 'Exit NTM Rev Growth (>15% = premium)',
            records: financialRecords,
            getCellValue: record => record.exit_ntm_revenue_total_growth,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: 'Growth Premium',
            records: financialRecords,
            getCellValue: record => record.exit_multiple_high_growth_at_exit_premium,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true,

        }),
        emptyRow(),
        createHeaderRow({headerText: 'Total Premium'}),
        createRow({
            headerText: 'Total Valuation Premium',
            records: financialRecords,
            getCellValue: record => record.exit_multiple_net_premium,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true,

        }),

    ]
}


export const getCapitalAllocationRows = ({financialRecords, columnHeaderFieldName}) => {

    return [
        createSectionHeaderRow({headerText: 'Capital Allocation'}),
        emptyRow(),
        createRow({
            headerText: 'Cash Flow: Dividends',
            records: financialRecords,
            getCellValue: record => record.cash_from_dividends,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName

        }),
        createRow({
            headerText: 'Cash Flow: Share Repurchase',
            records: financialRecords,
            getCellValue: record => record.cash_from_share_repurchase,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName

        }),
    ]
}


export const getRevenueGrowthRows = ({financialRecords, columnHeaderFieldName}) => {
    return [
        createSectionHeaderRow({headerText: 'Revenue Growth'}),
        emptyRow(),
        ...getRevenueCompositionAndTotalDollarGrowthRows({financialRecords}),
        emptyRow(),
        ...getRevenueCompositionAndTotalGrowthRows({financialRecords}),
        emptyRow(),
        createHeaderRow({headerText: 'Recurring Growth Metrics'}),
        createRow({
            headerText: '% Recurring',
            records: financialRecords,
            getCellValue: record => record.revenue_recurring_percent,
            formatValue: formatPercentNoNull
        }),
        createRow({
            headerText: 'Organic CC Recurr. Rev. Growth',
            records: financialRecords,
            getCellValue: record => record.organic_constant_currency_recurring_revenue_growth,
            formatValue: formatPercentOneDecimalNoNull
        }),
        createRow({
            headerText: 'Recurring Growth',
            records: financialRecords,
            getCellValue: record => record.revenue_recurring_growth,
            formatValue: formatPercentOneDecimalNoNull
        }),
        createRow({
            headerText: 'Recurring Dollar Growth',
            records: financialRecords,
            getCellValue: record => record.revenue_recurring_dollar_growth,
            formatValue: formatNoDecimalNoNullOrZero
        }),
        createRow({
            headerText: 'QoQ Recurr. Dollar Growth',
            records: financialRecords,
            getCellValue: record => record.revenue_recurring_sequential_dollar_growth,
            formatValue: formatNoDecimalNoNullOrZero
        }),
        emptyRow(),

        createHeaderRow({headerText: 'Total Rev Growth Metrics'}),

        createRow({
            headerText: 'Organic CC Revenue Growth',
            records: financialRecords,
            getCellValue: record => record.organic_constant_currency_revenue_growth,
            formatValue: formatPercentOneDecimalNoNull
        }),
        createRow({
            headerText: 'Revenue Growth',
            records: financialRecords,
            getCellValue: record => record.revenue_growth,
            formatValue: formatPercentOneDecimalNoNull
        }),
        createRow({
            headerText: 'Revenue Dollar Growth',
            records: financialRecords,
            getCellValue: record => record.revenue_dollar_growth,
            formatValue: formatNoDecimalNoNullOrZero
        }),
        createRow({
            headerText: 'QoQ Rev. Dollar Growth',
            records: financialRecords,
            getCellValue: record => record.revenue_sequential_dollar_growth,
            formatValue: formatNoDecimalNoNullOrZero
        }),
    ]
}