import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Form, Divider} from 'semantic-ui-react'
import {parseSearchProps} from "../../common/query/parse";
import CompanySelectBox from '../../components/CompanySelectBox'
import {commonFilterSpecsByName} from '../../common/filter/common';
import {getFilterComponentsFromSpecs} from '../../common/filter/add'
import SelectNewRangeFilter from '../../components/filter/SelectNewRangeFilter'
import qs from "qs";
import {useHistory, useLocation} from "react-router-dom";
import {allTableColumns} from "./common";
import {fetchFacetsForValuationTableRecords} from "../../features/summaryValuationFeatureSlice";
import RangeFilter from "../../components/filter/RangeFilter";


const quickFilterSpecs = [
    commonFilterSpecsByName.tracking_rank,
    commonFilterSpecsByName.business_quality,
    commonFilterSpecsByName.geography_group,
    commonFilterSpecsByName.region_name,

    commonFilterSpecsByName.lbo_track,
    commonFilterSpecsByName.primary_market_type
];

const filterSpecs = [
    {
        field: 'market_value_usd',
        label: 'Market Value (thousands)',
        type: 'range',
    },

];

export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchFacetsForValuationTableRecords());
    }, [dispatch]);

    const searchProps = parseSearchProps({searchString: location.search});

    const {facetsByField, loadingFacets} = useSelector(state => state.summaryValuation, shallowEqual);

    // quick filters
    const quickFilters = getFilterComponentsFromSpecs({
            filterSpecs: quickFilterSpecs,
            facetsByField: facetsByField,
            history,
            searchProps
        }
    );

    // filters that we specify directly from a spec
    const filters = getFilterComponentsFromSpecs({
            filterSpecs,
            facetsByField: facetsByField,
            history,
            searchProps
        }
    );

    return (
        <Form loading={loadingFacets}>
            {quickFilters}
            <CompanySelectBox/>
            {filters}

        </Form>
    )


}

