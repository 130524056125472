import {createSlice} from '@reduxjs/toolkit'

import client from '../../benchClient';
import {
    executeWithPending,
    getInitialStateWithLoading,
    getSingularPayloadFromArray,
    makeStandardReducers
} from "../common";
import {parseBoolean} from "../../common/query/parse";
import {PortfolioType} from "../../common/constants";

const portfolioService = client.service('portfolios');
const portfolioDividendsService = client.service('portfolio_dividends');
const portfolioTradesService = client.service('portfolio_trades');
const portfolioCurrencyConversionsService = client.service('portfolio_currency_conversions');
const otherPortfolioRecordsService = client.service('other_portfolio_records');

const portfolioStateService = client.service('portfolio_states');
const portfolioStateWithReportService = client.service('portfolio_state_with_reports');

const portfolioPositionsService = client.service('portfolio_positions');
const securityInvestmentsService = client.service('security_investments');
const securityInvestmentsWithPortfolioDataService = client.service('security_investments_with_portfolio_data');
const currentPortfolioStateService = client.service('current_portfolio_state');
const portfolioTradesFacetService = client.service('security_trades_facets');
const portfolioPeriodReportService = client.service('portfolio_period_reports');
const navBridgeService = client.service('nav_bridges');
const securityLendingRateRecordService = client.service('security_lending_rate_records');
const tradeAnalysisRecordService = client.service('trade_analysis_records');
const dailyTradeAnalysisSummaryRecords = client.service('daily_trade_analysis_summary_records');
const portfolioReturnsGroupsService = client.service('portfolio_returns_groups');
const portfolioPeriodReportWithSecurityInvestmentPeriodReportService = client.service('portfolio_period_reports_with_security_investment_period_reports');



const portfolioFeatureSlice = createSlice({
    name: 'portfolio',
    initialState: getInitialStateWithLoading({
        portfolios: [],
        portfolio: {},
        doradoBacktestPortfolio: {},

        portfolioDividends: [],
        portfolioTrades: [],

        portfolioCurrencyConversions: [],
        otherPortfolioRecords: [],

        portfolioStates: [],
        portfolioStateWithReports: [],
        portfolioPositions: [],

        securityInvestments: [],
        securityInvestmentsWithPortfolioData: [],
        currentPortfolioState: {},

        securityTradesFacets: {},

        portfolioPeriodReports: [],
        portfolioPeriodReportsWithSecurityInvestmentPeriodReports: [],


        securityLendingRateRecords: [],

        navBridges: [],

        tradeAnalysisRecords: [],

        dailyTradeAnalysisSummaryRecords: [],

        portfolioReturnsGroups: []

    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchPortfolios', destinationVariable: 'portfolios'}),
        ...makeStandardReducers({prefix: 'fetchPortfolioStates', destinationVariable: 'portfolioStates'}),
        ...makeStandardReducers({prefix: 'fetchPortfolioStateWithReports', destinationVariable: 'portfolioStateWithReports'}),

        ...makeStandardReducers({prefix: 'fetchPortfolioPositions', destinationVariable: 'portfolioPositions'}),

        ...makeStandardReducers({prefix: 'fetchPortfolioDividends', destinationVariable: 'portfolioDividends'}),
        ...makeStandardReducers({prefix: 'fetchPortfolioTrades', destinationVariable: 'portfolioTrades'}),
        ...makeStandardReducers({prefix: 'fetchSecurityInvestmentsWithPortfolioData', destinationVariable: 'securityInvestmentsWithPortfolioData'}),
        ...makeStandardReducers({prefix: 'fetchSecurityInvestments', destinationVariable: 'securityInvestments'}),

        ...makeStandardReducers({prefix: 'fetchCurrentPortfolioState', destinationVariable: 'currentPortfolioState'}),

        ...makeStandardReducers({prefix: 'fetchFacetsForPortfolioTradeRecords', destinationVariable: 'securityTradesFacets'}),

        ...makeStandardReducers({prefix: 'fetchPortfolioPeriodReports', destinationVariable: 'portfolioPeriodReports'}),

        ...makeStandardReducers({prefix: 'fetchDoradoBacktestPortfolio', destinationVariable: 'doradoBacktestPortfolio'}),
        ...makeStandardReducers({prefix: 'fetchPortfolio', destinationVariable: 'portfolio'}),

        ...makeStandardReducers({prefix: 'fetchNavBridges', destinationVariable: 'navBridges'}),

        ...makeStandardReducers({prefix: 'fetchSecurityLendingRateRecords', destinationVariable: 'securityLendingRateRecords'}),

        ...makeStandardReducers({prefix: 'fetchTradeAnalysisRecords', destinationVariable: 'tradeAnalysisRecords'}),

        ...makeStandardReducers({prefix: 'fetchDailyTradeAnalysisSummaryRecords', destinationVariable: 'dailyTradeAnalysisSummaryRecords'}),

        ...makeStandardReducers({prefix: 'fetchPortfolioReturnsGroups', destinationVariable: 'portfolioReturnsGroups'}),

        ...makeStandardReducers({
            prefix: 'fetchPortfolioPeriodReportsWithSecurityInvestmentPeriodReports',
            destinationVariable: 'portfolioPeriodReportsWithSecurityInvestmentPeriodReports'}),

        ...makeStandardReducers({prefix: 'fetchPortfolioCurrencyConversions', destinationVariable: 'portfolioCurrencyConversions'}),
        ...makeStandardReducers({prefix: 'fetchOtherPortfolioRecords', destinationVariable: 'otherPortfolioRecords'}),



    }
});


export const fetchPortfolios = ({sort, filters}) => async (dispatch, getState) => {

    let newSort = sort == null ? {
        id: 1,
        is_backtest: 1,
    } : sort;


    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolios',
        getPayload: () => portfolioService.find({
            query: {
                $sort: newSort,
                ...filters
            }
        })
    })
};



export const fetchLiveAndBacktestPortfolios = () => async (dispatch, getState) => {
    const sort = {
        type: 1,
        is_backtest: 1
    }

    const filters = {
        type: {
            $in: [
                'Point Sur',
                'Special Opps V',
                'Dorado',
                'Proforma Track Record',
                'Backtest Sensitivity']
        },
    }

    return dispatch(fetchPortfolios({sort, filters}))
}



export const fetchPortfoliosExMembers = () => async (dispatch, getState) => {

    const sort = {
        type: 1
    }

    const filters = {
        type: {
            $in: ['Point Sur', 'Special Opps V', 'Dorado']
        },
        is_backtest: false,
    }

    return dispatch(fetchPortfolios({sort, filters}))
}








export const fetchPortfolioDividends = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolioDividends',
        getPayload: () => portfolioDividendsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
};

export const fetchPortfolioTrades = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolioTrades',
        getPayload: () => portfolioTradesService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
};

export const fetchPortfolioCurrencyConversions = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolioCurrencyConversions',
        getPayload: () => portfolioCurrencyConversionsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
};


export const fetchOtherPortfolioRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchOtherPortfolioRecords',
        getPayload: () => otherPortfolioRecordsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
};


export const fetchPortfolioStates = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolioStates',
        getPayload: () => portfolioStateService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
};

export const fetchPortfolioStateWithReports = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolioStateWithReports',
        getPayload: () => portfolioStateWithReportService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
};



export const fetchPortfolioPositions = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolioPositions',
        getPayload: () => portfolioPositionsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
};


export const fetchSecurityInvestmentsWithPortfolioData = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchSecurityInvestmentsWithPortfolioData',
        getPayload: () => securityInvestmentsWithPortfolioDataService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
};


export const fetchSecurityInvestments = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchSecurityInvestments',
        getPayload: () => securityInvestmentsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
};

export const fetchCurrentPortfolioState = ({portfolioId, isEndOfDay}) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchCurrentPortfolioState',
        getPayload: () => currentPortfolioStateService.find({
            query: {
                portfolio_id: portfolioId,
                is_end_of_day: parseBoolean(isEndOfDay)
            }
        })
    })
};


export const fetchFacetsForPortfolioTradeRecords = () => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchFacetsForPortfolioTradeRecords',
        getPayload: () => portfolioTradesFacetService.find()
    })
}


export const fetchPortfolioPeriodReports = ({sort, filters}) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolioPeriodReports',
        getPayload: () => portfolioPeriodReportService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
}


export const fetchPortfolioPeriodReportsWithSecurityInvestmentPeriodReports = ({sort, filters}) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolioPeriodReportsWithSecurityInvestmentPeriodReports',
        getPayload: () => portfolioPeriodReportWithSecurityInvestmentPeriodReportService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
}



export const fetchDoradoBacktestPortfolio = () => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchDoradoBacktestPortfolio',
        getPayload: () => {return getSingularPayloadFromArray(portfolioService.find({
            query: {
                type: PortfolioType.dorado,
                is_backtest: true
            }
        }))}
    })
};


export const fetchPortfolio = (portfolioId) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolio',
        getPayload: () => {return getSingularPayloadFromArray(portfolioService.find({
            query: {
                id: portfolioId,
            }
        }))}
    })
};

export const fetchNavBridges = (portfolioId) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchNavBridges',
        getPayload: () => navBridgeService.find({
            query: {
                $sort: {
                    standard_date: 1
                },
                portfolio_id: portfolioId
            }
        })
    })
};

export const fetchSecurityLendingRateRecords = ({sort, filters}) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchSecurityLendingRateRecords',
        getPayload: () => securityLendingRateRecordService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
}

export const fetchTradeAnalysisRecords = ({sort, filters}) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchTradeAnalysisRecords',
        getPayload: () => tradeAnalysisRecordService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
}


export const fetchDailyTradeAnalysisSummaryRecords = ({sort, filters}) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchDailyTradeAnalysisSummaryRecords',
        getPayload: () => dailyTradeAnalysisSummaryRecords.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    })
}


export const fetchPortfolioReturnsGroups = ({portfolioIds, startDate, endDate}) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: portfolioFeatureSlice,
        prefix: 'fetchPortfolioReturnsGroups',
        getPayload: () => portfolioReturnsGroupsService.find({
            query: {
                portfolio_ids: {
                    $in: portfolioIds
                },
                start_date: startDate,
                end_date: endDate
            }
        })
    })
}




export default portfolioFeatureSlice.reducer
