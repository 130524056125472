import moment from "moment";
import React from "react";
import {Icon, Label} from "semantic-ui-react";


const dateDomainMoments = [
    moment("20150101", "YYYYMMDD"),
    moment("20160101", "YYYYMMDD"),
    moment("20170101", "YYYYMMDD"),
    moment("20180101", "YYYYMMDD"),
    moment("20190101", "YYYYMMDD"),
    moment("20200101", "YYYYMMDD"),
    moment("20210101", "YYYYMMDD")

];

export const dateDomain = dateDomainMoments.map(d => d.valueOf());

export const colorRange = [
    "#ece2f0",
    "#fed976",
    "#c7e9b4",
    "#c994c7",
    "#8c6bb1",
    "#41b6c4",
    "#225ea8"
];


export const YearLegend = () => {

    const legendItems = dateDomainMoments.map((dt, i) => {

        const color = colorRange[i];
        return <LegendItem dateMoment={dt} color={color} key={color}/>
    }).reverse();

    return (
        <div>
            {legendItems}
        </div>
    )
}

const LegendItem = ({dateMoment, color}) => {

    const style = {
        color: color
    };

    return (
        <div key={dateMoment.format("YYYY")}>
            <Label basic horizontal>
                <Icon name={'square'} style={style}/>
                {dateMoment.format("YYYY")}
            </Label>

        </div>
    )
}

