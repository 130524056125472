import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import {useReloadOnChange} from "../../../../common/hooks";
import {Grid, Statistic} from "semantic-ui-react";
import ViewWithSidebar from "../../../../components/ViewWithSidebar";
import RdgTable from "../../../../components/rdg/RdgTable";
import {columns} from "./common";
import {
    fetchBenchmarkMetadatasWithCompanies,

} from "../../../../features/dorado/doradoValuationRecords";
import {convertQuerySortToFeathersSort} from "../../../../common/request/convert";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {benchmarkMetadataRecords, loadingBenchmarkMetadataRecords} = useSelector(state => state.doradoValuation, shallowEqual);

    console.log(benchmarkMetadataRecords);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData: requestData
    });

    const sidebar = (
        <div>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={benchmarkMetadataRecords.length} label='Records' size="tiny"/>
                </Grid.Row>
            </Grid>
        </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             loading={loadingBenchmarkMetadataRecords}
                                             records={benchmarkMetadataRecords}/>}
                         sidebarView={sidebar}/>
    )



}


const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = {
        is_backtest: true,
        revision_percent_from_changed_methodology: {
            $gte: 0
        }

    };

    dispatch(fetchBenchmarkMetadatasWithCompanies({sort, filters}));


};
