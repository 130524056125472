import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
const securityListingDividendsForCompanyService = client.service('security_listing_dividends_for_company');


const securityListingDividendsFeatureSlice = createSlice({
    name: 'securityListingDividends',
    initialState: getInitialStateWithLoading({
        securityListingDividendsForCompany: [],
    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchSecurityListingDividendRecordsForCompany', destinationVariable: 'securityListingDividendsForCompany'}),
    }
});


export const fetchSecurityListingDividendRecordsForCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: securityListingDividendsFeatureSlice,
        prefix: 'fetchSecurityListingDividendRecordsForCompany',
        getPayload: () => securityListingDividendsForCompanyService.get(companyId)
    })
};


export default securityListingDividendsFeatureSlice.reducer