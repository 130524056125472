const defaultState = {
    loading: true,
    records: [],

    facetsByField: {},
    loadingFacetsByField: true,
};


export default (state = defaultState, action = {}) => {
    switch (action.type) {

        case 'FETCH_AKKR_CENSUS_RECORDS_PENDING': {
            return {
                ...state,
                loading: true
            }
        }

        case 'FETCH_AKKR_CENSUS_RECORDS_FULFILLED': {
            return {
                ...state,
                loading: false,
                records: action.payload.data,
            }
        }

        case 'FETCH_AKKR_CENSUS_FACETS_PENDING': {
            return {
                ...state,
                loadingFacetsByField: true
            }
        }

        case 'FETCH_AKKR_CENSUS_FACETS_FULFILLED': {
            return {
                ...state,
                loadingFacetsByField: false,
                facetsByField: action.payload.data
            }
        }

        default:
            return state;

    }
}