import {
    getMostRecentSortColumnAndDirectionForRDG,
    getRdgRowsFromRecords,
    getTableClassName,
    makeHandleReactDataGridSort,
} from "../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import React from "react";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";
import {
    DateFormatter,
    NoDecimalNoNullFormatter, PercentOneDecimalNoNullFormatter,
    TwoDecimalCurrencyFormatter
} from "../../../common/reactDataGrid/formatters";
import {getWindowHeightLessMenuBar} from "../../../common/common";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {createAppendSortToQuery} from "../../../common/query/add";


const getColumns = ({startDate, endDate}) => {
    return [
        CompanyLinkFromSecurityListing,

        {
            key: 'total_gain',
            name: 'Total Gain $',
            formatter: NoDecimalNoNullFormatter,
            sortable: true,
            width: 90,
        },
        {
            key: 'total_gain_percent_of_adj_beginning_portfolio_nav',
            name: 'Contribution % of Beginning NAV',
            formatter: PercentOneDecimalNoNullFormatter,
            sortable: true,
            width: 90,
        },
        {
            key: 'total_gain_percent',
            name: 'Total Gain %',
            formatter: PercentOneDecimalNoNullFormatter,
            sortable: true,
            width: 90,
        },

        {
            key: 'beginning_market_value',
            name: 'Ending Market Value',
            formatter: NoDecimalNoNullFormatter,
            sortable: true,
            width: 100,
        },

        {
            key: 'ending_market_value',
            name: 'Ending Market Value',
            formatter: NoDecimalNoNullFormatter,
            sortable: true,
            width: 100,
        },
        {
            key: 'beginning_share_price',
            name: 'Beginning $/share',
            formatter: TwoDecimalCurrencyFormatter,
            sortable: true,
            width: 100,
        },
        {
            key: 'ending_share_price',
            name: 'Ending $/share',
            formatter: TwoDecimalCurrencyFormatter,
            sortable: true,
            width: 100,
        },


        {
            key: 'first_valuation_date',
            name: 'First Valuation Date',
            formatter: DateFormatter,
            sortable: true,
            width: 100,
        },
        {
            key: 'last_valuation_date',
            name: 'Last Valuation Date',
            formatter: DateFormatter,
            sortable: true,
            width: 100,
        },
        {
            key: 'realized_short_term_capital_gain',
            name: 'Realized ST Gain $',
            formatter: NoDecimalNoNullFormatter,
            sortable: true,
            width: 90,
        },
        {
            key: 'realized_long_term_capital_gain',
            name: 'Realized LT Gain $',
            formatter: NoDecimalNoNullFormatter,
            sortable: true,
            width: 90,
        },
        {
            key: 'unrealized_short_term_capital_gain',
            name: 'Unrealized ST Gain $',
            formatter: NoDecimalNoNullFormatter,
            sortable: true,
            width: 90,
        },
        {
            key: 'unrealized_long_term_capital_gain',
            name: 'Unrealized LT Gain $',
            formatter: NoDecimalNoNullFormatter,
            sortable: true,
            width: 90,
        },




    ]
}


export default ({records, loading, selectedPeriodReport}) => {

    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const onChangeSort = createAppendSortToQuery({history, searchProps: searchProps});
    const handleReactDataGridSort = makeHandleReactDataGridSort(onChangeSort);
    const {sortColumn, sortDirection} = getMostRecentSortColumnAndDirectionForRDG(searchProps);

    const longRecords = records.filter(r => r.is_long);
    const shortRecords = records.filter(r => !r.is_long);


    const allRecords = [
        {
            _isHeader: true,
            _name: 'Total',
            total_gain: selectedPeriodReport.positions_total_gain,
            share_price_growth: selectedPeriodReport.ending_net_asset_value / selectedPeriodReport.beginning_net_asset_value - 1
        },

        {
            _isHeader: true,
            _name: ''
        },
        {
            _isHeader: true,
            _name: 'Long'
        },
        ...longRecords,
        {
            _isHeader: true,
            _name: ''
        },
        {
            _isHeader: true,
            _name: 'Short'
        },
        ...shortRecords
    ]

    const columns = getColumns({startDate: selectedPeriodReport.start_date, endDate: selectedPeriodReport.end_date});

    const rows = getRdgRowsFromRecords({records: allRecords, columns});
    return (

        <div className={getTableClassName(loading)}>

            <ReactDataGrid
                columns={columns}
                onGridSort={handleReactDataGridSort}
                rowGetter={index => rows[index]}
                rowsCount={rows.length}
                rowHeight={22}
                headerRowHeight={100}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                minHeight={getWindowHeightLessMenuBar() - 45}
            />

        </div>

    )
};