import React from 'react';
import {Form} from 'semantic-ui-react';
import {fieldSpecs, periodOptions} from "./common";
import {parseSearchProps} from "../../../common/query/parse";
import {createAddValueToQuery} from "../../../common/query/add";
import {useHistory, useLocation} from "react-router-dom";


export default () => {

    const location = useLocation();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});
    const history = useHistory();

    const onChange = createAddValueToQuery({history, searchProps, fieldName: 'periodOption'});

    const options = [
        {
            text: 'Annual',
            value: 'Annual'
        },
        {
            text: 'Monthly',
            value: 'Monthly'
        },
        {
            text: 'Quarterly',
            value: 'Quarterly'
        },
        {
            text: 'LTM Annual',
            value: 'LTM Annual'
        },
        {
            text: 'LTM Quarterly',
            value: 'LTM Quarterly'
        },
    ];

    return (
        <Form.Select options={options}
                     value={searchProps.periodOption}
                     onChange={onChange}
                     inline
                     label="Period"/>
    )

}