import { createSlice } from '@reduxjs/toolkit'

import client from '../../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";
const doradoRevenueGrowthAuditService = client.service('dorado_revenue_growth_audit_records');


const doradoAuditFeatureSlice = createSlice({
    name: 'doradoAudit',
    initialState: getInitialStateWithLoading({
        doradoRevenueGrowthAuditRecords: [],
    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchDoradoRevenueGrowthAuditRecords', destinationVariable: 'doradoRevenueGrowthAuditRecords'}),

    }
});


export const fetchDoradoRevenueGrowthAuditRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoAuditFeatureSlice,
        prefix: 'fetchDoradoRevenueGrowthAuditRecords',
        getPayload: () => {return doradoRevenueGrowthAuditService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}

    })
};


export default doradoAuditFeatureSlice.reducer