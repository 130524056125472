import React from 'react';
import {Container, Header, List} from 'semantic-ui-react';


export default () => {

  return (
    <Container>

      <Header as='h1'>Valuation Methodology</Header>
      <p>
        These valuation and company financial screens take raw CapIQ data and supplement it with data collected from
        company filings and AKKR estimates. These
        three separate data sets are merged to provide more accurate valuation metrics.
        The methodology is as follows:
      </p>

      <List celled bulleted relaxed size="large">
        <List.Item>
          <List.Content>
            <List.Header>
              Download CapIQ data for software universe. Data includes historical and estimated income statement items,
              balance sheet, cash flow, etc..
            </List.Header>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            <List.Header>
              Manually collect standardized supplemental data for software universe from public filings. Key items
              include:
            </List.Header>
            <List.Description>
              <List.List>
                <List.Item>
                  Revenue and Gross Profit Composition by standard buckets (if available):
                  <List>
                    <List.Item>Perpetual License</List.Item>
                    <List.Item>Term License</List.Item>
                    <List.Item>Maintenance/Support</List.Item>
                    <List.Item>Subscription/SaaS</List.Item>
                    <List.Item>Services</List.Item>
                    <List.Item>Hardware</List.Item>
                    <List.Item>Recurring Transactional</List.Item>
                    <List.Item>Other</List.Item>
                  </List>
                </List.Item>
                <List.Item>
                  Capitalized Software. Adjust historical and forward estimates for capitalized software.
                </List.Item>
                <List.Item>
                  Standardized COGS, S&M, R&D, G&A. Standardized functional cost groups exclude D&A and nonrecurring
                  charges, but include cap SW.
                </List.Item>
                <List.Item>
                  Estimate historical and future organic constant currency revenue growth.
                </List.Item>
                <List.Item>
                  <p>Properly handle unvested RSUs.</p>

                  <p>
                    Unvested RSUs, depending on how they are structured, are often not included in basic or diluted
                    shares. Under GAAP,
                    unvested RSUs are not included in diluted shares if they are "anti-dilutive", ie result in
                    increasing EPS. Counterintuitively,
                    if a company has no earnings, increasing the denominator (shares) results in less-negative EPS.
                    Therefore, as many
                    public companies are not generating GAAP profits, they are allowed to exclude most forms of dilution
                    from
                    diluted shares.

                    The fact that these shares are not included in GAAP diluted shares does not change the fact that
                    these
                    securities are an underlying source of dilution. They will typically vest over time (1-3 years), and
                    may vest 100%
                    immediately upon a change-of-control.
                  </p>


                </List.Item>
                <List.Item>
                  US Federal NOLs
                </List.Item>
              </List.List>

            </List.Description>

          </List.Content>


        </List.Item>
        <List.Item>
          <List.Header>
            Clean and merge data, calculating forward estimates and valuation metrics on an AKKR-standardized basis.
          </List.Header>
          <List>
            <List.Item>
              EBITDA estimates adjusted for cap SW
            </List.Item>
            <List.Item>
              Correct diluted share count, taking into account RSU vesting and option dilution
            </List.Item>
          </List>
        </List.Item>

      </List>

      <Header as='h1'>Dilution Detail</Header>
      <p>Restricted Stock vs Restricted Stock Units</p>
      <List>

        <List.Item>
          Restricted Stock: Stock is issued upon the grant and therefore flows into basic shares
          immediately. Each period,
          basic shares are increased by new grants and reduced by forfeited shares.
        </List.Item>
        <List.Item>
          Restricted Stock Units: Stock is issued when the RSUs vest. Therefore, there is no impact on the
          basic share
          count until shares vest. This means there can be a big overhang of unvested RSUs that must be
          added to the basic share count,
          as they will flow into basic shares over time or upon a change of control.
        </List.Item>
      </List>

    </Container>
  )


}
