import React, { Component} from 'react';
import {Container, Header, List, Table} from "semantic-ui-react";


export default () => {
    return (
        <Container>

            <Header as='h1'>Backtest Questions</Header>

            <p>
                Backtest Audit Reports:
            </p>
            <Table padded verticalAlign={'top'}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>
                            Audit Report
                        </Table.HeaderCell>
                        <Table.HeaderCell width={7}>
                            Questions Answered
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Report
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    What is our return, and our alpha?
                                </List.Item>
                                <List.Item>
                                    Is the max drawdown acceptable?  What caused it?
                                </List.Item>
                                <List.Item>
                                    What % of the portfolio do we trade per day?  Is this too active?
                                </List.Item>
                                <List.Item>
                                    What is our top position weight / top-5 weight.  Are we comfortable with the
                                    maximum concentration level?
                                </List.Item>
                                <List.Item>
                                    What companies were responsible for our greatest / worst portfolio returns?  Note that
                                    decisions are made at a portfolio level so we also need to 
                                </List.Item>
                            </List>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>

            </Table>

            <p>
                Answers to these questions will determine the validity of our backtest.
            </p>

            <Table padded verticalAlign={'top'}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>
                            Question
                        </Table.HeaderCell>
                        <Table.HeaderCell width={7}>
                            Rationale
                        </Table.HeaderCell>
                        <Table.HeaderCell width={6}>
                            Tests
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Is the backtest return superior to default strategies?
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Backtest should have superior return vs the broad market (general alpha).
                                </List.Item>
                                <List.Item>
                                    Backtest should outperform a broad tech/software index.  We deserve credit
                                    for focusing on tech, but should be alpha generated from selection vs simply buying
                                    the index.
                                </List.Item>
                                <List.Item>
                                    Backtest should outperform other simple buy-and-hold strategies (high growth SaaS,
                                    equal weight Dorado) for us to believe that trading adds value.
                                </List.Item>
                            </List>
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Return vs S&P 500
                                </List.Item>
                                <List.Item>
                                    Return vs IGV.  Note that it is ok for the alpha vs IGV to be less than vs the S&P
                                    as we focus on tech because we believe it will outperform the broad market.
                                </List.Item>
                                <List.Item>
                                    Return vs equal weight >20% growth SaaS in Dorado
                                </List.Item>
                                <List.Item>
                                    Return vs equal weight Execution Score > 40%
                                </List.Item>
                            </List>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell >
                            What companies are the source of our alpha?
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    We should expect companies and share price dates that Dorado likes
                                    (high expected IRR) to be the source of return in our backtest.
                                </List.Item>
                                <List.Item>
                                    If a result is counterintuitive (something we never would have bought ourselves),
                                    is this an error in Dorado, or is this Dorado doing a better job at valuation
                                    than us?
                                </List.Item>
                            </List>
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    List companies by Relative Gain $ vs SPY and investigate
                                </List.Item>
                                <List.Item>
                                    List companies by Relative Gain $ vs IGV and investigate
                                </List.Item>
                                <List.Item>
                                    List companies by Gain $ and compare to the weighted average Dorado IRR when we
                                    made the buy trades
                                </List.Item>
                                <List.Item>
                                    Do we make money on a variety of company profiles over time, or is it just high growth SaaS?
                                </List.Item>


                            </List>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell >
                            How concentrated are our returns by company?
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Just like a discretionary portfolio, ownership >10% in any security carries concentration risks.
                                    This is even more-so with a quantitative strategy where the concentration could be
                                    the result of an error or incomplete information.
                                </List.Item>
                                <List.Item>
                                    Results are less likely to be repeatable in real life if returns are due to a few
                                    great picks.
                                </List.Item>
                                <List.Item>
                                    Our theory is Dorado generates alpha by rationally valuing 75+ companies and rebalancing
                                    towards the most attractive.  If that is the case, we should see alpha generated
                                    across companies and across time (trades in a single company).
                                </List.Item>
                            </List>
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Top position avg. / max weight
                                </List.Item>
                                <List.Item>
                                    Top 5 positions avg. / max weights
                                </List.Item>
                                <List.Item>
                                    Run a sensitivity with greater / less concentration.  Can Dorado still outperform
                                    at lower concentration?
                                </List.Item>
                                <List.Item>
                                    Remove some top outperformers from the Dorado universe and re-run.  Can Dorado
                                    find other situations to trade?
                                </List.Item>
                                <List.Item>
                                    Remove unconventional / unintuitive outperformers from the Dorado universe and re-run.
                                    Ie, if Pluralsight is a source of alpha, remove it. Can we generate alpha without these
                                    unconventional picks?
                                </List.Item>
                            </List>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell >
                            Are our results robust through time?
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    The backtest should generate alpha through different market environments (market up
                                    vs down, volatile vs low vol, growth SaaS outperforming or underperforming)
                                </List.Item>
                                <List.Item>
                                    It should not be sensitive to start date or end date.
                                </List.Item>
                            </List>
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Continue to extend backtest further back in time.  Do note that results prior to some
                                    date (2012, 2014?) will be unreliable, as the market had much less understanding of
                                    software and we probably would not have fully fleshed out Dorado concepts like max margin.
                                </List.Item>
                                <List.Item>
                                    Run Dorado with a variety of start and end dates.  Try running it at the start
                                    of a drawdown period and see how long it takes to get back to outperformance.
                                </List.Item>
                            </List>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell >
                            What is the volatility of our returns?
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Steadier returns indicate greater likelihood of outperformance in the future.
                                </List.Item>
                                <List.Item>
                                    Steadier returns may indicate greater value in the "trading" component of our strategy,
                                    an ability to constantly shift and make money somewhere.
                                </List.Item>
                            </List>
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Standard deviation vs benchmarks.  Are we lower or higher?  Higher may be ok if
                                    we are comfortable the volatility is upside volatility or the drawdowns are temporary.
                                </List.Item>
                                <List.Item>
                                    Sharpe Ratio
                                </List.Item>
                            </List>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell >
                            What caused our largest drawdowns?
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Dorado can accept some level of drawdown if we have comfort it is due to market irrationality.
                                </List.Item>
                                <List.Item>
                                    Conversely, drawdowns may expose a critical flaw in Dorado's algorithm or our
                                    forecasts, so they should be investigated.
                                </List.Item>
                            </List>
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    What are the largest contributors to the drawdown?  What did Dorado think of them going in
                                    and at the bottom?
                                </List.Item>
                                <List.Item>
                                    Were any detractors due to forecast error, where it is clear in retrospect that the
                                    forecast was too high?
                                </List.Item>
                                <List.Item>
                                    Were any detractors due to what looks to use like valuation error, where the multiple
                                    and valuation look way too aggressive?
                                </List.Item>
                                <List.Item>
                                    Did Dorado later lower valuation on any of these detractors?  Ie does Dorado admit it
                                    was wrong on valuation?  Could we have predicted this in advance and avoided the loss?
                                </List.Item>
                                <List.Item>
                                    What do the weighted average valuation and execution score metrics look like for our
                                    portfolio during the drawdown?  If we own a rule-of-40 portfolio at 5x revenue we can
                                    chalk it up to market irrationality.  If our portfolio is at 15x revenue then Dorado
                                    may be too biased towards growth.
                                </List.Item>
                            </List>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell >
                            Does Dorado generate alpha from picking good businesses or from trading?
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Our valuations are 5-year IRRs.  If they are directionally correct, a buy and hold strategy
                                    with occasional rebalancing should work.
                                </List.Item>
                                <List.Item>
                                    Is our frequency of trading additive to returns?  If so, the strategy is much more
                                    valuable as it will help dampen volatility and is more likely to be an all-weather
                                    strategy.
                                </List.Item>
                            </List>
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Run Dorado as a buy and hold strategy.  How do returns compare to a frequent trading
                                    strategy?
                                </List.Item>
                                <List.Item>
                                    For a given company, is our trading additive?  Is the IRR of the investment greater
                                    than just buying on the first trade and holding?
                                </List.Item>
                                <List.Item>
                                    Break a company's return down into specific trades.  Which ones generated alpha?
                                    Does trimming winners on valuation help returns?  Does buying dips help returns?
                                </List.Item>
                                <List>
                                    Dorado backtest only trades 1 time per day.  Would returns be enhanced by more
                                    frequent trading during the day?
                                </List>

                            </List>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell >
                            Are there any methodological errors we can think of in our backtest?
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Real world results typically underperform backtest because it is impossible to not
                                    introduce some lookahead bias into results.
                                </List.Item>
                            </List>
                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    Backtest currently uses VWAP for trades.  We have no idea if we could have gotten this
                                    price in reality.  We should try to get intraday trade data and see if results are
                                    similar.  We should check volumes to make sure the $ amount we trade is reasonable.
                                </List.Item>
                                <List.Item>
                                    Are we honest in our forecasts?  Do we under-forecast growth for companies that
                                    subsequently disappoint or over-forecast growth for companies that subsequently do well?
                                </List.Item>
                                <List.Item>
                                    Does the backtest work for US-only SaaS?  There can be time zone issues trading
                                    foreign securities - we want to make sure this isn't a false source of alpha.
                                </List.Item>
                                <List.Item>
                                    Break a company's return down into specific trades.  Which ones generated alpha?
                                    Does trimming winners on valuation help returns?  Does buying dips help returns?
                                </List.Item>


                            </List>
                        </Table.Cell>
                    </Table.Row>





                </Table.Body>
            </Table>
        </Container>
    )
}
