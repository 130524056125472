import {createSlice} from '@reduxjs/toolkit'

import client from '../../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";
import {PortfolioType} from "../../common/constants";

const doradoPortfoliosService = client.service('dorado_portfolios');
const doradoPortfolioStatesService = client.service('dorado_portfolio_states');
const doradoTargetPortfolioRecommendations = client.service('dorado_portfolio_recommendations');
const doradoPortfolioPositionsService = client.service('dorado_portfolio_positions');
const doradoPortfolioWithReportsService = client.service('dorado_portfolio_with_reports');
const doradoPortfolioStatesWithReportService = client.service('dorado_portfolio_states_with_report');
const doradoPortfolioStatesWithReportDownloadService = client.service('dorado_portfolio_states_with_report_csv');
const doradoBacktestParametersService = client.service('dorado_backtest_parameters');
const doradoPortfolioParametersService = client.service('dorado_portfolio_parameters');
const doradoBacktestPortfolioSensitivitiesService = client.service('dorado_portfolio_result_sensitivities');
const securityInvestmentPeriodReportsService = client.service('security_investment_period_reports');
const doradoConstantsService = client.service('dorado_constants');
const doradoPortfolioTradeMetadataService = client.service('dorado_portfolio_trade_metadata');
const doradoCustomBacktestPositionReportsService = client.service('dorado_custom_backtest_position_reports');
const doradoBacktestPeriodReportsService = client.service('dorado_backtest_period_reports');

const doradoBacktestPeriodReportWithCompaniesService = client.service('dorado_backtest_period_report_with_companies');
const doradoBacktestBenchmarkPortfolioStatesByPortfolio = client.service('dorado_backest_benchmark_portfolio_states_by_portfolio');



const doradoPortfolioFeatureSlice = createSlice({
    name: 'doradoPortfolio',
    initialState: {
        ...getInitialStateWithLoading({
            portfolio: {},
            portfolioStates: [],

            doradoPortfolio: {},

            // Dorado -> Current Portfolio View
            currentPortfolioState: {},
            latestEndOfDayDoradoPortfolioState: {},

            // used to get ID for backtest redirect
            backtestDoradoPortfolio: {},

            // positions for a state
            doradoPortfolioPositions: [],

            doradoCustomBacktestPositionReports: [],

            // with attached report
            doradoPortfolioStatesWithReport: [],
            portfolioWithReport: {dorado_portfolio_report: {}},


            securityInvestmentPeriodReports: [],
            doradoConstants: {},

            doradoPortfoliosWithReports: [],
            doradoBacktestPeriodReports: [],
            doradoBacktestPeriodReportsWithCompanies: [],

            doradoPortfolioTradeMetadata: {},

            // as of a certain date
            portfolioRecommendations: {
                rows: [],
            },
            securityOrders: [],
            securityTrades: [],
            portfolioPositions: [],


            backtestParameters: {},
            backtestSensitivities: [],
            doradoPortfolioParameters: {},

            // for company detail
            historicalPortfolioPositionsForCompany: [],

            spyIndexedReturnRecords: [],
            igvIndexedReturnRecords: [],

            doradoBacktestBenchmarkPortfolioStatesByPortfolio: []




        }),
        // override
        loadingDoradoCustomBacktestPositionReports: false,
    },
    reducers: {
        // fetchDoradoPortfolioPending: (state) => {
        //     state.loadingPortfolio = true
        // },
        // fetchDoradoPortfolioFulfilled: (state, action) => {
        //     state.loadingPortfolio = false;
        //     const result = action.payload.data;
        //     if (result.length === 1) {
        //         state.portfolio = result[0];
        //     }
        // },
        fetchDoradoPortfolioWithReportPending: (state) => {
            state.loadingPortfolioWithReport = true
        },
        fetchDoradoPortfolioWithReportFulfilled: (state, action) => {
            state.loadingPortfolioWithReport = false;
            const result = action.payload.data;
            if (result.length === 1) {
                state.portfolioWithReport = result[0];
            }
        },

        fetchCurrentDoradoPortfolioStatePending: (state) => {
            state.loadingCurrentPortfolioState = true;
        },
        fetchCurrentDoradoPortfolioStateFulfilled: (state, action) => {
            state.loadingCurrentPortfolioState = false;
            const result = action.payload.data;
            if (result.length === 1) {
                state.currentPortfolioState = result[0];
            }
        },

        fetchBacktestDoradoPortfolioPending: (state) => {
            state.loadingBacktestDoradoPortfolio = true;
        },
        fetchBacktestDoradoPortfolioFulfilled: (state, action) => {
            state.loadingBacktestDoradoPortfolio = false;
            const result = action.payload.data;
            if (result.length === 1) {
                state.backtestDoradoPortfolio = result[0];
            } else {
                console.log("error")
                state.backtestDoradoPortfolio = {};
            }

        },




        ...makeStandardReducers({prefix: 'getDoradoPortfolio', destinationVariable: 'doradoPortfolio'}),

        ...makeStandardReducers({prefix: 'fetchDoradoPortfolioPositionsForState', destinationVariable: 'doradoPortfolioPositions'}),

        ...makeStandardReducers({prefix: 'fetchDoradoBacktestParameters', destinationVariable: 'backtestParameters'}),
        ...makeStandardReducers({prefix: 'patchDoradoBacktestParameters', destinationVariable: 'backtestParameters'}),

        ...makeStandardReducers({prefix: 'fetchDoradoPortfolioParameters', destinationVariable: 'doradoPortfolioParameters'}),
        ...makeStandardReducers({prefix: 'patchDoradoPortfolioParameters', destinationVariable: 'doradoPortfolioParameters'}),

        ...makeStandardReducers({prefix: 'fetchDoradoConstants', destinationVariable: 'doradoConstants'}),
        ...makeStandardReducers({prefix: 'fetchDoradoPortfolioTradeMetadata', destinationVariable: 'doradoPortfolioTradeMetadata'}),

        ...makeStandardReducers({prefix: 'fetchDoradoBacktestSensitivities', destinationVariable: 'backtestSensitivities'}),
        ...makeStandardReducers({prefix: 'fetchSecurityInvestmentPeriodReports', destinationVariable: 'securityInvestmentPeriodReports'}),

        ...makeStandardReducers({
            prefix: 'fetchDoradoCustomBacktestPositionReports',
            destinationVariable: 'doradoCustomBacktestPositionReports',
            defaultLoading: false
            }),



        ...makeStandardReducers({prefix: 'fetchDoradoPortfolioStates', destinationVariable: 'portfolioStates'}),
        ...makeStandardReducers({prefix: 'fetchDoradoTargetPortfolioRecommendations', destinationVariable: 'portfolioRecommendations'}),
        ...makeStandardReducers({prefix: 'fetchDoradoPortfolioPositionsForPortfolioState', destinationVariable: 'portfolioPositions'}),

        ...makeStandardReducers({prefix: 'fetchDoradoPortfolioStatesWithReport', destinationVariable: 'doradoPortfolioStatesWithReport'}),

        ...makeStandardReducers({prefix: 'fetchHistoricalPortfolioPositionsForCompany', destinationVariable: 'historicalPortfolioPositionsForCompany'}),

        ...makeStandardReducers({prefix: 'fetchDoradoPortfoliosWithReports', destinationVariable: 'doradoPortfoliosWithReports'}),
        ...makeStandardReducers({prefix: 'fetchDoradoBacktestPeriodReports', destinationVariable: 'doradoBacktestPeriodReports'}),

        ...makeStandardReducers({
            prefix: 'fetchDoradoBacktestPeriodReportsWithCompanies',
            destinationVariable: 'doradoBacktestPeriodReportsWithCompanies'}),

        ...makeStandardReducers({
            prefix: 'fetchDoradoBacktestBenchmarkPortfolioStatesByPortfolio',
            destinationVariable: 'doradoBacktestBenchmarkPortfolioStatesByPortfolio'}),





        clearBacktestDoradoPortfolio: (state, action) => {
            state.backtestDoradoPortfolio = {};
            state.loadingBacktestDoradoPortfolio = true;
        }

    }
});

export const {
    clearBacktestDoradoPortfolio
} = doradoPortfolioFeatureSlice.actions;



export const fetchBacktestDoradoPortfolio = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchBacktestDoradoPortfolio',
        getPayload: () => {return doradoPortfolioWithReportsService.find({
            query: {
                type: PortfolioType.backtest
            }

        })}
    })
};




export const fetchDoradoBacktestParameters = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoBacktestParameters',
        getPayload: () => {return doradoBacktestParametersService.find({})}
    })
};

export const patchDoradoBacktestParameters = (backtestParameters) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'patchDoradoBacktestParameters',
        getPayload: () => {return doradoBacktestParametersService.patch(backtestParameters.id, backtestParameters)}
    })
};

export const fetchDoradoPortfolioParameters = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoPortfolioParameters',
        getPayload: () => {return doradoPortfolioParametersService.find({})}
    })
};

export const patchDoradoPortfolioParameters = (parameters) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'patchDoradoPortfolioParameters',
        getPayload: () => {return doradoPortfolioParametersService.patch(parameters.id, parameters)}
    })
};

export const fetchDoradoConstants = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoConstants',
        getPayload: () => {return doradoConstantsService.find({})}
    })
};


export const fetchDoradoPortfolioTradeMetadata = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoPortfolioTradeMetadata',
        getPayload: () => {return doradoPortfolioTradeMetadataService.find({})}
    })
};

export const getDoradoPortfolio = (id) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'getDoradoPortfolio',
        getPayload: () => {return doradoPortfoliosService.get(id)}
    })
};




export const fetchDoradoBacktestSensitivities = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoBacktestSensitivities',
        getPayload: () => {return doradoBacktestPortfolioSensitivitiesService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}
    })
};

export const fetchSecurityInvestmentPeriodReports = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchSecurityInvestmentPeriodReports',
        getPayload: () => {return securityInvestmentPeriodReportsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}
    })
};


export const fetchCurrentDoradoPortfolioState = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchCurrentDoradoPortfolioState',
        getPayload: () => {return doradoPortfolioStatesService.find({
            query: {
                type: PortfolioType.current
            }
        })}
    })
};






export const fetchDoradoPortfolioPositionsForState = ({dorado_portfolio_state_id}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoPortfolioPositionsForState',
        getPayload: () => {return doradoPortfolioPositionsService.find({
            query: {
                dorado_portfolio_state_id
            }
        })}
    })
};


export const fetchDoradoPortfolio = ({filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoPortfolio',
        getPayload: () => {return doradoPortfoliosService.find({
            query: {
                ...filters
            }
        })}
    })
};





export const fetchDoradoPortfolioStates = ({filters, sortByDateAsc = true}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoPortfolioStates',
        getPayload: () => {return doradoPortfolioStatesService.find({
            query: {
                $sort: {
                    standard_date: sortByDateAsc ? 1 : -1
                },
                ...filters
            }
        })}

    })
};

export const fetchDoradoPortfolioWithReport = ({filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoPortfolioWithReport',
        getPayload: () => {return doradoPortfolioWithReportsService.find({
            query: {
                ...filters
            }
        })}
    })
};


export const fetchDoradoPortfoliosWithReports = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoPortfoliosWithReports',
        getPayload: () => {return doradoPortfolioWithReportsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}
    })
};




export const fetchDoradoPortfolioStatesWithReport = ({filters, sortByDateAsc = true}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoPortfolioStatesWithReport',
        getPayload: () => {return doradoPortfolioStatesWithReportService.find({
            query: {
                $sort: {
                    standard_date: sortByDateAsc ? 1 : -1
                },
                ...filters
            }
        })}

    })
};

export const fetchDoradoBacktestPeriodReports = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoBacktestPeriodReports',
        getPayload: () => {return doradoBacktestPeriodReportsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}

    })
};


export const fetchDoradoBacktestPeriodReportsWithCompanies = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoBacktestPeriodReportsWithCompanies',
        getPayload: () => {return doradoBacktestPeriodReportWithCompaniesService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}
    })
};


export const fetchDoradoTargetPortfolioRecommendations = ({dorado_portfolio_state_id}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoTargetPortfolioRecommendations',
        getPayload: () => {return doradoTargetPortfolioRecommendations.find({
            query: {
                dorado_portfolio_state_id: dorado_portfolio_state_id
            }
        })}
    })
};




export const fetchDoradoPortfolioPositionsForPortfolioState = ({dorado_portfolio_state_id}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoPortfolioPositionsForPortfolioState',
        getPayload: () => {return doradoPortfolioPositionsService.find({
            query: {
                dorado_portfolio_state_id: dorado_portfolio_state_id,
                $sort: {
                    market_value: -1
                }
            }
        })}
    })
};

export const fetchHistoricalPortfolioPositionsForCompany = ({company_id}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchHistoricalPortfolioPositionsForCompany',
        getPayload: () => {return doradoPortfolioPositionsService.find({
            query: {
                company_id: company_id,
                $sort: {
                    standard_date: 1
                },
                is_backtest: true

            }
        })}
    })
};


export const downloadDoradoPortfolioStatesWithReport = ({dispatch}) => {

    dispatch({
        type: 'DOWNLOAD_PORTFOLIO_STATES_WITH_REPORT',
        payload: doradoPortfolioStatesWithReportDownloadService.find({
        }, {responseType: 'blob'}).then((response, other) => {

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Dorado Backtest.csv');
            document.body.appendChild(link);
            link.click();

        })
    });
};


export const fetchDoradoCustomBacktestPositionReports = ({startDate, endDate}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoCustomBacktestPositionReports',
        getPayload: () => {return doradoCustomBacktestPositionReportsService.find({
            query: {
                start_date: startDate,
                end_date: endDate
            }
        })}
    })
};


export const fetchDoradoBacktestBenchmarkPortfolioStatesByPortfolio = ({filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPortfolioFeatureSlice,
        prefix: 'fetchDoradoBacktestBenchmarkPortfolioStatesByPortfolio',
        getPayload: () => {return doradoBacktestBenchmarkPortfolioStatesByPortfolio.find({
            query: {
                ...filters
            }
        })}
    })
};





export default doradoPortfolioFeatureSlice.reducer