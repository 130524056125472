import React from 'react'
import {Divider, Table} from "semantic-ui-react";
import {formatFullDate, formatPercentOneDecimalNoNull, formatTwoDecimalNoNull} from "../../../common/format";
import moment from 'moment';


export default ({portfolioLongShortRegressionStats}) => {

    let startDate = '';
    let endDate = '';

    if (portfolioLongShortRegressionStats.regression_summary.slope != null) {

        const dates = portfolioLongShortRegressionStats.points.map(p => p.standard_date);

        startDate = moment(dates[0]).format('MM/DD/YYYY');
        endDate = moment(dates[dates.length - 1]).format('MM/DD/YYYY');
    }

    return (
        <div>
            <Divider horizontal>Regression</Divider>
            <Table basic='very' collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            R2
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(portfolioLongShortRegressionStats.regression_summary.r2)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Correlation
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(portfolioLongShortRegressionStats.regression_summary.correlation)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Slope / Beta
                        </Table.Cell>
                        <Table.Cell>
                            {formatTwoDecimalNoNull(portfolioLongShortRegressionStats.regression_summary.slope)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Intercept
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(portfolioLongShortRegressionStats.regression_summary.intercept)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Start Date
                        </Table.Cell>
                        <Table.Cell>
                            {formatFullDate(startDate, true)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            End Date
                        </Table.Cell>
                        <Table.Cell>
                            {formatFullDate(endDate, true)}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>


        </div>
    )

}