import React, {useEffect, useState} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Loader, Statistic} from 'semantic-ui-react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../common/query/parse";
import {
    fetchDoradoBacktestPortfolio, fetchPortfolios,
} from "../../features/portfolio/portfolioFeatureSlice";
import {createSetFieldValueOnQuery, createSetFieldValuesOnQuery} from "../../common/query/add";

export const fieldSpecs = {
    portfolio_id: {
        parse: (v) => v == null ? null : parseInt(v)
    },
};


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        portfolios,
        loadingPortfolios,
    } = useSelector(state => state.portfolio, shallowEqual);

    const portfolioOptions = portfolios.map(p => {
        return {
            value: p.id,
            text: getPortfolioName(p),
            key: p.id
        }
    });

    return (

        <Form.Select
            fluid
            inline
            loading={loadingPortfolios}
            options={portfolioOptions}
            onChange={
                (e, d) => {
                    createSetFieldValuesOnQuery({
                        history,
                        searchProps,
                        changes: {
                            portfolio_id: d.value,
                            portfolio_state_id: null
                        }
                    })()
                }
            }
            value={searchProps.portfolio_id}
            label="Portfolio"
        />

    )

}


const getPortfolioName = (portfolio) => {

    if (!portfolio.is_backtest) {
        return portfolio.type
    }

    return 'Backtest | ' + portfolio.name

}