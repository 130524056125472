import React, {useEffect} from 'react';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import {Container, Grid} from 'semantic-ui-react'

import AdminMetadataTable from './AdminMetadata';
import {
    startDataImportProcess,
    startPointSurDoradoForecastAndValuation,
    startDoradoPortfolioBacktest,
    fetchValuationMetadata,
    startUpdatePortfolioFromTradeFiles,
    startPortfolioImportAndRefreshProcess,
    fetchQueueData,
    fetchPortfolioMetadata, fetchCompanyValidationSummaries
} from "../../features/adminFeatureSlice";


export default () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchValuationMetadata());
        dispatch(fetchPortfolioMetadata());
        dispatch(fetchCompanyValidationSummaries());
        dispatch(fetchQueueData());
    }, [dispatch]);

    const {profilingMetadata} = useSelector(state => state.valuationMetadataStore, shallowEqual);

    const {
        loadingImportStartedAcknowledgement,
        valuationMetadata,
        loadingValuationMetadata,
        portfolioMetadata,
        loadingPortfolioMetadata,
        companyValidationSummaries
    } = useSelector(state => state.admin, shallowEqual);

    return (
        <Container>
            <AdminMetadataTable valuationMetadata={valuationMetadata}
                                loadingValuationMetadata={loadingValuationMetadata}
                                profilingMetadatas={profilingMetadata}
                                portfolioMetadata={portfolioMetadata}

                                onStartProcess={() => dispatch(startDataImportProcess()).then(() => {
                                    return fetchQueueData({dispatch});
                                })}

                                onStartUpdatePortfolioFromTradeFiles={() => dispatch(startUpdatePortfolioFromTradeFiles()).then(() => {
                                    return fetchQueueData({dispatch});
                                })}
                                onStartUpdateGoldmanFTPAndPortfolio={() => dispatch(startPortfolioImportAndRefreshProcess()).then(() => {
                                    return fetchQueueData({dispatch});
                                })}
                                processing={loadingImportStartedAcknowledgement}
                                companyValidationSummaries={companyValidationSummaries}

            />

        </Container>
    )
}
