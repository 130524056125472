import React, {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Button, Container, Header, Icon, Label, Message, List} from 'semantic-ui-react'
import {
    fetchAkkrMetadata,
    startAkkrImportProcess,
    startAkkrImportCompanyDataProcess,
    clearAkkrBenchmarkMetadataRecords
} from '../../actions/akkr/akkrActions';

import moment from 'moment'
import {formatOneDecimalNoNull} from '../../common/format'
import {formatFullDateTimeUtcToLocal} from "../../common/formatDate";
import {fetchQueueData} from "../../actions/metadataActions";
import QueueTables from "../admin/QueueTables";


export default () => {

    const dispatch = useDispatch();

    useEffect(() => {
        // fetchAkkrMetadata({dispatch});
        fetchQueueData({dispatch});
    }, [dispatch]);

    const {processing} = useSelector(state => state.akkrStore, shallowEqual);
    const {queueData} = useSelector(state => state.valuationMetadataStore, shallowEqual);

    return (
        <Container>
            <MetadataTable onStartProcess={() => startAkkrImportProcess({dispatch}).then(() => {
                                return fetchQueueData({dispatch});
                            })}
                           onImportCompanyData={() => startAkkrImportCompanyDataProcess({dispatch}).then(() => {
                                return fetchQueueData({dispatch});
                            })}
                           processing={processing}
                           queueData={queueData}
            />
        </Container>
    )

}


const MetadataTable = ({
                           onStartProcess,
                           onImportCompanyData,
                           processing,
                           queueData
                       }) => {

    return (<div>
        <List>
            <List.Item>
                <Button icon labelPosition='left' onClick={onImportCompanyData} color={processing ? 'orange' : 'green'}>
                    <Icon name='refresh'/>
                    {processing ? 'Processing...' : 'Create Summary Excel File'}
                </Button>
                <p>
                    Imports company data without creating a PDF. The Portfolio Review summary data is saved to
                    Portfolio Review 2.0/Intermediate Output. Use this if you want to check your data before creating a
                    full PDF.
                </p>
            </List.Item>
            <List.Item>
                <Button icon labelPosition='left' onClick={onStartProcess} color={processing ? 'orange' : 'blue'}>
                    <Icon name='file pdf'/>
                    {processing ? 'Processing...' : 'Create Portfolio Review PDF'}
                </Button>
                <p>
                    Imports data and creates a pdf output, which is saved to the box folder "Portfolio Review 2.0".
                    Note this utilizes a PDF API which costs a small amount of money.
                </p>
            </List.Item>

        </List>


        <br/>

        <a href='https://accel-kkr.app.box.com/folder/80872412882/'>Open Portfolio Review Folder in Box</a>
        <br/>
        <p>
            Refresh this page to view the current status of the import process.
            If a process fails it will show up in the failed section.
            Contact Weston if you need it resolved.
        </p>
        <p>
            Only one job can run at a time. You can see which job is running below. Other jobs will be queued and run
            after the first one is complete.
        </p>
        <p>
            "Create Summary Excel File" should take 3-5 minutes to complete.  If it has been >10 minutes there could be an issue.
        </p>
        <p>
            "Create PDF" takes 10-15 minutes to complete.  If it has been >30 minutes there could be an issue.
        </p>

        <br/>

        <QueueTables queueData={queueData}/>

        <br/>
        <br/>

    </div>)

};
