import React, {Component} from 'react';
import {Container, Grid, Header, List, Table} from "semantic-ui-react";


export default () => {
    return (
        <Container>

            <Header as='h1'>Dorado Documentation</Header>

            <Table padded verticalAlign={'top'} basic={'very'}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>
                            Metric
                        </Table.HeaderCell>
                        <Table.HeaderCell width={6}>
                            Rationale
                        </Table.HeaderCell>
                        <Table.HeaderCell width={7}>
                            Calculation
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row verticalAlign={'top'}>
                        <Table.Cell>
                            <Header as='h4'>
                                Execution Score
                            </Header>
                        </Table.Cell>
                        <Table.Cell>
                            <p>
                                Execution Score is mean to reflect the sustainable operating performance of the
                                business. It
                                shouldn't vary significantly quarter-to-quarter unless there has been a fundamental
                                change in
                                the business. We use trailing LTM metrics to average out anomolous quarters and smooth
                                values.
                            </p>
                            <List bulleted>
                                <List.Item>
                                    For "growth", we want the best measure of sustainable value creation. This is more
                                    closely
                                    tied to recurring revenue (vs total revenue), and should be organic. If company is
                                    less than 50%
                                    recurring, or recurring growth is not sustainable (SaaS transition effects), we may
                                    want to make adjustments.
                                </List.Item>
                                <List.Item>
                                    For "margin", we use EBIT as it incorporates normalized capex (depreciation), and
                                    LTM
                                    to normalize for seasonality.
                                </List.Item>
                                <List.Item>
                                    For "dilution", we use a heavily averaged measure since grant rates year to year can
                                    be noisy.
                                </List.Item>
                                <List.Item>
                                    For "change in deferred", we want to give growing businesses the benefit of booked
                                    but not implemented
                                    revenue. The logic is that many expenses are associated with growth and
                                    should be matched with booked growth.
                                </List.Item>
                            </List>
                            <p>
                                We also apply an <b>excess dilution</b> penalty as follows:
                            </p>
                            <p>
                                If dilution is >3%, we argue that it is a worse problem than simply an offset to growth;
                                it is
                                probably a part of the cost structure and expected by employees as part of comp.
                                Therefore, we should
                                move some of the dilution into a $ cost equivalent, which will represent a more
                                significant penalty.
                            </p>
                            <p>
                                If a company were to need to buy back 1% of its stock to offset 1% dilution, this would
                                represent:

                            </p>
                            <List bulleted>
                                <List.Item>
                                    2% margin hit at 2x revenue
                                </List.Item>
                                <List.Item>
                                    4% margin hit at 4x revenue
                                </List.Item>
                                <List.Item>
                                    8% margin hit at 8x revenue
                                </List.Item>
                            </List>
                            <p>
                                We could make this calculation dynamic, but then historical execution score will be
                                dependent on historical
                                stock price. It will also overly penalize companies trading at high multiples (ie 8x
                                revenue). A compromise is to
                                uniformly <b>apply a 3% margin penalty to every 1% of dilution above 3%</b>.
                            </p>

                        </Table.Cell>
                        <Table.Cell>
                            <p>
                                Sum of:
                            </p>
                            <List bulleted>
                                <List.Item>
                                    <b>LTM Organic Recurring Revenue Growth</b> (4 quarters sum vs. prior 4 quarters
                                    sum). Preference for
                                    Actual ARR, then Imputed ARR, then recurring revenue.
                                </List.Item>
                                <List.Item>
                                    <b>LTM EBIT margin</b>
                                </List.Item>
                                <List.Item>
                                    <b>Normalized Share Grant Dilution %</b>. Average of the trailing 5 years of annual
                                    gross dilution (RSUs, RS, Options /3) granted, less a 20% standard forfeiture rate.
                                    If we have less than
                                    3 years of history (recent IPO), we blend in a default dilution rate of 3%. Note
                                    this value
                                    is diluted out of growth vs subtracted (ex: 10% growth, 2% dilution, calc = (1 + .1)
                                    / (1 + .02) - 1 = 7.8% net
                                </List.Item>
                                <List.Item>
                                    <b>LTM Change in Short Term Deferred Revenue:</b> Sum of the 4 quarters of change in
                                    ST deferred revenue
                                    divided by 4 quarter LTM sum of total revenue.
                                </List.Item>
                            </List>
                        </Table.Cell>

                    </Table.Row>

                    <Table.Row verticalAlign={'top'}>
                        <Table.Cell>
                            <Header as='h4'>
                                Valuation Premium
                            </Header>
                        </Table.Cell>
                        <Table.Cell>
                            <p>
                                Valuation premium is used as a way to supplement Dorado's valuation multiple methodology
                                for exceptionally low
                                risk companies. For us, low risk is defined as high probability attaining the exit
                                profile (max margin + growth).
                            </p>
                            <p>
                                We would expect a premium business to:
                            </p>

                            <List bulleted>
                                <List.Item>
                                    Have a current margin close to its forecasted exit max margin, ie not a lot of risk
                                    to attaining exit max margin.
                                </List.Item>
                                <List.Item>
                                    Have a current execution score above its max margin. Ie if the company trades growth
                                    for margin over time,
                                    it should easily scale into max margin.
                                </List.Item>
                                <List.Item>
                                    Have a current execution score above its forecasted execution score at exit. Ie our
                                    forecast of growth + max margin
                                    at exit seems conservative relative to current performance.
                                </List.Item>
                            </List>

                        </Table.Cell>
                        <Table.Cell>
                            <p>
                                Premium Score is 3 metrics multiplied together:
                            </p>
                            <List bulleted>
                                <List.Item>
                                    Current Margin % of Exit Max Margin
                                </List.Item>
                                <List.Item>
                                    Current Execution Score % of Exit Max Margin
                                </List.Item>
                                <List.Item>
                                    Current Execution Score % Exit Execution Score
                                </List.Item>
                            </List>
                            <p>
                                We begin applying a premium to companies that pass the following tests:
                            </p>
                            <List bulleted>
                                <List.Item>
                                    <b>Premium Score > .8</b>. Arbitrary threshold, but logic is this is the minimum
                                    situation
                                    where we would feel comfortable the exit state is very low risk:
                                    1) current margin is at least 80% of max margin,
                                    2) current execution score is 100% of max margin,
                                    3) current execution score is 100% of exit execution score
                                </List.Item>
                                <List.Item>
                                    <b>Current Margin is at least 75% of exit max margin or Current Margin > 30% and
                                        at least 65% of exit max margin</b>. Logic is that if there is a big lift to get
                                    to max margin,
                                    there is real risk regardless of the total value of the execution score. We lower
                                    the cut-in threshold for high margin businesses as they have more compelling
                                    evidence
                                    of low risk.
                                </List.Item>
                            </List>
                            <p>
                                <b>The premium is arbitrarily capped at 25%</b>. Logic is anything more extreme should
                                be more closely incorporated into the core dorado valuation algorithm as opposed to a
                                sub-module.
                            </p>
                            <p>
                                The premium is a function of the premium score. A score of 80% receives 0%, anything
                                above 180% receives
                                the full premium. In between, we use a parabolic interpolation of the value (it becomes
                                increasingly hard to
                                get a higher premium as the score approaches 180%).
                            </p>
                            <p>
                                We can <b>exclude</b> companies that mathematically should receive an execution score
                                but we do not
                                feel are low-risk enough to deserve it. Premium companies should be close to bond-like.
                                We exclude
                                Facebook, Google, and The Trade Desk due to revenue model and/or governance.
                            </p>

                        </Table.Cell>

                    </Table.Row>

                    <Table.Row verticalAlign={'top'}>
                        <Table.Cell>
                            <Header as='h4'>
                                605 Revenue Overrides
                            </Header>
                        </Table.Cell>
                        <Table.Cell>
                            <p>
                                Where the distortions caused by ASC 606 are significant, we want to override 606
                                accounting with
                                our best estimate of underlying economics. To do this, we want to override the correct
                                ASC 605
                                revenue as well as change in deferred revenue.
                            </p>

                        </Table.Cell>
                        <Table.Cell>
                            <List bulleted>
                                <List.Item>
                                    In the benchmark file, during an ASC 606 period, link into the output tab our best
                                    estimate of ASC 605 revenue. Link in reported change in deferred revenue (606), and
                                    "Change in Contract Assets".
                                </List.Item>
                                <List.Item>
                                    The "flag" to signal that we are overriding 606 revenue with 605 is if we link
                                    "Change in Contract Assets"
                                    in the benchmark file. If this is linked anywhere, we must also link this value for
                                    all 606 periods.
                                </List.Item>

                            </List>
                            <p>
                                Our calculation is as follows, using Alteryx 2019 as an example:
                            </p>
                            <List bulleted>
                                <List.Item>
                                    Begin with reported 606 revenue from CapIQ (417,910).
                                </List.Item>
                                <List.Item>
                                    Add 606 Change in Deferred Revenue (310).
                                </List.Item>
                                <List.Item>
                                    Add 606 reported "Change in Contract Assets" (-30,100).
                                    Contract Assets is revenue recognized that has not been billed. Also called unbilled
                                    receivables.
                                    Example 1: you recognize a 3 year term license in revenue under 606, but you only
                                    billed for 1 year.
                                    Example 2: Recognizing a future contracted price increase in the current period.
                                </List.Item>
                                <List.Item>
                                    The sum of these values reflects "Billings".
                                </List.Item>
                                <List.Item>
                                    Subtract our estimate of revenue on a 605 basis (329,630)
                                </List.Item>
                                <List.Item>
                                    To get estimated 605 change in deferred revenue (58,490)
                                </List.Item>
                            </List>
                        </Table.Cell>

                    </Table.Row>

                    <Table.Row verticalAlign={'top'}>
                        <Table.Cell>
                            <Header as='h4'>
                                Target Cash %
                            </Header>
                        </Table.Cell>
                        <Table.Cell>
                            <p>
                                Cash and Cash % is a fungible concept in a long-short portfolio. Unencumbered cash is
                                cash
                                left over after subtracting the market value of short positions from the total cash held
                                in
                                the account. If shorts appreciate, free/unencumbered cash goes down and can even go
                                negative.
                                Therefore targeting a specific cash % is challenging, as one may need to rebalance every
                                day.
                            </p>
                            <p>
                                Conceptually, if we have unencumbered cash, we will typically want to use it to buy long
                                positions.
                            </p>
                            <p>
                                However, if cash is close to zero and shorts outperform longs, unencumbered cash can go
                                negative. In order to
                                bring cash back to $0, we would be forced to sell longs every day this happens. Instead,
                                we should save the
                                short rebalancing for specific dates (either when it materially deviates from target or
                                once per month, etc.),
                                and otherwise behave as if cash is $0 (rebalance longs with an equal $ of longs and
                                shorts).
                            </p>
                        </Table.Cell>
                        <Table.Cell>
                            <p>
                                If <b>Target Cash %</b> is set:
                            </p>
                            <List bulleted>
                                <List.Item>
                                    Dorado will crea
                                </List.Item>
                                <List.Item>
                                    The "flag" to signal that we are overriding 606 revenue with 605 is if we link
                                    "Change in Contract Assets"
                                    in the benchmark file. If this is linked anywhere, we must also link this value for
                                    all 606 periods.
                                </List.Item>
                            </List>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row verticalAlign={'top'}>
                        <Table.Cell>
                            <Header as='h4'>
                                Dorado Universe
                            </Header>
                        </Table.Cell>
                        <Table.Cell>
                            <p>
                                Companies in the Dorado Universe are a subset of companies that we cover. If we believe
                                they are predictable enough
                                and alpha-generating enough, we can add a company to the Dorado universe. Reasons for
                                adding a company to Dorado
                                are:
                            </p>
                            <List ordered>
                                <List.Item>
                                    Alpha-generating long potential (avg. to great business, predictable, with growth or
                                    cash flow). Doesn't have to be a long today but
                                    if it were cheap enough for Dorado to recommend it we would feel similar.
                                </List.Item>
                                <List.Item>
                                    Large Index Weight and reasonably predictable (want Dorado to opine even if ends up
                                    zero weight)
                                </List.Item>
                                <List.Item>
                                    Liquid, predictable bad business short. Liquid, as we want them to act as a true
                                    hedge to our longs (correlated).
                                    Predictable as Dorado cannot properly risk downside optionality of low recurring
                                    businesses and valuation will
                                    only reflect our biased forecasts.
                                </List.Item>
                            </List>
                            <br/>
                            <p>
                                Add the <b>Evaluation Only</b> flag to see a company's Dorado valuation but don't
                                include in backtest or
                                in our current portfolio. Useful to test a new company before adding full-time, as well
                                as to test
                                an out-of-universe company that we have in the portfolio (Class, Agilysis, Gaia, etc.).
                            </p>

                            <Header as={"h4"}>Future Concepts</Header>
                            <p>
                                We can consider two additional Dorado types: 1) long-only unpredictable. Provide Dorado
                                an extremely conservative forecast, if it is
                                still a buy at those levels it could be interesting. 2) short-only unpredictable.
                                Provide a best-case short forecast, if it is still
                                a short Dorado can include it in the basket.
                            </p>

                            <p>
                                We should also remain aware of reasons to exclude a company from Dorado. Dorado doesn't
                                value upside or downside optionality.
                                We don't want to be short a stock with unpriced upside optionality or long a stock with
                                downside optionality.

                                If the optionality is one-directional, we might want to include in Dorado but never take
                                the other side of the trade.
                                Ex, we will never short Google even if the numbers would indicate its overpriced.
                            </p>
                            <List as={'ul'}>
                                <List.Item as={'li'}>
                                    Never long (downside optionality): Massive customer concentration
                                </List.Item>
                                <List.Item as={'li'}>
                                    Never short (upside optionality): Unmonetized IP/R&D (Google, Facebook)
                                </List.Item>
                            </List>

                        </Table.Cell>
                    </Table.Row>


                </Table.Body>
            </Table>
        </Container>
    )
}