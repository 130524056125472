import {
    getTotalImputedArrBridge,
    getArrCompositionBridges,
    getBalanceSheetRows,
    getCashFlowRows,
    getDilutionAnalysisRows,
    getGrossMarginRows,
    getGrossProfitAndExpenseRows,
    getMarginRows,
    getRevenueCompositionAndTotalDollarGrowthRows,
    getRevenueCompositionAndTotalGrowthRows,
    getRevenueCompositionAndTotalRows,
    getShareComponents, getExecutionAssessmentRows
} from "../../../../components/companyFinancials/components";
import {getCostDetailType} from "../../../../components/companyValuation/financials/common";
import {
    cellWidth,
    createSectionHeaderRow,
    emptyRow,
    headerRowHeight, rowHeaderWidth,
    rowHeight
} from "../../../../components/companyFinancials/common";
import {getTableClassName} from "../../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {getWindowHeightLessMenuBar} from "../../../../common/common";
import React from "react";
import {ModelRowRenderer} from "../../../../components/rdg/rdgComponents";
import {createRow, HeaderContentRenderer, LeftAlignHeaderRenderer} from "../../../../components/rdg/helpers";
import {
    formatFullDate,
    formatNoDecimalNoNull,
    formatNoDecimalNoNullOrZero,
    formatPercentOneDecimalNoNull
} from "../../../../common/format";
import {createHeaderRow} from "../../historicalFinancials/common";
import {getClassToColorRevenue} from "../../../../common/colors";


const financialsCellWidth = 90;


const entryLTMLabel = 'Entry LTM';

const labelSpecs = [
    {name: entryLTMLabel, width: financialsCellWidth},
    {name: 'Entry NTM', width: financialsCellWidth},
    {name: '--->', width: 40},
    {name: 'Exit LTM', width: financialsCellWidth},
    {name: 'Exit NTM', width: financialsCellWidth},
    {name: ' --->', width: 40},
    {name: 'Max Margin', width: financialsCellWidth},
];


const columnHeaderFieldName = 'label';


export default ({financialRecords, loading}) => {

    const labelRow = [
        {
            key: 'rowHeader',
            name: '',
            width: rowHeaderWidth,
            frozen: true,
            headerRenderer: <LeftAlignHeaderRenderer/>
        }
    ].concat(
        labelSpecs.map(spec => {
            return {
                key: spec.name,
                name: spec.name,
                width: spec.width,
                headerRenderer: <HeaderContentRenderer/>
            }

        })
    );

    const dataRows = [
        emptyRow(),
        createRow({
            headerText: 'Period End',
            records: financialRecords,
            getCellValue: record => record.period_end_date,
            formatValue: formatFullDate,
            columnHeaderFieldName
        }),
        emptyRow(),
        ...getRevenueCompositionAndTotalRows({financialRecords, columnHeaderFieldName}),
        createRow({
            headerText: 'Revenue Growth',
            records: financialRecords,
            getCellValue: record => record.revenue_growth,
            indent: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Recurring Growth',
            records: financialRecords,
            getCellValue: record => record.revenue_recurring_growth,
            indent: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: '% Recurring',
            records: financialRecords,
            getCellValue: record => record.revenue_recurring_percent,
            indent: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
        createRow({
            headerText: 'EBITDA',
            records: financialRecords,
            getCellValue: record => record.ebitda,
            underline: false,
            getCellClassName: getClassToColorRevenue,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'EBIT',
            records: financialRecords,
            getCellValue: record => record.ebit,
            underline: false,
            getCellClassName: getClassToColorRevenue,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Pretax FCF',
            records: financialRecords,
            getCellValue: record => record.pretax_free_cash_flow,
            underline: false,
            getCellClassName: getClassToColorRevenue,
            columnHeaderFieldName
        }),


        emptyRow(),
        ...getMarginRows({financialRecords, columnHeaderFieldName}),
        emptyRow(),
        ...getGrossMarginRows({financialRecords, columnHeaderFieldName}),

        // createHeaderRow({headerText: 'Adj. Rule-of-40'}),
        // createRow({
        //     headerText: 'Organic YoY Growth',
        //     records: financialRecords,
        //     getCellValue: record => {
        //
        //         if (record.label === entryLTMLabel) {
        //             return record.ltm_reported_arr_organic_growth
        //         }
        //
        //         return record.arr_organic_growth
        //     },
        //     formatValue: formatPercentOneDecimalNoNull,
        //     indent: true,
        //     columnHeaderFieldName
        // }),
        // createRow({
        //     headerText: 'Net Share Dilution %',
        //     records: financialRecords,
        //     getCellValue: record => {
        //
        //         return record.total_net_equivalent_share_grant_dilution_percent
        //     },
        //     formatValue: formatPercentOneDecimalNoNull,
        //     indent: true,
        //     columnHeaderFieldName
        // }),
        // createRow({
        //     headerText: 'EBIT %',
        //     records: financialRecords,
        //     getCellValue: record => {
        //
        //         if (record.label === entryLTMLabel) {
        //             return record.ltm_reported_ebit_margin
        //         }
        //
        //         return record.ebit_margin
        //     },
        //     formatValue: formatPercentOneDecimalNoNull,
        //     indent: true,
        //     columnHeaderFieldName
        // }),
        //
        // createRow({
        //     headerText: 'EBIT % + Dil. Org. ARR Growth',
        //     records: financialRecords,
        //     getCellValue: record => {
        //         if (record.label === entryLTMLabel) {
        //             return record.ltm_reported_dil_org_ebit_margin_plus_arr_growth
        //         }
        //
        //         return record.ebit_margin_plus_organic_arr_growth
        //     },
        //     formatValue: formatPercentOneDecimalNoNull,
        //     underline: true,
        //     columnHeaderFieldName
        // }),

        createSectionHeaderRow({headerText: 'ARR'}),
        emptyRow(),
        createHeaderRow({headerText: 'Imputed ARR Bridge'}),
        createRow({
            headerText: 'Beginning ARR',
            records: financialRecords,
            getCellValue: record => record.arr_beginning,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName

        }),
        createRow({
            headerText: 'Implemented ARR',
            records: financialRecords,
            getCellValue: record => record.arr_implemented,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Acquired ARR',
            records: financialRecords,
            getCellValue: record => record.arr_acquired,
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Gross Churn',
            records: financialRecords,
            getCellValue: record => record.arr_gross_churn_dollars,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Ending ARR',
            records: financialRecords,
            getCellValue: record => record.arr_ending,
            formatValue: formatNoDecimalNoNullOrZero,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Gross Retention %',
            records: financialRecords,
            getCellValue: record => record.arr_gross_retention,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Organic YoY Growth',
            records: financialRecords,
            getCellValue: record => record.arr_organic_growth,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),

        emptyRow(),
        createSectionHeaderRow({headerText: 'Balance Sheet'}),
        emptyRow(),
        createRow({
            headerText: 'Cash',
            records: financialRecords,
            getCellValue: record => record.cash_and_short_term_investments,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Long Term Investments',
            records: financialRecords,
            getCellValue: record => record.long_term_investments,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Preferred Stock',
            records: financialRecords,
            getCellValue: record => record.preferred_stock,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Pension',
            records: financialRecords,
            getCellValue: record => record.pension_liability,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Minority Interest',
            records: financialRecords,
            getCellValue: record => record.minority_interest,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Convertible Debt',
            records: financialRecords,
            getCellValue: record => record.convertible_debt,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Non-Convertible Debt',
            records: financialRecords,
            getCellValue: record => record.non_convertible_debt,
            indent: true,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Total Debt',
            records: financialRecords,
            getCellValue: record => record.total_debt,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),

        emptyRow(),
        createSectionHeaderRow({headerText: 'Shares'}),
        emptyRow(),
        createRow({
            headerText: 'Basic Shares',
            records: financialRecords,
            getCellValue: record => record.shares_ending,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Unvested RSUs',
            records: financialRecords,
            getCellValue: record => record.unvested_rsus_ending,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Options',
            records: financialRecords,
            getCellValue: record => record.options_ending,
            columnHeaderFieldName
        }),
        emptyRow(),
        ...getExecutionAssessmentRows({financialRecords, columnHeaderFieldName}),
        emptyRow(),
        emptyRow()


    ];

    return (
        <div className={getTableClassName(loading) + ' align-right'}>

            <ReactDataGrid
                columns={labelRow}
                rowGetter={index => dataRows[index]}
                rowsCount={dataRows.length}
                headerRowHeight={headerRowHeight}
                rowHeight={rowHeight}
                minHeight={getWindowHeightLessMenuBar()}
                rowRenderer={ModelRowRenderer}
            />

        </div>
    )

}