import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Container, Form, Grid} from 'semantic-ui-react'
import {fetchCompany} from '../../../../actions/companyActions';
import {useReloadOnChange} from "../../../../common/hooks";
import CompanyHeader from "../../../../components/CompanyHeader";
import {
    fetchDoradoAuditRecordsForCompany
} from "../../../../actions/dorado/doradoCompanyActions";
import {
    fetchDoradoForecastMetadatasForCompany
} from "../../../../features/dorado/doradoCompanyDetail";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import {formatAbsoluteDateString} from "../../../../common/formatDate";
import AuditTable from "./AuditTable";
import {fieldSpecs} from "./common";
import {createAddValueToQuery} from "../../../../common/query/add";
import TermFilter from "../../../../components/filter/TermFilter";


const auditFieldOptions = [
    {
        'key': 'revenue_total_percent_error',
        'text': 'Revenue Forecast % of Actual',
        'value': 'revenue_total_percent_error'
    },
    {
        'key': 'revenue_recurring_percent_error',
        'text': 'Recurring Revenue Forecast % of Actual',
        'value': 'revenue_recurring_percent_error'
    },
];


export default () => {

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: [],
        matchFields: ['id'],
        requestData: requestAuditData
    });


    const {
        company,
        loadingCompany,
        doradoAuditRecords,
        loadingDoradoAuditRecords
    } = useSelector(state => state.companyStore, shallowEqual);

    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});


    return (
        <Container fluid>
            <Grid padded>

                <Grid.Column width={4}>
                    <Grid.Row>
                        <Grid>
                            <Grid.Column width={14}>
                                <CompanyHeader company={company} loading={loadingCompany}/>
                            </Grid.Column>
                        </Grid>
                    </Grid.Row>
                    <Grid.Row>
                        <br/>
                        <Form>
                            <TermFilter allowNullSelection={false}
                                        label='Field'
                                        options={auditFieldOptions}
                                        value={searchProps.auditField}
                                        onChange={createAddValueToQuery({
                                            history,
                                            searchProps,
                                            fieldName: 'auditField'
                                        })}
                            />

                        </Form>

                    </Grid.Row>

                </Grid.Column>
                <Grid.Column width={12}>
                    <AuditTable/>


                </Grid.Column>
            </Grid>
        </Container>
    )

}


const requestAuditData = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    fetchCompany({dispatch, id});
    fetchDoradoAuditRecordsForCompany({dispatch, companyId: id});

};
