import {ReportPeriodType} from "../../../common/constants";


export const periodTypeOptions = [
    {
        key: ReportPeriodType.monthly,
        value: ReportPeriodType.monthly,
        text: ReportPeriodType.monthly
    },
    {
        key: ReportPeriodType.annual,
        value: ReportPeriodType.annual,
        text: ReportPeriodType.annual
    },
    {
        key: ReportPeriodType.cumulative,
        value: ReportPeriodType.cumulative,
        text: ReportPeriodType.cumulative
    },
    {
        key: ReportPeriodType.custom,
        value: ReportPeriodType.custom,
        text: ReportPeriodType.custom
    },
]



export const fieldSpecs = {
    portfolio_period_report_id: {
        parse: (v) => (v === "" || v == null) ? null : parseInt(v)
    },
    periodType: {
        parse: (v) => v == null ? periodTypeOptions[0].value : v
    }
};