import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
const convertibleDebtService = client.service('convertible_debt');


const debtFeatureSlice = createSlice({
    name: 'debt',
    initialState: getInitialStateWithLoading({
        convertibleDebtRecords: [],
        convertibleDebtRecord: {}
    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchConvertibleDebtForCompany', destinationVariable: 'convertibleDebtRecords'}),
        ...makeStandardReducers({prefix: 'patchConvertibleDebtRecord', destinationVariable: 'convertibleDebtRecord'}),
    }
});





export const fetchConvertibleDebtForCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: debtFeatureSlice,
        prefix: 'fetchConvertibleDebtForCompany',
        getPayload: () => convertibleDebtService.find({
            query: {
                company_id: companyId,
                $sort: {
                    issue_date: -1
                }
            }
        })
    })
};


export const patchConvertibleDebtRecord = ({id, patchData}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: debtFeatureSlice,
        prefix: 'patchConvertibleDebtRecord',
        getPayload: () => {return convertibleDebtService.patch(id, patchData)}
    })
};



export default debtFeatureSlice.reducer