import React from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {
    boolOptions,
    getRdgRowsFromRecords,
} from "../../../common/reactDataGrid/common";
import ReactDataGrid from 'react-data-grid';
import {getWindowHeightLessMenuBar} from '../../../common/common'
import {useHistory, useLocation} from "react-router-dom";
import {getTableClassName} from '../../../common/reactDataGrid/common'

import {
    StringFormatter,
    DateFormatter,
    NoDecimalNoNullOrZeroFormatter,
    SharePriceFormatter,
    PercentNoDecimalNoNullFormatter,
    PercentOneDecimalNoNullFormatter,
    BoolToNoFlagFormatter, NoDecimalNoNullFormatter, BoolToYesNoCheckFormatter
} from "../../../common/reactDataGrid/formatters";

import {Editors} from 'react-data-grid-addons';

const {DropDownEditor} = Editors;




export const columns = [
    {
        key: 'id',
        name: 'ID',
        width: 80
    },

    {
        key: 'announce_date',
        name: 'Announce Date',
        width: 90,
        formatter: DateFormatter,
        editable: true
    },
    {
        key: 'close_date',
        name: 'Close Date',
        width: 90,
        formatter: DateFormatter,
        editable: true
    },
    {
        key: 'type',
        name: 'Type',
        width: 100,
        editable: true
    },
    {
        key: 'target_name',
        name: 'Target Name',
        width: 300,
    },
    {
        key: 'proforma_in_dorado_forecasts',
        name: 'Pro-forma in Dorado Forecasts?',
        width: 70,
        formatter: BoolToYesNoCheckFormatter,
        editor: <DropDownEditor options={boolOptions}/>,
        editable: true
    },
    {
        key: 'shares_issued',
        name: 'Shares Issued (thousands), including RSUs assumed',
        width: 120,
        editable: true,
        formatter: NoDecimalNoNullFormatter
    },
    {
        key: 'cash_component',
        name: 'Cash Component (thousands, net of cash assumed)',
        width: 120,
        editable: true,
        formatter: NoDecimalNoNullFormatter
    },
    {
        key: 'debt_assumed',
        name: 'Debt Assumed (thousands)',
        width: 90,
        editable: true,
        formatter: NoDecimalNoNullFormatter
    },
    {
        key: 'ciq_transaction_id',
        name: 'CIQ Transaction ID',
        width: 130,
        editable: true
    },
    {
        key: 'target_ciq_id',
        name: 'Target CIQ ID',
        width: 130,
        editable: true
    },
    {
        key: 'ciq_transaction_amount',
        name: 'CIQ Transaction Amount',
        width: 90,
        formatter: NoDecimalNoNullOrZeroFormatter
    },



];