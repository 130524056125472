import React, {useEffect} from 'react';
import {
    CompanyLinkFormatter,
    PercentOneDecimalNoNullFormatter,
    PricePercentChangeOneDecimalFormatter,
    SharePriceFormatter,
    UtcToLocalFullDateTimeFormatter,
    NoDecimalNoNullOrZeroFormatter,
    OneDecimalNoNullFormatter,
    TwoDecimalNoNullFormatter,
    ThreeDecimalNoNullFormatter,
    NoDecimalNoNullFormatter, MultipleOneDecimalFormatter
} from "../../../common/reactDataGrid/formatters";
import {CurrencySharePriceFormatter} from "../../../common/columns";
import {
    changeFromLastClosePriceCol, dailyDollarVolumeCol, dailyVolumeCol, exchangeIsOpenCol,
    lastTradeDateCol,
    median3moDollarVolumeCol,
    median3moVolumeCol
} from "../commonColumns";
import {sortFunctional} from "../../../common/tableHelpers";
import {parseBoolean} from "../../../common/query/parse";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";


export const orderedIndexIds = [
    1,  // spy
    2,  // IGV
    8,  // WisdomTree Cloud
    3,  // IWM / Russell 2000
    6,  // Russell 1000 Value
    7,  // Russell 2000 Value
]


export const getEtfSortKey = (id) => {
    return orderedIndexIds.indexOf(id)
}

export const fieldSpecs = {
    showAlternativeListings: {
        parse: parseBoolean
    }
};



export const columns = [
    CompanyLinkFromSecurityListing,

    {
        key: 'last_price',
        name: 'Current $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.last_price,
                trading_currency: r.trading_currency
            }
        }
    },
    {
        key: 'vwap',
        name: 'VWAP $/share (15min delay)',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.vwap,
                trading_currency: r.trading_currency
            }
        }
    },
    changeFromLastClosePriceCol,
    dailyDollarVolumeCol,
    dailyVolumeCol,
    {
        key: 'iex_volume',
        name: 'IEX Volume',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 80,
    },


    {
        key: 'irr',
        name: 'Expected IRR',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'no_multiple_expansion_irr',
        name: 'No Multiple Expansion IRR',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'no_multiple_change_irr',
        name: 'No Multiple Change IRR',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },

    lastTradeDateCol,
    {
        key: 'price_source',
        name: 'Source',
        width: 130,
        sortable: true,
    },
    exchangeIsOpenCol,


    {
        key: 'execution_score',
        name: 'Execution Score',
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'entry_ev_to_revenue_total_ntm',
        name: 'Entry EV/NTM Revenue',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },
    {
        key: 'last_close_price',
        name: 'Last Close $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.last_close_price,
                trading_currency: r.trading_currency
            }
        }
    },
    {
        key: 'volume_percent_of_median',
        name: 'Volume Multiple of Median',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },
    median3moDollarVolumeCol,
    median3moVolumeCol,
    {
        key: 'trailing_market_value_usd',
        name: 'Trailing Market Cap. $USD',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 80,
    },

    {
        key: 'entry_ev_to_pretax_fcf_ntm',
        name: 'Entry EV/NTM Pretax FCF',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },
    {
        key: 'entry_ev_to_maximized_pretax_fcf_ntm',
        name: 'Entry EV/NTM Max FCF',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80,
    },
    {
        key: 'total_gain',
        name: 'Expected Gain',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },
];


export const getTopAndBottomReturns = ({doradoRealTimeValuationRecords: realTimeDoradoValuationRecords}) => {

    realTimeDoradoValuationRecords = realTimeDoradoValuationRecords.filter(r => r.last_price != null && r.price_change_from_last_close != null)

    realTimeDoradoValuationRecords = sortFunctional({
        array: realTimeDoradoValuationRecords,
        sortKey: 'price_change_from_last_close',
        sortDirection: -1
    });

    const lastRecords = realTimeDoradoValuationRecords.slice(Math.max(realTimeDoradoValuationRecords.length -5, 0));
    const firstRecords = realTimeDoradoValuationRecords.slice(0, 5);

    const combinedRecords = firstRecords.concat(lastRecords).filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
    })

    return combinedRecords
}



