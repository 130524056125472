import {PeriodType} from "../../../common/constants";
import {parseBoolean} from "../../../common/query/parse";

export const fieldSpecs = {
    'currencyType': {
        parse: (value) => {
            if (value) {
                return value
            }
            return 'Filing'
        }
    },
    // 'periodType': {
    //     parse: (value) => {
    //         if (value) {
    //             return value
    //         }
    //         return PeriodType.annual
    //     }
    // },
    // 'isLtm': {
    //     parse: parseBoolean
    // },
    'periodOption': {
        parse: (value) => {
            if (value) {
                return value
            }
            return 'Annual'
        }
    }
};

export const periodOptions = {
    'Annual': {
        isLtm: false,
        periodType: PeriodType.annual
    },
    'Monthly': {
        isLtm: false,
        periodType: PeriodType.monthly
    },
    'Quarterly': {
        isLtm: false,
        periodType: PeriodType.quarterly
    },
    'LTM Annual': {
        isLtm: true,
        periodType: PeriodType.annual
    },
    'LTM Quarterly': {
        isLtm: true,
        periodType: PeriodType.quarterly
    }
};