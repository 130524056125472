
export const columnGroups = [
    {
        name: 'Revenue',
        columns: [
            'short_name',
            'currency_type',
            'is_calendarized',
            'period_end_date',
            'fiscal_period_end',

            'revenue_perpetual_license',
            'revenue_term_license',
            'revenue_maintenance',
            'revenue_subscription',
            'revenue_recurring_transactional',
            'revenue_service',
            'revenue_hardware',
            'revenue_other',
            'revenue_total'
        ]
    },
    {
        name: 'Sales Efficiency',
        columns: [
            'short_name',
            'period_end_date',
            'fiscal_period_end',
            'currency_type',
            'is_calendarized',



            'bookings_to_sales_and_marketing',
            'bookings_recurring_to_sales_and_marketing',

            'bookings_total',
            'bookings_recurring',

            'sales_and_marketing',

            'sales_and_marketing_margin',
            'revenue_growth',
            'revenue_dollar_growth',
            'revenue_recurring_growth',
            'revenue_recurring_dollar_growth',


        ]
    }
];
