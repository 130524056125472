import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Grid, Button, Statistic, Icon, Divider} from 'semantic-ui-react'
import {fetchAkkrTableRecords, downloadAkkrDataRecords} from '../../../actions/akkr/dataActions';
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import AkkrDataTable from './AkkrDataTable'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import SidebarFilters from "./SidebarFilters"
import {parseSearchProps} from "../../../common/query/parse";
import {useLocation} from "react-router-dom";
import ShowHideColumnsComponent from '../../../components/showHideColumns/ShowHideColumnsComponent'
import {columnGroups} from './columnGroups'


export default () => {

    const dispatch = useDispatch();

    const records = useSelector(state => state.akkrTableStore.records);

    const location = useLocation();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs: {}});

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={records.length} label='Records' size="tiny"/>
                </Grid.Row>
                <Grid.Row>
                    <Button basic color='blue' onClick={() => executeDownloadAkkrDataRecords({dispatch, searchProps})}>
                        <Icon name='download' />
                        Download
                    </Button>
                </Grid.Row>

            </Grid>
            <ShowHideColumnsComponent columnGroups={columnGroups}/>
            <Divider horizontal> Filters </Divider>
            <SidebarFilters/>
        </div>
    );


    return (
        <ViewWithSidebar mainView={<AkkrDataTable/>} sidebarView={sidebar}/>
    )


};

const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });
    fetchAkkrTableRecords({dispatch, sort, filters});
};

const executeDownloadAkkrDataRecords = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });
    downloadAkkrDataRecords({dispatch, sort, filters});

};
