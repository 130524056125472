import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {Button, Grid, Header, Icon, Statistic, Table, Divider} from "semantic-ui-react";
import React from "react";
import {
    formatFullDateTimeUtcToLocal,
    formatDateTimeUtcToLocal,
    formatAbsoluteDateString, formatSimpleDate
} from "../../../common/formatDate";
import {
    formatBooleanYesNo,
    formatPercentOneDecimalNoNull,
    formatOneDecimalNoNull,
    formatPercentFourDecimalNoNull, formatNoDecimalNoNull, formatTwoDecimalNoNull
} from "../../../common/format";


export default ({doradoPortfolio, loading}) => {

    const report = doradoPortfolio.dorado_portfolio_report || {};

    return (
        <React.Fragment>
            <Divider horizontal>Tax Detail</Divider>
            <Table compact collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Tax Summary
                        </Table.HeaderCell>
                        <Table.HeaderCell/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Realized ST Gain Inefficiency (delta vs LT)
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(report.realized_short_term_tax_inefficiency)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Total Gain less ST Tax Inefficiency
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(report.total_gain_less_realized_short_term_tax_inefficiency)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Total Pretax Return
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(report.indexed_return)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Total Return less ST Tax Inefficiency
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(report.indexed_return_less_realized_short_term_tax_inefficiency)}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Table compact collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Pre-Tax</Table.HeaderCell>
                        <Table.HeaderCell>Tax $</Table.HeaderCell>
                        <Table.HeaderCell>After-Tax</Table.HeaderCell>
                        <Table.HeaderCell>Tax Rate</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Total Gain $</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.total_gain)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.total_gain_tax)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.total_gain_after_tax)}</Table.Cell>
                        <Table.Cell>{formatPercentOneDecimalNoNull(report.total_gain_tax_rate)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Realized Short Term Gain</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.realized_short_term_gain)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.realized_short_term_gain_tax)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.realized_short_term_gain_after_tax)}</Table.Cell>
                        <Table.Cell>{formatPercentOneDecimalNoNull(report.realized_short_term_gain_tax_rate)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Realized Long Term Gain</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.realized_long_term_gain)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.realized_long_term_gain_tax)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.realized_long_term_gain_after_tax)}</Table.Cell>
                        <Table.Cell>{formatPercentOneDecimalNoNull(report.realized_long_term_gain_tax_rate)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Unrealized Short Term Gain</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.unrealized_short_term_gain)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.unrealized_short_term_gain_tax)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.unrealized_short_term_gain_after_tax)}</Table.Cell>
                        <Table.Cell>{formatPercentOneDecimalNoNull(report.unrealized_short_term_gain_tax_rate)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Unrealized Long Term Gain</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.unrealized_long_term_gain)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.unrealized_long_term_gain_tax)}</Table.Cell>
                        <Table.Cell>{formatNoDecimalNoNull(report.unrealized_long_term_gain_after_tax)}</Table.Cell>
                        <Table.Cell>{formatPercentOneDecimalNoNull(report.unrealized_long_term_gain_tax_rate)}</Table.Cell>
                    </Table.Row>


                </Table.Body>
            </Table>
        </React.Fragment>


    )

}