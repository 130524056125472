import React from 'react';
import { shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Search, Button, Icon} from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import {useHistory, useLocation} from "react-router-dom";
import {
    changeCompanySearchValue,
    clearCompanySearchResults,
    searchForCompany
} from "../features/companySearchFeatureSlice";


export default () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const {searchResults, loadingSearchResults, searchText} = useSelector(state => state.companySearch, shallowEqual);

    let results = searchResults.map(result => {
        let html = null;
        if (result.highlight && result.highlight.company_name_and_ticker.length > 0) {

            // title is an html string
            html = result.highlight.company_name_and_ticker[0];

            html = html.split('<em>').join('<b>');
            html = html.split('</em>').join('</b>');
            // title = title.replace('/<em>/g', '<b>');
            // title = title.replace('/</em>/g', '</b>');
            html = ReactHtmlParser(html);

        } else {
            html = ReactHtmlParser(result._source.company_name_and_ticker);
        }
        html = html.map((t, i) => {
            return <span key={i}>{t}</span>
        });

        // title must be one element to avoid bugs
        html = (
            <span key={result._id}>
                <Button icon basic size='small' onClick={(e, d) => {
                    history.push("/company_detail/notes/" + result._id);
                }} floated='right'>
                    <Icon name='sticky note'/>
                </Button>
                <span>{html}</span>
            </span>);
        return {
            id: result._id,
            title: result._id,
            html: html
        }

    });

    const goToCompanyPage = (event, data) => {

        const id = data.result.id;
        dispatch(clearCompanySearchResults());
        const tagName = event.target.tagName;

        // if button or Icon, then we are going to the companyFinancials notes page
        if (data.value != null) {
            if (tagName !== 'BUTTON' && tagName !== 'I') {
                history.push("/company_detail/overview/" + id);
            }
        }

    };

    const resultRenderer = ({html, id}) => <div key={id}>{html}</div>;

    const handleSearchChange = (e, {value}) => {

        dispatch(changeCompanySearchValue({text: value}));
        dispatch(searchForCompany({text: value}));
    };

    return (
        <Search
            loading={loadingSearchResults}
            onSearchChange={handleSearchChange}
            value={searchText}
            results={results}
            onResultSelect={goToCompanyPage}
            resultRenderer={resultRenderer}
            minCharacters={0}
        />
    )

}
