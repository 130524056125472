import React, { Component } from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'


export default class DeleteModalButton extends Component {
  state = { modalOpen: false }

  handleOpen = (e) => this.setState({
    modalOpen: true,
  })

  handleClose = (e) => this.setState({
    modalOpen: false,
  })

  render() {
    const buttonText = this.props.buttonText || 'Delete';
    const size = this.props.size || 'mini';

    const onYesClick = (e, d) => {
      this.props.onDelete(e, d);
      this.handleClose(e);
    };

    return (
      <Modal
        trigger={<Button basic color='red' size={size} onClick={this.handleOpen}>{buttonText}</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        basic
        size='small'
      >
        <Header content={this.props.headerContent} />
        <Modal.Content>
          <p>{this.props.content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='grey' inverted onClick={this.handleClose}>
            <Icon name='remove' /> No
          </Button>
          <Button color='red' inverted onClick={onYesClick}>
            <Icon name='checkmark' /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
