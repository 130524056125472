import moment from "moment-timezone";
import {isUndefinedOrNull} from "./common";



export const formatFullDateTimeUtcToLocal = (utcDateString) => {
    // take a UTC date and time, and format to local

    if (isUndefinedOrNull(utcDateString)) {
        return ''
    }

    return moment.utc(utcDateString).local().format('M/D/YYYY, hh:mm a')
};


export const formatAbbreviatedDateTimeUtcToLocal = (utcDateString) => {
    // take a UTC date and time, and format to local

    if (isUndefinedOrNull(utcDateString)) {
        return ''
    }

    return moment.utc(utcDateString).local().format('hh:mm a M/D')
};



export const formatDateTimeUtcToLocal = (utcDateString) => {
    // take a UTC date and time, and format to local

    if (isUndefinedOrNull(utcDateString)) {
        return ''
    }
    return moment.utc(utcDateString).local().format('M/D/YYYY')
};


export const formatAbsoluteDateAsMonthYear = (value) => {
    // take an absolute date, like period end, convert to m/yy

    return formatAbsoluteDateString({dateString: value, format: 'M/YY'})
};



export const formatAbsoluteDateString = function ({dateString, format}) {

    if (isUndefinedOrNull(dateString)) {
        return ''
    }

    let d = moment(dateString);

    if (d.isValid()) {
        return d.format(format)
    } else {
        return ''
    }
};

export const formatSimpleDate = (d) => {
    return formatAbsoluteDateString({dateString: d, format: 'M/D/YYYY'})
};