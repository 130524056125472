import {useEffect, useRef} from "react";
import {searchPropsOrMatchChanged} from "./request/newData";
import {useDispatch} from "react-redux";
import {parseSearchProps} from "./query/parse";
import {useLocation, useParams} from "react-router-dom";
import deepEqual from 'deep-equal';


export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useCompareUrlState = ({searchProps, match, queryMatchFields}) => {
    const prevSearchProps = usePrevious(searchProps);
    const prevMatch = usePrevious(match);

    return searchPropsOrMatchChanged({
        lastSearchProps: prevSearchProps,
        searchProps,
        lastMatchParams: prevMatch,
        matchParams: match,
        queryMatchFields: queryMatchFields
    })

};

export const useReloadOnChange = ({fieldSpecs, queryMatchFields, matchFields, requestData, stateValues}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const matchParams = useParams();

    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const prevStateValues = usePrevious(stateValues);

    let shouldReload = useCompareUrlState({
        searchProps,
        match: matchParams,
        queryMatchFields
    });

    if (stateValues !== undefined && !deepEqual(stateValues, prevStateValues)) {
        shouldReload = true;
    }

    useEffect(() => {
        if (shouldReload) {
            console.log('Change event');
            requestData({searchProps, matchParams, dispatch});
        }
    });

};


