import React from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {parseSearchProps} from "../../../../common/query/parse";
import {getRdgRowsFromRecords, getTableClassName} from "../../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {getWindowHeightLessMenuBar} from "../../../../common/common";
import {
    BoolToNoFlagFormatter,
    UtcDateFormatter,
    PercentOneDecimalNoNullFormatter,
    NoDecimalNoNullOrZeroFormatter, BoolToYesFormatter
} from "../../../../common/reactDataGrid/formatters";
import {formatFullDate} from "../../../../common/format";




export default ({fiscalYearEnds, rows}) => {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs: {}});

    const columns = [
        {
            key: 'as_of_date',
            name: 'As of Date',
            formatter: UtcDateFormatter,
            width: 90,
            _getValueFromRecord: (r) => {
                return r.metadata.as_of_date
            }
        },
        {
            key: 'event_description',
            name: 'Event',
            width: 130,
            _getValueFromRecord: (r) => {
                return r.metadata.event_description
            }
        },
        {
            key: 'forecast_type',
            name: 'Type',
            width: 250,
            _getValueFromRecord: (r) => {
                return r.metadata.forecast_type
            }
        },
        {
            key: 'is_synthetic',
            name: 'Synthetic?',
            width: 80,
            _getValueFromRecord: (r) => {
                return r.metadata.is_synthetic
            },
            formatter: BoolToYesFormatter
        },
        ...fiscalYearEnds.map(d => {
            return {
                key: d,
                name: formatFullDate(d, true),
                width: 90,
                formatter: NoDecimalNoNullOrZeroFormatter,
                _getValueFromRecord: (r) => {
                    const record = r[d];

                    if (record) {
                        return record.revenue_total
                    }
                    return null
                }
            }
        })
    ];

    rows = getRdgRowsFromRecords({records: rows, columns});

    return (

        <div className={getTableClassName(false)}>

            <ReactDataGrid
                columns={columns}
                rowGetter={index => rows[index]}
                rowsCount={rows.length}
                headerRowHeight={100}
                rowHeight={22}
                minHeight={getWindowHeightLessMenuBar()}
            />

        </div>

    );


}