import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import RdgTable from "../../../components/rdg/RdgTable";
import {columns} from './common'
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {
    fetchDoradoPortfoliosWithReports
} from "../../../features/dorado/doradoPortfolio";
import {Button, Icon, Form} from "semantic-ui-react";
import {
    downloadCumulativePeriodReports,
    fetchPortfolioPeriodReports, fetchPortfolios
} from "../../../features/portfolio/portfolioFeatureSlice";
import {PortfolioType, ReportPeriodType} from "../../../common/constants";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    useEffect(() => {
        dispatch(fetchPortfolioPeriodReports({
            filters: {
                period_type: ReportPeriodType.cumulative
            },
            sort: {
                is_backtest: 1,
                id: 1
            }
        }));

        const sort = {
            type: -1,
            is_backtest: 1
        };

    }, [dispatch]);


    const {
        portfolioPeriodReports,
        loadingPortfolioPeriodReports,
    } = useSelector(state => state.portfolio, shallowEqual);

    const liveReports = portfolioPeriodReports.filter(p => p.portfolio.is_backtest === false);
    const backtestReports = portfolioPeriodReports.filter(p => p.portfolio.is_backtest === true);

    const benchmarkReports = backtestReports.filter(p => p.portfolio.type === PortfolioType.benchmark);

    const doradoBacktestReports = backtestReports.filter(p => p.portfolio.type === PortfolioType.dorado);

    const sensitivityBacktestReports = backtestReports.filter(p => p.portfolio.type === PortfolioType.backtest_sensitivity);
    const doradoMirrorReports = sensitivityBacktestReports.filter(p => p.portfolio.name.includes('Mirror'));
    const remainingReports = sensitivityBacktestReports.filter(p => !p.portfolio.name.includes('Mirror'));

    const allReports = [
        {name: 'Live Portfolios', isHeader: true, portfolio: {}},
        ...liveReports,
        {portfolio: {}},
        {name: 'Dorado Mirror', isHeader: true, portfolio: {}},
        ...doradoMirrorReports,
        {portfolio: {}},
        {name: 'Dorado Backtest', isHeader: true, portfolio: {}},
        ...doradoBacktestReports,
        {portfolio: {}},
        {name: 'Benchmarks', isHeader: true, portfolio: {}},
        ...benchmarkReports,
        {portfolio: {}},
        {name: 'Sensitivities', isHeader: true, portfolio: {}},
        ...remainingReports
    ]

    const sidebarView = (
      <div>
          <Form>
          </Form>
      </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             loading={loadingPortfolioPeriodReports}
                                             records={allReports}
                                             />}
                         sidebarView={sidebarView}/>
    )


}

