import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
const currentIndexConstituentPositionsForEtfService = client.service('current_index_constituent_positions_for_etf');
const etfTradeAssessmentRecordService = client.service('real_time_trade_assessment_record_for_etf');


const indexConstituentFeatureSlice = createSlice({
    name: 'indexConstituents',
    initialState: getInitialStateWithLoading({
        indexConstituentPositions: [],
        etfTradeAssessmentRecord: {},
    }),
    reducers: {
        ...makeStandardReducers({
            prefix: 'fetchCurrentIndexConstituentPositionsForEtf',
            destinationVariable: 'indexConstituentPositions'
        }),
        ...makeStandardReducers({
            prefix: 'fetchRealTimeEtfTradeAssessmentRecord',
            destinationVariable: 'etfTradeAssessmentRecord'
        }),

    }
});


export const fetchCurrentIndexConstituentPositionsForEtf = ({etfId, sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: indexConstituentFeatureSlice,
        prefix: 'fetchCurrentIndexConstituentPositionsForEtf',
        getPayload: () => {return currentIndexConstituentPositionsForEtfService.get(etfId, {
            query: {
                $sort: sort,
                ...filters
            }
        })}

    })
};


export const fetchRealTimeEtfTradeAssessmentRecord = ({etfId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: indexConstituentFeatureSlice,
        prefix: 'fetchRealTimeEtfTradeAssessmentRecord',
        getPayload: () => {return etfTradeAssessmentRecordService.get(etfId, {
            query: {
            }
        })}

    })
};


export default indexConstituentFeatureSlice.reducer