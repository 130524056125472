import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../../common/common'
import {formatNoDecimalNoNullOrZero, formatPercentNoNull} from '../../../../common/format';
import {
    dollarGrowthX,
    getExitNtmGrowthfromEntryNTM,
    getOutYearDollarGrowth, growthSeriesByForwardYears,
    series1ChartSpecificationByName
} from "./common";

const ReactHighcharts = require('react-highcharts');







// let dollarGrowthPoints = dollarGrowthX.map(v => {
//     return {
//         x: v,
//         exit_ntm_revenue_total_growth: getOutYearDollarGrowth(v, 6, 0),
//         exit_vs_entry_revenue_total: getExitNtmGrowthfromEntryNTM(v, 6, 0)
//     }
// });
//
//
//
//
// const dollarGrowthPlus10percentPoints = dollarGrowthX.map(v => {
//     return {
//         x: v,
//         exit_ntm_revenue_total_growth: getOutYearDollarGrowth(v, 6, .1),
//         exit_vs_entry_revenue_total: getExitNtmGrowthfromEntryNTM(v, 6, .1)
//     }
// });
//
//
// const dollarGrowthPlus20percentPoints = dollarGrowthX.map(v => {
//     return {
//         x: v,
//         exit_ntm_revenue_total_growth: getOutYearDollarGrowth(v, 6, .2),
//         exit_vs_entry_revenue_total: getExitNtmGrowthfromEntryNTM(v, 6, .2)
//     }
// });



class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const doradoValuationRecords = this.props.doradoValuationRecords;
        const doradoRevenueGrowthAuditRecords = this.props.doradoRevenueGrowthAuditRecords;
        const loading = this.props.loading;

        const forwardYears = this.props.forwardYears;

        const standardGrowthLines = growthSeriesByForwardYears[forwardYears];

        const showHistoricals = this.props.showHistoricals;
        const showStandardGrowthLines = this.props.showStandardGrowthLines;

        const series1ChartSpec = series1ChartSpecificationByName[this.props.series1ChartName];

        const series = [
            {
                name: 'Companies',
                data: doradoValuationRecords.map(r => {
                    return {
                        exit_ntm_revenue_total_growth: r.exit_ntm_revenue_total_growth,
                        exit_vs_entry_revenue_total: r.exit_vs_entry_revenue_total + 1,
                        entry_ntm_revenue_total: r.entry_ntm_revenue_total,
                        exit_ntm_revenue_total: r.exit_ntm_revenue_total,

                        x: r.ltm_relevant_organic_growth,
                        y: series1ChartSpec.getY({record: r, forwardYears}),
                        label: r.company.ticker,
                        company_name: r.company.short_name
                    }
                }),
                tooltip: {
                    pointFormatter: function () {
                        return [
                            '<b>' + this.company_name + '</b>',
                            'LTM Organic Growth: ' + formatPercentNoNull(this.x),
                            'Exit NTM Rev Growth: ' + formatPercentNoNull(this.y),
                            'Exit Rev Mult of NTM: ' + formatPercentNoNull(this.exit_vs_entry_revenue_total),
                            'Entry NTM Rev: ' + formatNoDecimalNoNullOrZero(this.entry_ntm_revenue_total),
                            'Exit NTM Rev: ' + formatNoDecimalNoNullOrZero(this.exit_ntm_revenue_total),

                        ].join("<br/>")
                    }

                },
            },

        ];

        if (showStandardGrowthLines) {
            series.push(
                {
                    name: 'Dollar Growth',
                    data: standardGrowthLines[0].map(r => {
                        return {
                            x: r.x,
                            y: series1ChartSpec.getY({record: r})
                        }
                    }),
                    type: 'line',
                    color: '#99d8c9',
                    marker: {
                        enabled: false
                    },
                    enableMouseTracking: false
                },
                {
                    name: 'Dollar + 10% Growth',
                    data: standardGrowthLines[1].map(r => {
                        return {
                            x: r.x,
                            y: series1ChartSpec.getY({record: r})
                        }
                    }),
                    type: 'line',
                    color: '#a6bddb',
                    marker: {
                        enabled: false
                    },
                    enableMouseTracking: false
                },
                {
                    name: 'Dollar + 20% Growth',
                    data: standardGrowthLines[2].map(r => {
                        return {
                            x: r.x,
                            y: series1ChartSpec.getY({record: r})
                        }
                    }),
                    type: 'line',
                    color: '#b2abd2',
                    marker: {
                        enabled: false
                    },
                    enableMouseTracking: false
                },



            )
        }

        if (showHistoricals) {
            series.push({
                name: 'Historical Data Points',
                color: 'red',
                data: doradoRevenueGrowthAuditRecords.map(r => {

                    // count ntm exit arr growth as revenue growth for consistency
                    const point = {

                        x: r.ltm_relevant_organic_growth,

                        exit_ntm_revenue_total_growth: r.exit_ntm_organic_arr_growth,
                        exit_ntm_organic_arr_growth: r.exit_ntm_organic_arr_growth,
                        exit_vs_entry_revenue_total: r.exit_vs_entry_organic_arr_growth + 1,
                        entry_ntm_revenue_total: r.entry_ntm_revenue_total,
                        exit_ntm_revenue_total: r.exit_ntm_revenue_total,

                        label: r.name
                    }

                    // get the exit value since year is specified at record level
                    point.y = series1ChartSpec.getY({record: point, forwardYears: 5});

                    return point

                }),
                tooltip: {
                    headerFormat: '<b>{point.label}</b><br/>',
                    pointFormatter: function () {
                        return [
                            '<b>' + this.label + '</b>',
                            'LTM Organic Growth: ' + formatPercentNoNull(this.x),
                            'Exit NTM ARR Growth: ' + formatPercentNoNull(this.exit_ntm_organic_arr_growth),
                            'Exit Rev Mult of NTM: ' + formatPercentNoNull(this.exit_vs_entry_revenue_total),
                            'Entry NTM Rev: ' + formatNoDecimalNoNullOrZero(this.entry_ntm_revenue_total),
                            'Exit NTM Rev (inc inorganic): ' + formatNoDecimalNoNullOrZero(this.exit_ntm_revenue_total),

                        ].join("<br/>")
                    }

                },
            });
        }


        const config = {
            chart: {
                type: 'scatter',
                zoomType: 'xy'
            },
            title: {
                text: series1ChartSpec.name
            },
            xAxis: {
                title: {
                    text: 'Relevant LTM Organic Growth'
                },
                labels: {
                    formatter: function () {
                        return formatPercentNoNull(this.value);
                    }
                }
            },
            yAxis: {
                title: {
                    text: series1ChartSpec.getYAxisLabel()
                },
                labels: {
                    formatter: function () {
                        return formatPercentNoNull(this.value);
                    }
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                    stickyTracking: false,
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        style: {
                            fontSize: "9px",
                            fontWeight: 'normal',
                            textOutline: '1px',
                            color: 'rgba(0, 0, 0, .5)'
                        },
                        format: '{point.label}'
                    },
                },
                scatter: {

                    marker: {
                        symbol: 'circle',
                        radius: 4,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },

                }
            },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent