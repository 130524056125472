const defaultState = {
  employeesScrapeRecords: [],
  loadingEmployeesScrapeRecords: true,

  employeeRecords: [],
  loadingEmployeeRecords: true,

  loadingDownloadRecords: false,

  employeesScrapeRecord: {company: {}},
  loadingEmployeesScrapeRecord: true,


};

export default (state=defaultState, action={}) => {
  switch (action.type) {

    case "FETCH_EMPLOYEE_SCRAPE_RECORDS_FULFILLED": {

      return {
        ...state,
        employeesScrapeRecords: action.payload.data,
        loadingEmployeesScrapeRecords: false
      }
    }

    case "FETCH_EMPLOYEE_SCRAPE_RECORDS_PENDING": {

      return {
        ...state,
        loadingEmployeesScrapeRecords: true
      }
    }

    case "FETCH_EMPLOYEE_RECORDS_FULFILLED": {

      return {
        ...state,
        employeeRecords: action.payload.data,
        loadingEmployeeRecords: false
      }
    }

    case "FETCH_EMPLOYEE_RECORDS_PENDING": {

      return {
        ...state,
        loadingEmployeeRecords: true
      }
    }

    case 'DOWNLOAD_EMPLOYEE_RECORDS_PENDING': {
      return {
        ...state,
        loadingDownloadRecords: true,
      }
    }

    case 'DOWNLOAD_EMPLOYEE_RECORDS_FULFILLED': {
      return {
        ...state,
        loadingDownloadRecords: false,
      }
    }

    case 'FETCH_EMPLOYEE_SCRAPE_RECORD_PENDING': {
      return {
        ...state,
        loadingEmployeesScrapeRecord: true
      }
    }

    case 'FETCH_EMPLOYEE_SCRAPE_RECORD_FULFILLED': {
      return {
        ...state,
        loadingEmployeesScrapeRecord: false,
        employeesScrapeRecord: action.payload.data
      }
    }

    default:
      return state;

  }
}
