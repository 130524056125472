import React from "react";

import {Divider, Form, Header, Icon} from "semantic-ui-react";
import {
    assumedImplementationCostOptions,
    longConcentrationScoreOptions,
    etfOptions,
    FormItemWithDesc,
    longMaxSinglePositionWeightOptions,
    maxTradePercentOfVolumeOptions,
    roundToFourDigits,
    shortStrategyOptions,
    targetCashOptions,
    targetShortPercentOptions,
    tradeCostPercentOptions,
    universeIrrOptions,
    shortConcentrationScoreOptions,
    shortMaxSinglePositionWeightOptions,
    shortCollateralNetInterestRateOptions,
    shortMinimumMarketValueOptions,
    returnConcentrationTradeoffOptions,
    noMultipleExpansionIrrWeightOptions
} from "./common";
import {createAddCheckedValueToQuery} from "../../../common/query/add";


export default ({parameters, dispatchPatchObject}) => {

    return (
        <React.Fragment>
            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='chart pie'/>
                    Portfolio Parameters
                </Header>
            </Divider>
            <br/>
            <FormItemWithDesc>
                <Form.Select options={noMultipleExpansionIrrWeightOptions}
                             value={roundToFourDigits(parameters.no_multiple_expansion_irr_weight)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     no_multiple_expansion_irr_weight: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'No Multiple Expansion IRR Weight %'}
                             width={3}
                />
                <p>
                    How much to weight our "no multiple expansion" IRR vs standard IRR.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={tradeCostPercentOptions}
                             value={roundToFourDigits(parameters.hard_trade_cost_percent)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     hard_trade_cost_percent: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Hard Trade Cost %'}
                             width={3}
                />
                <p>
                    Trade commission expense as a % of trade value.
                    On the high side, $.02/share on a $5 stock would be 0.4%.
                    On the low side, $.005/share on a $50 stock would be .01%.
                    The median share price of a Dorado company is $80.
                    Electronically traded, that would be .0065%.
                    Using .02% is 3x our expected trade cost so sufficiently conservative.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={assumedImplementationCostOptions}
                             value={roundToFourDigits(parameters.assumed_implementation_shortfall_percent)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     assumed_implementation_shortfall_percent: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Trade Cost % used to analyze new trades'}
                             width={3}
                />
                <p>
                    For trade analysis, one way cost of a reallocation trade (paired buy and sale would be 2x this cost).
                    While our actual hard/soft trade costs are specified, we want to evaluate potential portfolio
                    trades using more conservative assumptions so the trades are value-added even if the price moves on us.
                    With an assumed 1% reallocation trade cost (2% round trip), we won't swap an 11.2% IRR for an 11.3%
                    IRR stock, but we will for a 15% IRR stock. Higher assumed costs results in fewer trades.

                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={assumedImplementationCostOptions}
                             value={roundToFourDigits(parameters.non_north_america_exchange_shortfall_premium)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     non_north_america_exchange_shortfall_premium: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Trade Cost Premium % used to analyze non-North America trades'}
                             width={3}
                />
                <p>
                    Premium transaction cost added to non-North America trades.  Since they typically occur when US exchanges are
                    closed, we take more market risk (US market may be relatively higher or lower when it next opens).  Logic is
                    we require a slightly higher return to take international trade risk.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={maxTradePercentOfVolumeOptions}
                             value={roundToFourDigits(parameters.target_maximum_trade_percent_of_median_volume)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     target_maximum_trade_percent_of_median_volume: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Low Volume Stocks: Max % of Median Volume to Trade'}
                             width={3}
                />
                <p>
                    For low volume/low float stocks, assume we can only buy x% of normal trading volume in a day, and
                    size trades accordingly.  If an IPO (with no historical volume), assume $5mm of volume, which would result in
                    fairly low trading on the IPO.  Assume we can obtain up to 20% of the actual traded volume that day.
                </p>
            </FormItemWithDesc>

            <FormItemWithDesc>
                <Form.Select options={longConcentrationScoreOptions}
                             value={parameters.target_maximum_long_concentration_score}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     target_maximum_long_concentration_score: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Maximum Long Concentration Score'}
                             width={3}
                />
                <p>
                    How heavily to weight higher IRRs in our long target portfolio. Higher = more concentrated.
                    Sum of position weight squared.
                    <br/>
                    1000 = 10 positions of 10% weight.
                    <br/>
                    500 = 20 positions of 5% weight.
                    <br/>
                    710 = 16 positions, top is 10%, #2 is 9.5%, fading to 2.5%
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={returnConcentrationTradeoffOptions}
                             value={parameters.return_score_penalty_per_concentration_score_point}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     return_score_penalty_per_concentration_score_point: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Return We Would Give Up to Reduce HH Index by 100'}
                             width={3}
                />
                <p>
                    An equivalence point for returns and concentration.  Would we would trade 5% returns (~1% IRR hit over
                    5 years) to reduce Herfhindahl by 100 points, or only 1% returns (.2% IRR hit), or nothing at all?
                </p>
            </FormItemWithDesc>

            <FormItemWithDesc>
                <Form.Select options={longMaxSinglePositionWeightOptions}
                             value={roundToFourDigits(parameters.target_maximum_long_single_position_weight)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     target_maximum_long_single_position_weight: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Target Long Portfolio: Max Single Position %'}
                             width={3}
                />
                <p>
                    Maximum long position size we will target. Actual positions may temporarily exceed this target due to
                    market movements.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={targetCashOptions}
                             value={roundToFourDigits(parameters.target_cash_percent)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     target_cash_percent: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Target Cash %.'}
                             width={3}
                             disabled={parameters.lock_cash_percent}
                />
                <p>
                    Target cash percent of NAV.  Disabled if "Lock Cash" is enabled.  Will buy or sell securities
                    to achieve target cash % of equity.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Checkbox label="Lock Cash %"
                               checked={parameters.lock_cash_percent}
                               onChange={(e, d) => {

                                   const patchObject = {
                                       id: parameters.id,
                                       lock_cash_percent: d.checked
                                   };

                                   dispatchPatchObject(patchObject);
                               }}
                               width={3}
                />
                <p>
                    Lock cash % at current value.  Any trades will be evenly matched buys and sells as opposed to
                    increasing or decreasing cash.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Checkbox label="Apply Avg Concentration Score to Locked Positions"
                               checked={parameters.apply_average_concentration_score_to_locked_positions}
                               onChange={(e, d) => {

                                   const patchObject = {
                                       id: parameters.id,
                                       apply_average_concentration_score_to_locked_positions: d.checked
                                   };

                                   dispatchPatchObject(patchObject);
                               }}
                               width={3}
                />
                <p>
                    On occasion we may have a large locked position that will affect our concentration
                    score.  Instead of forcing extreme diversification of the rest of the portfolio, we can instead
                    choose to ignore it, weighting it at an average level.
                </p>
            </FormItemWithDesc>
            <Divider horizontal>Short Portfolio</Divider>
            <FormItemWithDesc>
                <Form.Checkbox label="Enable Short Trades"
                               checked={parameters.enable_short_trades}
                               onChange={(e, d) => {

                                   const patchObject = {
                                       id: parameters.id,
                                       enable_short_trades: d.checked
                                   };

                                   dispatchPatchObject(patchObject);
                               }}
                               width={3}
                />
                <p>

                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={shortStrategyOptions}
                             value={parameters.short_strategy}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     short_strategy: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Short Strategy'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>

                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={shortCollateralNetInterestRateOptions}
                             value={roundToFourDigits(parameters.short_collateral_net_interest_rate)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     short_collateral_net_interest_rate: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Short Collateral Net Interest Rate'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>
                    Positive (inflow) or negative (outflow) interest on short collateral.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={targetShortPercentOptions}
                             value={roundToFourDigits(parameters.target_minimum_short_percent_of_net_asset_value)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     target_minimum_short_percent_of_net_asset_value: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Minimum Short Exposure % NAV.'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>
                    Minimum short exposure our fund will hold as a % of NAV.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={targetShortPercentOptions}
                             value={roundToFourDigits(parameters.target_maximum_short_percent_of_net_asset_value)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     target_maximum_short_percent_of_net_asset_value: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Maximum Short Exposure % NAV.'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>
                    Maximum short exposure our fund will hold as a % of NAV.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={universeIrrOptions}
                             value={roundToFourDigits(parameters.universe_irr_at_minimum_short_percent_of_net_asset_value)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     universe_irr_at_minimum_short_percent_of_net_asset_value: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Universe IRR at minimum Short %'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>
                    Universe IRR where we are most bullish, and therefore will have the minimum short exposure.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={universeIrrOptions}
                             value={roundToFourDigits(parameters.universe_irr_at_maximum_short_percent_of_net_asset_value)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     universe_irr_at_maximum_short_percent_of_net_asset_value: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Universe IRR at maximum Short %'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>
                    Universe IRR where we are most bearish, and therefore will have the maximum short exposure.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={etfOptions}
                             value={parameters.default_short_etf_id}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     default_short_etf_id: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Default ETF for Short Exposure'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>
                    Defaut ETF to generate short trades.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={shortConcentrationScoreOptions}
                             value={parameters.target_maximum_short_concentration_score}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     target_maximum_short_concentration_score: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Maximum Short Concentration Score'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>
                    How heavily to weight worse company IRRs in our short target portfolio. Higher = more concentrated.
                    Sum of position weight squared.
                    <br/>
                    500 = 20 positions of 5% weight.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={shortMaxSinglePositionWeightOptions}
                             value={roundToFourDigits(parameters.target_maximum_short_single_position_weight)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     target_maximum_short_single_position_weight: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Target Short Portfolio: Max Single Position %'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>
                    Maximum short position size we will target. Actual positions may temporarily exceed this target due to
                    market movements.
                </p>
            </FormItemWithDesc>
            <FormItemWithDesc>
                <Form.Select options={shortMinimumMarketValueOptions}
                             value={roundToFourDigits(parameters.short_minimum_market_value_usd)}
                             onChange={(e, d) => {

                                 const patchObject = {
                                     id: parameters.id,
                                     short_minimum_market_value_usd: d.value
                                 };

                                 dispatchPatchObject(patchObject);
                             }}
                             label={'Short Min. Market Value $m'}
                             width={3}
                             disabled={!parameters.enable_short_trades}
                />
                <p>
                    Minimum market cap to short (thousands).
                </p>
            </FormItemWithDesc>


        </React.Fragment>
    )

}