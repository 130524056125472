import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Divider, Form, Grid, Statistic, Table} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import {
    fetchNavBridges,
    fetchPortfolios
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfoliosSummaryTable from "../PortfoliosSummaryTable";
import NavBridgeTable from "./NavBridgeTable";
import {PortfolioType} from "../../../common/constants";
import {getFilteredTermFromSearchPropsForField} from "../../../common/filter/add";
import {createAddTermFilterToQuery, createAddValueToQuery} from "../../../common/query/add";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import moment from 'moment';
import {unitsOptions, fieldSpecs} from "./common";
import qs from 'qs';


export default () => {

    const {navBridges, loadingNavBridges} = useSelector(state => state.portfolio, shallowEqual);
    const {portfolios, loadingPortfolios} = useSelector(state => state.portfolio, shallowEqual);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    useEffect(() => {
        dispatch(fetchPortfolios({
            sort: {
                type: 1
            },
            filters: {
                is_backtest: false,
            }


        }));
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['portfolioId'],
        requestData
    });

    if (searchProps.portfolioId == null) {
        if (!loadingPortfolios && portfolios.length > 0) {
            searchProps.portfolioId = portfolios[0].id;
            const url = location.pathname + qs.stringify(searchProps, {addQueryPrefix: true})
            return <Redirect to={url}/>
        }
    }

    const portfolioOptions = portfolios.map(p => {
        return {
            value: p.id,
            text: p.type,
            key: p.id
        }
    })

    const selectedPortfolio = portfolios.find(p => p.id === searchProps.portfolioId) || {};

    const portfolioType = selectedPortfolio.type;

    let visibleNavBridges = navBridges;

    const sidebar = (
        <div>
            <Grid relaxed>
                <Grid.Row>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <PortfoliosSummaryTable portfolios={portfolios}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <Form.Select
                                fluid
                                inline
                                options={portfolioOptions}
                                value={searchProps.portfolioId}
                                onChange={createAddValueToQuery({
                                    history: history,
                                    searchProps,
                                    fieldName: 'portfolioId'
                                })}
                                label="Portfolio"
                            />
                            <Form.Select
                                fluid
                                inline
                                options={unitsOptions}
                                value={searchProps.units}
                                onChange={createAddValueToQuery({
                                    history: history,
                                    searchProps,
                                    fieldName: 'units'
                                })}
                                label="Units"
                            />
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider horizontal>Definitions</Divider>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Table basic={'very'}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Net Interest Income
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        Interest paid on PB cash balances.  PB is not supposed to carry cash balances
                                        (sweeps to money market), so these dollars should be minimal.
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Margin Expense
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        Margin borrowing costs are applied when unencumbered cash (net of short collateral) is negative.
                                        Margin is charged daily at 1-mo LIBOR + 50bps.
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Short Borrow Expense
                                    </Table.HeaderCell>
                                    <Table.Cell>
                                        There are two components to short borrow expense.  1) Interest paid on the cash collateral
                                        exactly offsetting our short market value that we are required to maintain in our PB account.  That
                                        interest rate is currently negative so it is an expense.  2) Extra charges on "hard-to-borrow" shorts.
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );

    return (
        <ViewWithSidebar mainView={<NavBridgeTable loading={loadingNavBridges}
                                                   records={visibleNavBridges}
                                                   units={searchProps.units}
                                                   portfolioType={portfolioType}
        />}

                         sidebarView={sidebar}/>
    )

};


const getVisibleNavBridges = (navBridges) => {

    if (navBridges.length === 0) {
        return navBridges
    }

    const maxDate = moment.max(navBridges.map(b => moment(b.standard_date)));

    const yearsToShow = [maxDate.year()];
    if (maxDate.month < 4) {
        yearsToShow.push(maxDate.year() - 1);
    }

    return navBridges.filter(n => {

        const dt = moment(n.standard_date);

        if (dt.month() === 11) {
            return true
        }

        return (yearsToShow.includes(dt.year()))
    })



}



const requestData = ({dispatch, searchProps}) => {

    if (searchProps.portfolioId != null) {
        dispatch(fetchNavBridges(searchProps.portfolioId));
    }

};