import {formatNoDecimalNoNullOrZero, formatPercentNoNull, formatMultipleOneDecimal} from "../../../../common/format";
import {parseBoolean} from "../../../../common/query/parse";


export const xSpecs = [
    {
        get: (record, isEntry) => isEntry ? record.entry_ntm_organic_arr_growth : record.exit_ntm_organic_arr_growth,
        label: 'NTM Organic ARR Growth',
        format: formatPercentNoNull,
    },
];

export const ySpecs = [
    {
        get: (record, isEntry) => isEntry ? record.entry_ev_to_revenue_total_ntm : record.exit_ev_to_revenue_total_ntm,
        label: 'EV/NTM Revenue',
        format: formatMultipleOneDecimal,
    },
    {
        get: (record, isEntry) => isEntry ? record.entry_ev_to_maximized_pretax_fcf_ntm : record.exit_ev_to_maximized_pretax_fcf_ntm,
        label: 'EV/NTM Max Pretax FCF',
        format: formatMultipleOneDecimal,
    },

];


export const xChartOptions = xSpecs.map(spec => {
    return {
        key: spec.label,
        value: spec.label,
        text: spec.label
    }
});


export const yChartOptions = ySpecs.map(spec => {
    return {
        key: spec.label,
        value: spec.label,
        text: spec.label
    }
});


export const xSpecByLabel = xSpecs.reduce((obj, spec) => {
    obj[spec.label] = spec;
    return obj
}, {});

export const ySpecByLabel = ySpecs.reduce((obj, spec) => {
    obj[spec.label] = spec;
    return obj
}, {});




export const fieldSpecs = {
    xAxisLabel: {
        parse: (v) => v == null ? xChartOptions[0].value : v
    },
    yAxisLabel: {
        parse: (v) => v == null ? yChartOptions[0].value : v
    },
    // showMultipleSensitivities: {
    //     parse: parseBoolean
    // }
};