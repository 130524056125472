import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
const companyEquityOfferingEventsService = client.service('company_equity_offering_events');
const equityOfferingEventsService = client.service('equity_offering_events');
const companyAcquisitionsService = client.service('company_acquisitions');


const eventsFeatureSlice = createSlice({
    name: 'events',
    initialState: getInitialStateWithLoading({
        equityEvents: [],
        equityEvent: {},

        acquisitionEvents: [],
        acquisitionEvent: {}
    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchEquityOfferingEventsForCompany', destinationVariable: 'equityEvents'}),
        ...makeStandardReducers({prefix: 'patchEquityOfferingEvent', destinationVariable: 'equityEvent'}),

        ...makeStandardReducers({prefix: 'fetchAcquisitionsForCompany', destinationVariable: 'acquisitionEvents'}),
        ...makeStandardReducers({prefix: 'patchAcquisitionEvent', destinationVariable: 'acquisitionEvent'}),
    }
});


export const fetchEquityOfferingEventsForCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: eventsFeatureSlice,
        prefix: 'fetchEquityOfferingEventsForCompany',
        getPayload: () => companyEquityOfferingEventsService.get(companyId)
    })
};


export const patchEquityOfferingEvent = ({id, patchData}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: eventsFeatureSlice,
        prefix: 'patchEquityOfferingEvent',
        getPayload: () => {return equityOfferingEventsService.patch(id, patchData)}
    })
};

export const fetchAcquisitionsForCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: eventsFeatureSlice,
        prefix: 'fetchAcquisitionsForCompany',
        getPayload: () => companyAcquisitionsService.find({
            query: {
                company_id: companyId,
                $sort: {
                    close_date: -1
                }
            }
        })
    })
};

export const patchAcquisitionEvent = ({id, patchData}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: eventsFeatureSlice,
        prefix: 'patchAcquisitionEvent',
        getPayload: () => {return companyAcquisitionsService.patch(id, patchData)}
    })
};



export default eventsFeatureSlice.reducer