import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic, Table} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";

import {fieldSpecs, YearLegend} from './common'
import {useReloadOnChange} from "../../../common/hooks";
import {
    fetchPortfolios,
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfolioSelect from "../../dorado/PortfolioSelect";
import {fetchPortfolioLongShortRegressionStats} from "../../../features/portfolio/portfolioAnalysisFeatureSlice";
import RegressionChart from "./RegressionChart";
import RegressionSummaryTable from "./RegressionSummaryTable";



export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        portfolios,
        loadingPortfolios,
    } = useSelector(state => state.portfolio, shallowEqual);

    const {
        portfolioLongShortRegressionStats,
        loadingPortfolioLongShortRegressionStats,
    } = useSelector(state => state.portfolioAnalysis, shallowEqual);

    useEffect(() => {
        const sort = {
            type: 1,
            is_backtest: 1
        }

        const filters = {
            type: {
                $in: [
                    'Point Sur',
                    'Special Opps V',
                    'Dorado',
                    'Backtest Sensitivity',
                    'Backtest Benchmark'
                ]
            },
        }

        dispatch(fetchPortfolios({sort, filters}))

    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort', 'portfolio_id'],
        requestData: refreshData
    });

    console.log(portfolioLongShortRegressionStats)

    if (searchProps.portfolio_id == null) {
        if (!loadingPortfolios && portfolios.length > 0) {
            return <Redirect to={location.pathname + "?portfolio_id=" + portfolios[0].id}/>
        }
    }

    const sidebar = (
        <div>
            <br/>
            <Form>
                <PortfolioSelect/>
                <YearLegend/>

                <RegressionSummaryTable portfolioLongShortRegressionStats={portfolioLongShortRegressionStats}/>


            </Form>

        </div>
    );

    return (
        <ViewWithSidebar mainView={<RegressionChart
            portfolioLongShortRegressionStats={portfolioLongShortRegressionStats}
            loading={loadingPortfolioLongShortRegressionStats}

        />}
                         sidebarView={sidebar}/>

    )

}


const refreshData = ({dispatch, searchProps}) => {

    if (searchProps.portfolio_id != null && searchProps.portfolio_id !== "") {
        dispatch(fetchPortfolioLongShortRegressionStats({portfolioId: searchProps.portfolio_id}));

    }

}
