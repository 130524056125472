import {formatMultipleOneDecimal, formatPercentNoNull} from "../../../../common/format";
import {formatPercentOneDecimalNoNull} from "../../../../common/format";
import {formatTwoDecimalPercentNoZero} from "../../../../common/reactDataGrid/formatters";





export const chartFieldSpecs = {
    x: {
        parse: (v) => v == null ? xAxisChartSpecifications[0].name: v
    },
    y: {
        parse: (v) => v == null ? yAxisChartSpecifications[0].name: v
    },
    predictionConstraint: {
        parse: (v) => v == null ? predictionConstraints[0]: v
    }
}


export const predictionConstraints = [
    'Material Price Change',
    'No Overlapping Periods',
    'None'
]


export const applyConstraintToPredictions = ({doradoPredictions, constraint, daysText}) => {
    if (constraint === 'None') {
        return doradoPredictions
    }

    if (constraint === 'No Overlapping Periods') {
        const overlapField = 'is_overlap_' + daysText;
        return doradoPredictions.filter(r => !r[overlapField]);
    }

    if (constraint === 'Material Price Change') {
        return doradoPredictions.filter(r => r.is_material_price_change);
    }



}



export const xAxisChartSpecifications = [
    {
        name: 'irr',
        getX: (record) => record.irr,
        getXAxisLabel: () => 'Entry IRR',
        formatX: formatPercentOneDecimalNoNull,
    },
    {
        name: 'fair_price_percent_of_entry_price',
        getX: (record) => record.fair_price_percent_of_entry_price,
        getXAxisLabel: () => 'Exit PV (6%) % Entry',
        formatX: formatPercentOneDecimalNoNull,
    },
    {
        name: 'exit_price_percent_of_entry_price',
        getX: (record) => record.exit_price_percent_of_entry_price,
        getXAxisLabel: () => 'Exit/Entry Price',
        formatX: formatPercentOneDecimalNoNull,
    },
    {
        name: 'buy_price_percent_of_entry_price',
        getX: (record) => record.buy_price_percent_of_entry_price,
        getXAxisLabel: () => '15% IRR Buy/Entry',
        formatX: formatPercentOneDecimalNoNull,
    },
];

export const yAxisChartSpecifications = [
    {
        name: 'return_1_day',
        suffix: '1_day',
        getY: (record) => record.return_1_day,
        getYAxisLabel: () => '1 Day Return',
        formatY: formatPercentOneDecimalNoNull,
    },
    {
        name: 'return_15_day',
        suffix: '15_day',
        getY: (record) => record.return_15_day,
        getYAxisLabel: () => '15 Day Return',
        formatY: formatPercentOneDecimalNoNull,
    },
    {
        name: 'return_30_day',
        suffix: '30_day',
        getY: (record) => record.return_30_day,
        getYAxisLabel: () => '30 Day Return',
        formatY: formatPercentOneDecimalNoNull,
    },
    {
        name: 'return_60_day',
        suffix: '60_day',
        getY: (record) => record.return_60_day,
        getYAxisLabel: () => '60 Day Return',
        formatY: formatPercentOneDecimalNoNull,
    },
    {
        name: 'return_90_day',
        suffix: '90_day',
        getY: (record) => record.return_90_day,
        getYAxisLabel: () => '90 Day Return',
        formatY: formatPercentOneDecimalNoNull,
    },
    {
        name: 'return_180_day',
        suffix: '180_day',
        getY: (record) => record.return_180_day,
        getYAxisLabel: () => '180 Day Return',
        formatY: formatPercentOneDecimalNoNull,
    }

];


export const xAxisChartSpecificationByName = xAxisChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});

export const yAxisChartSpecificationByName = yAxisChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});