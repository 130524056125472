import {colorBySourceType, SourceType} from "./constants";

// for coloring, these are both CIQ types of records
export const ciqTypes = [
    SourceType.ciqEstimate,
    SourceType.ciqReported
];

export const getClassToColorRevenue = (record) => {
    return colorBySourceType[record.source_of_revenue_estimate]
};

export const getClassToColorRevenueComposition = (record) => {

    if (ciqTypes.includes(record.source_of_revenue_estimate)) {
        return colorBySourceType[SourceType.pointSurDefaultEstimate]
    }

    if (record.source_of_revenue_estimate === SourceType.pointSurEstimate) {
        return colorBySourceType[SourceType.pointSurEstimate]
    }

};

export const getClassToColorOpex = (record) => {
    if (ciqTypes.includes(record.source_of_ebitda_estimate)) {
        return colorBySourceType[SourceType.pointSurDefaultEstimate]
    }

    if (record.source_of_ebitda_estimate === SourceType.pointSurEstimate) {
        return colorBySourceType[SourceType.pointSurEstimate]
    }

};

export const getClassToColorEBITDA = (record) => {
    return colorBySourceType[record.source_of_ebitda_estimate]
};

export const getClassToColorGrossProfit = (record) => {
    return colorBySourceType[record.source_of_gross_profit_estimate]
};

export const getClassToColorDilution = (record) => {
    return colorBySourceType[record.source_of_dilution]
};

export const getClassToColorCapex = (record) => {
    return colorBySourceType[record.source_of_capital_expenditures_estimate];
};

export const getClassToColorChangeInDeferred = (record) => {
    return colorBySourceType[record.source_of_change_in_deferred_revenue_estimate];

};


export const makeGetClassToColorBoolean = ({field, deEmphasizePrePublic = false}) => {

    return (record) => {

        if (deEmphasizePrePublic) {
            if (!record.is_public_period) {
                return 'deemphasize'
            }
        }

        const value = record[field];

        if (value === false) {
            return 'override-color'
        }
    }

};

export const makeGetEmphasizeYesBoolean = ({field, colorOverride}) => {

    colorOverride = colorOverride ? colorOverride : 'estimate-color';

    return (record) => {
        const value = record[field];

        if (value === false) {
            return 'deemphasize'
        }

        return colorOverride
    }

};



