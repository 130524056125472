import React, { Component} from 'react';
import { connect } from 'react-redux';
import {Container, Header, Table} from 'semantic-ui-react';



class TagDocumentationPage extends Component {


  render() {
    return (
      <Container>

        <Header as='h1'>Tags</Header>

        <p>Companies can be assigned any number of a collection of tags.  These tags are used to better filter and sort our universe.</p>

        <Header>Company Tags</Header>
        <Table padded>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Is Public
                </Header>
              </Table.Cell>
              <Table.Cell>
                Defines if a company is currently public.  Calculated from CapIQ.  If CIQ company type is "Private Company", or the
                company does not have a ticker.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Is or Was Public
                </Header>
              </Table.Cell>
              <Table.Cell>
                Primarily for backtest purposes, used to select all companies that were ever public.  The only companies that do not
                fall into this bucket are: 1) pre-IPO companies, 2) private companies we entered into the database for some reason
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Has Valid Period Info
                </Header>
              </Table.Cell>
              <Table.Cell>
                  Has period information required to build a model.  Requires 1) month of fiscal year end, 2) last reported period.
                We typically have this information, but may not if the company is pre-IPO or there is a CapIQ error.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Halt CIQ Data Updates?
                </Header>
              </Table.Cell>
              <Table.Cell>
                  A flag we can set to stop updating CIQ data for a particular company.  Use this to flag private companies where
                data should never change again (taken-private).  We don't want to flag this for pre-IPO companies as we want to
                continually check for new CIQ data for these names.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Has Valid Share Class Securities?
                </Header>
              </Table.Cell>
              <Table.Cell>
                  <p>A flag showing the small number of companies with no share class securities or invalid securities from CapIQ.  Mostly reflects
                private businesses, including pre-IPO companies where CapIQ has not populated share class data.  Can also reflect errors
                    that may need to be manually fixed.</p>
                <p>
                  A common issue, for example, with Agora is incomplete ADR information.  Even post-public, the company
                  has an invalid value as it does not have any matched Security CIQ IDs, which are in turn used to pull
                  share class and pricing data.  First provide an override of the right Security CIQ ID and refresh all data.
                </p>
                <p>
                  A second issue is that CapIQ sometimes changes share class indices upon going private.  In Agora's case, it
                  had an old "Ordinary Shares" class that was also index 1 (same as Class A), that needed to be deleted.
                </p>
              </Table.Cell>
            </Table.Row>



          </Table.Body>
        </Table>


        <Header>Business Quality Tags</Header>

        <Table padded>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Great Business
                </Header>
              </Table.Cell>
              <Table.Cell>
                Our favorite businesses, regardless of price.  Passes one of the following tests:<br/>
                1) Has characteristics that would make you comfortable owning for 5-10 years (recurring, durable, growing, scalable, etc.)<br/>
                2) If market dropped 40% across the board, which businesses would you want to buy?<br/>
                3) Unique, strategic asset that could have scarcity value
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Terrible Business
                </Header>
              </Table.Cell>
              <Table.Cell>
                Bad business, regardless of price.  Has characteristics making it unlikely business going to create value (high churn, poor unit economics,
                commoditization, bad software, structurally declining, losing scale, etc.).  Even at optically attractive multiples
                we would be very cautious about buying.  Examples include:
                1) Structurally low margin / may never be profitable.  Value trap even at a low revenue multiple.
                2) Potential to be permanently declining.  Need to be very careful about what multiple is paid for a permanently declining business that is losing scale.
                3) Reasons to believe it is permanently inefficient and poorly run.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header>Geography Group Tags</Header>

        <Table padded>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Core / Tier 1
                </Header>
              </Table.Cell>
              <Table.Cell>
                <p>US, Canada, ANZ, UK, Israel.</p>
                Countries with business/shareholder friendly environments and known for scalable software businesses
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Ancillary / Tier 2
                </Header>
              </Table.Cell>
              <Table.Cell>
                <p>Nordics, majority of North/Central Europe, South Africa</p>
                We invest in these regions but need to do more work to get comfortable
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Non-Core / Tier 3
                </Header>
              </Table.Cell>
              <Table.Cell>
                <p>France, Germany, Italy, Spain, Latin America, Asia</p>
                Low likelihood of investing in these regions.  Even if we did, valuations would need to be structurally lower.
              </Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>

        <Header>Tracking Rank</Header>

        <p>
          Tracking rank is an estimate of how much time we should spend thinking about this name, adjusting target prices, regardless of current valuation.
          Our valuation screens should be focused on Tier 1 and 2, while Tier 3 and 4 we can check sporadically for big price moves.

          Tracking rank should not just be a copy of business quality.  High quality names will make it in this bucket, but so should low quality names we want to track.
          Geography or size should not be considered - we can filter on these attributes separately.
        </p>

        <Table padded>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Tier 1
                </Header>
              </Table.Cell>
              <Table.Cell>
                Primarily recurring software businesses we think we can value.
                Shorts we want to track closely.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Tier 2
                </Header>
              </Table.Cell>
              <Table.Cell>
                Businesses we want to track closely, but may not be as core.  Examples include:
                1) Google/Facebook: Consumer and advertising elements
                2) Spok: Recurring pager business. Not software but at a price we'd dig in.
                3) MicroStrategy.  Legacy software business with controlling shareholder.  Would invest at a price but not particularly actionable.
                4) Altus group.  Collection of businesses with >50% service but also has a nice software business we want to track.
                5) Alfa Financial Software.  Low recurring software business.
                6) Cloudera.  Infrastructure enough that going to be a big time investment to understand the space.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Tier 3
                </Header>
              </Table.Cell>
              <Table.Cell>
                Fairly uninteresting businesses we want to check on every 6-12 months for major changes.
                May include a lot of low-recurring potential shorts with a well-out-of-the-money price target, where
                we might do some work if it appreciated significantly.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as='h4' textAlign='center'>
                  Tier 4
                </Header>
              </Table.Cell>
              <Table.Cell>
                Companies we don't want to ever think about.
                Typically well outside our investable universe, in an area where we have no expertise or angle.
                Businesses that aren't interesting either long or short.
              </Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>


        <Header>Primary Industry Tags</Header>

        <Table padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Level 1
              </Table.HeaderCell>
              <Table.HeaderCell>
                Level 2
              </Table.HeaderCell>
              <Table.HeaderCell>
                Level 3
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                Not Tech
              </Table.Cell>
              <Table.Cell>
                Not Tech
              </Table.Cell>
              <Table.Cell>
                Not Tech
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Tech
              </Table.Cell>
              <Table.Cell>
                Hardware, Consulting, Communications
              </Table.Cell>
              <Table.Cell>
                Hardware, Consulting, Communications
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Tech
              </Table.Cell>
              <Table.Cell>
                Software, Internet, Tech-Enabled Service
              </Table.Cell>
              <Table.Cell>
                Software, Internet
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Tech
              </Table.Cell>
              <Table.Cell>
                Software, Internet, Tech-Enabled Service
              </Table.Cell>
              <Table.Cell>
                Tech-Enabled Service
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header>Software Sub-Sector Tags</Header>
        <p>
          A company classified as software/internet/tech-enabled service can have one or more tags to further
          classify its subsector.
        </p>

        <Table padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Sub-Sector
              </Table.HeaderCell>
              <Table.HeaderCell>
                Description
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                Security
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Infrastructure
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Data Management
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Application / Vertical
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Large Diversified
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>

        <p>
          Other potential buckets: payments, consumer, marketplace, stock exchange, PEO
        </p>

        <Header>Primary Revenue Model Tags</Header>
        <p>
          Many companies have more than one revenue model.  We capture the primary revenue model here.
        </p>

        <Table padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Revenue Model
              </Table.HeaderCell>
              <Table.HeaderCell>
                Description
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                Subscription
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Recurring-Transactional
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                License/Maintenance
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {

  }
}



export default connect(mapStateToProps, {})(TagDocumentationPage);
