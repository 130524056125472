import React, {useEffect} from "react";
import {Route} from "react-router-dom";
import {useAuth0} from "./react-auth0-wrapper";


const PrivateRoute = ({path, children, ...rest}) => {
    const {isAuthenticated, loginWithRedirect} = useAuth0();

    useEffect(() => {
        const fn = async () => {
            if (!isAuthenticated) {
                await loginWithRedirect({
                    appState: {targetUrl: path}
                });
            }
        };
        fn();
    }, [isAuthenticated, loginWithRedirect, path]);

    // const render = props => <Component {...props} />;
    // console.log(component)
    return (
        <Route path={path}>
            {children}
        </Route>
    )

};


export default PrivateRoute;
