import {getValueWithT1AndT2Years, getValueWithFowardYear} from "../../common/reactDataGrid/valuationColumns";


export const groupFieldSpecs = {

    screenGroup: {
        parse: (v) => {
            if (v == null) {
                return 'Summary'
            }
            return v
        }
    },
    estimatesType: {
        parse: (v) => v == null ? 'Point Sur' : v
    }

};


