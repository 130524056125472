import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Button, Container, Divider, Form, Grid, Header, Table} from 'semantic-ui-react'
import {useReloadOnChange} from "../../../../common/hooks";
import CompanyHeader from "../../../../components/CompanyHeader";
import {
    fetchDoradoForecastMetadatasForCompany, fetchFinancialPeriodRecordsForMetadata
} from "../../../../features/dorado/doradoCompanyDetail";
import ColorsPopup from "../../../../components/ColorsPopup";
import TermFilter from "../../../../components/filter/TermFilter";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import {createAddValueToQuery, createSetFieldValueOnQuery} from "../../../../common/query/add";
import {formatAbsoluteDateString} from "../../../../common/formatDate";
import FinancialsComponent from "./FinancialsComponent";
import qs from 'qs';
import {PeriodType, ForecastType} from "../../../../common/constants";
import PeriodTypeSelect from "../../../../components/PeriodTypeSelect";
import {partition} from "../../../../common/common";
import {validMoment} from "../../../../common/format";
import {fetchCompany} from "../../../../features/companyFeatureSlice";


const fieldSpecs = {
    doradoForecastMetadataId: {
        parse: (v) => {
            if (v == null) {
                return null
            }
            if (v === '') {
                return null
            }

            return parseInt(v)
        }
    },
    // metadataType: {
    //     parse: (v) => v == null ? ForecastType.currentModel : v
    // },
    periodType: {
        parse: (v) => v == null ? PeriodType.annual : v
    }
};


export default () => {

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: [],
        matchFields: ['id'],
        requestData: requestCompanyData
    });

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['doradoForecastMetadataId', 'periodType'],
        matchFields: ['id'],
        requestData: requestForecastData
    });


    const {
        company,
        loadingCompany,

        } = useSelector(state => state.company, shallowEqual);

    const {
        doradoForecastMetadatas,
        loadingDoradoForecastMetadatas,
        financialPeriodRecords,
        loadingFinancialPeriodRecords,

    } = useSelector(state => state.doradoCompanyDetail, shallowEqual);

    const loading = loadingCompany || loadingDoradoForecastMetadatas || loadingFinancialPeriodRecords;


    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const [revisedMetadatas, standardMetadatas] = partition(doradoForecastMetadatas, m => m.forecast_type === ForecastType.revised_point_sur_backtest);

    const reorderedMetadatas = standardMetadatas.concat(revisedMetadatas);

    const metadataOptions = reorderedMetadatas.map(metadata => {

        let eventText = metadata.description ? "  | " + metadata.description + "" : "";

        let text = "";

        if (metadata.forecast_type === ForecastType.revised_point_sur_backtest) {
            text += 'Methodology Auto-Adj: '
        }

        if (!metadata.forecast_is_valid) {
            eventText += ' (Invalid)'
        }

        text += formatAbsoluteDateString({dateString: metadata.as_of_standard_date, format: 'M/D/YYYY'}) + eventText;

        return {
            text: text,
            key: metadata.id,
            value: metadata.id
        }
    });

    if (!loadingDoradoForecastMetadatas && doradoForecastMetadatas.length > 0) {
        if (metadataIdisNullOrDoesNotMatchVisibleMetadatas({
            doradoForecastMetadatas,
            doradoForecastMetadataId: searchProps.doradoForecastMetadataId
        })) {
            searchProps.doradoForecastMetadataId = doradoForecastMetadatas[0].id;

            const newUrl = history.location.pathname + qs.stringify(searchProps, {addQueryPrefix: true});

            return <Redirect to={newUrl}/>
        }
    }

    return (
        <Container fluid>
            <Grid padded>

                <Grid.Column width={4}>
                    <Grid.Row>
                        <Grid>
                            <Grid.Column width={14}>
                                <CompanyHeader company={company} loading={loadingCompany}/>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <ColorsPopup/>
                            </Grid.Column>
                        </Grid>
                    </Grid.Row>
                    <Grid.Row>
                        <Table basic='very' compact='very' collapsing>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Filing Currency:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {company.filing_currency}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Form>
                            <PeriodTypeSelect/>
                            <TermFilter allowNullSelection={false}
                                        label='Forecast as of Date'
                                        options={metadataOptions}
                                        value={searchProps.doradoForecastMetadataId}
                                        onChange={createAddValueToQuery({
                                            history,
                                            searchProps,
                                            fieldName: 'doradoForecastMetadataId'
                                        })}
                                        disabled={loadingDoradoForecastMetadatas}
                            />
                        </Form>

                    </Grid.Row>

                </Grid.Column>
                <Grid.Column width={12}>
                    <FinancialsComponent loading={loadingFinancialPeriodRecords}
                                         financialRecords={financialPeriodRecords}/>


                </Grid.Column>
            </Grid>
        </Container>
    )

}


const metadataIdisNullOrDoesNotMatchVisibleMetadatas = ({doradoForecastMetadatas, doradoForecastMetadataId}) => {

    const visibleIds = doradoForecastMetadatas.map(r => r.id);

    return doradoForecastMetadataId == null || !visibleIds.includes(doradoForecastMetadataId);

};


const requestCompanyData = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    dispatch(fetchCompany({companyId: id}));
    dispatch(fetchDoradoForecastMetadatasForCompany({companyId: id, sortAsc: false}));
};

const requestForecastData = ({dispatch, searchProps, matchParams}) => {
    const metadataId = searchProps.doradoForecastMetadataId;

    if (metadataId != null) {
        dispatch(fetchFinancialPeriodRecordsForMetadata({
            doradoForecastMetadataId: metadataId,
            periodType: searchProps.periodType}));
    }
};