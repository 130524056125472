export const fieldHasValues = (records, fieldName) => {
  const values = records.map(record => {
    return record[fieldName]
  });
  return values.some((currentValue) => {
    return currentValue
  });
};


export const getNonNullCompositionFieldNames = (financialRecords, dbMapping) => {
  return Object.keys(dbMapping).reduce((list, dbFieldName) => {
    if (fieldHasValues(financialRecords, dbFieldName)) {
      list.push(dbFieldName);
    }
    return list
  }, []);
};


export const getNonNullSpecs = ({financialRecords, specs, fieldPrefix}) => {
  return specs.filter(spec => {
    return fieldHasValues(financialRecords, fieldPrefix + "_" + spec.dbRevenueType)
  })
};




export const getNonNullArrSpecs = ({financialRecords, specs}) => {
  return specs.filter(spec => {
    return fieldHasValues(financialRecords, 'arr_ending_' + spec.dbRevenueType)
  })
};


export const hasGrossProfitCostDetailTypes = ['Operating Costs', 'SG&A', 'Functional'];

export const hasGrossProfit = (costDetailType) => {
  return hasGrossProfitCostDetailTypes.includes(costDetailType)
};

export const getCostDetailType = (records) => {

  const costDetailTypes = records.map(record => {
    return record.cost_detail_type
  });

  if (costDetailTypes.includes('Functional')) {
    return 'Functional'
  }

  if (costDetailTypes.includes('SG&A')) {
    return 'SG&A'
  }

  if (costDetailTypes.includes('Operating Costs')) {
    return 'Operating Costs'
  }


  if (costDetailTypes.includes('Total Costs')) {
    return 'Total Costs'
  }


};


export const functionalOpexSpecs = [
  {field: 'research_and_development', displayName: 'R&D'},
  {field: 'sales_and_marketing', displayName: 'S&M'},
  {field: 'general_and_administrative', displayName: 'G&A'},
];

export const functionalOpexMarginSpecs = functionalOpexSpecs.map(spec => {
  return {field: spec.field + '_margin', displayName: spec.displayName}
});

export const sgaSpecs = [
  {field: 'research_and_development', displayName: 'R&D'},
  {field: 'sales_general_and_administrative', displayName: 'SG&A'},
];

export const sgaMarginSpecs = sgaSpecs.map(spec => {
  return {field: spec.field + '_margin', displayName: spec.displayName}
});
