import React, {useEffect} from 'react';
import {Button, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import {
    fetchFacetsForDoradoValuationRecords, downloadDoradoValuationRecords
} from '../../../../actions/dorado/doradoValuationActions';
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../../common/request/convert';
import ViewWithSidebar from '../../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../../common/hooks";
import {getFilterComponentsFromSpecs} from "../../../../common/filter/add";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import {
    fetchDoradoValuationRecords,
    fetchPointSurDoradoValuationRecords
} from '../../../../features/dorado/doradoValuationRecords'
import ChartComponent from "./MaxMarginChart";
import DoradoSidebar from "../../CommonFilters";
import {createAddValueToQuery, createAppendSortToQuery} from "../../../../common/query/add";



const sortOptions = [
    {
        text: 'Max Pretax FCF Margin',
        value: 'exit_maximized_pretax_fcf_margin_ntm',
        key: 'exit_maximized_pretax_fcf_margin_ntm'
    },
    {
        text: 'Max EBITDA Margin',
        value: 'exit_maximized_ebitda_margin_ntm',
        key: 'exit_maximized_ebitda_margin_ntm'
    },
    {
        text: 'Exit Gross Margin',
        value: 'exit_ntm_gross_margin',
        key: 'exit_ntm_gross_margin'
    },
    {
        text: 'Capex Margin',
        value: 'exit_maximized_capital_expenditures_percent_of_sales_ntm',
        key: 'exit_maximized_capital_expenditures_percent_of_sales_ntm'
    },



];



export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {doradoValuationRecords, loadingDoradoValuationRecords} = useSelector(state => {
        return {
            doradoValuationRecords: state.doradoValuation.records,
            loadingDoradoValuationRecords: state.doradoValuation.loading
        }
    }, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const onChangeSort = createAppendSortToQuery({history, searchProps, maximumSortColumns: 1});

    const sortArray = searchProps.sort || [];
    const sortValue = sortArray.length ? sortArray[0][0] : null;

    const sidebar = (
        <div>

            <Header>Filters</Header>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={doradoValuationRecords.length} label='Records' size="tiny"/>
                </Grid.Row>
            </Grid>
            <Form>
                <Form.Select
                    fluid
                    inline
                    options={sortOptions}
                    onChange={(e, d) => onChangeSort(d.value, "-1")}
                    value={sortValue}
                    label="Sort"
                />
            </Form>
            <DoradoSidebar/>

        </div>
    );

    return (
        <ViewWithSidebar mainView={<ChartComponent doradoValuationRecords={doradoValuationRecords}/>} sidebarView={sidebar}/>
    )

}


const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    dispatch(fetchPointSurDoradoValuationRecords({sort, filters}));

};
