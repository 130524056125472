import {getBasePortfolioAndBenchmark} from "../returnsChart/alpha";
import moment from "moment";
import {zip} from "../../../common/common";


export const fieldSpecs = {};


export const getDailyAlphaSeries = ({portfolioStates}) => {

    const dates = portfolioStates.map(r => r.standard_date);
    const longReturns = portfolioStates.map(r => r.portfolio_state_report.time_weighted_long_return);
    const shortReturns = portfolioStates.map(r => r.portfolio_state_report.time_weighted_short_return);

    const cumulativealphaSeries = zip([longReturns, shortReturns]).map(row => {

        const [longReturn, shortReturn] = row;

        return longReturn - shortReturn;

    });

    let previousCumulative = 0;

    return zip([dates, cumulativealphaSeries]).map(row => {

        const [date, cumulativeAlpha] = row;

        const record = {
            standard_date: date,
            cumulative_alpha: cumulativeAlpha,
            daily_alpha: cumulativeAlpha - previousCumulative
        }

        previousCumulative = cumulativeAlpha;

        return record

    })


}