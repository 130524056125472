import {AttributionType, Fund} from '../../common/constants'
import React from "react";
import {formatSharePriceFixedDigits} from "../../common/format";


export const fieldSpecs = {

  fund: {
    parse: (v) => {
      if (v == null) {
        return Fund.pointSur
      }
      return v
    }
  },
  attributionType: {
    parse: (v) => {
      if (v == null) {
        return AttributionType.attributed
      }
      return v
    }
  }

};

export class CurrencySharePriceFormatter extends React.Component {
    render() {

        if (this.props.value.price_per_share == null) {
            return <div/>
        }

        let prefix = '';
        if (this.props.value.trading_currency !== 'USD') {
            prefix = this.props.value.trading_currency + ' ';
        }

        return <div
            className="align-right">{prefix + formatSharePriceFixedDigits(this.props.value.price_per_share)}</div>
    }
}


