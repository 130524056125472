import qs from 'qs';
import deepEqual from 'deep-equal'


// searchprops is a key:value dict
// keys include:
// search: a list of ordered search terms, field: asc/desc
// filter: a dict of filters


export const serializeSearchPropsToQueryString = ({history, searchProps}) => {
    history.push(history.location.pathname + qs.stringify(searchProps, {
        addQueryPrefix: true,
        strictNullHandling: true
    }));
};

export const createAddValueToQuery = ({history, searchProps, fieldName}) => {
    // generic add value
    return (event, data) => {
        searchProps[fieldName] = data.value;
        history.push(history.location.pathname + qs.stringify(searchProps, {addQueryPrefix: true}));
    };
};

export const createSetFieldValueOnQuery = ({history, searchProps, fieldName, value}) => {
    return (event, data) => {
        searchProps[fieldName] = value;
        history.push(history.location.pathname + qs.stringify(searchProps, {addQueryPrefix: true}));
    };
};

export const createSetFieldValuesOnQuery = ({history, searchProps, changes}) => {
    return (event, data) => {

        searchProps = {
            ...searchProps,
            ...changes
        }
        history.push(history.location.pathname + qs.stringify(searchProps, {addQueryPrefix: true}));
    };
};


export const createAddCheckedValueToQuery = ({history, searchProps, fieldName}) => {
    // generic add value
    return (event, data) => {
        searchProps[fieldName] = data.checked;
        history.push(history.location.pathname + qs.stringify(searchProps, {addQueryPrefix: true}));
    };
};

export const createToggleCheckedValueOnQuery = ({history, searchProps, fieldName, trueValue, falseValue}) => {
    // generic add value
    return (event, data) => {
        searchProps[fieldName] = data.checked ? trueValue : falseValue;
        history.push(history.location.pathname + qs.stringify(searchProps, {addQueryPrefix: true}));
    };
};


export const createAppendSortToQuery = ({history, searchProps, maximumSortColumns}) => {
    // return a function which will add sort to searchProps and then serialize into the querystring

    maximumSortColumns = maximumSortColumns || 3;

    return (sortColumn, sortDirection) => {

        let sort = searchProps.sort || [];

        // filter out duplicate sort targetWeightColumns
        sort = sort.filter((spec) => {
            const column = spec[0];
            return column !== sortColumn;
        });

        // if sortDirection is null we actually want to drop the sort
        if (sortDirection != null) {
            sort.push([sortColumn, sortDirection]);
        }

        if (sort.length > maximumSortColumns) {
            sort = sort.slice(-maximumSortColumns);
        }
        searchProps.sort = sort;
        serializeSearchPropsToQueryString({history, searchProps});
    };

};


export const createAddTermsFilterToQuery = ({history, searchProps, fieldName}) => {

    return (event, data) => {

        // value is an array of values for multi-select
        // sort to turn into a set we can compare
        let values = data.value;
        values.sort();

        let originalFilters = searchProps.filters || {};

        const newFilters = {...originalFilters};
        if (values.length === 0) {
            delete newFilters[fieldName]
        } else {
            newFilters[fieldName] = {
                type: 'terms',
                terms: values
            };
        }

        if (deepEqual(newFilters, {})) {
            delete searchProps.filters
        } else {
            searchProps.filters = newFilters;
        }

        serializeSearchPropsToQueryString({history, searchProps});

    };

};


export const createAddTermFilterToQuery = ({history, searchProps, fieldName}) => {

    return (event, data) => {

        let value = data.value;

        let originalFilters = searchProps.filters || {};

        const newFilters = {...originalFilters};
        if (isNoSelectionFlag(value)) {
            delete newFilters[fieldName]
        } else {
            newFilters[fieldName] = {
                type: 'term',
                term: value
            };
        }

        if (deepEqual(newFilters, {})) {
            delete searchProps.filters
        } else {
            searchProps.filters = newFilters;
        }

        serializeSearchPropsToQueryString({history, searchProps});

    };
};

export const setTermFilterValuesToQuery = ({history, searchProps, valueByfieldName}) => {

    let originalFilters = searchProps.filters || {};

    const newFilters = {...originalFilters};

    for (let fieldName of Object.keys(valueByfieldName)) {
        let value = valueByfieldName[fieldName];
        if (isNoSelectionFlag(value)) {
            delete newFilters[fieldName]
        } else {
            newFilters[fieldName] = {
                type: 'term',
                term: value
            };
        }
    }

    if (deepEqual(newFilters, {})) {
        delete searchProps.filters
    } else {
        searchProps.filters = newFilters;
    }

    serializeSearchPropsToQueryString({history, searchProps});


};


export const createAddRangeFilterToQuery = ({history, searchProps, fieldName, isGreaterThanFilter}) => {
    // of form:
    // {
    //   type: range,
    //   $gte: 1000,
    //   $lte: 10000,
    // }


    const operator = isGreaterThanFilter ? '$gte' : '$lte';

    return (event, data) => {
        const value = data.value;

        let originalFilters = searchProps.filters || {};

        const newFilters = {...originalFilters};
        const filter = newFilters[fieldName] || {type: 'range'};

        if (value == null) {
            delete filter[operator];
        } else {
            filter[operator] = value;
        }

        newFilters[fieldName] = filter;
        if (filter['$gte'] == null && filter['$lte'] == null) {
            delete newFilters[fieldName]
        }

        if (deepEqual(newFilters, {})) {
            delete searchProps.filters
        } else {
            searchProps.filters = newFilters;
        }

        serializeSearchPropsToQueryString({history, searchProps});


    }

};


export const removeDeletableFilterFromQuery = ({history, searchProps, fieldName}) => {
    return (event, data) => {

        searchProps.visibleFilters = searchProps.visibleFilters.filter(field => {
            return field !== fieldName
        });

        delete searchProps.filters[fieldName];

        if (deepEqual(searchProps.filters, {})) {
            delete searchProps.filters
        }

        serializeSearchPropsToQueryString({history, searchProps});

    }
};


export const createAddTrueOnlyCheckboxFilterToQuery = ({
                                                           history,
                                                           searchProps,
                                                           fieldName,
                                                           invertTrueFalse = false,
                                                           invertCheckedAction = false
                                                       }) => {
    // only two values, true or null.  No false value


    return (event, data) => {

        let originalFilters = searchProps.filters || {};

        const newFilters = {...originalFilters};

        const shouldAddFilter = invertCheckedAction ? !data.checked : data.checked;

        if (shouldAddFilter) {
            newFilters[fieldName] = {
                type: 'term',
                term: invertTrueFalse ? false : true
            }
            console.log(newFilters)
        } else {
            delete newFilters[fieldName]
        }

        if (deepEqual(newFilters, {})) {
            delete searchProps.filters
        } else {
            searchProps.filters = newFilters;
        }

        serializeSearchPropsToQueryString({history, searchProps});

    };
};


export const createAddTextFilterToQuery = ({history, searchProps, fieldName}) => {

    return (event, data) => {

        let value = data.value;

        let originalFilters = searchProps.filters || {};

        const newFilters = {...originalFilters};
        if (value === '' || value == null) {
            delete newFilters[fieldName]
        } else {
            newFilters[fieldName] = {
                type: 'text',
                value: value
            };
        }

        if (deepEqual(newFilters, {})) {
            delete searchProps.filters
        } else {
            searchProps.filters = newFilters;
        }

        serializeSearchPropsToQueryString({history, searchProps});
    };
};


const isNoSelectionFlag = (value) => {
    return value === '<no selection>'
};