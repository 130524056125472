import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Divider, Table} from 'semantic-ui-react'
import {sumArray} from "../../../common/common";
import {
    formatFullDate,
    formatNoDecimalNoNull,
    formatPercentNoNull, formatPercentOneDecimalNoNull,
    formatPercentTwoDecimalNoNull
} from "../../../common/format";
import moment from "moment";

const ReactHighcharts = require('react-highcharts');


export default ({dailyTradeAnalysisSummaries}) => {


    const favorableVwapPercentOfNav = sumArray(dailyTradeAnalysisSummaries.map(r => r.total_gain_vs_vwap_usd_percent_of_nav));
    const favorableClosePercentOfNav = sumArray(dailyTradeAnalysisSummaries.map(r => r.total_gain_vs_close_usd_percent_of_nav));

    const validVwapRecords = dailyTradeAnalysisSummaries.filter(r => r.total_gain_vs_vwap_usd != null).sort(function (a, b) {
        var keyA = new Date(a.standard_date),
            keyB = new Date(b.standard_date);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
    });

    const validCloseRecords = dailyTradeAnalysisSummaries.filter(r => r.total_gain_vs_close_usd != null).sort(function (a, b) {
        var keyA = new Date(a.standard_date),
            keyB = new Date(b.standard_date);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
    });

    const favorableVwapDays = validVwapRecords.filter(r => r.total_gain_vs_vwap_usd > 0);
    const favorableVwapPercent = validVwapRecords.length ? favorableVwapDays.length / validVwapRecords.length : 0;

    const favorableCloseDays = validCloseRecords.filter(r => r.total_gain_vs_close_usd > 0);
    const favorableClosePercent = validCloseRecords.length ? favorableCloseDays.length / validCloseRecords.length : 0;

    const totalVwapFavorableDollars = sumArray(validVwapRecords.map(r => r.total_gain_vs_vwap_usd));
    const totalCloseFavorableDollars = sumArray(validVwapRecords.map(r => r.total_gain_vs_close_usd));

    let vsVwapRunningTotal = 0.0;
    let vsCloseRunningTotal = 0.0;

    const cumulativeVwapNavRecords = validVwapRecords.map(r => {

        vsVwapRunningTotal += r.total_gain_vs_vwap_usd_percent_of_nav;
        return {
            x: moment(r.standard_date).valueOf(),
            y: vsVwapRunningTotal
        }
    });

    const cumulativeCloseNavRecords = validCloseRecords.map(r => {

        vsCloseRunningTotal += r.total_gain_vs_close_usd_percent_of_nav;
        return {
            x: moment(r.standard_date).valueOf(),
            y: vsCloseRunningTotal
        }
    });


    const series = [
        {
            name: 'Vs Close',
            data: cumulativeCloseNavRecords,
            type: 'line',
            color: '#8073ac',
            lineWidth: 4,
        },
        {
            name: 'Vs VWAP',
            data: cumulativeVwapNavRecords,
            type: 'line',
            color: '#1b7837',
            lineWidth: 4,
        },

    ];

    const config = {
        chart: {
            type: 'line'
        },
        title: {
            text: undefined
        },
        xAxis: {
            // title: {
            //     text: 'Date'
            // },
            type: 'datetime',
            labels: {
                formatter: function () {
                    return formatFullDate(this.value, false);
                }
            }
        },
        yAxis: [
            {
                title: {
                    text: 'Cumulative Outperformance % of NAV'
                },
                labels: {
                    formatter: function () {
                        return formatPercentTwoDecimalNoNull(this.value);
                    }

                },
            },

        ],
        tooltip: {
            useHTML: true,
            shared: false,
            headerFormat: '',
            pointFormatter: function () {
                return '<table>' +
                    '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                    '<tr><td>' + this.series.name + '</td><td>' + formatPercentTwoDecimalNoNull(this.y) + '</td></tr>' +
                    '</table>'
            },
        },
        plotOptions: {
            series: {
                animation: false,
                turboThreshold: 0,
            },
            line: {
                marker: {
                    enabled: false
                },
            }
        },
        legend: {
            enabled: true
        },

        series: series,

    };

    console.log(cumulativeVwapNavRecords)

    return (
        <React.Fragment>
            <Divider horizontal>
                Summary
            </Divider>
            <Table compact collapsing basic={'very'}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>

                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            vs VWAP
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            vs Close
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Favorable % NAV
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(favorableVwapPercentOfNav)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(favorableClosePercentOfNav)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Favorable Days %
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentNoNull(favorableVwapPercent)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentNoNull(favorableClosePercent)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Favorable $
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(totalVwapFavorableDollars)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(totalCloseFavorableDollars)}
                        </Table.Cell>
                    </Table.Row>


                </Table.Body>

            </Table>
            <Divider horizontal>
                Cumulative vs VWAP % NAV
            </Divider>
            <ReactHighcharts config={config}></ReactHighcharts>
        </React.Fragment>
    )


}