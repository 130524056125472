const commonFilterSpecs = [

    {
        field: 'country_name',
        label: 'Country',
        type: 'terms',
        allowNull: false,
    },
    {
        field: 'region_name',
        label: 'Region',
        type: 'terms',
        allowNull: false
    },
    {
        field: 'geography_group',
        label: 'Geography',
        type: 'terms',
        allowNull: false
    },
    {
        field: 'business_quality',
        label: 'Business Quality',
        type: 'terms',
    },
    {
        field: 'management_score',
        label: 'Management Score',
        type: 'terms',
    },
    {
        field: 'tracking_rank',
        label: 'Tracking Rank',
        type: 'terms',
    },
    {
        field: 'diluted_market_value',
        label: 'Market Value',
        type: 'range',
    },
    {
        field: 'diluted_enterprise_value',
        label: 'Enterprise Value',
        type: 'range',
    },
    {
        field: 'ipo_date',
        label: 'IPO Year',
        type: 'range',
    },


    {
        field: 'primary_market_type',
        label: 'Primary Market Type',
        type: 'terms',
    },
    {
        field: 'lbo_track',
        label: 'LBO Track List',
        type: 'checkbox'
    },
    {
        field: 'is_public',
        label: 'Is Public?',
        type: 'booleanTerms'
    },
    {
        field: 'is_or_was_public',
        label: 'Is or Was Public?',
        type: 'booleanTerms'
    },
    {
        field: 'has_valid_period_info',
        label: 'Has Valid Period Info?',
        type: 'booleanTerms'
    },
    {
        field: 'halt_ciq_data_updates',
        label: 'Halt CIQ Data Updates?',
        type: 'booleanTerms'
    },
    {
        field: 'has_valid_share_classes',
        label: 'Has Valid Share Classes?',
        type: 'booleanTerms'
    },
    {
        field: 'in_dorado_universe',
        label: 'In Dorado Universe?',
        type: 'booleanTerms'
    },
    {
        field: 'is_or_was_in_portfolio',
        label: 'Is or Was in Portfolio?',
        type: 'booleanTerms'
    },


];


export const commonFilterSpecsByName = commonFilterSpecs.reduce((obj, spec) => {
    obj[spec.field] = spec;
    return obj
}, {});


