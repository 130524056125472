import { createSlice } from '@reduxjs/toolkit'

import client from '../../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";
const doradoValuationReturnAudits = client.service('dorado_valuation_return_audits');


const doradoValuationReturnAuditsFeatureSlice = createSlice({
    name: 'doradoValuationReturnAudit',
    initialState: getInitialStateWithLoading({
        records: [],
    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchDoradoValuationReturnAudits', destinationVariable: 'records'}),

    }
});


export const fetchDoradoValuationReturnAudits = ({asOfDate}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoValuationReturnAuditsFeatureSlice,
        prefix: 'fetchDoradoValuationReturnAudits',
        getPayload: () => {return doradoValuationReturnAudits.find({
            query: {
                as_of_date: asOfDate
            }
        })}

    })
};


export default doradoValuationReturnAuditsFeatureSlice.reducer