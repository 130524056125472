import {PortfolioType} from "./constants";


export const getFullPortfolioName = (portfolio) => {
    if (!portfolio.is_backtest) {
        return portfolio.type
    }

    if (portfolio.type === PortfolioType.benchmark) {
        return 'Benchmark | ' + portfolio.name
    }

    return 'Backtest | ' + portfolio.name
}


export const getPortfolioOptionText = (portfolio) => {
    return portfolio.type + (portfolio.is_backtest ? ' | ' + portfolio.name : '')
}