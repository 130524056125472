import React from 'react'
import {Form, Input} from 'semantic-ui-react'


export default function RangeFilter({
                                    label,
                                    onChangeMin,
                                    minValue,
                                    onChangeMax,
                                    maxValue
                                  }) {

  return (
    <Form.Group widths='equal'>
      <Form.Input fluid label={label} placeholder='min' onChange={onChangeMin} value={minValue}/>
      <Form.Field>
        <label style={{color: 'white'}}>{'max'}</label>
        <Input fluid placeholder='max' onChange={onChangeMax} value={maxValue}/>
      </Form.Field>
    </Form.Group>

  )


}
