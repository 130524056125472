


export const getAbbreviation = (name) => {

    if (name === 'wahlswede@accel-kkr.com') {
        return 'WA'
    }
    if (name === 'tbuckingham@accel-kkr.com') {
        return 'TB'
    }

    return name


}