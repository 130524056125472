

const defaultState = {
  roles: [],
  loadingRoles: true,

  role: {},
  loadingRole: true,

  loadingFacets: true,
  facets: {}
};


export default (state=defaultState, action={}) => {
  switch (action.type) {

    case 'FETCH_ROLES_FOR_COMPANY_PENDING': {
      return {
        ...state,
        loadingRoles: true,
      }
    }

    case 'FETCH_ROLES_FOR_COMPANY_FULFILLED': {

      return {
        ...state,
        roles: action.payload.data,
        loadingRoles: false
      }

    }

    case 'DELETE_ROLE_FULFILLED': {
      const id = action.payload.id;
      return {
        ...state,
        roles: state.roles.filter(item => item.id !== id)
      }
    }

    case 'NEW_ROLE': {
      return {
        ...state,
        role: {},
        loadingRole: false
      }
    }

    case 'FETCH_ROLE_PENDING': {
      return {
        ...state,
        loadingRole: true,
        role: {}
      }
    }

    case 'FETCH_ROLE_FULFILLED': {
      return {
        ...state,
        role: action.payload.data,
        loadingRole: false
      }
    }

    case 'FETCH_ROLE_FACETS_PENDING': {
      return {
        ...state,
        loadingFacets: true
      }
    }

    case 'FETCH_ROLE_FACETS_FULFILLED': {
      return {
        ...state,
        facets: action.payload.data,
        loadingFacets: false
      }
    }

    case 'MODIFY_ROLE_FIELD': {
      return {
        ...state,
        role: {
          ...state.role,
          [action.payload.fieldName]: action.payload.value
        }
      }
    }

    default:
      return state;
  }
}
