import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Form, Grid, Statistic, Table} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import {columns} from "./common";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import moment from 'moment';
import {fetchSecurityLendingRateRecords} from "../../../features/portfolio/portfolioFeatureSlice";
import RdgTable from "../../../components/rdg/RdgTable";


export default () => {

    const {securityLendingRateRecords, loadingSecurityLendingRateRecords} = useSelector(state => state.portfolio, shallowEqual);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['sort, filters'],
        requestData
    });

    const sidebar = (
        <div>

        </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable loading={loadingSecurityLendingRateRecords}
                                             records={securityLendingRateRecords}
                                             columns={columns}
        />}

                         sidebarView={sidebar}/>
    )

};


const requestData = ({dispatch, searchProps}) => {

    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    dispatch(fetchSecurityLendingRateRecords({sort, filters}))


};