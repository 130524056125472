import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic, Table} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {createAddCheckedValueToQuery, createAddValueToQuery} from "../../../common/query/add";
import {
    exposureMetrics,
    fieldSpecs,
    series1ChartSpecificationByName,
    series1ChartSpecifications,
    unitOptions
} from './common'
import {useReloadOnChange} from "../../../common/hooks";
import {
    fetchPortfolios,
    fetchPortfoliosExMembers,
    fetchPortfolioStates
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfoliosSummaryTable from "../../portfolio/PortfoliosSummaryTable";
import Chart from './Chart'
import qs from 'qs';


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});
    const match = useRouteMatch();

    const {
        portfolios,
        loadingPortfolios,
        portfolioStates,
        loadingPortfolioStates,
    } = useSelector(state => state.portfolio, shallowEqual);

    useEffect(() => {
        dispatch(fetchPortfoliosExMembers())
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort', 'portfolio_id'],
        requestData: refreshData
    });

    if (searchProps.portfolio_id == null) {
        if (!loadingPortfolios && portfolios.length > 0) {
            searchProps.portfolio_id = portfolios[0].id;
            const url = location.pathname + qs.stringify(searchProps, {addQueryPrefix: true})
            return <Redirect to={url}/>
        }
    }


    const portfolioOptions = portfolios.map(p => {
        return {
            value: p.id,
            text: p.type,
            key: p.id
        }
    })

    const exposureOptions = exposureMetrics.map(metric => {
        return (
            <Form.Checkbox
                key={metric.name}
                label={metric.name}
                checked={searchProps[metric.name] === true || searchProps[metric.name] === 'true'}
                onChange={createAddCheckedValueToQuery({
                    history,
                    searchProps,
                    fieldName: metric.name
                })}

            />)
    })

    const sidebar = (
        <div>
            <br/>

            <Form>
                <Form.Select
                    fluid
                    inline
                    options={portfolioOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'portfolio_id'})}
                    value={searchProps.portfolio_id}
                    label="Portfolio"
                />
                <Form.Select
                    fluid
                    inline
                    options={unitOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'unitOption'})}
                    value={searchProps.unitOption}
                    label="Units"
                />
                {exposureOptions}
            </Form>

            <Divider horizontal>Target Ranges</Divider>
            <Table basic='very' collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Gross Long
                        </Table.Cell>
                        <Table.Cell>
                            100% - 140%
                        </Table.Cell>
                        <Table.Cell>
                            Longs are our core source of alpha.  We should have at least full (100%) exposure to our best ideas.
                            An additional 30-40% leverage to our best long ideas (130-140%) can be very impactful to returns
                            if we feel comfortable managing the risk.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Net Exposure
                        </Table.Cell>
                        <Table.Cell>
                            50 - 100%
                        </Table.Cell>
                        <Table.Cell>
                            Stocks go up over time so it makes sense to have at least 50% market exposure.  Conversely,
                            we don't want to be levered long the market (>100%) - it is preferable to add short exposure to
                            balance long ideas.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Gross Short
                        </Table.Cell>
                        <Table.Cell>
                            20% - 90%
                        </Table.Cell>
                        <Table.Cell>
                            Primarily a plug to manage our net exposure.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Gross Exposure
                        </Table.Cell>
                        <Table.Cell>
                            120% - 230%
                        </Table.Cell>
                        <Table.Cell>
                            If our longs and shorts are not correlated, we must closely monitor the total "bets" outstanding.
                            200%+ gross exposure means we run the risk of a 2x market move if longs/shorts go in opposite directions.
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>


        </div>
    );

    return (
        <ViewWithSidebar mainView={<Chart portfolioStates={portfolioStates}
                                          loading={loadingPortfolioStates}
                                          series1ChartSpec={series1ChartSpecificationByName[searchProps.unitOption]}
                                          unitOption={searchProps.unitOption}
                                          searchProps={searchProps}

        />}
                         sidebarView={sidebar}/>
    )

}


const refreshData = ({dispatch, searchProps}) => {

    const sort = {
        standard_date: 1
    }

    const filters = {
        portfolio_id: searchProps.portfolio_id
    }

    if (searchProps.portfolio_id != null) {
        dispatch(fetchPortfolioStates({sort, filters}));

    }

    }


