import { createSlice } from '@reduxjs/toolkit'
import client from '../../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";

const igvContributionService = client.service('igv_contribution');
const longShortRegressionStatsService = client.service('portfolio_long_short_regression_stats');


const portfolioAnalysisFeatureSlice = createSlice({
    name: 'portfolioAnalysis',
    initialState: {
        ...getInitialStateWithLoading({

            igvContributionData: {
                igv_contribution_records: []
            },

            portfolioLongShortRegressionStats: {
                regression_summary: {},
                points: []
            }

        }),
    },
    reducers: {
        ...makeStandardReducers({
            prefix: 'fetchIgvContributionRecords',
            destinationVariable: 'igvContributionData'
        }),
        ...makeStandardReducers({
            prefix: 'fetchPortfolioLongShortRegressionStats',
            destinationVariable: 'portfolioLongShortRegressionStats'
        }),

    }
});


export const fetchIgvContributionRecords = ({startDate, endDate}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioAnalysisFeatureSlice,
        prefix: 'fetchIgvContributionRecords',
        getPayload: () => {
            return igvContributionService.find({
                query: {
                    start_date: startDate,
                    end_date: endDate
                }
            })
        }
    })
};


export const fetchPortfolioLongShortRegressionStats = ({portfolioId, startDate, endDate}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: portfolioAnalysisFeatureSlice,
        prefix: 'fetchPortfolioLongShortRegressionStats',
        getPayload: () => {
            return longShortRegressionStatsService.find({
                query: {
                    start_date: startDate,
                    end_date: endDate,
                    portfolio_id: portfolioId
                }
            })
        }
    })
};





export default portfolioAnalysisFeatureSlice.reducer