import {useLocation, useParams} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {parseSearchProps} from "./query/parse";
import {useEffect} from "react";
import {pollJobQueue} from "../features/pollingProcessFeatureSlice";
import {usePrevious} from "./hooks";


export const usePollForCompletion = ({onComplete, fieldSpecs={}}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const matchParams = useParams();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        refreshingRecords,
        processTaskId,
        processRefreshCount
    } = useSelector(state => {return state.polling}, shallowEqual);

    // const previousProcessRefreshCount = usePrevious(processRefreshCount);

    useEffect(() => {
        if (processTaskId != null) {
            dispatch(pollJobQueue({taskId: processTaskId, processRefreshCount})).then(action => {
                if (action.payload.data.status === 'finished') {
                    onComplete({dispatch, searchProps});
                }
            })
        }

    });


}