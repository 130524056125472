import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
const earningsDateFeedService = client.service('earnings_date_feed');


const earningsDateFeatureSlice = createSlice({
    name: 'earningsDate',
    initialState: {
        loading: true,
        records: [],
    },
    reducers: {
        fetchEarningsDateFeedRecordsPending: (state) => {
            state.loading = true;
        },
        fetchEarningsDateFeedRecordsFulfilled: (state, action) => {
            state.loading = false;
            state.records = action.payload.data;
        }
    }
});

const {fetchEarningsDateFeedRecordsPending, fetchEarningsDateFeedRecordsFulfilled} = earningsDateFeatureSlice.actions;

export const fetchEarningsDateFeedRecords = () => async (dispatch, getState) => {
    try {
        dispatch(fetchEarningsDateFeedRecordsPending());

        const payload = await earningsDateFeedService.find({
            query: {}
        });
        dispatch(fetchEarningsDateFeedRecordsFulfilled(payload))
    } catch (e) {
        alert('Error: ' + e);
    }
};

export default earningsDateFeatureSlice.reducer