import React, {Component} from 'react';
import {connect} from 'react-redux';
import qs from 'qs'
import {withRouter} from 'react-router'
import {Form} from 'semantic-ui-react';
import {createAddValueToQuery} from "../../common/query/add";


class AkkrCurrencySelect extends Component {

  render() {

    const searchProps = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    const history = this.props.history;

    const selectedCurrencyType = searchProps.currencyType || 'Filing';
    const company = this.props.company;

    let filingCurrency = null;
    if (!this.props.loadingCompany) {
      filingCurrency = company.filing_currency;
    }

    const onChange = createAddValueToQuery({history, searchProps, fieldName: 'currencyType'});

    const options = [];
    if (filingCurrency != null && filingCurrency !== 'USD') {
      options.push({
        text: filingCurrency + ' (filing)',
        value: 'Filing'
      });
      options.push({
        text: 'USD',
        value: 'USD'
      });
    } else {
      options.push({
        text: 'USD',
        value: 'Filing'
      })
    }

    return (
      <Form.Select options={options}
                   value={selectedCurrencyType}
                   onChange={onChange}
                   inline
                   label="Currency"/>
    )
  }
}

function mapStateToProps(state) {
  return {
    company: state.akkrCompaniesStore.company,
    loadingCompany: state.akkrCompaniesStore.loadingCompany,
  }
}


export default withRouter(connect(mapStateToProps, {})(AkkrCurrencySelect));
