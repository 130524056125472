import {commonColumnsByName} from "../../../common/reactDataGrid/columns";
import {
    BoolToNoFlagFormatter,
    BoolToNoTextFormatter,
    BoolToYesCheckFormatter,
    DoradoUniverseTypeFormatter,
    NoDecimalNoNullFormatter,
    NoDecimalNoNullOrZeroFormatter,
    PercentFourDecimalNoNullFormatter,
    PercentOneDecimalNoNullFormatter,
    TwoDecimalNoNullFormatter,
    UtcDateFormatter,
    UtcToLocalFullDateTimeFormatter
} from "../../../common/reactDataGrid/formatters";
import React from "react";
import {Editors} from 'react-data-grid-addons';
import {benchNullOption} from "../../../common/query/null";
import {DoradoUniverseType} from "../../../common/constants";
import {boolOptions} from "../../../common/reactDataGrid/common";
import {
    inDoradoUniverseColumn,
    pointSurIsValidColumn, psErrorTextColumn
} from "../../../components/rdg/dataQuality";
import {Link} from "react-router-dom";

const {DropDownEditor} = Editors;


export class PortfolioReportLinkFormatter extends React.Component {
    render() {

        const name = this.props.value.name;
        const portfolioId = this.props.value.portfolio_id;

        if (this.props.value.isHeader) {
            return <div style={{'fontWeight': 'bold'}}>
                {this.props.value.name}
            </div>
        }

        return (
            <Link to={'/dorado_backtest_report?portfolio_id=' + portfolioId}>
                {name}
            </Link>)
    }
}

export class PeriodReportsLinkFormatter extends React.Component {
    render() {

        const portfolioId = this.props.value;

        if (portfolioId == null) {
            return <div/>
        }

        return (
            <Link to={'/period_reports?portfolio_id=' + portfolioId}>
                Periods
            </Link>)
    }
}


export class BacktestDetailLinkFormatter extends React.Component {
    render() {

        const doradoPortfolioId = this.props.value;

        return (
            <Link to={'/dorado_portfolio_backtest_detail/' + doradoPortfolioId}>
                Detail
            </Link>)
    }
}


export const columns = [

    {
        key: 'name',
        name: 'Name',
        width: 200,
        formatter: PortfolioReportLinkFormatter,
        _getValueFromRecord: (r) => {
            if (r.isHeader) {
                return {
                    name: r.name,
                    isHeader: r.isHeader
                }
            }

            return {
                portfolio_id: r.portfolio.id,
                name: r.portfolio.name
            }
        },
        frozen: true
    },
    {
        key: 'type',
        name: 'Type',
        width: 160,
        frozen: true,
        _getValueFromRecord: (r) => {
            return r.portfolio.type
        },
    },
    {
        key: 'is_backtest',
        name: 'Is Backtest?',
        width: 80,
        frozen: true,
        _getValueFromRecord: (r) => {
            return r.portfolio.is_backtest
        },
        formatter: BoolToYesCheckFormatter
    },
    {
        key: 'period_reports',
        name: 'Period Reports',
        width: 90,
        formatter: PeriodReportsLinkFormatter,
        _getValueFromRecord: (r) => {
            return r.portfolio.id
        },
    },
    {
        key: 'created_date',
        name: 'Created',
        formatter: UtcToLocalFullDateTimeFormatter,
        width: 150,
    },
    {
        key: 'start_date',
        name: 'Start Date',
        formatter: UtcDateFormatter,
        width: 100,
    },
    {
        key: 'end_date',
        name: 'End Date',
        formatter: UtcDateFormatter,
        width: 100,
    },
    {
        key: 'days',
        name: 'Days',
        width: 50,
    },
    {
        key: 'time_weighted_return',
        name: 'Time Weighted Return',
        width: 80,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'time_weighted_return_ex_short_contribution',
        name: 'TWR ex-Shorts',
        formatter: PercentOneDecimalNoNullFormatter,
        width: 70,
    },
    {
        key: 'short_contribution_to_return',
        name: 'Short Contrib. to Return',
        formatter: PercentOneDecimalNoNullFormatter,
        width: 70,
    },
    {
        key: 'irr',
        name: 'IRR',
        width: 80,
        formatter: PercentOneDecimalNoNullFormatter
    },

    {
        key: 'standard_deviation',
        name: 'Std. Dev.',
        width: 80,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'sharpe_ratio',
        name: 'Sharpe Ratio',
        width: 80,
        formatter: TwoDecimalNoNullFormatter
    },
    {
        key: 'sortino_ratio',
        name: 'Sortino Ratio',
        width: 80,
        formatter: TwoDecimalNoNullFormatter
    },
    {
        key: 'maximum_drawdown',
        name: 'Max Drawdown',
        width: 80,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'average_securities_short_market_value_percent',
        name: 'Avg % Short',
        width: 80,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'b_001',
        name: '',
        width: 20,
    },
    {
        key: 'long_short_correlation',
        name: 'Long-Short Correlation',
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80
    },
    {
        key: 'long_short_r2',
        name: 'Long-Short R2',
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80
    },
    {
        key: 'long_short_slope',
        name: 'Long-Short Slope',
        formatter: TwoDecimalNoNullFormatter,
        width: 80
    },
    {
        key: 'long_short_intercept',
        name: 'Long-Short Intercept',
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80
    },
    {
        key: 'b_002',
        name: '',
        width: 20,
    },
    {
        key: 'long_positions_total_gain',
        name: 'Long Positions Total Gain $',
        width: 100,
        formatter: NoDecimalNoNullFormatter
    },
    {
        key: 'short_positions_total_gain',
        name: 'Short Positions Total Gain $',
        width: 100,
        formatter: NoDecimalNoNullFormatter
    },


    {
        key: 'assumed_implementation_shortfall_percent',
        name: 'Assumed Trade Cost % (Imp. Shortfall)',
        width: 100,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'target_maximum_long_concentration_score',
        name: 'Target Max Long Conc. Score',
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'average_long_herfindahl_index',
        name: 'Avg Long HH Index',
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'return_score_penalty_per_concentration_score_point',
        name: 'Return / Conc per 100 HH',
        width: 100,
        formatter: PercentFourDecimalNoNullFormatter,
        _getValueFromRecord: (r) => {

            if (r.return_score_penalty_per_concentration_score_point == null) {
                return ""
            }

            return r.return_score_penalty_per_concentration_score_point * 100
        },
    },


    {
        key: 'cumul_total_trading_costs_percent_average_equity',
        name: 'Cumul. Total Soft/Hard Trade Cost % Equity',
        width: 100,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'avg_trading_day_gross_trades_percent_of_equity',
        name: 'Avg Daily Net Trades % Equity',
        width: 80,
        formatter: PercentOneDecimalNoNullFormatter
    },


    {
        key: 'average_top_long_position_weight',
        name: 'Top Long Position Avg % Equity',
        width: 70,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'average_top_five_long_positions_weight',
        name: 'Top 5 Long Pos. Avg % Equity',
        width: 70,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'average_long_position_count',
        name: 'Avg # Long Positions',
        width: 70,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'average_long_execution_score',
        name: 'Avg Long Execution Score',
        width: 70,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'average_short_execution_score',
        name: 'Avg Short Execution Score',
        width: 70,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'portfolio_id',
        name: 'Portfolio ID',
        width: 70,
        _getValueFromRecord: (r) => {
            return r.portfolio.id
        }
    },
    // {
    //     key: 'realized_short_term_gain',
    //     name: 'Realized Short Term Gain',
    //     width: 100,
    //     _getValueFromRecord: (r) => {
    //         return r.dorado_portfolio_report.realized_short_term_gain
    //     },
    //     formatter: NoDecimalNoNullOrZeroFormatter
    // },
    // {
    //     key: 'realized_long_term_gain',
    //     name: 'Realized Long Term Gain',
    //     width: 100,
    //     _getValueFromRecord: (r) => {
    //         return r.dorado_portfolio_report.realized_long_term_gain
    //     },
    //     formatter: NoDecimalNoNullOrZeroFormatter
    // },
    // {
    //     key: 'unrealized_short_term_gain',
    //     name: 'Unrealized Short Term Gain',
    //     width: 100,
    //     _getValueFromRecord: (r) => {
    //         return r.dorado_portfolio_report.unrealized_short_term_gain
    //     },
    //     formatter: NoDecimalNoNullOrZeroFormatter
    // },
    // {
    //     key: 'unrealized_long_term_gain',
    //     name: 'Unrealized Long Term Gain',
    //     width: 100,
    //     _getValueFromRecord: (r) => {
    //         return r.dorado_portfolio_report.unrealized_long_term_gain
    //     },
    //     formatter: NoDecimalNoNullOrZeroFormatter
    // },


]