const defaultState = {
    portfolios: [],
    loadingPortfolios: true,


    portfolio: {},
    portfolioPositions: [],

    // old
    portfolioAnalytics: {
        long_positions: [],
        short_positions: [],
        long_aggregate: {name: 'Long'},
        short_aggregate: {name: 'Short'}
    },
    loadingPortfolioAnalytics: true,


    loadingCurrentReturnsAnalysis: true,
    currentReturnsAnalysis: {
        long_positions: [],
        short_positions: [],
        aggregates: []
    },

    portfolioMetadata: {},

    // new
    securityTrades: [],
    loadingSecurityTrades: true,
    securityTradesFacets: {},
    loadingSecurityTradesFacets: true,

    dividendRecords: [],
    loadingDividendRecords: true,

    securityRecords: [],
    loadingSecurityRecords: true

};


export default (state = defaultState, action = {}) => {
    switch (action.type) {

        case 'FETCH_PORTFOLIOS_PENDING': {
            return {
                ...state,
                loadingPortfolios: true
            }
        }

        case 'FETCH_PORTFOLIOS_FULFILLED': {
            return {
                ...state,
                portfolios: action.payload.data,
                loadingPortfolios: false
            }
        }

        case 'FETCH_PORTFOLIO_FULFILLED': {
            return {
                ...state,
                portfolio: action.payload.data,
                portfolioPositions: action.payload.data.portfolio_positions
            }

        }

        case 'NEW_PORTFOLIO_IN_STORE': {
            return {
                ...state,
                portfolio: {},
                portfolioPositions: []
            }
        }

        case 'NEW_PORTFOLIO_FULFILLED': {
            return {
                ...state,
                portfolio: action.payload.data,
                portfolios: [...state.portfolios, action.payload.data]
            }
        }

        case 'DELETE_PORTFOLIO_PENDING': {
            return {
                ...state,
                portfolio: {},
                portfolioPositions: [],
                loadingPortfolios: true,
            }
        }

        case 'DELETE_PORTFOLIO_FULFILLED': {

            const deletedId = parseInt(action.payload.data.id);

            return {
                ...state,
                portfolio: {},
                portfolioPositions: [],
                portfolios: state.portfolios.filter(portfolio => {
                    return portfolio.id !== deletedId
                }),
                loadingPortfolios: false,
            }
        }

        case 'UPDATE_PORTFOLIO_FULFILLED': {
            const portfolio = action.payload.data;

            return {
                ...state,
                portfolio: action.payload.data,
                portfolios: state.portfolios.map(p => {
                    if (portfolio.id === p.id) {
                        return portfolio
                    }
                    return p
                })
            }
        }

        case 'ADD_NEW_PORTFOLIO_POSITION': {

            return {
                ...state,
                portfolioPositions: [...state.portfolioPositions, action.payload]
            }
        }

        case 'SET_PORTFOLIO_NAME': {
            return {
                ...state,
                portfolio: {
                    ...state.portfolio,
                    name: action.payload.name
                }
            }
        }

        case 'SET_PORTFOLIO_POSITION_VALUE': {

            const update = action.payload.update;
            const company_id = action.payload.company_id;

            return {
                ...state,
                portfolioPositions: state.portfolioPositions.map(position => {
                    if (position.company_id === company_id) {

                        return {
                            ...position,
                            ...update
                        }

                    }

                    return position
                })
            }
        }

        case 'REMOVE_PORTFOLIO_POSITION': {

            const company_id = action.payload.company_id;
            return {
                ...state,
                portfolioPositions: state.portfolioPositions.filter(position => {
                    return position.company_id !== company_id
                })
            }


        }

        case 'FETCH_PORTFOLIO_ANALYTICS_FULFILLED': {

            return {
                ...state,
                portfolioAnalytics: action.payload.data,
                loadingPortfolioAnalytics: false
            }

        }

        case 'FETCH_PORTFOLIO_CURRENT_RETURNS_PENDING': {
            return {
                ...state,
                loadingCurrentReturnsAnalysis: true
            }
        }

        case 'FETCH_PORTFOLIO_CURRENT_RETURNS_FULFILLED': {
            return {
                ...state,
                currentReturnsAnalysis: action.payload.data,
                loadingCurrentReturnsAnalysis: false
            }
        }

        case 'FETCH_PORTFOLIO_METADATA_FULFILLED': {
            return {
                ...state,
                portfolioMetadata: action.payload.data
            }
        }

        case 'FETCH_PORTFOLIO_TRADE_RECORDS_PENDING': {
            return {
                ...state,
                loadingSecurityTrades: true
            }
        }

        case 'FETCH_PORTFOLIO_TRADE_RECORDS_FULFILLED': {
            return {
                ...state,
                securityTrades: action.payload.data,
                loadingSecurityTrades: false
            }
        }

        case 'FETCH_PORTFOLIO_TRADE_FACETS_PENDING': {
            return {
                ...state,
                loadingSecurityTradesFacets: true
            }
        }

        case 'FETCH_PORTFOLIO_TRADE_FACETS_FULFILLED': {
            return {
                ...state,
                loadingSecurityTradesFacets: false,
                securityTradesFacets: action.payload.data
            }
        }

        case 'FETCH_PORTFOLIO_DIVIDEND_RECORDS_PENDING': {
            return {
                ...state,
                loadingDividendRecords: true
            }
        }

        case 'FETCH_PORTFOLIO_DIVIDEND_RECORDS_FULFILLED': {
            return {
                ...state,
                dividendRecords: action.payload.data,
                loadingDividendRecords: false
            }
        }

        case 'FETCH_SECURITY_RECORDS_PENDING': {
            return {
                ...state,
                loadingSecurityRecords: true
            }
        }

        case 'FETCH_SECURITY_RECORDS_FULFILLED': {
            return {
                ...state,
                loadingSecurityRecords: false,
                securityRecords: action.payload.data
            }
        }

        case "PATCH_SECURITY_FULFILLED": {

            const patchedSecurity = action.payload.data;

            return {
                ...state,
                loadingSecurityRecords: false,
                securityRecords: state.securityRecords.map(s => {
                    if (s.id === patchedSecurity.id) {
                        return patchedSecurity
                    } else {
                        return s
                    }
                })
            }
        }


        default:
            return state;
    }
}
