import React, { Component} from 'react';
import {Container, Header, List, Table} from "semantic-ui-react";


export default () => {
    return (
        <Container>

            <Header as='h3'>How to Import a Pre-IPO Company into Dorado</Header>

            <ol>
                <li>
                    Set <b>“Is Pre IPO”</b> = Yes in Tags
                </li>
                <li>
                    Run Company Import.  Check “Trading Currency” and “Filing Currency” in Tags.
                    If these values are missing, set values for them.
                </li>
                <li>
                    Run Company Import.  “Has Valid CIQ Share Class Securities” should now be “Yes”.
                    Check “Has Valid Period Info”.
                    If this is false, CapIQ is missing the fiscal year end month and/or the last reported period.

                </li>
                <li>
                    To fix a fiscal year issue: in Tags, manually assign “Fiscal Year End Month Override”.
                    Also make sure you have created a valid benchmark file.
                    The last reported historical quarter will be used as the last reported quarter.
                </li>
            </ol>

        </Container>
    )
}
