

export function sortFunctional ({array, sortKey, sortDirection= 1}) {
    if (sortKey == null || sortDirection == null) {
        return array;
    }

    return array.slice().sort(compareValues(sortKey, sortDirection));
}


function compareValues(key, order=1) {
    // https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/

    return (objA, objB) => {
        const valueA = (typeof objA[key] === 'string') ? objA[key].toUpperCase() : objA[key];
        const valueB = (typeof objB[key] === 'string') ? objB[key].toUpperCase() : objB[key];

        if (valueA == valueB) {
            return 0;
        }

        if (valueA == null && valueB == null) {
          return 0
        }

        if (valueA == null) {
            return 1;
        }
        if (valueB == null) {
            return -1;
        }

        let comparison = 0;
        if (valueA > valueB) {
            comparison = 1;
        } else if (valueA < valueB) {
            comparison = -1;
        }
        return (
            (order === -1) ? (comparison * -1) : comparison
        );


    };


    // return function(objA, objB) {
    //
    //
    //
    //
    //     if(!objA.hasOwnProperty(key) || !objB.hasOwnProperty(key)) {
    //         // property doesn't exist on either object
    //         return 0;
    //     }
    //
    //
    //
    //     const varA = (typeof objA[key] === 'string') ?
    //         objA[key].toUpperCase() : objA[key];
    //     const varB = (typeof objB[key] === 'string') ?
    //         objB[key].toUpperCase() : objB[key];
    //
    //     let comparison = 0;
    //     if (varA > varB) {
    //         comparison = 1;
    //     } else if (varA < varB) {
    //         comparison = -1;
    //     }
    //     return (
    //         (order === -1) ? (comparison * -1) : comparison
    //     );
    // };
}



export function numericSortToSemanticText(numericSort) {
    return numericSort === 1 ? 'ascending' : 'descending';
}



export const createHandleSortClick = (columnName, sortColumn, sortDirection, onChangeSort) => {
    return () => {
        if (columnName === sortColumn) {
            const newDir = sortDirection === 1 ? -1 : 1;
            onChangeSort(columnName, newDir);
        } else {
            onChangeSort(columnName, -1);
        }
    };
};

export const setSortedAttribute = (columnName, sortColumn, sortDirection) => {
    return sortColumn === columnName ? numericSortToSemanticText(sortDirection) : null;
};
