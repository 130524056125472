import React, {useEffect} from 'react'
import {Divider, Grid, Table} from "semantic-ui-react";
import {
    formatNoDecimalNoNull,
    formatPercentOneDecimalNoNull,
    formatPercentTwoDecimalNoNull
} from "../../../common/format";
import {sumArray} from "../../../common/common";
import {TradeType} from "../../../common/constants";


export default ({securityTrades, loading}) => {

    const buys = securityTrades.filter(r => r.trade_type === TradeType.buy);
    const sells = securityTrades.filter(r => r.trade_type === TradeType.sell);

    const buyGross = sumArray(buys.map(r => r.gross_value_before_transaction_cost_usd));
    const sellGross = sumArray(sells.map(r => r.gross_value_before_transaction_cost_usd));

    const buyNet = sumArray(buys.map(r => r.net_value_usd));
    const sellNet = sumArray(sells.map(r => r.net_value_usd));

    return (
        <React.Fragment>
            <Divider horizontal>
                Successful Trades
            </Divider>
            <Table textAlign={'right'} compact={'very'} basic={'very'} collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign={'left'}>
                            Type
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Gross $USD
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Net $USD
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Buy
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(buyGross)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(buyNet)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Sell
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(-sellGross)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(-sellNet)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Net Buy
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(buyGross - sellGross)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(buyNet - sellNet)}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </React.Fragment>
    )


}