import {
    CompanyLinkFormatter,
    PercentOneDecimalNoNullFormatter,
    PercentTwoDecimalNoZeroFormatter,
    NoDecimalNoNullOrZeroFormatter,
    SharePriceFormatter,
    TwoDecimalNoNullFormatter
} from "../../../common/reactDataGrid/formatters";
import {companyOrEtfBacktestLinkColumn} from "../../dorado/common";


export const portfolioPositionColumns = [
    companyOrEtfBacktestLinkColumn,
    {
        key: 'market_value',
        name: 'Market Value $',
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'price_per_share_trading',
        name: '$/share (trading)',
        width: 100,
        formatter: SharePriceFormatter
    },
    {
        key: 'net_shares',
        name: 'Shares',
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'percent_of_net_asset_value',
        name: '% of NAV',
        width: 70,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
];