import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import {formatPercentOneDecimalNoNull, formatSharePrice} from "../../../common/format";
import {formatSimpleDate} from "../../../common/formatDate";
import {scaleThreshold} from 'd3-scale'
import moment from 'moment';
import {colorRange, dateDomain} from "./common";
// import Highcharts from 'highcharts';
// import Annotations from 'highcharts/modules/annotations';

const ReactHighcharts = require('react-highcharts');
// require('highcharts/modules/annotations')(ReactHighcharts.Highcharts)


// Annotations(Highcharts)





const colorScale = scaleThreshold().domain(dateDomain).range(colorRange);

class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const portfolioLongShortRegressionStats = this.props.portfolioLongShortRegressionStats;
        const loading = this.props.loading;

        const regressionSummary = portfolioLongShortRegressionStats.regression_summary;


        const predictionSeries = {
            name: 'Long vs Short Returns',
            data: portfolioLongShortRegressionStats.points.map(r => {
                return {
                    x: r.long_return,
                    y: r.short_return,
                    ...r,
                    marker: {
                        fillColor: colorScale(moment(r.standard_date).valueOf()),
                    }
                }
            }),
            tooltip: {
                pointFormatter: function () {
                    return [
                        'Date: ' + formatSimpleDate(this.standard_date),
                        'Long Return (x): ' + formatPercentOneDecimalNoNull(this.x),
                        'Short Return (y): ' + formatPercentOneDecimalNoNull(this.y),

                    ].join("<br/>")
                }

            },
        };

        const allSeries = [
            predictionSeries
        ]

        if (regressionSummary.slope != null) {

            const xs = predictionSeries.data.map(r => r.x);

            const minX = Math.min(...xs);
            const maxX = Math.max(...xs);

            allSeries.push({
                name: 'Regression',
                type: 'line',
                marker: {
                    enabled: false
                },
                enableMouseTracking: false,
                color: 'rgba(100,100,100,.25)',
                data: [
                    {
                        x: minX,
                        y: regressionSummary.intercept + regressionSummary.slope * minX
                    },
                    {
                        x: maxX,
                        y: regressionSummary.intercept + regressionSummary.slope * maxX
                    }
                ],
                tooltip: {
                    enabled: false
                }
            })



        }


        allSeries.push({
                name: 'Attractive Below this Line',
                type: 'line',
                marker: {
                    enabled: false
                },
                enableMouseTracking: false,
                color: 'rgba(116,173,209,.25)',
                data: [
                    {
                        x: -.05,
                        y: -.06
                    },
                    {
                        x: -.02,
                        y: -.03
                    },
                    {
                        x: 0.0,
                        y: -.01
                    },
                    {
                        x: .02,
                        y: .01
                    },
                    {
                        x: .05,
                        y: .04
                    },

                ],
                tooltip: {
                    enabled: false
                }
            })


        const config = {
            chart: {
                type: 'scatter',
                zoomType: 'xy'
            },
            title: {
                text: 'Short Return as a Function of Long Return'
            },
            xAxis: {
                title: {
                    text: 'Long Return'
                },
                labels: {
                    formatter: function () {
                        return formatPercentOneDecimalNoNull(this.value);
                    }
                }
            },
            // legend: {
            //     labelFormatter: function () {
            //
            //         console.log(this.name)
            //         return this.name
            //
            //     }
            // },
            yAxis: {
                title: {
                    text: 'Short Return'
                },
                labels: {
                    formatter: function () {
                        return formatPercentOneDecimalNoNull(this.value);
                    }
                }
            },
            plotOptions: {
                series: {
                    animation: false,
                    stickyTracking: false,
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        style: {
                            fontSize: "9px",
                            fontWeight: 'normal',
                            textOutline: '1px',
                            color: 'rgba(0, 0, 0, .5)'
                        },
                        format: '{point.label}'
                    },
                },
                scatter: {

                    marker: {
                        symbol: 'circle',
                        radius: 4,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },

                }
            },

            series: allSeries,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent