import React, {useEffect} from 'react';
import {Divider, Form, Header} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {formatAbsoluteDateString, formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import {createAddValueToQuery} from "../../../common/query/add";
import TermFilter from "../../../components/filter/TermFilter";
import RdgTable from "../../../components/rdg/RdgTable";
import {
    fetchDoradoPortfolio,
    fetchDoradoPortfolioPositionsForPortfolioState,
    fetchDoradoPortfolioStates, fetchDoradoPortfolioWithReport
} from "../../../features/dorado/doradoPortfolio";
import {columnsByViewOption, fieldSpecs, getRecordsFromViewOption, viewOptions} from "./common";
import {ForecastType as DoradoPortfolioType, ForecastType} from "../../../common/constants";
import {
    fetchDoradoTargetData,
} from "../../../features/dorado/doradoTargetPortfolio";
import {convertQuerySortToFeathersSort} from "../../../common/request/convert";
import TargetPortfolioAttributesTable from "../../dorado/targetCommon/TargetPortfolioAttributesTable";
import moment from 'moment';
import TargetTradeOrdersTable from "../../dorado/targetCommon/TargetTradeOrdersTable";
import TradesSummary from "./TradesSummary";
import PortfolioSummary from "./PortfolioSummary";
import PortfolioSelect from "../../dorado/PortfolioSelect";
import {fetchPortfolios, fetchPortfolioStates} from "../../../features/portfolio/portfolioFeatureSlice";
import qs from 'qs';


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    let {
        portfolioStates,
        loadingPortfolioStates,
        portfolios,
        loadingPortfolios,
    } = useSelector(state => {return state.portfolio}, shallowEqual);

    const {
        doradoTargetData,
        loadingDoradoTargetData,
    } = useSelector(state => {return state.doradoTargetPortfolio}, shallowEqual);

    if (portfolioStates.length > 0 && portfolioStates[0].portfolio_id !== searchProps.portfolio_id) {
        portfolioStates = [];
        loadingPortfolioStates = true;
    }

    useEffect(() => {
        dispatch(fetchPortfolios({filters: {is_backtest: true}}));
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['portfolio_id'],
        requestData: requestPortfolioStates
    });

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['portfolio_state_id', 'sort'],
        requestData
    });

    console.log(doradoTargetData)

    if (searchProps.portfolio_id == null && portfolios.length > 0) {
        searchProps.portfolio_id = portfolios[0].id;
        const url = location.pathname + qs.stringify(searchProps, {addQueryPrefix: true})
        return <Redirect to={url}/>
    }

    const validWeekdayPortfolioStates = portfolioStates.filter(s => {

        if (s.is_initial_state) {
            return false
        }

        const weekDay = moment(s.standard_date).isoWeekday();

        return !(weekDay === 6 || weekDay === 7);

    })

    if (searchProps.portfolio_state_id == null && validWeekdayPortfolioStates.length > 0) {
        searchProps.portfolio_state_id = validWeekdayPortfolioStates[0].id;
        const url = location.pathname + qs.stringify(searchProps, {addQueryPrefix: true})
        return <Redirect to={url}/>
    }

    const portfolioOptions = validWeekdayPortfolioStates.map(portfolioState => {
        return {
            text: formatAbsoluteDateString({dateString: portfolioState.standard_date, format: 'M/D/YYYY (ddd)'}),
            key: portfolioState.id,
            value: portfolioState.id
        }
    });

    const [priorDayPortfolioState, endingPortfolioState] = getBeginningAndEndingPortfolioState({portfolioStates, portfolio_state_id: searchProps.portfolio_state_id});

    const viewOption = searchProps.viewOption;
    const columns = columnsByViewOption[viewOption];
    const records = getRecordsFromViewOption({
        viewOption,
        doradoTargetData
    });

    const targetPortfolioState = doradoTargetData.dorado_target_portfolio_state;

    const loadingRecords = loadingDoradoTargetData;

    const buyLongOrders = doradoTargetData.dorado_target_security_orders.filter(order => order.is_short_sale_or_cover === false);
    const buyCoverOrders = doradoTargetData.dorado_target_security_orders.filter(order => order.is_short_sale_or_cover === true);

    const sellLongOrders = doradoTargetData.dorado_target_security_orders.filter(order => order.is_short_sale_or_cover === false);
    const sellShortOrders = doradoTargetData.dorado_target_security_orders.filter(order => order.is_short_sale_or_cover === true);

    const sidebar = (
        <div>
            <Divider horizontal>Portfolio</Divider>

            <Form>
                <PortfolioSelect filters={{is_backtest: true}}/>
                <TermFilter allowNullSelection={false}
                            label='Backtest Date'
                            options={portfolioOptions}
                            value={searchProps.portfolio_state_id}
                            onChange={createAddValueToQuery({
                                history,
                                searchProps,
                                fieldName: 'portfolio_state_id'
                            })}
                            loading={loadingPortfolioStates}
                />
                <TermFilter allowNullSelection={false}
                            label='View'
                            options={viewOptions}
                            value={searchProps.viewOption}
                            onChange={createAddValueToQuery({
                                history,
                                searchProps,
                                fieldName: 'viewOption'
                            })}
                            loading={loadingPortfolioStates}
                />
            </Form>
            <TargetPortfolioAttributesTable targetPortfolioState={targetPortfolioState} loading={loadingDoradoTargetData}/>
            <PortfolioSummary priorDayPortfolioState={priorDayPortfolioState}
                              beginningPortfolioState={doradoTargetData.portfolio_state}
                              endingPortfolioState={endingPortfolioState}
                              />
            <TargetTradeOrdersTable buyLongOrders={buyLongOrders}
                                    buyCoverOrders={buyCoverOrders}
                                    sellShortOrders={sellShortOrders}
                                    sellLongOrders={sellLongOrders}
            />
            <TradesSummary securityTrades={doradoTargetData.security_trades}/>

            <Divider horizontal>Definitions</Divider>
            <p>
                Target Portfolio <b>Last Price per Share</b> is the VWAP on the day.  It represents the price we would
                evaluate during that day of trading.
            </p>
        </div>
    );



    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns} loading={loadingRecords} records={records}/>}
                         sidebarView={sidebar}
                         sidebarOpenWidth={5}
        />
    )

}


const requestPortfolioStates = ({dispatch, searchProps}) => {
    const portfolio_id = searchProps.portfolio_id;

    if (portfolio_id != null) {
        dispatch(fetchPortfolioStates({
            filters: {
                portfolio_id: portfolio_id,
                is_end_of_day: true,
            },
            sort: {
                standard_date: 1
            }
        }));
    }



};


const requestData = ({dispatch, searchProps}) => {
    const portfolio_state_id = searchProps.portfolio_state_id;

    if (portfolio_state_id != null) {

        const sort = convertQuerySortToFeathersSort(searchProps.sort);

        const filters = {};

        dispatch(fetchDoradoTargetData({sort, filters, portfolioStateId: portfolio_state_id}))

    }

};


const getBeginningAndEndingPortfolioState = ({portfolioStates, portfolio_state_id: portfolio_state_id}) => {

    if (portfolio_state_id == null) {
        return [null, null]
    }

    let endState = portfolioStates.filter(r => r.id === portfolio_state_id);

    if (endState.length === 0) {
        return [null, null]
    }

    endState = endState[0];

    const priorStandardDate = moment(endState.standard_date).subtract(1, 'days').format('YYYY-MM-DD')

    const beginState = portfolioStates.filter(r => r.standard_date === priorStandardDate)[0];

    return [beginState, endState]

}