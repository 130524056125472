import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Form} from 'semantic-ui-react'
import TextareaAutosize from "react-textarea-autosize";
import {modifyCompanyNote} from "../../actions/companyNoteActions"


export default ({label, fieldName}) => {

    const dispatch = useDispatch();

    const {companyNote, loadingCompanyNote} = useSelector(state => state.companyStore, shallowEqual);

    let fieldValue = loadingCompanyNote ? '' : companyNote[fieldName];
    fieldValue = fieldValue == null ? '' : fieldValue;

    return (
        <Form.Field
            control={TextareaAutosize}
            loading={loadingCompanyNote.toString()}
            label={label}
            value={fieldValue}
            onChange={(e, d) => {
                modifyCompanyNote({dispatch, fieldName, text: e.target.value});
            }}
        />
    )

}



