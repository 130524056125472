import {Button, Header, Popup, Table} from "semantic-ui-react";
import {formatFullDateTimeUtcToLocal} from "../../common/formatDate";
import {formatOneDecimalNoNull} from "../../common/format";
import React from "react";
import {Link} from "react-router-dom";


const baseUrl = '/tags?sort%5B0%5D%5B0%5D=company_name&sort%5B0%5D%5B1%5D=1&sort%5B1%5D%5B0%5D=date_added&sort%5B1%5D%5B1%5D=-1'

const baseQueryByName = {
    'Invalid Share Classes' : '&filters%5Bhas_valid_share_classes%5D%5Btype%5D=term&filters%5Bhas_valid_share_classes%5D%5Bterm%5D=false',
    'Invalid Period Info': '&filters%5Bhas_valid_period_info%5D%5Btype%5D=term&filters%5Bhas_valid_period_info%5D%5Bterm%5D=false'
}



export default ({companyValidations}) => {


    const rows = companyValidations.map(validation => {

        const baseQuery = baseQueryByName[validation.name];

        let totalCount = <div>{validation.total_count}</div>

        if (baseQuery != null) {

            const url = baseUrl + baseQuery;
            totalCount = (
                <Link to={url}>
                    {validation.total_count}
                </Link>
            )
        }


        return (
            <Table.Row key={validation.name}>
                <Table.Cell>
                    {validation.name}
                </Table.Cell>
                <Table.Cell>
                    {validation.dorado_or_portfolio_count}
                </Table.Cell>
                <Table.Cell>
                    {totalCount}
                </Table.Cell>

            </Table.Row>
        )
    });


    return (
        <div>
            <Table basic={'very'} compact collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Validation</Table.HeaderCell>
                        <Table.HeaderCell>Dorado / Portfolio Ct</Table.HeaderCell>
                        <Table.HeaderCell>Total Ct</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        </div>

    )

}