import React from "react";
import {getCompanyLink} from "../../common/reactDataGrid/formatters";


export class PortfolioHeaderFormatter extends React.Component {
    render() {
        if (this.props.value.isHeader) {
            return <div style={{'fontWeight': 'bold'}}>
                {this.props.value.name}
            </div>
        }

        if (this.props.value.companyId == null) {
            return <div>{this.props.value.name}</div>
        }

        return getCompanyLink({companyId: this.props.value.companyId, companyName: this.props.value.name})

    }
}

export const portfolioHeaderColumn = {
    key: 'name',
    name: 'Name',
    width: 250,
    frozen: true,
    sortable: true,
    formatter: PortfolioHeaderFormatter,
    _getValueFromRecord: (r) => {

        if (r._isHeader) {
            return {
                name: r._name,
                isHeader: true
            }
        }

        let name = null;

        if (r.company_id != null) {
            name = r.company.company_name;
        }
        if (r.exchange_traded_fund_id != null) {
            name = r.exchange_traded_fund.name;
        }
        return {
            name,
            isHeader: false,
            companyId: r.company_id
        }
    }
};