const defaultState = {
    metadata: {},
    loading: true,
    processing: false

};


export default (state = defaultState, action = {}) => {
    switch (action.type) {

        case 'FIND_AKKR_METADATA_FULFILLED': {

            return {
                ...state,
                metadata: action.payload.data,
                loading: false

            }

        }

        case "START_AKKR_IMPORT_PROCESS_PENDING": {
            return {
                ...state,
                processing: true
            }
        }

        case "START_AKKR_IMPORT_PROCESS_FULFILLED": {
            return {
                ...state,
                processing: false
            }
        }

        case "START_AKKR_IMPORT_COMPANY_DATA_PROCESS_PENDING": {
            return {
                ...state,
                processing: true
            }
        }

        case "START_AKKR_IMPORT_COMPANY_DATA_PROCESS_FULFILLED": {
            return {
                ...state,
                processing: false
            }
        }

        case "CLEAR_AKKR_BENCHMARK_METADATA_RECORDS_PENDING": {
            return {
                ...state,
                processing: true
            }
        }

        case "CLEAR_AKKR_BENCHMARK_METADATA_RECORDS_FULFILLED": {
            return {
                ...state,
                processing: false
            }
        }

        default:
            return state;

    }
}
