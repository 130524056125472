import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Statistic, Table} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import TradesSidebar from '../TradesSidebar';
import {validMoment} from "../../../common/format";
import {
    fetchPortfolioDividends,
    fetchPortfolios,
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfoliosSummaryTable from "../PortfoliosSummaryTable";
import RdgTable from "../../../components/rdg/RdgTable";
import {columns} from './cols'


export default () => {

    const {portfolioDividends, loadingPortfolioDividends} = useSelector(state => state.portfolio, shallowEqual);
    const {portfolios, loadingPortfolios} = useSelector(state => state.portfolio, shallowEqual);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPortfolios({}));
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>
            <Grid relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Statistic horizontal value={portfolioDividends.length} label='Dividends Count' size="tiny"/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <TradesSidebar/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable loading={loadingPortfolioDividends}
                                             records={portfolioDividends}
                                             columns={columns}



        />}

                         sidebarView={sidebar}/>
    )

};

const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: {
            ...searchProps.filters,
            // ...{
            //     is_backtest: false
            // }
        },
        parseSpecByField: {}
    });

    if (filters.portfolio_id != null) {
        dispatch(fetchPortfolioDividends({sort, filters}));
    }


};
