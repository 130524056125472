import {parseFloatFromValue} from "../filter/parse";

export const convertQuerySortToFeathersSort = (sort) => {
//  sort is an array of [column, direction]
// we turn that into a dict of {key.order: direction} so that the server get request can be made

  sort = sort || [];
  return sort.reduce((d, row, index) => {
    const [key, value] = row;
    const orderedKey = key + '&' + index.toString();
    d[orderedKey] = value;
    return d
  }, {});


};


const rangeOperators = ['$lte', '$gte'];

export const convertFiltersToFeathersFilters = ({filters, parseSpecByField}) => {

  parseSpecByField = parseSpecByField || {};

  filters = filters || {};
  return Object.keys(filters).reduce((obj, fieldName) => {
    const filter = filters[fieldName];

    const parseSpec = parseSpecByField[fieldName] || {};

    if (filter.type === 'terms') {
      obj[fieldName] = {
        $in: filter.terms
      };
    }
    if (filter.type === 'term') {
      obj[fieldName] = filter.term;

    }
    if (filter.type === 'text') {
      obj[fieldName] = {
        $search: filter.value
      };
    }
    if (filter.type === 'range') {

      const q = {};
      const parseFuncs = getParseFuncByOperator(parseSpec);

      for (let operator of rangeOperators) {

        const parse = parseFuncs[operator];
        const value = parse(filter[operator]);
        if (value != null) {
          q[operator] = value;
        }
      }

      obj[fieldName] = q;
    }


    return obj


  }, {});
};


const getParseFuncByOperator = (parseSpec) => {

  if (parseSpec.parse != null) {
    return {
      $gte: parseSpec.parse,
      $lte: parseSpec.parse,
    }
  }

  if (parseSpec.$gte == null) {
    return {
      $gte: parseFloatFromValue,
      $lte: parseFloatFromValue
    }
  }

  return {
    $gte: parseSpec.$gte,
    $lte: parseSpec.$lte
  }

};




