import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import moment from "moment";
import {formatSharePrice, formatFullDate} from "../../../common/format";
import {series1ChartSpecificationByName} from "./common";

const ReactHighcharts = require('react-highcharts');


const getWindowHeightTopSection = () => {
  return window.innerHeight - 90 - 40;
};


class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightTopSection();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightTopSection();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const portfolioStates = this.props.portfolioStates;
        const loading = this.props.loading;

        const series1ChartSpec = series1ChartSpecificationByName[this.props.series1ChartName];

        const backtestBenchmarkName = this.props.backtestBenchmarkName;

        const benchmarkPortfolioStates = this.props.benchmarkPortfolioStates;

        const series = [
            {
                name: series1ChartSpec.name,
                data: portfolioStates.map(r => {
                    return {
                        x: moment(r.standard_date).valueOf(),
                        y: series1ChartSpec.getY(r)
                    }
                })
            }
        ];

        if (benchmarkPortfolioStates.length > 0) {
            series.push({
                name: backtestBenchmarkName,
                data: benchmarkPortfolioStates.map(r => {

                    if (r.dorado_portfolio_state_report == null) {
                        console.log(r)
                    }

                    return {
                        x: moment(r.standard_date).valueOf(),
                        y: series1ChartSpec.getY(r)
                    }
                })
            })
        }

        const config = {
            chart: {
                type: series1ChartSpec.type == null ? 'line' : series1ChartSpec.type,
                zoomType: 'xy',
            },
            title: {
                text: series1ChartSpec.name
            },
            xAxis: {
                title: {
                    text: 'Date'
                },
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return formatFullDate(this.value, false);
                    }
                },
            },
            yAxis: {
                min: series1ChartSpec.yMin,
                max: series1ChartSpec.yMax,
                title: {
                    text: series1ChartSpec.getYAxisLabel()
                },
                labels: {
                    formatter: function () {
                        return series1ChartSpec.formatY(this.value);
                    }

                }
            },
            tooltip: {
                useHTML: true,
                shared: false,
                headerFormat: '',
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                        '<tr><td>' + series1ChartSpec.getYAxisLabel() + '</td><td>' + series1ChartSpec.formatY(this.y) + '</td></tr>' +
                        '</table>'
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                },
                line: {
                    marker: {
                        enabled: false
                    },
                }
            },

            series: series,



        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent