import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {
    downloadDoradoPortfolioStatesWithReport,
    fetchDoradoPortfolioStatesWithReport,
    getDoradoPortfolio
} from "../../../features/dorado/doradoPortfolio";
import BacktestReturnsChart from './BacktestReturnsChart'
import {createAddValueToQuery} from "../../../common/query/add";
import {fieldSpecs, series1ChartSpecifications} from './common'
import {IgvReturnCheckbox, SpyReturnCheckbox} from './indices'
import {formatFullDateTimeUtcToLocal} from "../../../common/formatDate";



const chartOptions = series1ChartSpecifications.map(spec => {
    return {
        key: spec.name,
        value: spec.name,
        text: spec.name
    }
});


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});
    const match = useRouteMatch();

    const {
        doradoPortfolioStatesWithReport,
        loadingDoradoPortfolioStatesWithReport,

        doradoPortfolio,
        loadingDoradoPortfolio,

    } = useSelector(state => {return state.doradoPortfolio}, shallowEqual);

    const {dorado_portfolio_id} = match.params;

    useEffect(() => {

        dispatch(getDoradoPortfolio(dorado_portfolio_id));

        dispatch(fetchDoradoPortfolioStatesWithReport({
            filters: {
                dorado_portfolio_id: dorado_portfolio_id,
                is_end_of_day: true,
            }
        }));

    }, [dispatch, dorado_portfolio_id]);

    const sidebar = (
        <div>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Header as={'h3'}>
                            {doradoPortfolio.name}
                            <Header.Subheader>
                                {doradoPortfolio.type}
                            </Header.Subheader>
                        </Header>
                    <p>Created: {formatFullDateTimeUtcToLocal(doradoPortfolio.created_date)}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Statistic horizontal value={doradoPortfolioStatesWithReport.length} label='Records' size="tiny"/>
                </Grid.Row>
                <Grid.Row centered>
                    <Button basic color='blue' onClick={() => downloadDoradoPortfolioStatesWithReport({dispatch})}>
                        <Icon name='download'/>
                        Download
                    </Button>
                </Grid.Row>
            </Grid>

            <Form>
                <Form.Select
                    fluid
                    inline
                    options={chartOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'series1ChartName'})}
                    value={searchProps.series1ChartName}
                    label="Chart"
                />
            </Form>

        </div>
    );

    console.log(doradoPortfolioStatesWithReport)

    return (
        <ViewWithSidebar mainView={<BacktestReturnsChart portfolioStates={doradoPortfolioStatesWithReport}
                                                         loading={loadingDoradoPortfolioStatesWithReport}
                                                         series1ChartName={searchProps.series1ChartName}

                                                         spyIndexedReturnRecords={[]}
                                                         showSpyIndexedReturnRecords={searchProps.showSpy}
                                                         igvIndexedReturnRecords={[]}
                                                         showIgvIndexedReturnRecords={searchProps.showIgv}

        />}
                         sidebarView={sidebar}/>
    )

}


