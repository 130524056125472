import React from 'react';
import ChartComponent from './ChartComponent'
import {parseGteYearToDate, parseLteYearToDate, parseMillions} from "../../common/filter/parse";
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from "../../common/request/convert";
import ViewWithSidebar from '../../components/ViewWithSidebar'
import ChartSidebar from './ChartSidebar'
import {groupFieldSpecs} from "./commontbl";
import {useReloadOnChange} from "../../common/hooks";


export default () => {

    useReloadOnChange({
        fieldSpecs: groupFieldSpecs,
        queryMatchFields: ['filters', 'sort', 'currencyType'],
        requestData
    });

    return (
      <ViewWithSidebar mainView={<ChartComponent/>} sidebarView={<ChartSidebar/>}/>
    )

}


const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {
            diluted_market_value: {parse: parseMillions},
            diluted_enterprise_value: {parse: parseMillions},
            ipo_date: {$gte: parseGteYearToDate, $lte: parseLteYearToDate}
        }
    });

};

