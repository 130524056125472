import React from 'react';
import {
    DateFormatter, NoDecimalNoNullFormatter,
    PositiveNegativeTwoDecimalFormatter,
    SharePriceFormatter, SharePriceFourDecimalFormatter,
    UtcDateFormatter
} from "../../../common/reactDataGrid/formatters";


export const columns = [
    {
        key: 'standard_date',
        name: 'Date / Payment Date',
        sortable: true,
        formatter: DateFormatter,
        width: 90
    },
    {
        key: 'source_currency',
        name: 'Source Currency',
        sortable: true,
        width: 90
    },
    {
        key: 'destination_currency',
        name: 'Destination Currency',
        sortable: true,
        width: 90
    },
    {
        key: 'source_cash_outflow',
        name: 'Source Cash Outflow',
        sortable: true,
        width: 120,
        formatter: PositiveNegativeTwoDecimalFormatter,
    },
    {
        key: 'destination_cash_inflow',
        name: 'Destination Cash Inflow',
        sortable: true,
        width: 120,
        formatter: PositiveNegativeTwoDecimalFormatter,
    },
    {
        key: 'source_to_destination_multiplier',
        name: 'Source to Destination Multiplier',
        sortable: true,
        width: 120,
        formatter: SharePriceFourDecimalFormatter,
    },



];

