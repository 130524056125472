const defaultState = {
  shareClassSummaryRecords: [],
  loadingShareClassSummaryRecords: true,

  financialRecords: [],
  loadingFinancialRecords: true,

  valuationRecord: {},
  loadingValuationRecord: true,

  valuationPeriodRecords: [],
  loadingValuationPeriodRecords: true,
  lfqValuationPeriodRecords: [],
  loadingLfqValuationPeriodRecords: true,

  maximizedMarginRecords: [],
  company: {},
  loading: true,



};


export default (state=defaultState, action={}) => {
  switch (action.type) {

    case "FIND_SHARE_CLASS_SUMMARY_RECORDS_PENDING": {

      return {
        ...state,
        loadingShareClassSummaryRecords: true
      }
    }

    case "FIND_SHARE_CLASS_SUMMARY_RECORDS_FULFILLED": {

      return {
        ...state,
        loadingShareClassSummaryRecords: false,
        shareClassSummaryRecords: action.payload.data
      }
    }

    case "FIND_FINANCIAL_RECORDS_FOR_COMPANY_PENDING": {

      return {
        ...state,
        loadingFinancialRecords: true,
      }

    }

    case "FIND_FINANCIAL_RECORDS_FOR_COMPANY_FULFILLED": {

      return {
        ...state,
        loadingFinancialRecords: false,
        financialRecords: action.payload.data
      }

    }

    case "FIND_VALUATION_RECORD_FOR_COMPANY_PENDING": {
      return {
        ...state,
        valuationRecord: {},
        loadingValuationRecord: true
      }

    }

    case "FIND_VALUATION_RECORD_FOR_COMPANY_FULFILLED": {
      const records = action.payload.data;
      let record = {};
      if (records.length === 1) {
        record = action.payload.data[0];
      }
      if (records.length > 1) {
        console.log('ERROR: More than 1 companyValuation record');
      }
      return {
        ...state,
        valuationRecord: record,
        loadingValuationRecord: false
      }
    }

    case "FIND_VALUATION_PERIOD_RECORDS_FOR_COMPANY_PENDING": {

      return {
        ...state,
        loadingValuationPeriodRecords: true
      }
    }

    case "FIND_VALUATION_PERIOD_RECORDS_FOR_COMPANY_FULFILLED": {

      return {
        ...state,
        loadingValuationPeriodRecords: false,
        valuationPeriodRecords: action.payload.data
      }
    }

    case "FIND_LFQ_VALUATION_PERIOD_RECORDS_FOR_COMPANY_PENDING": {

      return {
        ...state,
        loadingLfqValuationPeriodRecords: true
      }
    }

    case "FIND_LFQ_VALUATION_PERIOD_RECORDS_FOR_COMPANY_FULFILLED": {

      return {
        ...state,
        loadingLfqValuationPeriodRecords: false,
        lfqValuationPeriodRecords: action.payload.data
      }
    }

    case "FIND_MAXIMIZED_MARGIN_RECORDS_FOR_COMPANY_FULFILLED": {

      return {
        ...state,
        maximizedMarginRecords: action.payload.data
      }
    }

    default:
      return state;

  }
}
