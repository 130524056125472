import React, {useEffect} from 'react';
import {Button, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../../common/request/convert';
import ViewWithSidebar from '../../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import {
    fetchDoradoValuationRecords,
    fetchPointSurDoradoValuationRecords
} from '../../../../features/dorado/doradoValuationRecords'
import Chart from "./Chart";
import DoradoSidebar from "../../CommonFilters";
import {fieldSpecs, chartOptions} from "./common";
import {createAddValueToQuery} from "../../../../common/query/add";



export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs: fieldSpecs});

    const {doradoValuationRecords, loadingDoradoValuationRecords} = useSelector(state => {
        return {
            doradoValuationRecords: state.doradoValuation.records,
            loadingDoradoValuationRecords: state.doradoValuation.loading
        }
    }, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>

            <Header>Filters</Header>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={doradoValuationRecords.length} label='Records' size="tiny"/>
                </Grid.Row>
            </Grid>
            <DoradoSidebar/>
            <br/>
            <Form>
                <Form.Select
                    fluid
                    inline
                    options={chartOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'series1ChartName'})}
                    value={searchProps.series1ChartName}
                    label="Chart"
                />
            </Form>

        </div>
    );

    return (
        <ViewWithSidebar mainView={<Chart doradoValuationRecords={doradoValuationRecords}
                                          series1ChartName={searchProps.series1ChartName}

        />} sidebarView={sidebar}/>
    )

}


const requestData = ({dispatch, searchProps}) => {
    const sort = {};
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    dispatch(fetchPointSurDoradoValuationRecords({sort, filters}));

};
