import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Button, Container, Divider, Form, Grid, Header, Table} from 'semantic-ui-react'
import {useReloadOnChange} from "../../../../common/hooks";
import CompanyHeader from "../../../../components/CompanyHeader";
import {
    getDoradoValuationRecordWithMetadata,
    fetchDoradoInterpolatedFinancialPeriodRecordsForValuationRecord,
    fetchCurrentDoradoValuationDataForCompany
} from "../../../../features/dorado/doradoCompanyDetail";
import FinancialComponent from "./FinancialComponent";
import ValuationRecordComponent from "./ValuationRecordComponent";
import {formatBooleanYesNo, validMoment} from "../../../../common/format";
import {fetchCompany} from "../../../../features/companyFeatureSlice";


export default () => {

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: [],
        matchFields: ['id'],
        requestData: requestCompanyData
    });

    const {
        company,
        loadingCompany,
    } = useSelector(state => state.company, shallowEqual);

    const {
        currentDoradoValuationData,
        loadingCurrentDoradoValuationData,
    } = useSelector(state => state.doradoCompanyDetail, shallowEqual);

    const loadingPage = loadingCompany || loadingCurrentDoradoValuationData;

    const doradoValuationRecord = currentDoradoValuationData.dorado_valuation_record;
    const interpolatedFinancialPeriodRecords = currentDoradoValuationData.interpolated_financial_period_records;

    const lastReportedPeriodText = loadingPage ? null : company.last_reported_period  + ' (' + validMoment(company.last_reported_period_end_date, 'M/D/YYYY') + ')'

    return (
        <Container fluid>
            <Grid padded>
                <Grid.Column width={2}>
                    <Grid.Row>
                        <CompanyHeader company={company} loading={loadingCompany}/>
                    </Grid.Row>
                    <Grid.Row>
                        <Table basic='very' compact='very' collapsing>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Filing Currency:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {company.filing_currency}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Trading Currency:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {company.trading_currency}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Last Reported Period:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {lastReportedPeriodText}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                    </Grid.Row>

                </Grid.Column>
                <Grid.Column width={5}>
                    <ValuationRecordComponent loading={loadingPage}
                                              doradoValuationRecord={doradoValuationRecord}
                                              company={company}
                    />
                </Grid.Column>
                <Grid.Column width={9}>
                    <FinancialComponent loading={loadingPage}
                                        financialRecords={interpolatedFinancialPeriodRecords}
                    />
                </Grid.Column>
            </Grid>

        </Container>
    )

}


const requestCompanyData = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;

    dispatch(fetchCompany({companyId: id}));
    dispatch(fetchCurrentDoradoValuationDataForCompany({companyId: id}))

};
