import React from 'react'
import {PortfolioType} from "../common/constants";
import {getPortfolioOptionText} from "../common/portfolio";
import {Form, Dropdown} from 'semantic-ui-react'
import {createSetFieldValuesOnQuery} from "../common/query/add";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../common/query/parse";


export const fieldSpecs = {
    portfolio_id: {
        parse: (v) => v == null ? null : parseInt(v)
    },
};


export default ({portfolios, value, onChange}) => {

    const history = useHistory();
    const location = useLocation();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    if (onChange == null) {
        onChange = (e, d) => {
            createSetFieldValuesOnQuery({
                history,
                searchProps,
                changes: {
                    portfolio_id: d.value,
                    portfolio_state_id: null
                }
            })()
        }

        value = searchProps.portfolio_id
    }


    const livePortfolios = portfolios.filter(p => p.is_backtest === false);
    const backtestPortfolios = portfolios.filter(p => p.is_backtest === true);

    const benchmarkReports = backtestPortfolios.filter(p => p.type === PortfolioType.benchmark);

    const remainderPortfolios = backtestPortfolios.filter(p => p.type !== PortfolioType.benchmark);

    // const doradoBacktestReports = backtestReports.filter(p => p.portfolio.type === PortfolioType.dorado);

    const livePortfolioOptions = getPortfolioOptions(livePortfolios);
    const benchmarkOptions = getPortfolioOptions(benchmarkReports)
    const remainderOptions = getPortfolioOptions(remainderPortfolios);

    // https://github.com/Semantic-Org/Semantic-UI-React/issues/1724
    const options = [
        ...livePortfolioOptions,
        {
            key: "divider1",
            children: <Dropdown.Divider/>,
            disabled: true
        },
        ...benchmarkOptions,
        {
            key: "divider2",
            children: <Dropdown.Divider/>,
            disabled: true
        },
        ...remainderOptions
    ]

    return (
        <Form.Dropdown selection
                       fluid
                       inline
                       label={'Portfolio'}
                       value={value}
                       onChange={onChange}
                       options={options}

        >
            {/*<Dropdown.Menu>*/}
            {/*    {livePortfolioItems}*/}
            {/*    <Dropdown.Divider/>*/}
            {/*    {getPortfolioDropdownItems(benchmarkReports)}*/}

            {/*</Dropdown.Menu>*/}
        </Form.Dropdown>

    )
}

const getPortfolioOptions = (portfolios) => {
    return portfolios.map(p => {
        return {
            value: p.id,
            text: getPortfolioOptionText(p),
            key: p.id
        }
    })
}


const getPortfolioDropdownItems = (portfolios) => {
    return portfolios.map(p => {
        return getPortfolioDropdownItem({
            value: p.id,
            text: getPortfolioOptionText(p),
            key: p.id
        })
    });
}


const getPortfolioDropdownItem = ({value, text, key}) => {
    return <Dropdown.Item key={key} value={value} text={text}/>
}