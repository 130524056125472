import {parseBoolean} from "../../../common/query/parse";


export const fieldSpecs = {
    showMedian: {
        parse: parseBoolean
    },
    show20thPercentile: {
        parse: parseBoolean
    },
    show80thPercentile: {
        parse: parseBoolean
    },
    showMethodologyAdjusted: {
        parse: parseBoolean
    },
    showOriginal: {
        parse: parseBoolean
    },
    portfolio_id: {
        parse: parseInt
    }
}
