import React from 'react';


import {
    DateFormatter,
    PositiveNegativeTwoDecimalFormatter,
    NoDecimalNoNullOrZeroFormatter,
    SharePriceFormatter,
    UtcDateFormatter,
    SharePriceFourDecimalFormatter,
    BoolToYesCheckFormatter,
    BoolToYesNoFormatter,
    BoolToYesFormatter,
    BoolToYesNoGrayFormatter,
    PercentTwoDecimalNoNullFormatter,
    PercentFourDecimalNoNullFormatter,
    OneDecimalNoNullFormatter
} from "../../../common/reactDataGrid/formatters";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";


export const fieldSpecs = {
    portfolio_id: {
        parse: (v) => (v === "" || v == null) ? null : parseInt(v)
    },
}



export const columns = [
    CompanyLinkFromSecurityListing,

    {
        key: 'standard_date',
        name: 'Trade Date',
        sortable: true,
        formatter: DateFormatter,
        width: 90,
        frozen: true
    },
    {
        key: 'trade_type',
        name: 'Type',
        sortable: true,
        width: 60,
        frozen: true
    },
    {
        key: 'is_short_sale_or_cover',
        name: 'Is Short Sale or Cover',
        sortable: true,
        width: 60,
        formatter: <BoolToYesNoGrayFormatter/>,
        frozen: true
        // formatter: <BoolToYesCheckFormatter color={'red'}/>
    },
    {
        key: 'is_intraday',
        name: 'Is Intraday',
        sortable: true,
        width: 60,
        formatter: <BoolToYesNoGrayFormatter/>
    },
    {
        key: 'source_account_type',
        name: 'Source Account',
        sortable: true,
        width: 200
    },
    {
        key: 'gross_value_before_transaction_cost_usd',
        name: 'Gross $USD',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110
    },
    {
        key: 'hard_transaction_cost_usd',
        name: 'Hard Trans Cost $USD',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110
    },
    {
        key: 'hard_transaction_cost_percent',
        name: 'Hard Transaction cost %',
        sortable: true,
        formatter: PercentFourDecimalNoNullFormatter,
        width: 100
    },
    {
        key: 'net_value_usd',
        name: 'Net $USD',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110
    },
    {
        key: 'spacer_1',
        name: '',
        sortable: false,
        width: 20
    },
    {
        key: 'trading_currency',
        name: 'Trading Currency',
        sortable: true,
        width: 90
    },
    {
        key: 'price_per_share_including_hard_transaction_costs_trading',
        name: 'Trading Currency: $/share (w/ trans costs)',
        sortable: true,
        formatter: SharePriceFormatter,
        width: 100
    },
    {
        key: 'shares',
        name: 'Shares',
        sortable: true,
        formatter: OneDecimalNoNullFormatter,
        width: 110
    },
    {
        key: 'net_value_trading',
        name: 'Trading Currency: Net $',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110
    },
    {
        key: 'spacer_2',
        name: '',
        sortable: false,
        width: 20
    },


    {
        key: 'currency',
        name: 'Execution Currency',
        sortable: true,
        width: 90
    },
    {
        key: 'price_per_share_including_hard_transaction_costs',
        name: 'Execution Currency: $/share (w/ trans costs)',
        sortable: true,
        formatter: SharePriceFormatter,
        width: 100
    },
    {
        key: 'gross_value_before_transaction_cost',
        name: 'Execution Currency: Gross $',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110
    },
    {
        key: 'hard_transaction_cost',
        name: 'Execution Currency: Hard Transaction Cost $',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110
    },
    {
        key: 'net_value',
        name: 'Execution Currency: Net $',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110
    },


    {
        key: 'hard_transaction_cost_per_share',
        name: 'Execution Currency: Hard Trans Cost $/share',
        sortable: true,
        formatter: SharePriceFourDecimalFormatter,
        width: 100
    },
    {
        key: 'price_per_share_ex_hard_transaction_costs',
        name: 'Execution Currency: $/share (ex trans costs)',
        sortable: true,
        formatter: SharePriceFormatter,
        width: 100
    },
    {
        key: 'currency_type',
        name: 'Currency Type',
        sortable: true,
        width: 90
    },
    {
        key: 'trade_datetime',
        name: 'Trade Datetime',
        sortable: true,
        formatter: UtcDateFormatter,
        width: 90,
    },

];

