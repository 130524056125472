import React from "react";
import {
    DateFormatter,
    NoDecimalNoNullFormatter,
    NoDecimalNoNullOrZeroFormatter,
    PercentChangeOneDecimalPositiveNegativeFormatter,
    PercentChangeTwoDecimalPositiveNegativeFormatter,
    PercentNoDecimalNoNullFormatter
} from "../../../common/reactDataGrid/formatters";
import {Link} from "react-router-dom";
import {formatFullDate} from "../../../common/format";
import qs from 'qs';
import {convertFiltersToFeathersFilters} from "../../../common/request/convert";


export const fieldSpecs = {
    analysisSyntheticType: {
        parse: (v) => v == null ? 'Live' : v
    },
    tradeGroupType: {
        parse: (v) => v == null ? 'Long Buy and Sell' : v
    }

}



export const analysisSyntheticTypeOptions = ['Synthetic', 'Live', 'All'].map(v => {
    return {
        value: v,
        text: v,
        key: v
    }
});


export const tradeGroupTypeOptions = ['Long Buy and Sell', 'Short Sell and Cover', 'All'].map(v => {
    return {
        value: v,
        text: v,
        key: v
    }
});



class DateWithTradeDetailLink extends React.Component {
    render() {

        const searchProps = {
            sort: [
                ['standard_date', -1]
            ],
            filters: {
                standard_date: {
                    term: this.props.value,
                    type: 'term'
                },

            },
        }
        const url = '/trade_review' + qs.stringify(searchProps, {addQueryPrefix: true})

        return <Link to={url}>{formatFullDate(this.props.value, false)}</Link>

    }
}



export const columns = [
    {
        key: 'standard_date',
        name: 'Date',
        sortable: true,
        formatter: DateWithTradeDetailLink,
        width: 90,

    },
    {
        key: 'gross_value_usd',
        name: 'Gross Traded $',
        sortable: true,
        formatter: NoDecimalNoNullFormatter,
        width: 90,

    },
    {
        key: 'net_value_usd',
        name: 'Net Traded $',
        sortable: true,
        formatter: NoDecimalNoNullFormatter,
        width: 90,

    },
    {
        key: 'net_percent_of_gross',
        name: 'Net % Gross',
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 90,

    },

    {
        key: 'valid_trade_records_count',
        name: 'Valid Records',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 70,

    },
    {
        key: 'invalid_trade_records_count',
        name: 'Invalid Records',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 70,

    },
    {
        key: 'total_gain_vs_vwap_percent',
        name: 'Favorable % vs VWAP',
        sortable: true,
        formatter: PercentChangeOneDecimalPositiveNegativeFormatter,
        width: 70,
    },
    {
        key: 'total_gain_vs_close_percent',
        name: 'Favorable % vs Close',
        sortable: true,
        formatter: PercentChangeOneDecimalPositiveNegativeFormatter,
        width: 70,
    },
    {
        key: 'buy_gross_value_usd',
        name: 'Buy $',
        sortable: true,
        formatter: NoDecimalNoNullFormatter,
        width: 90,

    },
    {
        key: 'buy_gain_vs_vwap_percent',
        name: 'Buy Favorable % vs VWAP',
        sortable: true,
        formatter: PercentChangeOneDecimalPositiveNegativeFormatter,
        width: 70,
    },
    {
        key: 'buy_gain_vs_close_percent',
        name: 'Buy Favorable % vs Close',
        sortable: true,
        formatter: PercentChangeOneDecimalPositiveNegativeFormatter,
        width: 70,
    },
    {
        key: 'sell_gross_value_usd',
        name: 'Sell $',
        sortable: true,
        formatter: NoDecimalNoNullFormatter,
        width: 90,

    },
    {
        key: 'sell_gain_vs_vwap_percent',
        name: 'Sell Favorable % vs VWAP',
        sortable: true,
        formatter: PercentChangeOneDecimalPositiveNegativeFormatter,
        width: 70,
    },
    {
        key: 'sell_gain_vs_close_percent',
        name: 'Sell Favorable % vs Close',
        sortable: true,
        formatter: PercentChangeOneDecimalPositiveNegativeFormatter,
        width: 70,
    },

    {
        key: 'synthetic_type',
        name: 'Synthetic Type',
        sortable: true,
        width: 90,
    },
    {
        key: 'total_gain_vs_vwap_usd_percent_of_nav',
        name: 'Favorable % vs VWAP % NAV',
        sortable: true,
        formatter: PercentChangeTwoDecimalPositiveNegativeFormatter,
        width: 70,
    },
    {
        key: 'total_gain_vs_close_usd_percent_of_nav',
        name: 'Favorable % vs Close % NAV',
        sortable: true,
        formatter: PercentChangeTwoDecimalPositiveNegativeFormatter,
        width: 70,
    },


];

