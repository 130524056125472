


export const unitsOptions = ['Thousands', 'Dollars'].map(v => {
    return {
        key: v,
        value: v,
        text: v
    }
});


export const fieldSpecs = {
    portfolioId: {
        parse: (v) => v == null ? null : parseInt(v)
    },

}
