import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
import {
    executeWithPending,
    getInitialStateWithLoading,
    makeStandardPollingReducers,
    makeStandardReducers
} from "./common";
import delay from "delay";
const refreshRealTimeDoradoValuationRecordService = client.service('refresh_real_time_dorado_valuation_records');
const processQueueTaskService = client.service('process_queue_task');
const startRecalcTargetPortfolioService = client.service('start_refresh_prices_and_get_trade_recommendations');


const pollingFeatureSlice = createSlice({
    name: 'pollingProcess',
    initialState: {
        refreshingRecords: false,
        processTaskId: null,
        processRefreshCount: 0
    },
    reducers: {
        pollJobQueueComplete: (state, action) => {

            const status = action.payload.data.status;

            if (status === 'started' || status === 'queued') {
                state.processRefreshCount += 1;
            } else if (status === 'finished') {
                state.processRefreshCount = 0;
                state.refreshingRecords = false;
                state.processTaskId = null;
            } else {
                state.processRefreshCount = -1;
                state.refreshingRecords = false;
                state.processTaskId = null;

                console.log('Job Queue error ' + status);
            }

        },
        ...makeStandardPollingReducers({prefix: 'startRefreshRealTimeDoradoValuationRecords'}),
        ...makeStandardPollingReducers({prefix: 'startCalculateDoradoTargetPortfolio'}),

    }
});

const {pollJobQueueComplete} = pollingFeatureSlice.actions;


export const pollJobQueue = ({taskId, waitMilliseconds=3000}) => async (dispatch) => {
    console.log('starting delay, waiting ' + waitMilliseconds);
    await delay(waitMilliseconds)
    console.log('ending delay')

    const payload = await processQueueTaskService.get(taskId);

    return dispatch(pollJobQueueComplete(payload));

}


export const startRefreshRealTimeDoradoValuationRecords = () => async (dispatch) => {
    await executeWithPending({
        dispatch,
        featureSlice: pollingFeatureSlice,
        prefix: 'startRefreshRealTimeDoradoValuationRecords',
        getPayload: () => {return refreshRealTimeDoradoValuationRecordService.find()}
    })
};


export const startCalculateDoradoTargetPortfolio = ({override_enable_long, override_enable_short}) => async (dispatch) => {

    const query = {};

    if (override_enable_long != null) {
        query.override_enable_long = override_enable_long;
    }
    if (override_enable_short != null) {
        query.override_enable_short = override_enable_short;
    }

    await executeWithPending({
        dispatch,
        featureSlice: pollingFeatureSlice,
        prefix: 'startCalculateDoradoTargetPortfolio',
        getPayload: () => {return startRecalcTargetPortfolioService.find({
            query: query
        })}
    })
};



export default pollingFeatureSlice.reducer
