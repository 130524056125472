import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {fetchAnalysisForScrape} from '../../../actions/employees/analysisActions';
import {fetchEmployeesScrapeRecord} from '../../../actions/employees/scrapeRecordActions';
import {parseSearchProps} from "../../../common/query/parse";
import {requestNewDataOnChange} from "../../../common/request/newData";
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import EmployeeAnalysisTable from './EmployeeAnalysisTable'
import EmployeeAnalysisSidebar from './EmployeeAnalysisSidebar'
import {fieldSpecs} from './common'


class EmployeeAnalysisPage extends Component {

  componentDidMount() {
    this._requestNewDataOnChange({});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const lastSearchProps = parseSearchProps({searchString: prevProps.location.search, fieldSpecs});
    const lastMatchParams = prevProps.match.params;
    this._requestNewDataOnChange({lastSearchProps, lastMatchParams});
  }

  _requestNewDataOnChange = ({lastSearchProps, lastMatchParams}) => {

    const searchProps = parseSearchProps({searchString: this.props.location.search, fieldSpecs});
    const matchParams = this.props.match.params;

    const requestData = ({searchProps, matchParams}) => {

      const {scrape_id} = matchParams;
      this.props.fetchEmployeesScrapeRecord(scrape_id);
      this.props.fetchAnalysisForScrape({scrapeId: scrape_id, geographyGroups: searchProps.geographyGroups});
    };

    requestNewDataOnChange({
      lastSearchProps,
      searchProps,
      lastMatchParams,
      matchParams,
      requestData,
      matchFields: ['scrape_id'],
      queryMatchFields: ['geographyGroups']
    })

  };

  render() {

    return (
      <ViewWithSidebar mainView={<EmployeeAnalysisTable />} sidebarView={<EmployeeAnalysisSidebar/>}/>
    )

  }
}


function mapStateToProps(state) {
  return {
  }
}


export default withRouter(connect(mapStateToProps,
  {
    fetchAnalysisForScrape, fetchEmployeesScrapeRecord
  })(EmployeeAnalysisPage));
