import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Container, Grid} from 'semantic-ui-react'
// import {fetchTargetPriceFeedRecords, fetchEarningsDateFeedRecords} from '../../actions/targetPriceFeedActions';
import {Table, Header} from 'semantic-ui-react'
import {formatPercentOneDecimalNoNull, formatSharePrice} from "../../common/format";
import {Link, useHistory, useLocation} from 'react-router-dom';
import {formatFullDateTimeUtcToLocal, formatSimpleDate} from "../../common/formatDate";
import moment from 'moment-timezone'
import {fetchBenchmarkMetadataFeedRecords, fetchTargetPriceFeedRecords} from '../../features/feedFeatureSlice'
import {fetchEarningsDateFeedRecords} from '../../features/earningsDateFeatureSlice'


export default () => {

    const dispatch = useDispatch();

    const currentTimeEst = moment().tz("America/Los_Angeles");

    useEffect(() => {
        const oneWeekAgo = moment().subtract(7, 'days');
        dispatch(fetchTargetPriceFeedRecords({limit: 20, dateLimit: oneWeekAgo}));
        dispatch(fetchEarningsDateFeedRecords());
        dispatch(fetchBenchmarkMetadataFeedRecords());

    }, [dispatch]);

    const {
        targetPriceRecords,
        loadingTargetPriceRecords,
        benchmarkMetadataRecords,
        loadingBenchmarkMetadataRecords,
    } = useSelector(state => state.targetPriceFeed, shallowEqual);

    const {earningsDateRecords, loadingEarningsDateRecords} = useSelector(state => {
        return {
            earningsDateRecords: state.earningsDateFeed.records,
            loadingEarningsDateRecords: state.earningsDateFeed.loading
        }
    }, shallowEqual);

    const targetPriceRows = targetPriceRecords.map(record => {

        return (
            <Table.Row key={record.id}>
                <Table.Cell>
                    {formatFullDateTimeUtcToLocal(record.last_modified)}
                </Table.Cell>
                <Table.Cell>{record.user_name}</Table.Cell>
                <Table.Cell>
                    <Link to={'/company_detail/notes/' + record.company.id}>
                        {record.company.company_name}
                    </Link>

                </Table.Cell>
                <Table.Cell>{formatSharePrice(record.buy_price)}</Table.Cell>
                <Table.Cell>{formatSharePrice(record.short_price)}</Table.Cell>
                <Table.Cell>{record.rationale}</Table.Cell>

            </Table.Row>
        )
    });

    const benchmarkMetadataRows = benchmarkMetadataRecords.map(record => {

        const isBold = getIsBold(record.change_in_exit_price_per_share_trading);

        let priceChangeValue = formatPercentOneDecimalNoNull(record.change_in_exit_price_per_share_trading)

        if (isBold) {
            priceChangeValue = <b>{priceChangeValue}</b>
        }

        return (
            <Table.Row key={record.id}>
                <Table.Cell>
                    <Link to={'/company_detail/dorado_backtest_valuations/' + record.company.id + "?series2ChartName=IRR%20%28using%20prior%20day%20forecast%29&showBuyPrice=true&showFairPrice=false&showFairPriceScaled=false&showBuyPriceScaled=false&showExtendedBacktest=false"}>
                        {record.company.short_name}
                    </Link>
                </Table.Cell>
                <Table.Cell style={{fontSize: '12px'}}>
                    {formatFullDateTimeUtcToLocal(record.last_modified_date)}
                </Table.Cell>
                <Table.Cell>
                    {formatSimpleDate(record.as_of_standard_date)}
                </Table.Cell>
                <Table.Cell>
                    {priceChangeValue}
                </Table.Cell>
                <Table.Cell>
                    {record.description}
                </Table.Cell>
                <Table.Cell>
                    {record.notes}
                </Table.Cell>

            </Table.Row>
        )
    })

    const earningsDateRows = earningsDateRecords.map(record => {

        const formattedDate = formatFullDateTimeUtcToLocal(record.earnings_release_datetime_utc);

        return (
            <Table.Row key={record.id} negative={record.is_current_day_earnings} className={record.is_current_day_earnings ? 'bold-value': null}>
                <Table.Cell >
                    <Link to={'/company_detail/notes/' + record.company.id}>
                        {record.company.company_name}
                    </Link>
                </Table.Cell>
                <Table.Cell>
                    {formattedDate}
                </Table.Cell>
            </Table.Row>
        )
    });


    return (
        <Container fluid>
            <Grid padded>
                <Grid.Column width={10}>
                    <Header>Model Updates</Header>
                    <Table basic='very' compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={2}>Company</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Last Modified</Table.HeaderCell>
                                <Table.HeaderCell width={2}>As of Date</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Value % Chg</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                                <Table.HeaderCell>Notes</Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {benchmarkMetadataRows}
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={1}/>
                <Grid.Column width={5}>
                    <Header>Upcoming Earnings</Header>
                    <Table basic='very' compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Company</Table.HeaderCell>
                                <Table.HeaderCell>Earnings Date</Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {earningsDateRows}
                        </Table.Body>
                    </Table>
                    <Header>Target Price Changes</Header>
                    <Table basic='very' compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3}>Modified</Table.HeaderCell>
                                <Table.HeaderCell>User</Table.HeaderCell>
                                <Table.HeaderCell>Company</Table.HeaderCell>
                                <Table.HeaderCell>Buy Price</Table.HeaderCell>
                                <Table.HeaderCell>Short Price</Table.HeaderCell>
                                <Table.HeaderCell>Rationale</Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {targetPriceRows}
                        </Table.Body>
                    </Table>
                </Grid.Column>

            </Grid>


        </Container>
    )
};


const getIsBold = (priceChange) => {

    if (priceChange == null) {
        return false
    }

    if (priceChange > .05) {
        return true
    }

    if (priceChange < -.05) {
        return true
    }

    return false



}