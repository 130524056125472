import {
    CompanyLinkFormatter, DateFormatter,
    PercentNoDecimalNoNullFormatter,
    PercentOneDecimalNoNullFormatter
} from "../../../../common/reactDataGrid/formatters";
import {formatSimpleDate} from "../../../../common/formatDate";
import {formatPercentNoNull, formatPercentOneDecimalNoNull} from "../../../../common/format";

export const columns = [
    {
        key: 'company_name',
        name: 'Name',
        width: 200,
        frozen: true,
        sortable: true,
        formatter: CompanyLinkFormatter,
        _getValueFromRecord: (r) => {
            return {
                company_name: r.company.company_name,
                company_id: r.company.id
            }
        }
    },
    {
        key: 'backtest_as_of_date',
        name: 'As of Date',
        width: 100,
        sortable: true,
        formatter: DateFormatter
    },
    {
        key: 'description',
        name: 'Description',
        width: 250,
        sortable: true,
    },
    {
        key: 'last_historical_period_end',
        name: 'Last Historical Period End',
        width: 100,
        sortable: true,
    },
    {
        key: 'revision_percent_from_changed_methodology',
        name: 'Revision % from Changed Methodology',
        width: 100,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },

];