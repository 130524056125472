import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Header, Progress, Statistic} from 'semantic-ui-react'
import {fetchDataQualityRecords} from '../../actions/benchmarkSummaryAction';
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../common/request/convert';
import CoverageFilters from './CoverageFilters'
import ViewWithSidebar from '../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../common/hooks";
import RdgTable from "../../components/rdg/RdgTable";
import {getColumns} from './common'
import {patchCompany} from "../../actions/tagActions";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../common/query/parse";
import {fetchCoverageRecords} from "../../features/dataQualityFeatureSlice";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const searchProps = parseSearchProps({searchString: location.search});

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const {coverageRecords, loadingCoverageRecords} = useSelector(state => state.dataQuality, shallowEqual);

    const {loadingImportStartedAcknowledgement} = useSelector(state => state.admin, shallowEqual);

    const doradoUniverseCount = coverageRecords.filter(r => r.in_dorado_universe === true).length;
    const validDoradoCount = coverageRecords.filter(r => r.in_dorado_universe === true && r.current_forecast_is_valid).length;
    const validDoradoBacktestCount = coverageRecords.filter(r => r.in_dorado_universe === true && r.backtest_forecast_is_valid).length;
    const lockedPositionCount = coverageRecords.filter(r => r.in_dorado_universe === true && r.dorado_is_locked).length;

    const progressBars = [
        <Progress key='dorado_valid' value={validDoradoCount} total={doradoUniverseCount} progress='ratio'
                  label={'Valid Dorado Companies'} color='green'/>,
        <Progress key='backtest_valid' value={validDoradoBacktestCount} total={doradoUniverseCount} progress='ratio'
                  label={'Valid Dorado Backtests'} color='blue'/>,
        <Progress key='locked_ct' value={lockedPositionCount} total={doradoUniverseCount} progress='value'
                  label={'Locked Companies'} color='grey'/>,
    ];

    const onGridRowsUpdated = ({fromRow, toRow, updated}) => {
        const record = coverageRecords[fromRow];

        // convert '' to null
        Object.keys(updated).map(key => {
            if (updated[key] === '') {
                updated[key] = null;
            }
            if (updated[key] === 'true') {
                updated[key] = true;
            }
            if (updated[key] === 'false') {
                updated[key] = false;
            }

            return key
        });

        patchCompany({dispatch, id: record.company_id, patchData: updated}).then(
            () => {
                requestData({dispatch, searchProps})
            }
        );

    };


    const sidebarView = (
        <Grid.Row>
            <Grid.Column textAlign="center">
                <br/>
                {progressBars}
                <Header>Filters</Header>
            </Grid.Column>

            <CoverageFilters/>
        </Grid.Row>
    );

    const table = <RdgTable columns={getColumns({dispatch, loadingImportStartedAcknowledgement})}
                            loading={loadingCoverageRecords}
                            records={coverageRecords}
                            onGridRowsUpdated={onGridRowsUpdated}/>;

    return (
        <ViewWithSidebar mainView={table} sidebarView={sidebarView}/>
    )


}


const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({filters: searchProps.filters});

    dispatch(fetchCoverageRecords({sort, filters}));

};

