import React, {useEffect} from 'react'
import {Divider, Grid, Table} from "semantic-ui-react";
import {
    formatNoDecimalNoNull,
    formatPercentOneDecimalNoNull,
    formatPercentTwoDecimalNoNull
} from "../../../common/format";
import {getTableClassName} from "../../../common/reactDataGrid/common";


export default ({targetPortfolioState, loading}) => {

    const style = {};
    if (loading) {
        style.color = 'grey';
    }

    return (
        <React.Fragment>
            <Divider horizontal>
                Portfolio Attributes
            </Divider>
            <Table textAlign={'right'} compact={'very'} style={style}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>
                            Current
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Delta
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Target
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Long Return Score
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.beginning_long_return_score)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.net_change_long_return_score)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.target_long_return_score)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Long Concentration Score
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(targetPortfolioState.beginning_long_concentration_score)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(targetPortfolioState.net_change_long_concentration_score)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(targetPortfolioState.target_long_concentration_score)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Long Conc. Adj Return Score
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.beginning_long_conc_adj_return_score)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.net_change_long_conc_adj_return_score)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.target_long_conc_adj_return_score)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Expected Total Gain
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.beginning_total_gain)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.net_change_total_gain)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.target_total_gain)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Expected IRR
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.beginning_irr)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.net_change_irr)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(targetPortfolioState.target_irr)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell/>
                        <Table.Cell/>
                        <Table.Cell/>
                        <Table.Cell/>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Long Positions % NAV
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.beginning_long_positions_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.net_change_long_positions_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.target_long_positions_percent_of_net_asset_value)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Short Positions % NAV
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.beginning_short_positions_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.net_change_short_positions_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.target_short_positions_percent_of_net_asset_value)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Cash % NAV
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.beginning_unencumbered_cash_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.net_change_unencumbered_cash_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.target_unencumbered_cash_percent_of_net_asset_value)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Gross Buys % NAV
                        </Table.Cell>
                        <Table.Cell>

                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.gross_buys_long_positions_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>

                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Gross Sells % NAV
                        </Table.Cell>
                        <Table.Cell>

                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.gross_sells_long_positions_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>

                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Invalid Positions % NAV
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.beginning_invalid_companies_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.net_change_invalid_companies_percent_of_net_asset_value)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(targetPortfolioState.target_invalid_companies_percent_of_net_asset_value)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Position Count
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(targetPortfolioState.beginning_position_count)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(targetPortfolioState.net_change_position_count)}
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(targetPortfolioState.target_position_count)}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>

            </Table>

        </React.Fragment>
    )

}