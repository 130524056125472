import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Table} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {createAddValueToQuery, createSetFieldValuesOnQuery} from "../../../common/query/add";
import {fieldSpecs, columns} from './common'
import {useReloadOnChange} from "../../../common/hooks";
import {
    fetchCurrentPortfolioState,
    fetchPortfolioPositions,
    fetchPortfoliosExMembers,
    fetchPortfolioStates, fetchSecurityInvestmentsWithPortfolioData
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfolioStateTable from "../../../components/portfolio/PortfolioStateTable";
import RdgTable from "../../../components/rdg/RdgTable";
import {sortFunctional} from "../../../common/tableHelpers";
import {formatDateTimeUtcToLocal, formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import {formatPercentOneDecimalNoNull} from "../../../common/format";
import {
    NoDecimalNoNullFormatter, PercentOneDecimalNoNullFormatter,
    PricePercentChangeOneDecimalFormatter
} from "../../../common/reactDataGrid/formatters";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        portfolios,
        loadingPortfolios,

        currentPortfolioState,
        loadingCurrentPortfolioState,

        securityInvestmentsWithPortfolioData,
        loadingSecurityInvestmentsWithPortfolioData,

    } = useSelector(state => state.portfolio, shallowEqual);

    useEffect(() => {
        dispatch(fetchPortfoliosExMembers())
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['portfolio_id'],
        requestData: loadCurrentPortfolioState
    });

    if (searchProps.portfolio_id == null) {
        if (!loadingPortfolios && portfolios.length > 0) {
            return <Redirect to={location.pathname + "?portfolio_id=" + portfolios[0].id}/>
        }
    }

    const portfolioOptions = portfolios.map(p => {
        return {
            value: p.id,
            text: p.type,
            key: p.id
        }
    })

    // may be null if not yet loaded
    const report = currentPortfolioState.portfolio_state_report;
    const dailyReturn = report != null ? report.time_weighted_daily_return : null;

    const ytdReturn = report != null ? report.time_weighted_ytd_return : null;
    const ytdLongReturn = report != null ? report.time_weighted_ytd_long_return : null;
    const ytdShortReturn = report != null ? report.time_weighted_ytd_short_return : null;

    const dailyLongReturn = report != null ? report.time_weighted_daily_long_return : null;
    const dailyShortReturn = report != null ? report.time_weighted_daily_short_return : null;
    const dailyOtherReturn = report != null ? report.time_weighted_daily_other_return : null;

    const dailyLongReturnDollars = report != null ? report.daily_long_return_dollars : null;
    const dailyShortReturnDollars = report != null ? report.daily_short_return_dollars : null;
    const dailyReturnDollars = report != null ? report.daily_return_dollars : null;

    const sidebar = (
        <div>
            <br/>
            <Form>
                <Form.Select
                    fluid
                    inline
                    options={portfolioOptions}
                    onChange={
                        (e, d) => {
                            createSetFieldValuesOnQuery({
                                history,
                                searchProps,
                                changes: {
                                    portfolio_id: d.value,
                                    // portfolio_state_id: null
                                }
                            })()
                        }
                    }
                    value={searchProps.portfolio_id}
                    label="Portfolio"
                />
            </Form>
            <Table compact collapsing basic={'very'}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            As of Date:
                        </Table.Cell>
                        <Table.Cell>
                            {formatFullDateTimeUtcToLocal(currentPortfolioState.as_of_datetime_utc)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Is End of Day:
                        </Table.Cell>
                        <Table.Cell>
                            {currentPortfolioState.is_end_of_day ? 'Yes' : 'No'}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <PortfolioStateTable portfolioState={currentPortfolioState}/>
            <br/>
            <Table compact collapsing basic={'very'}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Daily Return
                        </Table.HeaderCell>
                        <Table.HeaderCell >
                            <div className="align-right">%</div>
                        </Table.HeaderCell>
                        <Table.HeaderCell className="align-right">
                            <div className="align-right">$ Contribution</div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <b>Net</b>
                        </Table.Cell>
                        <Table.Cell>
                            <b>
                                <PricePercentChangeOneDecimalFormatter value={dailyReturn}/>
                            </b>
                        </Table.Cell>
                        <Table.Cell>
                            <NoDecimalNoNullFormatter value={dailyReturnDollars}/>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Long Unlevered
                        </Table.Cell>
                        <Table.Cell>
                            <PricePercentChangeOneDecimalFormatter value={dailyLongReturn}/>
                        </Table.Cell>
                        <Table.Cell>
                            <NoDecimalNoNullFormatter value={dailyLongReturnDollars}/>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Short Unlevered
                        </Table.Cell>
                        <Table.Cell>
                            <PricePercentChangeOneDecimalFormatter value={dailyShortReturn}/>
                        </Table.Cell>
                        <Table.Cell>
                            <NoDecimalNoNullFormatter value={dailyShortReturnDollars}/>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <br/>
            <Table compact collapsing basic={'very'}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            YTD Return
                        </Table.HeaderCell>
                        <Table.HeaderCell >
                            <div className="align-right">%</div>
                        </Table.HeaderCell>
                        <Table.HeaderCell className="align-right">
                            <div className="align-right" style={{color: 'white'}}>$ Contribution</div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Net
                        </Table.Cell>
                        <Table.Cell>
                            <PercentOneDecimalNoNullFormatter value={ytdReturn}/>
                        </Table.Cell>
                        <Table.Cell>
                            <NoDecimalNoNullFormatter value={null}/>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Long Unlevered
                        </Table.Cell>
                        <Table.Cell>
                            <PercentOneDecimalNoNullFormatter value={ytdLongReturn}/>
                        </Table.Cell>
                        <Table.Cell>
                            <NoDecimalNoNullFormatter value={null}/>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Short Unlevered
                        </Table.Cell>
                        <Table.Cell>
                            <PercentOneDecimalNoNullFormatter value={ytdShortReturn}/>
                        </Table.Cell>
                        <Table.Cell>
                            <NoDecimalNoNullFormatter value={null}/>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    );

    const longRecords = sortFunctional({
        array: securityInvestmentsWithPortfolioData.filter(r => r.is_long === true),
        sortKey: 'market_value',
        sortDirection: -1
    });
    const shortRecords = sortFunctional({
        array: securityInvestmentsWithPortfolioData.filter(r => r.is_long !== true),
        sortKey: 'market_value',
        sortDirection: 1
    });

    const rows = [
        {
            _name: 'Long',
            _isHeader: true
        },
        ...longRecords,
        {
            _name: '',
            _isHeader: true
        },
        {
            _name: 'Short',
            _isHeader: true
        },
        ...shortRecords,


    ];

    return (
        <ViewWithSidebar mainView={<RdgTable records={rows}
                                             loading={loadingSecurityInvestmentsWithPortfolioData}
                                             columns={columns}

        />}

                         sidebarView={sidebar}/>
    )

}


const loadCurrentPortfolioState = ({dispatch, searchProps}) => {

    const portfolio_id = searchProps.portfolio_id;

    if (portfolio_id != null) {
        dispatch(fetchCurrentPortfolioState({
            portfolioId: portfolio_id,
            isEndOfDay: false
        })).then((result) => {

            const portfolioState = result.data;

            dispatch(fetchSecurityInvestmentsWithPortfolioData({
                filters: {
                    portfolio_state_id: portfolioState.id
                }
            }));
        });
    }

}

