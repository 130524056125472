import {createSlice} from '@reduxjs/toolkit'

import client from '../../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";
import {PeriodType} from "../../common/constants";

const doradoForecastMetadatasService = client.service('dorado_forecast_metadatas');
const doradoForecastMetadatasWithCompaniesService = client.service('dorado_forecast_metadatas_with_companies');

const doradoBacktestValuationComparisonForCompanyService = client.service('dorado_backtest_valuation_comparison_for_company');

const firstdoradoValuationRecordForMetadataService = client.service('first_dorado_valuation_record_for_metadata');
const doradoInterpolatedRecordsForMetadataService = client.service('dorado_interpolated_financial_period_records_for_metadata');
const doradoValuationRecordsService = client.service('dorado_valuation_records')
const doradoInterpolatedFinancialPeriodRecordsService = client.service('dorado_interpolated_financial_period_records');
const financialPeriodRecordService = client.service('financial_period_records');
const currentDoradoValuationDataService = client.service('current_dorado_valuation_data');
const doradoValuationRecordsForCompanyService = client.service('dorado_valuation_records_for_company');
const doradoOverviewDatasService = client.service('dorado_overview_datas');


const doradoCompanyDetailFeatureSlice = createSlice({
    name: 'doradoCompanyDetail',
    initialState: getInitialStateWithLoading({
        doradoForecastMetadatas: [],
        financialPeriodRecords: [],

        currentDoradoValuationData: {
            'dorado_valuation_record': {},
            'interpolated_financial_period_records': [],
            'dorado_forecast_metadata': {},
            'forecast_is_valid': null,
            'forecast_error_text': null
        },

        doradoBacktestValuationComparisons: [],
        doradoValuationRecords: [],
        doradoOverviewData: {
            'dorado_valuation_record': {},
            'dorado_forecast_metadata': {},
            'financial_period_records': []
        },


        doradoForecastMetadatasWithCompanies: [],


        // maybe not used
        doradoForecastMetadata: {},
        firstDoradoValuationRecordForMetadata: {dorado_forecast_metadata: {}, company: {}},
        doradoInterpolatedFinancialPeriodRecordsForMetadata: [],

        doradoValuationRecord: {dorado_forecast_metadata: {}, company: {}},
        doradoInterpolatedFinancialPeriodRecords: []

    }),
    reducers: {
        ...makeStandardReducers({
            prefix: 'fetchDoradoForecastMetadatasForCompany',
            destinationVariable: 'doradoForecastMetadatas'
        }),
        ...makeStandardReducers({
            prefix: 'fetchDoradoBacktestValuationComparisonForCompany',
            destinationVariable: 'firstDoradoValuationRecords'
        }),
        ...makeStandardReducers({
            prefix: 'getDoradoForecastMetadata',
            destinationVariable: 'doradoForecastMetadata'
        }),
        ...makeStandardReducers({
            prefix: 'getDoradoInterpolatedFinancialPeriodRecordsForMetadata',
            destinationVariable: 'doradoInterpolatedFinancialPeriodRecordsForMetadata'
        }),
        ...makeStandardReducers({
            prefix: 'getDoradoValuationRecordWithMetadata',
            destinationVariable: 'doradoValuationRecord'
        }),
        ...makeStandardReducers({
            prefix: 'fetchDoradoInterpolatedFinancialPeriodRecordsForValuationRecord',
            destinationVariable: 'doradoInterpolatedFinancialPeriodRecords'
        }),
        ...makeStandardReducers({
            prefix: 'fetchDoradoForecastMetadatasWithCompanies',
            destinationVariable: 'doradoForecastMetadatasWithCompanies'
        }),
        ...makeStandardReducers({
            prefix: 'fetchFinancialPeriodRecordsForMetadata',
            destinationVariable: 'financialPeriodRecords'
        }),
        ...makeStandardReducers({
            prefix: 'fetchCurrentDoradoValuationDataForCompany',
            destinationVariable: 'currentDoradoValuationData'
        }),
        ...makeStandardReducers({
            prefix: 'fetchDoradoBacktestValuationComparisonForCompany',
            destinationVariable: 'doradoBacktestValuationComparisons'
        }),
        ...makeStandardReducers({
            prefix: 'fetchDoradoValuationRecordsForCompany',
            destinationVariable: 'doradoValuationRecords'
        }),
        ...makeStandardReducers({
            prefix: 'fetchDoradoOverviewDataForCompany',
            destinationVariable: 'doradoOverviewData'
        }),






    }
});


export const fetchDoradoForecastMetadatasForCompany = ({filters, companyId, sortAsc = true}) => async (dispatch, getState) => {

    const sort = sortAsc ? 1 : -1;

    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'fetchDoradoForecastMetadatasForCompany',
        getPayload: () => {
            return doradoForecastMetadatasService.find({
                query: {
                    company_id: companyId,
                    $sort: {
                        as_of_standard_date: sort
                    },
                    ...filters
                }

            })
        }
    })
};


export const fetchDoradoForecastMetadatasWithCompanies = ({filters, sort}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'fetchDoradoForecastMetadatasWithCompanies',
        getPayload: () => {
            return doradoForecastMetadatasWithCompaniesService.find({
                query: {
                    $sort: sort,
                    ...filters
                }

            })
        }
    })
}

export const fetchDoradoBacktestValuationComparisonForCompany = ({companyId}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'fetchDoradoBacktestValuationComparisonForCompany',
        getPayload: () => {
            return doradoBacktestValuationComparisonForCompanyService.get(companyId)
        }
    })
};


export const fetchDoradoValuationRecordsForCompany = ({companyId, filters, sort}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'fetchDoradoValuationRecordsForCompany',
        getPayload: () => {
            return doradoValuationRecordsForCompanyService.get(companyId, {
                query: {
                    $sort: sort,
                    ...filters
                }

            })
        }
    })
}


export const getDoradoForecastMetadata = ({doradoForecastMetadataId}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'getDoradoForecastMetadata',
        getPayload: () => {
            return doradoForecastMetadatasService.get(doradoForecastMetadataId)
        }
    })
};




export const getDoradoInterpolatedFinancialPeriodRecordsForMetadata = ({doradoForecastMetadataId}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'getDoradoInterpolatedFinancialPeriodRecordsForMetadata',
        getPayload: () => {
            return doradoInterpolatedRecordsForMetadataService.get(doradoForecastMetadataId)
        }
    })
};


export const getDoradoValuationRecordWithMetadata = ({doradoValuationRecordId}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'getDoradoValuationRecordWithMetadata',
        getPayload: () => {
            return doradoValuationRecordsService.get(doradoValuationRecordId)
        }
    })
};

export const fetchDoradoInterpolatedFinancialPeriodRecordsForValuationRecord = ({doradoValuationRecordId}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'fetchDoradoInterpolatedFinancialPeriodRecordsForValuationRecord',
        getPayload: () => {
            return doradoInterpolatedFinancialPeriodRecordsService.find({
                query: {
                    dorado_valuation_record_id: doradoValuationRecordId,
                    $sort: {
                        period_end_date: 1
                    }
                }
            })
        }
    });
};


export const fetchFinancialPeriodRecordsForMetadata = ({doradoForecastMetadataId, periodType}) => async (dispatch, getState) => {

    const periodTypes = periodType === PeriodType.annual ? [periodType] : [PeriodType.quarterly, PeriodType.half];

    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'fetchFinancialPeriodRecordsForMetadata',
        getPayload: () => {
            return financialPeriodRecordService.find({
                query: {
                    dorado_forecast_metadata_id: doradoForecastMetadataId,
                    $sort: {
                        period_end_date: 1
                    },
                    period_type: {
                        $in: periodTypes
                    }
                }

            })
        }
    })
};


export const fetchCurrentDoradoValuationDataForCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'fetchCurrentDoradoValuationDataForCompany',
        getPayload: () => {
            return currentDoradoValuationDataService.get(companyId)
        }
    })
}


export const fetchDoradoOverviewDataForCompany = ({companyId, periodType}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoCompanyDetailFeatureSlice,
        prefix: 'fetchDoradoOverviewDataForCompany',
        getPayload: () => {
            return doradoOverviewDatasService.get(companyId, {
                query: {
                    period_type: periodType
                }
            })
        }
    })
}



export default doradoCompanyDetailFeatureSlice.reducer



