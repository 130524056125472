import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Statistic, Button, Icon} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import TradesSidebar from '../TradesSidebar';
import {fetchPortfolios, fetchPortfolioTrades} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfoliosSummaryTable from "../PortfoliosSummaryTable";
import {columns, fieldSpecs} from "./common";
import RdgTable from "../../../components/rdg/RdgTable";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {parseString} from "../../../common/filter/parse";


const parseSpecByField = {
    standard_date: {parse: parseString}
}



export default () => {

    const {
        portfolioTrades,
        loadingPortfolioTrades,
        portfolios,
        loadingPortfolios
    } = useSelector(state => state.portfolio, shallowEqual);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    useEffect(() => {
        dispatch(fetchPortfolios({
            filters: {},
            sort: {
                name: 1
            }
        }));
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Statistic horizontal value={portfolioTrades.length} label='Trades Count' size="tiny"/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <TradesSidebar/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    );


    return (
        <ViewWithSidebar mainView={<RdgTable loading={loadingPortfolioTrades}
                                             records={portfolioTrades}
                                             columns={columns} />}
            sidebarView={sidebar}/>
    )

};

const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);

    const filters = convertFiltersToFeathersFilters({
        filters: {
            ...searchProps.filters,
        },
        parseSpecByField
    });

    if (filters.portfolio_id != null) {
        dispatch(fetchPortfolioTrades({sort, filters}));
    }



};
