import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {Button, Modal, Table, Header} from 'semantic-ui-react'
import {createSetFieldValueOnQuery} from "../../../common/query/add";
import {parseBoolean, parseSearchProps} from "../../../common/query/parse";
import {associateEmployeeWithRole, associateDescriptionWithRole, setLastAssociatedRole} from '../../../actions/employees/associationActions'
import {getLabelFromCostCenter} from '../../../common/employee/roles'


const fieldSpecs = {
  associateModalOpen: {
    parse: (v) => {
      return parseBoolean(v)
    }
  }
};


class AssociateModal extends Component {

  render() {

    const history = this.props.history;
    const searchProps = parseSearchProps({searchString: this.props.location.search, fieldSpecs});

    const record = this.props.recordBeingAssociated;
    const closeModal = createSetFieldValueOnQuery({history, searchProps, fieldName: 'associateModalOpen', value: false});

    const roleRows = this.props.roles.map(role => {

      // on association
      // 1) associate
      // 2) refresh updated records
      // 3) close modal

      let onClick;
      if (searchProps.modalType === 'single') {
        onClick = (e, d) => {
          this.props.associateEmployeeWithRole({roleId: role.id, employeeId: record.id}).then(response => {
            closeModal();
            this.props.refresh();
            this.props.setLastAssociatedRole(role);
          });
        };
      } else {
        onClick = (e, d) => {
          this.props.associateDescriptionWithRole({roleId: role.id, employeeId: record.id}).then(response => {
            closeModal();
            this.props.refresh();
            this.props.setLastAssociatedRole(role);
          });

        };
      }

      return (
        <Table.Row positive={! role.is_standard} key={role.id}>
          <Table.Cell>
            {role.name}
          </Table.Cell>
          <Table.Cell>
            {role.minor_cost_center}
          </Table.Cell>
          <Table.Cell>
            {getLabelFromCostCenter({costCenter: role.major_cost_center})}
          </Table.Cell>
          <Table.Cell>
            {role.is_standard ? 'Yes' : 'No'}
          </Table.Cell>
          <Table.Cell>
            <Button primary loading={this.props.loadingAssociation} onClick={onClick}>Associate</Button>
          </Table.Cell>
        </Table.Row>
      )
    });

    const header = searchProps.modalType === 'single' ? "Associate Single Employee with a Role" : "Associate All Employees with Same Description";

    return (
      <Modal
        open={searchProps.associateModalOpen}
        centered={false}
      >
        <Modal.Header>
          {header}
          <Header color="green">{record.description}</Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Table basic compact selectable size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Minor Cost Center</Table.HeaderCell>
                <Table.HeaderCell>Major Cost Center</Table.HeaderCell>
                <Table.HeaderCell>Is Standard?</Table.HeaderCell>
                <Table.HeaderCell>Associate</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {roleRows}
            </Table.Body>
          </Table>

        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>)

  }


}


function mapStateToProps(state) {
  return {
    roles: state.roleStore.roles,
    loadingRoles: state.roleStore.loadingRoles,

    recordBeingAssociated: state.associationStore.recordBeingAssociated,
    loadingAssociation: state.associationStore.loadingAssociation
  }
}


export default withRouter(connect(mapStateToProps,
  {
    associateEmployeeWithRole, associateDescriptionWithRole, setLastAssociatedRole
  })(AssociateModal));
