import React from 'react'
import {Popup, Icon, Header} from 'semantic-ui-react'

export default function ColorsPopup() {

    return (
        <Popup trigger={<Icon circular name='info' style={{float: 'right'}}/>} wide>
            <div>
                <Header as={'h4'}>Period End Colors</Header>
                <p className={'financials-pre-public-header'}>Pre-IPO Periods</p>
                <p>Historical Public Periods</p>
                <p className={'financials-forecast-header'}>Estimate Periods</p>

            </div>
        </Popup>
    )


}


