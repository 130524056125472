import React from "react";
import {Link} from "react-router-dom";
import {BoolToNoFlagFormatter, DateFormatter} from "../../common/reactDataGrid/formatters";


export const columns = [
    {
        key: 'forecast_type',
        name: 'Type',
        sortable: true,
        width: 130,
        frozen: true,
        _getValueFromRecord: (r) => {
            return r.dorado_forecast_metadata.forecast_type
        }
    },
    {
        key: 'as_of_standard_date',
        name: 'As of Date',
        sortable: true,
        width: 90,
        formatter: DateFormatter,
        frozen: true,
        _getValueFromRecord: (r) => {
            return r.dorado_forecast_metadata.as_of_standard_date
        }
    },
    {
        key: 'description',
        name: 'Description',
        sortable: true,
        width: 200,
        frozen: true,
        _getValueFromRecord: (r) => {
            return r.dorado_forecast_metadata.description
        }
    },


    {
        key: 'forecast_is_valid',
        name: 'Is Valid',
        sortable: true,
        formatter: <BoolToNoFlagFormatter color={'red'}/>,
        width: 70,
    },
    {
        key: 'forecast_error_text',
        name: 'Error Detail',
        sortable: true,
        width: 700,
        resizable: true,
    },
    {
        key: 'first_valid_standard_date',
        name: 'Backtest First Date',
        sortable: true,
        width: 90,
        formatter: DateFormatter,
        _getValueFromRecord: (r) => {
            return r.dorado_forecast_metadata.first_valid_standard_date
        }
    },
    {
        key: 'last_valid_standard_date',
        name: 'Backtest Last Date',
        sortable: true,
        width: 90,
        formatter: DateFormatter,
        _getValueFromRecord: (r) => {
            return r.dorado_forecast_metadata.last_valid_standard_date
        }
    },
    {
        key: 'last_reported_fiscal_period_end',
        name: 'Last Reported Period',
        sortable: true,
        width: 100,
        _getValueFromRecord: (r) => {
            return r.dorado_forecast_metadata.last_reported_fiscal_period_end
        }
    },
];