import client from '../../empClient';
const employeesScrapeRecordsService = client.service('employees_scrape_records');

export function fetchEmployeesScrapeRecords({sort, filters}) {

  return dispatch => {
    dispatch({
      type: 'FETCH_EMPLOYEE_SCRAPE_RECORDS',
      payload: employeesScrapeRecordsService.find({
        query: {
          $sort: sort,
          ...filters
        }
      })
    });
  };
}

export function fetchEmployeesScrapeRecord(id) {

  return dispatch => {
    dispatch({
      type: 'FETCH_EMPLOYEE_SCRAPE_RECORD',
      payload: employeesScrapeRecordsService.get(id)
    });
  };
}


export function deleteEmployeeScrapeRecord({id}) {
    return dispatch => {
        return dispatch({
            type: 'DELETE_EMPLOYEE_SCRAPE_RECORD',
            payload: employeesScrapeRecordsService.remove(id)
        });
    };
}




