import client from '../../benchClient';
import {getAdjustedFilters} from "../../features/dorado/doradoValuationRecords";

const doradoValuationRecordsService = client.service('dorado_valuation_records');
const doradoValuationFacetsService = client.service('dorado_valuation_records_facets');
const doradoInterpolatedFinancialPeriodRecordsService = client.service('dorado_interpolated_financial_period_records');
const doradoDownloadValuationRecordsService = client.service('download_dorado_valuation_records');


export function fetchDoradoValuationRecords({dispatch, sort, filters}) {

    dispatch({
        type: 'FETCH_DORADO_VALUATION_RECORDS',
        payload: doradoValuationRecordsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    });
}

// export const fetchFacetsForDoradoValuationRecords = ({dispatch}) => {
//     return dispatch({
//         type: 'FETCH_DORADO_VALUATION_RECORDS_FACETS',
//         payload: doradoValuationFacetsService.find()
//     })
// };




export const getDoradoValuationRecord = ({dispatch, id}) => {

    return dispatch({
        type: 'GET_DORADO_VALUATION_RECORD',
        payload: doradoValuationRecordsService.get(id)
    });
};


export const fetchDoradoInterpolatedFinancialPeriodRecordsForValuationRecord = ({dispatch, doradoValuationRecordId}) => {
    return dispatch({
        type: 'FETCH_DORADO_INTERPOLATED_FINANCIAL_PERIOD_RECORDS',
        payload: doradoInterpolatedFinancialPeriodRecordsService.find({
            query: {
                dorado_valuation_record_id: doradoValuationRecordId,
                $sort: {
                    period_end_date: 1
                }
            }
        })
    })
};

export const downloadDoradoValuationRecords = ({dispatch, sort, filters}) => {

    dispatch({
        type: 'DOWNLOAD_DORADO_VALUATION_RECORDS',
        payload: doradoDownloadValuationRecordsService.find({
            query: {
                $sort: sort,
                ...getAdjustedFilters({filters})
            }
        }, {responseType: 'blob'}).then((response, other) => {

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Dorado Valuation Records.csv');
            document.body.appendChild(link);
            link.click();

        })
    });
};

