import moment from "moment";

export const getDateOptionsToPresent = (earliestDate) => {

    const dates = [];

    const now = moment();

    let dt = earliestDate;

    while (dt < now) {
        dates.push(dt);
        dt = dt.clone().add(1, 'days');

    }

    return dates

};