import {coverageByEmail} from "./constants";


export const getCoverageSearch = ({email}) => {

    let url = '?sort%5B0%5D%5B0%5D=company_name&sort%5B0%5D%5B1%5D=1&sort%5B1%5D%5B0%5D=historical_periods_count&sort%5B1%5D%5B1%5D=-1';

    if (!email) {
        return url
    }

    return url + '&filters%5Bcoverage%5D%5Btype%5D=terms&filters%5Bcoverage%5D%5Bterms%5D%5B0%5D='  + coverageByEmail[email];

};