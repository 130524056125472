import React, {useEffect} from 'react';
import {Button, Form, Grid, Header, Icon, Statistic, List} from 'semantic-ui-react'
import ViewWithSidebar from '../../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import {fetchValuationPremiumAuditRecords} from "../../../../features/dorado/doradoValuationRecords";
import RdgTable from "../../../../components/rdg/RdgTable";
import {columns} from './columns'


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {valuationPremiumAuditRecords, loadingValuationPremiumAuditRecords} = useSelector(state => {return state.doradoValuation}, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>

            <Grid columns={1} relaxed>
                <Grid.Row centered>
                    <Statistic horizontal value={valuationPremiumAuditRecords.length} label='Records' size="tiny"/>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p>
                            See Dorado Documentation for Premium Methodology Detail.
                        </p>
                    </Grid.Column>
                </Grid.Row>

            </Grid>

        </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns} records={valuationPremiumAuditRecords}
                                             loading={loadingValuationPremiumAuditRecords}/>} sidebarView={sidebar}/>
    )

}


const requestData = ({dispatch, searchProps}) => {

    dispatch(fetchValuationPremiumAuditRecords({}));

};