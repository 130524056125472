import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import {columns} from './columns'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {getFilterComponentsFromSpecs} from "../../../common/filter/add";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import BacktestAsofDateFilters from "../BacktestAsOfDateFilters";
import {formatDateTimeUtcToLocal, formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import moment from "moment";
import ValuationRecordCount from "../ValuationRecordCount";
import RdgTable from "../../../components/rdg/RdgTable";
import {
    fetchDoradoValuationRecords
} from "../../../features/dorado/doradoValuationRecords";
import {ForecastType} from "../../../common/constants";



export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {doradoValuationRecords, loadingDoradoValuationRecords} = useSelector(state => state.doradoValuation, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData: requestDoradoValuationRecords
    });

    const sidebar = (
        <div>
            <Grid centered columns={1} relaxed>
                <ValuationRecordCount/>
            </Grid>
            <BacktestAsofDateFilters/>

        </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             loading={loadingDoradoValuationRecords}
                                             records={doradoValuationRecords}/>}
                         sidebarView={sidebar}/>
    )

}


const requestDoradoValuationRecords = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = {
        ...convertFiltersToFeathersFilters({
            filters: searchProps.filters,
            parseSpecByField: {}
        }),
        forecast_type: ForecastType.backtestModel
    };

    if (filters.as_of_date) {
        dispatch(fetchDoradoValuationRecords({sort, filters}));
    }

};