import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../../common/common'
import {formatSharePrice} from "../../../../common/format";
import {formatSimpleDate} from "../../../../common/formatDate";
import {scaleThreshold} from 'd3-scale'
import moment from 'moment';
import {colorRange, dateDomain} from "./yearLegend";
// import Highcharts from 'highcharts';
// import Annotations from 'highcharts/modules/annotations';

const ReactHighcharts = require('react-highcharts');
// require('highcharts/modules/annotations')(ReactHighcharts.Highcharts)


// Annotations(Highcharts)





const colorScale = scaleThreshold().domain(dateDomain).range(colorRange);

class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const doradoPredictions = this.props.doradoPredictions;
        const loading = this.props.loading;
        const selectedRegressionParams = this.props.selectedRegressionParams;
        const latestPredictionRecord = this.props.latestPredictionRecord;

        const yAxisChartSpecification = this.props.yAxisChartSpecification;
        const xAxisChartSpecification = this.props.xAxisChartSpecification;

        const predictionSeries = {
            name: 'Backtest (color = year)',
            data: doradoPredictions.map(r => {
                return {
                    x: xAxisChartSpecification.getX(r),
                    y: yAxisChartSpecification.getY(r),
                    ...r,
                    marker: {
                        fillColor: colorScale(moment(r.entry_date).valueOf()),
                    }
                }
            }).filter(r => r.x != null && r.y != null),
            tooltip: {
                pointFormatter: function () {

                    const exitPrice = this['exit_price_' + yAxisChartSpecification.suffix]

                    return [
                        xAxisChartSpecification.getXAxisLabel() + ": " + xAxisChartSpecification.formatX(this.x),
                        yAxisChartSpecification.getYAxisLabel() + ": " + yAxisChartSpecification.formatY(this.y),
                        'Entry Date: ' + formatSimpleDate(this.entry_date),
                        'Entry Price: ' + formatSharePrice(this.entry_price),
                        'Exit Price: ' + formatSharePrice(exitPrice),

                    ].join("<br/>")
                }

            },
        };

        const allSeries = [
            predictionSeries
        ]

        if (selectedRegressionParams != null && doradoPredictions.length >= 2 && selectedRegressionParams.slope != null) {

            const xs = predictionSeries.data.map(r => r.x);

            const minX = Math.min(...xs);
            const maxX = Math.max(...xs);

            allSeries.push({
                name: 'Regression',
                type: 'line',
                marker: {
                    enabled: false
                },
                enableMouseTracking: false,
                color: 'rgba(100,100,100,.25)',
                data: [
                    {
                        x: minX,
                        y: selectedRegressionParams.intercept + selectedRegressionParams.slope * minX
                    },
                    {
                        x: maxX,
                        y: selectedRegressionParams.intercept + selectedRegressionParams.slope * maxX
                    }
                ],
                tooltip: {
                    enabled: false
                }
            })


            if (latestPredictionRecord !== null) {
                const x = xAxisChartSpecification.getX(latestPredictionRecord);
                const y = selectedRegressionParams.intercept + selectedRegressionParams.slope * x;

                allSeries.push({
                    name: 'Current Valuation',
                    data: [latestPredictionRecord].map(r => {
                        return {
                            x: x,
                            y: y,
                            ...r,
                        }
                    }),
                    marker: {
                        fillColor: '#d7301f'
                    },
                    tooltip: {
                        pointFormatter: function () {

                            const exitPrice = this['exit_price_' + yAxisChartSpecification.suffix]

                            return [
                                xAxisChartSpecification.getXAxisLabel() + ": " + xAxisChartSpecification.formatX(this.x),
                                yAxisChartSpecification.getYAxisLabel() + ": " + yAxisChartSpecification.formatY(this.y),
                                'Entry Date: ' + formatSimpleDate(this.entry_date),
                                'Entry Price: ' + formatSharePrice(this.entry_price)

                            ].join("<br/>")
                        }

                    },
                })
            }
        }


        const config = {
            chart: {
                type: 'scatter',
                zoomType: 'xy'
            },
            title: {
                text: yAxisChartSpecification.getYAxisLabel()
            },
            xAxis: {
                title: {
                    text: xAxisChartSpecification.getXAxisLabel()
                },
                labels: {
                    formatter: function () {
                        return xAxisChartSpecification.formatX(this.value);
                    }
                }
            },
            // legend: {
            //     labelFormatter: function () {
            //
            //         console.log(this.name)
            //         return this.name
            //
            //     }
            // },
            annotations: [{
                labels: [{
                    point: {
                        x: 0,
                        y: 0,

                    },
                    text: 'Cancelled<br>during<br>World War II'
                }
                ],
                labelOptions: {
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    borderColor: 'silver'
                }
            }],
            yAxis: {
                title: {
                    text: yAxisChartSpecification.getYAxisLabel()
                },
                labels: {
                    formatter: function () {
                        return yAxisChartSpecification.formatY(this.value);
                    }
                },
                min: -1
            },
            plotOptions: {
                series: {
                    animation: false,
                    stickyTracking: false,
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        style: {
                            fontSize: "9px",
                            fontWeight: 'normal',
                            textOutline: '1px',
                            color: 'rgba(0, 0, 0, .5)'
                        },
                        format: '{point.label}'
                    },
                },
                scatter: {

                    marker: {
                        symbol: 'circle',
                        radius: 4,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },

                }
            },

            series: allSeries,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent