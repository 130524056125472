import deepEqual from "deep-equal";


export const requestNewDataOnChange = ({lastSearchProps, searchProps, lastMatchParams, matchParams, matchFields, queryMatchFields, requestData}) => {

  matchParams = matchParams || {};

  if (searchPropsOrMatchChanged({lastSearchProps, searchProps, lastMatchParams, matchParams, matchFields, queryMatchFields})) {
    requestData({searchProps, matchParams})
  }

};

export const searchPropsOrMatchChanged = ({lastSearchProps,
                                            searchProps,
                                            lastMatchParams,
                                            matchParams,
                                            matchFields,
                                            queryMatchFields
                                          }) => {
  // compare props.  If specified fields have changed (deepCompare), request a new search
  lastMatchParams = lastMatchParams || {};
  matchParams = matchParams || {};

  return (!queryFieldsMatch({lastSearchProps, searchProps, queryMatchFields}) || !matchFieldsMatch({lastMatchParams, matchParams, matchFields}))

};


const queryFieldsMatch = ({lastSearchProps, searchProps, queryMatchFields}) => {
  if (lastSearchProps == null) {
    return false
  }

  if (queryMatchFields == null) {
    return deepEqual(searchProps, lastSearchProps)
  }

  for (let field of queryMatchFields) {
    if (!deepEqual(searchProps[field], lastSearchProps[field])) {
      return false
    }
  }

  return true

};

const matchFieldsMatch = ({lastMatchParams, matchParams, matchFields}) => {

  if (matchFields == null) {
    return deepEqual(lastMatchParams, matchParams)
  }

  for (let field of matchFields) {
    if (!deepEqual(matchParams[field], lastMatchParams[field])) {
      return false
    }
  }

  return true
};
