import React from 'react'
import { Container, Header} from 'semantic-ui-react'


export default function LogoutPage(){

  return (
    <Container>
      <Header>
        You have been logged out
      </Header>
    </Container>
  )
}
