import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../../common/common'
import {
    formatMultipleOneDecimal,
    formatPercentNoNull,
    formatNoDecimalNoNullOrZero,
    formatOneDecimal,
    formatMultipleTwoDecimals,
    formatPercentOneDecimalNoNull, formatFullDate
} from '../../../../common/format';
import {series1ChartSpecificationByName} from "./common";
import {exitMultipleSensitivities} from "./multipleSensitivities";
const ReactHighcharts = require('react-highcharts');



const getDataRecord = ({xSpec, ySpec, isEntry, record}) => {

    return {
        x: xSpec.get(record, isEntry),
        y: ySpec.get(record, isEntry),
        label: record.company.ticker,
        company_name: record.company.short_name,

        exit_ntm_revenue_total_growth: record.exit_ntm_revenue_total_growth,
        exit_ev_to_maximized_pretax_fcf_ntm: record.exit_ev_to_maximized_pretax_fcf_ntm,
        exit_ev_to_revenue_total_ntm: record.exit_ev_to_revenue_total_ntm,
        exit_maximized_pretax_fcf_margin_ntm: record.exit_maximized_pretax_fcf_margin_ntm,

        entry_ntm_revenue_total_growth: record.entry_ntm_revenue_total_growth,
        entry_ntm_organic_arr_growth: record.entry_ntm_organic_arr_growth,
        entry_ev_to_revenue_total_ntm: record.entry_ev_to_revenue_total_ntm,
        entry_ev_to_maximized_pretax_fcf_ntm: record.entry_ev_to_maximized_pretax_fcf_ntm,
    }

}



class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const doradoValuationRecords = this.props.doradoValuationRecords;
        const loading = this.props.loading;

        const xSpec = this.props.xSpec;
        const ySpec = this.props.ySpec;


        const series = [
            {
                name: 'Entry Valuation',
                data: doradoValuationRecords.map(r => {
                    return getDataRecord({xSpec, ySpec, record: r, isEntry: true})
                }),
                // tooltip: {
                //     useHTML: true,
                //     shared: false,
                //     headerFormat: '',
                //     pointFormatter: function () {
                //
                //         // return '<b>' + this.company_name + '</b><br/>' +
                //         //     '<table>' +
                //         //     '<tr><td> Metric </td><td>Entry</td><td>Exit</td></tr>' +
                //         //     '<tr><td>NTM Rev Growth</td><td>' + formatPercentNoNull(this.entry_ntm_revenue_total_growth) + '</td><td>' + formatPercentNoNull(this.entry_ntm_revenue_total_growth) + '</td></tr>' +
                //         //     '</table>'
                //
                //         return '<table>' +
                //             '<tr><td> Metric </td><td>Entry</td><td>Exit</td></tr>' +
                //             '<tr><td>NTM Rev Growth</td><td>' + formatPercentNoNull(this.entry_ntm_revenue_total_growth) + '</td><td>' + formatPercentNoNull(this.entry_ntm_revenue_total_growth) + '</td></tr>' +
                //             '</table>'
                //
                //         //
                //         // return '<table>' +
                //         // '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                //         // '<tr><td>' + series1ChartSpec.getYAxisLabel() + '</td><td>' + series1ChartSpec.formatY(this.y) + '</td></tr>' +
                //         // '</table>'
                //         //
                //         // return [
                //         //     '<b>' + this.company_name + '</b>',
                //         //     'Exit NTM Rev Growth: ' + formatPercentNoNull(this.exit_ntm_revenue_total_growth),
                //         //     'Exit EV/NTM Rev: ' + formatMultipleOneDecimal(this.exit_ev_to_revenue_total_ntm),
                //         //     'Exit EV/Max Pretax FCF: ' + formatMultipleOneDecimal(this.exit_ev_to_maximized_pretax_fcf_ntm),
                //         //     'Exit Max Pretax FCF Margin:  ' + formatPercentOneDecimalNoNull(this.exit_maximized_pretax_fcf_margin_ntm)
                //         //
                //         // ].join("<br/>")
                //     }
                //
                // },
            },
            {
                name: 'Exit Valuation',
                data: doradoValuationRecords.map(r => {
                    return getDataRecord({xSpec, ySpec, record: r, isEntry: false})
                }),
                // tooltip: {
                //     pointFormatter: function () {
                //         return [
                //             '<b>' + this.company_name + '</b>',
                //             'Exit NTM Rev Growth: ' + formatPercentNoNull(this.exit_ntm_revenue_total_growth),
                //             'Exit EV/NTM Rev: ' + formatMultipleOneDecimal(this.exit_ev_to_revenue_total_ntm),
                //             'Exit EV/Max Pretax FCF: ' + formatMultipleOneDecimal(this.exit_ev_to_maximized_pretax_fcf_ntm),
                //             'Exit Max Pretax FCF Margin:  ' + formatPercentOneDecimalNoNull(this.exit_maximized_pretax_fcf_margin_ntm)
                //
                //         ].join("<br/>")
                //     }
                //
                // },
            },

        ];

        // if (showMultipleSensitivities && series1ChartSpec.name === 'Exit Pretax FCF Multiple vs Exit NTM Growth') {
        //
        //     const multipleSensitivitySeries = exitMultipleSensitivities.map(s => {
        //         return {
        //             name: s.name,
        //             type: 'line',
        //             color: s.color,
        //             marker: {
        //                 enabled: false
        //             },
        //             enableMouseTracking: false,
        //             tooltip: {
        //                 enabled: false
        //             },
        //             data: s.data.map(d => {
        //                 return {
        //                     x: d.exitGrowth,
        //                     y: d.exitPretaxFcfMultiple
        //                 }
        //             })
        //         }
        //     })
        //
        //     series.push(...multipleSensitivitySeries)
        //
        // }

        const config = {
            chart: {
                type: 'scatter',
                zoomType: 'xy'
            },
            title: {
                text: xSpec.label + " vs " + ySpec.label
            },
            xAxis: {
                title: {
                    text: xSpec.label
                },
                labels: {
                    formatter: function () {
                        return xSpec.format(this.value);
                    }
                }
            },
            yAxis: {
                title: {
                    text: ySpec.label
                },
                labels: {
                    formatter: function () {
                        return ySpec.format(this.value);
                    }
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                    stickyTracking: false,
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        style: {
                            fontSize: "9px",
                            fontWeight: 'normal',
                            textOutline: '1px',
                            color: 'rgba(0, 0, 0, .5)'
                        },
                        format: '{point.label}'
                    },
                },
                scatter: {

                    marker: {
                        symbol: 'circle',
                        radius: 4,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },

                }
            },
            tooltip: {
                    useHTML: true,
                    shared: false,
                    headerFormat: '',
                    pointFormatter: function () {

                        // return '<b>' + this.company_name + '</b><br/>' +
                        //     '<table>' +
                        //     '<tr><td> Metric </td><td>Entry</td><td>Exit</td></tr>' +
                        //     '<tr><td>NTM Rev Growth</td><td>' + formatPercentNoNull(this.entry_ntm_revenue_total_growth) + '</td><td>' + formatPercentNoNull(this.entry_ntm_revenue_total_growth) + '</td></tr>' +
                        //     '</table>'

                        return '<table>' +
                            '<tr><td></td><td style="min-width:50px"><b>Entry    </b></td><td><b>Exit</b></td></tr>' +
                            '<tr><td>NTM ARR/Rev Growth</td><td>' + formatPercentNoNull(this.entry_ntm_organic_arr_growth) + '</td><td>' + formatPercentNoNull(this.exit_ntm_revenue_total_growth) + '</td></tr>' +
                            '<tr><td>EV/NTM Rev</td><td>' + formatMultipleOneDecimal(this.entry_ev_to_revenue_total_ntm) + '</td><td>' + formatMultipleOneDecimal(this.exit_ev_to_revenue_total_ntm) + '</td></tr>' +
                            '<tr><td>EV/NTM Max ptax FCF</td><td>' + formatMultipleOneDecimal(this.entry_ev_to_maximized_pretax_fcf_ntm) + '</td><td>' + formatMultipleOneDecimal(this.exit_ev_to_maximized_pretax_fcf_ntm) + '</td></tr>' +
                            '<tr><td>Max Pretax Margin</td><td></td><td>' + formatPercentNoNull(this.exit_maximized_pretax_fcf_margin_ntm) + '</td></tr>' +
                            '</table>'

                        //
                        // return '<table>' +
                        // '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                        // '<tr><td>' + series1ChartSpec.getYAxisLabel() + '</td><td>' + series1ChartSpec.formatY(this.y) + '</td></tr>' +
                        // '</table>'
                        //
                        // return [
                        //     '<b>' + this.company_name + '</b>',
                        //     'Exit NTM Rev Growth: ' + formatPercentNoNull(this.exit_ntm_revenue_total_growth),
                        //     'Exit EV/NTM Rev: ' + formatMultipleOneDecimal(this.exit_ev_to_revenue_total_ntm),
                        //     'Exit EV/Max Pretax FCF: ' + formatMultipleOneDecimal(this.exit_ev_to_maximized_pretax_fcf_ntm),
                        //     'Exit Max Pretax FCF Margin:  ' + formatPercentOneDecimalNoNull(this.exit_maximized_pretax_fcf_margin_ntm)
                        //
                        // ].join("<br/>")
                    }

                },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent