import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../common/query/parse";
import {createAppendSortToQuery} from "../../common/query/add";
import {
    getMostRecentSortColumnAndDirectionForRDG,
    getRdgRowsFromRecords,
    getTableClassName,
    makeHandleReactDataGridSort
} from "../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {getWindowHeightLessMenuBar} from "../../common/common";
import React from "react";
import {IsEditableFieldRenderer} from "./rdgComponents";


export default ({
                    columns,
                    records,
                    loading,
                    onGridRowsUpdated,
                    fieldSpecs = {},
                    colorEditableFieldsBlue = false

                }) => {

    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const onChangeSort = createAppendSortToQuery({history, searchProps: searchProps});
    const handleReactDataGridSort = makeHandleReactDataGridSort(onChangeSort);
    const {sortColumn, sortDirection} = getMostRecentSortColumnAndDirectionForRDG(searchProps);

    if (colorEditableFieldsBlue) {
        columns = columns.map(col => {
            if (col.editable) {
                col.headerRenderer = <IsEditableFieldRenderer/>
            }
            return col
        })
    }



    const rows = getRdgRowsFromRecords({records, columns});

    const isEditable = onGridRowsUpdated != null;

    if (onGridRowsUpdated == null) {
        onGridRowsUpdated = () => {
        };
    }

    return (

        <div className={getTableClassName(loading)}>

            <ReactDataGrid
                columns={columns}
                onGridSort={handleReactDataGridSort}
                rowGetter={index => rows[index]}
                rowsCount={rows.length}
                rowHeight={22}
                headerRowHeight={100}
                minHeight={getWindowHeightLessMenuBar()}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                enableCellSelect={isEditable}
                onGridRowsUpdated={onGridRowsUpdated}
            />

        </div>

    )
};