import {CompanyLinkFormatter} from "../../../common/reactDataGrid/formatters";
import {tradeColumns} from "./trades";
import {portfolioPositionColumns} from "./portfolioPositions";
import {targetPortfolioColumns} from "../../dorado/portfolioTarget/targetPortfolioColumns";
import {tradeOrderColumns} from "../../dorado/portfolioTarget/tradeOrderColumns";
import {TradeType} from "../../../common/constants";
import {sumArray} from "../../../common/common";


export const fieldSpecs = {
    portfolio_id: {
        parse: (v) => {
            return v == null ? null : parseInt(v)
        }
    },

    portfolio_state_id: {
        parse: (v) => {
            return v == null || v === "" ? null : parseInt(v)
        }
    },
    viewOption: {
        parse: (v) => {
            return v == null ? 'Target Portfolio' : v
        }
    }
};

export const viewSpecs = [
    {name: 'Target Portfolio', columns: targetPortfolioColumns},
    {name: 'Trade Orders', columns: tradeOrderColumns},
    {name: 'Trades', columns: tradeColumns},
    {name: 'Beginning Portfolio', columns: portfolioPositionColumns},
    {name: 'Ending Portfolio', columns: portfolioPositionColumns}
];

export const columnsByViewOption = viewSpecs.reduce((obj, spec) => {
    obj[spec.name] = spec.columns;
    return obj
}, {});


export const viewOptions = viewSpecs.map(s => {
    return {
        key: s.name,
        value: s.name,
        text: s.name
    }
});


export const companyColumn = {
    key: 'company_name',
    name: 'Name',
    width: 200,
    frozen: true,
    sortable: true,
    formatter: CompanyLinkFormatter,
    _getValueFromRecord: (r) => {
        return {
            company_name: r.company_name,
            company_id: r.company_id
        }
    }
};


export const getRecordsFromViewOption = ({
                                             viewOption,
                                             doradoTargetData
                                         }) => {
    return {
        'Target Portfolio': doradoTargetData.dorado_target_portfolio_positions,
        'Trade Orders': doradoTargetData.dorado_target_security_orders,
        'Trades': getTradesWithTotal(doradoTargetData.security_trades),
        'Beginning Portfolio': doradoTargetData.beginning_portfolio_positions,
        'Ending Portfolio': doradoTargetData.ending_portfolio_positions,

    }[viewOption]
};


const getTradesWithTotal = (security_trades) => {

    const buys = security_trades.filter(r => r.trade_type === TradeType.buy);
    const sells = security_trades.filter(r => r.trade_type === TradeType.sell);

    const totalBuy = {
        company: {
            company_name: 'Buy Total'
        },
        gross_value_before_transaction_cost_usd: sumArray(buys.map(r => r.gross_value_before_transaction_cost_usd)),
        net_value_usd: sumArray(buys.map(r => r.net_value_usd))
    }

    const totalSell = {
        company: {
            company_name: 'Sell Total'
        },
        gross_value_before_transaction_cost_usd: sumArray(sells.map(r => r.gross_value_before_transaction_cost_usd)),
        net_value_usd: sumArray(sells.map(r => r.net_value_usd))
    }

    const totalNet = {
        company: {
            company_name: 'Net Buy Total'
        },
        gross_value_before_transaction_cost_usd: totalBuy.gross_value_before_transaction_cost_usd - totalSell.gross_value_before_transaction_cost_usd,
        net_value_usd: totalBuy.net_value_usd - totalSell.net_value_usd
    }


    const totalRows = [
        {company: {}},
        totalBuy,
        totalSell,
        totalNet
    ]

    return security_trades.concat(totalRows)

}