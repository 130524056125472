import moment from "moment";
import {getDateOptionsToPresent} from "../../../common/dateHelpers";


const earliestStartDate = moment("20180101", "YYYYMMDD");

export const startEndDateOptions = getDateOptionsToPresent(earliestStartDate).map(d => {
    const s = d.format('YYYY-MM-DD');
    return {
        key: s,
        value: s,
        text: d.format('MM-DD-YYYY')
    }

});


export const showAlphaOptions = ['Cumulative', 'Daily'].map(d => {
    return {
        key: d,
        value: d,
        text: d
    }
})


export const fieldSpecs = {
    portfolioIds: {
        parse: (v) => v == null ? [] : v.map(id => parseInt(id))
    },
}