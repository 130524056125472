import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Button, Container, Divider, Form, Grid, Header, Table} from 'semantic-ui-react'
import FinancialsComponent from '../forecasts/FinancialsComponent';
import {formatFullDate, validMoment} from "../../../../common/format";
import PeriodTypeSelect from '../../../../components/PeriodTypeSelect';
import ColorsPopup from '../../../../components/ColorsPopup'
import {useReloadOnChange} from "../../../../common/hooks";
import {fetchCompany, fetchHistoricalPeriodRecordsForCompany} from "../../../../features/companyFeatureSlice";
import {EstimateType, PeriodType} from "../../../../common/constants";
import {fetchDoradoOverviewDataForCompany} from "../../../../features/dorado/doradoCompanyDetail";
import MarketValueComponent from "./MarketValueComponent";
import MultiplesComponent from "./MultiplesComponent";


const fieldSpecs = {
    periodType: {
        parse: (value) => {
            if (value) {
                return value
            }
            return PeriodType.annual
        }
    },
}



export default () => {

    useReloadOnChange({
        fieldSpecs,
        queryMatchFields: ['currencyType', 'periodType'],
        matchFields: ['id'],
        requestData: requestOverviewData
    });

    useReloadOnChange({
        fieldSpecs,
        queryMatchFields: [],
        matchFields: ['id'],
        requestData: requestCompanyData
    });

    const {
        company,
        loadingCompany,
        } = useSelector(state => state.company, shallowEqual);

    const {
        doradoOverviewData,
        loadingDoradoOverviewData
    } = useSelector(state => state.doradoCompanyDetail, shallowEqual);

    const loading = loadingCompany || loadingDoradoOverviewData;

    const viewComponent = <FinancialsComponent financialRecords={doradoOverviewData.financial_period_records}
                                               loading={loading}/>;

    const lastReportedPeriodText = loading ? null : company.last_reported_period  + ' (' + validMoment(company.last_reported_period_end_date, 'M/D/YYYY') + ')'

    return (
        <Container fluid>
            <Grid padded>

                <Grid.Column width={4}>
                    <Grid.Row>
                        <Grid>
                            <Grid.Column width={14}>
                                <Header as='h3'>
                                    {company.company_name}
                                    <Header.Subheader>
                                        {company.exchange_ticker}
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>

                            <Grid.Column width={2}>
                                <ColorsPopup/>
                            </Grid.Column>
                        </Grid>
                    </Grid.Row>

                    <br/>
                    <Grid.Row>
                        <Form>
                            <PeriodTypeSelect/>

                        </Form>
                        <Table basic='very' compact='very' collapsing size={'small'}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Filing Currency:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {company.filing_currency}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Trading Currency:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {company.trading_currency}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Last Reported Period:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {lastReportedPeriodText}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        IPO Date:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatFullDate(company.ipo_date)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Year Founded:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {company.year_founded}
                                    </Table.Cell>
                                </Table.Row>


                            </Table.Body>
                        </Table>



                        <MarketValueComponent company={company}
                                              doradoValuationRecord={doradoOverviewData.dorado_valuation_record}
                                              loading={loading}
                        />
                        <Divider horizontal>Multiples</Divider>
                        <MultiplesComponent company={company}
                                              doradoValuationRecord={doradoOverviewData.dorado_valuation_record}
                                              loading={loading}
                        />
                    </Grid.Row>

                </Grid.Column>

                <Grid.Column width={12}>
                    {viewComponent}


                </Grid.Column>

            </Grid>
        </Container>
    )
}



const requestCompanyData = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    dispatch(fetchCompany({companyId: id}));
};


const requestOverviewData = ({dispatch, searchProps, matchParams}) => {

    const {id} = matchParams;

    const periodType = searchProps.periodType;

    dispatch(fetchDoradoOverviewDataForCompany({companyId: id, periodType}));

};
