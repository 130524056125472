import React from 'react';
import {
    NoDecimalNoNullOrZeroFormatter,
    PercentOneDecimalNoNullFormatter,
    SharePriceFormatter
} from "../../../../common/reactDataGrid/formatters";
import {CompanyBacktestValuationsLinkFormatter} from "../../common";
import {Link} from "react-router-dom";
import {formatPercentOneDecimalNoNull} from "../../../../common/format";


export class BacktestValuationsLinkFormatter extends React.Component {
    render() {

        const companyId = this.props.value.company_id;
        const companyName = this.props.value.company_name;

        return (
            <Link to={'/company_detail/dorado_company_valuation_comparisons/' + companyId}>
                {companyName}
            </Link>)
    }
}


class PositiveBoldFormatter extends React.Component {
    render() {

        const value = this.props.value;

        const style = {

        }

        if (value > 0) {
            style['fontWeight'] =  'bold';
        }

        return <div style={style} className="align-right">{formatPercentOneDecimalNoNull(value)}</div>


    }
}




export const columns = [
    {
        key: 'company_name',
        name: 'Name',
        width: 200,
        frozen: true,
        sortable: true,
        formatter: BacktestValuationsLinkFormatter,
        _getValueFromRecord: (r) => {
            return {
                company_name: r.company_name,
                company_id: r.company_id
            }
        }
    },

    {
        key: 'latest_premium',
        name: 'Latest Valuation Premium',
        width: 90,
        formatter: PositiveBoldFormatter
    },
    {
        key: 'min_premium',
        name: 'Min. Valuation Premium',
        width: 90,
        formatter: PositiveBoldFormatter
    },
    {
        key: 'max_premium',
        name: 'Max. Valuation Premium',
        width: 90,
        formatter: PositiveBoldFormatter
    },
    {
        key: 'gap',
        name: '  ',
        width: 30,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'latest_premium_score',
        name: 'Latest Premium Score',
        width: 90,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'min_premium_score',
        name: 'Min. Premium Score',
        width: 90,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'max_premium_score',
        name: 'Max. Premium Score',
        width: 90,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'gap2',
        name: '  ',
        width: 30,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'latest_current_margin_percent_of_exit_max_margin',
        name: 'Latest Current Margin % Exit',
        width: 90,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'min_current_margin_percent_of_exit_max_margin',
        name: 'Min Current Margin % Exit',
        width: 90,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'max_current_margin_percent_of_exit_max_margin',
        name: 'Max Current Margin % Exit',
        width: 90,
        formatter: PercentOneDecimalNoNullFormatter
    },

];



