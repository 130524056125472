import React, {useEffect} from 'react';
import {
    CompanyLinkFormatter,
    PercentOneDecimalNoNullFormatter,
    PercentTwoDecimalNoZeroFormatter,
    NoDecimalNoNullOrZeroFormatter,
    SharePriceFormatter,
    TwoDecimalNoNullFormatter,
    TwoDecimalCurrencyFormatter, BoolToYesCheckFormatter, BoolToYesNoCheckFormatter
} from "../../../common/reactDataGrid/formatters";
import {CurrencySharePriceFormatter, TradeTypeFormatter} from "../../../common/columns";
import {boolOptions} from "../../../common/reactDataGrid/common";
import {Editors} from 'react-data-grid-addons';
import {exchangeIsOpenCol} from "../commonColumns";
import {companyOrEtfBacktestLinkColumn} from "../common";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";

const {DropDownEditor} = Editors;


export const tradeOrderColumns = [
    CompanyLinkFromSecurityListing,

    {
        key: 'trade_type',
        name: 'Trade Type',
        width: 80,
        formatter: TradeTypeFormatter,
        _getValueFromRecord: (r) => {
            return {
                trade_type: r.trade_type,
                is_short_sale_or_cover: r.is_short_sale_or_cover
            }
        }
    },
    {
        key: 'last_price_per_share',
        name: 'Last Price $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.last_price_per_share,
                trading_currency: r.currency
            }
        }
    },
    {
        key: 'limit_price_per_share',
        name: 'Limit Price $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.limit_price_per_share,
                trading_currency: r.currency
            }
        }
    },
    {
        key: 'limit_price_premium_vs_last_price',
        name: 'Limit vs Last Price',
        width: 80,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'shares',
        name: 'Shares',
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'gross_value_before_transaction_cost_usd',
        name: 'Gross Value $USD (before transaction cost)',
        width: 110,
        formatter: TwoDecimalCurrencyFormatter
    },
    {
        key: 'hard_transaction_cost_usd',
        name: 'Transaction Costs $USD',
        width: 90,
        formatter: TwoDecimalCurrencyFormatter
    },
    {
        key: 'net_value_usd',
        name: 'Net Value $USD',
        width: 110,
        formatter: TwoDecimalCurrencyFormatter
    },
    {
        key: 'gross_value_before_transaction_cost',
        name: 'Gross Value $local (if different)',
        width: 110,
        formatter: TwoDecimalCurrencyFormatter,
        _getValueFromRecord: (r) => {
            if (r.currency === 'USD') {
                return null
            }
            return r.gross_value_before_transaction_cost

        }
    },
    exchangeIsOpenCol,
    {
        key: 'gs_trade_algo_template',
        name: 'Algo Template',
        width: 200,
    },
    {
        key: 'id',
        name: 'ID',
        width: 70,
    },


];


export const realTradeOrdersColumns = [
    ...tradeOrderColumns.slice(0, tradeOrderColumns.length - 2),
    {
        key: '_include_trade_order',
        name: 'Include In Trade Order?',
        formatter: <BoolToYesNoCheckFormatter/>,
        width: 70,
        editable: true,
        sortable: true,
        editor: <DropDownEditor options={boolOptions}/>
    },
    ...tradeOrderColumns.slice(tradeOrderColumns.length - 2, tradeOrderColumns.length),
]