import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import moment from "moment";
import {formatSharePrice, formatFullDate, formatPercentOneDecimalNoNull} from "../../../common/format";
const ReactHighcharts = require('react-highcharts');


class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const doradoUniverseValuationRecords = this.props.doradoUniverseValuationRecords;
        const loading = this.props.loading;
        const chartSpec = this.props.chartSpec;

        const series = chartSpec.getSeries(doradoUniverseValuationRecords);

        const config = {
            chart: {
                type: 'line',
                zoomType: 'xy'
            },
            title: {
                text: 'Universe Valuation'
            },
            xAxis: {
                title: {
                    text: 'Date'
                },
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return formatFullDate(this.value, false);
                    }
                }
            },
            yAxis: [
                {
                    title: {
                        text: chartSpec.yAxisTitle
                    },
                    labels: {
                        formatter: function () {
                            return chartSpec.yAxisFormat(this.value);
                        }

                    },
                    height: '75%',
                },
                {
                    top: '75%',
                    height: '25%',
                    // min: 0,
                }
            ],
            tooltip: {
                useHTML: true,
                shared: false,
                headerFormat: '',
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                        '<tr><td>' + this.series.yAxis.axisTitle.textStr + '</td><td>' + chartSpec.yAxisFormat(this.y) + '</td></tr>' +
                        '</table>'
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                },
                line: {
                    marker: {
                        enabled: false
                    },
                }
            },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent