import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {Form, Header, Divider} from 'semantic-ui-react'
import {parseSearchProps} from "../../../common/query/parse";
import {
  createAddCheckedValueToQuery,
  createAddValueToQuery
} from '../../../common/query/add';
import {fieldSpecs} from './common'
import {formatFullDate} from "../../../common/format";


const geographyOptions = [0,1,2,3,4,5,6,7,8,9,10].map(option => {
  return {
    key: option.toString(),
    value: option.toString(),
    text: option.toString()
  }
});

class EmployeeAnalysisSidebar extends Component {


  render() {

    const history = this.props.history;
    const searchProps = parseSearchProps({searchString: this.props.location.search, fieldSpecs});

    const totalRecords = this.props.loadingAnalysisResult ? 0 : this.props.analysisResult.total.count;
    const notEmployeeCount = this.props.loadingAnalysisResult ? 0 : this.props.analysisResult.not_employee_count;
    const notAssociatedCount = this.props.loadingAnalysisResult ? 0 : this.props.analysisResult.not_associated_count;

    return (
      <Form>
        <Header>
          <Header.Content>
            {this.props.loadingScrapeRecord ? '...' : this.props.scrapeRecord.company.company_name}
            <Header.Subheader>
              {this.props.loadingScrapeRecord ? null : formatFullDate(this.props.scrapeRecord.date_acquired)}
            </Header.Subheader>
          </Header.Content>
        </Header>
        <p>{totalRecords} Total Records</p>
        <p>{notEmployeeCount} Not Employee</p>
        <p>{notAssociatedCount} Not Associated</p>
        <Divider horizontal >Options</Divider>
        <Form.Checkbox label="Show Roles"
                       checked={searchProps.showRoles}
                       onChange={createAddCheckedValueToQuery({
                         history,
                         searchProps,
                         fieldName: 'showRoles'
                       })}
        />
        <Form.Select
          options={geographyOptions}
          value={searchProps.geographyGroups}
          onChange={createAddValueToQuery({history, searchProps, fieldName: 'geographyGroups'})}
          label="Geography Buckets"
        />
      </Form>
    )
  }
}



function mapStateToProps(state) {
  return {
    scrapeRecord: state.employeeRecordStore.employeesScrapeRecord,
    loadingScrapeRecord: state.employeeRecordStore.loadingEmployeesScrapeRecord,

    analysisResult: state.employeeAnalysisStore.analysisResult,
    loadingAnalysisResult: state.employeeAnalysisStore.loading,

  }
}


export default withRouter(connect(mapStateToProps,
  {

  })(EmployeeAnalysisSidebar));
