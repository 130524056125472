import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../../common/common'
import {formatMultipleOneDecimal, formatPercentNoNull, formatNoDecimalNoNullOrZero, formatMultipleTwoDecimals} from '../../../../common/format';
const ReactHighcharts = require('react-highcharts');


class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const doradoValuationRecords = this.props.doradoValuationRecords;
        const loading = this.props.loading;

        const xAxisCategories = doradoValuationRecords.map(r => r.company.short_name);

        const maxMarginSeries = {
            name: 'Max Pretax FCF Margin',
            dataLabels: {
                enabled: true,
                // rotation: -90,
                color: 'black',
                align: 'right',
                formatter: function() {
                    return formatNoDecimalNoNullOrZero(this.y * 100)
                },
                y: 30, // 10 pixels down from the top
                shadow: false,
                style: {
                    fontSize: '10px',
                    fontFamily: 'Verdana, sans-serif'
                },
                border: 0
            },
            data: doradoValuationRecords.map(r => {

                return {
                    y: r.exit_maximized_pretax_fcf_margin_ntm,
                    max_margin: r.exit_maximized_pretax_fcf_margin_ntm,
                    gross_margin: r.exit_ntm_gross_margin,
                    ebitda_margin: r.exit_maximized_ebitda_margin_ntm,
                    capex_margin: r.exit_maximized_capital_expenditures_percent_of_sales_ntm,
                    gross_retention: r.exit_arr_gross_retention,
                    bookings_to_sm: r.entry_average_implemented_arr_to_sm,
                    retention_sales_efficiency_modifier: r.exit_max_margin_churn_sales_efficiency_modifier
                }
            }),
            color: '#41ab5d',
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormatter: function() {
                    return [
                        'Exit Gross Margin: ' + formatPercentNoNull(this.gross_margin),
                        'Max EBITDA Margin: ' + formatPercentNoNull(this.ebitda_margin),
                        'Capex % Sales: ' + formatPercentNoNull(this.capex_margin),
                        'Max Pretax FCF Margin: ' + formatPercentNoNull(this.max_margin),
                        'Gross Retention: ' + formatPercentNoNull(this.gross_retention),
                        'Bookings/S&M: ' + formatMultipleTwoDecimals(this.bookings_to_sm),
                        'Retention/S&M Efficiency Modifier: ' + formatPercentNoNull(this.retention_sales_efficiency_modifier)
                    ].join("<br/>")
                }

            },

        };

        const grossMarginSeries = {
            name: 'Exit Gross Margin',
            data: doradoValuationRecords.map(r => {
                return r.exit_ntm_gross_margin
            }),
            color: '#bdbdbd',
            tooltip: {
                enabled: false
            },
            enableMouseTracking: false
        };

        const ebitdaMarginSeries = {
            name: 'Max EBITDA Margin',
            data: doradoValuationRecords.map(r => {
                return r.exit_maximized_ebitda_margin_ntm
            }),
            color: '#4292c6',
            enableMouseTracking: false
        };

        const smModifier = {
            name: 'Retention/Sales Efficiency Modifier',
            data: doradoValuationRecords.map(r => {
                return r.exit_maximized_ebitda_margin_ntm + r.exit_max_margin_churn_sales_efficiency_modifier
            }),
            color: '#fd8d3c',
            enableMouseTracking: false
        };

        const pointWidth = 25;

        const config = {
            chart: {
                type: 'column',
                scrollablePlotArea: {
                    minWidth: pointWidth * doradoValuationRecords.length * 1.2,
                    scrollPositionX: 1
                }
            },
            title: {
                text: 'Max Margin Components'
            },
            yAxis: {
                min: 0,
                max: 1,
                title: {
                    text: 'Margin'
                },
                labels: {
                    formatter: function () {
                        return formatPercentNoNull(this.value);
                    }
                },
            },
            plotOptions: {
                column: {
                    grouping: false,
                    // groupPadding: 0.5,   // Exactly overlap
                    pointWidth: pointWidth,
                    // pointPadding: .1,
                    // shadow: false,
                    // borderWidth: 0,
                    borderColor: 'black'
                }
            },
            xAxis: {
                categories: xAxisCategories,
                scrollbar: {
                    enabled: true
                }
            },
            series: [
                grossMarginSeries,
                smModifier,
                ebitdaMarginSeries,
                maxMarginSeries,
            ],



        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}

const getRetention_sales_efficiency_modifier = (r) => (1 - r.exit_arr_gross_retention) / r.entry_average_implemented_arr_to_sm;


export default ChartComponent