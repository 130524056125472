import client from "../../empClient";

const employeeRecordsService = client.service('source_employee_records');


export function fetchEmployeeRecordsForCompany({sort, filters, employeesScrapeRecordId}) {

  return dispatch => {
    dispatch({
      type: 'FETCH_EMPLOYEE_RECORDS',
      payload: employeeRecordsService.find({
        query: {
          $sort: sort,
          ...filters,
          employees_scrape_record_id: employeesScrapeRecordId
        }
      })
    });
  };


}
