import client from '../benchClient';

const metadataService = client.service('metadata');
const queueDataService = client.service('process_queue_summary');


export function fetchValuationMetadata({dispatch}) {
    dispatch({
        type: 'FIND_VALUATION_METADATA',
        payload: metadataService.find({
            query: {}
        })
    });

}


export const fetchQueueData = ({dispatch}) => {
    return dispatch({
        type: 'FETCH_QUEUE_DATA',
        payload: queueDataService.find({
            query: {}
        })
    })
};

