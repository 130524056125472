import {PortfolioType} from "../../../common/constants";


export const getDailyAlphaSeries = ({portfolioReturnsGroups}) => {

    const result = getBasePortfolioAndBenchmark({portfolioReturnsGroups});

    if (result.basePortfolio === undefined) {
        return []
    }

    const baseSeries = result.basePortfolio.series;
    const benchmarkSeries = result.benchmarkPortfolio.series;

    const alphaSeries = baseSeries.map(s => {

        const benchmarkRecord = benchmarkSeries.find(r => r.standard_date === s.standard_date);

        let cumulative_alpha = null;

        if (benchmarkRecord != null) {
            if (benchmarkRecord.indexed_return != null && s.indexed_return != null) {
                cumulative_alpha = s.indexed_return - benchmarkRecord.indexed_return;
            }
        }

        return {
            standard_date: s.standard_date,
            cumulative_alpha: cumulative_alpha
        }
    }).filter(s => s.cumulative_alpha != null);

    let previousCumulative = 0;
    for (let s of alphaSeries) {
        s.daily_alpha = s.cumulative_alpha - previousCumulative;
        previousCumulative = s.cumulative_alpha;
    }


    return alphaSeries

}


export const getBasePortfolioAndBenchmark = ({portfolioReturnsGroups}) => {

    if (portfolioReturnsGroups.length < 2) {
        return {}
    }


    let basePortfolio = portfolioReturnsGroups.find(p => {
        if (p.is_backtest === false) {
            return true
        }

        if (p.type === PortfolioType.dorado) {
            return true
        }

        if (p.type !== PortfolioType.backtest_benchmark) {
            return true
        }

        return false
    });

    if (basePortfolio === undefined) {
        basePortfolio = portfolioReturnsGroups[0];
    }

    const benchmarkPortfolio = portfolioReturnsGroups.find(p => p.id !== basePortfolio.id);

    return {
        basePortfolio,
        benchmarkPortfolio
    }
}

