import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {Form, Header} from 'semantic-ui-react'


class RoleSidebar extends Component {


  render() {

    const history = this.props.history;


    let newButton = null;
    if (! this.props.loadingCompany) {
      newButton = (
        <Form.Button primary onClick={() => history.push('/employee_analysis/new_role/' + this.props.company.id)}>
          New Role
        </Form.Button>
      )
    }


    return (
      <Form>
        <Header>{this.props.loadingCompany ? null : this.props.company.company_name}</Header>
        {newButton}

      </Form>
    )


  }
}


function mapStateToProps(state) {
  return {
    company: state.companyStore.company,
    loadingCompany: state.companyStore.loadingCompany

  }
}


export default withRouter(connect(mapStateToProps,
  {

  })(RoleSidebar));
