import { createSlice } from '@reduxjs/toolkit'

import client from '../../benchClient';
import {makeStandardReducers, executeWithPending} from "../common";
import {getInitialStateWithLoading} from "../common";
const doradoPredictionsService = client.service('dorado_predictions');
const doradoRegressionSummariesService = client.service('dorado_regression_summaries');


const doradoPredictionsFeatureSlice = createSlice({
    name: 'doradoPredictions',
    initialState: getInitialStateWithLoading({
        doradoPredictions: [],
        doradoRegressionSummaries: []
    }),
    reducers: {
        ...makeStandardReducers({
            prefix: 'fetchDoradoPredictionsForCompany',
            destinationVariable: 'doradoPredictions'
        }),
        ...makeStandardReducers({
            prefix: 'fetchDoradoRegressionSummariesForCompany',
            destinationVariable: 'doradoRegressionSummaries'
        }),

    }
});


export const fetchDoradoPredictionsForCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPredictionsFeatureSlice,
        prefix: 'fetchDoradoPredictionsForCompany',
        getPayload: () => {
            return doradoPredictionsService.find({
                query: {
                    company_id: companyId,
                    $sort: {
                        entry_date: 1
                    }
                }

            })
        }
    })
};

export const fetchDoradoRegressionSummariesForCompany = ({companyId, predictionConstraint}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoPredictionsFeatureSlice,
        prefix: 'fetchDoradoRegressionSummariesForCompany',
        getPayload: () => {
            return doradoRegressionSummariesService.find({
                query: {
                    company_id: companyId,
                    prediction_constraint: predictionConstraint
                }

            })
        }
    })
};

export default doradoPredictionsFeatureSlice.reducer