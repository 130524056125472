import {formatMultipleOneDecimal, formatPercentNoNull, formatPercentOneDecimalNoNull} from '../../common/format';
import {revenueLines, grossProfitLines, attractiveRuleOf40Lines, attractiveEBITDAYieldLine} from '../../common/chart/chartLines';
import {getTableField} from "../../common/reactDataGrid/valuationColumns";


const getOrganicRevenueGrowthOrRevenueGrowth = ({record, period, useDilutedGrowth}) => {

  const organicBaseField = useDilutedGrowth ? 'dil_adj_organic_constant_currency_revenue_growth' : 'organic_constant_currency_revenue_growth';

  const fieldName = getTableField({baseField: organicBaseField, period});
  const organicValue = record[fieldName];
  if (organicValue != null) {
    return organicValue
  }

  const baseField = useDilutedGrowth ? 'dil_adj_revenue_growth' : 'revenue_growth';
  return record[getTableField({baseField, period})]

};

const getRevenueGrowthLabel = ({period, useDilutedGrowth}) => {

  if (useDilutedGrowth) {
    return period + ' Diluted Revenue Growth (organic if provided)';
  }

  return period + ' Revenue Growth (organic if provided)';

};


export const chartSpecificationByName = {
    'EV/Revenue vs Revenue Growth': {

        getX: getOrganicRevenueGrowthOrRevenueGrowth,
        getY: ({record, period}) => {
            return record[getTableField({baseField: 'ev_to_revenue_total', period})]
        },
        getXAxisLabel: getRevenueGrowthLabel,
        getYAxisLabel: (period) => {
            return 'EV/' + period + ' Revenue';
        },

        formatX: formatPercentNoNull,
        formatY: formatMultipleOneDecimal,

        defaultAxes: {
            floorX: -.2,
            ceilingX: .4,
            minY: 0,
            ceilingY: 20
        },
        lines: revenueLines

    },
    'EV/Recurring Revenue vs Revenue Growth': {
        getX: getOrganicRevenueGrowthOrRevenueGrowth,
        getY: ({record, period}) => {
            return record[getTableField({baseField: 'ev_to_revenue_recurring', period})]
        },
        getXAxisLabel: getRevenueGrowthLabel,
        getYAxisLabel: (period) => {
            return 'EV/' + period + ' Recurring Revenue';
        },

        formatX: formatPercentNoNull,
        formatY: formatMultipleOneDecimal,

        defaultAxes: {
            floorX: -.2,
            ceilingX: .4,
            minY: 0,
            ceilingY: 20
        },
        lines: revenueLines
    },
    'EV/Gross Profit vs Revenue Growth': {
        getX: getOrganicRevenueGrowthOrRevenueGrowth,
        getY: ({record, period}) => {
            return record[getTableField({baseField: 'ev_to_gross_profit_total', period})]
        },
        getXAxisLabel: getRevenueGrowthLabel,
        getYAxisLabel: (period) => {
            return 'EV/' + period + ' Gross Profit';
        },

        formatX: formatPercentNoNull,
        formatY: formatMultipleOneDecimal,

        defaultAxes: {
            floorX: -.2,
            ceilingX: .4,
            minY: 0,
            ceilingY: 20
        },
        lines: grossProfitLines
    },
    'EV/Revenue vs (Revenue Growth + EBITDA Margin)': {
        getX: ({record, period, useDilutedGrowth}) => {
            const ebitdaMargin = record[getTableField({baseField: 'ebitda_margin', period})];
            const revenueGrowth = getOrganicRevenueGrowthOrRevenueGrowth({record, period, useDilutedGrowth});
            if (ebitdaMargin == null || revenueGrowth == null) {
                return null;
            }

            return ebitdaMargin + revenueGrowth;
        },
        getY: ({record, period}) => {
            return record[getTableField({baseField: 'ev_to_revenue_total', period})]
        },
        getXAxisLabel: ({period, useDilutedGrowth}) => {
            return period + ' Organic Revenue Growth + EBITDA Margin';
        },
        getYAxisLabel: (period) => {
            return 'EV/' + period + ' Revenue';
        },

        formatX: formatPercentNoNull,
        formatY: formatMultipleOneDecimal,
        defaultAxes: {
            floorX: -.2,
            ceilingX: .6,
            minY: 0,
            ceilingY: 20
        },
        lines: attractiveRuleOf40Lines
    },
    'EBITDA Yield vs Revenue Growth': {
        getX: getOrganicRevenueGrowthOrRevenueGrowth,
        getY: ({record, period}) => {
            return record[getTableField({baseField: 'ebitda_yield', period})]
        },
        getXAxisLabel: getRevenueGrowthLabel,
        getYAxisLabel: (period) => {
            return period + ' EBITDA / EV';
        },
        reversedYAxis: true,
        yAxisFormatter: function () {
            return formatMultipleOneDecimal(1/this.value)  + ' ' + formatPercentNoNull(this.value);
        },

        formatX: formatPercentNoNull,
        formatY: formatPercentOneDecimalNoNull,
        defaultAxes: {
            floorX: -.1,
            ceilingX: .3,
            minY: 0,
            maxY: .15
        },
        lines: [
            attractiveEBITDAYieldLine
        ]
    },
    'Pretax FCF Yield vs Revenue Growth': {
        getX: getOrganicRevenueGrowthOrRevenueGrowth,
        getY: ({record, period}) => {
            return record[getTableField({baseField: 'pretax_fcf_yield', period})]
        },
        getXAxisLabel: getRevenueGrowthLabel,
        getYAxisLabel: (period) => {
            return period + ' Pretax FCF / EV';
        },
        reversedYAxis: true,
        yAxisFormatter: function () {
            return formatMultipleOneDecimal(1/this.value)  + ' ' + formatPercentNoNull(this.value);
        },

        formatX: formatPercentNoNull,
        formatY: formatPercentOneDecimalNoNull,
        defaultAxes: {
            floorX: -.1,
            ceilingX: .3,
            minY: 0,
            maxY: .15
        },
        lines: [
            attractiveEBITDAYieldLine
        ]
    },
    'EV/Street Revenue vs PS to Street Rev Est.': {
        getX: ({record, period, useDilutedGrowth}) => {
            return record[getTableField({baseField: 'ps_estimate_vs_street_revenue_total', period})];
        },
        getY: ({record, period}) => {
            return record[getTableField({baseField: 'ev_to_revenue_total', period})]
        },
        getXAxisLabel: ({period, useDilutedGrowth}) => {
            return period + ' PS Rev Est. % of Street';
        },
        getYAxisLabel: (period) => {
            return 'EV/' + period + ' Revenue';
        },
        formatX: formatPercentNoNull,
        formatY: formatMultipleOneDecimal,
        defaultAxes: {
            floorX: -.2,
            ceilingX: .2,
            minY: 0,
            ceilingY: 20
        },
        lines: []
    },
    // 'Expected Return with Constant Rev Multiple': {
    //     getX: (valuationPeriodRecord) => {
    //         const fcf = (valuationPeriodRecord.financial_period_record.ebitda || 0) * .6;
    //         const fcfYield = fcf / valuationPeriodRecord.valuation_record.diluted_enterprise_value;
    //         return getOrganicRevenueGrowthOrRevenueGrowth(valuationPeriodRecord) + fcfYield;
    //     },
    //     getY: (valuationPeriodRecord) => {
    //         return valuationPeriodRecord.ev_to_revenue_total;
    //     },
    //     getXAxisLabel: (period) => {
    //         return period + ' FCF Yield + Organic Revenue Growth';
    //     },
    //     getYAxisLabel: (period) => {
    //         return 'EV/' + period + ' Revenue';
    //     },
    //     formatX: formatPercentOneDecimalNoNull,
    //     formatY: formatMultipleOneDecimal,
    //     defaultAxes: {
    //         floorX: -.2,
    //         ceilingX: .4,
    //         minY: 0,
    //         maxY: 5
    //     }
    // },


};


export const periodSelectionOptions = [
    '2019',
    '2020',
];

export const legendSpecs = {
    'None': {
        enabled: false
    },
    'Top Left': {
        enabled: true,
        floating: true,
        layout: 'vertical',
        verticalAlign: 'top',
        align: 'left',
        x: 80,
        y: 30
    }
};



