import React, {Component} from 'react';
import {connect} from 'react-redux';
import qs from 'qs'
import {withRouter} from 'react-router'
import {Form} from 'semantic-ui-react';
import {createToggleCheckedValueOnQuery} from "../common/query/add";
import {PeriodType} from "../common/constants"

class PeriodTypeSelect extends Component {

  render() {

    const searchProps = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    const history = this.props.history;

    const selectedPeriodType = searchProps.periodType || PeriodType.annual;
    const company = this.props.company;
    const loadingCompany = this.props.loadingCompany;

    const checked = selectedPeriodType !== PeriodType.annual;

    const onChange = createToggleCheckedValueOnQuery({
      history,
      searchProps,
      fieldName: 'periodType',
      trueValue: PeriodType.interim,
      falseValue: PeriodType.annual
    });

    return (
      <Form.Radio toggle label={getLabel({company, loadingCompany, selectedPeriodType})} onChange={onChange} checked={checked}/>
    )

  }
}

const getLabel = ({company, loadingCompany, selectedPeriodType}) => {
  if (selectedPeriodType === PeriodType.annual || loadingCompany) {
    return selectedPeriodType + ' Periods'
  }
  return company.interim_period_frequency + ' Periods'
};


function mapStateToProps(state) {
  return {
    company: state.companyStore.company,
    loadingCompany: state.companyStore.loadingCompany,
  }
}


export default withRouter(connect(mapStateToProps, {})(PeriodTypeSelect));
