import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {Button, Grid, Header, Icon, Statistic, Table} from "semantic-ui-react";
import React from "react";
import {
    formatFullDateTimeUtcToLocal,
    formatDateTimeUtcToLocal,
    formatAbsoluteDateString, formatSimpleDate
} from "../../../common/formatDate";
import {
    formatBooleanYesNo,
    formatPercentOneDecimalNoNull,
    formatOneDecimalNoNull,
    formatTwoDecimalNoNull, formatPercentTwoDecimalNoNull, formatPercentFourDecimalNoNull, formatNoDecimalNoNull
} from "../../../common/format";




export default ({doradoPortfolio, loading}) => {

    const report = doradoPortfolio.dorado_portfolio_report || {};
    console.log(report)
    return (
        <Table compact collapsing>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='2'>Parameters</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body >
                <Table.Row>
                    <Table.Cell>Created</Table.Cell>
                    <Table.Cell>{formatFullDateTimeUtcToLocal(doradoPortfolio.created_date)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Start Date</Table.Cell>
                    <Table.Cell>{formatSimpleDate(report.start_date)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>End Date</Table.Cell>
                    <Table.Cell>{formatSimpleDate(report.end_date)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Include Non-NorAm Companies?</Table.Cell>
                    <Table.Cell>{formatBooleanYesNo(report.include_non_north_america_exchanges)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Target Cash %</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.target_cash_percent)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Target Max Long Concentration Score</Table.Cell>
                    <Table.Cell>{formatNoDecimalNoNull(report.target_maximum_long_concentration_score)}</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Target Portfolio: Max Long Single Position %</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.target_maximum_long_single_position_weight)}</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Soft Trade Cost %</Table.Cell>
                    <Table.Cell>{formatPercentFourDecimalNoNull(report.soft_trade_cost_percent)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Hard Trade Cost %</Table.Cell>
                    <Table.Cell>{formatPercentFourDecimalNoNull(report.hard_trade_cost_percent)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Trading Cost % used for Trade Evaluation</Table.Cell>
                    <Table.Cell>{formatPercentTwoDecimalNoNull(report.assumed_implementation_shortfall_percent)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Trading Cost % Premium used for non-NorAm Trade Evaluation</Table.Cell>
                    <Table.Cell>{formatPercentTwoDecimalNoNull(report.non_north_america_exchange_shortfall_premium)}</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Target Max Trade % Historical Median Volume</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.target_maximum_trade_percent_of_median_volume)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                    </Table.Cell>
                    <Table.Cell>
                    </Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Enable Short</Table.Cell>
                    <Table.Cell>{formatBooleanYesNo(report.enable_short_trades)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Short Strategy</Table.Cell>
                    <Table.Cell>{report.short_strategy}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Short Min Market Value $USD mm</Table.Cell>
                    <Table.Cell>{formatNoDecimalNoNull(report.short_minimum_market_value_usd)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Short Max % NAV</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.target_maximum_short_percent_of_net_asset_value)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Short Min % NAV</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.target_minimum_short_percent_of_net_asset_value)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Universe IRR at Max Short %</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.universe_irr_at_maximum_short_percent_of_net_asset_value)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Universe IRR at Min Short %</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.universe_irr_at_minimum_short_percent_of_net_asset_value)}</Table.Cell>
                </Table.Row>


                <Table.Row>
                    <Table.Cell>Target Max Short Concentration Score</Table.Cell>
                    <Table.Cell>{formatNoDecimalNoNull(report.target_maximum_short_concentration_score)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Target Portfolio: Max Short Single Position %</Table.Cell>
                    <Table.Cell>{formatPercentOneDecimalNoNull(report.target_maximum_short_single_position_weight)}</Table.Cell>
                </Table.Row>


            </Table.Body>


        </Table>
    )

}