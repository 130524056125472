import React from 'react'
import {Form, Dropdown} from 'semantic-ui-react'
import {noSelectionOption, nullValueOption} from '../../common/query/null'


export default function BooleanTermFilter({
                                              label,
                                              onChange,
                                              value,
                                              allowNoSelection
                                          }) {

    allowNoSelection = allowNoSelection === undefined ? true : allowNoSelection;

    if (value == null) {
        value = '<no selection>'
    }

    const options = [
        {
            key: 'true',
            value: 'true',
            text: 'Yes'
        },
        {
            key: 'false',
            value: 'false',
            text: 'No'
        },
        nullValueOption
    ];

    if (allowNoSelection) {
        options.unshift(noSelectionOption)
    }

    return (
        <Form.Field>
            <label>{label}</label>
            <Dropdown selection
                      options={options}
                      value={value}
                      onChange={onChange}
            />
        </Form.Field>


    )


}
