import {commonColumnsByName} from "../../../common/reactDataGrid/columns";
import {CompanyLinkFormatter, TwoDecimalCurrencyFormatter, PercentOneDecimalNoNullFormatter} from "../../../common/reactDataGrid/formatters";
import {companyBacktestLinkColumn} from "../common";
import {formatNoDecimalNoNullOrZero, formatPercentNoNull} from "../../../common/format";


export const columns = [
    companyBacktestLinkColumn,
    {
        key: 'total_gain',
        name: 'Total Gain $',
        formatter: TwoDecimalCurrencyFormatter,
        width: 110,
        sortable: true
    },
    {
        key: 'relative_gain_vs_spy',
        name: 'Relative Gain $ vs SPY',
        formatter: TwoDecimalCurrencyFormatter,
        width: 110,
        sortable: true
    },
    {
        key: 'percent_of_total_gain',
        name: '% of Total Gain (can be neg.)',
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80,
        sortable: true
    },
    {
        key: 'average_percent_of_equity',
        name: 'Avg % Equity',
        formatter: PercentOneDecimalNoNullFormatter,
        width: 70,
        sortable: true
    },
    {
        key: 'maximum_percent_of_equity',
        name: 'Max % Equity',
        formatter: PercentOneDecimalNoNullFormatter,
        width: 70,
        sortable: true
    },




];




export const series1ChartSpecifications = [
    {
        name: 'Total Return',
        getY: (record) => record.dorado_portfolio_state_report.indexed_return,
        getYAxisLabel: () => 'Total Return',
        formatY: formatPercentNoNull,
    },
    {
        name: 'Net Asset Value',
        getY: (record) => record.net_asset_value,
        getYAxisLabel: () => 'Net Asset Value',
        yMin: 0,
        formatY: formatNoDecimalNoNullOrZero,
    },
];


export const series1ChartOptions = series1ChartSpecifications.map(spec => {
    return {
        key: spec.name,
        value: spec.name,
        text: spec.name
    }
});

export const series1ChartSpecificationByName = series1ChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});


export const fieldSpecs = {
    series1ChartName: {
        parse: (v) => v == null ? series1ChartSpecifications[0].name : v
    }
};