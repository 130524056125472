import React, {useEffect} from 'react';
import {Button, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import DoradoSidebar from "../CommonFilters";
import {fetchDoradoValuationReturnAudits} from "../../../features/dorado/doradoStockPriceAudit";
import ReturnAuditChart from './ReturnAuditChart';
import ReturnAuditHistogram from './ReturnAuditHistogram'


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {records, loading} = useSelector(state => state.doradoValuationReturnAudit, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>

            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={records.length} label='Records' size="tiny"/>
                </Grid.Row>
            </Grid>
            <DoradoSidebar/>
        </div>
    );

    return (
        <ViewWithSidebar mainView={<ReturnAuditChart records={records} loading={loading} asOfDate={getAsOfDate(searchProps)}/>} sidebarView={sidebar}/>
    )

}

const getAsOfDate = (searchProps) => {
    const filters = searchProps.filters || {};
    const entryDate = filters.entry_date || {term: null};
    return entryDate.term;
};


const requestData = ({dispatch, searchProps}) => {


    dispatch(fetchDoradoValuationReturnAudits({asOfDate: getAsOfDate(searchProps)}));
};