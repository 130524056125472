
export const isUndefinedOrNull = (v) => v === undefined || v === null;


export const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


export const getWindowHeightLessMenuBar = () => {
  return window.innerHeight - 90;
};

export const zip = (arrays) => {
  return arrays[0].map(function (_, i) {
    return arrays.map(function (array) {
      return array[i]
    })
  });
}

export const getBooleanFromStringOrBool = (value) => {
  if (typeof value === 'boolean') {
    return value;
  }

  if (value === 'false') {
    return false;
  }

  return true;
};

export const getDictFromArray = ({array, field}) => {
  return array.reduce((obj, item) => {
    obj[item[field]] = item;
    return obj
  }, {})
};

export const sumArray = array => array.reduce(function (a, b) {
  return a + b;
}, 0);

export const optionFromValue = value => {
    const text = value == null ? '' : value;

    return {value: value, text: text, key: text, id: text}
};

export const optionsFromValues = values => {
  return values.map(optionFromValue)
};

export const partition = (arr, fn) =>
    arr.reduce(
        (acc, val, i, arr) => {
          acc[fn(val, i, arr) ? 0 : 1].push(val);
          return acc;
        },
        [[], []]
    );