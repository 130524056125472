
export const getFilteredColumns = ({columns, columnGroups, columnGroupName}) => {

    const filteredGroup = columnGroups.find(g => g.name === columnGroupName);

    if (filteredGroup === undefined) {
        return columns
    }

    return filteredGroup.columns.map(colName => {

        return columns.find(c => c.key === colName)

    }).filter(c => c !== undefined)


};

