import React, {useEffect} from 'react'
import {Divider, Grid, Table} from "semantic-ui-react";
import {
    formatNoDecimalNoNull,
    formatPercentOneDecimalNoNull,
    formatPercentTwoDecimalNoNull
} from "../../../common/format";
import {sumArray} from "../../../common/common";
import {TradeType} from "../../../common/constants";


export default ({
                    buyLongOrders,
                    buyCoverOrders,
                    sellLongOrders,
                    sellShortOrders,
                    loading
                }) => {

    const buyLongTotal = sumArray(buyLongOrders.map(r => r.gross_value_before_transaction_cost_usd));
    const buyCoverTotal = sumArray(buyCoverOrders.map(r => r.gross_value_before_transaction_cost_usd));
    const sellLongTotal = sumArray(sellLongOrders.map(r => r.gross_value_before_transaction_cost_usd));
    const sellShortTotal = sumArray(sellShortOrders.map(r => r.gross_value_before_transaction_cost_usd));

    return (
        <React.Fragment>
            <Divider horizontal>
                Target Trade Orders
            </Divider>
            <Table textAlign={'right'} compact={'very'} basic={'very'} collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign={'left'}>
                            Type
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            $
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Buy Long
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(buyLongTotal)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Buy Cover
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(buyCoverTotal)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Sell Long
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(-sellLongTotal)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Sell Short
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(-sellShortTotal)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell/>
                        <Table.Cell/>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Net Buy
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(buyLongTotal + buyCoverTotal - sellLongTotal - sellShortTotal)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell textAlign={'left'}>
                            Gross Trade
                        </Table.Cell>
                        <Table.Cell>
                            {formatNoDecimalNoNull(buyLongTotal + buyCoverTotal + sellLongTotal + sellShortTotal)}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </React.Fragment>
    )


}