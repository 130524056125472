import {formatNoDecimalNoNullOrZero, formatPercentNoNull} from "../../../common/format";


export const exposureMetrics = [
    {
        name: 'Gross Exposure',
        color: 'black',
        baseName: 'securities_gross_market_value',
        multiplier: 1.0
    },
    {
        name: 'Net Exposure',
        color: '#4393c3',
        baseName: 'securities_net_market_value',
        multiplier: 1.0
    },
    {
        name: 'Gross Long',
        color: '#1b7837',
        dashStyle: 'ShortDash',
        baseName: 'securities_long_market_value',
        multiplier: 1.0
    },
    {
        name: 'Gross Short',
        color: '#d6604d',
        dashStyle: 'ShortDash',
        baseName: 'securities_short_market_value',
        multiplier: -1.0
    },

]

export const getExposureFieldName = ({baseName, unitOption}) => {

    if (unitOption === 'Dollars') {
        return baseName
    }

    return baseName + "_percent"
}




export const series1ChartSpecifications = [
    {
        name: 'Dollars',
        title: 'Exposure $',
        getY: (record) => record.net_asset_value,
        getYAxisLabel: () => 'Net Asset Value',
        yMin: 0,
        formatY: formatNoDecimalNoNullOrZero,
    },
    {
        name: 'Percent of NAV',
        title: 'Exposure % of NAV',
        getY: (record) => record.net_asset_value,
        getYAxisLabel: () => 'Net Asset Value',
        yMin: 0,
        formatY: formatPercentNoNull,
    },

];

export const series1ChartSpecificationByName = series1ChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});

export const unitOptions = ['Percent of NAV', 'Dollars'].map(v => {
    return {
        key: v,
        value: v,
        text: v
    }
});



export const fieldSpecs = {
    portfolio_id: {
        parse: (v) => v == null ? null : parseInt(v)
    },
    series1ChartName: {
        parse: (v) => v == null ? series1ChartSpecifications[0].name : v
    },
    unitOption: {
        parse: (v) => v == null ? unitOptions[0].value : v
    }
}