import React, {useEffect} from 'react';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import {Container, Header, Form, Input, Icon, Button, List, Grid} from 'semantic-ui-react'
import {
    fetchBacktestDoradoPortfolio, fetchDoradoBacktestBenchmarkPortfolioStatesByPortfolio,
    fetchDoradoPortfolioStatesWithReport, fetchDoradoPortfolioWithReport,
} from "../../../features/dorado/doradoPortfolio";
import ParametersTable from "./ParametersTable";
import ReportTable from "./ReportTable";
import {PortfolioType} from "../../../common/constants";
import Chart from './Chart'
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {fieldSpecs} from "./common";
import {series1ChartOptions} from "./common";
import {createAddValueToQuery} from "../../../common/query/add";
import {benchNullOption} from "../../../common/query/null";
import TaxReportTable from "./TaxReportTable";


export default () => {

    const dispatch = useDispatch();
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const dorado_portfolio_id = match.params.dorado_portfolio_id;

    useEffect(() => {

        dispatch(fetchDoradoPortfolioWithReport({
            filters: {
                'dorado_portfolio.id': dorado_portfolio_id
            }
        }));

        const stateFilters = {
            dorado_portfolio_id: dorado_portfolio_id,
            is_end_of_day: true,
        }

        dispatch(fetchDoradoPortfolioStatesWithReport({filters: stateFilters}));

        const backtestBenchmarkFilters = {
            is_end_of_day: true
        };

        dispatch(fetchDoradoBacktestBenchmarkPortfolioStatesByPortfolio({
            filters: backtestBenchmarkFilters
        }))

    }, [dorado_portfolio_id, dispatch]);


    const {
        portfolioWithReport,
        loadingPortfolioWithReport,

        doradoPortfolioStatesWithReport,
        loadingDoradoPortfolioStatesWithReport,
        doradoBacktestBenchmarkPortfolioStatesByPortfolio,
        loadingDoradoBacktestBenchmarkPortfolioStatesByPortfolio
    } = useSelector(state => {return state.doradoPortfolio}, shallowEqual);

    const backtestBenchmarkOptions = doradoBacktestBenchmarkPortfolioStatesByPortfolio.map(p => {
        return {
            key: p.name,
            value: p.name,
            text: p.name
        }
    });
    backtestBenchmarkOptions.unshift(benchNullOption);

    console.log(portfolioWithReport)

    let reportText = '';
    if (!loadingPortfolioWithReport) {

        if (portfolioWithReport.name === portfolioWithReport.type) {
            reportText = portfolioWithReport.name;
        } else {
            reportText = portfolioWithReport.name + " | " + portfolioWithReport.type
        }
    }

    return (
        <Container fluid>
            <Grid padded>
                <Grid.Column width={5}>
                    <Header as='h3'>Backtest Report
                        <Header.Subheader>
                            {reportText}
                        </Header.Subheader>
                    </Header>
                    <Form>
                        <Form.Select width={9}
                            fluid
                            inline
                            options={series1ChartOptions}
                            onChange={createAddValueToQuery({
                                history,
                                searchProps,
                                fieldName: 'series1ChartName'
                            })}
                            value={searchProps.series1ChartName}
                            label="Chart"
                        />
                        <Form.Select width={9}
                                     fluid
                                     inline
                                     loading={loadingDoradoBacktestBenchmarkPortfolioStatesByPortfolio}
                                     options={backtestBenchmarkOptions}
                                     onChange={createAddValueToQuery({
                                         history,
                                         searchProps,
                                         fieldName: 'backtestBenchmark'
                                     })}
                                     value={searchProps.backtestBenchmark}
                                     label="Benchmark"
                        />

                    </Form>

                    <ParametersTable doradoPortfolio={portfolioWithReport} loading={loadingPortfolioWithReport}/>
                    <ReportTable doradoPortfolio={portfolioWithReport} loading={loadingPortfolioWithReport}/>
                    <TaxReportTable doradoPortfolio={portfolioWithReport} loading={loadingPortfolioWithReport}/>
                    <br/>
                    <br/>
                </Grid.Column>
                <Grid.Column width={10}>
                    <Chart portfolioStates={doradoPortfolioStatesWithReport}
                           loading={loadingDoradoPortfolioStatesWithReport}
                           series1ChartName={searchProps.series1ChartName}

                           backtestBenchmarkName={searchProps.backtestBenchmark}
                           benchmarkPortfolioStates={getBacktestBenchmarkPortfolioStates({
                               backtestBenchmarkName: searchProps.backtestBenchmark,
                               doradoBacktestBenchmarkPortfolioStatesByPortfolio
                           })}
                    />

                </Grid.Column>
            </Grid>

        </Container>

    )
}



const getBacktestBenchmarkPortfolioStates = ({backtestBenchmarkName, doradoBacktestBenchmarkPortfolioStatesByPortfolio}) => {

    const matches = doradoBacktestBenchmarkPortfolioStatesByPortfolio.filter(p => {
        return p.name === backtestBenchmarkName
    })

    if (matches.length === 1) {
        return matches[0].portfolio_states
    }

    return []



}