import React from 'react';
import {Form} from 'semantic-ui-react'
import MultiSelectFilter from '../../components/filter/MultiSelectFilter'
import TermFilter from '../../components/filter/TermFilter'
import RangeFilter from '../../components/filter/RangeFilter'
import DeletableRangeFilter from '../../components/filter/DeletableRangeFilter'
import BooleanTermFilter from '../../components/filter/BooleanTermFilter'
import {
    createAddTermsFilterToQuery,
    createAddRangeFilterToQuery,
    createAddTrueOnlyCheckboxFilterToQuery,
    removeDeletableFilterFromQuery,
    createAddTermFilterToQuery
} from "../query/add";
import {parseBoolean} from '../../common/query/parse'


export const getFilterComponentsFromSpecs = ({filterSpecs, facetsByField, history, searchProps}) => {
    // filterSpec has 3 required fields:
    // field
    // label
    // type


    return filterSpecs.map(spec => {

        switch (spec.type) {

            case 'terms': {
                return <MultiSelectFilter label={spec.label}
                                          key={spec.field}
                                          allowNullSelection={spec.allowNullSelection}
                                          options={optionsFromTermsFacet({
                                              facet: facetsByField[spec.field],
                                              allowNull: false
                                          })}
                                          onChange={createAddTermsFilterToQuery({
                                              history: history,
                                              searchProps,
                                              fieldName: spec.field
                                          })}
                                          values={getFilteredTermsFromSearchPropsForField({
                                              searchProps,
                                              fieldName: spec.field
                                          })}
                />
            }

            case 'term': {
                return <TermFilter label={spec.label}
                                   key={spec.field}
                                   allowNullSelection={false}
                                   options={optionsFromTermsFacet({
                                       facet: facetsByField[spec.field],
                                       allowNull: false
                                   })}
                                   value={getFilteredTermFromSearchPropsForField({
                                       searchProps,
                                       fieldName: spec.field
                                   })}
                                   onChange={createAddTermFilterToQuery({
                                       history: history,
                                       searchProps,
                                       fieldName: spec.field
                                   })}
                />
            }

            case 'booleanTerms': {
                return <BooleanTermFilter label={spec.label}
                                          key={spec.field}
                                          allowNoSelection={spec.allowNoSelection}
                                          onChange={createAddTermFilterToQuery({
                                              history: history,
                                              searchProps,
                                              fieldName: spec.field
                                          })}
                                          value={getFilteredTermFromSearchPropsForField({
                                              searchProps,
                                              fieldName: spec.field
                                          })}
                />
            }

            case 'range': {

                return <RangeFilter label={spec.label}
                                    key={spec.field}
                                    onChangeMin={createAddRangeFilterToQuery({
                                        history,
                                        searchProps,
                                        fieldName: spec.field,
                                        isGreaterThanFilter: true
                                    })}
                                    minValue={getFilteredRangeValueFromSearchPropsForField({
                                        fieldName: spec.field,
                                        searchProps,
                                        isGreaterThanFilter: true
                                    })}
                                    onChangeMax={createAddRangeFilterToQuery({
                                        history,
                                        searchProps,
                                        fieldName: spec.field,
                                        isGreaterThanFilter: false
                                    })}
                                    maxValue={getFilteredRangeValueFromSearchPropsForField({
                                        fieldName: spec.field,
                                        searchProps,
                                        isGreaterThanFilter: false
                                    })}


                />
            }

            case 'deletableRange': {

                return <DeletableRangeFilter label={spec.label}
                                             key={spec.field}
                                             onChangeMin={createAddRangeFilterToQuery({
                                                 history,
                                                 searchProps,
                                                 fieldName: spec.field,
                                                 isGreaterThanFilter: true
                                             })}
                                             minValue={getFilteredRangeValueFromSearchPropsForField({
                                                 fieldName: spec.field,
                                                 searchProps,
                                                 isGreaterThanFilter: true
                                             })}
                                             onChangeMax={createAddRangeFilterToQuery({
                                                 history,
                                                 searchProps,
                                                 fieldName: spec.field,
                                                 isGreaterThanFilter: false
                                             })}
                                             maxValue={getFilteredRangeValueFromSearchPropsForField({
                                                 fieldName: spec.field,
                                                 searchProps,
                                                 isGreaterThanFilter: false
                                             })}
                                             onClickDelete={removeDeletableFilterFromQuery({
                                                 history,
                                                 searchProps,
                                                 fieldName: spec.field,
                                             })}
                />
            }

            case 'checkbox': {

                const checked = getFilteredTermFromSearchPropsForField({
                    searchProps,
                    fieldName: spec.field
                });

                return <Form.Checkbox key={spec.field}
                                      label={spec.label}
                                      checked={parseBoolean(checked)}
                                      onChange={createAddTrueOnlyCheckboxFilterToQuery({
                                          history,
                                          searchProps,
                                          fieldName: spec.field
                                      })}
                />
            }

            default: {
                return <div key={spec.field}/>
            }

        }


    });

};

export const optionsFromTermsFacet = ({facet, allowNull}) => {
    // populate options of a select box from a set of terms


    // if no facet, return empty options
    if (facet == null) {
        return []
    }

    // by default allow null values
    if (allowNull == null) {
        allowNull = true;
    }

    let terms = facet.terms;
    const hasNullValue = terms.includes(null);
    terms = terms.filter((term) => {
        return term != null
    });

    let options = terms.map(term => {
        return {
            key: term,
            value: term,
            text: term
        }
    });

    if (hasNullValue && allowNull) {
        options.unshift({
            key: 'null',
            value: null,
            text: '<null>'
        });

    }

    return options

};


export const getFilteredTermsFromSearchPropsForField = ({searchProps, fieldName}) => {

    const filters = searchProps.filters || {};
    const filter = filters[fieldName] || {terms: []};
    return filter.terms

};

export const getFilteredTermFromSearchPropsForField = ({searchProps, fieldName}) => {

    const filters = searchProps.filters || {};
    const filter = filters[fieldName] || [];
    return filter.term

};

export const getFilteredRangeValueFromSearchPropsForField = ({searchProps, fieldName, isGreaterThanFilter}) => {

    const operator = isGreaterThanFilter ? '$gte' : '$lte';

    const filters = searchProps.filters || {};
    const filter = filters[fieldName] || {};

    const value = filter[operator];
    if (value == null) {
        return ""
    }
    return value

};


export const getTextFilterFromSearchPropsForField = ({searchProps, fieldName}) => {
    const filters = searchProps.filters || {};
    const filter = filters[fieldName] || [];
    return filter.value
};
