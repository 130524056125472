const defaultState = {
    companies: [],
    companiesWithLinkedInEmployees: [],
    companySummaries: [],
    publicCompanies: [],

    loading: false,
    loadingCompanies: true,
    loadingCompaniesWithLinkedInEmployees: true,
    errors: {},

    // companyFinancials
    company: {},
    loadingCompany: true,

    // companyFinancials search bar
    searchResults: [],
    loadingSearch: false,
    searchText: '',

    // CompanyNote
    companyNote: {},
    loadingCompanyNote: true,
    saveNoteChangesPending: false,

    // target price
    targetPriceRecords: [],
    loadingTargetPriceRecords: true,
    newTargetPriceRecord: {},

    // securities
    shareClassSecurities: [],
    loadingShareClassSecurities: true,

    // equity events
    equityEvents: [],
    loadingEquityEvents: true,

    // pce option pool tiers
    pceOptionPoolTiers: [],
    loadingPceOptionPoolTiers: true,

    // dorado
    loadingDoradoForecastMetadatas: true,
    doradoForecastMetadatas: [],

    loadingDoradoFinancialPeriodRecords: true,
    doradoFinancialPeriodRecords: [],

    loadingDoradoAuditRecords: true,
    doradoAuditRecords: [],

    // convertible debt page
    convertibleDebtRecords: [],
    loadingConvertibleDebtRecords: true,


    acquisitionEvents: [],
    loadingAcquisitionEvents: true,

};

export default (state = defaultState, action = {}) => {
    switch (action.type) {

        case "FETCH_COMPANIES_FULFILLED": {
            return {
                ...state,
                companies: action.payload.data,
                loadingCompanies: false
            }
        }

        case "FETCH_COMPANIES_WITH_LINKEDIN_EMPLOYEES_FULFILLED": {
            return {
                ...state,
                companiesWithLinkedInEmployees: action.payload,
                loadingCompaniesWithLinkedInEmployees: false
            }
        }

        case "FETCH_PUBLIC_COMPANIES_FULFILLED": {
            return {
                ...state,
                publicCompanies: action.payload.data
            }
        }

        case "FETCH_COMPANY_SUMMARIES_FULFILLED": {

            return {
                ...state,
                companySummaries: action.payload
            }
        }

        case 'NEW_COMPANY': {
            return {
                ...state,
                company: {}
            }
        }

        case 'SAVE_COMPANY_PENDING': {
            return {
                ...state,
                loading: true
            }
        }

        case 'SAVE_COMPANY_FULFILLED': {
            // should sort but when we redirect to the page it results in a new query for fresh records
            return {
                ...state,
                companies: [...state.companies, action.payload],
                errors: {},
                loading: false
            }
        }

        case 'SAVE_COMPANY_REJECTED': {
            const data = action.payload.response.data;
            // convert feathers error formatting to match client-side error formatting
            const {company_name, ciq_id} = data.errors;
            const errors = {global: data.message, company_name, ciq_id};
            return {
                ...state,
                errors: errors,
                loading: false
            }
        }

        case 'DELETE_COMPANY_FULFILLED': {
            const id = action.payload.id;

            return {
                ...state,
                companies: state.companies.filter(item => item.id !== id),
                companySummaries: state.companySummaries.filter(item => item.id !== id)
            }
        }

        case 'FETCH_COMPANY_PENDING': {
            return {
                ...state,
                loadingCompany: true
            }
        }

        case 'FETCH_COMPANY_FULFILLED': {
            return {
                ...state,
                company: action.payload.data,
                loadingCompany: false
            }

        }

        // case 'SEARCH_FOR_COMPANY_PENDING': {
        //
        //     return {
        //         ...state,
        //         loadingSearch: true,
        //     }
        // }

        case 'CHANGE_COMPANY_SEARCH': {
            return {
                ...state,
                searchText: action.payload
            }
        }


        // case 'SEARCH_FOR_COMPANY_FULFILLED': {
        //     return {
        //         ...state,
        //         searchResults: action.payload.data,
        //         loadingSearch: false
        //     }
        // }

        // case 'CLEAR_COMPANY_SEARCH_RESULTS': {
        //     return {
        //         ...state,
        //         searchResults: [],
        //         loadingSearch: false,
        //         searchText: ''
        //     }
        // }

        case 'FETCH_COMPANY_NOTE_PENDING': {
            return {
                ...state,
                loadingCompanyNote: true
            }
        }

        case 'FETCH_COMPANY_NOTE_FULFILLED': {
            return {
                ...state,
                companyNote: action.payload.data || {},
                loadingCompanyNote: false
            }
        }

        case 'MODIFY_COMPANY_NOTE_FIELD': {

            let newNote = {...state.companyNote};
            newNote[action.payload.fieldName] = action.payload.text;
            return {
                ...state,
                companyNote: newNote
            }
        }


        case 'PATCH_COMPANY_NOTE_PENDING': {
            return {
                ...state,
                saveNoteChangesPending: true
            }

        }

        case 'PATCH_COMPANY_NOTE_FULFILLED': {
            return {
                ...state,
                companyNote: action.payload.data,
                saveNoteChangesPending: false
            }

        }

        case 'FETCH_TARGET_PRICE_RECORDS_FOR_COMPANY_PENDING': {
            return {
                ...state,
                loadingTargetPriceRecords: true,
                newTargetPriceRecord: {}
            }
        }

        case 'FETCH_TARGET_PRICE_RECORDS_FOR_COMPANY_FULFILLED': {

            const targetPriceRecords = action.payload.data;

            let newTargetPriceRecord = {};

            if (targetPriceRecords.length > 0) {
                const mostRecentTargetPriceRecord = targetPriceRecords[0];
                newTargetPriceRecord = {...mostRecentTargetPriceRecord}
            }

            return {
                ...state,
                targetPriceRecords: action.payload.data,
                loadingTargetPriceRecords: false,
                newTargetPriceRecord: newTargetPriceRecord
            }
        }

        case 'EDIT_TARGET_PRICE': {

            return {
                ...state,
                newTargetPriceRecord: {
                    ...state.newTargetPriceRecord,
                    ...action.payload
                }
            }
        }

        case 'NEW_TARGET_PRICE_PENDING': {
            return {
                ...state
            }
        }

        case 'NEW_TARGET_PRICE_FULFILLED': {
            return {
                ...state,
                targetPriceRecords: [action.payload.data, ...state.targetPriceRecords],
                isEditingTargetPrice: false,
                editedTargetPriceRecord: {},
            }
        }

        case "PATCH_COMPANY_FULFILLED": {
            return {
                ...state,
                company: action.payload.data
            }
        }

        case "MODIFY_COMPANY_FIELD": {

            const newCompany = {...state.company};
            newCompany[action.payload.fieldName] = action.payload.value;

            console.log(action.payload.fieldName)

            console.log(newCompany)

            return {
                ...state,
                company: newCompany
            }
        }

        case 'FETCH_SHARE_CLASS_SECURITIES_FOR_COMPANY_PENDING': {
            return {
                ...state,
                loadingShareClassSecurities: true
            }
        }

        case 'FETCH_SHARE_CLASS_SECURITIES_FOR_COMPANY_FULFILLED': {
            return {
                ...state,
                shareClassSecurities: action.payload.data,
                loadingShareClassSecurities: false
            }
        }

        case "PATCH_SHARE_CLASS_SECURITY_PENDING": {
            return {
                ...state,
                loadingShareClassSecurities: true
            }
        }

        case "PATCH_SHARE_CLASS_SECURITY_FULFILLED": {

            const patchedSecurity = action.payload.data;

            return {
                ...state,
                loadingShareClassSecurities: false,
                shareClassSecurities: state.shareClassSecurities.map(s => {
                    if (s.id === patchedSecurity.id) {
                        return patchedSecurity
                    } else {
                        return s
                    }
                })
            }
        }

        case 'FETCH_EQUITY_OFFEERING_EVENTS_FOR_COMPANY_PENDING': {
            return {
                ...state,
                loadingEquityEvents: true
            }
        }

        case 'FETCH_EQUITY_OFFEERING_EVENTS_FOR_COMPANY_FULFILLED': {
            return {
                ...state,
                loadingEquityEvents: false,
                equityEvents: action.payload.data
            }
        }

        case 'FETCH_MOST_RECENT_PCE_OPTION_POOL_TIERS_FOR_COMPANY_PENDING': {
            return {
                ...state,
                loadingPceOptionPoolTiers: true
            }
        }

        case 'FETCH_MOST_RECENT_PCE_OPTION_POOL_TIERS_FOR_COMPANY_FULFILLED': {
            return {
                ...state,
                loadingPceOptionPoolTiers: false,
                pceOptionPoolTiers: action.payload.data
            }
        }

        case 'FETCH_DORADO_FINANCIAL_PERIOD_RECORDS_FOR_METADATA_PENDING': {
            return {
                ...state,
                loadingDoradoFinancialPeriodRecords: true
            }
        }

        case 'FETCH_DORADO_FINANCIAL_PERIOD_RECORDS_FOR_METADATA_FULFILLED': {
            return {
                ...state,
                loadingDoradoFinancialPeriodRecords: false,
                doradoFinancialPeriodRecords: action.payload.data
            }
        }

        case 'FETCH_DORADO_AUDIT_RECORDS_FOR_COMPANY_PENDING': {
            return {
                ...state,
                loadingDoradoAuditRecords: true
            }
        }

        case 'FETCH_DORADO_AUDIT_RECORDS_FOR_COMPANY_FULFILLED': {
            return {
                ...state,
                loadingDoradoAuditRecords: false,
                doradoAuditRecords: action.payload.data
            }
        }

        case 'FETCH_CONVERTIBLE_DEBT_FOR_COMPANY_PENDING': {
            return {
                ...state,
                loadingConvertibleDebtRecords: true
            }
        }

        case 'FETCH_CONVERTIBLE_DEBT_FOR_COMPANY_FULFILLED': {
            return {
                ...state,
                convertibleDebtRecords: action.payload.data,
                loadingConvertibleDebtRecords: false
            }
        }

        case 'FETCH_ACQUISITIONS_FOR_COMPANY_PENDING': {
            return {
                ...state,
                loadingAcquisitionEvents: true
            }
        }

        case 'FETCH_ACQUISITIONS_FOR_COMPANY_FULFILLED': {
            return {
                ...state,
                loadingAcquisitionEvents: false,
                acquisitionEvents: action.payload.data
            }
        }

        case 'FETCH_ALL_DORADO_FINANCIAL_PERIOD_RECORDS_FOR_COMPANY_PENDING': {
            return {
                ...state,
                loadingDoradoFinancialPeriodRecords: true
            }
        }

        case 'FETCH_ALL_DORADO_FINANCIAL_PERIOD_RECORDS_FOR_COMPANY_FULFILLED': {
            return {
                ...state,
                loadingDoradoFinancialPeriodRecords: false,
                doradoFinancialPeriodRecords: action.payload.data
            }
        }



        default:
            return state;
    }
}
