import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import {chartSpecByName} from "./chartSpecs";

const ReactHighcharts = require('react-highcharts');



class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const doradoValuationRecords = this.props.doradoValuationRecords;
        const loading = this.props.loading;

        const chartSpec = chartSpecByName[this.props.chartName];

        const auditSeries = {
            name: chartSpec.name,
            data: doradoValuationRecords.map(r => {
                return {
                    x: chartSpec.getX(r),
                    y: chartSpec.getY(r),
                    label: r.company.ticker
                }
            }).filter(r => r.x != null)
        };

        const config = {
            chart: {
                type: 'scatter',
                zoomType: 'xy'
            },
            title: {
                text: chartSpec.name
            },
            xAxis: {
                title: {
                    text: chartSpec.getXAxisLabel()
                },
                labels: {
                    formatter: function () {
                        return chartSpec.formatX(this.value);
                    }
                }
            },
            yAxis: {
                title: {
                    text: chartSpec.getYAxisLabel()
                },
                labels: {
                    formatter: function () {
                        return chartSpec.formatY(this.value);
                    }
                },
                min: -1
            },
            plotOptions: {
                series: {
                    animation: false,
                    stickyTracking: false,
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        style: {
                            fontSize: "9px",
                            fontWeight: 'normal',
                            textOutline: '1px',
                            color: 'rgba(0, 0, 0, .5)'
                        },
                        format: '{point.label}'
                    },
                },
                scatter: {

                    marker: {
                        symbol: 'circle',
                        radius: 4,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },

                }
            },

            series: [auditSeries],

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent