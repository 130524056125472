import React from 'react';
import {divideValueBy1000000} from "./reactDataGrid/valuationColumns";
import {
    OneDecimalNoNullFormatter,
    TwoDecimalNoNullFormatter,
    NoDecimalNoNullOrZeroFormatter,
    WeightFormatter,
    RelativeWeightFormatter,
    PercentGainsFormatter,
    PercentNoDecimalNoNullFormatter,
    getCompanyLink,
    PercentFourDecimalNoNullFormatter,
    ThreeDecimalNoNullFormatter,
    PercentOneDecimalNoNullFormatter,
    UtcToLocalFullDateTimeFormatter,
    PricePercentChangeOneDecimalFormatter,
    SharePriceFormatter,
    PercentTwoDecimalNoNullFormatter, MultipleOneDecimalFormatter
} from "./reactDataGrid/formatters";
import {formatSharePriceFixedDigits} from "./format";
import {getWindowHeightLessMenuBar} from "./common";


class HeaderFormatter extends React.Component {
    render() {
        if (this.props.value.isLabel) {
            return <div style={{'fontWeight': 'bold'}}>
                {this.props.value.label}
            </div>
        }

        return getCompanyLink({companyId: this.props.value.company_id, companyName: this.props.value.company_name})

    }
}

export class CurrencySharePriceFormatter extends React.Component {
    render() {

        if (this.props.value.price_per_share == null) {
            return <div/>
        }

        let prefix = '';
        if (this.props.value.trading_currency !== 'USD') {
            prefix = this.props.value.trading_currency + ' ';
        }

        return <div
            className="align-right">{prefix + formatSharePriceFixedDigits(this.props.value.price_per_share)}</div>
    }
}


export class TradeTypeFormatter extends React.Component {
    render() {

        const tradeType = this.props.value.trade_type;
        const isShortSaleOrCover = this.props.value.is_short_sale_or_cover;

        const color = tradeType === 'Buy' ? 'blue' : 'red';
        const style = {color: color};


        if (isShortSaleOrCover) {
            style.fontWeight = 'bold';
        }

        let tradeText = tradeType;

        if (isShortSaleOrCover) {
            if (tradeType === 'Buy') {
                tradeText = 'Cover Short';
            } else {
                tradeText = 'Short Sell';
            }
        }

        return <div style={style}>{tradeText}</div>

    }
}



export const headerLabelSpec = {
    key: 'header_label',
    name: 'Name',
    width: 250,
    frozen: true,
    sortable: true,
    formatter: HeaderFormatter,
    _getValueFromRecord: (r) => {

        if (r.isLabel) {
            return {
                isLabel: true,
                label: r.label
            }
        }

        return {
            company_name: r.company ? r.company.short_name : r.exchange_traded_fund.short_name,
            company_id: r.company_id,
        }
    }
};
