export const ySpecs = [
    {
        name: 'Share Price',
        getValue: r => r.primary_share_price
    },
    {
        name: 'EV/LFQA Revenue',
        getValue: r => r.ev_to_lfqa_revenue
    },
    {
        name: 'EV/LFQA Recurring',
        getValue: r => r.ev_to_lfqa_recurring_revenue
    },
    {
        name: 'EV/LFQA Max Pretax FCF',
        getValue: r => r.ev_to_lfqa_maximized_pretax_fcf
    },
    {
        name: 'LFQA Max Pretax FCF Margin',
        getValue: r => r.lfqa_maximized_pretax_fcf_margin
    },
    {
        name: 'Diluted EV',
        getValue: r => r.diluted_enterprise_value
    },
    {
        name: 'Diluted Market Value',
        getValue: r => r.diluted_market_value
    },
    {
        name: 'LFQA Maximized Pretax FCF',
        getValue: r => r.lfqa_maximized_pretax_fcf
    },
    {
        name: 'LFQA Recurring Revenue',
        getValue: r => r.lfqa_recurring_revenue
    },
    {
        name: 'LFQA Revenue',
        getValue: r => r.lfqa_revenue
    },
    {
        name: 'Basic Shares',
        getValue: r => r.basic_shares
    },
    {
        name: 'Diluted Shares',
        getValue: r => r.diluted_shares
    },
    {
        name: 'Unvested RSUs',
        getValue: r => r.unvested_rsus
    },
    {
        name: 'Cash',
        getValue: r => r.cash_and_short_term_investments
    },
    {
        name: 'Debt',
        getValue: r => r.total_debt
    },





];

export const ySpecsByName = ySpecs.reduce((obj, item) => {
    obj[item.name] = item;
    return obj
}, {});


export const fieldSpecs = {
    yAxis1: {
        parse: (v) => v == null ? 'Share Price' : v
    },
    yAxis2: {
        parse: (v) => v == null ? null : v
    },

    rangeSelectorIndex: {
        parse: (v) => v == null ? 5 : v
    }

};