import {commonColumnsByName, companyColumnFromObject} from "../../common/reactDataGrid/columns";
import {
    BoolToYesFormatter,
    BusinessQualityFormatter,
    CompanyLinkFormatter,
    DateFormatter, ManagementQualityFormatter,
    MultipleOneDecimalFormatter,
    NoDecimalNoNullOrZeroFormatter,
    PercentFromPriceFormatter,
    PercentNoDecimalNoNullFormatter,
    SharePriceFormatter,
    StringFormatter,
    UtcDateFormatter
} from "../../common/reactDataGrid/formatters";
import React from "react";
import {divideValueBy1000, divideValueBy1000Old} from "../../common/reactDataGrid/valuationColumns";

export const allTableColumns = [

    companyColumnFromObject,
    {
        key: 'last_price_per_share_trading',
        name: 'Price $/share',
        sortable: true,
        formatter: <SharePriceFormatter/>,
        width: 90
    },

    {
        key: 'buy_price',
        name: 'Buy Price',
        sortable: true,
        formatter: <SharePriceFormatter/>,
        width: 90,
    },
    {
        key: 'percent_from_buy_price',
        name: '% from Buy Price',
        sortable: true,
        formatter: PercentFromPriceFormatter,
        width: 70,
        _group: 'target_price'
    },
    {
        key: 'target_price_last_modified',
        name: 'Last Updated',
        sortable: true,
        formatter: DateFormatter,
        width: 90,
        _group: 'target_price'
    },
    {
        key: 'lbo_track',
        name: 'LBO Track',
        sortable: true,
        formatter: BoolToYesFormatter,
        width: 90,
        _getValueFromRecord: (r) => {
            return r.company.lbo_track

        }
    },


    {
        key: 'market_value_usd',
        name: 'Market Value $m',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000(r.market_value_usd)
        }
    },
    {
        key: 'enterprise_value_usd',
        name: 'Enterprise Value $m',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return divideValueBy1000(r.enterprise_value_usd)
        }
    },
    {
        key: 'region_name',
        name: 'Region',
        sortable: true,
        formatter: StringFormatter,
        width: 150,
        _getValueFromRecord: (r) => {
            return r.company.region_name
        }
    },

    {
        key: 'lfq_revenue_growth',
        name: 'LFQ Revenue Growth',
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'ltm_revenue_growth',
        name: 'LTM Revenue Growth',
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80,
    },
    {
        key: 'ev_to_lfqa_revenue',
        name: 'EV/ LFQA Revenue',
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80
    },
    {
        key: 'revenue_ltm_usd',
        name: 'Last Revenue',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 80,
        _getValueFromRecord: (r) => divideValueBy1000(r.revenue_ltm_usd)
    },
    {
        key: 'business_quality',
        name: 'Business Quality',
        sortable: true,
        formatter: <BusinessQualityFormatter labelSize='tiny'/>,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.company.business_quality
        }
    },
    {
        key: 'management_score',
        name: 'Mgmt. Quality',
        sortable: true,
        formatter: <ManagementQualityFormatter labelSize='tiny'/>,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.company.management_score
        }
    },


    {
        key: 'normalized_gross_retention',
        name: 'Gross Retention',
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.company.normalized_gross_retention
        }
    },
    {
        key: 'normalized_net_retention',
        name: 'Net Retention',
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80,
        _getValueFromRecord: (r) => {
            return r.company.normalized_net_retention
        }
    },
    {
        key: 'country_name',
        name: 'Country',
        sortable: true,
        formatter: StringFormatter,
        width: 150,
        _getValueFromRecord: (r) => {
            return r.company.country_name
        }
    },




];