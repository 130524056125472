import React from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {parseSearchProps} from "../../../../common/query/parse";
import {getRdgRowsFromRecords, getTableClassName} from "../../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {getWindowHeightLessMenuBar} from "../../../../common/common";
import {BoolToNoFlagFormatter, UtcDateFormatter, PercentOneDecimalNoNullFormatter} from "../../../../common/reactDataGrid/formatters";
import {fieldSpecs} from "./common";
import numeral from "numeral";



const columns = [
    {
        key: 'as_of_date',
        name: 'As of Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'event_description',
        name: 'Event',
        width: 150
    },
    {
        key: 'ntm_1',
        name: 'NTM Year 1',
        width: 100,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'ntm_2',
        name: 'NTM Year 2',
        width: 100,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'ntm_3',
        name: 'NTM Year 3',
        width: 100,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'ntm_4',
        name: 'NTM Year 4',
        width: 100,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'ntm_5',
        name: 'NTM Year 5',
        width: 100,
        formatter: PercentOneDecimalNoNullFormatter
    },
];

const ntmYears = [1, 2, 3, 4, 5];


export default () => {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        company,
        loadingCompany,
        doradoForecastMetadatas,
        loadingDoradoForecastMetadatas,
        doradoAuditRecords,
        loadingDoradoAuditRecords} = useSelector(state => state.companyStore, shallowEqual);

    const loading = loadingDoradoForecastMetadatas || loadingDoradoAuditRecords || loadingCompany;

    let rows = [];

    if (!loading) {
        rows = doradoForecastMetadatas.map(metadata => {

            const auditRecords = doradoAuditRecords.filter(r => r.dorado_forecast_metadata_id === metadata.id);
            const auditRecordByYear = auditRecords.reduce((obj, record) => {
                obj[record.ntm_year] = record;
                return obj
            }, {});

            const results = {};
            for (let ntmYear of ntmYears) {
                const auditRecord = auditRecordByYear[ntmYear] || {};

                results["ntm_" + String(ntmYear)] = auditRecord[searchProps.auditField] || null;
            }

            return {
                as_of_date: metadata.as_of_date,
                event_description: metadata.event_description,
                ...results
            }
        })


    }

    rows = getRdgRowsFromRecords({records: rows, columns});

    return (

        <div className={getTableClassName(loading)}>

            <ReactDataGrid
                columns={columns}
                rowGetter={index => rows[index]}
                rowsCount={rows.length}
                headerRowHeight={100}
                rowHeight={22}
                minHeight={getWindowHeightLessMenuBar()}
            />

        </div>

    );


}