import {sortFunctional} from "../../../common/tableHelpers";
import moment from "moment";
import {formatFullDate, formatNoDecimalNoNull} from "../../../common/format";
import {getMonthCategory, getXValue} from "./common";


const compareAbsoluteValues = (a, b) => {
    return Math.abs(b.total_gain) - Math.abs(a.total_gain)
}


const colors = [
    '#a6cee3',
    '#1f78b4',
    '#b2df8a',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
    '#8dd3c7',
    '#bebada',
    '#fb8072',
    '#80b1d3',
    '#fdb462',
    '#b3de69',
    '#fccde5',
    '#d9d9d9',
    '#bc80bd',
    '#ccebc5',
    '#ffed6f',

    '#003c30',
    '#543005',
    '#dfc27d'
]


const getShortName = (position) => {

    if (position.company_id != null) {
        return position.company.short_name
    }

    return position.exchange_traded_fund.short_name
}


const getSelectedSecurityInvestmentPeriodReports = ({securityInvestmentPeriodReports, longShortType}) => {

    if (longShortType === 'Long') {
        return securityInvestmentPeriodReports.filter(r => r.is_long)
    }

    if (longShortType === 'Short') {
        return securityInvestmentPeriodReports.filter(r => !r.is_long)
    }

    return securityInvestmentPeriodReports.slice()

}


export const getTopContributorsSeries = ({
                                             periodReports,
                                             topPositionCount,
                                             getYValue,
                                             formatY,
                                             longShortType
                                         }) => {

    const topContributorsByMonth = periodReports.map(report => {

        const securityInvestmentPeriodReports = getSelectedSecurityInvestmentPeriodReports({
            securityInvestmentPeriodReports: report.security_investment_period_reports,
            longShortType
        });

        const topContributors = securityInvestmentPeriodReports.sort(compareAbsoluteValues).slice(0, topPositionCount);

        return {
            end_date: report.end_date,
            topContributors: topContributors
        }
    });

    const allCompanies = Array.from(new Set(topContributorsByMonth.reduce((list, monthlyData) => {
        return list.concat(monthlyData.topContributors.map(c => getShortName(c)))
    }, []))).sort();


    const companySeries = allCompanies.map((companyName, i) => {

        return {
            name: companyName,
            stacking: 'normal',
            color: colors[i],
            opacity: .8,
            data: topContributorsByMonth.map(monthlyData => {

                let contribution = 0;

                const match = monthlyData.topContributors.filter(r => getShortName(r) === companyName);

                if (match.length === 1) {
                    contribution = getYValue(match[0])
                }

                return {
                    x: getXValue(monthlyData.end_date),
                    y: contribution
                }
            }),
            // pointPadding: -.4,
            maxPointWidth: 80,
            tooltip: {
                useHTML: true,
                shared: false,
                headerFormat: '',
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td>Company: </td><td>' + this.series.name + '</td></tr>' +
                        '<tr><td>Contribution: </td><td>' + formatY(this.y) + '</td></tr>' +
                        '</table>'
                },
            },
            events: {
                legendItemClick: function (event) {
                    if (!this.visible)
                        return false;

                    let seriesIndex = this.index;
                    let series = this.chart.series;

                    for (let i = 0; i < series.length; i++) {
                        if (series[i].index !== seriesIndex && series[i].index !== 0) {
                            series[i].visible ?
                                series[i].hide() :
                                series[i].show();
                        }
                    }
                    return false;

                }
            }

        }

    })

    return companySeries



}