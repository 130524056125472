import React from 'react'
import {Button, Container, Grid, Icon} from 'semantic-ui-react'
import {createSetFieldValueOnQuery} from '../common/query/add';
import {parseBoolean, parseSearchProps} from "../common/query/parse";
import {useLocation, useHistory} from "react-router-dom"
import {useReloadOnChange} from "../common/hooks";


const sidebarClosedWidth = 1;
// const sidebarOpenWidth = 4;


const sidebarFieldSpecs = {
    sidebarOpen: {
        parse: (value) => {

            if (value == null) {
                return true
            }

            return parseBoolean(value);
        }
    }

};


export default ({mainView, sidebarView, sidebarOpenWidth}) => {

    sidebarOpenWidth = sidebarOpenWidth || 4;

    const location = useLocation();
    const history = useHistory();

    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs: sidebarFieldSpecs});

    useReloadOnChange({
        fieldSpecs: sidebarFieldSpecs,
        queryMatchFields: ['sidebarOpen'],
        requestData: () => {
            window.dispatchEvent(new Event('resize'));
        }
    });

    const sidebarOpen = searchProps.sidebarOpen;

    const sidebarWidth = sidebarOpen ? sidebarOpenWidth : sidebarClosedWidth;
    const mainWidth = 16 - sidebarWidth;

    const setSidebarValue = createSetFieldValueOnQuery({
        history,
        searchProps,
        fieldName: 'sidebarOpen',
        value: !sidebarOpen
    });

    const button = (
        <Button icon basic color='blue' floated='right' onClick={(e, d) => {
            setSidebarValue();
            window.dispatchEvent(new Event('resize'));
        }}>
            <Icon name={sidebarOpen ? 'hide' : 'unhide'}/>
        </Button>
    );

    const railStyle = {
        height: '36px',
        position: 'absolute',
        top: '53px',
        right: 0,
        backgroundColor: 'white',

    };

    const rail = (
        <div style={railStyle}>
            {button}
        </div>
    );

    return (
        <Container fluid>
            <Grid padded>
                <Grid.Column width={mainWidth} style={{paddingTop: 0}}>
                    {mainView}
                </Grid.Column>
                <Grid.Column width={sidebarWidth} style={{paddingTop: 0}}>
                    <Grid.Row className={sidebarOpen ? null : 'hidden'}>
                        {sidebarView}
                    </Grid.Row>
                </Grid.Column>
            </Grid>
            {rail}
        </Container>
    )
}