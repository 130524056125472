import React from 'react';
import {useSelector} from 'react-redux';
import {Grid, Statistic} from 'semantic-ui-react'
import {fetchAkkrCompanyRecords} from '../../../actions/akkr/akkrActions';
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import AkkrCompaniesTable from './AkkrCompaniesTable'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";


export default () => {

    const records = useSelector(state => state.akkrCompaniesStore.records);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={records.length} label='Companies' size="tiny"/>
                </Grid.Row>
            </Grid>
        </div>
    );


    return (
        <ViewWithSidebar mainView={<AkkrCompaniesTable/>} sidebarView={sidebar}/>
    )

};

const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    fetchAkkrCompanyRecords({dispatch, sort, filters});

};
