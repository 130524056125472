import {
    createHeaderRow,
    createSectionHeaderRow,
    emptyRow,
    headerRowHeight, rowHeaderWidth,
    rowHeight
} from "../../../components/companyFinancials/common";
import {getTableClassName} from "../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {getWindowHeightLessMenuBar} from "../../../common/common";
import React from "react";
import {ModelRowRenderer} from "../../../components/rdg/rdgComponents";
import {createRow, HeaderContentRenderer, LeftAlignHeaderRenderer} from "../../../components/rdg/helpers";
import {
    formatFullDate,
    formatPercentOneDecimalNoNull,
    formatString,
    formatMultipleTwoDecimals,
    formatSharePrice,
    formatNoDecimalNoNull,
    formatBooleanYesNo,
    formatPercentNoNull,
    formatNoDecimal,
    formatOneDecimal,
    formatOneDecimalNoNull, formatPercentTwoDecimalNoNull, formatTwoDecimalNoNull
} from "../../../common/format";
import moment from "moment";
import {PortfolioType} from "../../../common/constants";
import {divideValueBy1000} from "../../../common/reactDataGrid/valuationColumns";
import {Icon} from "semantic-ui-react";



const portfolioCurrencies = [
    'USD',
    'CAD',
    'AUD',
    'NZD',
    'EUR',
    'NOK',
];


export default ({records, loading, units, portfolioType}) => {

    const valueInUnits = units === 'Thousands' ? v => divideValueBy1000(v) : v => v;

    const cellWidth = units === 'Thousands' ? 85 : 110;

    const dateRow = [
        {
            key: 'rowHeader',
            name: 'End Date',
            width: 300,
            frozen: true,
            headerRenderer: <LeftAlignHeaderRenderer/>,
            _isForecast: false
        }
    ].concat(
        records.map(record => {

            return {
                key: record.id,
                name: formatFullDate(record.standard_date),
                width: cellWidth,
                headerRenderer: <HeaderContentRenderer/>,

                // color blue for annuals
                _isForecast: moment(record.standard_date).month() === 11
            }

        })
    );

    const columnHeaderFieldName = 'id';


    const piperCheckRows = portfolioType !== PortfolioType.special_opps_v ? [] : [

        createSectionHeaderRow({headerText: 'Piper Reconciliation'}),
        emptyRow(),
        createRow({
            headerText: 'Piper Cash Match after Adj',
            records,
            getCellValue: record => {
                return record.piper_cash_reconciles_with_point_sur
            },
            formatValue: value => {

                if (value === true) {
                    return <Icon name={'check'} color={'green'}/>
                }
                if (value === false) {
                    return <Icon name={'x'} color={'red'}/>
                }
                return <div/>

            },
            columnHeaderFieldName
        }),
        emptyRow(),
    ];


    const gsCheckRows = portfolioType !== PortfolioType.point_sur ? [] : [

        createSectionHeaderRow({headerText: 'GS PB Reconciliation'}),
        emptyRow(),

        createRow({
            headerText: 'GS Cash Match after Adj',
            records,
            getCellValue: record => {
                return record.gs_cash_by_currency_reconciles_with_point_sur
            },
            formatValue: value => {

                if (value === true) {
                    return <Icon name={'check'} color={'green'}/>
                }
                if (value === false) {
                    return <Icon name={'x'} color={'red'}/>
                }
                return <div/>

            },
            columnHeaderFieldName
        }),
        emptyRow(),

        createHeaderRow({headerText: 'Cash by Currency'}),
        ...portfolioCurrencies.map(currency => {
            return createRow({
                headerText: currency,
                records,
                getCellValue: record => {

                    return valueInUnits(record['cash_' + currency.toLowerCase()])
                },
                formatValue: formatTwoDecimalNoNull,
                columnHeaderFieldName
            })
        }),
        emptyRow(),

        createHeaderRow({headerText: 'Money Market Bridge'}),
        createRow({
            headerText: 'Beginning',
            records,
            getCellValue: record => {

                return valueInUnits(record.monthly_beginning_net_money_market_balance)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Inflows',
            records,
            getCellValue: record => {

                return valueInUnits(-record.monthly_cash_moved_into_money_market)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: 1
        }),
        createRow({
            headerText: 'Outflows',
            records,
            getCellValue: record => {

                return valueInUnits(-record.monthly_cash_moved_out_of_money_market)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: 1
        }),
        createRow({
            headerText: 'Money Market Interest Income',
            records,
            getCellValue: record => {

                return valueInUnits(-record.monthly_money_market_interest_income)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: 1
        }),
        createRow({
            headerText: 'Ending',
            records,
            getCellValue: record => {

                return valueInUnits(record.net_money_market_balance)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            underline: true
        }),

        emptyRow(),


        createHeaderRow({headerText: 'Check vs GS Statements'}),
        createRow({
            headerText: 'Calculated NAV',
            records,
            getCellValue: record => {

                return valueInUnits(record.net_asset_value)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'GS PB NAV + Money Market',
            records,
            getCellValue: record => {

                return valueInUnits(record.gs_total_reported_nav)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Calc NAV less GS NAV',
            records,
            getCellValue: record => {

                return valueInUnits(record.nav_vs_gs_total_reported_nav)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Calc NAV % of GS NAV',
            records,
            getCellValue: record => {

                return record.nav_percent_of_gs_total_reported_nav
            },
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
    ];

    const pbInterestDetailRows = portfolioType !== PortfolioType.point_sur ? [] : [
        createSectionHeaderRow({headerText: 'PB Interest / Margin Analysis ($ not $1000s)'}),
        emptyRow(),
        createHeaderRow({headerText: 'Monthly Interest Income Analysis'}),
        createRow({
            headerText: 'Avg positive PB cash balance $',
            records,
            getCellValue: record => {
                return valueInUnits(record.detail_monthly_average_positive_cash_balance)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Avg positive PB cash balance days',
            records,
            getCellValue: record => {
                return record.detail_monthly_positive_cash_balance_days
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net interest income',
            records,
            getCellValue: record => {
                return valueInUnits(record.detail_monthly_net_interest_income)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net interest rate',
            records,
            getCellValue: record => {
                return record.detail_monthly_net_interest_income_rate
            },
            formatValue: formatPercentTwoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),

        emptyRow(),
        createHeaderRow({headerText: 'Monthly Margin Borrowing Analysis'}),
        createRow({
            headerText: 'Avg negative cash balance (margin)',
            records,
            getCellValue: record => {
                return valueInUnits(record.detail_monthly_average_negative_cash_balance)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Avg negative PB cash balance days',
            records,
            getCellValue: record => {
                return record.detail_monthly_negative_cash_balance_days
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net interest expense',
            records,
            getCellValue: record => {
                return valueInUnits(record.detail_monthly_net_margin_expense)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net interest rate',
            records,
            getCellValue: record => {
                return record.detail_monthly_net_margin_expense_rate
            },
            formatValue: formatPercentTwoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
    ];


    const dataRows = [
        emptyRow(),
        createHeaderRow({headerText: 'Net Asset Value'}),
        createRow({
            headerText: 'Long Securities',
            records,
            getCellValue: record => {
                return valueInUnits(record.securities_long_market_value)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Short Securities',
            records,
            getCellValue: record => {
                return valueInUnits(record.securities_short_market_value)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net Long Securities',
            records,
            getCellValue: record => {
                return valueInUnits(record.securities_net_market_value)
            },
            formatValue: formatNoDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Cash - Short Collateral',
            records,
            getCellValue: record => {
                return valueInUnits(record.cash_encumbered)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Cash - Unencumbered',
            records,
            getCellValue: record => {
                return valueInUnits(record.cash_unencumbered)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net Asset Value',
            records,
            getCellValue: record => {
                return valueInUnits(record.net_asset_value)
            },
            formatValue: formatNoDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),
        emptyRow(),
        createHeaderRow({headerText: '% of NAV'}),
        createRow({
            headerText: 'Long Securities %',
            records,
            getCellValue: record => {
                return record.securities_long_market_value_percent
            },
            formatValue: formatPercentOneDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Short Securities %',
            records,
            getCellValue: record => {
                return record.securities_short_market_value_percent
            },
            formatValue: formatPercentOneDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net Long Securities %',
            records,
            getCellValue: record => {
                return record.securities_net_market_value_percent
            },
            formatValue: formatPercentOneDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Cash %',
            records,
            getCellValue: record => {
                return record.cash_unencumbered_percent
            },
            formatValue: formatPercentOneDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        emptyRow(),


        createHeaderRow({headerText: 'Monthly NAV Bridge'}),
        createRow({
            headerText: 'Beginning NAV',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_beginning_net_asset_value)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Funding In',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_in_funding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Funding Out',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_out_funding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Dividend Tax Withholding',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_dividend_tax_withholding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Other Tax Withholding',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_other_tax_withholding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Income and Change in Market Value',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_change_in_market_value)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Ending NAV',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_ending_net_asset_value)
            },
            formatValue: formatNoDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),

        emptyRow(),
        createHeaderRow({headerText: 'Annual/YTD NAV Bridge'}),
        createRow({
            headerText: 'Beginning NAV',
            records,
            getCellValue: record => {
                return valueInUnits(record.annual_beginning_net_asset_value)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Funding In',
            records,
            getCellValue: record => {
                return valueInUnits(record.annual_in_funding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Funding Out',
            records,
            getCellValue: record => {
                return valueInUnits(record.annual_out_funding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Dividend Tax Withholding',
            records,
            getCellValue: record => {
                return valueInUnits(record.annual_dividend_tax_withholding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Other Tax Withholding',
            records,
            getCellValue: record => {
                return valueInUnits(record.annual_other_tax_withholding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),


        createRow({
            headerText: 'Income and Change in Market Value',
            records,
            getCellValue: record => {
                return valueInUnits(record.annual_change_in_market_value)
            },
            formatValue: formatNoDecimalNoNull,
            indent: 1,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Ending NAV',
            records,
            getCellValue: record => {
                return valueInUnits(record.annual_ending_net_asset_value)
            },
            formatValue: formatNoDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),

        emptyRow(),
        createHeaderRow({headerText: 'Returns'}),

        createRow({
            headerText: 'Monthly Time-Weighted Return',
            records,
            getCellValue: record => {
                return record.monthly_time_weighted_return
            },
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Monthly IRR (not annualized)',
            records,
            getCellValue: record => {
                return record.monthly_unannualized_irr
            },
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
        createRow({
            headerText: 'Annual/YTD Time-Weighted Return',
            records,
            getCellValue: record => {
                return record.annual_time_weighted_return
            },
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Annual/YTD IRR (not annualized)',
            records,
            getCellValue: record => {
                return record.annual_unannualized_irr
            },
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),

        emptyRow(),



        createHeaderRow({headerText: 'Monthly Cash Bridge ($USD-equivalent)'}),
        createRow({
            headerText: 'Beginning Cash',
            records,
            getCellValue: record => {

                return valueInUnits(record.monthly_beginning_unencumbered_cash)
            },
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Funding In',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_in_funding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Funding Out',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_out_funding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Cash Used to Buy Long Securities',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_cash_used_to_buy_long_securities)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Cash from Sale of Long Securities',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_cash_from_sell_long_of_securities)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Cash Used to Buy Cover Securities',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_cash_used_to_buy_cover_securities)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Cash from Short Sale of Securities',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_cash_from_short_sell_of_securities)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Change in Short Cash Collateral',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_change_in_short_collateral)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),

        createRow({
            headerText: 'Long Dividends',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_long_dividends)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Short Dividends',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_short_dividends)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Dividend Foreign Tax Withholding',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_dividend_tax_withholding)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net Interest Income',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_net_interest_income)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Margin Expense',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_net_margin_expense)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Short Borrow Expense',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_short_borrow_expense)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Wire Fees',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_wire_fees)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Trading Fees',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_trading_fees)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Money Market Interest Income',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_money_market_interest_income)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Error / Currency Translation',
            records,
            getCellValue: record => {
                return valueInUnits(record.monthly_error)
            },
            formatValue: formatNoDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),


        createRow({
            headerText: 'Ending Cash',
            records,
            getCellValue: record => {

                return valueInUnits(record.monthly_ending_unencumbered_cash)
            },
            formatValue: formatNoDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),

        emptyRow(),

        ...gsCheckRows,
        ...piperCheckRows,

        ...pbInterestDetailRows


    ];

    return (
        <div className={getTableClassName(loading) + ' align-right'}>

            <ReactDataGrid
                columns={dateRow}
                rowGetter={index => dataRows[index]}
                rowsCount={dataRows.length}
                headerRowHeight={headerRowHeight}
                rowHeight={rowHeight}
                minHeight={getWindowHeightLessMenuBar()}
                rowRenderer={ModelRowRenderer}
            />

        </div>
    )

}