import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Container, Divider, Form, Header, Icon} from 'semantic-ui-react'
import {fetchDoradoBacktestParameters, patchDoradoBacktestParameters} from "../../../features/dorado/doradoPortfolio";
import {formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import {parseBoolean} from "../../../common/query/parse";
import {Redirect} from 'react-router';
import {
    startDoradoMirrorBacktest,
    startDoradoPortfolioBacktest,
    startDoradoPortfolioBacktestSensitivity
} from "../../../features/adminFeatureSlice";
import {
    assumedImplementationCostOptions,
    longConcentrationScoreOptions,
    FormItemWithDesc,
    longMaxSinglePositionWeightOptions,
    roundToFourDigits,
    startEndDateOptions,
    targetCashOptions,
    tradeCostPercentOptions
} from "./common";
import PortfolioParameters from "./PortfolioParameters";


export default () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDoradoBacktestParameters());
    }, [dispatch]);

    const {backtestParameters, loadingBacktestParameters} = useSelector(state => state.doradoPortfolio, shallowEqual);
    const {loadingImportStartedAcknowledgement} = useSelector(state => state.admin, shallowEqual);

    if (loadingImportStartedAcknowledgement) {
        return <Redirect to={'/admin'}/>
    }

    const dateOptions = startEndDateOptions.map(d => {
        const s = d.format('YYYY-MM-DD');
        return {
            key: s,
            value: s,
            text: d.format('MM-DD-YYYY')
        }
    });
    dateOptions.unshift({
        key: 'null',
        value: null,
        text: ''
    });

    return (
        <Container>
            <Header as='h1'>Backtest Parameters</Header>
            <p>Last modified {formatFullDateTimeUtcToLocal(backtestParameters.last_modified)}</p>

            <Form loading={loadingBacktestParameters}>
                <Form.Button icon labelPosition='left'
                             onClick={() => {
                                 dispatch(startDoradoPortfolioBacktest())
                             }}
                             color={loadingImportStartedAcknowledgement ? 'grey' : 'purple'}
                >
                    <Icon name='history'/>
                    {loadingImportStartedAcknowledgement ? 'Processing...' : 'Run Backtest'}
                </Form.Button>
                <Form.Button icon labelPosition='left'
                             onClick={() => {
                                 dispatch(startDoradoPortfolioBacktestSensitivity())
                             }}
                             color={loadingImportStartedAcknowledgement ? 'grey' : 'teal'}
                >
                    <Icon name='share alternate'/>
                    {loadingImportStartedAcknowledgement ? 'Processing...' : 'Run Backtest Sensitivity'}
                </Form.Button>
                <Form.Button icon labelPosition='left'
                             onClick={() => {
                                 dispatch(startDoradoMirrorBacktest())
                             }}
                             color={loadingImportStartedAcknowledgement ? 'grey' : 'blue'}
                >
                    <Icon name='clone'/>
                    {loadingImportStartedAcknowledgement ? 'Processing...' : 'Run Dorado Mirror'}
                </Form.Button>

                <PortfolioParameters dispatchPatchObject={(patchObject) => dispatch(patchDoradoBacktestParameters(patchObject))}
                                     parameters={backtestParameters}

                />
                <br/>
                <Divider horizontal>
                    <Header as='h4'>
                        <Icon name='chart line'/>
                        Backtest-Specific Parameters
                    </Header>
                </Divider>
                <br/>
                <FormItemWithDesc>
                    <Form.Select options={tradeCostPercentOptions}
                                 value={roundToFourDigits(backtestParameters.soft_trade_cost_percent)}
                                 onChange={(e, d) => {

                                     const patchObject = {
                                         id: backtestParameters.id,
                                         soft_trade_cost_percent: d.value
                                     };

                                     dispatch(patchDoradoBacktestParameters(patchObject));
                                 }}
                                 label={'Soft Trade Cost %'}
                                 width={3}
                    />
                    <p>Bid/ask spread, routing, timing, and other factors which cause us to slightly underperform the prices we see in an instant.
                        In backtest, we cannot always assume we buy at the VWAP and keeps the backtest honest.</p>

                </FormItemWithDesc>
                <FormItemWithDesc>
                    <Form.Checkbox
                        label='Include Companies not on North America Exchanges'
                        checked={parseBoolean(backtestParameters.include_non_north_america_exchanges)}
                        onChange={(e, d) => {
                            const patchObject = {
                                id: backtestParameters.id,
                                include_non_north_america_exchanges: d.checked
                            };

                            dispatch(patchDoradoBacktestParameters(patchObject));

                        }}
                        width={3}
                    />
                    <p>US/Canada exchanges are on the same time zone.  Backtesting becomes more complicated when matching trades and dates in different timezones, market holidays, etc.
                        Our backtest should work on domestic stocks only even if we trade internationally in practice.</p>
                </FormItemWithDesc>
                <FormItemWithDesc>
                    <Form.Select options={dateOptions}
                                 value={backtestParameters.start_date}
                                 onChange={(e, d) => {

                                     const patchObject = {
                                         id: backtestParameters.id,
                                         start_date: d.value
                                     };

                                     dispatch(patchDoradoBacktestParameters(patchObject));
                                 }}
                                 label={'Backtest Start Date'}
                                 width={3}
                    />
                    <p>Select null for full range</p>
                </FormItemWithDesc>
                <FormItemWithDesc>
                    <Form.Select options={dateOptions}
                                 value={backtestParameters.end_date}
                                 onChange={(e, d) => {

                                     const patchObject = {
                                         id: backtestParameters.id,
                                         end_date: d.value
                                     };

                                     dispatch(patchDoradoBacktestParameters(patchObject));
                                 }}
                                 label={'End Date (select null for full range)'}
                                 width={3}
                    />
                    <p>Select null for full range</p>
                </FormItemWithDesc>
                <FormItemWithDesc>
                    <Form.Checkbox
                        label='Match Dorado Funding'
                        checked={parseBoolean(backtestParameters.match_dorado_funding)}
                        onChange={(e, d) => {
                            const patchObject = {
                                id: backtestParameters.id,
                                match_dorado_funding: d.checked
                            };

                            dispatch(patchDoradoBacktestParameters(patchObject));

                        }}
                        width={3}
                    />
                    <p>Check to match the exact same cash inflows as we do for our actual dorado history vs starting with a fixed initial funding.</p>
                </FormItemWithDesc>





            </Form>
            <br/>
            <br/>
            <br/>


        </Container>
    )
}



