import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {fetchEmployeesScrapeRecords} from '../../../actions/employees/scrapeRecordActions';
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from "../../../common/request/convert";
import {parseSearchProps} from "../../../common/query/parse";
import {requestNewDataOnChange} from "../../../common/request/newData";
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import EmployeeScrapeTable from './EmployeeScrapeTable'


class EmployeeScrapesPage extends Component {

  componentDidMount() {
    this._requestNewDataOnChange({});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const lastSearchProps = parseSearchProps({searchString: prevProps.location.search});
    this._requestNewDataOnChange({lastSearchProps});
  }

  _requestNewDataOnChange = ({lastSearchProps}) => {

    const searchProps = parseSearchProps({searchString: this.props.location.search});

    const requestData = ({searchProps}) => {
      const sort = convertQuerySortToFeathersSort(searchProps.sort);
      const filters = convertFiltersToFeathersFilters({filters: searchProps.filters});

      this.props.fetchCompanyEmployeeScrapeRecords({sort, filters});
    };

    requestNewDataOnChange({lastSearchProps, searchProps, requestData, queryMatchFields: ['filters', 'sort']})

  };

  render() {

    return (
      <ViewWithSidebar mainView={<EmployeeScrapeTable refreshRecords={() => {this._requestNewDataOnChange({});}}/>}
                       sidebarView={<div/>}/>
    )

  }
}


function mapStateToProps(state) {
  return {
  }
}


export default withRouter(connect(mapStateToProps,
  {
    fetchCompanyEmployeeScrapeRecords: fetchEmployeesScrapeRecords
  })(EmployeeScrapesPage));
