import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {fetchEmployeeRecordsForCompany} from '../../../actions/employees/employeeRecordActions';
import {getAssociationCountsForScrape} from '../../../actions/employees/associationActions';
import {fetchRolesForScrapeId} from '../../../actions/employees/roleActions';
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from "../../../common/request/convert";
import {parseSearchProps} from "../../../common/query/parse";
import {requestNewDataOnChange} from "../../../common/request/newData";
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import EmployeeRecordTable from './EmployeeRecordTable'
import EmployeeRecordSidebar from './EmployeeRecordSidebar'


class EmployeeRecordPage extends Component {

  componentDidMount() {
    this._requestNewDataOnChange({});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const lastSearchProps = parseSearchProps({searchString: prevProps.location.search});
    const lastMatchParams = prevProps.match.params;
    this._requestNewDataOnChange({lastSearchProps, lastMatchParams});
  }

  _requestNewDataOnChange = ({lastSearchProps, lastMatchParams}) => {

    const searchProps = parseSearchProps({searchString: this.props.location.search});
    const matchParams = this.props.match.params;

    const requestData = ({searchProps, matchParams}) => {

      const {scrape_id} = matchParams;
      const sort = convertQuerySortToFeathersSort(searchProps.sort);
      const filters = convertFiltersToFeathersFilters({filters: searchProps.filters});

      this.props.fetchEmployeeRecordsForCompany({sort, filters, employeesScrapeRecordId: scrape_id});
      this.props.getAssociationCountsForScrape({scrapeId: scrape_id});

      const rawSort = [
        ["name", "1"],
        ["minor_cost_center", "1"],
        ["major_cost_center", "1"]
      ];
      const roleSort = convertQuerySortToFeathersSort(rawSort);

      this.props.fetchRolesForScrapeId({sort: roleSort, filters: {}, scrapeId: scrape_id});
    };

    requestNewDataOnChange({
      lastSearchProps,
      searchProps,
      lastMatchParams,
      matchParams,
      requestData,
      matchFields: ['scrape_id'],
      queryMatchFields: ['filters', 'sort']
    })

  };

  render() {

    return (
      <ViewWithSidebar mainView={<EmployeeRecordTable refresh={() => {this._requestNewDataOnChange({})}}/>} sidebarView={<EmployeeRecordSidebar/>}/>
    )

  }
}


function mapStateToProps(state) {
  return {
  }
}


export default withRouter(connect(mapStateToProps,
  {
    fetchEmployeeRecordsForCompany, getAssociationCountsForScrape, fetchRolesForScrapeId
  })(EmployeeRecordPage));
