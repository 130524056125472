const defaultState = {
  records: [],
  loading: true,
  facetsByField: {},
  loadingFacets: true
};


export default (state = defaultState, action = {}) => {
  switch (action.type) {

    // case "FETCH_VALUATION_TABLE_RECORDS_PENDING": {
    //   return {
    //     ...state,
    //     loading: true
    //   }
    // }

    // case "FETCH_VALUATION_TABLE_RECORDS_FULFILLED": {
    //
    //   return {
    //     ...state,
    //     records: action.payload.data,
    //     loading: false
    //   }
    // }

    // case 'FETCH_VALUATION_TABLE_FACETS_FULFILLED': {
    //   return {
    //     ...state,
    //     facetsByField: action.payload.data,
    //     loadingFacets: false
    //   }
    // }

    default:
      return state;
  }
}
