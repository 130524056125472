import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchRolesForCompany} from '../../../actions/employees/roleActions';
import {fetchCompanyOld} from '../../../actions/companyActions'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from "../../../common/request/convert";
import {parseSearchProps} from "../../../common/query/parse";
import {requestNewDataOnChange} from "../../../common/request/newData";
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import RoleTableView from './RoleTableView'
import RoleSidebar from './RoleSidebar'


class RoleTablePage extends Component {

  componentDidMount() {
    this._requestNewDataOnChange({});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const lastSearchProps = parseSearchProps({searchString: prevProps.location.search});
    const lastMatchParams = prevProps.match.params;
    this._requestNewDataOnChange({lastSearchProps, lastMatchParams});
  }

  _requestNewDataOnChange = ({lastSearchProps, lastMatchParams}) => {

    const searchProps = parseSearchProps({searchString: this.props.location.search});
    const matchParams = this.props.match.params;

    const requestData = ({searchProps, matchParams}) => {

      const {company_id} = matchParams;
      const sort = convertQuerySortToFeathersSort(searchProps.sort);
      const filters = convertFiltersToFeathersFilters({filters: searchProps.filters});

      this.props.fetchCompany(company_id);
      this.props.fetchRolesForCompany({sort, filters, companyId: company_id});
    };

    requestNewDataOnChange({
      lastSearchProps,
      searchProps,
      lastMatchParams,
      matchParams,
      requestData,
      matchFields: ['company_id'],
      queryMatchFields: ['filters', 'sort']
    })

  };

  render() {

    return (
      <ViewWithSidebar mainView={<RoleTableView refreshRecords={() => this._requestNewDataOnChange({})}/>} sidebarView={<RoleSidebar/>}/>
    )

  }


}

function mapStateToProps(state) {
  return {


  }
}


export default connect(mapStateToProps, {fetchRolesForCompany, fetchCompanyOld})(RoleTablePage);
