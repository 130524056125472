import numeral from "numeral";
import React from "react";

export const formatFiveDecimal = (value) => {
    if (value == null) {
        return ''
    }
    if (value === 1.0) {
        return ''
    }

    // numeral throws an error if more than 7 decimals with 0
    if (Math.abs(value) < .000001) {
        value = 0;
    }

    return numeral(value).format('(0,0.00000)')
};

export class CurrencyFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatFiveDecimal(this.props.value)}</div>
    }
}