const defaultState = {
  companies: [],
  loading: true,
  facetsByField: {},
  loadingFacets: true
};

export default (state=defaultState, action={}) => {
  switch (action.type) {

    case "FETCH_COMPANIES_FOR_TAGS_PENDING": {
      return {
        ...state,
        loading: true
      }
    }

    case "FETCH_COMPANIES_FOR_TAGS_FULFILLED": {
      return {
        ...state,
        companies: action.payload.data,
        loading: false
      }
    }

    case "PATCH_COMPANY_PENDING": {
      return {
        ...state,
        loading: true
      }
    }

    case "PATCH_COMPANY_FULFILLED": {

      const patchedCompany = action.payload.data;

      return {
        ...state,
        loading: false,
        companies: state.companies.map(company => {
          if (company.id === patchedCompany.id) {
            return patchedCompany
          } else {
            return company
          }
        })
      }
    }

    case "FETCH_COMPANY_FACETS_FOR_TAGS_FULFILLED" : {
      return {
        ...state,
        facetsByField: action.payload.data,
        loadingFacets: false
      }
    }

    default:
      return state;
  }
}
