import {
    getRdgRowsFromRecords, getTableClassName,
} from "../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import React from "react";
import {companyColumnFromObject} from "../../../common/reactDataGrid/columns";
import {CurrencySharePriceFormatter} from "../../../common/columns";
import {
    TwoDecimalCurrencyFormatter,
    NoDecimalNoNullFormatter,
    PercentNoDecimalNoNullFormatter,
    PercentOneDecimalNoNullFormatter,
    UtcDateFormatter,
    PercentGainOneDecimalFormatter,
    OneDecimalNoNullFormatter, TwoDecimalNoNullFormatter
} from "../../../common/reactDataGrid/formatters";
import {getWindowHeightLessMenuBar} from "../../../common/common";
import {Button, Icon} from "semantic-ui-react";


class SelectPeriodReportFormatter extends React.Component {
    render() {

        const periodReportId = this.props.value;
        const buttonStyle = {"paddingTop": 4, "paddingBottom": 4};

        return (
            <div>
                <Button icon basic size="small" color="blue" style={buttonStyle}
                        onClick={() => this.props.setDoradoBacktestPeriodReportId(periodReportId)}
                >
                    <Icon name="magnify"/>
                </Button>
            </div>

        )

    }
}



export const getColumns = ({setDoradoBacktestPeriodReportId}) => {
    return [
        {
            key: 'start_date',
            name: 'Start Date',
            formatter: UtcDateFormatter,
            width: 90,
            frozen: true
        },
        {
            key: 'end_date',
            name: 'End Date',
            formatter: UtcDateFormatter,
            width: 90,
            frozen: true
        },
        {
            key: 'id',
            name: 'View',
            formatter: <SelectPeriodReportFormatter setDoradoBacktestPeriodReportId={setDoradoBacktestPeriodReportId}/>,
            width: 55,
            frozen: true
        },

        {
            key: 'time_weighted_return',
            name: 'Time Weighted Return',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
            frozen: true
        },
        {
            key: 'time_weighted_return_ex_short_contribution',
            name: 'TWR ex-Shorts',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
        },
        {
            key: 'short_contribution_to_return',
            name: 'Short Contrib. to Return',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
        },
        {
            key: 'b_0',
            name: '',
            width: 20,
        },
        {
            key: 'time_weighted_long_return',
            name: 'Long Positions TWR',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
        },
        {
            key: 'time_weighted_short_return',
            name: 'Short Positions TWR',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
        },
        {
            key: 'time_weighted_long_vs_short_return',
            name: 'Long vs Short Return',
            formatter: PercentGainOneDecimalFormatter,
            width: 70,
        },
        {
            key: 'b_000',
            name: '',
            width: 20,
        },
        {
            key: 'period_spy_return',
            name: 'S&P 500 Return',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
        },
        {
            key: 'period_long_alpha_vs_spy',
            name: 'Long Alpha vs SPY',
            formatter: PercentGainOneDecimalFormatter,
            width: 70,
        },
        {
            key: 'period_alpha_vs_spy',
            name: 'Alpha vs SPY',
            formatter: PercentGainOneDecimalFormatter,
            width: 70,
        },
        {
            key: 'b_',
            name: '',
            width: 20,
        },
        {
            key: 'period_igv_return',
            name: 'IGV Return',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
        },
        {
            key: 'period_long_alpha_vs_igv',
            name: 'Long Alpha vs IGV',
            formatter: PercentGainOneDecimalFormatter,
            width: 70,
        },
        {
            key: 'period_alpha_vs_igv',
            name: 'Alpha vs IGV',
            formatter: PercentGainOneDecimalFormatter,
            width: 70,
        },
        {
            key: 'b_98000',
            name: '',
            width: 20,
        },
        {
            key: 'period_wcld_return',
            name: 'WCLD Return',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
        },
        {
            key: 'period_long_alpha_vs_wcld',
            name: 'Long Alpha vs WCLD',
            formatter: PercentGainOneDecimalFormatter,
            width: 70,
        },
        {
            key: 'period_alpha_vs_wcld',
            name: 'Alpha vs WCLD',
            formatter: PercentGainOneDecimalFormatter,
            width: 70,
        },
        {
            key: 'b_8',
            name: '',
            width: 20,
        },
        {
            key: 'cumulative_time_weighted_return',
            name: 'Cumulative Time Weighted Return',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80,
        },
        {
            key: 'cumulative_spy_return',
            name: 'Cumulative S&P 500 Return',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80,
        },
        {
            key: 'cumulative_alpha_vs_spy',
            name: 'Cumulative Alpha vs SPY',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80,
        },
        {
            key: 'b_9',
            name: '',
            width: 20,
        },
        {
            key: 'cumulative_igv_return',
            name: 'Cumulative IGV Return',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80,
        },
        {
            key: 'cumulative_alpha_vs_igv',
            name: 'Cumulative Alpha vs IGV',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80,
        },
        {
            key: 'b_1aasd',
            name: '',
            width: 20,
        },
        {
            key: 'cumulative_wcld_return',
            name: 'Cumulative WCLD Return',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80,
        },
        {
            key: 'cumulative_alpha_vs_wcld',
            name: 'Cumulative Alpha vs WCLD',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80,
        },
        {
            key: 'b_1',
            name: '',
            width: 20,
        },
        {
            key: 'irr',
            name: 'IRR (not annualized)',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80
        },
        {
            key: 'standard_deviation',
            name: 'Standard Deviation',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80,
        },
        {
            key: 'b_1010',
            name: '',
            width: 20,
        },
        {
            key: 'time_weighted_long_return_2',
            name: 'Long Positions TWR',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
            _getValueFromRecord: (r) => {
                return r.time_weighted_long_return
            }
        },
        {
            key: 'time_weighted_short_return_2',
            name: 'Short Positions TWR',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 70,
            _getValueFromRecord: (r) => {
                return r.time_weighted_short_return
            }
        },

        {
            key: 'long_short_correlation',
            name: 'Long-Short Correlation',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80
        },
        {
            key: 'long_short_r2',
            name: 'Long-Short R2',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80
        },
        {
            key: 'long_short_slope',
            name: 'Long-Short Slope',
            formatter: TwoDecimalNoNullFormatter,
            width: 80
        },
        {
            key: 'long_short_intercept',
            name: 'Long-Short Intercept',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 80
        },
        {
            key: 'b_1010n',
            name: '',
            width: 20,
        },


        {
            key: 'beginning_net_asset_value',
            name: 'Beginning NAV',
            formatter: NoDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'long_positions_total_gain',
            name: 'Long Positions Total Gain',
            width: 100,
            formatter: NoDecimalNoNullFormatter
        },
        {
            key: 'short_positions_total_gain',
            name: 'Short Positions Total Gain',
            width: 100,
            formatter: NoDecimalNoNullFormatter
        },
        {
            key: 'positions_total_gain',
            name: 'Positions Total Gain',
            formatter: NoDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'other_nav_change',
            name: 'Net Other Change',
            width: 100,
            formatter: NoDecimalNoNullFormatter
        },
        {
            key: 'ending_net_asset_value',
            name: 'Ending NAV',
            formatter: NoDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'ending_securities_long_market_value_percent',
            name: 'Ending % Long',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'ending_securities_short_market_value_percent',
            name: 'Ending % Short',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'ending_securities_net_market_value_percent',
            name: 'Ending % Net Long',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'average_long_execution_score',
            name: 'Long Avg Execution Score',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'average_long_herfindahl_index',
            name: 'Avg Long Herfindahl Index',
            formatter: NoDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'average_long_position_count',
            name: 'Avg Long Position Count',
            formatter: NoDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'average_short_position_count',
            name: 'Avg Short Position Count',
            formatter: NoDecimalNoNullFormatter,
            width: 90,
        },
        {
            key: 'avg_trading_day_gross_trades_percent_of_equity',
            name: 'Avg Daily Trades % Equity',
            formatter: PercentOneDecimalNoNullFormatter,
            width: 90,
        },

        {
            key: 'report_id',
            name: 'Report ID',
            width: 90,
            _getValueFromRecord: (r) => {
                return r.id
            }
        },


    ]
}




export default ({records, loading, setDoradoBacktestPeriodReportId}) => {

    const columns = getColumns({setDoradoBacktestPeriodReportId});

    const rows = getRdgRowsFromRecords({records, columns});

    return (

        <div className={getTableClassName(loading)}>

            <ReactDataGrid
                columns={columns}
                rowGetter={index => rows[index]}
                rowsCount={rows.length}
                rowHeight={22}
                headerRowHeight={100}
                minHeight={getWindowHeightLessMenuBar() - 25}
            />

        </div>

    )
};