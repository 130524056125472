import React from 'react'
import { Label, Grid, List } from 'semantic-ui-react'



export const costCenters = {
  COGS: {
    sortValue: 1,
    color: 'grey',
    valid: true,
    details: [
      'DevOps, Infrastructure',
      'Professional Service, Consulting',
      'Support, Technical Support',
      'Implementation Service'
    ]
  },
  'R&D': {
    sortValue: 2,
    color: 'green',
    valid: true,
    details: [
      'Engineering',
      'Product Management',
      'Engineering QA / Test',
      'Other Non-Engineering Product',
      'Engineering Management'
    ]
  },
  'S&M': {
    sortValue: 3,
    color: 'orange',
    valid: true,
    details: [
      'Marketing',
      'Account Management, Client Service',
      'Quota-Carrying Sales',
      'Sales Support',
      'Sales Management',
      'Business Development, Partnerships'
    ]
  },
  'G&A': {
    sortValue: 4,
    color: 'brown',
    valid: true,
    details: [
      'Executive Management',
      'Finance',
      'HR',
      'IT',
      'Legal',
      'Public Company',
      'Operations'
    ]
  },
  'Unknown': {
    sortValue: 5,
    valid: false,
    details: []
  },
  'Not Employee': {
    sortValue: 6,
    valid: false,
    details: [
      'Not Employee'
    ]
  },
  'Not Associated': {
    sortValue: 7,
    valid: false,
    details: [
      'Not Associated'
    ]
  }


};


export const getLabelFromCostCenter = ({costCenter, props}) => {

  props = props || {};
  return costCenter ? <Label color={costCenters[costCenter].color} {...props}>{costCenter}</Label> : null
};




export const sortedValidCostCenterKeys = Object.keys(costCenters).filter(key => {
  return costCenters[key].valid
}).sort((a, b) => {return costCenters[a].sortValue > costCenters[b].sortValue});



export const costCenterOptions = Object.keys(costCenters).map(key => {
  return {
    key: key,
    text: key,
    value: key
  }
});


export const costCenterDetailOptions = [];
Object.keys(costCenters).forEach(key => {
  costCenters[key].details.forEach(detailItem => {
    costCenterDetailOptions.push({
      key: detailItem,
      text: key + ": " + detailItem,
      value: detailItem
    });
  })
});


export const compareCostCenter = (a, b) => {
  return costCenters[a.cost_center].sortValue - costCenters[b.cost_center].sortValue
};

export const isValidCostCenter = (cost_center) => {
  return costCenters[cost_center].valid
}


export const compareCostCenterAndDetailAndName = (a, b) => {

  let val = costCenters[a.cost_center].sortValue - costCenters[b.cost_center].sortValue;

  if (val !== 0) {
    return val
  }
  if (a.cost_center_detail !== b.cost_center_detail) {
    return a.cost_center_detail > b.cost_center_detail ? 1 : -1
  }

  return a.name > b.name

}


export const sortRolesByCostCenterAndDetailAndName = (roles) => {
  return roles.slice().sort(compareCostCenterAndDetailAndName);
}





export const getRoleOptionsFromRoles = (roles) => {
  // add a null key in front of roles for select cancel functionality
  roles = [{key: -1, id: null}, ...roles];

  return roles.map((role) => {
    return {
      key: role.key ? role.key: role.id,
      text: role.name,
      value: role.id,
      content: role.id ? (
        <Grid>
          <Grid.Column floated="left" width="12">{role.name}</Grid.Column>
          <Grid.Column floated="right" width="4">{getLabelFromCostCenter(role.cost_center)}</Grid.Column>
        </Grid>
      ) : null
    }
  });

}

export const newlineToBreak = (text) => {
  if (text == null) {
    return ''
  }

  return (
    <List bulleted>
      {text.split('\n').map((item, key) => {
        return <List.Item key={key}>{item}</List.Item>
      })}
    </List>

  )
}

export const mergeGenericAndCompanyRoles = (genericRoles, companyRoles) => {

  const rolesByName = {};

  genericRoles.forEach(role => {
    rolesByName[role.name] = role;
  });

  // overwrite with companyFinancials role
  companyRoles.forEach(role => {
    rolesByName[role.name] = role;
  });

  return sortRolesByCostCenterAndDetailAndName(Object.values(rolesByName))

}

