import {formatPercentNoNull, formatMultipleNoDecimal} from '../format'
import {getColorTextFromRgbAndOpacity, hexToRgb} from './chartColorers'


const attractiveConstantDollarGrowthMultiples = [
  [0, 9.22],
  [.05, 12.88],
  [.10, 16.20],
  [.15, 19.23],
  [.20, 22.02],
  [.25, 24.57],
  [.30, 26.94]
];

const lboEntryValuations = [
  {
    revenueGrowth: 0,
    ebitdaMultiple: 7
  },
  {
    revenueGrowth: .05,
    ebitdaMultiple: 8.5
  },
  {
    revenueGrowth: .1,
    ebitdaMultiple: 10.5
  },
  {
    revenueGrowth: .15,
    ebitdaMultiple: 13
  },
  {
    revenueGrowth: .3,
    ebitdaMultiple: 17.5
  },

];
const labelStyle = {
  fontSize: "12px",
  fontWeight: 'bold',
  textOutline: '1px',
};

const lineTemplate = {
  type: 'line',
  allowPointSelect: false,
  lineWidth: 2,
  marker: {
    enabled: false
  },
  enableMouseTracking: false,
  dataLabels: {
    enabled: true,
    allowOverlap: true,
    // formatter: function () {
    //   return formatMultipleNoDecimal(this.point.ebitdaMultiple)
    // },
    style: {
      ...labelStyle
    }
  },
};


const prePremiumMultiplier = 1 / 1.3;


export const attractiveValuations = attractiveConstantDollarGrowthMultiples.map(item => {


  const record = {
    'EBITDA Multiple': item[1] * prePremiumMultiplier,
    'Revenue Growth': item[0],
  };

  for (let margin of [.2, .3, .4]) {
    record['EV/Rev at ' + formatPercentNoNull(margin) + ' margin'] = record['EBITDA Multiple'] * margin;
  }

  return record
});

const margins = [.2, .3, .4];
const marginColors = [
  hexToRgb('#4d004b'),
  hexToRgb('#084081'),
  hexToRgb('#fc4e2a')
];


export const revenueLines = margins.map((margin, i) => {
  return {
    data: lboEntryValuations.map(item => {
      const revenueMultipleBeforePremium = item.ebitdaMultiple * margin * prePremiumMultiplier;

      return {
        x: item.revenueGrowth,
        y: revenueMultipleBeforePremium,
        margin: margin,
        ebitdaMultiple: item.ebitdaMultiple,
      }
    }),
    type: 'line',
    allowPointSelect: false,
    lineWidth: 2,
    marker: {
      enabled: false
    },
    enableMouseTracking: false,
    dataLabels: {
      enabled: true,
      allowOverlap: true,
      formatter: function () {
        return formatMultipleNoDecimal(this.point.ebitdaMultiple)
      },
      style: {
        ...labelStyle,
        // color: getColorTextFromRgbAndOpacity(hexToRgb('#4d004b'), .8)
      }
    },

    color: getColorTextFromRgbAndOpacity(marginColors[i], .6),
    name: 'Entry LBO EV/EBITDA at ' + formatPercentNoNull(margin) + ' margin'


  }


});

export const grossProfitLines = margins.map((margin, i) => {

  const assumedGrossMargin = .7;

  let result = {
    ...lineTemplate,
    data: lboEntryValuations.map(item => {

      const grossProfitMultipleBeforePremium = item.ebitdaMultiple * margin * prePremiumMultiplier / assumedGrossMargin;

      return {
        x: item.revenueGrowth,
        y: grossProfitMultipleBeforePremium,
        margin: margin,
        ebitdaMultiple: item.ebitdaMultiple,
      }
    }),
    color: getColorTextFromRgbAndOpacity(marginColors[i], .6),
    name: 'Entry LBO EV/EBITDA at ' + formatPercentNoNull(margin) + ' margin  assuming ' + formatPercentNoNull(assumedGrossMargin) + ' GM'
  };

  result.dataLabels.formatter = function () {
    return formatMultipleNoDecimal(this.point.ebitdaMultiple)
  };

  return result


});

const ruleOf40MaximizedGrowth = .03;
const ruleOf40MaximizedEBITDAMultiple = 12;

export const attractiveRuleOf40Lines = [
  {
    ...lineTemplate,
    data: [0.05, .1, .2, .3, .4, .5].map(ebitdaMarginPlusGrowth => {

      const ebitdaMargin = ebitdaMarginPlusGrowth - ruleOf40MaximizedGrowth;
      const revMultiple = ruleOf40MaximizedEBITDAMultiple * ebitdaMargin * prePremiumMultiplier;

      return {
        x: ebitdaMarginPlusGrowth,
        y: revMultiple
      }
    }),
    color: getColorTextFromRgbAndOpacity(hexToRgb('#00441b'), .6),
    name: formatMultipleNoDecimal(ruleOf40MaximizedEBITDAMultiple) + ' EBITDA with ' + formatPercentNoNull(ruleOf40MaximizedGrowth) + ' growth after premium'
  }


];


export const ebitdaMultipleFromYieldLine = {
  type: 'line',
  allowPointSelect: false,
  lineWidth: 2,
  marker: {
    enabled: false
  },
  enableMouseTracking: false,
  dataLabels: {
    enabled: true,
    allowOverlap: true,
    formatter: function () {
      return formatMultipleNoDecimal(this.point.ebitdaMultiple)
    },
    style: {
      ...labelStyle
    }
  },

};


export const attractiveEBITDAYieldLine = {
  ...lineTemplate,
  data: attractiveConstantDollarGrowthMultiples.map(item => {

    const ebitdaMultiple = item[1];
    const ebitdaYield = 1 / ebitdaMultiple;
    const revenueGrowth = item[0];

    return {
      x: revenueGrowth,
      y: ebitdaYield,
      ebitdaMultiple: ebitdaMultiple,
    }
  }),
  color: getColorTextFromRgbAndOpacity(hexToRgb('#4d004b'), .6),
  name: 'Attractive EV/EBITDA'

};



