export const executeWithPending = async ({dispatch, featureSlice, prefix, getPayload}) => {
    try {
        dispatch(featureSlice.actions[prefix + 'Pending']());

        const payload = await getPayload();

        dispatch(featureSlice.actions[prefix + 'Fulfilled'](payload))

        return payload
    } catch (e) {
        console.log(prefix, 'Error: ' + e);
    }



};


export const getSingularPayloadFromArray = (payload) => {
    //  can call on an API that returns an array and convert to a single item


    return payload.then(payload => {
        const data = payload.data;

        if (data.length === 1) {
            payload.data = data[0];
            return payload
        } else {
            console.log('Payload Error (Multiple or None)', payload)
            return {}
        }
    })




}



export const getInitialStateWithLoading = (initialValues) => {

    const state = {...initialValues};

    const allKeys = Object.keys(initialValues);

    for (let k of allKeys) {

        if (!k.startsWith('loading')) {
            const loadingField = 'loading' + makeFirstLetterUppercase(k);

            if (!allKeys.includes(loadingField)) {
                state[loadingField] = true;
            }


        }

    }

    return state
};

const makeFirstLetterUppercase = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
};

export const makeStandardReducers = ({prefix, destinationVariable}) => {

    return {
        [prefix + 'Pending']: (state) => {
            state['loading' + makeFirstLetterUppercase(destinationVariable)] = true;
        },
        [prefix + 'Fulfilled']: (state, action) => {
            state[destinationVariable] = action.payload.data;
            state['loading' + makeFirstLetterUppercase(destinationVariable)] = false;
        },

    }
};


export const makeStandardPollingReducers = ({prefix}) => {
    return {
        [prefix + 'Pending']: (state) => {
            state.refreshingRecords = true;
            state.processTaskId = null;
            state.processRefreshCount = 0;
        },
        [prefix + 'Fulfilled']: (state, action) => {
            state.processTaskId = action.payload.data.task_id;
        },

    }
}