import client from '../benchClient';

const companiesService = client.service('companies');

const newCompaniesFromCiqIdService = client.service('new_company_from_ciq_id');
const shareClassSecuritiesService = client.service('share_class_securities');


export function fetchCompany({dispatch, id}) {
    return dispatch({
        type: 'FETCH_COMPANY',
        payload: companiesService.get(id)
    });
}

export function fetchCompanyOld(id) {
    return dispatch => {
        return dispatch({
            type: 'FETCH_COMPANY',
            payload: companiesService.get(id)
        });
    };
}


export function addNewCompanyFromCiqId({ciq_id}) {
    return dispatch => {
        return dispatch({
            type: 'ADD_NEW_COMPANY_FROM_CIQ_ID',
            payload: newCompaniesFromCiqIdService.create({

                ciq_id: ciq_id,

            })
        });
    };
}

export function modifyCompany({dispatch, fieldName, value}) {
    return dispatch({
        type: 'MODIFY_COMPANY_FIELD',
        payload: {
            fieldName: fieldName,
            value: value
        }
    })
}

export function fetchShareClassSecuritiesForCompany({dispatch, companyId}) {
    return dispatch({
        type: 'FETCH_SHARE_CLASS_SECURITIES_FOR_COMPANY',
        payload: shareClassSecuritiesService.find({
            query: {
                company_id: companyId,
                $sort: {
                    index: 1
                }
            }

        })
    })
}


export function patchShareClassSecurity({dispatch, id, patchData}) {

    return dispatch({
        type: 'PATCH_SHARE_CLASS_SECURITY',
        payload: shareClassSecuritiesService.patch(id, patchData)
    });

}