import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Header, Progress, Statistic} from 'semantic-ui-react'
import ViewWithSidebar from '../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../common/hooks";
import RdgTable from "../../components/rdg/RdgTable";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../common/query/parse";
import {fetchDataQualityRecordsForCompany} from "../../features/dataQualityFeatureSlice";
import {fieldSpecs} from "../company/historicalFinancials/common";
import {columns} from './common'
import {fetchCompany} from "../../features/companyFeatureSlice";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const searchProps = parseSearchProps({searchString: location.search});

    useReloadOnChange({
        fieldSpecs,
        queryMatchFields: [],
        matchFields: ['id'],
        requestData: requestData
    });

    const {company, loadingCompany} = useSelector(state => state.company, shallowEqual);

    const {dataQualityRecords, loadingDataQualityRecords} = useSelector(state => state.dataQuality, shallowEqual);

    console.log(dataQualityRecords);

    const sidebarView = (
        <Grid.Row>
            <Grid.Column textAlign="center">
                <Header>
                    Backtest Data Quality
                </Header>
                <Header>
                    {loadingCompany ? "" : company.company_name}
                </Header>
            </Grid.Column>
        </Grid.Row>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             loading={loadingDataQualityRecords}
                                             records={dataQualityRecords}/>}
                         sidebarView={sidebarView}/>
    )
}


const requestData = ({dispatch, matchParams}) => {
    const {company_id} = matchParams;
    dispatch(fetchCompany({companyId: company_id}))
    dispatch(fetchDataQualityRecordsForCompany({companyId: company_id}));

};