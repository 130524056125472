import { createSlice } from '@reduxjs/toolkit'

import client from '../../benchClient';
import {makeStandardReducers, executeWithPending} from "../common";
import {getInitialStateWithLoading} from "../common";
const doradoBacktestAuditRecordsService = client.service('dorado_backtest_audit_records');


const doradoBacktestAuditFeatureSlice = createSlice({
    name: 'doradoBacktestAudit',
    initialState: getInitialStateWithLoading({
        records: [],
    }),
    reducers: {
        ...makeStandardReducers({
            prefix: 'fetchDoradoBacktestAuditRecords',
            destinationVariable: 'records'
        }),
    }
});


export const fetchDoradoBacktestAuditRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoBacktestAuditFeatureSlice,
        prefix: 'fetchDoradoBacktestAuditRecords',
        getPayload: () => {
            return doradoBacktestAuditRecordsService.find({
                query: {
                    $sort: sort,
                    ...filters
                }

            })
        }
    })
};

export default doradoBacktestAuditFeatureSlice.reducer