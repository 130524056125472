import {chartSpecificationByName} from "./chartCommon";
import qs from "qs";
import {getBooleanFromStringOrBool} from '../../common/common'


export const chartFieldSpecs = {
  chartName: {
    parse: (v) => {
      if (v == null) {
        return Object.keys(chartSpecificationByName)[0];
      }
      return v
    }
  },
  period: {
    parse: (v) => {
      if (v == null) {
        return '2019';
      }
      return v
    }
  },
  labels: {
    parse: (v) => {
      if (v == null) {
        return 'Ticker';
      }
      return v
    }
  },
  color: {
    parse: (v) => {
      if (v == null) {
        return '% Recurring';
      }
      return v
    }
  },
  axesSize: {
    parse: (v) => {
      if (v == null) {
        return 'Default';
      }
      return v
    }
  },
  axesSpec: {
    parse: (v) => {
      if (v == null) {
        return {};
      }
      return v
    }
  },
  valuationLinesEnabled: {
    parse: (v) => {
      if (v == null) {
        return false
      }
      return getBooleanFromStringOrBool(v)
    }
  },
  regressionLineEnabled: {
    parse: (v) => {
      if (v == null) {
        return false
      }
      return getBooleanFromStringOrBool(v)
    }
  },
  useDilutedGrowth: {
    parse: getBooleanFromStringOrBool
  }

};




export const createAddCustomAxesSizeToValuationChartQuery = ({history, searchProps}) => {
    return (fieldName, selectedAxesSpec) => {
        return (event, data) => {
            if (data.value == null || data.value === '') {
                selectedAxesSpec[fieldName] = null;
            } else {
                selectedAxesSpec[fieldName] = data.value;
            }
            searchProps.axesSpec = selectedAxesSpec;
            history.push(history.location.pathname + qs.stringify(searchProps, {addQueryPrefix: true}));
        };
    };

};
