import {getTableClassName} from "../../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {headerRowHeight, rowHeaderWidth, rowHeight} from "../../../../components/companyFinancials/common";
import {getWindowHeightLessMenuBar} from "../../../../common/common";
import {ModelRowRenderer} from "../../../../components/rdg/rdgComponents";
import React from "react";
import {createRow, HeaderContentRenderer, LeftAlignHeaderRenderer} from "../../../../components/rdg/helpers";
import {formatNoDecimal, formatSharePrice} from "../../../../common/format";
import {formatSimpleDate} from "../../../../common/formatDate";


const columnHeaderFieldName = 'as_of_date';
const financialsCellWidth = 90;

export default ({company, doradoValuationRecord, loading}) => {

    const records = [doradoValuationRecord]

    const labelRow = [
        {
            key: 'rowHeader',
            name: '',
            width: rowHeaderWidth,
            frozen: true,
            headerRenderer: <LeftAlignHeaderRenderer/>
        }
    ].concat(
        records.map(record => {
            return {
                key: record.as_of_date,
                name: formatSimpleDate(record.as_of_date),
                width: financialsCellWidth,
                headerRenderer: <HeaderContentRenderer/>
            }

        })
    );

    const priceRows = [
        createRow({
            headerText: '$/share - trading',
            records: records,
            getCellValue: record => record.entry_price_per_share_trading,
            formatValue: formatSharePrice,
            columnHeaderFieldName
        }),

    ];
    if (company.trading_currency !== company.filing_currency) {
        priceRows.push(createRow({
            headerText: '$/share - filing',
            records: records,
            getCellValue: record => record.entry_price_per_share_filing,
            formatValue: formatSharePrice,
            columnHeaderFieldName
        }),)
    }
    const dataRows = [
        ...priceRows,
        createRow({
            headerText: 'Basic Shares',
            records: records,
            getCellValue: record => record.entry_shares_ending,
            formatValue: formatNoDecimal,
            indent: 2,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Unvested RSUs',
            records: records,
            getCellValue: record => record.entry_unvested_rsus_ending,
            formatValue: formatNoDecimal,
            indent: 2,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Option Dilution',
            records: records,
            getCellValue: record => record.entry_option_treasury_method_dilution,
            formatValue: formatNoDecimal,
            indent: 2,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Net Convertible Debt Dilution',
            records: records,
            getCellValue: record => record.entry_convertible_debt_net_dilution,
            formatValue: formatNoDecimal,
            indent: 2,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Diluted Shares',
            records: records,
            getCellValue: record => record.entry_diluted_shares,
            formatValue: formatNoDecimal,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Market Value',
            records: records,
            getCellValue: record => record.entry_market_value_filing,
            formatValue: formatNoDecimal,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: '(-) Cash',
            records: records,
            getCellValue: record => record.entry_cash,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: '(+) Convertible Debt',
            records: records,
            getCellValue: record => record.entry_convertible_debt,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: '(+) Non-Convertible Debt',
            records: records,
            getCellValue: record => record.entry_non_convertible_debt,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: '(-) Other Net Liabilities',
            records: records,
            getCellValue: record => record.entry_other_bs,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            indent: true,
        }),
        createRow({
            headerText: 'EV',
            records: records,
            getCellValue: record => record.entry_ev_filing,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
            underline: true,
        }),
    ]



    return (
        <div className={getTableClassName(loading) + ' align-right'}>

            <ReactDataGrid
                columns={labelRow}
                rowGetter={index => dataRows[index]}
                rowsCount={dataRows.length}
                headerRowHeight={headerRowHeight}
                rowHeight={rowHeight}
                minHeight={320}
                rowRenderer={ModelRowRenderer}
            />

        </div>
    )


}