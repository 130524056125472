import moment from 'moment';


export const parseMillions = (value) => {

  value = parseFloatFromValue(value);
  if (value == null) {
    return null
  }

  return value * 1000

};



export const parseFloatFromValue = (value) => {

  value = parseFloat(value);

  if (isNaN(value)) {
    return null
  }
  return parseFloat(value)
};


export const parseString = (value) => {
  if (value == null) {
    return null
  }
  return value
}


export const parseGteYearToDate = (value) => {

  const year = parseYear(value);
  if (year == null) {
    return null
  }
  return moment({year: year, months: 1, days: 1}).format()

};

export const parseLteYearToDate = (value) => {

  const year = parseYear(value);
  if (year == null) {
    return null
  }
  return moment({year: year, months: 12, days: 31, hours: 23, minutes: 59, seconds: 59}).format()

};




const parseYear = (value) => {
  if (value == null) {
    return null
  }
  if (value.length !== 4) {
    return null
  }
  value = parseInt(value);

  if (isNaN(value)) {
    return null
  }
  return value


};
