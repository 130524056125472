import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {fetchDoradoUniverseValuationRecords} from "../../../features/dorado/doradoValuationRecords";
import UniverseChart from "./UniverseChart";
import {chartOptions, fieldSpecs, chartSpecByName} from "./common";
import {createAddValueToQuery} from "../../../common/query/add";



export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        doradoUniverseValuationRecords,
        loadingDoradoUniverseValuationRecords
    } = useSelector(state => state.doradoValuation, shallowEqual);

    console.log(doradoUniverseValuationRecords)

    useReloadOnChange({
        fieldSpecs,
        queryMatchFields: ['filters', 'sort'],
        requestData: requestDoradoUniverseValuationRecords
    });

    const sidebar = (
        <div>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Header>
                            Dorado Universe
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <Form.Select
                                fluid
                                inline
                                options={chartOptions}
                                onChange={createAddValueToQuery({history, searchProps, fieldName: 'chartName'})}
                                value={searchProps.chartName}
                                label="Chart"
                            />
                        </Form>

                    </Grid.Column>

                </Grid.Row>

            </Grid>
        </div>
    );

    const chartSpec = chartSpecByName[searchProps.chartName];
    return (
        <ViewWithSidebar mainView={<UniverseChart doradoUniverseValuationRecords={doradoUniverseValuationRecords}
                                                  loading={loadingDoradoUniverseValuationRecords}
                                                  chartSpec={chartSpec}

        />}
                         sidebarView={sidebar}/>
    )

}

const requestDoradoUniverseValuationRecords = ({dispatch}) => {

    dispatch(fetchDoradoUniverseValuationRecords({
        sort: {
            as_of_datetime: 1,
        },
        filters: {
            is_valid: true
        }
    }));
}


