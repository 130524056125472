import React, {useEffect} from 'react'
import {Divider, Grid, Table} from "semantic-ui-react";
import {
    formatNoDecimalNoNull,
    formatPercentOneDecimalNoNull,
    formatPercentTwoDecimalNoNull
} from "../../../common/format";
import {getTableClassName} from "../../../common/reactDataGrid/common";


const rowSpecs = [
    {
        name: 'Long Securities',
        field: 'securities_long_market_value',
        formatter: formatNoDecimalNoNull
    },
    {
        name: 'Short Securities',
        field: 'securities_short_market_value',
        formatter: formatNoDecimalNoNull
    },
    {
        name: 'Cash',
        field: 'cash',
        formatter: formatNoDecimalNoNull
    },
    {
        name: 'Cash - Short Collateral',
        field: 'cash_encumbered',
        formatter: formatNoDecimalNoNull
    },
    {
        name: 'Cash - Unencumbered',
        field: 'cash_unencumbered',
        formatter: formatNoDecimalNoNull
    },
    {
        name: 'Net Asset Value',
        field: 'net_asset_value',
        formatter: formatNoDecimalNoNull
    },
    {
        name: 'Long Securities % NAV',
        field: 'securities_long_market_value_percent',
        formatter: formatPercentTwoDecimalNoNull
    },
    {
        name: 'Short Securities % NAV',
        field: 'securities_short_market_value_percent',
        formatter: formatPercentTwoDecimalNoNull
    },
    {
        name: 'Cash % NAV',
        field: 'cash_percent',
        formatter: formatPercentTwoDecimalNoNull
    },


]


export default ({priorDayPortfolioState,
                 beginningPortfolioState,
                 endingPortfolioState,
                 loading
                }) => {

    const style = {};
    if (loading) {
        style.color = 'grey';
    }

    if (beginningPortfolioState == null || priorDayPortfolioState == null) {
        return <div/>
    }

    const rows = rowSpecs.map(spec => {

        const priorDay = priorDayPortfolioState[spec.field];
        const beginning = beginningPortfolioState[spec.field];
        const ending = endingPortfolioState[spec.field];
        const change = ending - beginning;

        return (
            <Table.Row key={spec.name}>
                <Table.Cell textAlign={'left'}>
                    {spec.name}
                </Table.Cell>
                <Table.Cell>
                    {spec.formatter(priorDay)}
                </Table.Cell>
                <Table.Cell>
                    {spec.formatter(beginning)}
                </Table.Cell>
                <Table.Cell>
                    {spec.formatter(change)}
                </Table.Cell>
                <Table.Cell>
                    {spec.formatter(ending)}
                </Table.Cell>
            </Table.Row>
        )
    })


    return (
        <React.Fragment>
            <Divider horizontal>
                Portfolio Bridge
            </Divider>
            <Table textAlign={'right'} compact={'very'} style={style}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>
                            Prior Day
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Beginning
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Change
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Ending
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        </React.Fragment>
    )
}