import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Button, Container, Divider, Form, Grid, Header, Table} from 'semantic-ui-react'
import {useReloadOnChange} from "../../../../common/hooks";
import CompanyHeader from "../../../../components/CompanyHeader";
import {
    fetchDoradoBacktestValuationComparisonForCompany, fetchDoradoForecastMetadatasForCompany
} from "../../../../features/dorado/doradoCompanyDetail";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import ViewWithSidebar from "../../../../components/ViewWithSidebar";
import RdgTable from "../../../../components/rdg/RdgTable";
import ComparisonTable from "./ComparisonTable";
import moment from "moment";
import {formatFullDateTimeUtcToLocal} from "../../../../common/formatDate";
import {fetchTargetPriceRecordsForCompany} from "../../../../actions/companyNoteActions";
import {CurrencyType, ForecastType} from "../../../../common/constants";
import {formatFullDate, formatSharePrice} from "../../../../common/format";
import {createAddCheckedValueToQuery} from "../../../../common/query/add";
import {extendedBacktestFieldSpecs, ShowExtendedBacktestCheckbox} from '../../../dorado/companyViews/common'
import {fetchCompany} from "../../../../features/companyFeatureSlice";


const fieldSpecs = {
    ...extendedBacktestFieldSpecs
}


export default () => {

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: [],
        matchFields: ['id'],
        requestData: requestCompanyData
    });

    const {
        company,
        loadingCompany
    } = useSelector(state => state.company, shallowEqual);

    const {
        doradoBacktestValuationComparisons,
        loadingDoradoBacktestValuationComparisons
    } = useSelector(state => state.doradoCompanyDetail, shallowEqual);

    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const commentaryMetadatas = doradoBacktestValuationComparisons.filter(r => r.notes != null).reverse();

    const commentaryRows = commentaryMetadatas.map(record => {
        return (
            <Table.Row key={record.id}>
                <Table.Cell>
                    {formatFullDate(record.as_of_standard_date, false)}
                    <br/>
                    {record.description}
                </Table.Cell>
                <Table.Cell>
                    {record.notes}
                </Table.Cell>
            </Table.Row>
        )
    });

    const sidebar = (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <CompanyHeader company={company} loading={loadingCompany}/>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    <Header>Backtest Model Notes</Header>
                    <Table compact className='align-left' loading={loadingDoradoBacktestValuationComparisons.toString()}>
                        <Table.Header className="align-center">
                            <Table.Row>
                                <Table.HeaderCell>Model</Table.HeaderCell>
                                <Table.HeaderCell>Notes</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {commentaryRows}
                        </Table.Body>

                    </Table>
                </Grid.Column>


            </Grid.Row>

        </Grid>
    );

    return (
        <ViewWithSidebar mainView={<ComparisonTable records={doradoBacktestValuationComparisons}
                                                    loading={loadingDoradoBacktestValuationComparisons}/>}
                         sidebarView={sidebar}/>
    )

}


const requestCompanyData = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    dispatch(fetchCompany({companyId: id}));
    dispatch(fetchDoradoBacktestValuationComparisonForCompany({companyId: id}))

};
