import React, {useEffect} from 'react';
import {Button, Form, Grid, Header, Icon, Statistic, List} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {fetchDoradoBacktestAuditRecords} from "../../../features/dorado/doradoBacktestAuditRecords";
import DoradoSidebar from "../CommonFilters";
import RdgTable from "../../../components/rdg/RdgTable";
import {columns} from './columns'
import {ForecastType} from "../../../common/constants";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {records, loading} = useSelector(state => {return state.doradoBacktestAudit}, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>

            <Grid columns={1} relaxed>
                <Grid.Row centered>
                    <Statistic horizontal value={records.length} label='Records' size="tiny"/>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Header>Audit Checklist</Header>
                        <List ordered>
                            <List.Item>
                                <List.Header>
                                    High PS Coefficient of Variation
                                </List.Header>
                                <List.Description>
                                    Investigate companies with high variance in Point Sur valuation over time.  Did
                                    we dramatically change our forecast?  Did Dorado dramatically change the multiple?
                                    Is there an error?
                                </List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Header>
                                    Significant Change in Cumulative FCF
                                </List.Header>
                                <List.Description>
                                    Our FCF forecast should typically not change a material amount relative to a company's
                                    market cap.  These are typically errors.
                                </List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Header>
                                    Significant Change in Exit Shares
                                </List.Header>
                                <List.Description>
                                    Dilution should be stable between forecasts.
                                </List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Header>
                                    Significant Change in Max Margin
                                </List.Header>
                                <List.Description>
                                    This is calculated from Dorado.  If there is a significant change, this may be caused by a
                                    change in our forecast for gross margin, retention or sales efficiency.
                                </List.Description>
                            </List.Item>
                        </List>
                    </Grid.Column>


                </Grid.Row>
            </Grid>

        </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns} records={records} loading={loading}/>} sidebarView={sidebar}/>
    )

}


const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    dispatch(fetchDoradoBacktestAuditRecords({sort, filters}));

};