import React from 'react';
import {
    NoDecimalNoNullOrZeroFormatter,
    PercentOneDecimalNoNullFormatter,
    SharePriceFormatter
} from "../../../common/reactDataGrid/formatters";
import {CompanyBacktestValuationsLinkFormatter} from "../common";
import {Link} from "react-router-dom";



class BacktestValuationComparisonsLinkFormatter extends React.Component {
    render() {
        return <Link to={'/company_detail/dorado_company_valuation_comparisons/' + this.props.value.company_id}>
            <div className="align-right">
                {this.props.value.models_count}
            </div>
        </Link>
    }
}


export const columns = [
    {
        key: 'company_name',
        name: 'Name',
        width: 200,
        frozen: true,
        sortable: true,
        formatter: CompanyBacktestValuationsLinkFormatter,
        _getValueFromRecord: (r) => {
            return {
                company_name: r.company.company_name,
                company_id: r.company.id
            }
        }
    },
    {
        key: 'record_count',
        name: 'Record Count',
        width: 90,
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'ps_relative_coefficient_of_variation',
        name: 'PS Dorado Variance vs. Stock Price',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'share_price_coefficient_of_variation',
        name: 'Share Price Coeff. Var.',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'discounted_exit_value_coefficient_of_variation',
        name: 'PS Backtest Buy Price Coeff. Var.',
        width: 90,
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter
    },
    {
        key: 'models_count',
        name: 'Models Count',
        width: 90,
        sortable: true,
        formatter: BacktestValuationComparisonsLinkFormatter,
        _getValueFromRecord: (r) => {
            return {
                models_count: r.models_count,
                company_id: r.company_id
            }
        }
    },
    {
        key: 'significant_exit_ntm_revenue_change_count',
        name: 'Significant Change Exit Revenue',
        width: 90,
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'significant_exit_shares_change_count',
        name: 'Significant Change Exit Shares',
        width: 90,
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'significant_exit_max_margin_change_count',
        name: 'Significant Change Max Margin',
        width: 90,
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'significant_exit_max_fcf_multiple_change_count',
        name: 'Significant Change Exit Max FCF Multiple',
        width: 90,
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'significant_cumulative_fcf_change_count',
        name: 'Significant Change Cumulative FCF',
        width: 90,
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter
    },






    // {
    //     key: 'share_price_standard_deviation',
    //     name: 'Share Price Std. Dev.',
    //     width: 90,
    //     sortable: true,
    //     formatter: SharePriceFormatter
    // },
    // {
    //     key: 'discounted_exit_value_standard_deviation',
    //     name: 'Backtest Price Std. Dev.',
    //     width: 90,
    //     sortable: true,
    //     formatter: SharePriceFormatter
    // },

];



