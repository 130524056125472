import React, {useEffect} from 'react';
import {Button, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {
    fetchDoradoValuationRecords,
    fetchPointSurDoradoValuationRecords
} from '../../../features/dorado/doradoValuationRecords'
import DoradoSidebar from "../CommonFilters";
import {chartSpecByName, chartSpecifications} from './chartSpecs'
import {createAddValueToQuery} from "../../../common/query/add";
import {fieldSpecs} from './common'
import IrrAuditChart from './IrrAuditChart'


const chartOptions = chartSpecifications.map(spec => {
    return {
        key: spec.name,
        value: spec.name,
        text: spec.name
    }
});


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {doradoValuationRecords, loadingDoradoValuationRecords} = useSelector(state => {
        return {
            doradoValuationRecords: state.doradoValuation.records,
            loadingDoradoValuationRecords: state.doradoValuation.loading
        }
    }, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const chartSpec = chartSpecByName[searchProps.chartName];

    const sidebar = (
        <div>

            <Header>Filters</Header>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={doradoValuationRecords.length} label='Records' size="tiny"/>
                </Grid.Row>
            </Grid>
            <Form>
                <Form.Select
                    fluid
                    inline
                    options={chartOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'chartName'})}
                    value={searchProps.chartName}
                    label="Chart"
                />
                <p>
                    {chartSpec.logicText}
                </p>
            </Form>
            <br/>
            <DoradoSidebar/>
        </div>
    );

    const mainView = <IrrAuditChart doradoValuationRecords={doradoValuationRecords}
                                    chartName={searchProps.chartName}
    />;

    return (
        <ViewWithSidebar mainView={mainView} sidebarView={sidebar}/>
    )

}


const requestData = ({dispatch, searchProps}) => {
    const sort = {};
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    dispatch(fetchPointSurDoradoValuationRecords({sort, filters}));

};