import {createAddCheckedValueToQuery} from "../../../common/query/add";
import {Form} from "semantic-ui-react";
import React from "react";
import {parseBoolean} from "../../../common/query/parse";


export const ShowExtendedBacktestCheckbox = ({history, searchProps}) => {
    return <Form.Checkbox label="Show Extended Backtest"
                          checked={searchProps.showExtendedBacktest}
                          onChange={createAddCheckedValueToQuery({
                              history,
                              searchProps,
                              fieldName: 'showExtendedBacktest'
                          })}
    />
}


export const extendedBacktestFieldSpecs = {
    showExtendedBacktest: {
        parse: parseBoolean
    }
};