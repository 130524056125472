const defaultState = {

  analysisResult: {
    major_cost_centers: [],
    total: {
      count: 0,
      percentage: 1
    },
    major_cost_centers_total: {
      count: 0,
      percentage: 1
    }
  },
  loading: true,
};

export default (state=defaultState, action={}) => {
  switch (action.type) {

    case "FETCH_ANALYSIS_FOR_SCRAPE_PENDING": {

      return {
        ...state,
        loading: true
      }
    }

    case "FETCH_ANALYSIS_FOR_SCRAPE_FULFILLED": {

      return {
        ...state,
        analysisResult: action.payload.data,
        loading: false
      }
    }

    default:
      return state
  }
}
