export const sortDirectionByReactDataGridSortDirection = {
    'ASC': 1,
    'DESC': -1,
    'NONE': null
};

export const reactDataGridSortDirectionBySortDirection = {
    '1': 'ASC',
    '-1': 'DESC',
    'null': 'NONE'
};

export const getMostRecentSortColumnAndDirectionForRDG = (props) => {

    const sort = props.sort || [];

    if (sort.length === 0) {
        return {
            sortColumn: null,
            sortDirection: null
        };
    }

    const [sortColumn, rdgSortDirection] = sort[sort.length - 1];
    const sortDirection = reactDataGridSortDirectionBySortDirection[String(rdgSortDirection)];

    return {sortColumn, sortDirection};
};


export const makeHandleReactDataGridSort = (onChangeSort) => {
  return (sortColumn, sortDirection) => {
      // sortDirection will come in ReactDataGrid's native format.  We should convert and call our own.

      const propsSortDirection = sortDirectionByReactDataGridSortDirection[sortDirection];
      onChangeSort(sortColumn, propsSortDirection);

    };
};


export const getRdgRowsFromRecords = ({records, columns}) => {
  // works for simple table scenarios where not complex mapping


  const headerSpecs = columns.map(record => {
    const defaultGetValue = (r, key) => {return r[record.key]};
    return {
      key: record.key,
      getValue: record._getValueFromRecord || defaultGetValue
    }
  });


  return records.map(record => {


      return headerSpecs.reduce((obj, spec) => {

      let value = spec.getValue(record, record.key);
      if (value === undefined) {
        value = null;
      }
      obj[spec.key] = value;

      return obj
    }, {});
  });

};


export const getTableClassName = (loading) => {
    let className = 'financials-size';
    if (loading) {
        className += ' loading-grid'
    }

    return className
};

export const boolOptions = [
    {
        key: '1',
        text: 'True',
        value: true
    },
    {
        key: '2',
        text: 'False',
        value: false
    },
];


export const boolAndNullOptions = [
    {
        key: '3',
        text: null,
        value: null
    },
    {
        key: '1',
        text: 'True',
        value: true
    },
    {
        key: '2',
        text: 'False',
        value: false
    },
];