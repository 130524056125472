import React from 'react'
import {Icon, Button, Table, Header} from 'semantic-ui-react'
import {formatFullDate, formatSharePrice} from '../../../common/format'
import {shallowEqual, useSelector} from "react-redux";
import {parseSearchProps} from "../../../common/query/parse";
import {parseBoolean} from "../../../common/query/parse";
import {createSetFieldValueOnQuery} from '../../../common/query/add';
import {useLocation, useHistory} from "react-router-dom"


const unexpandedLength = 4;

const fieldSpecs = {
    isExpanded: {
        parse: parseBoolean
    }
};


export default () => {

    const history = useHistory();
    const location = useLocation();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {targetPriceRecords, loadingTargetPriceRecords} = useSelector(state => state.companyStore, shallowEqual);

    let records = targetPriceRecords;
    if (!searchProps.isExpanded) {
        records = records.slice(0, unexpandedLength);
    }

    const rows = records.map(record => {
        return (
            <Table.Row key={record.id}>
                <Table.Cell>
                    {formatFullDate(record.last_modified, true)}
                </Table.Cell>
                <Table.Cell>
                    {formatSharePrice(record.buy_price)}
                </Table.Cell>
                <Table.Cell>
                    {formatSharePrice(record.akkr_coverage_buy_price)}
                </Table.Cell>

                <Table.Cell>
                    {record.currency}
                </Table.Cell>
                <Table.Cell>
                    {record.rationale}
                </Table.Cell>
                <Table.Cell>
                    {record.user_name}
                </Table.Cell>

            </Table.Row>
        )
    });

    let footer = null;
    if (!searchProps.isExpanded) {
        if (targetPriceRecords.length > unexpandedLength) {
            footer = (
                <Table.Footer fullWidth>
                    <Table.Row key='expand'>
                        <Table.HeaderCell colSpan={8}>
                            <Button icon size='tiny' onClick={createSetFieldValueOnQuery({
                                history,
                                searchProps,
                                fieldName: 'isExpanded',
                                value: true
                            })}>
                                <Icon name='plus'/>
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            )
        }
    }

    return (
        <div>
            <span>
              <Header as='h4' key='header'>
                History
              </Header>
            </span>
            <Table compact className='align-left' key='tbl' loading={loadingTargetPriceRecords.toString()}>
                <Table.Header className="align-center">
                    <Table.Row>
                        <Table.HeaderCell>Date Set</Table.HeaderCell>
                        <Table.HeaderCell>Buy Price</Table.HeaderCell>
                        <Table.HeaderCell>AKKR Buy Price</Table.HeaderCell>
                        <Table.HeaderCell>Currency</Table.HeaderCell>
                        <Table.HeaderCell>Rationale</Table.HeaderCell>
                        <Table.HeaderCell>User</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rows}
                </Table.Body>
                {footer}
            </Table>
        </div>
    )

}
