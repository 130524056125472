import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic, Table} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";

import {fieldSpecs, getDailyAlphaSeries} from './common'
import {useReloadOnChange} from "../../../common/hooks";
import {
    fetchPortfolios,
    fetchPortfolioStateWithReports
} from "../../../features/portfolio/portfolioFeatureSlice";
import Chart from "./Chart";
import PortfolioSelect from "../../dorado/PortfolioSelect";
import {createAddValueToQuery} from "../../../common/query/add";
import TermFilter from "../../../components/filter/TermFilter";
import {showAlphaOptions} from "../returnsChart/common";



export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        portfolios,
        loadingPortfolios,
        portfolioStateWithReports,
        loadingPortfolioStateWithReports,

    } = useSelector(state => state.portfolio, shallowEqual);

    useEffect(() => {
        const sort = {
            type: 1,
            is_backtest: 1
        }

        const filters = {
            type: {
                $in: [
                    'Point Sur',
                    'Special Opps V',
                    'Dorado',
                    'Backtest Sensitivity',
                    'Backtest Benchmark'
                ]
            },
        }

        dispatch(fetchPortfolios({sort, filters}))

    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort', 'portfolio_id'],
        requestData: refreshData
    });

    if (searchProps.portfolio_id == null) {
        if (!loadingPortfolios && portfolios.length > 0) {
            return <Redirect to={location.pathname + "?portfolio_id=" + portfolios[0].id}/>
        }
    }

    const sidebar = (
        <div>
            <br/>
            <Form>
                <PortfolioSelect/>
                <TermFilter allowNullSelection={true}
                            label='Show Long vs Short Return'
                            options={showAlphaOptions}
                            value={searchProps.showAlpha}
                            onChange={
                                createAddValueToQuery({
                                history,
                                searchProps,
                                fieldName: 'showAlpha'
                            })}
                />
                <Divider horizontal>Definitions</Divider>
            <Table basic='very' collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Net Return
                        </Table.Cell>
                        <Table.Cell>
                            Daily portfolio NAV returns chained together.  Assumes entire portfolio funded up-front
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Long Positions TWR
                        </Table.Cell>
                        <Table.Cell>
                            Time weighted return of long positions (no cash drag).
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Short Positions TWR
                        </Table.Cell>
                        <Table.Cell>
                            Time weighted return of short positions
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>


            </Form>

        </div>
    );

    const alphaSeries = getDailyAlphaSeries({portfolioStates: portfolioStateWithReports})

    return (
        <ViewWithSidebar mainView={<Chart portfolioStates={portfolioStateWithReports}
                                          loading={loadingPortfolioStateWithReports}
                                          alphaSeries={alphaSeries}
                                          showAlpha={searchProps.showAlpha}

        />}
                         sidebarView={sidebar}/>

    )

}


const refreshData = ({dispatch, searchProps}) => {

    const sort = {
        standard_date: 1
    }

    const filters = {
        portfolio_id: searchProps.portfolio_id,
        is_end_of_day: true,
    }

    if (searchProps.portfolio_id != null && searchProps.portfolio_id !== "") {
        dispatch(fetchPortfolioStateWithReports({sort, filters}));

    }

}
