import React, {useEffect} from 'react';
import {
    CompanyLinkFormatter,
    PercentOneDecimalNoNullFormatter,
    PercentTwoDecimalNoZeroFormatter,
    NoDecimalNoNullOrZeroFormatter,
    SharePriceFormatter,
    TwoDecimalNoNullFormatter,
    TwoDecimalCurrencyFormatter,
} from "../../../common/reactDataGrid/formatters";
import {companyColumnFromObject} from "../../../common/reactDataGrid/columns";
import {CurrencySharePriceFormatter, TradeTypeFormatter} from "../../../common/columns";
import {companyOrEtfBacktestLinkColumn} from "../../dorado/common";


export const tradeColumns = [
    companyOrEtfBacktestLinkColumn,
    {
        key: 'trade_type',
        name: 'Trade Type',
        width: 70,
        // formatter: TradeTypeFormatter,
        // _getValueFromRecord: (r) => {
        //     return {
        //         trade_type: r.trade_type,
        //         is_short_sale_or_cover: r.is_short_sale_or_cover
        //     }
        // }
    },
    {
        key: 'last_price_per_share',
        name: 'Price $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.price_per_share_ex_hard_transaction_costs,
                trading_currency: r.currency
            }
        }
    },
    {
        key: 'shares',
        name: 'Shares',
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter
    },

    {
        key: 'gross_value_before_transaction_cost_usd',
        name: 'Gross $USD before trans. cost.',
        width: 150,
        formatter: TwoDecimalCurrencyFormatter
    },
    {
        key: 'net_value_usd',
        name: 'Net $USD',
        width: 150,
        formatter: TwoDecimalCurrencyFormatter
    },
    {
        key: 'hard_transaction_cost_percent',
        name: 'Hard Trans. Cost %',
        width: 100,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'soft_transaction_cost_percent',
        name: 'Soft Trans. Cost %',
        width: 100,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'hard_transaction_cost_per_share',
        name: 'Hard Trans. Cost $/share',
        width: 100,
        formatter: TwoDecimalCurrencyFormatter
    },
    {
        key: 'soft_transaction_cost_per_share',
        name: 'Soft Trans. Cost $/share',
        width: 100,
        formatter: TwoDecimalCurrencyFormatter
    },
    {
        key: 'hard_transaction_cost',
        name: 'Hard Trans. Cost $',
        width: 100,
        formatter: TwoDecimalCurrencyFormatter
    },
    {
        key: 'soft_transaction_cost',
        name: 'Soft Trans. Cost $',
        width: 100,
        formatter: TwoDecimalCurrencyFormatter
    },
];