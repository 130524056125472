import client from '../../benchClient';

const historicalValuationRecordsService = client.service('historical_valuation_records');


export function fetchHistoricalValuationRecordsForCompany({dispatch, companyId}) {

    return dispatch({
        type: 'FETCH_HISTORICAL_VALUATION_RECORDS_FOR_COMPANY',
        payload: historicalValuationRecordsService.find({
            query: {
                company_id: companyId,
                currency_type: 'Trading',
                $sort: {
                    as_of_date: 1
                }
            }

        })
    })
}