import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Statistic, Header, List} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import {fetchCompany, patchShareClassSecurity} from "../../../actions/companyActions";
import {fetchEquityOfferingEventsForCompany, patchEquityOfferingEvent} from "../../../features/eventsFeatureSlice";
import {columns} from "./cols";
import RdgTable from "../../../components/rdg/RdgTable";
import {cleanUpdated} from "../../../components/rdg/helpers";


export default () => {

    const dispatch = useDispatch();
    const {company, loadingCompany} = useSelector(state => state.companyStore, shallowEqual);

    const {equityEvents, loadingEquityEvents} = useSelector(state => state.events, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: [],
        requestData
    });

    const sidebar = (
        <div>
            <Header>{loadingCompany ? null: company.company_name}</Header>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={equityEvents.length} label='Events Count' size="tiny"/>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p>
                            Equity events are autogenerated from CapIQ and commonly contain errors.
                            Before these events can be used, we must fix any errors and sign off that we have validated the record.
                        </p>
                        <p>
                            Key items requiring validation:
                        </p>
                        <List ordered>
                            <List.Item>
                                Shares offered and $/share.  Can include expected/actual greenshoe.
                            </List.Item>
                            <List.Item>
                                % Primary.  % of offering that is primary vs. secondary.
                            </List.Item>
                            <List.Item>
                                Net Primary Proceeds.  The amount of cash (after expenses) actually coming into the company from the offering.
                                Remove cash from sales of secondary shares, and offering expenses.
                            </List.Item>
                            <List.Item>
                                Announce date / close date.
                            </List.Item>
                            <List.Item>
                                Proforma Shares Post-Offering.  Total of all share classes after offering.  This is the
                                expected basic share count immediately after the IPO.
                            </List.Item>

                        </List>
                        <p>Items can be changed by double clicking on the item.
                            If all items are correct, check the Is Validated box.</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );

    const onGridRowsUpdated = ({fromRow, toRow, updated}) => {

        updated = cleanUpdated(updated);

        const event = equityEvents[fromRow];

        dispatch(patchEquityOfferingEvent({id: event.id, patchData: updated})).then(() => {
            dispatch(fetchEquityOfferingEventsForCompany({companyId: event.company_id}));
        });



    };


    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             records={equityEvents}
                                             loading={loadingEquityEvents}
                                             onGridRowsUpdated={onGridRowsUpdated}
        />}
                         sidebarView={sidebar}/>
    )

};

const requestData = ({dispatch, searchProps, matchParams}) => {

    const {id} = matchParams;
    fetchCompany({dispatch, id});

    dispatch(fetchEquityOfferingEventsForCompany({companyId: id}));
};