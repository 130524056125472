import React, {useEffect} from 'react';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import {Container, Header, Form, Input, Icon, Button, List, Grid, Statistic} from 'semantic-ui-react'
import RdgTable from "../../../components/rdg/RdgTable";
import {useReloadOnChange} from "../../../common/hooks";
import ViewWithSidebar from "../../../components/ViewWithSidebar";
import {
    fetchCurrentDoradoTargetData,
    updateDoradoTargetSecurityOrder
} from "../../../features/dorado/doradoTargetPortfolio";
import TargetSidebar from "./TargetSidebar";
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from "../../../common/request/convert";
import {fetchPointSurDoradoValuationRecords} from "../../../features/dorado/doradoValuationRecords";
import {parseSearchProps} from "../../../common/query/parse";
import {useLocation} from "react-router-dom";
import {fetchDoradoPortfolioTradeMetadata} from "../../../features/dorado/doradoPortfolio";
import {getOrderValueMultiplerWithDefault, getRecordsAndColumnsForView} from "./common";
import {fieldSpecs} from './common'
import {startCalculateDoradoTargetPortfolio} from "../../../features/pollingProcessFeatureSlice";
import {usePollForCompletion} from "../../../common/pollingHooks";
import {cleanUpdated} from "../../../components/rdg/helpers";
import {fetchFlexOutstandingUnfilledOrders} from "../../../features/dorado/flex";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        currentDoradoTargetData,
        loadingCurrentDoradoTargetData,
    } = useSelector(state => {return state.doradoTargetPortfolio}, shallowEqual);

    const {
        doradoPortfolioTradeMetadata
    } = useSelector(state => {return state.doradoPortfolio}, shallowEqual);

    useEffect(() => {

        dispatch(fetchDoradoPortfolioTradeMetadata());
        dispatch(fetchFlexOutstandingUnfilledOrders({}));

    }, [dispatch]);

    usePollForCompletion({
        onComplete: refreshData
    });

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData: refreshDoradoPortfolioPositions
    });

    const currentDoradoTargetPortfolioState = currentDoradoTargetData.dorado_target_portfolio_state;
    const doradoTargetPortfolioPositions = currentDoradoTargetData.dorado_target_portfolio_positions;
    const doradoTargetSecurityOrders = currentDoradoTargetData.dorado_target_security_orders;

    let [records, columns] = getRecordsAndColumnsForView({
        viewOption: searchProps.viewOption,
        doradoTargetPortfolioPositions,
        doradoTargetSecurityOrders
    });


    if (searchProps.viewOption === 'Real Orders') {
        const multiplier = getOrderValueMultiplerWithDefault(searchProps.orderValueMultiplier);

        records = records.map(record => {
            const newShares = Math.round(record.shares * multiplier);
            const scaleFactor = newShares / record.shares;

            return {
                ...record,
                shares: newShares,

                gross_value_before_transaction_cost: record.gross_value_before_transaction_cost * scaleFactor,
                hard_transaction_cost: record.hard_transaction_cost * scaleFactor,
                net_value: record.net_value * scaleFactor,

                gross_value_before_transaction_cost_usd: record.gross_value_before_transaction_cost_usd * scaleFactor,
                hard_transaction_cost_usd: record.hard_transaction_cost_usd * scaleFactor,
                net_value_usd: record.net_value_usd * scaleFactor,

            }

        })

    }

    const sidebar = <TargetSidebar currentDoradoTargetPortfolioState={currentDoradoTargetPortfolioState}
                                   loadingCurrentDoradoTargetPortfolioState={loadingCurrentDoradoTargetData}
                                   doradoPortfolioTradeMetadata={doradoPortfolioTradeMetadata}
                                   doradoTargetSecurityOrders={searchProps.viewOption === 'Real Orders' ? records : doradoTargetSecurityOrders}
                                   onStartProcess={() => dispatch(startCalculateDoradoTargetPortfolio({}))}
                                   onStartLongCalc={() => dispatch(startCalculateDoradoTargetPortfolio({override_enable_short: false}))}
                                   onStartShortCalc={() => dispatch(startCalculateDoradoTargetPortfolio({override_enable_long: false}))}

    />;

    const onGridRowsUpdated = ({fromRow, toRow, updated}) => {

        updated = cleanUpdated(updated);

        const record = records[fromRow];

        for (let key of Object.keys(updated)) {
            record[key] = updated[key];
        }

        dispatch(updateDoradoTargetSecurityOrder(record));

    }

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             loading={loadingCurrentDoradoTargetData}
                                             records={records}
                                             onGridRowsUpdated={onGridRowsUpdated}

        />}
                         sidebarView={sidebar}
        />
    )

}

const refreshData = ({dispatch, searchProps}) => {

    refreshDoradoPortfolioPositions({dispatch, searchProps});
    dispatch(fetchDoradoPortfolioTradeMetadata());
    dispatch(fetchFlexOutstandingUnfilledOrders({}));
}


const refreshDoradoPortfolioPositions = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {}
    });

    dispatch(fetchCurrentDoradoTargetData({sort, filters}));
}

