import React from 'react';
import {createAppendSortToQuery} from '../../../common/query/add';
import {
    getMostRecentSortColumnAndDirectionForRDG,
    getRdgRowsFromRecords,
    makeHandleReactDataGridSort
} from "../../../common/reactDataGrid/common";
import {parseSearchProps} from "../../../common/query/parse";
import ReactDataGrid from 'react-data-grid';
import {getWindowHeightLessMenuBar} from '../../../common/common'
import { useLocation, useHistory } from "react-router-dom"
import {
    BoolToYesCheckFormatter,
    NoDecimalNoNullOrZeroFormatter,
    UtcDateFormatter,
    BoolToYesNoFormatter,
    PercentNoDecimalNoNullFormatter,
    MultipleTwoDecimalFormatter
} from "../../../common/reactDataGrid/formatters";
import {AkkrCompanyLinkFormatter} from "../common";
import { shallowEqual, useSelector } from 'react-redux'
import {getTableClassName} from '../../../common/reactDataGrid/common'
import {CurrencyFormatter} from "../companiesTable/currencyFormatter";
import {RevenueCompositionMappingByDbField} from '../../../common/constants'
import {getFilteredColumns} from "../../../components/showHideColumns/common";
import {columnGroups} from "./columnGroups";


const colSpecsFromObject = (obj) => {
    return Object.keys(obj).reduce((list, dbFieldName) => {

        list.push({
            key: dbFieldName,
            name: obj[dbFieldName],
            sortable: true,
            formatter: NoDecimalNoNullOrZeroFormatter,
            width: 100,
        });
        return list
  }, []);
};


export const tableColumns = [
    {
        key: 'short_name',
        name: 'Name',
        width: 200,
        frozen: true,
        sortable: true,
        formatter: AkkrCompanyLinkFormatter,
        _getValueFromRecord: (r) => {
            return {
                name: r.name,
                akkr_company_id: r.id
            }
        }
    },
    {
        key: 'is_active_investment',
        name: 'Is Active Investment?',
        sortable: true,
        formatter: BoolToYesCheckFormatter,
        width: 90
    },
    {
        key: 'last_reported_period_end_date',
        name: 'Last Reported Period Date',
        sortable: true,
        formatter: UtcDateFormatter,
        width: 80
    },
    {
        key: 'last_reported_period',
        name: 'Last Reported Period',
        sortable: true,
        width: 80
    },
    {
        key: 'is_buyout',
        name: 'Buyout Fund',
        sortable: true,
        formatter: BoolToYesCheckFormatter,
        width: 70
    },
    {
        key: 'is_growth',
        name: 'Growth Fund',
        sortable: true,
        formatter: BoolToYesCheckFormatter,
        width: 70
    },
    {
        key: 'is_credit',
        name: 'Credit Fund',
        sortable: true,
        formatter: BoolToYesCheckFormatter,
        width: 70
    },
    {
        key: 'filing_currency',
        name: 'Filing Currency',
        sortable: true,
        width: 90,

    },
    {
        key: 'filing_exchange_rate_to_usd',
        name: 'Filing Exch. Rt. to USD',
        sortable: true,
        width: 90,
        formatter: CurrencyFormatter
    },
    {
        key: 'fiscal_year_end_month',
        name: 'Fiscal Year End Month',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 50
    },
    {
        key: 'currency_type',
        name: 'Currency Type',
        sortable: true,
        width: 70
    },
    {
        key: 'is_calendarized',
        name: 'Is Calendarized',
        formatter: BoolToYesNoFormatter,
        sortable: true,
        width: 100
    },
    {
        key: 'is_ltm',
        name: 'Is LTM',
        formatter: BoolToYesNoFormatter,
        sortable: true,
        width: 70
    },
    {
        key: 'fiscal_year',
        name: 'Fiscal Year',
        sortable: true,
        width: 70
    },
    {
        key: 'period_end_date',
        name: 'Period End Date',
        formatter: UtcDateFormatter,
        sortable: true,
        width: 100
    },
    {
        key: 'fiscal_period_end',
        name: 'Fiscal Period End',
        sortable: true,
        width: 100
    },
    {
        key: 'period_type',
        name: 'Period Type',
        sortable: true,
        width: 100
    },

    ...colSpecsFromObject(RevenueCompositionMappingByDbField),
    {
        key: 'revenue_total',
        name: 'Total Revenue',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 100,
    },

    {
        key: 'bookings_to_sales_and_marketing',
        name: 'Bookings to S&M',
        sortable: true,
        formatter: MultipleTwoDecimalFormatter,
        width: 100,
    },
    {
        key: 'bookings_recurring_to_sales_and_marketing',
        name: 'Recurring Bookings to S&M',
        sortable: true,
        formatter: MultipleTwoDecimalFormatter,
        width: 100,
    },

    {
        key: 'bookings_total',
        name: 'Total Bookings',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 100,
    },
    {
        key: 'bookings_recurring',
        name: 'Recurring Bookings',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 100,
    },
    {
        key: 'sales_and_marketing',
        name: 'S&M',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 100,
    },
    {
        key: 'sales_and_marketing_margin',
        name: 'S&M % Rev',
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 100,
    },
    {
        key: 'revenue_growth',
        name: 'Revenue Growth',
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 100,
    },
    {
        key: 'revenue_dollar_growth',
        name: 'Revenue Dollar Growth',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 100,
    },
    {
        key: 'revenue_recurring_growth',
        name: 'Recurring Growth',
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 100,
    },
    {
        key: 'revenue_recurring_dollar_growth',
        name: 'Recurring Revenue Dollar Growth',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 100,
    },



];


export default () => {

    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {records, loading} = useSelector(state => state.akkrTableStore, shallowEqual);

    const onChangeSort = createAppendSortToQuery({history, searchProps: searchProps});
    const handleReactDataGridSort = makeHandleReactDataGridSort(onChangeSort);
    const {sortColumn, sortDirection} = getMostRecentSortColumnAndDirectionForRDG(searchProps);

    const columns = getFilteredColumns({
        columns: tableColumns,
        columnGroupName: searchProps.columnGroup,
        columnGroups
    });

    const rows = getRdgRowsFromRecords({records, columns});

    return (

        <div className={getTableClassName(loading)}>

            <ReactDataGrid
                columns={columns}
                onGridSort={handleReactDataGridSort}
                rowGetter={index => rows[index]}
                rowsCount={rows.length}
                rowHeight={22}
                headerRowHeight={100}
                minHeight={getWindowHeightLessMenuBar()}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            />

        </div>

    )


};
