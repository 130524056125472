import client from '../../benchClient';

const akkrFinancialsService = client.service('akkr_data_records');
const akkrDataFacetsService = client.service('akkr_data_records_facets');
const akkrDataExportService = client.service('download_akkr_data_records');


export function fetchAkkrTableRecords({dispatch, sort, filters}) {

    dispatch({
        type: 'FETCH_AKKR_TABLE_RECORDS',
        payload: akkrFinancialsService.find({
            query: {
                $sort: sort,
                ...filters,
            }
        })
    });

}


export function fetchFacetsForAkkrDataRecords({dispatch}) {
    dispatch({
        type: 'FETCH_AKKR_TABLE_FACETS',
        payload: akkrDataFacetsService.find()
    });

}


export const downloadAkkrDataRecords = ({dispatch, sort, filters}) => {

    dispatch({
        type: 'DOWNLOAD_AKKR_DATA_RECORDS',
        payload: akkrDataExportService.find({
            query: {
                $sort: sort,
                ...filters,
            }
        }, {responseType: 'blob'}).then((response, other) => {

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'AKKR Data Records.csv');
            document.body.appendChild(link);
            link.click();

        })
    });
};



