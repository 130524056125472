import React, {useEffect} from 'react';
import {Container, Grid, Header, List} from 'semantic-ui-react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from 'react-router'
import {fetchCompany, patchShareClassSecurity} from "../../../actions/companyActions";
import {getRdgRowsFromRecords, getTableClassName} from "../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {formatFullDateTimeUtcToLocal} from "../../../common/formatDate"
import {cleanUpdated} from "../../../components/rdg/helpers";
import {columns} from "./common";
import {
    fetchShareClassesAndSecurityListingsForCompany,
    patchSecurityListing, patchShareClass
} from "../../../features/companyFeatureSlice";





const GrayRowRenderer = ({renderBaseRow, ...props}) => {

    const isInactive = props.row.is_inactive === true;

    const color = isInactive ? "grey" : null;
    return <div style={{color}}>{renderBaseRow(props)}</div>;
};


export default () => {

    const dispatch = useDispatch();
    const match = useRouteMatch();

    const {id} = match.params;
    useEffect(() => {
        fetchCompany({dispatch, id});
        dispatch(fetchShareClassesAndSecurityListingsForCompany({companyId: id}));
    }, [id, dispatch]);

    const {company, loadingCompany} = useSelector(state => state.companyStore, shallowEqual);

    const {shareClassesAndSecurityListings, loadingShareClassesAndSecurityListings} = useSelector(state => state.company, shallowEqual);

    let rows = getRdgRowsFromRecords({records: shareClassesAndSecurityListings, columns});

    const onGridRowsUpdated = ({fromRow, toRow, updated}) => {
        // double update is a big in reactDataGrid
        // https://github.com/adazzle/react-data-grid/issues/1061
        const record = shareClassesAndSecurityListings[fromRow];

        updated = cleanUpdated(updated);

        if (record.is_security_listing) {
            dispatch(patchSecurityListing({
                id: record.security_listing_id,
                patchData: updated
            })).then(() => dispatch(fetchShareClassesAndSecurityListingsForCompany({companyId: id}))
            );
        } else {
            console.log("ho")
            dispatch(patchShareClass({
                    id: record.share_class_id,
                    patchData: updated
                }

            )).then(() => dispatch(fetchShareClassesAndSecurityListingsForCompany({companyId: id}))
            );
        }


    };


    let header = <Header>Equity Securities</Header>;

    let errorMsg = <div/>;
    if (!company.has_valid_share_classes) {
        errorMsg = <Header.Subheader>
            Share Class Error: {company.has_valid_share_classes_error_text}
        </Header.Subheader>
    }

    if (!loadingCompany) {
        header = (
            <Header>
                Equity Securities: {company.company_name}
                {errorMsg}
            </Header>
        );
    }

    return (
        <Container fluid>
            {header}
            <div className={getTableClassName(loadingShareClassesAndSecurityListings)}>

                <ReactDataGrid
                    columns={columns}
                    rowGetter={index => rows[index]}
                    rowsCount={rows.length}
                    rowHeight={22}
                    rowRenderer={GrayRowRenderer}
                    headerRowHeight={70}
                    minHeight={500}
                    onGridRowsUpdated={onGridRowsUpdated}
                    enableCellSelect={true}
                />
            </div>
            <br/>
            <Grid padded>

                <Grid.Row>
                    <Grid.Column>
                        <List bulleted>
                            <List.Item>
                                A company has one or more Share Classes.  These are represented by the rows with a
                                'Share Class ID'.
                            </List.Item>
                            <List.Item>
                                A Share Class can have zero, one, or many Security Listings.  A private share class has
                                no public security listings.  A typical public share class has 1 security listing.  However,
                                some companies have multiple listings (ex: DCBO in US and Canada) which tie back to the
                                same underlying share class.
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>


        </Container>
    )
}



