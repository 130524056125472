import React, {useEffect} from 'react';
import {Button, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../common/request/convert';
import ViewWithSidebar from '../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../common/query/parse";
import {fetchEtfsWithSecurityListingRows} from "../../features/etfFeatureSlice";
import RdgTable from "../../components/rdg/RdgTable";
import {BoolToYesNoFormatter, StringFormatter, UtcDateFormatter} from "../../common/reactDataGrid/formatters";
import {cleanUpdated} from "../../components/rdg/helpers";
import {fetchShareClassesAndSecurityListingsForCompany, patchSecurityListing} from "../../features/companyFeatureSlice";


const columns = [
    {
        key: 'id',
        name: 'ETF ID',
        width: 70,
        sortable: true,
        frozen: true,
        _getValueFromRecord: (r) => {
            if (r.is_security_listing === true) {
                return ''
            }
            return r.id
        }
    },
    {
        key: 'security_listing_id',
        name: 'Security Listing ID',
        width: 70,
        sortable: true,
        frozen: true,
        _getValueFromRecord: (r) => {
            if (r.is_security_listing === true) {
                return r.id
            }
        }
    },
    {
        key: 'name',
        name: 'Name',
        width: 350,
        sortable: true,
        frozen: true,
    },
    {
        key: 'short_name',
        name: 'Short Name',
        width: 200,
        sortable: true,
        editable: false
    },
    {
        key: 'exchange_ticker',
        name: 'Ticker',
        width: 150
    },
    {
        key: 'ciq_trading_id',
        name: 'CIQ Trading ID',
        width: 120
    },
    {
        key: 'iex_symbol',
        name: 'IEX Symbol',
        editable: true,
        formatter: StringFormatter,
        width: 100
    },
    {
        key: 'yahoo_ticker',
        name: 'Yahoo Ticker',
        editable: true,
        formatter: StringFormatter,
        width: 100
    },
    {
        key: 'eikon_instrument_code',
        name: 'Eikon Code',
        editable: true,
        formatter: StringFormatter,
        width: 100
    },
    {
        key: 'sedol',
        name: 'SEDOL',
        editable: true,
        formatter: StringFormatter,
        width: 100
    },
    {
        key: 'gs_product_id',
        name: 'GS Product ID',
        editable: true,
        formatter: StringFormatter,
        width: 100
    },
    {
        key: 'update_real_time_price',
        name: 'Update Real Time Price',
        editable: true,
        formatter: BoolToYesNoFormatter,
        width: 100
    },
    {
        key: 'first_ciq_price_date',
        name: 'First CIQ Price Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'last_ciq_price_date',
        name: 'Last CIQ Price Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'first_cached_price_date',
        name: 'First Cached Price Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'last_cached_price_date',
        name: 'Last Cached Price Date',
        formatter: UtcDateFormatter,
        width: 90
    },
    {
        key: 'last_ex_dividend_date',
        name: 'Last Ex-Dividend Date',
        formatter: UtcDateFormatter,
        width: 90
    },
];



export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {etfsWithSecurityListings, loadingEtfsWithSecurityListings} = useSelector(state => {return state.etf}, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>

            {/*<Grid centered columns={1} relaxed>*/}
            {/*    <Grid.Row>*/}
            {/*        <Statistic horizontal value={records.length} label='Records' size="tiny"/>*/}
            {/*    </Grid.Row>*/}
            {/*</Grid>*/}

        </div>
    );

    const onGridRowsUpdated = ({fromRow, toRow, updated}) => {
        // double update is a big in reactDataGrid
        // https://github.com/adazzle/react-data-grid/issues/1061
        const record = etfsWithSecurityListings[fromRow];

        updated = cleanUpdated(updated);

        if (record.is_security_listing) {
            dispatch(patchSecurityListing({
                id: record.id,
                patchData: updated
            })).then(() => dispatch(fetchEtfsWithSecurityListingRows({}))
            );
        }

    };

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             records={etfsWithSecurityListings}
                                             loading={loadingEtfsWithSecurityListings}
                                             onGridRowsUpdated={onGridRowsUpdated}

        />} sidebarView={sidebar}

        />
    )
}


const requestData = ({dispatch, searchProps}) => {
    dispatch(fetchEtfsWithSecurityListingRows({}));
};