import {Button, Divider, Grid, Header, Icon, Table} from "semantic-ui-react";
import React from "react";
import {
    formatNoDecimalNoNull,
    formatNoDecimalNoNullOrZero,
    formatOneDecimalNoNull, formatPercentFourDecimalNoNull, formatPercentNoNull,
    formatPercentOneDecimalNoNull,
    formatPercentTwoDecimalNoNull
} from "../../../common/format";
import {formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import {getPortfolioSharesAsOfText, getPricesAsOfText, getTradeRecommendationsAsOfText} from "../common";


export default ({doradoPortfolioTradeMetadata}) => {

    const shortParameters = [
        <Table.Row>
            <Table.Cell>

            </Table.Cell>
        </Table.Row>
    ]



    return (
        <React.Fragment>
            <Divider horizontal>
                Dorado Parameters
            </Divider>
            <Table compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Enable Short?
                        </Table.Cell>
                        <Table.Cell>
                            {doradoPortfolioTradeMetadata.enable_short_trades === false ? 'No' : 'Yes'}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            No Multiple Expansion IRR Weight
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentNoNull(doradoPortfolioTradeMetadata.no_multiple_expansion_irr_weight)}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>
                            Assumed Trade Cost %
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(doradoPortfolioTradeMetadata.assumed_implementation_shortfall_percent)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Target Max Long Concentration Score
                        </Table.Cell>
                        <Table.Cell>
                            {doradoPortfolioTradeMetadata.target_maximum_long_concentration_score}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Return/Conc per 100 HH
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentOneDecimalNoNull(doradoPortfolioTradeMetadata.return_score_penalty_per_concentration_score_point * 100)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Target Cash %
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(doradoPortfolioTradeMetadata.target_cash_percent)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Max Single Long Position %
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentTwoDecimalNoNull(doradoPortfolioTradeMetadata.target_maximum_long_single_position_weight)}
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>
                            Hard Trade Cost
                        </Table.Cell>
                        <Table.Cell>
                            {formatPercentFourDecimalNoNull(doradoPortfolioTradeMetadata.hard_trade_cost_percent)}
                        </Table.Cell>
                    </Table.Row>

                </Table.Body>
            </Table>

        </React.Fragment>
    )

}