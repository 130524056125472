const defaultState = {
  metadata: {},
  profilingMetadata: [],
  loadingProfilingMetadata: true,
  loading: true,
  queueData: {
    finished_jobs: [],
    started_jobs: [],
    queued_jobs: [],
    failed_jobs: []
  }
};


export default (state = defaultState, action = {}) => {
  switch (action.type) {

    case 'FIND_VALUATION_METADATA_FULFILLED': {

      return {
        ...state,
        metadata: action.payload.data,
        loading: false

      }

    }

    case 'FETCH_PROFILING_METADATA_FULFILLED': {

      return {
        ...state,
        profilingMetadata: action.payload.data,
        loadingProfilingMetadata: false
      }

    }

    case 'FETCH_QUEUE_DATA_FULFILLED': {
      return {
        ...state,
        queueData: action.payload.data
      }
    }

    default:
      return state;

  }
}
