

const defaultState = {
  jobPostings: []
};


export default (state = defaultState, action = {}) => {

  switch (action.type) {
    case "FIND_JOB_POSTINGS_FULFILLED": {

      return {
        ...state,
        jobPostings: action.payload.data
      }
    }

    default:
      return state;

  }
}
