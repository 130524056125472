import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic, Table} from 'semantic-ui-react'

import {formatNoDecimalNoNull, formatPercentOneDecimalNoNull, formatTwoDecimalNoNull} from "../../common/format";



const currencies = [
    'USD',
    'CAD',
    'AUD',
    'NZD',
    'EUR',
    'NOK'
]

export default ({portfolioState}) => {

    const rows = currencies.map(currency => {

        const field = 'cash_' + currency.toLowerCase();

        return {
            currency: currency,
            value: portfolioState[field]
        }

    }).map(row => {
        return (
            <Table.Row key={row.currency}>
                <Table.Cell textAlign={'left'}>
                    {row.currency}
                </Table.Cell>
                <Table.Cell>
                    {formatTwoDecimalNoNull(row.value)}
                </Table.Cell>
            </Table.Row>
        )
    });


    return (
        <React.Fragment>
            <Divider horizontal>Cash by Currency</Divider>
            <Table compact collapsing basic={'very'} textAlign={'right'}>
                <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Currency
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                $
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>

            </Table>

        </React.Fragment>

    )
}