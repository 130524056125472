const defaultState = {
    metadata: {},
    loading: true,
    records: [],

    company: {},
    loadingCompany: true,

    financialRecords: [],
    loadingFinancialRecords: true

};


export default (state = defaultState, action = {}) => {
    switch (action.type) {

        case 'FETCH_AKKR_COMPANY_RECORDS_PENDING': {
            return {
                ...state,
                loading: true
            }
        }

        case 'FETCH_AKKR_COMPANY_RECORDS_FULFILLED': {
            return {
                ...state,
                loading: false,
                records: action.payload.data,
            }
        }

        case 'FETCH_AKKR_COMPANY_PENDING': {
            return {
                ...state,
                loadingCompany: true
            }
        }

        case 'FETCH_AKKR_COMPANY_FULFILLED': {
            return {
                ...state,
                loadingCompany: false,
                company: action.payload.data
            }
        }

        case 'FIND_AKKR_FINANCIAL_RECORDS_FOR_COMPANY_PENDING': {
            return {
                ...state,
                loadingFinancialRecords: true
            }
        }

        case 'FIND_AKKR_FINANCIAL_RECORDS_FOR_COMPANY_FULFILLED': {

            return {
                ...state,
                loadingFinancialRecords: false,
                financialRecords: action.payload.data
            }
        }

        default:
            return state;

    }
}