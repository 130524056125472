import React, {useEffect} from 'react';
import {Button, Divider, Form, Header, Icon, Table} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {fetchSecurityInvestmentPeriodReports} from "../../../features/dorado/doradoPortfolio";
import {createAddValueToQuery, createSetFieldValueOnQuery} from "../../../common/query/add";
import {fieldSpecs, periodTypeOptions} from './common'
import CompanyPeriodReturnsTable from "./CompanyPeriodReturnsTable";
import PeriodReportTable from "./PeriodReportTable";
import {useReloadOnChange} from "../../../common/hooks";
import {convertQuerySortToFeathersSort} from "../../../common/request/convert";
import {formatFullDate} from "../../../common/format";
import {fetchPortfolioPeriodReports, fetchPortfolios} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfolioSelect from "../../../components/PortfolioSelect";
import qs from "qs";
import {PortfolioType} from "../../../common/constants";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {

        portfolioPeriodReports,
        loadingPortfolioPeriodReports,

        portfolios,
        loadingPortfolios

    } = useSelector(state => {
        return state.portfolio
    }, shallowEqual);

    const {
        securityInvestmentPeriodReports,
        loadingSecurityInvestmentPeriodReports,
    } = useSelector(state => {
        return state.doradoPortfolio
    }, shallowEqual);


    useEffect(() => {
        const sort = {
            name: 1
        }
        dispatch(fetchPortfolios({sort, filters: {}}));
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['portfolio_id'],
        requestData
    });

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['portfolio_period_report_id', 'sort'],
        requestData: requestSecurityInvestmentPeriodReportsForPeriod
    });

    if (searchProps.portfolio_id == null) {
        if (!loadingPortfolios && portfolios.length > 0) {

            const doradoPortfolios = portfolios.filter(p => p.type === PortfolioType.dorado && p.is_backtest === false);

            if (doradoPortfolios.length === 1) {
                searchProps.portfolio_id = doradoPortfolios[0].id;
                const newUrl = location.pathname + qs.stringify(searchProps, {addQueryPrefix: true});
                return <Redirect to={newUrl}/>
            }

        }
    }

    const setPortfolioPeriodReportId = (id) => {
        createSetFieldValueOnQuery({history, searchProps, fieldName: 'portfolio_period_report_id', value: id})();
    };

    const selectedPeriodReport = getSelectedPeriodReport({
        portfolio_period_report_id: searchProps.portfolio_period_report_id,
        portfolioPeriodReports
    });

    const sidebar = (
        <div>
            <Header>
                Period Reports
            </Header>
            <Form>
                <PortfolioSelect portfolios={portfolios}/>
                <Form.Select
                    fluid
                    inline
                    options={periodTypeOptions}
                    onChange={createAddValueToQuery({history, searchProps, fieldName: 'periodType'})}
                    value={searchProps.periodType}
                    label="Period Type"
                />

            </Form>
            <br/>
            <Divider horizontal>Definitions</Divider>
            <Table basic='very' collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Time Weighted Return
                        </Table.Cell>
                        <Table.Cell>
                            Daily returns chained together.  Assumes entire portfolio funded up-front
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            TWR ex-Shorts
                        </Table.Cell>
                        <Table.Cell>
                            All short trades are removed from the portfolio history and returns are recalculated.  Represents
                            return of long positions and cash balance.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Short Contribution
                        </Table.Cell>
                        <Table.Cell>
                            Difference between net TWR and TWR ex-shorts.  Reflets return and weight of short positions.
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Long Positions TWR
                        </Table.Cell>
                        <Table.Cell>
                            Time Weighted return of long positions (no cash).
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Short Positions TWR
                        </Table.Cell>
                        <Table.Cell>
                            Time Weighted return of short positions.
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

        </div>
    );

    const dateText = formatFullDate(selectedPeriodReport.start_date, true) + " - " + formatFullDate(selectedPeriodReport.end_date, true);

    const companyPositionsView = (
        <div>

            <div style={{float: 'left'}}>
                <Header floated={'left'}>
                    Positions
                </Header>
                <Button icon basic size={'tiny'} onClick={
                    () => createSetFieldValueOnQuery({
                        history,
                        searchProps,
                        fieldName: 'portfolio_period_report_id',
                        value: null
                    })()}
                >
                    <Icon name='angle left'/>
                    Back
                </Button>
            </div>
            <Header floated={'right'}>
                {selectedPeriodReport.start_date == null ? "" : dateText}
            </Header>
            <p/>
            <CompanyPeriodReturnsTable loading={loadingSecurityInvestmentPeriodReports}
                                       records={securityInvestmentPeriodReports}
                                       selectedPeriodReport={selectedPeriodReport}/>


        </div>
    )

    const visibleDoradoBacktestPeriodReports = getSelectedPeriodReports({
        portfolioPeriodReports,
        periodType: searchProps.periodType
    });

    const periodReportView = (
        <div>
            <PeriodReportTable loading={loadingPortfolioPeriodReports}
                               records={visibleDoradoBacktestPeriodReports}
                               setDoradoBacktestPeriodReportId={setPortfolioPeriodReportId}
            />
        </div>
    )

    const visibleMainView = searchProps.portfolio_period_report_id == null ? periodReportView : companyPositionsView;

    return (
        <ViewWithSidebar mainView={visibleMainView}
                         sidebarView={sidebar}

        />
    )

}

const requestData = ({dispatch, searchProps}) => {

    if (searchProps.portfolio_id != null) {
        dispatch(fetchPortfolioPeriodReports({
            filters: {
                portfolio_id: searchProps.portfolio_id
            },
            sort: {
                end_date: 1
            }
        }));
    }


};


const requestSecurityInvestmentPeriodReportsForPeriod = ({dispatch, searchProps, matchParams}) => {

    if (searchProps.portfolio_period_report_id != null) {

        const sort = convertQuerySortToFeathersSort(searchProps.sort);
        const filters = {
            portfolio_period_report_id: searchProps.portfolio_period_report_id
        };
        dispatch(fetchSecurityInvestmentPeriodReports({sort, filters}))

    }
};


const getSelectedPeriodReport = ({portfolioPeriodReports, portfolio_period_report_id}) => {

    if (portfolio_period_report_id == null) {
        return {}
    }

    const result = portfolioPeriodReports.filter(r => {
        return r.id === portfolio_period_report_id
    });

    if (result.length === 1) {
        return result[0]
    }

    return {}
}


const getSelectedPeriodReports = ({portfolioPeriodReports, periodType}) => {
    return portfolioPeriodReports.filter(r => r.period_type === periodType)

}