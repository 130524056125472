import {
    getValuationPremiumDetails
} from "../../../../components/companyFinancials/components";
import {
    cellWidth, createHeaderRow,
    createSectionHeaderRow,
    emptyRow,
    headerRowHeight, rowHeaderWidth,
    rowHeight
} from "../../../../components/companyFinancials/common";
import {getTableClassName} from "../../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {getWindowHeightLessMenuBar} from "../../../../common/common";
import React from "react";
import {ModelRowRenderer} from "../../../../components/rdg/rdgComponents";
import {createRow, HeaderContentRenderer, LeftAlignHeaderRenderer} from "../../../../components/rdg/helpers";
import {
    formatFullDate,
    formatPercentOneDecimalNoNull,
    formatString,
    formatMultipleTwoDecimals,
    formatSharePrice,
    formatNoDecimalNoNull,
    formatBooleanYesNo,
    formatPercentNoNull,
    formatNoDecimal,
    formatOneDecimal,
    formatOneDecimalNoNull
} from "../../../../common/format";
import {Link} from "react-router-dom";


const typeAbbreviations = {
    'Backtest Model': 'backtest',
    'Current Model': 'latest'
};


const makeFlagAbsDelta = ({delta, field}) => record => {
    if (Math.abs(record[field]) > delta) {
        return 'override-color'
    }
}

const getChangeColor = record => {

    if (record.change_in_exit_price_per_share_trading > .1) {
        return 'positive-return-color'
    }
    if (record.change_in_exit_price_per_share_trading > .1) {
        return 'override-color'
    }

}



export default ({records, loading}) => {

    const asOfDateRow = [
        {
            key: 'rowHeader',
            name: 'As of Date',
            width: 300,
            frozen: true,
            headerRenderer: <LeftAlignHeaderRenderer/>,
            _isForecast: false
        }
    ].concat(
        records.map(record => {
            return {
                key: record.id,
                name: formatFullDate(record.as_of_standard_date),
                width: cellWidth,
                headerRenderer: <HeaderContentRenderer/>
            }
        })
    );

    const columnHeaderFieldName = 'id';

    const dataRows = [
        emptyRow(),
        createRow({
            headerText: 'Type',
            records,
            getCellValue: record => {
                return typeAbbreviations[record.forecast_type]
            },
            formatValue: formatString,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Invalid?',
            records,
            getCellValue: record => {

                if (!record.forecast_is_valid) {
                    return 'invalid'
                }
                return ''
            },
            formatValue: formatString,
            columnHeaderFieldName
        }),
        emptyRow(),
        createRow({
            headerText: '% Change in Exit Price',
            records,
            getCellValue: record => record.change_in_exit_price_per_share_trading,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            getCellClassName: getChangeColor
        }),
        emptyRow(),

        createHeaderRow({headerText: 'Valuation Change Contribution'}),
        createRow({
            headerText: 'Change in Exit NTM Revenue',
            records,
            getCellValue: record => record.change_in_exit_ntm_revenue,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Change in Exit Max Margin',
            records,
            getCellValue: record => record.change_in_exit_max_pretax_fcf_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Change in Exit Max Pretax FCF',
            records,
            getCellValue: record => record.change_in_exit_max_pretax_fcf,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Chg Exit FCF Multiple pre-Disc/Prem',
            records,
            getCellValue: record => record.change_in_unaffected_max_pretax_fcf_multiple,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Effect of Disc/Prem Change',
            records,
            getCellValue: record => record.change_in_net_premium,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Change in Cumul. FCF',
            records,
            getCellValue: record => record.change_in_cumul_cash_flow_percent_of_ev,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Change in Diluted Shares',
            records,
            getCellValue: record => record.change_in_diluted_shares,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Other/Combined Effects',
            records,
            getCellValue: record => record.residual_change_in_exit_price_per_share_trading,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),









        emptyRow(),

        createHeaderRow({headerText: 'Growth'}),
        createRow({
            headerText: 'LTM 4-Q Avg Relevant Organic Growth',
            records,
            getCellValue: record => record.ltm_relevant_organic_growth,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Forecast NTM Organic ARR Growth',
            records,
            getCellValue: record => record.entry_ntm_organic_arr_growth,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Exit ARR Growth',
            records,
            getCellValue: record => record.exit_ntm_organic_arr_growth,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Exit Revenue Growth',
            records,
            getCellValue: record => record.exit_ntm_revenue_total_growth,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
        createRow({
            headerText: 'Revenue CAGR',
            records,
            getCellValue: record => record.revenue_total_cagr,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Diluted Shares CAGR (ex-repur/options)',
            records,
            getCellValue: record => record.diluted_shares_cagr_ex_repurchase_and_options,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Rev/share CAGR (ex-repur/options)',
            records,
            getCellValue: record => record.revenue_per_share_cagr_ex_repurchase,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            underline: true
        }),
        createRow({
            headerText: 'Avg Annual Repurchase % Entry Shares',
            records: records,
            getCellValue: record => record.cumulative_repurchase_shares_percent_of_basic_entry / 5,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        emptyRow(),
        createRow({
            headerText: 'Entry NTM Revenue',
            records,
            getCellValue: record => record.entry_ntm_revenue_total,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Exit NTM Revenue',
            records,
            getCellValue: record => record.exit_ntm_revenue_total,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Exit vs Entry NTM Revenue',
            records,
            getCellValue: record => record.exit_vs_entry_revenue_total + 1,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName
        }),




        emptyRow(),
        createHeaderRow({headerText: 'Execution Score'}),
        createRow({
            headerText: 'LTM Relevant Organic Growth',
            records,
            getCellValue: record => record.ltm_relevant_organic_growth,
            indent: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: '(-) Normalized Dilution',
            records,
            getCellValue: record => record.equiv_shares_normalized_net_granted_percent,
            indent: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'LTM EBIT Margin',
            records,
            getCellValue: record => record.ltm_reported_ebit_margin,
            indent: true,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'LTM Margin + Dil. Org. Growth',
            records,
            getCellValue: record => record.ltm_reported_ebit_margin_plus_dil_org_growth,
            formatValue: formatPercentOneDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Excess Dilution Penalty',
            records,
            getCellValue: record => record.excess_dilution_penalty,
            formatValue: formatPercentOneDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'LTM % Deferred Rev.',
            records,
            getCellValue: record => record.ltm_reported_change_st_deferred_rev_margin,
            formatValue: formatPercentOneDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Override Effect',
            records,
            getCellValue: record => record.execution_score_override_effect,
            formatValue: formatPercentOneDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Execution Score',
            records,
            getCellValue: record => record.execution_score,
            formatValue: formatPercentOneDecimalNoNull,
            underline: true,
            columnHeaderFieldName
        }),
        emptyRow(),

        createHeaderRow({headerText: 'Exit Max Margin'}),
        createRow({
            headerText: 'Exit NTM Gross margin',
            records,
            getCellValue: record => record.exit_ntm_gross_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Min OPEX (.35x GM)',
            records,
            getCellValue: record => record.exit_max_margin_min_opex_percent_of_sales,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'S&M Efficiency/Churn Modifier',
            records,
            getCellValue: record => record.exit_max_margin_churn_sales_efficiency_modifier,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Max EBITDA Margin',
            records,
            getCellValue: record => record.exit_maximized_ebitda_margin_ntm,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            underline: true
        }),
        createRow({
            headerText: 'Capex',
            records,
            getCellValue: record => record.exit_maximized_capital_expenditures_percent_of_sales_ntm,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Max Pretax FCF Margin',
            records,
            getCellValue: record => record.exit_maximized_pretax_fcf_margin_ntm,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            underline: true
        }),
        emptyRow(),
        createRow({
            headerText: '5-yr avg Bookings/S&M',
            records,
            getCellValue: record => record.entry_average_implemented_arr_to_sm,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Exit Gross Retention',
            records,
            getCellValue: record => record.exit_arr_gross_retention,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Valuation Discount'}),
        createRow({
            headerText: 'Entry Max Pretax FCF Margin',
            records,
            getCellValue: record => record.entry_maximized_pretax_fcf_margin,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: false
        }),
        createRow({
            headerText: '(-) Execution Score',
            records,
            getCellValue: record => record.execution_score,
            formatValue: formatPercentOneDecimalNoNull,
            indent: true,
            columnHeaderFieldName
        }),

        createRow({
            headerText: 'Exit Valuation Discount',
            records,
            getCellValue: record => record.exit_multiple_discount,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            underline: true,
            indent: false
        }),
        createRow({
            headerText: 'Change in Valuation Discount',
            records,
            getCellValue: record => record.change_in_exit_multiple_discount,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
            indent: true,
            getCellClassName: makeFlagAbsDelta({delta: .05, field: 'change_in_exit_multiple_discount'})
        }),
        emptyRow(),
        ...getValuationPremiumDetails({financialRecords: records, columnHeaderFieldName}),

        emptyRow(),





        createHeaderRow({headerText: 'Exit Multiples'}),
        createRow({
            headerText: 'Unaffected Exit EV/NTM Revenue',
            records,
            getCellValue: record => record.unaffected_exit_ev_to_revenue_total_ntm,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Unaffected Exit EV/NTM Pretax FCF',
            records,
            getCellValue: record => record.unaffected_exit_ev_to_maximized_pretax_fcf_ntm,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName
        }),
        emptyRow(),

        createRow({
            headerText: 'Exit EV/NTM Revenue',
            records,
            getCellValue: record => record.exit_ev_to_revenue_total_ntm,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Exit EV/Max Pretax FCF',
            records,
            getCellValue: record => record.exit_ev_to_maximized_pretax_fcf_ntm,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName
        }),


        emptyRow(),
        createRow({
            headerText: 'Exit EV/NTM Actual EBITDA',
            records,
            getCellValue: record => record.exit_ev_to_ebitda_ntm,
            formatValue: formatMultipleTwoDecimals,
            columnHeaderFieldName
        }),

        emptyRow(),

        createHeaderRow({headerText: 'Exit Valuation'}),
        createRow({
            headerText: 'Exit EV',
            records,
            getCellValue: record => record.exit_ev_filing,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Cash',
            records,
            getCellValue: record => record.exit_cash,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Convertible Debt',
            records,
            getCellValue: record => -record.exit_convertible_debt,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Non-Convertible Debt',
            records,
            getCellValue: record => -record.exit_non_convertible_debt,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Other (Pens, Pref, Minor.)',
            records,
            getCellValue: record => record.exit_other_bs,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),



        createRow({
            headerText: 'Exit Market Value',
            records,
            getCellValue: record => record.exit_market_value_filing,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            underline: true
        }),
        createRow({
            headerText: 'Exit Diluted Shares',
            records,
            getCellValue: record => record.exit_diluted_shares,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Exit $/Share - Filing',
            records,
            getCellValue: record => record.exit_price_per_share_filing,
            formatValue: formatSharePrice,
            columnHeaderFieldName,
            underline: true
        }),
        createRow({
            headerText: 'Exit $/Share - Trading',
            records,
            getCellValue: record => record.exit_price_per_share_trading,
            formatValue: formatSharePrice,
            columnHeaderFieldName
        }),
        emptyRow(),
        createRow({
            headerText: 'Cumulative 5yr FCF',
            records: records,
            getCellValue: record => record.cumulative_free_cash_flow,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
        }),

        emptyRow(),

        createRow({
            headerText: 'Exit $/share Gain % from Share Repurchase',
            records: records,
            getCellValue: record => record.share_repurchase_gain_percent_of_exit_price,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Total Share Repurchase $',
            records: records,
            getCellValue: record => record.cumulative_repurchase_spend,
            formatValue: formatNoDecimal,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Total Repurchased Shares',
            records: records,
            getCellValue: record => record.cumulative_repurchase_shares,
            formatValue: formatOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Cumulative Repurchase % Entry Shares',
            records: records,
            getCellValue: record => record.cumulative_repurchase_shares_percent_of_basic_entry,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Avg Annual Repurchase % Entry Shares',
            records: records,
            getCellValue: record => record.cumulative_repurchase_shares_percent_of_basic_entry / 5,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName,
        }),
        createRow({
            headerText: 'Avg Repur. $/share filing',
            records: records,
            getCellValue: record => record.cumulative_repurchase_price_filing,
            formatValue: formatSharePrice,
            columnHeaderFieldName,
        }),

        emptyRow(),

        createHeaderRow({headerText: 'Entry Balance Sheet'}),
        createRow({
            headerText: 'Cash',
            records,
            getCellValue: record => record.entry_cash,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Convertible Debt',
            records,
            getCellValue: record => record.entry_convertible_debt,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Non-Convertible Debt',
            records,
            getCellValue: record => record.entry_non_convertible_debt,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Other (neg. = liab.)',
            records,
            getCellValue: record => record.entry_other_bs,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName,
            indent: true
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Entry Shares'}),
        createRow({
            headerText: 'Entry Basic Shares',
            records,
            getCellValue: record => record.entry_shares_ending,
            formatValue: formatOneDecimal,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Entry Unvested RSUs',
            records,
            getCellValue: record => record.entry_unvested_rsus_ending,
            formatValue: formatOneDecimal,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Entry Options Treasury Method',
            records,
            getCellValue: record => record.entry_option_treasury_method_dilution,
            formatValue: formatOneDecimal,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Entry Diluted Shares',
            records,
            getCellValue: record => record.entry_diluted_shares,
            formatValue: formatOneDecimal,
            columnHeaderFieldName,
            underline: true
        }),
        createRow({
            headerText: 'Entry Options Outstanding',
            records,
            getCellValue: record => record.entry_options_ending,
            formatValue: formatOneDecimal,
            columnHeaderFieldName,
            indent: true
        }),
        createRow({
            headerText: 'Entry Options Strike Price',
            records,
            getCellValue: record => record.entry_options_weighted_strike_price,
            formatValue: formatSharePrice,
            columnHeaderFieldName,
            indent: true
        }),
        emptyRow(),
        createHeaderRow({headerText: 'Entry'}),

        createRow({
            headerText: 'Entry NTM Revenue',
            records,
            getCellValue: record => record.entry_ntm_revenue_total,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Entry NTM Organic ARR Growth',
            records,
            getCellValue: record => record.entry_ntm_organic_arr_growth,
            formatValue: formatPercentOneDecimalNoNull,
            columnHeaderFieldName
        }),

        createRow({
            headerText: 'Entry NTM EBITDA',
            records,
            getCellValue: record => record.entry_ntm_ebitda,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),



        emptyRow(),
        createHeaderRow({headerText: 'Exit'}),
        createRow({
            headerText: 'Exit NTM Revenue',
            records,
            getCellValue: record => record.exit_ntm_revenue_total,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Exit NTM EBITDA',
            records,
            getCellValue: record => record.exit_ntm_ebitda,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'Exit Net Cash',
            records,
            getCellValue: record => record.exit_net_cash,
            formatValue: formatNoDecimalNoNull,
            columnHeaderFieldName
        }),




    ];

    return (
        <div className={getTableClassName(loading) + ' align-right'}>

            <ReactDataGrid
                columns={asOfDateRow}
                rowGetter={index => dataRows[index]}
                rowsCount={dataRows.length}
                headerRowHeight={headerRowHeight}
                rowHeight={rowHeight}
                minHeight={getWindowHeightLessMenuBar()}
                rowRenderer={ModelRowRenderer}
            />

        </div>
    )

}