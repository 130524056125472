import {abbreviationByString} from "../../common/constants";

export const rowHeaderWidth = 200;
export const cellWidth = 85;
export const headerRowHeight = 30;
export const rowHeight = 22;


export const abbreviateSource = (value) => {

    const abbrev = abbreviationByString[value];
    return abbrev ? abbrev : value

};

export const createHeaderRow = ({headerText}) => {

    return {
        rowHeader: headerText,
        _header: true
    }
};

export const createSectionHeaderRow = ({headerText}) => {
    return {
        rowHeader: headerText,
        _sectionHeader: true
    }
};
export const emptyRow = () => {
    return {}
};