import numeral from 'numeral';
import moment from 'moment'
import {isUndefinedOrNull} from './common'


export const validMoment = function (string, format, isUtc) {

    if (isUndefinedOrNull(string)) {
        return ''
    }

    let d = moment(string);

    if (isUtc) {
        d = moment.utc(string);
    }

    if (d.isValid()) {
        return d.format(format)
    } else {
        return ''
    }

}


export const getPercentage = (amount, total) => {
    if (!total) {
        return numeral(0).format('0%')
    }
    return numeral(amount / total).format('0%')
}

export const formatSharePrice = (value) => {
    if (value == null || value === '') {
        return ''
    }

    if (value < 1) {
        return numeral(value).format('$0,0.0000')
    }
    return numeral(value).format('$0,0.00')
}

export const formatSharePriceFixedDigits = (value) => {

    if (value == null || value === '') {
        return ''
    }

    return numeral(value).format('$0,0.00')

};

export const formatNoDecimal = (value) => {

    if (value == null) {
        return ''
    }

    // numeral throws an error if more than 7 decimals with 0
    if (Math.abs(value) < .00001) {
        value = 0;
    }

    return numeral(value).format('(0,0)')
};

export const formatOneDecimal = (value) => {

    // numeral throws an error if more than 7 decimals with 0
    if (Math.abs(value) < .00001) {
        value = 0;
    }

    return numeral(value).format('(0,0.0)')
};


export const formatNoDecimalNoNullOrZero = (value) => {
    if (value == null || Math.abs(value) < .00001) {
        return ''
    }

    return formatNoDecimal(value)
};

export const formatNoDecimalNoNull = (value) => {
    if (value == null) {
        return ''
    }

    return formatNoDecimal(value)
};




export const formatOneDecimalNoNull = (value) => {
    if (value == null || Math.abs(value) < .00001) {
        return ''
    }

    return formatOneDecimal(value)
}

export const formatTwoDecimalNoNull = (value) => {
    if (value == null || Math.abs(value) < .00001) {
        return ''
    }

    return numeral(value).format('(0,0.00)')
};


export const formatThreeDecimalNoNull = (value) => {
    if (value == null) {
        return ''
    }

    return numeral(value).format('(0,0.000)')
}

export const formatFourDecimalNoNullNoZero = (value) => {
    if (value == null) {
        return ''
    }

    return numeral(value).format('(0,0.0000)')
};


export const formatTwoDecimalCurrencyNoZero = (value) => {
    if (value == null || Math.abs(value) < .00001) {
        return ''
    }

    return numeral(value).format('($0,0.00)')
};


export const formatTwoDecimalCurrency = (value) => {
    if (value == null) {
        return ''
    }

    return numeral(value).format('($0,0.00)')
};




export const formatYear = (value) => {
    if (value == null || Math.abs(value) < .00001) {
        return ''
    }
    return numeral(value).format('0')
}

export const formatPercentNoNull = (value) => {
    if (value == null || value === '') {
        return ''
    }

    if (Math.abs(value) < .00001) {
        value = Math.round(value, 6)
    }

    return numeral(value).format('(0%)')
}

export const formatPercentOneDecimalNoNull = (value) => {

    if (value == null || value === '') {
        return ''
    }

    if (Math.abs(value) < .00001) {
        value = Math.round(value, 6)
    }

    return numeral(value).format('(0.0%)')
}

export const formatPercentTwoDecimalNoNull = (value) => {

    if (value == null || value === '') {
        return ''
    }

    if (Math.abs(value) < .00001) {
        value = Math.round(value, 6)
    }

    return numeral(value).format('(0.00%)')
}


export const formatPercentFourDecimalNoNull = (value) => {
    if (value == null || value === '') {
        return ''
    }
    return numeral(value).format('(0.0000%)')
}


export const formatMultipleOneDecimal = (value) => {
    if (value == null || !isFinite(value)) {
        return ''
    }

    let text = numeral(Math.abs(value)).format('0.0') + 'x';

    if (value < 0) {
        text = '(' + text + ')';
    }

    return text
}

export const formatMultipleTwoDecimals = (value) => {
    if (value == null || !isFinite(value)) {
        return ''
    }

    let text = numeral(Math.abs(value)).format('0.00') + 'x';

    if (value < 0) {
        text = '(' + text + ')';
    }

    return text
};

export const formatMultipleNoDecimal = (value) => {
    if (value == null) {
        return ''
    }

    let text = numeral(Math.abs(value)).format('0') + 'x';

    if (value < 0) {
        text = '(' + text + ')';
    }

    return text
};

export const formatFullDate = (value, isUtc) => {

    return validMoment(value, 'M/D/YYYY', isUtc)
};


export const formatString = (value) => {
    return value
};

export const formatBooleanYesNo = (value) => {

    if (value === 'true' || value === true) {
        return 'Yes'
    }

    if (value === 'false' || value === false) {
        return 'No'
    }

    return ''
};


export const formatBooleanYes = (value) => {
    if (value === 'true' || value === true) {
        return 'Yes'
    }

    return ''
};
