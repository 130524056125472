import React from 'react';

import {BusinessQualityFormatter, CompanyLinkFormatter, StringFormatter} from '../../common/reactDataGrid/formatters';
import {ManagementQualityFormatter} from "./formatters";


export const CompanyLinkFromSecurityListing = {
    key: 'ui_display_name',
    name: 'Name',
    width: 200,
    frozen: true,
    sortable: true,
    formatter: CompanyLinkFormatter,
    _getValueFromRecord: (r) => {

        if (r._isHeader) {
            return {
                display_name: r._name,
                isHeader: true
            }
        }

        // could be empty/header rows
        if (r.security_listing == null) {
            return {
                display_name: '',
                company_id: null
            }
        }

        return {
            display_name: r.security_listing.ui_display_name,
            company_id: r.security_listing.ui_company_id
        }
    }
}


export const CompanyLinkFromCompany = {
    key: 'company_name',
    name: 'Name',
    width: 200,
    frozen: true,
    sortable: true,
    formatter: CompanyLinkFormatter,
    _getValueFromRecord: (r) => {
        return {
            display_name: r.company.company_name,
            company_id: r.company.id
        }
    }
}




const commonColumns = [
    {
        key: 'company_name',
        name: 'Name',
        width: 200,
        frozen: true,
        sortable: true,
        formatter: CompanyLinkFormatter,
        _getValueFromRecord: (r) => {
            return {
                company_name: r.company_name,
                company_id: r.id
            }
        }
    },
    {
        key: 'exchange_ticker',
        name: 'Ticker',
        sortable: true,
        formatter: StringFormatter,
        width: 120
    },
    {
        key: 'region_name',
        name: 'Region',
        sortable: true,
        formatter: StringFormatter,
        width: 150,
        _group: 'geography'
    },
    {
        key: 'country_name',
        name: 'Country',
        sortable: true,
        formatter: StringFormatter,
        width: 150,
        _group: 'geography'
    },
    {
        key: 'geography_group',
        name: 'Geography Group',
        sortable: true,
        formatter: StringFormatter,
        width: 150
    },
    {
        key: 'business_quality',
        name: 'Business Quality',
        sortable: true,
        formatter: <BusinessQualityFormatter labelSize='tiny'/>,
        width: 80
    },
    {
        key: 'management_score',
        name: 'Mgmt. Quality',
        sortable: true,
        formatter: <ManagementQualityFormatter labelSize='tiny'/>,
        width: 80
    },
    {
        key: 'tracking_rank',
        name: 'Tracking Rank',
        sortable: true,
        formatter: StringFormatter,
        width: 150

    },

];






export const commonColumnsByName = commonColumns.reduce((obj, col) => {
    obj[col.key] = col;
    return obj;
}, {});


export const companyColumnFromObject = {
    key: 'company_name',
    name: 'Name',
    width: 200,
    frozen: true,
    sortable: true,
    formatter: CompanyLinkFormatter,
    _getValueFromRecord: (r) => {

        return {
            display_name: r.company.short_name,
            company_id: r.company.id
        }
    }
};

export const companyColumnFromCompanyId = {
    key: 'company_name',
    name: 'Name',
    width: 200,
    frozen: true,
    sortable: true,
    formatter: CompanyLinkFormatter,
    _getValueFromRecord: (r) => {
        return {
            display_name: r.company_name,
            company_id: r.company_id
        }
    }
}