import React from 'react'
import {Form, Dropdown} from 'semantic-ui-react'
import {nullValueOption} from '../../common/query/null'


export default function MultiSelectFilter({
                                              label,
                                              options,
                                              allowNullSelection,
                                              onChange,
                                              values
                                          }) {

    // options are of the form {key, value, text}

    allowNullSelection = allowNullSelection || false;

    if (allowNullSelection) {
        options = [nullValueOption, ...options]
    }

    return (
        <Form.Field>
            <label>{label}</label>
            <Dropdown multiple
                      selection
                      closeOnChange={true}
                      options={options}
                      value={values}
                      onChange={onChange}
                      search={true}
            />
        </Form.Field>


    )


}
