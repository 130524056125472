export const FinancialPeriodType = {
    historical: 'Historical',
    sellSideEstimate: 'Sell-Side Estimate',
    default_estimate: 'Default Estimate',
    maximizedMargin: 'Maximized Margin',
    dorado: 'Dorado',
    pointSurEstimate: 'Point Sur Estimate'
};
export const orderedRegions = [
    'USA',
    'Canada',
    'Europe',
    'Latin America',
    'ANZ',
    'Asia',
    'Middle East',
    'Africa',
    'Unknown'
];
export const RevenueCompositionMappingByDbField = {

    revenue_perpetual_license: 'Perpetual License',
    revenue_term_license: 'Term License',
    revenue_maintenance: 'Maintenance',
    revenue_subscription: 'Subscription',
    revenue_recurring_transactional: 'Recurring Transactional',
    revenue_service: 'Service',
    revenue_hardware: 'Hardware',
    revenue_other: 'Other'
};

export const RevenueTypeSpecs = [
    {
        revenueType: 'Perpetual License',
        dbRevenueType: 'perpetual_license',
    },
    {
        revenueType: 'Term License',
        dbRevenueType: 'term_license',
    },
    {
        revenueType: 'Maintenance',
        dbRevenueType: 'maintenance',
    },
    {
        revenueType: 'Subscription',
        dbRevenueType: 'subscription',
    },
    {
        revenueType: 'Recurring Transactional',
        dbRevenueType: 'recurring_transactional',
    },
    {
        revenueType: 'Service',
        dbRevenueType: 'service',
    },
    {
        revenueType: 'Hardware',
        dbRevenueType: 'hardware',
    },
    {
        revenueType: 'Other',
        dbRevenueType: 'other',
    },
];



export const GrossProfitCompositionMappingByDbField = {

    gross_profit_perpetual_license: 'Perpetual License',
    gross_profit_term_license: 'Term License',
    gross_profit_maintenance: 'Maintenance',
    gross_profit_subscription: 'Subscription',
    gross_profit_recurring_transactional: 'Recurring Transactional',
    gross_profit_service: 'Service',
    gross_profit_hardware: 'Hardware',
    gross_profit_other: 'Other'

};
export const GrossMarginCompositionMappingByDbField = {

    gross_margin_perpetual_license: 'Perpetual License',
    gross_margin_term_license: 'Term License',
    gross_margin_maintenance: 'Maintenance',
    gross_margin_subscription: 'Subscription',
    gross_margin_recurring_transactional: 'Recurring Transactional',
    gross_margin_service: 'Service',
    gross_margin_hardware: 'Hardware',
    gross_margin_other: 'Other'

};

export const ArrCompositionSpecs = [
    {
        revenueType: 'Term License',
        dbRevenueType: 'term_license',
    },
    {
        revenueType: 'Maintenance',
        dbRevenueType: 'maintenance',
    },
    {
        revenueType: 'Subscription',
        dbRevenueType: 'subscription',
    },
    {
        revenueType: 'Recurring Transactional',
        dbRevenueType: 'recurring_transactional',
    },
];


export const PeriodType = {
    annual: 'Annual',
    quarterly: 'Quarterly',
    interim: 'Interim',
    half: 'Half',
    monthly: 'Monthly'
};

export const SourceType = {
    ciqEstimate: 'CIQ Estimate',
    ciqReported: 'CIQ Reported',
    benchmark: 'Benchmark',
    pointSurDefaultEstimate: 'Point Sur Default Estimate',
    pointSurEstimate: 'Point Sur Estimate',
    dorado: 'Dorado'
};

export const colorBySourceType = {
    [SourceType.ciqEstimate]: 'estimate-color',
    [SourceType.ciqReported]: 'ciq-reported-color',
    [SourceType.pointSurDefaultEstimate]: 'point-sur-default-estimate-color',
    [SourceType.pointSurEstimate]: 'point-sur-estimate-color'
};


export const Fund = {
    pointSur: 'Point Sur',
    specialOpps: 'Special Opps V'
};

export const AttributionType = {
    account: 'Account',
    attributed: 'Attributed'
};

export const QualityText = {
    great: 'Great',
    good: 'Good',
    average: 'Average',
    bad: 'Bad',
    terrible: 'Terrible'
};

export const DoradoUniverseType = {
    alphaLong: 'Alpha Long',
    largeIndexWeight: 'Large Index Weight',
    predictableShort: 'Predictable Short'
};

export const DoradoUniverseTypeByInteger = {
    1: DoradoUniverseType.alphaLong,
    2: DoradoUniverseType.largeIndexWeight,
    3: DoradoUniverseType.predictableShort
};

export const qualityTextByValue = {
    5: QualityText.great,
    4: QualityText.good,
    3: QualityText.average,
    2: QualityText.bad,
    1: QualityText.terrible
};

export const EstimateType = {
    historical: 'Historical',
    sellSideEstimate: 'Sell-Side Estimate',
    pointSurEstimate: 'Point Sur Estimate'
};

export const ForecastType = {
    currentModel: 'Current Model',
    backtestModel: 'Backtest Model',
    revised_point_sur_backtest: 'Revised Backtest Model'

};


export const coverageByEmail = {

    'wahlswede@accel-kkr.com': 'Weston',
    'tbuckingham@accel-kkr.com': 'Tyler'
};

export const coverageOptions = [
    {
        key: '',
        value: null,
        text: ''
    },
    {
        key: 'Tyler',
        value: 'Tyler',
        text: 'Tyler'
    },
    {
        key: 'Weston',
        value: 'Weston',
        text: 'Weston'
    },
];
export const abbreviationByString = {
    'CIQ Reported': 'CIQ Rptd',
    'None': 'None',
    'Historical Estimate': 'Est',

    'CIQ Estimate': 'CIQ Est',
    'Point Sur Default Estimate': 'Default',
    'Point Sur Estimate': 'PSur Est',
    'Sell-Side Estimate': 'Sell-Side',
    'Residual from Annual Benchmark': 'Ann. Bench'

};


export const CurrencyType = {
    'trading': 'Trading'
}


export const PortfolioType = {
    current: 'Current',
    backtest: 'Point Sur Backtest',
    dorado: 'Dorado',
    backtest_benchmark: 'Backtest Benchmark',
    benchmark: 'Benchmark',
    point_sur: 'Point Sur',
    special_opps_v: 'Special Opps V',
    backtest_sensitivity: 'Backtest Sensitivity'
}

export const TradeType = {
    buy: 'Buy',
    sell: 'Sell'
}

export const ReportPeriodType = {
    monthly: 'Monthly',
    cumulative: 'Cumulative',
    custom: 'Custom',
    annual: 'Annual'
}

export const ShortStrategy = {
    singleEtf: 'Single ETF',
    igvSubset: 'IGV Subset',
    softwareIRRWeighted: 'Software IRR-Weighted',
    softwareLowIRREqualWeight: 'Software Low-IRR Equal Weight'
}

export const shortStrategies = [
    ShortStrategy.singleEtf,
    ShortStrategy.igvSubset,
    ShortStrategy.softwareIRRWeighted,
    ShortStrategy.softwareLowIRREqualWeight
]

export const Currencies = [
    'USD',
    'SEK',
    'AUD',
    'GBP',
    'CAD',
    'EUR',
    'NZD',
    'NOK',
    'JPY',
    'ZAR',
    'SGD',
    'CHF',
    'DKK',
    'BRL',
    'ILS',
    'KRW',
    'CLP',
    'CNY',
    'HKD',
    'COP',
    'PLN'


]
