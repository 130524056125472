import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {
    createAddCheckedValueToQuery,
    createAddValueToQuery,
    createSetFieldValuesOnQuery
} from "../../../common/query/add";
import {fieldSpecs, showAlphaOptions, startEndDateOptions} from './common'
import {useReloadOnChange} from "../../../common/hooks";
import {
    fetchLiveAndBacktestPortfolios,
    fetchPortfolioPositions,
    fetchPortfolioReturnsGroups, fetchPortfolios,
    fetchPortfolioStates
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfoliosSummaryTable from "../../portfolio/PortfoliosSummaryTable";
import {formatAbsoluteDateString} from "../../../common/formatDate";
import TermFilter from "../../../components/filter/TermFilter";
import
    PortfolioStateTable from "../../../components/portfolio/PortfolioStateTable";
import RdgTable from "../../../components/rdg/RdgTable";
import {sortFunctional} from "../../../common/tableHelpers";
import MultiSelectFilter from "../../../components/filter/MultiSelectFilter";
import Chart from "./Chart";
import {columns} from "../../portfolio/portfolioHistory/common";
import {PortfolioType} from "../../../common/constants";
import qs from 'qs';
import {getDailyAlphaSeries} from "./alpha";
import {getPortfolioOptionText} from "../../../common/portfolio";



export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        portfolios,
        loadingPortfolios,
        portfolioReturnsGroups,
        loadingPortfolioReturnsGroups,

    } = useSelector(state => state.portfolio, shallowEqual);

    useEffect(() => {
        const sort = {
            type: 1,
            is_backtest: 1
        }
        const filters = {};

        dispatch(fetchPortfolios({sort, filters}))

    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort', 'portfolioIds', 'startDate', 'endDate'],
        requestData: loadPortfolioReturnsGroups
    });

    if (searchProps.isFirstLoad === 'true') {
        if (!loadingPortfolios && portfolios.length > 0) {

            const doradoPortfolio = portfolios.find(p => p.is_backtest === false && p.type === PortfolioType.dorado);

            const benchmarkPortfolios = portfolios.filter(p => p.type === PortfolioType.backtest_benchmark);

            const portfolioIds = [
                ...doradoPortfolio !== undefined ? [doradoPortfolio.id] : [],
                ...benchmarkPortfolios.map(p => p.id)
            ];

            const redirectSearchProps = {
                portfolioIds: portfolioIds
            };
            const url = location.pathname + qs.stringify(redirectSearchProps, {addQueryPrefix: true})

            return <Redirect to={url}/>
        }
    }

    const portfolioOptions = portfolios.map(p => {
        return {
            value: p.id,
            text: getPortfolioOptionText(p),
            key: p.id
        }
    });


    const sidebar = (
        <div>
            <br/>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <PortfoliosSummaryTable portfolios={portfolios.filter(p => p.is_backtest === false)}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <br/>

            <Form>
                <MultiSelectFilter allowNullSelection={false}
                                   label={'Portfolios'}
                                   onChange={
                                       createAddValueToQuery({
                                           history,
                                           searchProps,
                                           fieldName: 'portfolioIds'
                                       })}
                                   options={portfolioOptions}
                                   values={searchProps.portfolioIds}

                />
                <TermFilter allowNullSelection={true}
                            label='Start Date'
                            options={startEndDateOptions}
                            value={searchProps.startDate}
                            onChange={
                                createAddValueToQuery({
                                history,
                                searchProps,
                                fieldName: 'startDate'
                            })}
                />
                <TermFilter allowNullSelection={true}
                            label='End Date'
                            options={startEndDateOptions}
                            value={searchProps.endDate}
                            onChange={
                                createAddValueToQuery({
                                history,
                                searchProps,
                                fieldName: 'endDate'
                            })}
                />
                <TermFilter allowNullSelection={true}
                            label='Show Alpha'
                            options={showAlphaOptions}
                            value={searchProps.showAlpha}
                            onChange={
                                createAddValueToQuery({
                                history,
                                searchProps,
                                fieldName: 'showAlpha'
                            })}
                />

            </Form>

        </div>
    );


    const alphaSeries = getDailyAlphaSeries({portfolioReturnsGroups});

    return (
        <ViewWithSidebar mainView={<Chart portfolioReturnsGroups={portfolioReturnsGroups}
                                          loading={loadingPortfolioReturnsGroups}
                                          showAlpha={searchProps.showAlpha}
                                          alphaSeries={alphaSeries}

        />}
                         sidebarView={sidebar}/>

    )

}


const loadPortfolioReturnsGroups = ({dispatch, searchProps}) => {

    dispatch(fetchPortfolioReturnsGroups({
        portfolioIds: searchProps.portfolioIds,
        startDate: searchProps.startDate,
        endDate: searchProps.endDate
    }));

}
