import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useLocation, useRouteMatch} from 'react-router'
import {Button, Container, Form, Grid, Header, Label} from 'semantic-ui-react'
import {formatFullDate, validMoment} from "../../common/format";
import {parseBoolean, parseSearchProps} from "../../common/query/parse";
import {fetchCompanyNote, fetchTargetPriceRecordsForCompany, patchCompanyNote} from "../../actions/companyNoteActions"
import {patchCompany} from "../../actions/tagActions"
import TargetPriceTable from './targetPrice/TargetPriceTable';
import TargetPriceView from './targetPrice/TargetPriceView';
import CurrencySelect from '../../components/currency/CurrencySelect';
import CompanyNameAndTickerHeader from '../../components/common/CompanyNameAndTickerHeader'
import BusinessQualityLabel from '../../components/QualityLabel';
import CompanyNoteTextField from './CompanyNoteTextField'
import {useReloadOnChange} from "../../common/hooks";
import IsPublicLabel from './IsPublicLabel'
import {modifyCompany} from "../../actions/companyActions";
import {fetchCompany, fetchLastSharePriceTradingForCompany} from "../../features/companyFeatureSlice";


const getSelectedCurrencyFromPropsAndCompany = ({searchProps, company, loadingCompany}) => {
    let selectedCurrencyType = searchProps.currencyType || 'Trading';

    if (loadingCompany) {
        return 'USD'
    }

    if (selectedCurrencyType === 'Trading') {
        return company.trading_currency
    }

    return 'USD'
};


export default () => {

    const dispatch = useDispatch();
    const match = useRouteMatch();
    const location = useLocation();

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['currencyType'],
        matchFields: ['id'],
        requestData
    });

    const {id} = match.params;

    const {company, loadingCompany} = useSelector(state => state.company, shallowEqual);

    const {companyNote, loadingCompanyNote, saveNoteChangesPending} = useSelector(state => state.companyStore, shallowEqual);

    const searchProps = parseSearchProps({searchString: location.search});

    const selectedCurrency = getSelectedCurrencyFromPropsAndCompany({
        company,
        loadingCompany,
        searchProps
    });

    const lastUpdatedSpan = loadingCompanyNote ? null :
        <span>{'Note Last Updated ' + formatFullDate(companyNote.last_modified)}</span>;

    const onSubmit = () => {
        patchCompanyNote({dispatch, id, companyNote});
    };

    const loadingForSubmitButton = loadingCompany || loadingCompanyNote || saveNoteChangesPending;


    const isPreIpoDiv = company.is_pre_ipo ? <Label color={'red'}>Is Pre IPO</Label> : null;


    return (
        <Container>
            <Grid padded>
                <Grid.Column width={4}>
                    <Grid.Row>
                        <Grid.Column>
                            <CompanyNameAndTickerHeader companyName={company.company_name}
                                                        exchangeTicker={company.exchange_ticker}
                                                        loading={loadingCompany}
                            />
                            <IsPublicLabel/>
                            {isPreIpoDiv}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <CompanyInfo company={company} loadingCompany={loadingCompany}/>
                    </Grid.Row>
                </Grid.Column>

                <Grid.Column width={12}>

                    <Header as='h3'>Target Prices</Header>

                    <TargetPriceView selectedCurrency={selectedCurrency}/>
                    <br/>
                    <TargetPriceTable/>
                    <br/>

                    <Form onSubmit={onSubmit}>
                        <Button type='submit' loading={loadingForSubmitButton}>Save Changes</Button>
                        {lastUpdatedSpan}
                        <br/>
                        <br/>
                        <CompanyNoteTextField label='Simple Description' fieldName='simple_description'/>
                        <CompanyNoteTextField label='LBO Note' fieldName='lbo_note'/>
                        <CompanyNoteTextField label='Engagement Note' fieldName='engagement_note'/>
                        <CompanyNoteTextField label='AKKR Coverage Initials' fieldName='akkr_coverage'/>

                        <CompanyNoteTextField label='Activists' fieldName='activists'/>
                        <CompanyNoteTextField label='Activist Campaign Note' fieldName='activist_campaign_note'/>
                        <CompanyNoteTextField label='Activist Campaign Note as of date' fieldName='activist_campaign_note_as_of_date'/>


                        <CompanyNoteTextField label='Target Price Notes' fieldName='target_price_note'/>
                        <CompanyNoteTextField label='Revenue Model, Accounting, Contract Length'
                                              fieldName='revenue_model_note'/>
                        {/*<CompanyNoteTextField label='Management Notes' fieldName='management_note'/>*/}
                        {/*<CompanyNoteTextField label='Business Description' fieldName='business_description'/>*/}
                        <CompanyNoteTextField label='Retention Notes' fieldName='retention_note'/>
                        {/*<CompanyNoteTextField label='S&M Notes' fieldName='sales_and_marketing_note'/>*/}
                        {/*<CompanyNoteTextField label='Ownership Notes' fieldName='ownership_note'/>*/}

                    </Form>
                </Grid.Column>
            </Grid>
        </Container>
    )
}

const requestData = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    const currencyType = searchProps.currencyType || 'Trading';

    dispatch(fetchCompany({companyId: id}));
    dispatch(fetchLastSharePriceTradingForCompany({companyId: id}))
    fetchCompanyNote({dispatch, companyId: id});

    fetchTargetPriceRecordsForCompany({dispatch, companyId: id, currencyType});
};

const CompanyInfo = ({company, loadingCompany}) => {

    if (loadingCompany) {
        return <div/>
    }

    return <div>
        <div>
            Business Quality: <BusinessQualityLabel labelSize={'small'} value={company.business_quality}/>
        </div>
        <br/>
        <div>
            Primary Revenue Model: <b>{company.primary_revenue_model}</b>
        </div>
        <br/>
        <div>
            Primary Market Type: <b>{company.primary_market_type}</b>
        </div>
        <br/>
        <div>
            Filing Currency: <b>{company.filing_currency}</b>
        </div>
        <br/>
        <div>
            Trading Currency: <b>{company.trading_currency}</b>
        </div>
        <br/>

        <div>Year founded: {company.year_founded}</div>
        <br/>
        <div>
            IPO Date: {validMoment(company.ipo_date, 'M/D/YYYY')}
        </div>
        <br/>
        <div>
            HQ: {company.headquarters_address}
        </div>


    </div>
};