import React, {Component} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Search, Form} from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import {createAddValueToQuery, createSetFieldValueOnQuery} from '../common/query/add';
import {parseSearchProps} from "../common/query/parse";
import {useHistory, useLocation} from "react-router-dom";
import {startImportProcessForCompany} from "../features/adminFeatureSlice";
import {fetchQueueData} from "../actions/metadataActions";
import {searchForCompany} from "../features/companySearchFeatureSlice";


const fieldSpecs = {
    companySearchText: {
        parse: (value) => {
            if (value == null) {
                return ''
            }
            return value
        }
    }
};


export default ({disabled}) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const {searchResults, loadingSearchResults} = useSelector(state => state.companySearch, shallowEqual);

    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    let results = searchResults.map(result => {

        let title = null;
        if (result.highlight && result.highlight.company_name_and_ticker.length > 0) {

            // title is an html string
            title = result.highlight.company_name_and_ticker[0];

            title = title.split('<em>').join('<b>');
            title = title.split('</em>').join('</b>');
            title = ReactHtmlParser(title);

        } else {
            title = ReactHtmlParser(result._source.company_name_and_ticker);
        }

        title = title.map((t, i) => {
            if (typeof t === "string") {
                return <span key={i}>{t}</span>
            }
            return t
        });

        // title must be one element to avoid bugs
        title = <span key={result._id}>{title}</span>;

        return {
            id: result._id,
            key: result._id,
            title: title,
            company_name: result._source.company_name
        }

    });

    const addSearchTextToQuery = createAddValueToQuery({history, searchProps, fieldName: 'companySearchText'});

    const handleSearchChange = (e, data) => {

        addSearchTextToQuery(e, data);
        dispatch(searchForCompany({text: data.value}));
    };

    const clearSearchBar = createSetFieldValueOnQuery({history, searchProps, fieldName: 'companySearchText', value: ''});

    const onResultSelect = (event, data) => {
        const company = data.result;
        clearSearchBar(event, data);

        dispatch(startImportProcessForCompany({companyId: company.id})).then(() => {
            return fetchQueueData({dispatch})
        });

    };

    const resultRenderer = ({title}) => <span key={title}>{title}</span>;

    return (
        <Search
                fluid
                disabled={disabled}
                loading={loadingSearchResults}
                onSearchChange={handleSearchChange}
                value={searchProps.companySearchText}
                results={results}
                onResultSelect={onResultSelect}
                resultRenderer={resultRenderer}
                minCharacters={0}
            />

        // <Form.Field>
        //     <label>Run Data Import for Specific Company</label>
        //
        // </Form.Field>
        )
}

