import React from 'react'
import {
    formatFullDate,
    formatMultipleOneDecimal,
    formatMultipleTwoDecimals, formatNoDecimalNoNull,
    formatNoDecimalNoNullOrZero,
    formatOneDecimalNoNull,
    formatPercentFourDecimalNoNull,
    formatPercentNoNull,
    formatPercentOneDecimalNoNull,
    formatSharePrice,
    formatSharePriceFixedDigits, formatTwoDecimalCurrencyNoZero,
    formatTwoDecimalNoNull,
    formatYear,
    formatThreeDecimalNoNull, formatPercentTwoDecimalNoNull
} from '../format'
import {Icon, Label} from 'semantic-ui-react'
import {Link} from 'react-router-dom';
import {getLabelFromCostCenter} from '../../common/employee/roles'
import QualityLabel from '../../components/QualityLabel'
import {qualityTextByValue} from '../../common/constants'
import {formatFullDateTimeUtcToLocal} from "../formatDate";
import {DoradoUniverseTypeByInteger} from "../constants";
import numeral from "numeral";


const greenTextStyle = {
    'fontWeight': 'bold',
    'color': '#006100'
};

const greenHighlightStyle = {
    'fontWeight': 'bold',
    'backgroundColor': '#C6EFCE',
    'color': '#006100'
};


const redTextStyle = {
    'fontWeight': 'bold',
    'color': '#9C0006'
};

const redHighlightStyle = {
    'fontWeight': 'bold',
    'backgroundColor': '#FFC7CE',
    'color': '#9C0006'
};


export class StringFormatter extends React.Component {

    render() {
        return <div className="align-left">{this.props.value}</div>
    }
}

export class CompanyLinkFormatter extends React.Component {
    render() {

        if (this.props.value.isHeader) {
            return <div style={{'fontWeight': 'bold'}}>
                {this.props.value.display_name}
            </div>
        }

        if (this.props.value.company_id == null) {
            return <div>{this.props.value.display_name}</div>
        }

        return getCompanyLink({companyId: this.props.value.company_id, companyName: this.props.value.display_name})
    }
}

export const getCompanyLink = ({companyId, companyName}) => {
    return <Link to={'/company_detail/overview/' + companyId}>{companyName}</Link>
}


export class YearFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatYear(this.props.value)}</div>
    }
}

export class UtcDateFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatFullDate(this.props.value, true)}</div>
    }
}

export class DateFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatFullDate(this.props.value, false)}</div>
    }
}



export class UtcToLocalFullDateTimeFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatFullDateTimeUtcToLocal(this.props.value)}</div>
    }
}


export class BoolToYesFlagFormatter extends React.Component {
    render() {

        if (this.props.value == null || this.props.value === false || this.props.value === 'False') {
            return <div/>
        }

        const color = this.props.color || 'orange';
        const icon = this.props.icon || "exclamation triangle";

        return (
            <div className="align-center">
                <Icon color={color} name={icon}>
                </Icon>
            </div>)
    }
}


export class BoolToNoFlagFormatter extends React.Component {
    render() {

        if (this.props.value == null || this.props.value === false || this.props.value === 'False') {

            const color = this.props.color || 'orange';

            return (
            <div className="align-center">
                <Icon color={color} name="exclamation triangle">
                </Icon>
            </div>)
        }

        return <div/>
    }
}

export class BoolToNoTextFormatter extends React.Component {
    render() {

        if (this.props.value == null || this.props.value === false || this.props.value === 'False') {
            return <div className="align-center">No</div>
        }

        return <div/>
    }
}



export class BoolToYesCheckFormatter extends React.Component {
    render() {

        if (this.props.value == null || this.props.value === false || this.props.value === 'False') {
            return <div/>
        }

        const color = this.props.color || 'green';
        const iconName = this.props.iconName || 'check';

        return (
            <div className="align-center">
                <Icon color={color} name={iconName}>
                </Icon>
            </div>)
    }
}

export class BoolToYesNoCheckFormatter extends React.Component {
    render() {

        if (this.props.value == null) {
            return <div/>
        }

        if (this.props.value === false || this.props.value === 'False') {
            return (
                <div className="align-center">
                    <Icon color='red' name="x" size="large">
                    </Icon>
                </div>
            )
        }

        const yesColor = this.props.yesColor ? this.props.yesColor : 'green';

        return (
            <div className="align-center">
                <Icon color={yesColor} name="check square" size="large">
                </Icon>
            </div>)
    }
}


export class BoolToYesFormatter extends React.Component {
    render() {

        if (this.props.value == null || this.props.value === false || this.props.value === 'False') {
            return <div/>
        }

        return (
            <div className="align-center">
                Yes
            </div>)
    }
}

export class BoolToYesNoFormatter extends React.Component {
    render() {

        if (this.props.value == null) {
            return <div/>
        }

        if (this.props.value === false || this.props.value === 'False' || this.props.value === 'false') {
            return (
            <div className="align-center">
                No
            </div>)
        }

        return (
            <div className="align-center">
                Yes
            </div>)
    }
}


export class BoolToYesNoGrayFormatter extends React.Component {
    render() {

        if (this.props.value == null) {
            return <div/>
        }

        if (this.props.value === false || this.props.value === 'False' || this.props.value === 'false') {
            return (
            <div className="align-center" style={{color: 'grey'}}>
                No
            </div>)
        }

        return (
            <div className="align-center">
                Yes
            </div>)
    }
}



export class CostCenterFormatter extends React.Component {
    render() {
        return (<div className="align-center">
            {getLabelFromCostCenter({costCenter: this.props.value})}
        </div>)
    }
}


export class NoDecimalNoNullOrZeroFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatNoDecimalNoNullOrZero(this.props.value)}</div>
    }
}


export class NoDecimalNoNullFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatNoDecimalNoNull(this.props.value)}</div>
    }
}




export class OneDecimalNoNullFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatOneDecimalNoNull(this.props.value)}</div>
    }
}

export class TwoDecimalNoNullFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatTwoDecimalNoNull(this.props.value)}</div>
    }
}

export class ThreeDecimalNoNullFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatThreeDecimalNoNull(this.props.value)}</div>
    }
}

export class TwoDecimalCurrencyFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatTwoDecimalCurrencyNoZero(this.props.value)}</div>
    }
}


export class PercentOneDecimalNoNullFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatPercentOneDecimalNoNull(this.props.value)}</div>
    }
}

export class PricePercentChangeOneDecimalFormatter extends React.Component {

    render() {

        let color = null;
        if (this.props.value > .01) {
            color = 'green'
        }
        if (this.props.value < -.01) {
            color = 'red'
        }

        return <div className="align-right" style={{color: color}}>{formatPercentOneDecimalNoNull(this.props.value)}</div>
    }
}


export class PercentChangeOneDecimalPositiveNegativeFormatter extends React.Component {

    render() {

        let color = null;
        if (this.props.value > 0) {
            color = 'green'
        } else {
            color = 'red'
        }

        return <div className="align-right" style={{color: color}}>{formatPercentOneDecimalNoNull(this.props.value)}</div>
    }
}


export class PercentChangeTwoDecimalPositiveNegativeFormatter extends React.Component {

    render() {

        let color = null;
        if (this.props.value > 0) {
            color = 'green'
        } else {
            color = 'red'
        }

        return <div className="align-right" style={{color: color}}>{formatPercentTwoDecimalNoNull(this.props.value)}</div>
    }
}




export class PercentNoDecimalNoNullFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatPercentNoNull(this.props.value)}</div>
    }
}

export class PercentFourDecimalNoNullFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatPercentFourDecimalNoNull(this.props.value)}</div>
    }
}



export class SharePriceFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatSharePriceFixedDigits(this.props.value)}</div>
    }
}

export class SharePriceFourDecimalFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatSharePrice(this.props.value)}</div>
    }
}


export class PositiveNegativeTwoDecimalFormatter extends React.Component {
    render() {

        const style = {};
        if (this.props.value < 0) {
            style['color'] = '#9C0006';
        }


        return <div className="align-right" style={style}>{formatSharePriceFixedDigits(this.props.value)}</div>
    }
}

export class MultipleOneDecimalFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatMultipleOneDecimal(this.props.value)}</div>
    }
}

export class MultipleTwoDecimalFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatMultipleTwoDecimals(this.props.value)}</div>
    }
}



export class PercentFromPriceFormatter extends React.Component {
    render() {

        const style = this.props.isRed ? redHighlightStyle : greenHighlightStyle;

        let text = formatPercentNoNull(this.props.value);
        if (this.props.text) {
            text = this.props.text + " " + text;
        }

        if (this.props.value < 0) {
            return (
                <div className="align-right" style={style}>{text}</div>
            )
        }

        return (
            <div className="align-right">{text}</div>
        )
    }
}

export class PercentFromNearestFormatter extends React.Component {
    render() {

        const {percent_from_nearest_recommendation, nearest_recommendation} = this.props.value;

        if (nearest_recommendation === 'Sell') {
            return <PercentFromPriceFormatter value={percent_from_nearest_recommendation} isRed={true} text={'short'}/>
        }

        return <PercentFromPriceFormatter value={percent_from_nearest_recommendation}/>

    }
}


export class RelativeWeightFormatter extends React.Component {
    render() {

        const color = this.props.value > 0 ? 'green' : '#9C0006';
        const percent = Math.abs(this.props.value) * 100;

        let style = {
            background: 'linear-gradient(to right, ' + color + ', ' + color + ' ' + percent + '%, #ffffff ' + percent + '%)',
            width: '100%'
        };

        return (
            <div style={style}>&nbsp;</div>
        )


    }
}

export class WeightFormatter extends React.Component {
    render() {
        let style = {
            fontWeight: 'bold',
        };

        return (
            <div className="align-right" style={style}>
                {formatPercentOneDecimalNoNull(this.props.value)}
            </div>
        )

    }
}


export class PercentGainsFormatter extends React.Component {
    render() {

        let style = {
            fontWeight: 'bold',
        };

        const highlightPercent = .2;

        if (this.props.value > highlightPercent) {
            style = {...style, ...greenTextStyle}
        }

        if (this.props.value < -highlightPercent) {
            style = {...style, ...redTextStyle}
        }

        return (
            <div className="align-right" style={style}>{formatPercentNoNull(this.props.value)}</div>
        )

    }
}


export class PercentGainOneDecimalFormatter extends React.Component {
    render() {

        let style = {
            fontWeight: 'bold',
        };

        const highlightPercent = 0;

        if (this.props.value > highlightPercent) {
            style = {...style, ...greenTextStyle}
        }

        if (this.props.value < -highlightPercent) {
            style = {...style, ...redTextStyle}
        }

        return (
            <div className="align-right" style={style}>{formatPercentOneDecimalNoNull(this.props.value)}</div>
        )

    }
}





export class BusinessQualityFormatter extends React.Component {
    render() {

        const labelSize = this.props.labelSize;
        let text = <div/>;

        switch (this.props.value) {
            case 'Great':
                text = <Label color="green" size={labelSize}>{this.props.value}</Label>;
                break;
            case 'Good':
                text = <Label color="blue" size={labelSize}>{this.props.value}</Label>;
                break;
            case 'Average':
                text = <Label color="grey" size={labelSize}>{this.props.value}</Label>;
                break;
            case 'Bad':
                text = <Label color="orange" size={labelSize}>{this.props.value}</Label>;
                break;
            case 'Terrible':
                text = <Label color="red" size={labelSize}>{this.props.value}</Label>;
                break;

            default:
                text = <div/>;


        }

        return (
            <div className="align-center">
                {text}
            </div>)
    }
}


export class ManagementQualityFormatter extends React.Component {
    render() {

        const labelSize = this.props.labelSize;
        const textValue = qualityTextByValue[this.props.value];


        return (
            <div className="align-center">
                <QualityLabel labelSize={labelSize} value={textValue}/>
            </div>)
    }
}


export class DoradoUniverseTypeFormatter extends React.Component {
    render() {
        return <div>
            {DoradoUniverseTypeByInteger[this.props.value]}
        </div>

    }
}


export class PercentTwoDecimalNoZeroFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatTwoDecimalPercentNoZero(this.props.value)}</div>
    }
}

export class PercentTwoDecimalNoNullFormatter extends React.Component {
    render() {
        return <div className="align-right">{formatPercentTwoDecimalNoNull(this.props.value)}</div>
    }
}



export const formatTwoDecimalPercentNoZero = (value) => {
    if (value == null || value === '') {
        return ''
    }

    if (value === 0) {
        return ''
    }

    return numeral(value).format('(0.00%)')
};
