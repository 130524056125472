import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Loader, Statistic, Dimmer, Progress, Table} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../common/request/convert';
import ViewWithSidebar from '../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../common/query/parse";
import {
    fetchIgvConstituentWeightRecords,
    fetchRealTimeDoradoValuationRecords, fetchRealTimeSecurityPriceRecords
} from '../../features/dorado/realTimeDoradoValuationRecords'
import {startRefreshRealTimeDoradoValuationRecords} from '../../features/pollingProcessFeatureSlice'

import {columns} from "./common";
import RdgTable from "../../components/rdg/RdgTable";
import {fetchPointSurDoradoValuationRecords} from "../../features/dorado/doradoValuationRecords";
import {fetchDoradoPortfolioTradeMetadata} from "../../features/dorado/doradoPortfolio";
import {getPricesAsOfText} from "../dorado/common";
import {series1ChartSpecifications} from "../dorado/backtestCharts/common";
import {orderedIndexIds} from "../dorado/realTimeValuation/common";
import {PricePercentChangeOneDecimalFormatter} from "../../common/reactDataGrid/formatters";
import {formatSharePrice} from "../../common/format";
import {
    fetchCurrentIndexConstituentPositionsForEtf,
    fetchRealTimeEtfTradeAssessmentRecord
} from "../../features/indexConstituentsFeatureSlice";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const {
        indexConstituentPositions,
        loadingIndexConstituentPositions,
        etfTradeAssessmentRecord,
        loadingEtfTradeAssessmentRecord,
    } = useSelector(state => state.indexConstituents, shallowEqual);

    const {
        doradoPortfolioTradeMetadata,
        loadingDoradoPortfolioTradeMetadata
    } = useSelector(state => {return state.doradoPortfolio}, shallowEqual);

    console.log(etfTradeAssessmentRecord)
    console.log(indexConstituentPositions)

    useEffect(() => {
        dispatch(fetchDoradoPortfolioTradeMetadata());

    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });


    let etfRows = [];

    if (etfTradeAssessmentRecord.security_listing != null) {
        etfRows = [etfTradeAssessmentRecord].map(r => {


            return (
                <Table.Row key={r.id}>
                    <Table.Cell>
                        {r.security_listing.ui_display_name}
                    </Table.Cell>
                    <Table.Cell>
                        <PricePercentChangeOneDecimalFormatter value={r.price_change_from_last_close}/>
                    </Table.Cell>
                    <Table.Cell>
                        {formatSharePrice(r.last_price)}
                    </Table.Cell>
                </Table.Row>
            )
        })
    }


    const sidebar = (
        <div>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Statistic horizontal value={indexConstituentPositions.length} label='Records' size="tiny"/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {getPricesAsOfText(doradoPortfolioTradeMetadata)}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider horizontal>ETFs</Divider>
                        <Table basic='very' collapsing compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        ETF
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        % Change (day)
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        $/share
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {etfRows}
                            </Table.Body>
                        </Table>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );

    let cumulativeTotal = 0;
    let cumulativeContribution = 0;
    const modifiedRecords = indexConstituentPositions.map(r => {

        const contributionPercent = r.trade_assessment_record.price_change_from_last_close ? r.weight * r.trade_assessment_record.price_change_from_last_close : 0;

        cumulativeTotal = cumulativeTotal + r.weight;
        cumulativeContribution = cumulativeContribution + contributionPercent;

        return {
            ...r,
            cumulative_weight: cumulativeTotal,
            contribution_percent: contributionPercent,
            cumulative_contribution: cumulativeContribution
        }

    })

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             loading={loadingIndexConstituentPositions}
                                             records={modifiedRecords}/>}
                         sidebarView={sidebar}/>
    )

}


const requestData = ({dispatch, searchProps, matchParams}) => {

    const {etf_id} = matchParams
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = {
        ...convertFiltersToFeathersFilters({
            filters: searchProps.filters,
            parseSpecByField: {}
        })
    };
    dispatch(fetchCurrentIndexConstituentPositionsForEtf({
        etfId: etf_id,
        sort,
        filters}));
    dispatch(fetchRealTimeEtfTradeAssessmentRecord({etfId: etf_id}));
};