import {
    getTotalImputedArrBridge,
    getArrCompositionBridges,
    getBalanceSheetRows,
    getCashFlowRows,
    getDilutionAnalysisRows,
    getGrossMarginRows,
    getGrossProfitAndExpenseRows,
    getMarginRows,
    getPeriodRow,
    getRevenueCompositionAndTotalDollarGrowthRows,
    getRevenueCompositionAndTotalGrowthRows,
    getRevenueCompositionAndTotalRows,
    getShareComponents,
    getEBITRows,
    getExecutionAssessmentRows,
    getChangeInCashRows,
    getTaxableIncomeRows,
    getPretaxFCFRows,
    getRevenueGrowthRows,
    get605ReconciliationRows, getActualArrBridge
} from "../../../../components/companyFinancials/components";
import {getCostDetailType} from "../../../../components/companyValuation/financials/common";
import {
    createSectionHeaderRow,
    emptyRow,
    headerRowHeight,
    rowHeight
} from "../../../../components/companyFinancials/common";
import {getTableClassName} from "../../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {getWindowHeightLessMenuBar} from "../../../../common/common";
import React from "react";
import {ModelRowRenderer} from "../../../../components/rdg/rdgComponents";
import {createRow} from "../../../../components/rdg/helpers";
import {getClassToColorEBITDA} from "../../../../common/colors";


export default ({financialRecords, loading}) => {


    const periodRow = getPeriodRow({financialRecords});

    const costDetailType = getCostDetailType(financialRecords);

    const dataRows = [
        ...getRevenueCompositionAndTotalRows({financialRecords}),
        emptyRow(),
        ...getGrossProfitAndExpenseRows({
            records: financialRecords,
            costDetailType: costDetailType
        }),
        ...getEBITRows({financialRecords}),

        emptyRow(),
        ...getPretaxFCFRows({financialRecords}),
        emptyRow(),

        ...getRevenueGrowthRows({financialRecords}),
        emptyRow(),
        createSectionHeaderRow({headerText: 'Margins'}),
        emptyRow(),
        ...getGrossMarginRows({financialRecords}),
        ...getMarginRows({financialRecords}),
        emptyRow(),
        ...getTotalImputedArrBridge({financialRecords}),
        emptyRow(),
        ...getActualArrBridge({financialRecords}),
        emptyRow(),
        ...getArrCompositionBridges({financialRecords}),
        emptyRow(),

        ...getExecutionAssessmentRows({financialRecords}),
        emptyRow(),
        ...getTaxableIncomeRows({financialRecords}),
        emptyRow(),
        ...getChangeInCashRows({financialRecords}),
        emptyRow(),
        ...getBalanceSheetRows({financialRecords}),
        emptyRow(),
        ...getDilutionAnalysisRows({financialRecords}),
        emptyRow(),
        ...getShareComponents({financialRecords}),

        emptyRow(),
        ...get605ReconciliationRows({financialRecords}),


    ];

    return (
        <div className={getTableClassName(loading) + ' align-right'}>

            <ReactDataGrid
                columns={periodRow}
                rowGetter={index => dataRows[index]}
                rowsCount={dataRows.length}
                headerRowHeight={headerRowHeight}
                rowHeight={rowHeight}
                minHeight={getWindowHeightLessMenuBar()}
                rowRenderer={ModelRowRenderer}
            />

        </div>
    )

}

