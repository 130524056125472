import moment from "moment";
import React from "react";
import {Icon, Label} from "semantic-ui-react";

export const fieldSpecs = {};


const dateDomainMoments = [
    moment("20180101", "YYYYMMDD"),
    moment("20181001", "YYYYMMDD"),
    moment("20190201", "YYYYMMDD"),
    moment("20200218", "YYYYMMDD"),
    moment("20200430", "YYYYMMDD"),
    moment("20201101", "YYYYMMDD"),
    moment("20210101", "YYYYMMDD")

];

export const dateDomain = dateDomainMoments.map(d => d.valueOf());

export const colorRange = [
    "#ece2f0",
    "#c7e9b4",
    "#f46d43",
    "#c994c7",

    "#d73027",
    "#8c6bb1",

    "#5aae61",
    "#225ea8"
];


export const YearLegend = () => {

    const legendItems = dateDomainMoments.map((dt, i) => {

        const previousDate = i === 0 ? null : dateDomainMoments[i-1];

        const color = colorRange[i];
        return <LegendItem dateMoment={dt}
                           previousDate={previousDate}
                           color={color}
                           key={color}/>
    }).reverse();

    return (
        <div>
            {legendItems}
        </div>
    )
}

const LegendItem = ({dateMoment, previousDate, color}) => {

    const style = {
        color: color
    };

    let text = '';

    if (previousDate == null) {
        text = 'Up to ' + dateMoment.format("MM/DD/YYYY");
    } else {
        text = previousDate.format("MM/DD/YYYY") + ' - ' + dateMoment.format("MM/DD/YYYY");
    }

    return (
        <div key={dateMoment.format("MM-DD-YYYY")}>
            <Label basic horizontal>
                <Icon name={'square'} style={style}/>
                {text}
            </Label>

        </div>
    )
}

