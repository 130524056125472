import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Loader, Statistic, Dimmer, Progress, Table} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {
    fetchRealTimeDoradoValuationRecords
} from '../../../features/dorado/realTimeDoradoValuationRecords'
import {startRefreshRealTimeDoradoValuationRecords} from '../../../features/pollingProcessFeatureSlice'

import {fetchDoradoPortfolioTradeMetadata} from '../../../features/dorado/doradoPortfolio'
import {columns, getEtfSortKey, getTopAndBottomReturns, orderedIndexIds} from "./common";
import RdgTable from "../../../components/rdg/RdgTable";
import {formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import {getPricesAsOfText} from "../common";
import StartProcessWithPollingButton from "../../../components/StartProcessWithPollingButton";
import {usePollForCompletion} from "../../../common/pollingHooks";
import {PricePercentChangeOneDecimalFormatter} from "../../../common/reactDataGrid/formatters";
import {formatSharePrice} from "../../../common/format";
import {sortFunctional} from "../../../common/tableHelpers";
import {createAddCheckedValueToQuery} from "../../../common/query/add";
import {fieldSpecs} from './common'
import doradoValuationRecords from "../../../features/dorado/doradoValuationRecords";




export default () => {

    const {
        realTimeDoradoValuationRecords,
        loadingRealTimeDoradoValuationRecords,
    } = useSelector(state => state.realTimeDoradoValuation, shallowEqual);

    console.log(realTimeDoradoValuationRecords)

    const {
        doradoPortfolioTradeMetadata,
        loadingDoradoPortfolioTradeMetadata
    } = useSelector(state => {return state.doradoPortfolio}, shallowEqual);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    useReloadOnChange({
        fieldSpecs,
        queryMatchFields: ['filters', 'sort', 'showAlternativeListings'],
        requestData,
    });

    usePollForCompletion({
        onComplete: requestFullRefresh
    });

    useEffect(() => {
        dispatch(fetchDoradoPortfolioTradeMetadata())
    }, [dispatch]);

    const etfRecords = realTimeDoradoValuationRecords.filter(r => r.security_listing.ui_is_company === false).sort((a, b) => {
        return getEtfSortKey(a.security_listing.exchange_traded_fund_id) > getEtfSortKey(b.security_listing.exchange_traded_fund_id) ? 1 : -1
    });

    const companyRecords = realTimeDoradoValuationRecords.filter(r => r.security_listing.ui_is_company === true);

    const validCompanyRecords = companyRecords.filter(r => r.entry_price_per_share_trading != null);
    const inValidCompanyRecords = companyRecords.filter(r => r.entry_price_per_share_trading == null);

    const etfRows = etfRecords.map(r => {

        return (
            <Table.Row key={r.id}>
                <Table.Cell>
                    {r.security_listing.ui_display_name}
                </Table.Cell>
                <Table.Cell>
                    <PricePercentChangeOneDecimalFormatter value={r.price_change_from_last_close}/>
                </Table.Cell>
                <Table.Cell>
                    {formatSharePrice(r.last_price)}
                </Table.Cell>
            </Table.Row>
        )
    })

    const moverRows = getTopAndBottomReturns({doradoRealTimeValuationRecords: companyRecords}).map(r => {
        return (
            <Table.Row key={r.id}>
                <Table.Cell>
                    {r.security_listing.ui_display_name}
                </Table.Cell>
                <Table.Cell>
                    <PricePercentChangeOneDecimalFormatter value={r.price_change_from_last_close}/>
                </Table.Cell>
                <Table.Cell>
                    {formatSharePrice(r.last_price)}
                </Table.Cell>
            </Table.Row>
        )
    });

    const sidebar = (
        <div>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Statistic horizontal value={validCompanyRecords.length} label='Records' size="tiny"/>
                        <Statistic horizontal value={inValidCompanyRecords.length} label='Invalid' size="tiny"/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {getPricesAsOfText(doradoPortfolioTradeMetadata)}
                    </Grid.Column>
                </Grid.Row>
                <StartProcessWithPollingButton expectedCompletionSeconds={20}
                                               onClick={() => dispatch(startRefreshRealTimeDoradoValuationRecords())}
                />
                <Grid.Row>
                    <Grid.Column>
                        <Divider horizontal>ETFs</Divider>
                        <Table basic='very' collapsing compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        ETF
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        % Change (day)
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        $/share
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {etfRows}
                            </Table.Body>
                        </Table>

                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider horizontal>Movers</Divider>
                        <Table basic='very' collapsing compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Company
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        % Change (day)
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        $/share
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {moverRows}
                            </Table.Body>
                        </Table>
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <Form.Checkbox label="Show Alternative Listings"
                                           checked={searchProps.showAlternativeListings}
                                           onChange={createAddCheckedValueToQuery({
                                               history,
                                               searchProps,
                                               fieldName: 'showAlternativeListings'
                                           })}
                            />
                        </Form>
                    </Grid.Column>

                </Grid.Row>




            </Grid>
        </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             loading={loadingRealTimeDoradoValuationRecords}
                                             records={realTimeDoradoValuationRecords}/>}
                         sidebarView={sidebar}/>
    )

}


const requestFullRefresh = ({dispatch, searchProps}) => {
    requestData({dispatch, searchProps});
    dispatch(fetchDoradoPortfolioTradeMetadata());
}


const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = {
        ...convertFiltersToFeathersFilters({
            filters: searchProps.filters,
            parseSpecByField: {}
        })
    };

    if (searchProps.showAlternativeListings !== true) {
        filters.is_alternative_listing = false
    }

    dispatch(fetchRealTimeDoradoValuationRecords({sort, filters}));
};
