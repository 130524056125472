import client from '../benchClient';

const dataQualityRecordsService = client.service('data_quality_records');
const dataQualityRecordsFacetService = client.service('facets/data_quality_records');


export function fetchDataQualityRecords({dispatch, sort, filters}) {

    return dispatch({
        type: 'FETCH_DATA_QUALITY_RECORDS',
        payload: dataQualityRecordsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    });

}


export function fetchDataQualityFacets({dispatch}) {
    return dispatch({
        type: 'FETCH_DATA_QUALITY_RECORDS_FACETS',
        payload: dataQualityRecordsFacetService.find()
    });
}


