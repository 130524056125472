import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Button, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../common/request/convert';
import FiltersComponent from './TableFiltersComponent'
import {parseGteYearToDate, parseLteYearToDate, parseMillions} from '../../common/filter/parse';
import ViewWithSidebar from '../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../common/hooks";
import ShowHideColumnsComponent from '../../components/showHideColumns/ShowHideColumnsComponent'
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../common/query/parse";
import {createAddValueToQuery} from "../../common/query/add";
import RdgTable from "../../components/rdg/RdgTable";
import {fetchSummaryValuationRecords} from "../../features/summaryValuationFeatureSlice";
import {allTableColumns} from "./common";





export default () => {

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs: {}});

    const {summaryValuationRecords, loadingSummaryValuationRecords} = useSelector(state => state.summaryValuation, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort'],
        requestData
    });

    const sidebar = (
        <div>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={summaryValuationRecords.length} label='Companies' size="tiny"/>
                </Grid.Row>
            </Grid>
            <FiltersComponent/>
        </div>
    );


    return (
        <ViewWithSidebar mainView={<RdgTable loading={loadingSummaryValuationRecords}
                                             records={summaryValuationRecords}
                                             columns={allTableColumns}/>}
                         sidebarView={sidebar}/>
    )

}


const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);
    const filters = convertFiltersToFeathersFilters({
        filters: searchProps.filters,
        parseSpecByField: {
            diluted_market_value: {parse: parseMillions},
            diluted_enterprise_value: {parse: parseMillions}
        }
    })

    dispatch(fetchSummaryValuationRecords({sort, filters}));
};

