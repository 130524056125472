import {createSlice} from '@reduxjs/toolkit'

import client from '../benchClient';
import {makeStandardReducers, executeWithPending} from "./common";
const dataImportService = client.service('data_import');
const allPointSurForecastAndValuationsService = client.service('execute_point_sur_dorado_valuations');
const doradoPortfolioBacktestService = client.service('execute_dorado_portfolio_backtest');
const downloadFtpAndUpdatePortfolioService = client.service('import_and_process_portfolio');
const refreshPortfolioFromTradeFilesService = client.service('update_portfolio_from_trade_files');
const valuationMetadataService = client.service('valuation_metadata');
const doradoPortfolioBacktestSensitivityService = client.service('execute_dorado_portfolio_backtest_sensitivities');
const doradoMirrorService = client.service('execute_dorado_mirror_backtest');
const newCompaniesFromCiqIdService = client.service('new_company_from_ciq_id');
const queueDataService = client.service('process_queue_summary');
const portfolioMetadataService = client.service('portfolio_metadata');
const refreshCiqService = client.service('execute_run_update_companies_and_securities_and_cache_share_prices');
const companyValidationSummariesService = client.service('company_validation_summaries')


const adminFeatureSlice = createSlice({
    name: 'admin',
    initialState: {
        importStartedAcknowledgement: null,
        loadingImportStartedAcknowledgement: false,

        valuationMetadata: {},
        portfolioMetadata: {},
        companyValidationSummaries: [],
        queueData: {
            finished_jobs: [],
            started_jobs: [],
            queued_jobs: [],
            failed_jobs: [],
            ciq: {
                finished_jobs: [],
                started_jobs: [],
                queued_jobs: [],
                failed_jobs: [],
            }
        }
    },
    reducers: {
        ...makeStandardReducers({
            prefix: 'startDataImportProcess',
            destinationVariable: 'importStartedAcknowledgement'
        }),
        ...makeStandardReducers({
            prefix: 'startPointSurDoradoForecastAndValuation',
            destinationVariable: 'importStartedAcknowledgement'
        }),
        ...makeStandardReducers({
            prefix: 'startDoradoPortfolioBacktest',
            destinationVariable: 'importStartedAcknowledgement'
        }),
        ...makeStandardReducers({
            prefix: 'startDoradoMirrorBacktest',
            destinationVariable: 'importStartedAcknowledgement'
        }),
        ...makeStandardReducers({
            prefix: 'startImportProcessForCompany',
            destinationVariable: 'importStartedAcknowledgement'
        }),
        ...makeStandardReducers({
            prefix: 'startPortfolioImportAndRefreshProcess',
            destinationVariable: 'importStartedAcknowledgement'
        }),
        ...makeStandardReducers({
            prefix: 'startDoradoPortfolioBacktestSensitivity',
            destinationVariable: 'importStartedAcknowledgement'
        }),
        ...makeStandardReducers({
            prefix: 'startRefreshCiqData',
            destinationVariable: 'importStartedAcknowledgement'
        }),
        ...makeStandardReducers({
            prefix: 'startUpdatePortfolioFromTradeFiles',
            destinationVariable: 'importStartedAcknowledgement'
        }),
        ...makeStandardReducers({
            prefix: 'addNewCompanyFromCiqId',
            destinationVariable: 'importStartedAcknowledgement'
        }),



        ...makeStandardReducers({
            prefix: 'fetchValuationMetadata',
            destinationVariable: 'valuationMetadata'
        }),

        ...makeStandardReducers({
            prefix: 'fetchQueueData',
            destinationVariable: 'queueData'
        }),
        ...makeStandardReducers({
            prefix: 'fetchPortfolioMetadata',
            destinationVariable: 'portfolioMetadata'
        }),
        ...makeStandardReducers({
            prefix: 'fetchCompanyValidationSummaries',
            destinationVariable: 'companyValidationSummaries'
        }),







    }
});


export const startDataImportProcess = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'startDataImportProcess',
        getPayload: () => {
            return dataImportService.find({})
        }
    })
};

export const startPointSurDoradoForecastAndValuation = ({saveProfile}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'startPointSurDoradoForecastAndValuation',
        getPayload: () => {
            return allPointSurForecastAndValuationsService.find({
                query: {
                    save_profile: saveProfile
                }
            })
        }
    })
};


export const startRefreshCiqData = ({saveProfile}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'startRefreshCiqData',
        getPayload: () => {
            return refreshCiqService.find({
                query: {
                    save_profile: saveProfile
                }
            })
        }
    })
};


export const startDoradoPortfolioBacktest = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'startDoradoPortfolioBacktest',
        getPayload: () => {
            return doradoPortfolioBacktestService.find({})
        }
    })
};


export const startDoradoMirrorBacktest = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'startDoradoMirrorBacktest',
        getPayload: () => {
            return doradoMirrorService.find({})
        }
    })
};



export const startImportProcessForCompany = ({companyId}) => async (dispatch, getState) => {
    return await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'startImportProcessForCompany',
        getPayload: () => {
            return dataImportService.get(companyId)
        }
    })
};


export const startPortfolioImportAndRefreshProcess = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'startPortfolioImportAndRefreshProcess',
        getPayload: () => {
            return downloadFtpAndUpdatePortfolioService.find()
        }
    })
};


export const startUpdatePortfolioFromTradeFiles = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'startUpdatePortfolioFromTradeFiles',
        getPayload: () => {
            return refreshPortfolioFromTradeFilesService.find()
        }
    })
};




export const startDoradoPortfolioBacktestSensitivity = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'startDoradoPortfolioBacktestSensitivity',
        getPayload: () => {
            return doradoPortfolioBacktestSensitivityService.find()
        }
    })
};



export const fetchValuationMetadata = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'fetchValuationMetadata',
        getPayload: () => {return valuationMetadataService.find()}
    })
};


export const fetchPortfolioMetadata = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'fetchPortfolioMetadata',
        getPayload: () => {return portfolioMetadataService.find()}
    })
};


export const fetchCompanyValidationSummaries = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'fetchCompanyValidationSummaries',
        getPayload: () => {return companyValidationSummariesService.find()}
    })
};




export const addNewCompanyFromCiqId = ({ciq_id}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'addNewCompanyFromCiqId',
        getPayload: () => {
            return newCompaniesFromCiqIdService.create({
                ciq_id: ciq_id,
            })
        }
    })
}

export const fetchQueueData = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: adminFeatureSlice,
        prefix: 'fetchQueueData',
        getPayload: () => queueDataService.find()
    })
};





export default adminFeatureSlice.reducer