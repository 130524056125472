import React from "react";
import {getTableClassName} from "../../../../common/reactDataGrid/common";
import ReactDataGrid from "react-data-grid";
import {headerRowHeight, rowHeaderWidth, rowHeight} from "../../../../components/companyFinancials/common";
import {ModelRowRenderer} from "../../../../components/rdg/rdgComponents";
import {createRow, HeaderContentRenderer, LeftAlignHeaderRenderer} from "../../../../components/rdg/helpers";
import {formatMultipleOneDecimal, formatNoDecimal} from "../../../../common/format";


const labels = [
    'Current',
    'NTM'
];

const columnHeaderFieldName = 'label'

const cellWidth = 60;

export default ({company, doradoValuationRecord, loading}) => {

    const records = getMultiplesRecords({doradoValuationRecord})

    const labelRow = [
        {
            key: 'rowHeader',
            name: '',
            width: rowHeaderWidth,
            frozen: true,
            headerRenderer: <LeftAlignHeaderRenderer/>
        }
    ].concat(
        labels.map(label => {
            return {
                key: label,
                name: label,
                width: cellWidth,
                headerRenderer: <HeaderContentRenderer/>
            }

        })
    );

    const dataRows = [
        createRow({
            headerText: 'EV/ARR',
            records: records,
            getCellValue: record => record.ev_to_arr,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'EV/Revenue',
            records: records,
            getCellValue: record => record.ev_to_revenue_total,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'EV/Gross Profit',
            records: records,
            getCellValue: record => record.ev_to_gross_profit_total,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'EV/EBITDA',
            records: records,
            getCellValue: record => {

                if (record.ev_to_ebitda < 0) {
                    return null
                }
                return record.ev_to_ebitda
            },
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'EV/Pretax FCF',
            records: records,
            getCellValue: record => {

                if (record.ev_to_pretax_fcf < 0) {
                    return null
                }
                return record.ev_to_pretax_fcf
            },
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName
        }),
        createRow({
            headerText: 'EV/Max Pretax FCF',
            records: records,
            getCellValue: record => record.ev_to_maximized_pretax_fcf,
            formatValue: formatMultipleOneDecimal,
            columnHeaderFieldName
        }),
    ]


    return (
        <div className={getTableClassName(loading) + ' align-right'}>

            <ReactDataGrid
                columns={labelRow}
                rowGetter={index => dataRows[index]}
                rowsCount={dataRows.length}
                headerRowHeight={headerRowHeight}
                rowHeight={rowHeight}
                minHeight={rowHeight * 8}
                rowRenderer={ModelRowRenderer}
            />

        </div>
    )


}


const getMultiplesRecords = ({doradoValuationRecord}) => {

    return [
        {
            label: 'Current',
            ev_to_arr: doradoValuationRecord.entry_ev_to_arr,

        },
        {
            label: 'NTM',
            ev_to_revenue_total: doradoValuationRecord.entry_ev_to_revenue_total_ntm,
            ev_to_gross_profit_total: doradoValuationRecord.entry_ev_to_gross_profit_total_ntm,
            ev_to_ebitda: doradoValuationRecord.entry_ev_to_ebitda_ntm,
            ev_to_pretax_fcf: doradoValuationRecord.entry_ev_to_pretax_fcf_ntm,
            ev_to_maximized_pretax_fcf: doradoValuationRecord.entry_ev_to_maximized_pretax_fcf_ntm

        }
    ]

}