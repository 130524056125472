import React from 'react'
import {Form, Dropdown} from 'semantic-ui-react'
import {nullValueOption} from '../../common/query/null'


export default ({
                    label,
                    options,
                    allowNullSelection,
                    onChange,
                    value,
                    disabled
                }) => {

    // options are of the form {key, value, text}

    allowNullSelection = allowNullSelection || false;

    if (allowNullSelection) {
        options = [nullValueOption, ...options]
    }

    return (
        <Form.Field disabled={disabled}>
            <label>{label}</label>
            <Dropdown selection
                      options={options}
                      value={value}
                      onChange={onChange}
                      search={true}
            />
        </Form.Field>


    )


}