import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../../common/common'
import {
    formatMultipleOneDecimal,
    formatPercentNoNull,
    formatNoDecimalNoNullOrZero,
    formatOneDecimal,
    formatMultipleTwoDecimals,
    formatPercentOneDecimalNoNull
} from '../../../../common/format';
const ReactHighcharts = require('react-highcharts');



class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const doradoValuationRecords = this.props.doradoValuationRecords;
        const loading = this.props.loading;

        const series = [

            {
                name: 'Companies',
                data: doradoValuationRecords.map(r => {
                    return {
                        x: r.entry_ebit_margin_plus_organic_arr_growth_max,
                        y: r.exit_maximized_pretax_fcf_margin_ntm,
                        label: r.company.ticker,
                        entry_ebit_margin_plus_organic_arr_growth_ltm: r.entry_ebit_margin_plus_organic_arr_growth_ltm,
                        entry_ebit_margin_plus_organic_arr_growth_ntm: r.entry_ebit_margin_plus_organic_arr_growth_ntm,
                        companyName: r.company.company_name

                    }
                }).filter(r => r.x != null),
                tooltip: {
                    pointFormatter: function () {
                        return [
                            '<b>' + this.companyName + '</b>',
                            'LTM Entry EBIT Margin + ARR Growth: ' + formatPercentOneDecimalNoNull(this.entry_ebit_margin_plus_organic_arr_growth_ltm),
                            'NTM Entry EBIT Margin + ARR Growth: ' + formatPercentOneDecimalNoNull(this.entry_ebit_margin_plus_organic_arr_growth_ntm),
                            'Max LTM/NTM Entry EBIT Margin + ARR Growth: ' + formatPercentOneDecimalNoNull(this.x),
                            'Exit Max Margin: ' + formatPercentOneDecimalNoNull(this.y),
                        ].join("<br/>")
                    }

                },
            },
            {
                name: 'Equal Rule of 40: Entry vs. Max Margin',
                data: [
                    {x: -.1, y: -.1},
                    {x: .6, y: .6}
                ],
                type: 'line',
                color: '#99d8c9',
                marker: {
                    enabled: false
                },
                enableMouseTracking: false
            },

        ];

        const config = {
            chart: {
                type: 'scatter',
                zoomType: 'xy'
            },
            title: {
                text: 'Entry Rule-of-40 vs. Max Margin'
            },
            xAxis: {
                title: {
                    text: 'Entry EBIT Margin + Organic ARR Growth (max of LTM, NTM)'
                },
                labels: {
                    formatter: function () {
                        return formatPercentNoNull(this.value);
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Exit Max FCF Margin'
                },
                labels: {
                    formatter: function () {
                        return formatPercentNoNull(this.value);
                    }
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                    stickyTracking: false,
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        style: {
                            fontSize: "9px",
                            fontWeight: 'normal',
                            textOutline: '1px',
                            color: 'rgba(0, 0, 0, .5)'
                        },
                        format: '{point.label}'
                    },
                },
                scatter: {

                    marker: {
                        symbol: 'circle',
                        radius: 4,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },

                }
            },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent