import {parseBoolean} from "../../../common/query/parse";


export const fieldSpecs = {
  showLocation: {
    parse: parseBoolean
  },
  showDateAssociated: {
    parse: parseBoolean
  },
  status: {
    parse: (v) => {
      if (v == null) {
        return 'All'
      }
      return v
    }
  }
};
