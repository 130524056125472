import {scaleThreshold} from 'd3';
import {formatPercentNoNull} from '../format';
import {getTableField} from "../../common/reactDataGrid/valuationColumns";


export const getColorTextFromRgbAndOpacity = (rgb, opacity) => {
  return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + opacity;
};


export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}


const legendItemsFromDomainAndRange = (domain, range, formatFunc) => {

  const r0 = {
    color: range[0],
    name: '< ' + formatFunc(domain[0])
  };

  const others = domain.map((value, i) => {
    const color = range[i + 1];
    return {
      color: color,
      name: '>=' + formatFunc(value)
    };
  });

  return [r0, ...others];
};

const nullLegendItem = {
  color: hexToRgb('#bababa'),
  name: 'n/a'
};

const getLegendItemsAndAssignFunctionFromSpec = ({domain, range, formatLegendItem, getValue}) => {

  let legendItems = legendItemsFromDomainAndRange(domain, range, formatLegendItem);
  const labelNames = legendItems.map(item => {
    return item.name
  });

  const scale = scaleThreshold().domain(domain).range(labelNames);
  const assignFunc = (value) => {
    if (value === null) {
      return nullLegendItem.name;
    }
    return scale(value);
  };

  legendItems.reverse();
  legendItems.push(nullLegendItem);

  const assignRecordToSeriesName = ({record, period}) => {
    return assignFunc(getValue({record, period}));
  };

  return {
    legendItems,
    assignRecordToSeriesName
  }

};


const rawSpecs = [
  {
    name: '% Recurring',
    domain: [.3, .5, .6, .7, .8],
    range: [
      hexToRgb('#a50026'),
      hexToRgb('#f46d43'),
      hexToRgb('#fdae61'),
      hexToRgb('#fee08b'),
      hexToRgb('#a6d96a'),
      hexToRgb('#1a9850')
    ],
    formatLegendItem: formatPercentNoNull,
    getValue: ({record, period}) => {
      return record[getTableField({baseField: 'revenue_recurring_percent', period})]
    }
  },
  {
    name: 'Revenue Growth',
    domain: [-0.1, 0, .1, .2],
    range: [
      hexToRgb('#a50026'),
      hexToRgb('#fc8d59'),
      hexToRgb('#abd9e9'),
      hexToRgb('#91cf60'),
      hexToRgb('#1a9850'),
    ],
    formatLegendItem: formatPercentNoNull,
    getValue: ({record, period}) => {
      return record[getTableField({baseField: 'revenue_growth', period})]
    }
  },
  {
    name: 'EBITDA Margin',
    domain: [-0.1, 0, .1, .2, .3],
    range: [
      hexToRgb('#a50026'),
      hexToRgb('#fc8d59'),
      hexToRgb('#abd9e9'),
      hexToRgb('#fee08b'),
      hexToRgb('#91cf60'),
      hexToRgb('#1a9850'),
    ],
    formatLegendItem: formatPercentNoNull,
    getValue: ({record, period}) => {
      return record[getTableField({baseField: 'ebitda_margin', period})]
    }
  },
  {
    name: 'Gross Margin',
    domain: [.5, .6, .7, .8, .9],
    range: [
      hexToRgb('#a50026'),
      hexToRgb('#fc8d59'),
      hexToRgb('#abd9e9'),
      hexToRgb('#a6d96a'),
      hexToRgb('#1a9850'),
      hexToRgb('#006837'),

    ],
    formatLegendItem: formatPercentNoNull,
    getValue: ({record, period}) => {
      return record[getTableField({baseField: 'gross_margin', period})]
    }
  },
  {
    name: 'Recurring Gross Margin',
    domain: [.5, .6, .7, .8, .9],
    range: [
      hexToRgb('#a50026'),
      hexToRgb('#fc8d59'),
      hexToRgb('#abd9e9'),
      hexToRgb('#fee08b'),
      hexToRgb('#91cf60'),
      hexToRgb('#1a9850'),
    ],
    formatLegendItem: formatPercentNoNull,
    getValue: ({record, period}) => {
      return record[getTableField({baseField: 'gross_margin_recurring', period})]
    }
  },
  // {
  //   name: 'Perpetual License % Rev',
  //   domain: [0.1, .1, .2, .3],
  //   range: [
  //
  //     hexToRgb('#bababa'),
  //     hexToRgb('#fee08b'),
  //     hexToRgb('#fdae61'),
  //     hexToRgb('#d73027'),
  //     hexToRgb('#a50026'),
  //
  //   ],
  //   formatLegendItem: formatPercentNoNull,
  //   getValue: (record) => {
  //     let value = null;
  //     if (record.financial_period_record.has_revenue_composition) {
  //       if (record.financial_period_record.revenue_perpetual_license !== null && record.financial_period_record.revenue_total) {
  //         value = record.financial_period_record.revenue_perpetual_license / record.financial_period_record.revenue_total;
  //       } else {
  //         value = 0;
  //       }
  //     }
  //     return value
  //   }
  //
  //
  // },
  {
    name: 'Capitalized Software % Rev',
    domain: [0.1, .05, .1],
    range: [
      hexToRgb('#abd9e9'),
      hexToRgb('#fdae61'),
      hexToRgb('#f46d43'),
      hexToRgb('#a50026'),
    ],
    formatLegendItem: formatPercentNoNull,
    getValue: ({record, period}) => {
      return record[getTableField({baseField: 'capitalized_software_percent_of_sales', period})]
    }

  }


];


export const colorSpecByName = {

  '': {
    legendItems: [{
      color: hexToRgb('#4292c6'),
      name: 'Companies'
    }],
    assignRecordToSeriesName: ({record, period}) => {return 'Companies'}
  }
};

for (let spec of rawSpecs) {
  colorSpecByName[spec.name] = {
    ...getLegendItemsAndAssignFunctionFromSpec(spec),
    getValue: spec.getValue,
    formatLegendItem: spec.formatLegendItem
  }
}



const getRuleOf40Value = ({record, period}) => {

  const ebitdaMargin = record[getTableField({baseField: 'ebitda_margin', period})];
  const revenueGrowth = record[getTableField({baseField: 'revenue_growth', period})];

  if (ebitdaMargin === null || revenueGrowth === null) {
    return nullLegendItem.name;
  }

  if (ebitdaMargin < 0 && revenueGrowth < 0) {
    return 'Both Negative';
  }

  if (ebitdaMargin < 0) {
    return '>=100% growth';
  }

  if (revenueGrowth < 0) {
    return '< 0% growth';
  }

  return revenueGrowth / (revenueGrowth + ebitdaMargin);
};

const createRuleOf40AssignFunction = (getValue) => {

  const domain = [.2, .4, .6, .8];
  const range = [
    '< 20% growth',
    '>=20% growth',
    '>=40% growth',
    '>=60% growth',
    '>=80% growth'
  ];

  const scale = scaleThreshold().domain(domain).range(range);

  return ({record, period}) => {

    const value = getValue({record, period});

    if (typeof value === 'string') {
      return value
    }

    return scale(value);
  };

};

colorSpecByName['Rule of 40 Growth Contribution'] = {
  legendItems: [
    {
      name: '>=100% growth',
      color: hexToRgb('#00441b')
    },
    {
      name: '>=80% growth',
      color: hexToRgb('#1b7837')
    },
    {
      name: '>=60% growth',
      color: hexToRgb('#5aae61')
    },
    {
      name: '>=40% growth',
      color: hexToRgb('#d9f0d3')
    },
    {
      name: '>=20% growth',
      color: hexToRgb('#c2a5cf')
    },
    {
      name: '< 20% growth',
      color: hexToRgb('#762a83')
    },
    {
      name: '< 0% growth',
      color: hexToRgb('#40004b')
    },
    {
      name: 'Both Negative',
      color: hexToRgb('#8c510a')
    },
    nullLegendItem,

  ],
  getValue: getRuleOf40Value,
  assignRecordToSeriesName: createRuleOf40AssignFunction(getRuleOf40Value),

  formatLegendItem: (value) => {
    if (typeof value === 'string') {
      return value
    }
    return formatPercentNoNull(value)
  }

};

