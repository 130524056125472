import {parseBoolean} from "../../../common/query/parse";


export const fieldSpecs = {

  showRoles: {
    parse: (v) => {
      if (v == null) {
        return true
      }
      return parseBoolean(v)
    }
  },
  geographyGroups: {
    parse: (v) => {
      if (v == null) {
        return '0'
      }
      return v
    }
  }
};
