import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import {
    formatMultipleOneDecimal,
    formatPercentNoNull,
    formatNoDecimalNoNullOrZero,
    formatOneDecimal,
    formatMultipleTwoDecimals,
    formatPercentOneDecimalNoNull,
    formatFullDate
} from '../../../common/format';
const ReactHighcharts = require('react-highcharts');


function chunkArray(array, size) {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}

const sumArray = (array) => {
    return array.reduce( (sum, current) => sum + current, 0 )
};



class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        let records = this.props.records;
        const loading = this.props.loading;
        const asOfDate = this.props.asOfDate;

        records = records.filter(r => r.total_gain != null && r.return_to_date != null);

        records.sort((a, b) => b.total_gain - a.total_gain);

        const chunks = chunkArray(records, 5).reverse();

        const groups = chunks.map(chunk => {

            const gains = chunk.map(r => r.total_gain);
            const returns = chunk.map(r => r.return_to_date);

            return {
                averageTotalGain: sumArray(gains) / gains.length,
                averageReturnToDate: sumArray(returns) / returns.length

            }
        });

        const series = [
            {
                name: 'Groups of 10 Companies by Expected Return',
                data: groups.map(r => {

                    return {
                        x: r.averageTotalGain,
                        y: r.averageReturnToDate,
                        label: formatPercentOneDecimalNoNull(r.averageReturnToDate)
                    }
                })
            },

        ];

        const config = {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Dorado Expected 5-Yr Return at ' + formatFullDate(asOfDate, false) + ' vs. Actual Stock Return'
            },
            xAxis: {
                title: {
                    text: 'Dorado Expected 5-Yr Return'
                },
                labels: {
                    formatter: function () {
                        return formatPercentNoNull(this.value);
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Stock Return Since Date'
                },
                labels: {
                    formatter: function () {
                        return formatPercentNoNull(this.value);
                    }
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                    stickyTracking: false,
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        style: {
                            fontSize: "9px",
                            fontWeight: 'normal',
                            textOutline: '1px',
                            color: 'rgba(0, 0, 0, .5)'
                        },
                        format: '{point.label}'
                    },
                }
            },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent