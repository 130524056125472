import { createSlice } from '@reduxjs/toolkit'
import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
const etfWithSecurityListingRowsService = client.service('etfs_with_security_listings');


const etfFeatureSlice = createSlice({
    name: 'etf',
    initialState: getInitialStateWithLoading({
        etfsWithSecurityListings: []
    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchEtfsWithSecurityListingRows', destinationVariable: 'etfsWithSecurityListings'}),
    }
});


export const fetchEtfsWithSecurityListingRows = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: etfFeatureSlice,
        prefix: 'fetchEtfsWithSecurityListingRows',
        getPayload: () => {return etfWithSecurityListingRowsService.find({
            query: {
                // $sort: sort,
                // ...filters
            }
        })}
    })
};

export default etfFeatureSlice.reducer