import React, {useEffect} from 'react';
import {Button, Label, Divider, Form, Grid, Header, Icon, Statistic, Table} from 'semantic-ui-react'

import {
    BoolToYesNoFormatter,
    NoDecimalNoNullOrZeroFormatter,
    PercentTwoDecimalNoZeroFormatter, TwoDecimalCurrencyFormatter, UtcToLocalFullDateTimeFormatter,
    NoDecimalNoNullFormatter
} from "../../../common/reactDataGrid/formatters";
import {startImportProcessForCompany} from "../../../features/adminFeatureSlice";
import {flagNonDoradoOrder} from "../../../features/dorado/flex";


export const fieldSpecs = {
    customDate: {
        parse: (v) => v == null ? null : v
    }
};



class FlagNonDoradoFormatter extends React.Component {
    render() {

        const dispatch = this.props.dispatch;
        const flexTradeOrder = this.props.value.flextrade_order;
        const loadingAssignmentAcknowledged = this.props.value.loadingAssignmentAcknowledged;
        const buttonStyle = {"paddingTop": 4, "paddingBottom": 4};

        if (flexTradeOrder.id == null) {
            return <div/>
        }


        return (
            <div>
                <Button loading={loadingAssignmentAcknowledged} icon basic size="small" color="red" style={buttonStyle}
                        onClick={() => dispatch(flagNonDoradoOrder({flexTradeOrder}))}
                >
                    <Icon name="exchange"/>
                </Button>
            </div>

        )

    }
}



export const getColumns = ({dispatch, loadingAssignmentAcknowledged}) => {
    return [
        {
            key: 'symbol',
            name: 'Symbol',
            width: 100,
            _getValueFromRecord: (r) => {
                return r.security.symbol
            },
            frozen: true
        },
        {
            key: 'instrumentName',
            name: 'Name',
            width: 200,
            _getValueFromRecord: (r) => {
                return r.security.instrumentName
            },
            frozen: true
        },

        {
            key: 'side',
            name: 'Flex Side',
            width: 100,
        },
        {
            key: 'gs_transaction_type',
            name: 'GS Side',
            width: 60,
        },
        {
            key: 'state',
            name: 'State',
            width: 100,
        },
        {
            key: 'limitPrice',
            name: 'Limit Price',
            width: 80,
            formatter: TwoDecimalCurrencyFormatter
        },
        {
            key: 'size',
            name: 'Total Shares',
            width: 60,
            formatter: NoDecimalNoNullFormatter
        },
        {
            key: 'executedShares',
            name: 'Executed Shares',
            width: 80,
            formatter: NoDecimalNoNullFormatter
        },
        {
            key: 'currency',
            name: 'Currency',
            width: 70,
            _getValueFromRecord: (r) => {
                return r.security.currency
            },
        },
        {
            key: 'averageExecutionPrice',
            name: 'Avg Execution Price',
            width: 80,
            formatter: TwoDecimalCurrencyFormatter
        },
        {
            key: 'executed_value_usd',
            name: 'Executed Value $USD',
            width: 80,
            formatter: NoDecimalNoNullFormatter
        },
        {
            key: 'orderLoadTime',
            name: 'Order Start Time',
            width: 150,
            formatter: UtcToLocalFullDateTimeFormatter
        },
        {
            key: 'completionTime',
            name: 'Completion Time',
            width: 150,
            formatter: UtcToLocalFullDateTimeFormatter
        },
        {
            key: 'isNonDoradoSecurityOrder',
            name: 'Is Non Dorado Order?',
            width: 60,
            formatter: BoolToYesNoFormatter
        },
        {
            key: 'flag_non_dorado',
            name: 'Flag Non Dorado Trade',
            formatter: <FlagNonDoradoFormatter dispatch={dispatch}/>,
            width: 70,
            _getValueFromRecord: (r) => {
                return {
                    flextrade_order: r,
                    loadingAssignmentAcknowledged: loadingAssignmentAcknowledged
                }
            }
        },

        {
            key: 'expireTime',
            name: 'Expire Time',
            width: 150,
            formatter: UtcToLocalFullDateTimeFormatter
        },
        {
            key: 'id',
            name: 'ID',
            width: 150,
            editable: true
        },
        {
            key: 'sedol',
            name: 'SEDOL',
            width: 150,
            _getValueFromRecord: (r) => {
                return r.security.sedol
            }
        },
    ]
}

