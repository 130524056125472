import React from 'react';
import ChartComponent from './ChartComponent'
import ViewWithSidebar from '../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../common/hooks";
import {fetchHistoricalValuationRecordsForCompany} from "../../actions/company/companyHistoricalValuationActions";
import {fetchCompany} from "../../actions/companyActions";
import Sidebar from './Sidebar'
import {shallowEqual, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";


export default () => {

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: [],
        matchFields: ['id'],
        requestData
    });

    const location = useLocation();
    const history = useHistory();

    const {records, loadingRecords} = useSelector(state => state.historicalValuationRecordsStore, shallowEqual);

    return (
        <ViewWithSidebar
            mainView={<ChartComponent records={records}
                                      loadingRecords={loadingRecords}
                                      location={location}
                                      history={history}
            />}
            sidebarView={<Sidebar/>}/>
    )

}


const requestData = ({dispatch, searchProps, matchParams}) => {

    const {id} = matchParams;
    fetchCompany({dispatch, id});
    fetchHistoricalValuationRecordsForCompany({dispatch, companyId: id, currencyType: ""});

};

