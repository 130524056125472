import { createSlice } from '@reduxjs/toolkit'
import client from '../../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";
const realTimeDoradoValuationRecordService = client.service('real_time_trade_assessment_records');
const realTimeSecurityPriceRecordsService = client.service('real_time_security_price_records')
const indexConstituentWeightRecordService = client.service('index_constituent_weight_records');
const igvContributionService = client.service('igv_contribution');



const realTimeDoradoValuationRecordsFeatureSlice = createSlice({
    name: 'realTimeDoradoValuationRecords',
    initialState: {
        ...getInitialStateWithLoading({
            realTimeDoradoValuationRecords: [],
            igvConstituentWeightRecords: [],

        }),
    },
    reducers: {
        ...makeStandardReducers({
            prefix: 'fetchRealTimeDoradoValuationRecords',
            destinationVariable: 'realTimeDoradoValuationRecords'
        }),
        ...makeStandardReducers({
            prefix: 'fetchIgvConstituentWeightRecords',
            destinationVariable: 'igvConstituentWeightRecords'
        }),


    }
});

export const fetchRealTimeDoradoValuationRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: realTimeDoradoValuationRecordsFeatureSlice,
        prefix: 'fetchRealTimeDoradoValuationRecords',
        getPayload: () => {
            return realTimeDoradoValuationRecordService.find({
                query: {
                    $sort: sort,
                    ...filters,
                    $limit: 500,
                }
            })
        }
    })
};

export const fetchRealTimeSecurityPriceRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: realTimeDoradoValuationRecordsFeatureSlice,
        prefix: 'fetchRealTimeSecurityPriceRecords',
        getPayload: () => {
            return realTimeSecurityPriceRecordsService.find({
                query: {
                    $sort: sort,
                    ...filters
                }
            })
        }
    })
};


export const fetchIgvConstituentWeightRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: realTimeDoradoValuationRecordsFeatureSlice,
        prefix: 'fetchIgvConstituentWeightRecords',
        getPayload: () => {
            return indexConstituentWeightRecordService.find({
                query: {
                    $sort: sort,
                    ...filters
                }
            })
        }
    })
};



export default realTimeDoradoValuationRecordsFeatureSlice.reducer