import React from 'react';
import {Label,} from 'semantic-ui-react'
import {QualityText} from "../common/constants";

export default ({labelSize, value}) => {

    let text = <div/>;

    switch (value) {
        case QualityText.great:
            text = <Label color="green" size={labelSize}>{value}</Label>;
            break;
        case QualityText.good:
            text = <Label color="blue" size={labelSize}>{value}</Label>;
            break;
        case QualityText.average:
            text = <Label color="grey" size={labelSize}>{value}</Label>;
            break;
        case QualityText.bad:
            text = <Label color="orange" size={labelSize}>{value}</Label>;
            break;
        case QualityText.terrible:
            text = <Label color="red" size={labelSize}>{value}</Label>;
            break;
        default:
            text = <div/>;
    }

    return (
        <span className={'align-center'}>
      {text}
    </span>)
};