

const defaultState = {
  profile: {},
  loading: true,
  errors: {}
};


export default (state=defaultState, action={}) => {
  switch (action.type) {

    case 'FETCH_PROFILE_PENDING': {
      return {
        ...state,
        loading: true,
        profile: {}
      }
    }

    case 'FETCH_PROFILE_FULFILLED': {

      return {
        ...state,
        profile: action.payload,
        errors: {},
        loading: false
      }
    }

    default:
      return state;
  }
}

