import React, {useEffect} from 'react';
import {Button, Label, Divider, Form, Grid, Header, Icon, Statistic, Table, Loader} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useReloadOnChange} from "../../../common/hooks";
import {getFilterComponentsFromSpecs} from "../../../common/filter/add";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {formatDateTimeUtcToLocal, formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import moment from "moment";
import {requestDoradoValuationRecords} from "../common";
import RdgTable from "../../../components/rdg/RdgTable";
import {fetchValuationMetadata} from "../../../features/adminFeatureSlice";
import {
    approveDoradoParentSecurityOrders, clearApprovalRequest,
    fetchDoradoSecurityOrderApprovalRequest
} from "../../../features/dorado/tradeApproval";
import {BoolToYesNoCheckFormatter} from "../../../common/reactDataGrid/formatters";
import {TradeType} from "../../../common/constants";
import {sortFunctional} from "../../../common/tableHelpers";
import {getColumns} from './common'
import {fetchFlexCurrentStreetOrders} from "../../../features/dorado/flex";
import {sumArray} from "../../../common/common";
import {formatNoDecimalNoNull, formatSharePrice, formatSharePriceFixedDigits} from "../../../common/format";
import {patchDoradoBacktestParameters} from "../../../features/dorado/doradoPortfolio";
import {fieldSpecs} from './common'
import {convertFiltersToFeathersFilters} from "../../../common/request/convert";
import {fetchPointSurDoradoValuationRecords} from "../../../features/dorado/doradoValuationRecords";
import {createAddValueToQuery} from "../../../common/query/add";


const TradeTypes = ['Buy Long', 'Sell Long', 'Buy Cover', 'Sell Short']




export default ({email}) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        currentStreetOrdersAnalysis,
        loadingCurrentStreetOrdersAnalysis,
        loadingFlaggedNonDoradoOrder
    } = useSelector(state => {
        return state.flex
    }, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['customDate'],
        requestData
    });

    const now = moment();

    const customDateOptions = [...Array(200).keys()].map(i => {
        return now.clone().subtract(i, 'days');
    }).map(d => {
        const s = d.format('YYYY-MM-DD');
        return {
            key: s,
            value: s,
            text: d.format('MM-DD-YYYY')
        }
    });
    customDateOptions.unshift({
        key: 'null',
        value: null,
        text: ''
    });


    const netBuyLong = (currentStreetOrdersAnalysis.trade_value_by_trade_type['Buy Long'] - currentStreetOrdersAnalysis.trade_value_by_trade_type['Sell Long']) || null;
    const netCover = (currentStreetOrdersAnalysis.trade_value_by_trade_type['Buy Cover'] - currentStreetOrdersAnalysis.trade_value_by_trade_type['Sell Short']) || null;

    const totalBuy = (currentStreetOrdersAnalysis.trade_value_by_trade_type['Buy Long'] + currentStreetOrdersAnalysis.trade_value_by_trade_type['Buy Cover']) || null;
    const totalSell = (currentStreetOrdersAnalysis.trade_value_by_trade_type['Sell Long'] + currentStreetOrdersAnalysis.trade_value_by_trade_type['Sell Short']) || null;
    const totalNetBuy = (totalBuy - totalSell);

    const symbolTotalRows = getTotalBySymbolRows(currentStreetOrdersAnalysis);


    const sidebar = (
        <div>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Divider horizontal>Total $ Traded</Divider>
                        <Table basic='very' collapsing compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>

                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Vanilla
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Cover / Short
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Total
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        <b>Buy</b>
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {formatNoDecimalNoNull(currentStreetOrdersAnalysis.trade_value_by_trade_type['Buy Long'])}
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {formatNoDecimalNoNull(currentStreetOrdersAnalysis.trade_value_by_trade_type['Buy Cover'])}
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {formatNoDecimalNoNull(totalBuy)}
                                    </Table.Cell>

                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <b>Sell</b>
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {formatNoDecimalNoNull(currentStreetOrdersAnalysis.trade_value_by_trade_type['Sell Long'])}
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {formatNoDecimalNoNull(currentStreetOrdersAnalysis.trade_value_by_trade_type['Sell Short'])}
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {formatNoDecimalNoNull(totalSell)}
                                    </Table.Cell>

                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <b>Net Buy</b>
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {formatNoDecimalNoNull(netBuyLong)}
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {formatNoDecimalNoNull(netCover)}
                                    </Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {formatNoDecimalNoNull(totalNetBuy)}
                                    </Table.Cell>

                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider horizontal>$ By Symbol</Divider>
                        <Table basic='very' collapsing compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Symbol
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Type
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        $ Traded
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        $/share
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Shares
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {symbolTotalRows}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>


                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <Form.Select options={customDateOptions}
                                         value={searchProps.customDate}
                                         onChange={createAddValueToQuery({
                                             history,
                                             searchProps,
                                             fieldName: 'customDate'
                                         })}
                                         label={'Custom Date'}
                            />
                        </Form>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </div>
    );

    const columns = getColumns({
        dispatch,
        loadingAssignmentAcknowledged: loadingFlaggedNonDoradoOrder
    });

    const records = [
        ...[].concat(...TradeTypes.map(tradeType => getOrderRowsForTradeType({
            tradeType,
            orderGroups: currentStreetOrdersAnalysis.flex_orders_by_trade_type[tradeType] || []})))
    ]

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             loading={loadingCurrentStreetOrdersAnalysis}
                                             records={records}
                                             onGridRowsUpdated={() => null}


        />}
                         sidebarView={sidebar}/>
    )

}


const getOrderRowsForTradeType = ({tradeType, orderGroups}) => {

    const spacedOrderGroups = orderGroups.map(orderGroup => {
        return [{security: {}}].concat(orderGroup)
        // return orderGroup.concat({security: {}})
    })

    return [
        {security: {symbol: tradeType}},
        ...[].concat(...spacedOrderGroups),
        {security: {}},
    ]


}




const getTotalBySymbolRows = (currentStreetOrdersAnalysis) => {

    const rowsGroups = TradeTypes.map(tradeType => {

        const orderGroups = currentStreetOrdersAnalysis.flex_orders_by_trade_type[tradeType] || [];

        return getTotalRowsForTradeType({tradeType, orderGroups})

    });

    return [].concat(...rowsGroups)

}


const getTotalRowsForTradeType = ({tradeType, orderGroups}) => {
    // totals traded for each security

    const rows = orderGroups.map(orderGroup => {

        const symbol = orderGroup[0].security.symbol;
        const currency = orderGroup[0].security.currency;

        const executedValueUsd = sumArray(orderGroup.map(o => o.executed_value_usd));
        const executedValueTrading = sumArray(orderGroup.map(o => o.executed_value));
        const executedShares = sumArray(orderGroup.map(o => o.executedShares));

        const averageExecutionPrice = executedShares > 0 ? executedValueTrading / executedShares : null;

        const currencyPrefix = currency === 'USD' ? '$' : '$' + currency;


        return <Table.Row key={symbol + tradeType}>
            <Table.Cell>
                {symbol}
            </Table.Cell>
            <Table.Cell>
                {tradeType}
            </Table.Cell>
            <Table.Cell textAlign='right'>
                {"$" + formatNoDecimalNoNull(executedValueUsd)}
            </Table.Cell>
            <Table.Cell textAlign='right'>
                {currencyPrefix + " " + formatSharePriceFixedDigits(averageExecutionPrice)}
            </Table.Cell>
            <Table.Cell textAlign='right'>
                {formatNoDecimalNoNull(executedShares)}
            </Table.Cell>
        </Table.Row>

    });

    if (rows.length > 0) {
        rows.push(<Table.Row key={tradeType}>
            <Table.Cell/>
            <Table.Cell/>
            <Table.Cell/>
            <Table.Cell/>
            <Table.Cell/>
        </Table.Row>)
    }

    return rows

}





const requestData = ({dispatch, searchProps}) => {
    dispatch(fetchFlexCurrentStreetOrders({customDate: searchProps.customDate}));

};