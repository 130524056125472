import React from 'react';
import {
    StringFormatter,
    DateFormatter,
    NoDecimalNoNullOrZeroFormatter,
    SharePriceFormatter,
    PercentNoDecimalNoNullFormatter,
    PercentOneDecimalNoNullFormatter,
    BoolToYesNoFormatter,
    BoolToNoFlagFormatter, BoolToYesNoCheckFormatter, PercentTwoDecimalNoZeroFormatter, PercentTwoDecimalNoNullFormatter
} from "../../../common/reactDataGrid/formatters";
import {boolOptions} from "../../../common/reactDataGrid/common";
import {Editors} from 'react-data-grid-addons';

const {DropDownEditor} = Editors;




export const columns = [
    {
        key: 'id',
        name: 'ID',
        width: 80
    },
    {
        key: 'security_listing_id',
        name: 'Security Listing ID',
        width: 100
    },
    {
        key: 'ciq_debt_id',
        name: 'CIQ Debt ID',
        width: 130,
        editable: true
    },
    {
        key: 'ciq_transaction_id',
        name: 'CIQ Transaction ID',
        width: 130,
        editable: true
    },
    {
        key: 'is_validated',
        name: 'Is Validated?',
        width: 70,
        formatter: BoolToYesNoCheckFormatter,
        editor: <DropDownEditor options={boolOptions}/>,
        editable: true
    },
    {
        key: 'was_closed_on_ipo',
        name: 'Closed Out on IPO?',
        width: 70,
        formatter: BoolToYesNoFormatter,
        editor: <DropDownEditor options={boolOptions}/>,
        editable: true
    },
    {
        key: 'ciq_trading_id',
        name: 'CIQ Trading ID',
        width: 130,
        editable: true
    },
    {
        key: 'description',
        name: 'Description',
        width: 300,
    },
    {
        key: 'issue_date',
        name: 'Issue Date',
        width: 90,
        formatter: DateFormatter,
        editable: true
    },
    {
        key: 'maturity_date',
        name: 'Maturity Date',
        width: 90,
        formatter: DateFormatter,
        editable: true
    },
    {
        key: 'fully_paid_off_date',
        name: 'Fully Paid Off Date',
        width: 90,
        formatter: DateFormatter,
        editable: true
    },
    {
        key: 'issue_currency',
        name: 'Issue Currency',
        width: 70,
        editable: true
    },
    {
        key: 'coupon',
        name: 'Offer Coupon',
        width: 70,
        formatter: PercentTwoDecimalNoNullFormatter,
        editable: true
    },
    {
        key: 'offer_amount',
        name: 'Offer Amount',
        width: 80,
        formatter: NoDecimalNoNullOrZeroFormatter,
        editable: true
    },
    // {
    //     key: 'amount_outstanding',
    //     name: 'Amount Outstanding',
    //     width: 80,
    //     formatter: NoDecimalNoNullOrZeroFormatter
    // },
    {
        key: 'conversion_price_at_issue',
        name: 'Orig. Conversion Price',
        width: 80,
        formatter: SharePriceFormatter,
        editable: true,
    },
    {
        key: 'modified_conversion_price_at_issue',
        name: 'Modified Conversion Price',
        width: 80,
        formatter: SharePriceFormatter,
        editable: true,
    },



];

