import client from '../../empClient';

const analysisService = client.service('employee_analysis');


export function fetchAnalysisForScrape({scrapeId, geographyGroups}) {

  return dispatch => {
    dispatch({
      type: 'FETCH_ANALYSIS_FOR_SCRAPE',
      payload: analysisService.get(scrapeId, {
        query: {
          geography_groups: geographyGroups
        }
      })
    });

  };
}
