import { createSlice } from '@reduxjs/toolkit'

import client from '../../benchClient';
import {ForecastType} from "../../common/constants";
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";
const doradoValuationRecordService = client.service('dorado_valuation_records');
const missingDoradoValuationRecordCountService = client.service('missing_dorado_valuation_record_count');
const valuationPremiumAuditService = client.service('dorado_valuation_premium_audits');
const doradoUniverseValuationRecordsService = client.service('dorado_universe_valuation_records');
const benchmarkMetadatasWithCompaniesService = client.service('benchmark_metadatas_with_companies');
const doradoValuationFacetsService = client.service('dorado_valuation_records_facets');


const doradoValuationRecordsFeatureSlice = createSlice({
    name: 'doradoValuation',
    initialState: {
        ...getInitialStateWithLoading({
            doradoValuationRecords: [],
            doradoValuationFacetsByField: {},

            valuationPremiumAuditRecords: [],
            currentDoradoValuationRecord: {},
            doradoUniverseValuationRecords: [],
            revisedBacktestDoradoValuationRecords: [],
            benchmarkMetadataRecords: [],
        }),

        loadingMissingRecordCount: true,
        missingRecordCount: 0,



    },
    reducers: {
        ...makeStandardReducers({
            prefix: 'fetchDoradoValuationRecords',
            destinationVariable: 'doradoValuationRecords'
        }),
        ...makeStandardReducers({prefix: 'fetchValuationPremiumAuditRecords', destinationVariable: 'valuationPremiumAuditRecords'}),
        ...makeStandardReducers({prefix: 'fetchDoradoUniverseValuationRecords', destinationVariable: 'doradoUniverseValuationRecords'}),
        ...makeStandardReducers({prefix: 'fetchRevisedBacktestDoradoValuationRecordsForCompany', destinationVariable: 'revisedBacktestDoradoValuationRecords'}),

        ...makeStandardReducers({
            prefix: 'fetchBenchmarkMetadatasWithCompanies',
            destinationVariable: 'benchmarkMetadataRecords'
        }),
        ...makeStandardReducers({
            prefix: 'fetchFacetsForDoradoValuationRecords',
            destinationVariable: 'doradoValuationFacetsByField'
        }),



        fetchCurrentDoradoValuationRecordPending: (state) => {
            state.loadingCurrentDoradoValuationRecord = true;
        },
        fetchCurrentDoradoValuationRecordFulfilled: (state, action) => {

            const result = action.payload.data;

            if (result.length === 1) {
                state.currentDoradoValuationRecord = result[0];
            } else {
                console.log("error")
                console.log(action.payload.data);
            }

            state.loadingCurrentDoradoValuationRecord = false;
        },


        fetchMissingDoradoValuationRecordCountPending: (state) => {
            state.loadingMissingRecordCount = true;
        },
        fetchMissingDoradoValuationRecordCountFulfilled: (state, action) => {
            state.loadingMissingRecordCount = false;
            state.missingRecordCount = action.payload.data;
        },
    }
});

const {
    fetchMissingDoradoValuationRecordCountPending,
    fetchMissingDoradoValuationRecordCountFulfilled} = doradoValuationRecordsFeatureSlice.actions;



export const fetchCurrentDoradoValuationRecord = ({company_id}) => async (dispatch, getState) => {
    // convenience to grab everything at once and without knowing the exact portfolio id

    await executeWithPending({
        dispatch,
        featureSlice: doradoValuationRecordsFeatureSlice,
        prefix: 'fetchCurrentDoradoValuationRecord',
        getPayload: () => {return doradoValuationRecordService.find({
            query: {
                company_id: company_id,
                'dorado_valuation_record.forecast_type': ForecastType.currentModel
            }
        })}
    })
};


export const fetchRevisedBacktestDoradoValuationRecordsForCompany = ({company_id}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoValuationRecordsFeatureSlice,
        prefix: 'fetchRevisedBacktestDoradoValuationRecordsForCompany',
        getPayload: () => {return doradoValuationRecordService.find({
            query: {
                company_id: company_id,
                'dorado_valuation_record.forecast_type': ForecastType.revised_point_sur_backtest,
                $sort: {as_of_date: 1},
            }
        })}
    })
};


export const fetchDoradoValuationRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoValuationRecordsFeatureSlice,
        prefix: 'fetchDoradoValuationRecords',
        getPayload: () => {return doradoValuationRecordService.find({
            query: {
                $sort: sort,
                $limit: 5000,
                forecast_type: ForecastType.currentModel,
                ...getAdjustedFilters({filters})
            }
        })}
    })
}


export const fetchFacetsForDoradoValuationRecords = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoValuationRecordsFeatureSlice,
        prefix: 'fetchFacetsForDoradoValuationRecords',
        getPayload: () => {return doradoValuationFacetsService.find()}
    })
}





// export const fetchDoradoValuationRecords = ({sort, filters}) => async (dispatch, getState) => {
//     try {
//         dispatch(fetchDoradoValuationRecordsPending());
//
//         const payload = await doradoValuationRecordService.find({
//             query: {
//                 $sort: sort,
//                 $limit: 5000,
//                 ...filters
//             }
//         });
//         dispatch(fetchDoradoValuationRecordsFulfilled(payload))
//     } catch (e) {
//         alert('Error: ' + e);
//     }
// };





export const fetchPointSurBacktestDoradoValuationRecords = ({sort, filters}) => async (dispatch, getState) => {
    try {
        // dispatch(fetchDoradoValuationRecordsPending());

        const payload = await doradoValuationRecordService.find({
            query: {
                $sort: sort,
                $limit: 5000,
                forecast_type: {
                    $in: [ForecastType.currentModel, ForecastType.backtestModel]
                },
                ...filters
            }
        });
        // dispatch(fetchDoradoValuationRecordsFulfilled(payload))
    } catch (e) {
        alert('Error: ' + e);
    }
};


export const getAdjustedFilters = ({filters}) => {
    const newFilters = {...filters};
    if (newFilters.forecast_type === ForecastType.currentModel) {
        delete newFilters.entry_date;
        delete newFilters.as_of_date;
    }
    return newFilters
};

export const fetchPointSurDoradoValuationRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoValuationRecordsFeatureSlice,
        prefix: 'fetchPointSurDoradoValuationRecords',
        getPayload: () => {return doradoValuationRecordService.find({
            query: {
                $sort: sort,
                $limit: 5000,
                forecast_type: ForecastType.currentModel,
                ...getAdjustedFilters({filters})
            }
        })}
    })
    //
    //
    // try {
    //     // dispatch(fetchDoradoValuationRecordsPending());
    //
    //     const payload = await doradoValuationRecordService.find({
    //         query: {
    //             $sort: sort,
    //             $limit: 5000,
    //             forecast_type: ForecastType.currentModel,
    //             ...getAdjustedFilters({filters})
    //         }
    //     });
    //     // dispatch(fetchDoradoValuationRecordsFulfilled(payload))
    // } catch (e) {
    //     alert('Error: ' + e);
    // }
};

export const fetchMissingDoradoValuationRecordCount = ({filters}) => async (dispatch, getState) => {
    try {
        dispatch(fetchMissingDoradoValuationRecordCountPending());

        const payload = await missingDoradoValuationRecordCountService.find({
            query: {
                forecast_type: ForecastType.currentModel,
                ...getAdjustedFilters({filters})
            }
        });
        dispatch(fetchMissingDoradoValuationRecordCountFulfilled(payload))
    } catch (e) {
        alert('Error: ' + e);
    }
};


export const fetchValuationPremiumAuditRecords = () => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoValuationRecordsFeatureSlice,
        prefix: 'fetchValuationPremiumAuditRecords',
        getPayload: () => {return valuationPremiumAuditService.find({

        })}
    })
};


export const fetchDoradoUniverseValuationRecords = ({sort, filters}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoValuationRecordsFeatureSlice,
        prefix: 'fetchDoradoUniverseValuationRecords',
        getPayload: () => {return doradoUniverseValuationRecordsService.find({
            query: {
                $sort: sort,
                ...filters
            }


        })}
    })
};


export const fetchBenchmarkMetadatasWithCompanies = ({filters, sort}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: doradoValuationRecordsFeatureSlice,
        prefix: 'fetchBenchmarkMetadatasWithCompanies',
        getPayload: () => {
            return benchmarkMetadatasWithCompaniesService.find({
                query: {
                    $sort: sort,
                    ...filters
                }

            })
        }
    })
}



export default doradoValuationRecordsFeatureSlice.reducer