import React, {useEffect} from 'react';
import {fetchFacetsForAkkrDataRecords} from "../../../actions/akkr/dataActions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {parseSearchProps} from "../../../common/query/parse";
import {useHistory, useLocation} from "react-router-dom";
import {getFilterComponentsFromSpecs} from "../../../common/filter/add";
import {Form, Divider} from 'semantic-ui-react'


const filterSpecs = [
    {
        field: 'name',
        label: 'Company Name',
        type: 'terms'
    },
    {
        field: 'is_active_investment',
        label: 'Is Active Investment',
        type: 'checkbox'
    },
    {
        field: 'is_buyout',
        label: 'Buyout Fund',
        type: 'checkbox'
    },
    {
        field: 'is_growth',
        label: 'Growth Fund',
        type: 'checkbox'
    },
    {
        field: 'is_credit',
        label: 'Credit Fund',
        type: 'checkbox'
    },
    {
        field: 'is_emerging_buyout',
        label: 'EBO Fund',
        type: 'checkbox'
    },
    {
        field: 'currency_type',
        label: 'Currency Type',
        type: 'terms'
    },
    {
        field: 'is_calendarized',
        label: 'Is Calendarized',
        type: 'booleanTerms'
    },
    {
        field: 'is_most_recent_ltm',
        label: 'Is Most Recent LTM',
        type: 'checkbox'
    },
    {
        field: 'is_ltm',
        label: 'Is LTM',
        type: 'booleanTerms',
    },

    {
        field: 'period_type',
        label: 'Period Type',
        type: 'terms'
    },


];


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        fetchFacetsForAkkrDataRecords({dispatch})
    }, [dispatch]);

    const searchProps = parseSearchProps({searchString: location.search});

    const {facetsByField, loadingFacets} = useSelector(state => state.akkrTableStore, shallowEqual);

    // quick filters
    const quickFilters = getFilterComponentsFromSpecs({
            filterSpecs: filterSpecs,
            facetsByField: facetsByField,
            history,
            searchProps
        }
    );

    return (
        <Form loading={loadingFacets}>
            {quickFilters}
        </Form>

    )


}
