import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import moment from "moment";
import {formatSharePrice, formatFullDate, formatPercentOneDecimalNoNull} from "../../../common/format";
const ReactHighcharts = require('react-highcharts');


class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const doradoUniverseValuationRecords = this.props.doradoUniverseValuationRecords;
        const loading = this.props.loading;
        const show20thPercentile = this.props.show20thPercentile;
        const show80thPercentile = this.props.show80thPercentile;
        const showMedian = this.props.showMedian;
        const showMethodologyAdjusted = this.props.showMethodologyAdjusted;
        const showOriginal = this.props.showOriginal;
        const benchmarkPortfolioStates = this.props.benchmarkPortfolioStates;

        const series = [];

        if (showMedian) {
            if (showOriginal) {
                series.push({
                    name: 'Median IRR',
                    data: doradoUniverseValuationRecords.map(r => {

                        return [moment(r.as_of_datetime).valueOf(), r.median_irr]

                        // return {
                        //     x: moment(r.as_of_datetime).valueOf(),
                        //     y: r.median_irr,
                        // }
                    }),
                    color: '#696969'
                })
            }
            if (showMethodologyAdjusted) {
                series.push({
                    name: 'Revised: Median IRR',
                    data: doradoUniverseValuationRecords.map(r => {

                        return [moment(r.as_of_datetime).valueOf(), r.revised_median_irr]
                        // return {
                        //     x: moment(r.as_of_datetime).valueOf(),
                        //     y: r.revised_median_irr,
                        // }
                    }),
                    color: 'black'
                })
            }
        }
        if (show20thPercentile) {
            if (showOriginal) {
                series.push({
                    name: '20th % IRR',
                    data: doradoUniverseValuationRecords.map(r => {

                        return [moment(r.as_of_datetime).valueOf(), r.irr_20th_percentile]
                        // return {
                        //     x: moment(r.as_of_datetime).valueOf(),
                        //     y: r.irr_20th_percentile,
                        // }
                    }),
                    color: '#fdae6b'
                })
            }

            if (showMethodologyAdjusted) {
                series.push({
                    name: 'Revised: 20th IRR',
                    data: doradoUniverseValuationRecords.map(r => {

                        return [moment(r.as_of_datetime).valueOf(), r.revised_irr_20th_percentile]
                        // return {
                        //     x: moment(r.as_of_datetime).valueOf(),
                        //     y: r.revised_irr_20th_percentile,
                        // }
                    }),
                    color: '#e08214'
                })
            }
        }
        if (show80thPercentile) {
            if (showOriginal) {
                series.push({
                    name: '80th % IRR',
                    data: doradoUniverseValuationRecords.map(r => {

                        return [moment(r.as_of_datetime).valueOf(), r.irr_80th_percentile]
                        // return {
                        //     x: moment(r.as_of_datetime).valueOf(),
                        //     y: r.irr_80th_percentile,
                        // }
                    }),
                    color: '#78c679'
                })
            }

            if (showMethodologyAdjusted) {
                series.push({
                    name: 'Revised: 80th IRR',
                    data: doradoUniverseValuationRecords.map(r => {

                        return [moment(r.as_of_datetime).valueOf(), r.revised_irr_80th_percentile]
                        // return {
                        //     x: moment(r.as_of_datetime).valueOf(),
                        //     y: r.revised_irr_80th_percentile,
                        // }
                    }),
                    color: '#1b7837'
                })
            }
        }

        console.log(benchmarkPortfolioStates)

        series.push({
            name: 'Total Return Index',
            data: benchmarkPortfolioStates.map(r => {
                return [moment(r.standard_date).valueOf(), r.portfolio_state_report.time_weighted_return]
            }),
            yAxis: 1,
            tooltip: {
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                        '<tr><td>' + 'Total Return' + '</td><td>' + formatPercentOneDecimalNoNull(this.y) + '</td></tr>' +
                        '</table>'
                },
            }

        })


        const config = {
            chart: {
                type: 'line',
                zoomType: 'xy'
            },
            title: {
                text: 'Universe Valuation'
            },
            xAxis: {
                title: {
                    text: 'Date'
                },
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return formatFullDate(this.value, false);
                    }
                }
            },
            yAxis: [
                {
                    title: {
                        text: 'IRR'
                    },
                    labels: {
                        formatter: function () {
                            return formatPercentOneDecimalNoNull(this.value);
                        }

                    },
                    height: '50%',
                },
                {
                    title: {
                        text: 'Benchmark Total Return'
                    },
                    labels: {
                        formatter: function () {
                            return formatPercentOneDecimalNoNull(this.value);
                        }

                    },
                    top: '55%',
                    height: '45%',
                    // min: 0,
                }
            ],
            tooltip: {
                useHTML: true,
                shared: false,
                headerFormat: '',
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                        '<tr><td>' + 'IRR' + '</td><td>' + formatPercentOneDecimalNoNull(this.y) + '</td></tr>' +
                        '</table>'
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                },
                line: {
                    marker: {
                        enabled: false
                    },
                }
            },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent