import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import moment from "moment";
import {formatSharePrice, formatFullDate, formatPercentOneDecimalNoNull} from "../../../common/format";
import {exposureMetrics, getExposureFieldName} from "./common";
import {parseBoolean} from "../../../common/query/parse";

const ReactHighcharts = require('react-highcharts');


class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const portfolioStates = this.props.portfolioStates;
        const loading = this.props.loading;

        const series1ChartSpec = this.props.series1ChartSpec;
        const unitOption = this.props.unitOption;
        const searchProps = this.props.searchProps;

        const percentTooltip = {
                useHTML: true,
                shared: false,
                headerFormat: '',
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                        '<tr><td>' + this.series.name + '</td><td>' + formatPercentOneDecimalNoNull(this.y) + '</td></tr>' +
                        '</table>'
                },
            }

        const series = exposureMetrics.map(m => {
            return {
                name: m.name,
                color: m.color,
                dashStyle: m.dashStyle,
                // lineWidth: m.lineWidth,
                data: portfolioStates.map(r => {

                    return {
                        x: moment.utc(r.standard_date).valueOf(),
                        y: r[getExposureFieldName({
                            baseName: m.baseName,
                            unitOption: unitOption
                        })] * m.multiplier
                    }
                })
            }
        }).filter(d => parseBoolean(searchProps[d.name]));

        const config = {
            chart: {
                // type: 'line',
                zoomType: 'xy'
            },
            title: {
                text: series1ChartSpec.title
            },
            xAxis: {
                title: {
                    text: 'Date'
                },
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return formatFullDate(this.value, false);
                    }
                }
            },
            yAxis: [
                {
                    labels: {
                        formatter: function () {
                            return series1ChartSpec.formatY(this.value);
                        }

                    },
                    opposite: true,
                    min: 0
                },

            ],
            tooltip: {
                useHTML: true,
                shared: true,
                headerFormat: '{point.key}',
                xDateFormat: '%m-%d-%Y',
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td>' + this.series.name + '</td><td>' + series1ChartSpec.formatY(this.y) + '</td></tr>' +
                        '</table>'
                },
                // pointFormatter: function () {
                //     return '<table>' +
                //         '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                //         '<tr><td>' + this.series.name + '</td><td>' + formatPercentOneDecimalNoNull(this.y) + '</td></tr>' +
                //         '</table>'
                // },
            },
            plotOptions: {
                series: {
                    animation: false,
                    turboThreshold: 0,
                },
                // line: {
                //     marker: {
                //         enabled: false
                //     },
                // }
            },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent