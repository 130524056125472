import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic, Table} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {
    createAddValueToQuery,
} from "../../../common/query/add";
import {fieldSpecs, columns, igvDateOptions} from './common'
import {useReloadOnChange} from "../../../common/hooks";
import RdgTable from "../../../components/rdg/RdgTable";
import {sortFunctional} from "../../../common/tableHelpers";
import {fetchIgvContributionRecords} from "../../../features/portfolio/portfolioAnalysisFeatureSlice";
import {PricePercentChangeOneDecimalFormatter} from "../../../common/reactDataGrid/formatters";
import {formatPercentOneDecimalNoNull, formatSharePrice} from "../../../common/format";
import {sumArray} from "../../../common/common";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        igvContributionData,
        loadingIgvContributionData

    } = useSelector(state => state.portfolioAnalysis, shallowEqual);

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['startDate', 'endDate'],
        requestData: refreshData
    });

    const cumulativeContribution = sumArray(igvContributionData.igv_contribution_records.map(r => r.return_contribution));

    const sidebar = (
        <div>
            <br/>
            <Form>
                <Form.Select
                    fluid
                    inline
                    options={igvDateOptions}
                    onChange={
                        createAddValueToQuery({
                            history,
                            searchProps,
                            fieldName: 'startDate'
                        })

                    }
                    value={searchProps.startDate}
                    label="Start Date"
                />
                <Form.Select
                    fluid
                    inline
                    options={igvDateOptions}
                    onChange={
                        createAddValueToQuery({
                            history,
                            searchProps,
                            fieldName: 'endDate'
                        })
                    }
                    value={searchProps.endDate}
                    label="End Date"
                />

            </Form>
            <br/>
            <Grid.Row>
                    <Grid.Column>
                        <Divider horizontal>IGV</Divider>
                        <Table basic='very' collapsing compact>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        IGV Beginning $/share
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatSharePrice(igvContributionData.igv_beginning_price)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        IGV Ending $/share
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatSharePrice(igvContributionData.igv_ending_price)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        IGV % Change
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatPercentOneDecimalNoNull(igvContributionData.igv_price_change)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Sum of Return Contrib.
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatPercentOneDecimalNoNull(cumulativeContribution)}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                    </Grid.Column>

                </Grid.Row>

        </div>
    );

    return (
        <ViewWithSidebar mainView={<RdgTable records={igvContributionData.igv_contribution_records}
                                             loading={loadingIgvContributionData}
                                             columns={columns}

        />}

                         sidebarView={sidebar}/>
    )

}


const refreshData = ({dispatch, searchProps}) => {

    dispatch(fetchIgvContributionRecords({
        startDate: searchProps.startDate,
        endDate: searchProps.endDate
    }))


}
