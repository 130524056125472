import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {parseSearchProps} from "../../common/query/parse";
import {useHistory, useLocation} from "react-router-dom";
import {
    getFilterComponentsFromSpecs,
    getFilteredRangeValueFromSearchPropsForField,
    getFilteredTermFromSearchPropsForField
} from "../../common/filter/add";
import {Form, Divider} from 'semantic-ui-react'
import {
    createAddRangeFilterToQuery,
    createAddTermFilterToQuery,
    createAddValueToQuery,
    createSetFieldValuesOnQuery
} from "../../common/query/add";
import TermFilter from "../../components/filter/TermFilter";
import {fetchFacetsForPortfolioTradeRecords} from "../../features/portfolio/portfolioFeatureSlice";
import {getFullPortfolioName} from "../../common/portfolio";
import moment from 'moment'
import PortfolioSelect from "../../components/PortfolioSelect";


const filterSpecs = [
    {
        field: 'ui_display_name',
        label: 'Security Listing Name',
        type: 'terms'
    },
    {
        field: 'source_account_type',
        label: 'Source Account Type',
        type: 'terms'
    },
    {
        field: 'is_short_sale_or_cover',
        label: 'Is Short Sale / Cover?',
        type: 'booleanTerms'
    },

];

const oneMonthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
const firstTradeEver = moment('2016-01-01').format('MM/DD/YYYY');

// const fieldSpecs = {
//     standard_date: {
//         parse: (v) => {
//             if (v == null) {
//                 return oneMonthAgo
//             }
//             return v
//         }
//     }
// }

export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchFacetsForPortfolioTradeRecords());
    }, [dispatch]);

    const searchProps = parseSearchProps({searchString: location.search});

    const {
        portfolios,
        loadingPortfolios,
        securityTradesFacets,
        loadingSecurityTradesFacets
    } = useSelector(state => state.portfolio, shallowEqual);


    // quick filters
    const quickFilters = getFilterComponentsFromSpecs({
            filterSpecs: filterSpecs,
            facetsByField: securityTradesFacets,
            history,
            searchProps
        }
    );

    const tradeDateMinOptions = [
        {value: oneMonthAgo, text: oneMonthAgo, key: oneMonthAgo},
        {value: firstTradeEver, text: firstTradeEver, key: firstTradeEver}

    ]

    return (
        <Form loading={loadingSecurityTradesFacets}>

            <PortfolioSelect portfolios={portfolios}
                             value={parseInt(getFilteredTermFromSearchPropsForField({
                    searchProps,
                    fieldName: 'portfolio_id'
                }))}
                onChange={createAddTermFilterToQuery({
                    history: history,
                    searchProps,
                    fieldName: 'portfolio_id'
                })}
            />
            {/*<Form.Select*/}
            {/*    fluid*/}
            {/*    inline*/}
            {/*    options={portfolioOptions}*/}
            {/*    value={getFilteredTermFromSearchPropsForField({*/}
            {/*        searchProps,*/}
            {/*        fieldName: 'portfolio_id'*/}
            {/*    })}*/}
            {/*    onChange={createAddTermFilterToQuery({*/}
            {/*        history: history,*/}
            {/*        searchProps,*/}
            {/*        fieldName: 'portfolio_id'*/}
            {/*    })}*/}
            {/*    label="Portfolio"*/}
            {/*/>*/}
            {quickFilters}
            <Form.Select
                fluid
                inline
                options={tradeDateMinOptions}
                value={getFilteredRangeValueFromSearchPropsForField({
                    fieldName: 'standard_date',
                    searchProps,
                    isGreaterThanFilter: true
                })}
                onChange={createAddRangeFilterToQuery({
                    history,
                    searchProps,
                    fieldName: 'standard_date',
                    isGreaterThanFilter: true
                })}
                label="Min Trade Date"
            />

        </Form>

    )


}