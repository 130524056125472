import {
    formatNoDecimalNoNullOrZero,
    formatPercentNoNull,
    formatPercentOneDecimalNoNull
} from "../../../../common/format";

export const series1ChartSpecifications = [
    {
        name: '5yr Cumul. Repurchase % Shares',
        getX: (record) => 1 / record.entry_ev_to_pretax_fcf_ntm,
        getXAxisLabel: () => 'Pretax EV FCF Yield',
        formatX: formatPercentOneDecimalNoNull,

        getY: (record) => record.cumulative_repurchase_shares_percent_of_basic_entry,
        getYAxisLabel: () => '5yr Cumul. Repurchase % Shares',
        formatY: formatPercentOneDecimalNoNull,
    },
    {
        name: 'Exit Price % Increase from Repurchase Gains',
        getX: (record) => record.cumulative_repurchase_shares_percent_of_basic_entry,
        getXAxisLabel: () => '5yr Cumul. Repurchase % Shares',
        formatX: formatPercentOneDecimalNoNull,

        getY: (record) => record.share_repurchase_gain_percent_of_exit_price,
        getYAxisLabel: () => 'Exit Price % Increase from Repurchase Gains',
        formatY: formatPercentOneDecimalNoNull,
    },
];

export const series1ChartSpecificationByName = series1ChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});


export const chartOptions = series1ChartSpecifications.map(spec => {
    return {
        key: spec.name,
        value: spec.name,
        text: spec.name
    }
});


export const fieldSpecs = {
    series1ChartName: {
        parse: (v) => v == null ? series1ChartSpecifications[0].name : v
    },
};