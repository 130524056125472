import React from "react";
import {
    NoDecimalNoNullOrZeroFormatter,
    OneDecimalNoNullFormatter, PricePercentChangeOneDecimalFormatter,
    UtcToLocalFullDateTimeFormatter,
    BoolToNoFlagFormatter, BoolToNoTextFormatter
} from "../../common/reactDataGrid/formatters";
import {formatFullDateTimeUtcToLocal} from "../../common/formatDate";
import {isUndefinedOrNull} from "../../common/common";
import moment from "moment";


export class UtcToLocalTradeTimeFormatter extends React.Component {
    render() {

        const color = this.props.value.color;
        const dt = this.props.value.date;

        const style = {};
        if (color) {
            style.color = color;
        }

        return <div style={style} className="align-right">{formatTradeDateTimeUtcToLocal(dt)}</div>;
    }
}

export const formatTradeDateTimeUtcToLocal = (utcDateString) => {
    if (isUndefinedOrNull(utcDateString)) {
        return ''
    }
    return moment.utc(utcDateString).local().format('M/D, hh:mm a')
};





export const median3moDollarVolumeCol = {
    key: 'median_3_mo_dollar_volume_usd',
    name: 'Median 3mo Dollar Volume ($USDm)',
    sortable: true,
    formatter: OneDecimalNoNullFormatter,
    width: 85,
    _getValueFromRecord: (r) => {
        return r.median_3_mo_dollar_volume_usd / 1000000
    }
};

export const median3moVolumeCol = {
    key: 'median_3_mo_volume',
    name: 'Median 3mo Volume (shares)',
    sortable: true,
    formatter: NoDecimalNoNullOrZeroFormatter,
    width: 85,
};

export const avg3moVolumeCol = {
    key: 'avg_3_mo_volume',
    name: 'Avg 3mo Volume (shares)',
    sortable: true,
    formatter: NoDecimalNoNullOrZeroFormatter,
    width: 80,
};


export const lastTradeDateCol = {
    key: 'last_trade_datetime',
    name: 'Last Trade Time',
    sortable: true,
    formatter: UtcToLocalTradeTimeFormatter,
    width: 110,
    _getValueFromRecord: (r) => {

        return {
            color: r.exchange_is_open ? null : 'grey',
            date: r.last_trade_datetime
        }

    }
};

export const exchangeIsOpenCol = {
    key: 'exchange_is_open',
    name: 'Exch. Open?',
    sortable: true,
    formatter: BoolToNoTextFormatter,
    width: 50,

}

export const changeFromLastClosePriceCol = {
    key: 'price_change_from_last_close',
    name: '% Change (day)',
    sortable: true,
    formatter: PricePercentChangeOneDecimalFormatter,
    width: 80,
}

export const dailyVolumeCol = {
    key: 'daily_volume',
    name: 'Volume in Day (shares)',
    sortable: true,
    formatter: NoDecimalNoNullOrZeroFormatter,
    width: 80,
};

export const dailyDollarVolumeCol = {
    key: 'daily_dollar_volume_usd',
    name: 'Volume in Day ($m)',
    sortable: true,
    formatter: OneDecimalNoNullFormatter,
    width: 80,
    _getValueFromRecord: (r) => {
        return r.daily_dollar_volume_usd / 1000000
    }
};