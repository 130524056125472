import React, {useEffect} from 'react';
import {Form} from "semantic-ui-react";
import {createAddCheckedValueToQuery} from "../../../common/query/add";
import {parseBoolean} from "../../../common/query/parse";


export const indicesFieldSpecs = {
    showSpy: {
        parse: parseBoolean
    },
    showIgv: {
        parse: parseBoolean
    },
}



export const SpyReturnCheckbox = ({history, searchProps}) => {
    return <Form.Checkbox label="Show S&P 500 (SPY)"
                          checked={searchProps.showSpy}
                          onChange={createAddCheckedValueToQuery({
                              history,
                              searchProps,
                              fieldName: 'showSpy'
                          })}
    />
}


export const IgvReturnCheckbox = ({history, searchProps}) => {
    return <Form.Checkbox label="Show IGV (IGV)"
                          checked={searchProps.showIgv}
                          onChange={createAddCheckedValueToQuery({
                              history,
                              searchProps,
                              fieldName: 'showIgv'
                          })}
    />
}