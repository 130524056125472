import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import moment from "moment";
import {formatSharePrice, formatFullDate, formatPercentOneDecimalNoNull} from "../../../common/format";
import {getColorTextFromRgbAndOpacity} from "../../../common/chart/chartColorers";
import {getPortfolioOptionText} from "../../../common/portfolio";
const ReactHighcharts = require('react-highcharts');


class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const portfolioReturnsGroups = this.props.portfolioReturnsGroups;
        const loading = this.props.loading;
        const showAlpha = this.props.showAlpha;
        const alphaSeries = this.props.alphaSeries;

        const series = portfolioReturnsGroups.map(g => {

            const isLiveDorado = g.name === 'Dorado' && g.is_backtest === false;

            const color = isLiveDorado ? '#1b7837' : null;
            const lineWidth = isLiveDorado ? 4: 2;

            return {
                name: getPortfolioOptionText(g),
                data: g.series.map(r => {
                    return {
                        x: moment(r.standard_date).valueOf(),
                        y: r.indexed_return
                    }
                }),
                type: 'line',
                color: color,
                lineWidth: lineWidth,

            }
        })

        if (showAlpha === 'Cumulative') {
            series.push({
                name: 'Cumulative Alpha',
                data: alphaSeries.map(r => {
                    return {
                        x: moment(r.standard_date).valueOf(),
                        y: r.cumulative_alpha
                    }
                }),
                type: 'column',
                color: getColorTextFromRgbAndOpacity({r: 50, g: 100, b: 50}, .2),
                borderWidth: 0,
                // opacity: 0.9
            })
        }
        if (showAlpha === 'Daily') {
            series.push({
                name: 'Daily Alpha',
                data: alphaSeries.map(r => {
                    return {
                        x: moment(r.standard_date).valueOf(),
                        y: r.daily_alpha
                    }
                }),
                type: 'column',
                color: getColorTextFromRgbAndOpacity({r: 50, g: 100, b: 50}, .2),
                borderWidth: 0,
                // opacity: 0.9
            })
        }




        const config = {
            chart: {
                type: 'line',
                zoomType: 'xy'
            },
            title: {
                text: 'Indexed Returns'
            },
            xAxis: {
                title: {
                    text: 'Date'
                },
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return formatFullDate(this.value, false);
                    }
                }
            },
            yAxis: [
                {
                    title: {
                        text: '% Return'
                    },
                    labels: {
                        formatter: function () {
                            return formatPercentOneDecimalNoNull(this.value);
                        }

                    },
                },

            ],
            tooltip: {
                useHTML: true,
                shared: false,
                headerFormat: '',
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                        '<tr><td>' + this.series.name + '</td><td>' + formatPercentOneDecimalNoNull(this.y) + '</td></tr>' +
                        '</table>'
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                    turboThreshold: 0,
                },
                line: {
                    marker: {
                        enabled: false
                    },
                }
            },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent