import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Statistic, Button, Icon, Form} from 'semantic-ui-react'
import {convertFiltersToFeathersFilters, convertQuerySortToFeathersSort} from '../../../common/request/convert';
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import TradesSidebar from '../TradesSidebar';
import {
    fetchDailyTradeAnalysisSummaryRecords,
    fetchPortfolios,
    fetchTradeAnalysisRecords
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfoliosSummaryTable from "../PortfoliosSummaryTable";
import {analysisSyntheticTypeOptions, columns, tradeGroupTypeOptions} from "./common";
import RdgTable from "../../../components/rdg/RdgTable";
import {getFilteredTermFromSearchPropsForField} from "../../../common/filter/add";
import {
    createAddTermFilterToQuery, createAddValueToQuery,
    createSetFieldValueOnQuery,
    createSetFieldValuesOnQuery
} from "../../../common/query/add";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {fieldSpecs} from "./common";
import SummaryTable from "./SummaryTable";



export default () => {

    const {
        dailyTradeAnalysisSummaryRecords,
        loadingDailyTradeAnalysisSummaryRecords,
        portfolios,
        loadingPortfolios
    } = useSelector(state => state.portfolio, shallowEqual);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    useEffect(() => {
        dispatch(fetchPortfolios({
            filters: {
                is_backtest: false,
            },
            sort: {
                name: 1
            }
        }));
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: fieldSpecs,
        queryMatchFields: ['filters', 'sort', 'analysisSyntheticType', 'tradeGroupType'],
        requestData
    });

    const sidebar = (
        <div>
            <Grid columns={1} relaxed>
                <Grid.Row>
                    <Grid.Column>
                        <Statistic horizontal value={dailyTradeAnalysisSummaryRecords.length}
                                   label='Trades Count' size="tiny"/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <Form.Select
                                fluid
                                inline
                                options={analysisSyntheticTypeOptions}
                                value={searchProps.analysisSyntheticType}
                                onChange={createAddValueToQuery({
                                    history: history,
                                    searchProps,
                                    fieldName: 'analysisSyntheticType',
                                })}
                                label="Live / Synthetic"
                            />
                            <Form.Select
                                fluid
                                inline
                                options={tradeGroupTypeOptions}
                                value={searchProps.tradeGroupType}
                                onChange={createAddValueToQuery({
                                    history: history,
                                    searchProps,
                                    fieldName: 'tradeGroupType'
                                })}
                                label="Trade Group Type"
                            />
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <SummaryTable dailyTradeAnalysisSummaries={dailyTradeAnalysisSummaryRecords}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    );


    return (
        <ViewWithSidebar mainView={<RdgTable loading={loadingDailyTradeAnalysisSummaryRecords}
                                             records={dailyTradeAnalysisSummaryRecords}
                                             columns={columns} />}
            sidebarView={sidebar}/>
    )

};

const requestData = ({dispatch, searchProps}) => {
    const sort = convertQuerySortToFeathersSort(searchProps.sort);

    const filters = {
        synthetic_type: searchProps.analysisSyntheticType,
        trade_group_type: searchProps.tradeGroupType
    }

    dispatch(fetchDailyTradeAnalysisSummaryRecords({sort, filters}));

};
