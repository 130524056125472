import {
    formatNoDecimalNoNullOrZero,
    formatPercentNoNull,
    formatMultipleOneDecimal,
    formatPercentOneDecimalNoNull
} from "../../../../common/format";


export const series1ChartSpecifications = [
    {
        name: 'Diluted Shares CAGR (ex-repurch.) vs Entry Normalized Net Dilution',
        getX: (record) => record.normalized_net_share_grant_dilution_percent,
        getY: (record) => record.diluted_shares_cagr_ex_repurchase_and_options,
        getYAxisLabel: () => 'Diluted Shares CAGR (ex-repurch.)',
        getXAxisLabel: () => 'Normalized Net Dilution',
        formatX: formatPercentOneDecimalNoNull,
        formatY: formatPercentOneDecimalNoNull,
    },
    {
        name: 'Diluted Shares CAGR (ex-repurch.) vs Revenue CAGR',
        getX: (record) => record.revenue_total_cagr,
        getY: (record) => record.diluted_shares_cagr_ex_repurchase_and_options,
        getYAxisLabel: () => 'Diluted Shares CAGR (ex-repurch.)',
        getXAxisLabel: () => 'Revenue CAGR',
        formatX: formatPercentOneDecimalNoNull,
        formatY: formatPercentOneDecimalNoNull,
    },

];

export const series1ChartSpecificationByName = series1ChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});



export const fieldSpecs = {
    series1ChartName: {
        parse: (v) => v == null ? series1ChartSpecifications[0].name : v
    },
};