import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Header, Icon, Statistic} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {createAddValueToQuery, createSetFieldValuesOnQuery} from "../../../common/query/add";
import {fieldSpecs, columns} from './common'
import {useReloadOnChange} from "../../../common/hooks";
import {
    fetchLiveAndBacktestPortfolios,
    fetchPortfolioPositions,
    fetchPortfolios,
    fetchPortfolioStates
} from "../../../features/portfolio/portfolioFeatureSlice";
import PortfoliosSummaryTable from "../PortfoliosSummaryTable";
import {formatAbsoluteDateString} from "../../../common/formatDate";
import TermFilter from "../../../components/filter/TermFilter";
import PortfolioStateTable from "../../../components/portfolio/PortfolioStateTable";
import RdgTable from "../../../components/rdg/RdgTable";
import {sortFunctional} from "../../../common/tableHelpers";
import PortfolioCurrenciesTable from "../../../components/portfolio/PortfolioCurrenciesTable";
import {getPortfolioOptionText} from "../../../common/portfolio";
import PortfolioSelect from "../../../components/PortfolioSelect";


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        portfolios,
        loadingPortfolios,
        portfolioStates,
        loadingPortfolioStates,
        portfolioPositions,
        loadingPortfolioPositions,

    } = useSelector(state => state.portfolio, shallowEqual);

    useEffect(() => {

        const sort = {
            type: 1,
            is_backtest: 1
        };

        dispatch(fetchPortfolios({sort, filters: {}}))
    }, [dispatch]);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort', 'portfolio_id'],
        requestData: loadPortfolioStates
    });

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: ['filters', 'sort', 'portfolio_state_id'],
        requestData: loadPortfolioPositions
    });

    if (searchProps.portfolio_id == null) {
        if (!loadingPortfolios && portfolios.length > 0) {
            return <Redirect to={location.pathname + "?portfolio_id=" + portfolios[0].id}/>
        }
    }

    if ((searchProps.portfolio_state_id == null || searchProps.portfolio_state_id === '') && !loadingPortfolioStates && portfolioStates.length > 0) {
        const portfolioState = portfolioStates[0];

        if (searchProps.portfolio_id === portfolioState.portfolio_id) {
            return <Redirect to={location.pathname + "?portfolio_id=" + searchProps.portfolio_id + "&portfolio_state_id=" + portfolioState.id}/>
        }


    }

    const selectedPortfolioState = getSelectedPortfolioState({
        portfolioStates,
        portfolio_state_id: searchProps.portfolio_state_id
    });


    // const portfolioOptions = portfolios.map(p => {
    //     return {
    //         value: p.id,
    //         text: getPortfolioOptionText(p),
    //         key: p.id
    //     }
    // })

    const portfolioDateOptions = portfolioStates.map(portfolioState => {

        let text = formatAbsoluteDateString({dateString: portfolioState.standard_date, format: 'M/D/YYYY (ddd)'});
        if (!portfolioState.is_end_of_day) {

            if (portfolioState.is_backtest) {
                text += ' (Pre-Trades)';
            } else {
                text += ' (Real-Time)';
            }

        }

        return {
            text: text,
            key: portfolioState.id,
            value: portfolioState.id
        }
    });

    const sidebar = (
        <div>
            <br/>
            <Form>

                <PortfolioSelect portfolios={portfolios}
                                 value={searchProps.portfolio_id}
                                 onChange={
                                     (e, d) => {
                                         // searchProps.portfolio_id = d.value;
                                         // delete searchProps.portfolio_state_id;
                                         createSetFieldValuesOnQuery({
                                             history,
                                             searchProps,
                                             changes: {
                                                 portfolio_id: d.value,
                                                 portfolio_state_id: null
                                             }
                                         })()
                                     }
                                 }
                />

                {/*<Form.Select*/}
                {/*    fluid*/}
                {/*    inline*/}
                {/*    options={portfolioOptions}*/}
                {/*    onChange={*/}
                {/*        (e, d) => {*/}
                {/*            // searchProps.portfolio_id = d.value;*/}
                {/*            // delete searchProps.portfolio_state_id;*/}
                {/*            createSetFieldValuesOnQuery({*/}
                {/*                history,*/}
                {/*                searchProps,*/}
                {/*                changes: {*/}
                {/*                    portfolio_id: d.value,*/}
                {/*                    portfolio_state_id: null*/}
                {/*                }*/}
                {/*            })()*/}
                {/*        }*/}
                {/*    }*/}

                {/*        // createAddValueToQuery({history, searchProps, fieldName: 'portfolio_id'})}*/}
                {/*    value={searchProps.portfolio_id}*/}
                {/*    label="Portfolio"*/}
                {/*/>*/}
                <TermFilter allowNullSelection={false}
                            label='Date'
                            options={portfolioDateOptions}
                            value={searchProps.portfolio_state_id}
                            onChange={
                                createAddValueToQuery({
                                history,
                                searchProps,
                                fieldName: 'portfolio_state_id'
                            })}
                />

            </Form>
            <PortfolioStateTable portfolioState={selectedPortfolioState}/>
            <PortfolioCurrenciesTable portfolioState={selectedPortfolioState}/>

        </div>
    );

    const longRecords = sortFunctional({
        array: portfolioPositions.filter(r => r.is_long === true),
        sortKey: 'market_value',
        sortDirection: -1
    });
    const shortRecords = sortFunctional({
        array: portfolioPositions.filter(r => r.is_long !== true),
        sortKey: 'market_value',
        sortDirection: 1
    });

    const rows = [
        {
            _name: 'Long',
            _isHeader: true
        },
        ...longRecords,
        {
            _name: '',
            _isHeader: true
        },
        {
            _name: 'Short',
            _isHeader: true
        },
        ...shortRecords,


    ];

    return (
        <ViewWithSidebar mainView={<RdgTable records={rows}
                                             loading={loadingPortfolioPositions}
                                             columns={columns}

        />}

                         sidebarView={sidebar}/>
    )

}


const loadPortfolioStates = ({dispatch, searchProps}) => {

    const sort = {
        standard_date: -1
    }

    const filters = {
        portfolio_id: searchProps.portfolio_id
    }

    if (searchProps.portfolio_id != null && searchProps.portfolio_id !== "") {
        dispatch(fetchPortfolioStates({sort, filters}));

    }

}

const loadPortfolioPositions = ({dispatch, searchProps}) => {

    const sort = {}

    const filters = {
        portfolio_state_id: searchProps.portfolio_state_id
    }

    if (searchProps.portfolio_state_id != null && searchProps.portfolio_state_id !== "") {
        dispatch(fetchPortfolioPositions({sort, filters}));

    }


}




const getSelectedPortfolioState = ({portfolioStates, portfolio_state_id}) => {
    const selectedPortfolioStates = portfolioStates.filter(p => p.id === portfolio_state_id);

    if (selectedPortfolioStates.length === 0) {
        return {}
    }

    return selectedPortfolioStates[0]

}

