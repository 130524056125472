import { createSlice } from '@reduxjs/toolkit'

import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
const summaryValuationRecordService = client.service('summary_valuation_records');
const valuationTableFacetsService = client.service('search/facets/valuation_table_records');

const summaryValuationFeatureSlice = createSlice({
    name: 'summaryValuation',
    initialState: getInitialStateWithLoading({
        summaryValuationRecords: [],
        facetsByField: {},
    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchSummaryValuationRecords', destinationVariable: 'summaryValuationRecords'}),
        ...makeStandardReducers({prefix: 'fetchFacetsForValuationTableRecords', destinationVariable: 'facetsByField'}),
    }
});


export const fetchSummaryValuationRecords = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: summaryValuationFeatureSlice,
        prefix: 'fetchSummaryValuationRecords',
        getPayload: () => {return summaryValuationRecordService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}

    })
};


export const fetchFacetsForValuationTableRecords = () => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: summaryValuationFeatureSlice,
        prefix: 'fetchFacetsForValuationTableRecords',
        getPayload: () => {return valuationTableFacetsService.find({
            query: {
            }
        })}

    })
};


export default summaryValuationFeatureSlice.reducer