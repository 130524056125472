import React from 'react';

import {
    MultipleOneDecimalFormatter,
    NoDecimalNoNullOrZeroFormatter,
    PercentNoDecimalNoNullFormatter,
    PercentOneDecimalNoNullFormatter
} from "./formatters";


export const divideValueBy1000Old = (record, field) => {
    const value = record[field];
    if (value == null) {
        return null
    }
    return value / 1000
};


export const divideValueBy1000 = (value) => {
    if (value == null) {
        return null
    }
    return value / 1000
};


export const divideValueBy1000000 = (value) => {
    if (value == null) {
        return null
    }
    return value / 1000000
};


const valuationYears = [2018, 2019, 2020];

const valuationColumnTemplates = [
    {
        getKey: (year) => {
            return 'ev_to_revenue_total_' + String(year)
        },
        getName: (year) => {
            return 'EV/ ' + String(year) + ' Revenue'
        },
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'ev_to_revenue_recurring_' + String(year)
        },
        getName: (year) => {
            return 'EV/ ' + String(year) + ' Recurring Revenue'
        },
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'ev_to_gross_profit_total_' + String(year)
        },
        getName: (year) => {
            return 'EV/ ' + String(year) + ' Gross Profit'
        },
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'ev_to_gross_profit_recurring_' + String(year)
        },
        getName: (year) => {
            return 'EV/ ' + String(year) + ' Recurring GP'
        },
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'ev_to_ebitda_' + String(year)
        },
        getName: (year) => {
            return 'EV/ ' + String(year) + ' EBITDA'
        },
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'ev_to_pretax_fcf_' + String(year)
        },
        getName: (year) => {
            return 'EV/ ' + String(year) + ' Pretax FCF'
        },
        sortable: true,
        formatter: MultipleOneDecimalFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'pretax_fcf_yield_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Pretax FCF Yield'
        },
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80
    },

];

const financialColumnTemplates = [
    {
        getKey: (year) => {
            return 'revenue_growth_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Revenue Growth'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80,
    },
    {
        getKey: (year) => {
            return 'total_net_equivalent_share_grant_dilution_percent_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Share Dilution %'
        },
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'revenue_recurring_percent_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' % Recurring'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'gross_profit_recurring_percent_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Gross Profit % Recurring'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'gross_margin_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Gross Margin'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'gross_margin_recurring_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Recurring Gross Margin'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'ebitda_margin_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' EBITDA Margin'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'capitalized_software_percent_of_sales_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Cap SW % Sales'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'capital_expenditures_percent_of_sales_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Capex % Sales'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80
    },
    {
        getKey: (year) => {
            return 'pretax_free_cash_flow_percent_of_sales_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Pretax FCF % Sales'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 80
    },

    {
        getKey: (year) => {
            return 'revenue_total_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Revenue'
        },
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 90,
        makeGetValueFromRecord: (year) => {
            return divideValueBy1000Old
            //
            // return (r) => {
            //     return divideValueBy1000Old(r, 'revenue_total_' + year)
            // }
        }
    },
    {
        getKey: (year) => {
            return 'revenue_recurring_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Recurring Revenue'
        },
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 90,
        makeGetValueFromRecord: (year) => {
            return divideValueBy1000Old
            // return (r) => {
            //     return divideValueBy1000Old(r, 'revenue_recurring_' + year)
            // }
        }
    },
    {
        getKey: (year) => {
            return 'gross_profit_total_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Gross Profit'
        },
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 90,
        makeGetValueFromRecord: (year) => {
            return divideValueBy1000Old
            // return (r) => {
            //     return divideValueBy1000Old(r, 'gross_profit_total_' + year)
            // }
        }
    },
    {
        getKey: (year) => {
            return 'gross_profit_recurring_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Recurring Gross Profit'
        },
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 90,
        makeGetValueFromRecord: (year) => {
            return divideValueBy1000Old
            // return (r) => {
            //     return divideValueBy1000Old(r, 'gross_profit_recurring_' + year)
            // }
        }
    },
    {
        getKey: (year) => {
            return 'ebitda_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' EBITDA'
        },
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 90,
        makeGetValueFromRecord: (year) => {
            return divideValueBy1000Old
            // return (r) => {
            //     return divideValueBy1000Old(r, 'ebitda_' + year)
            // }
        }
    },
    {
        getKey: (year) => {
            return 'capital_expenditures_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Capex'
        },
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 90,
        makeGetValueFromRecord: (year) => {
            return divideValueBy1000Old
            // return (r) => {
            //     return divideValueBy1000Old(r, 'capital_expenditures_' + year)
            // }
        }
    },
    {
        getKey: (year) => {
            return 'pretax_free_cash_flow_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' Pretax FCF'
        },
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 90,
        makeGetValueFromRecord: (year) => {
            return divideValueBy1000Old
            // return (r) => {
            //     return divideValueBy1000Old(r, 'pretax_free_cash_flow_' + year)
            // }
        }
    },
    {
        getKey: (year) => {
            return 'ps_estimate_vs_street_revenue_total_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' PS Rev. Est. vs Street'
        },
        sortable: true,
        formatter: PercentOneDecimalNoNullFormatter,
        width: 90,
    },
    {
        getKey: (year) => {
            return 'ps_estimate_vs_street_ebitda_' + String(year)
        },
        getName: (year) => {
            return String(year) + ' PS EBITDA. Est. vs Street'
        },
        sortable: true,
        formatter: PercentNoDecimalNoNullFormatter,
        width: 90,
    },



];

const valuationColumns = valuationColumnTemplates.reduce((list, template) => {

    list = list.concat(
        valuationYears.map(year => {

            return {
                ...template,
                key: template.getKey(year),
                name: template.getName(year),
                _group: String(year),
                _filterType: 'range'
            }
        })
    );
    return list

}, []);

const financialColumns = financialColumnTemplates.reduce((list, template) => {

    list = list.concat(
        valuationYears.map(year => {

            let _getValueFromRecord = null;
            if (template.makeGetValueFromRecord != null) {
                _getValueFromRecord = template.makeGetValueFromRecord(year);
            }

            return {
                ...template,
                key: template.getKey(year),
                name: template.getName(year),
                _group: String(year),
                _getValueFromRecord,
                _filterType: 'range'
            }
        })
    );
    return list

}, []);

export const simpleScreenYears = [2019, 2020];
export const forwardYear = 2019;


export const getTableField = ({baseField, period}) => {
    return baseField + '_' + period
};

export const getValueWithT1AndT2Years = (s) => {
    return simpleScreenYears.map(year => {
        return s + '_' + year
    })
};

export const getValueWithFowardYear = (s) => {
    return s + '_' + forwardYear
};


const valuationColumnKeys = valuationColumns.map(col => col.key);
const financialPeriodColumnKeys = financialColumns.map(col => col.key);
const valuationTableKeys = valuationColumnKeys.concat(financialPeriodColumnKeys);



export const getColumnsAdjustedForEstimatesType = ({columns, estimatesTypeSpec}) => {

    return columns.map(column => {
        if (valuationTableKeys.includes(column.key)) {
            return {
                ...column,
                key: estimatesTypeSpec.getKey(column.key)
            }
        }
        return column
    })

};