import client from '../../empClient';
const rolesService = client.service('roles');
const rolesFacetService = client.service('role_facets');



export function fetchRolesForCompany({sort, filters, companyId}) {

    return dispatch => {
        dispatch({
            type: 'FETCH_ROLES_FOR_COMPANY',
            payload: rolesService.find({
                query: {
                  company_id: companyId,
                  $sort: sort,
                  ...filters
                }
            })
        });

    };
}


export function fetchRolesForScrapeId({sort, filters, scrapeId}) {

    return dispatch => {
        dispatch({
            type: 'FETCH_ROLES_FOR_COMPANY',
            payload: rolesService.find({
                query: {
                  employees_scrape_record_id: scrapeId,
                  $sort: sort,
                  ...filters
                }
            })
        });

    };
}


export function deleteRole({id}) {
    return dispatch => {
        return dispatch({
            type: 'DELETE_ROLE',
            payload: rolesService.remove(id)
        });
    };
}

export function fetchRole({id}) {

    return dispatch => {
        return dispatch({
            type: 'FETCH_ROLE',
            payload: rolesService.get(id)
        });
    };
}


export function newRole() {
    return dispatch => {
        dispatch({
            type: 'NEW_ROLE'
        });
    };
}

export function fetchRoleFacets() {

    return dispatch => {
        dispatch({
            type: 'FETCH_ROLE_FACETS',
            payload: rolesFacetService.find()
        });
    };
}


export function modifyRole({fieldName, value}) {

  return {
    type: 'MODIFY_ROLE_FIELD',
    payload: {
      fieldName: fieldName,
      value: value
    }
  }
}


export function createNewRole(role) {
    return dispatch => {
        return dispatch({
            type: 'CREATE_NEW_ROLE',
            payload: rolesService.create(role)
        });
    };
}

export function patchRole(role) {
  return dispatch => {
    return dispatch({
      type: 'PATCH_ROLE',
      payload: rolesService.patch(role.id, role)
    });
  };
}
