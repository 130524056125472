import client from '../../benchClient';
import {FinancialPeriodType, PeriodType} from "../../common/constants";
import {fetchQueueData} from "../metadataActions";

const processService = client.service('akkr_process');
const importDataService = client.service('akkr_import_company_data');
const metadataService = client.service('akkr_metadata');
const akkrCompaniesService = client.service('akkr_companies');
const akkrFinancialsService = client.service('akkr_financial_period_records');
const clearAkkrBenchmarkMetadataService = client.service('clear_akkr_benchmark_metadata');


function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function startAkkrImportProcess({dispatch}) {

    return dispatch({
        type: 'START_AKKR_IMPORT_PROCESS',
        payload: processService.find().then(result => {

            return sleep(3000).then(() => {
                fetchQueueData({dispatch});
            });

        })
    });

}

export function startAkkrImportCompanyDataProcess({dispatch}) {

    return dispatch({
        type: 'START_AKKR_IMPORT_COMPANY_DATA_PROCESS',
        payload: importDataService.find().then(result => {

            return sleep(3000).then(() => {
                console.log('fetching metadata')
                fetchAkkrMetadata({dispatch});
            });

        })
    });

}



export function fetchAkkrMetadata({dispatch}) {
    return dispatch({
        type: 'FIND_AKKR_METADATA',
        payload: metadataService.find({})
    });

}


export function fetchAkkrCompanyRecords({dispatch, sort, filters}) {

    dispatch({
        type: 'FETCH_AKKR_COMPANY_RECORDS',
        payload: akkrCompaniesService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })
    });

}


export function fetchAkkrCompany({dispatch, id}) {
    return dispatch({
        type: 'FETCH_AKKR_COMPANY',
        payload: akkrCompaniesService.get(id)
    });

}


export const fetchFinancialsForAkkrCompany = ({dispatch, akkr_company_id, currencyType, periodType, isLtm}) => {

    dispatch({
        type: 'FIND_AKKR_FINANCIAL_RECORDS_FOR_COMPANY',
        payload: akkrFinancialsService.find({
            query: {
                akkr_company_id: akkr_company_id,
                $sort: {
                    period_end_date: 1
                },
                is_calendarized: false,
                is_ltm: isLtm,
                currency_type: currencyType,
                period_type: periodType


            }
        })
    });

}
