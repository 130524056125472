import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Container, Form, Grid} from 'semantic-ui-react'
import {fetchCompany} from '../../../../actions/companyActions';
import {useReloadOnChange} from "../../../../common/hooks";
import CompanyHeader from "../../../../components/CompanyHeader";
import {
    fetchAllAnnualDoradoFinancialPeriodRecordsForCompany,
    fetchDoradoAuditRecordsForCompany
} from "../../../../actions/dorado/doradoCompanyActions";
import {
    fetchDoradoForecastMetadatasForCompany
} from "../../../../features/dorado/doradoCompanyDetail";

import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../../common/query/parse";
import DoradoBacktestComparisonTable from "./DoradoBacktestComparisonTable";
import {partition} from "../../../../common/common";
import {ForecastType} from "../../../../common/constants";



export default () => {

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: [],
        matchFields: ['id'],
        requestData: requestBacktestForecasts
    });

    const {
        company,
        loadingCompany,
        loadingDoradoFinancialPeriodRecords,
        doradoFinancialPeriodRecords
    } = useSelector(state => state.companyStore, shallowEqual);

    const {
        doradoForecastMetadatas,
        loadingDoradoForecastMetadatas
    } = useSelector(state => state.doradoCompanyDetail, shallowEqual);

    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    const loading = loadingCompany || loadingDoradoFinancialPeriodRecords || loadingDoradoForecastMetadatas;


    let fiscalYearEnds = [];
    let rows = [];
    if (!loading) {

        fiscalYearEnds = [...new Set(doradoFinancialPeriodRecords.map(r => r.period_end_date))].sort();

        rows = doradoForecastMetadatas.map(metadata => {

            const obj = {
                metadata
            };
            const matchingRecords = doradoFinancialPeriodRecords.filter(r => r.dorado_forecast_metadata_id === metadata.id);
            for (let record of matchingRecords) {
                obj[record.period_end_date] = record;
            }
            return obj
        });

    }

    const [revisedMetadataRows, standardMetadataRows] = partition(rows, r => r.metadata.forecast_type === ForecastType.revised_point_sur_backtest);

    rows = [
        ...standardMetadataRows,
        {metadata: {}},
        ...revisedMetadataRows
    ]

    return (
        <Container fluid>
            <Grid padded>

                <Grid.Column width={4}>
                    <Grid.Row>
                        <Grid>
                            <Grid.Column width={14}>
                                <CompanyHeader company={company} loading={loadingCompany}/>
                            </Grid.Column>
                        </Grid>
                    </Grid.Row>

                </Grid.Column>
                <Grid.Column width={12}>
                    <DoradoBacktestComparisonTable fiscalYearEnds={fiscalYearEnds} rows={rows}/>

                </Grid.Column>
            </Grid>
        </Container>
    )

}


const requestBacktestForecasts = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    fetchCompany({dispatch, id});
    dispatch(fetchDoradoForecastMetadatasForCompany({companyId: id}));
    fetchAllAnnualDoradoFinancialPeriodRecordsForCompany({dispatch, companyId: id})


};