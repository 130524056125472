import {formatNoDecimalNoNullOrZero, formatPercentOneDecimalNoNull} from "../../../common/format";
import moment from "moment";


export const series1ChartSpecifications = [
    {
        name: 'Monthly $ Gain',
        getYAxisLabel: () => 'Monthly $ Gain',
        formatY: formatNoDecimalNoNullOrZero,
        getPeriodReportY: (record) => record.positions_total_gain,
        getPositionReportY: (record) => record.total_gain,

    },
    {
        name: 'Monthly % Gain',
        getYAxisLabel: () => 'Monthly % Gain',
        formatY: formatPercentOneDecimalNoNull,
        getPeriodReportY: (record) => record.time_weighted_return,
        getPositionReportY: (record) => record.total_gain_percent_of_average_portfolio_nav,
    },


];


export const series1chartOptions = series1ChartSpecifications.map(spec => {
    return {
        key: spec.name,
        value: spec.name,
        text: spec.name
    }
});

export const series1ChartSpecificationByName = series1ChartSpecifications.reduce((obj, spec) => {
    obj[spec.name] = spec;
    return obj
}, {});


export const topPositionCountOptions = [1, 2, 3, 4, 5, 10, 30].map(v => {
    return {
        key: v,
        value: v,
        text: v
    }
});


export const longShortTypeOptions = ['All', 'Long', 'Short'].map(v => {
    return {
        key: v,
        value: v,
        text: v
    }
});



export const fieldSpecs = {
    series1ChartName: {
        parse: (v) => v == null ? series1ChartSpecifications[0].name : v
    },
    topPositionCount: {
        parse: (v) => v == null ? 3 : parseInt(v)
    },
    longShortType: {
        parse: (v) => v == null ? 'All' : v
    }
};


export const getMonthCategory = (s) => {
    return moment(s).format('MMM-YY')
}


export const getXValue = (d) => {
    // need start of month to line up on axis correctly
    return moment(d).startOf('month').valueOf()
}
