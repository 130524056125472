import React from 'react'
import {Form, Button} from 'semantic-ui-react'
import RadioGroup from '../../components/RadioGroup'


const axesSizes = [
  'Default',
  'Custom',
  'None'
];


export default function AxesSizer({
                                    selectedAxesSize,
                                    onChangeAxesSize,
                                    selectedAxesSpec,
                                    onChangeAxesSpec,
                                    onClearAxesSpec
                                  }) {

  const sizeOptions = axesSizes.map(name => {
    return {
      label: name,
      value: name
    }
  });

  const sizer = (
    <div>
      <Form.Group >
        <Form.Input label='X Min' width={4} value={selectedAxesSpec.minX || ''}
                    onChange={onChangeAxesSpec('minX', selectedAxesSpec)}/>
        <Form.Input label='X Max' width={4} value={selectedAxesSpec.maxX || ''}
                    onChange={onChangeAxesSpec('maxX', selectedAxesSpec)}/>
        <Form.Input label='Y Min' width={4} value={selectedAxesSpec.minY || ''}
                    onChange={onChangeAxesSpec('minY', selectedAxesSpec)}/>
        <Form.Input label='Y Max' width={4} value={selectedAxesSpec.maxY || ''}
                    onChange={onChangeAxesSpec('maxY', selectedAxesSpec)}/>
      </Form.Group>
      <Form.Group>
        <Button onClick={onClearAxesSpec}>
          Clear
        </Button>
      </Form.Group>

    </div>
  );

  return (
    <div>
      <RadioGroup
        title="Axes Size"
        radioObjects={sizeOptions}
        selectedValue={selectedAxesSize}
        onChange={onChangeAxesSize}
        horizontal={true}
      />
      {selectedAxesSize === 'Custom' ? sizer : ''}

    </div>
  )


}

