import {Button, Divider, Grid, Header, Icon, Table, Form} from "semantic-ui-react";
import React from "react";
import {
    formatNoDecimalNoNull,
    formatNoDecimalNoNullOrZero,
    formatOneDecimalNoNull,
    formatPercentOneDecimalNoNull,
    formatPercentTwoDecimalNoNull, formatSharePrice
} from "../../../common/format";
import {formatFullDateTimeUtcToLocal} from "../../../common/formatDate";
import {getPortfolioSharesAsOfText, getPricesAsOfText, getTradeRecommendationsAsOfText} from "../common";
import DoradoParamsDetail from "./DoradoParamsDetail";
import {viewOptions, fieldSpecs} from "./common";
import {createAddValueToQuery} from "../../../common/query/add";
import TermFilter from "../../../components/filter/TermFilter";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {parseSearchProps} from "../../../common/query/parse";
import {sumArray} from "../../../common/common";
import StartProcessWithPollingButton from "../../../components/StartProcessWithPollingButton";
import {getTradeOrderIds} from "./orders";
import {requestApprovalForTradeOrders} from "../../../features/dorado/tradeApproval";
import TargetPortfolioAttributesTable from "../targetCommon/TargetPortfolioAttributesTable";
import TargetTradeOrdersTable from "../targetCommon/TargetTradeOrdersTable";
import {sortFunctional} from "../../../common/tableHelpers";
import {TradeType} from "../../../common/constants";
import {setIncludeTradeOrderFalse} from "../../../features/dorado/doradoTargetPortfolio";


export default ({
                    currentDoradoTargetPortfolioState,
                    loadingCurrentDoradoTargetPortfolioState,
                    doradoPortfolioTradeMetadata,
                    doradoTargetSecurityOrders,
                    onStartProcess,
                    onStartLongCalc,
                    onStartShortCalc
                }) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    const {
        currentDoradoTargetData,
        loadingCurrentDoradoTargetData,
    } = useSelector(state => {return state.doradoTargetPortfolio}, shallowEqual);

    const {
        outstandingUnfilledOrders,
        newOrderConfirmation,
        loadingNewOrderConfirmation
    } = useSelector(state => {return state.flex}, shallowEqual);

    const {
        approvalRequestConfirmation,
        loadingApprovalRequestConfirmation
    } = useSelector(state => {return state.tradeApproval}, shallowEqual);

    const orderIds = getTradeOrderIds({doradoTargetSecurityOrders});

    let requestTradeApprovalButton = <div/>;

    const targetOrdersCount = loadingCurrentDoradoTargetData ? null : currentDoradoTargetData.dorado_target_security_orders.length;

    const buyOrders = sortFunctional({
        array: doradoTargetSecurityOrders.filter(r => r.trade_type === TradeType.buy),
        sortKey: 'gross_value_before_transaction_cost_usd',
        sortDirection: -1
    });

    const sellOrders = sortFunctional({
        array: doradoTargetSecurityOrders.filter(r => r.trade_type !== TradeType.buy),
        sortKey: 'gross_value_before_transaction_cost_usd',
        sortDirection: -1
    });

    const buyLongOrders = buyOrders.filter(order => order.is_short_sale_or_cover === false);
    const buyCoverOrders = buyOrders.filter(order => order.is_short_sale_or_cover === true);

    const sellLongOrders = sellOrders.filter(order => order.is_short_sale_or_cover === false);
    const sellShortOrders = sellOrders.filter(order => order.is_short_sale_or_cover === true);


    if (searchProps.viewOption === 'Real Orders') {
        requestTradeApprovalButton = (
            <div>
                <Form>
                    <Form.Input label={'Scale Orders (multiplier from 0 to 1)'}
                                width={6}
                                value={searchProps.orderValueMultiplier}
                                onChange={createAddValueToQuery({
                                    history,
                                    searchProps,
                                    fieldName: 'orderValueMultiplier'
                                })}

                    />
                    <Form.Button primary basic loading={loadingApprovalRequestConfirmation} onClick={
                        () => dispatch(requestApprovalForTradeOrders({
                            orderIds,
                            multiplier: searchProps.orderValueMultiplier
                        }))
                    }>
                        <Icon name='bolt'/>
                        Request Trade Approval
                    </Form.Button>
                    <Form.Button basic onClick={() => dispatch(setIncludeTradeOrderFalse())}>
                        <Icon color='black' name="x" size="large"/>
                        Clear Orders
                    </Form.Button>

                </Form>


            </div>



        );
    }

    return (
        <div>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Header>
                            Portfolio Trade Recommendations
                        </Header>
                        <Table basic={'very'} compact={'very'} collapsing>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Portfolio Shares as of:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatFullDateTimeUtcToLocal(doradoPortfolioTradeMetadata.portfolio_shares_as_of_date)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Prices as of:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatFullDateTimeUtcToLocal(doradoPortfolioTradeMetadata.prices_as_of_date)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Trade Recommendations as of:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatFullDateTimeUtcToLocal(doradoPortfolioTradeMetadata.trade_recommendations_as_of_date)}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <StartProcessWithPollingButton expectedCompletionSeconds={40}
                                               onClick={onStartProcess}
                                               buttonColor={'green'}
                                               iconName={'refresh'}
                                               buttonText={'Recalc Target Portfolio'}

                />
                <StartProcessWithPollingButton expectedCompletionSeconds={40}
                                               onClick={onStartLongCalc}
                                               buttonColor={'grey'}
                                               iconName={'chevron up'}
                                               buttonText={'Recalc Long'}
                                               hidePolling={true}

                />
                <StartProcessWithPollingButton expectedCompletionSeconds={40}
                                               onClick={onStartShortCalc}
                                               buttonColor={'grey'}
                                               iconName={'chevron down'}
                                               buttonText={'Recalc Short'}
                                               hidePolling={true}

                />

                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <TermFilter allowNullSelection={false}
                                        label='View'
                                        options={viewOptions}
                                        value={searchProps.viewOption}
                                        onChange={createAddValueToQuery({
                                            history,
                                            searchProps,
                                            fieldName: 'viewOption'
                                        })}
                            />
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider horizontal>
                            Orders
                        </Divider>
                        {requestTradeApprovalButton}
                        <Table basic={'very'} compact={'very'} collapsing>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Outstanding FlexNOW Orders:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {outstandingUnfilledOrders.length}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        New Target Orders:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {targetOrdersCount}
                                    </Table.Cell>
                                </Table.Row>

                            </Table.Body>

                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <DoradoParamsDetail doradoPortfolioTradeMetadata={doradoPortfolioTradeMetadata}/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <TargetPortfolioAttributesTable targetPortfolioState={currentDoradoTargetPortfolioState}
                                                        loading={loadingCurrentDoradoTargetPortfolioState}

                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <TargetTradeOrdersTable buyLongOrders={buyLongOrders}
                                                sellLongOrders={sellLongOrders}
                                                buyCoverOrders={buyCoverOrders}
                                                sellShortOrders={sellShortOrders}


                        />


                    </Grid.Column>
                </Grid.Row>

            </Grid>
        </div>
    )

}


