import React from 'react'
import {Form, Button, Input, Icon} from 'semantic-ui-react'


export default function DeletableRangeFilter({
                                    label,
                                    onChangeMin,
                                    minValue,
                                    onChangeMax,
                                    maxValue,
                                    onClickDelete
                                  }) {

  const deleteButton = <Button icon floated='right' onClick={onClickDelete} size='small'>
    <Icon name='delete'/>
  </Button>;

  return (
    <Form.Group widths='equal'>
      <Form.Field>
        <label style={{height: 36}}>{label}</label>
        <Input fluid placeholder='min' onChange={onChangeMin} value={minValue}/>
      </Form.Field>


      <Form.Field>
        <label style={{color: 'white', height: 36}}>{deleteButton}</label>
        <Input fluid placeholder='max' onChange={onChangeMax} value={maxValue}/>
      </Form.Field>
    </Form.Group>

  )


}
