import React, {useEffect} from 'react';
import {Button, Label, Divider, Form, Grid, Header, Icon, Statistic, Table} from 'semantic-ui-react'

import {CurrencySharePriceFormatter, headerLabelSpec, TradeTypeFormatter} from "../../../common/columns";
import {
    BoolToYesNoFormatter,
    NoDecimalNoNullOrZeroFormatter,
    PercentTwoDecimalNoZeroFormatter, TwoDecimalCurrencyFormatter, BoolToYesNoCheckFormatter
} from "../../../common/reactDataGrid/formatters";
import {exchangeIsOpenCol} from "../commonColumns";
import {boolOptions} from "../../../common/reactDataGrid/common";
import {Editors} from 'react-data-grid-addons';

const {DropDownEditor} = Editors;



export const columns = [
    headerLabelSpec,
    {
        key: 'id',
        name: 'ID',
        width: 70,
    },
    {
        key: 'trade_type',
        name: 'Trade Type',
        width: 80,
        formatter: TradeTypeFormatter,
        _getValueFromRecord: (r) => {
            return {
                trade_type: r.trade_type,
                is_short_sale_or_cover: r.is_short_sale_or_cover
            }
        }
    },
    {
        key: 'is_internal_fill',
        name: 'Fill Internal?',
        sortable: true,
        formatter: BoolToYesNoCheckFormatter,
        width: 80,
        editable: true,
        editor: <DropDownEditor options={boolOptions}/>

    },
    {
        key: 'last_price_per_share',
        name: 'Last Price $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.last_price_per_share,
                trading_currency: r.currency
            }
        }
    },
    {
        key: 'limit_price_per_share',
        name: 'Limit Price $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.limit_price_per_share,
                trading_currency: r.currency
            }
        }
    },
    {
        key: 'limit_price_premium_vs_last_price',
        name: 'Limit vs Last Price',
        width: 80,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'shares',
        name: 'Shares',
        width: 100,
        formatter: NoDecimalNoNullOrZeroFormatter
    },
    {
        key: 'gross_value_before_transaction_cost_usd',
        name: 'Gross Value $USD (before transaction cost)',
        width: 110,
        formatter: TwoDecimalCurrencyFormatter
    },

    {
        key: 'gross_value_before_transaction_cost',
        name: 'Gross Value $local (if different)',
        width: 110,
        formatter: TwoDecimalCurrencyFormatter,
        _getValueFromRecord: (r) => {
            if (r.currency === 'USD') {
                return null
            }
            return r.gross_value_before_transaction_cost

        }
    },
    {
        key: 'exchange_is_open',
        name: 'Exch. Open?',
        sortable: true,
        formatter: BoolToYesNoFormatter,
        width: 50,

    },
    {
        key: 'gs_trade_algo_template',
        name: 'Algo Template',
        width: 200,
    },


]