import {createSlice} from '@reduxjs/toolkit'

import client from '../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "./common";
import {PeriodType} from "../common/constants";

const shareClassesAndSecurityListingsForCompanyService = client.service('share_classes_and_security_listings_for_company');
const companiesService = client.service('companies');
const historicalPeriodRecordService = client.service('historical_period_records');
const tradingSharePricesService = client.service('trading_share_prices');
const securityListingService = client.service('security_listings')
const shareClassService = client.service('share_classes')


const companyFeatureSlice = createSlice({
    name: 'company',
    initialState: getInitialStateWithLoading({
        company: {},
        shareClassesAndSecurityListings: [],
        historicalPeriodRecords: [],

        sharePriceTrading: null,


    }),
    reducers: {
        ...makeStandardReducers({prefix: 'fetchCompany', destinationVariable: 'company'}),
        ...makeStandardReducers({
            prefix: 'fetchShareClassesAndSecurityListingsForCompany',
            destinationVariable: 'shareClassesAndSecurityListings'
        }),
        ...makeStandardReducers({
            prefix: 'fetchHistoricalPeriodRecordsForCompany',
            destinationVariable: 'historicalPeriodRecords'
        }),
        ...makeStandardReducers({
            prefix: 'fetchLastSharePriceTradingForCompany',
            destinationVariable: 'sharePriceTrading'
        }),
        patchSecurityListingPending: (state) => {
            state.loadingShareClassesAndSecurityListings = true;
        },
        patchSecurityListingFulfilled: (state, action) => {
        },
        patchShareClassPending: (state) => {
            state.loadingShareClassesAndSecurityListings = true;
        },
        patchShareClassFulfilled: (state, action) => {
        },




    }
});


export const fetchCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: companyFeatureSlice,
        prefix: 'fetchCompany',
        getPayload: () => companiesService.get(companyId)
    })
};


export const fetchShareClassesAndSecurityListingsForCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: companyFeatureSlice,
        prefix: 'fetchShareClassesAndSecurityListingsForCompany',
        getPayload: () => shareClassesAndSecurityListingsForCompanyService.get(companyId)
    })
};

export const fetchHistoricalPeriodRecordsForCompany = ({companyId, periodType}) => async (dispatch, getState) => {

    const periodTypes = periodType === PeriodType.annual ? [periodType] : [PeriodType.quarterly, PeriodType.half];

    await executeWithPending({
        dispatch,
        featureSlice: companyFeatureSlice,
        prefix: 'fetchHistoricalPeriodRecordsForCompany',
        getPayload: () => {
            return historicalPeriodRecordService.find({
                query: {
                    company_id: companyId,
                    $sort: {
                        period_end_date: 1
                    },
                    period_type: {
                        $in: periodTypes
                    }
                }

            })
        }
    })
};


export const fetchLastSharePriceTradingForCompany = ({companyId}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: companyFeatureSlice,
        prefix: 'fetchLastSharePriceTradingForCompany',
        getPayload: () => tradingSharePricesService.get(companyId)
    })
};


export const patchSecurityListing = ({id, patchData}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: companyFeatureSlice,
        prefix: 'patchSecurityListing',
        getPayload: () => {return securityListingService.patch(id, patchData)}
    })
};

export const patchShareClass = ({id, patchData}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: companyFeatureSlice,
        prefix: 'patchShareClass',
        getPayload: () => {return shareClassService.patch(id, patchData)}
    })
};



export default companyFeatureSlice.reducer