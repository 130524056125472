
const defaultState = {

  recordBeingAssociated: {},
  loadingAssociation: false,
  lastAssociatedRole: null,
  associationCounts: {total: 0, associated: 0}
};

export default (state=defaultState, action={}) => {

  switch (action.type) {

    case 'SET_RECORD_BEING_ASSOCIATED': {
      return {
        ...state,
        recordBeingAssociated: action.payload,
        loadingAssociation: false
      }
    }

    case "ASSOCIATE_EMPLOYEE_WITH_ROLE_PENDING": {
      return {
        ...state,
        loadingAssociation: true
      }
    }

    case "ASSOCIATE_EMPLOYEE_WITH_ROLE_FULFILLED": {
      return {
        ...state,
        loadingAssociation: false
      }
    }

    case "ASSOCIATE_DESCRIPTION_WITH_ROLE_PENDING": {
      return {
        ...state,
        loadingAssociation: true
      }
    }

    case "ASSOCIATE_DESCRIPTION_WITH_ROLE_FULFILLED": {
      return {
        ...state,
        loadingAssociation: false
      }
    }

    case "SET_LAST_ASSOCIATED_ROLE": {
      return {
        ...state,
        lastAssociatedRole: action.payload
      }
    }

    case 'GET_ASSOCIATION_COUNTS_FOR_SCRAPE_FULFILLED': {
      return {
        ...state,
        associationCounts: {...action.payload.data}
      }
    }


    default:
      return state;

  }
}
