import {
    BoolToYesCheckFormatter,
    BoolToYesFormatter,
    DateFormatter,
    NoDecimalNoNullFormatter,
    NoDecimalNoNullOrZeroFormatter,
    PercentChangeOneDecimalPositiveNegativeFormatter, PercentFourDecimalNoNullFormatter,
    PositiveNegativeTwoDecimalFormatter,
    PricePercentChangeOneDecimalFormatter
} from "../../../common/reactDataGrid/formatters";
import React from "react";


export const columns = [
    {
        key: 'company_or_etf_name',
        name: 'Company or ETF',
        sortable: false,
        width: 200,
        _getValueFromRecord: (r) => {

            if (r.security_trade.company_id != null) {
                return r.security_trade.company.short_name
            }
            return r.security_trade.exchange_traded_fund.short_name
        },
    },
    {
        key: 'standard_date',
        name: 'Date',
        sortable: true,
        formatter: DateFormatter,
        width: 90,
        _getValueFromRecord: (r) => {
            return r.security_trade.standard_date
        },
    },
    {
        key: 'trade_type',
        name: 'Type',
        sortable: true,
        width: 60,
        _getValueFromRecord: (r) => {
            return r.security_trade.trade_type
        },
    },
    {
        key: 'is_short_sale_or_cover',
        name: 'Is Short Sale or Cover',
        sortable: true,
        width: 60,
        formatter: <BoolToYesFormatter/>,
        _getValueFromRecord: (r) => {
            return r.security_trade.is_short_sale_or_cover
        },
    },
    {
        key: 'is_synthetic',
        name: 'Is Synthetic',
        sortable: true,
        width: 70,
        formatter: <BoolToYesCheckFormatter/>,
    },
    {
        key: 'gross_value_before_transaction_cost_usd',
        name: 'Gross Value $USD',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110,
        _getValueFromRecord: (r) => {
            return r.security_trade.gross_value_before_transaction_cost_usd
        },
    },
    {
        key: 'price_per_share_ex_hard_transaction_costs',
        name: 'Execution Price $/trading',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 100,
        _getValueFromRecord: (r) => {
            return r.security_trade.price_per_share_ex_hard_transaction_costs
        },
    },
    {
        key: 'vwap_per_share_trading',
        name: 'VWAP $/trading',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 100,
    },
    {
        key: 'close_price_per_share_trading',
        name: 'Close $/trading',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 100,
    },
    {
        key: 'gain_vs_vwap_percent',
        name: 'Favorable % vs VWAP',
        sortable: true,
        formatter: PercentChangeOneDecimalPositiveNegativeFormatter,
        width: 100,
    },
    {
        key: 'gain_vs_close_percent',
        name: 'Favorable % vs Close',
        sortable: true,
        formatter: PercentChangeOneDecimalPositiveNegativeFormatter,
        width: 100,
    },
    {
        key: 'gain_vs_vwap_usd',
        name: 'Gain $ vs VWAP',
        sortable: true,
        formatter: NoDecimalNoNullFormatter,
        width: 100,
    },
    {
        key: 'gain_vs_close_usd',
        name: 'Gain $ vs Close',
        sortable: true,
        formatter: NoDecimalNoNullFormatter,
        width: 100,
    },
    {
        key: 'gain_vs_vwap_usd_percent_of_nav',
        name: 'Favorable % vs VWAP of NAV',
        sortable: true,
        formatter: PercentFourDecimalNoNullFormatter,
        width: 100,
    },
    {
        key: 'gain_vs_close_usd_percent_of_nav',
        name: 'Favorable % vs Close of NAV',
        sortable: true,
        formatter: PercentFourDecimalNoNullFormatter,
        width: 100,
    },





    {
        key: 'shares',
        name: 'Shares',
        sortable: true,
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 110,
        _getValueFromRecord: (r) => {
            return r.security_trade.shares
        },
    },

    {
        key: 'source_account_type',
        name: 'Source Account',
        sortable: true,
        width: 200,
        _getValueFromRecord: (r) => {
            return r.security_trade.source_account_type
        },
    },


];