import React from 'react';
import {
    DateFormatter, NoDecimalNoNullFormatter,
    PositiveNegativeTwoDecimalFormatter,
    SharePriceFormatter, SharePriceFourDecimalFormatter,
    UtcDateFormatter
} from "../../../common/reactDataGrid/formatters";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";


export const columns = [
    CompanyLinkFromSecurityListing,
    {
        key: 'standard_date',
        name: 'Date / Payment Date',
        sortable: true,
        formatter: DateFormatter,
        width: 90
    },
    {
        key: 'ex_dividend_date',
        name: 'Ex-Dividend Date',
        sortable: true,
        formatter: DateFormatter,
        width: 90
    },
    {
        key: 'trade_type',
        name: 'Type',
        sortable: true,
        width: 80
    },
    {
        key: 'dividend',
        name: 'Dividend $',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110
    },
    {
        key: 'currency',
        name: 'Currency',
        sortable: true,
        width: 90
    },
    {
        key: 'dividend_per_share',
        name: 'Dividend $/share',
        sortable: true,
        formatter: SharePriceFourDecimalFormatter,
        width: 100
    },
    {
        key: 'shares',
        name: 'Shares',
        sortable: true,
        formatter: NoDecimalNoNullFormatter,
        width: 100
    },
    {
        key: 'dividend_usd',
        name: 'Dividend $USD',
        sortable: true,
        formatter: PositiveNegativeTwoDecimalFormatter,
        width: 110
    },
    {
        key: 'source_account_type',
        name: 'Source Account',
        sortable: true,
        width: 150
    },

];

