import React, {Component, useEffect} from 'react'
import {Form, Divider} from 'semantic-ui-react'
import {getFilterComponentsFromSpecs} from '../../common/filter/add'
import {commonFilterSpecsByName} from "../../common/filter/common";
import {parseSearchProps} from "../../common/query/parse";
import CompanySelectBox from '../../components/CompanySelectBox'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchFacetsForTags} from "../../actions/tagActions";
import {useHistory, useLocation} from "react-router-dom";


const filterSpecs = [
    commonFilterSpecsByName.geography_group,
    commonFilterSpecsByName.business_quality,
    commonFilterSpecsByName.management_score,
    commonFilterSpecsByName.primary_market_type,
    commonFilterSpecsByName.is_public,
    commonFilterSpecsByName.is_or_was_public,
    commonFilterSpecsByName.has_valid_period_info,
    commonFilterSpecsByName.halt_ciq_data_updates,
    commonFilterSpecsByName.has_valid_share_classes,
    commonFilterSpecsByName.in_dorado_universe,
    commonFilterSpecsByName.is_or_was_in_portfolio,

];


export default () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const searchProps = parseSearchProps({searchString: location.search});

    useEffect(() => {
        fetchFacetsForTags({dispatch})
    }, [dispatch]);

    const {facetsByField, loadingFacets} = useSelector(state => state.tagsStore, shallowEqual);

    const filters = getFilterComponentsFromSpecs({
            filterSpecs,
            facetsByField: facetsByField,
            history,
            searchProps
        }
    );

    return (
        <Form loading={loadingFacets}>
            <CompanySelectBox/>
            {filters}
        </Form>
    )

}