import {combineReducers} from 'redux';
import CompanyReducer from './companyReducer';
import EmployeeRecordReducer from './employeeRecordReducer'
import AssociationRecordReducer from './associationReducer'
import RoleReducer from './roleReducer'
import EmployeeAnalysisReducer from './employeeAnalysisReducer'
import JobPostingReducer from './jobPostingReducer'
import ProfileReducer from './profileReducer'
import CompanyValuationReducer from './valuation/companyValuationReducer'
import AdminReducer from './adminReducer'
import CoverageRecordsReducer from './coverageSummaryRecordsReducer'
import TagsReducer from './tagsReducer'
import ValuationTableReducer from './valuationTableReducer'
import PortfolioReducer from './portfolioReducer'
import ValuationMetadataReducer from './valuationMetadataReducer'
import AkkrReducer from './akkr/akkrReducer'
import AkkrCompaniesReducer from './akkr/akkrCompaniesReducer'
import AkkrTableReducer from './akkr/akkrTableReducer'
import DoradoReducer from './doradoReducer'
import HistoricalValuationRecordsReducer from './historicalValuationRecordsReducer'
import AkkrCensusReducer from './akkr/akkrCensusReducer'
import feed from '../features/feedFeatureSlice'
import earningsDateFeed from '../features/earningsDateFeatureSlice'
import doradoValuation from '../features/dorado/doradoValuationRecords'
import doradoTargetPortfolio from '../features/dorado/doradoTargetPortfolio'
import doradoBacktestAudit from '../features/dorado/doradoBacktestAuditRecords'
import realTimeDoradoValuation from '../features/dorado/realTimeDoradoValuationRecords'
import doradoPortfolio from '../features/dorado/doradoPortfolio'
import doradoValuationReturnAudit from '../features/dorado/doradoStockPriceAudit'
import etf from '../features/etfFeatureSlice'
import doradoCompanyDetail from '../features/dorado/doradoCompanyDetail'
import admin from '../features/adminFeatureSlice'
import events from '../features/eventsFeatureSlice'
import doradoPredictions from '../features/dorado/doradoPredictions'
import debt from '../features/debtFeatureSlice'
import polling from '../features/pollingProcessFeatureSlice'
import flex from '../features/dorado/flex'
import tradeApproval from '../features/dorado/tradeApproval'
import portfolio from '../features/portfolio/portfolioFeatureSlice'
import portfolioAnalysis from '../features/portfolio/portfolioAnalysisFeatureSlice'
import doradoAudit from '../features/dorado/doradoAudit'
import securityListingDividends from '../features/securityListingDividendsFeatureSlice'
import companySearch from '../features/companySearchFeatureSlice'
import summaryValuation from '../features/summaryValuationFeatureSlice'
import company from '../features/companyFeatureSlice'
import dataQuality from '../features/dataQualityFeatureSlice'
import indexConstituents from '../features/indexConstituentsFeatureSlice'


const reducers = {
    companyStore: CompanyReducer,
    employeeRecordStore: EmployeeRecordReducer,
    associationStore: AssociationRecordReducer,
    roleStore: RoleReducer,
    employeeAnalysisStore: EmployeeAnalysisReducer,
    jobPostingStore: JobPostingReducer,
    profileStore: ProfileReducer,
    companyValuationStore: CompanyValuationReducer,
    coverageSummaryStore: CoverageRecordsReducer,
    tagsStore: TagsReducer,
    valuationTableStore: ValuationTableReducer,
    portfolioStore: PortfolioReducer,
    valuationMetadataStore: ValuationMetadataReducer,
    targetPriceFeed: feed,
    earningsDateFeed,
    doradoValuation,
    doradoTargetPortfolio,
    doradoBacktestAudit,
    realTimeDoradoValuation,
    doradoPortfolio,
    doradoValuationReturnAudit,
    etf,
    doradoCompanyDetail,
    admin,
    events,
    doradoPredictions,
    debt,
    polling,
    flex,
    tradeApproval,
    portfolio,
    portfolioAnalysis,
    doradoAudit,
    securityListingDividends,
    companySearch,
    summaryValuation,
    company,
    dataQuality,
    indexConstituents,
    akkrStore: AkkrReducer,
    akkrCompaniesStore: AkkrCompaniesReducer,
    akkrTableStore: AkkrTableReducer,
    doradoStore: DoradoReducer,
    historicalValuationRecordsStore: HistoricalValuationRecordsReducer,
    akkrCensusStore: AkkrCensusReducer

};

const rootReducer = combineReducers(reducers);

export default rootReducer;
