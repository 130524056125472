import React, {useEffect} from 'react';
import {
    CompanyLinkFormatter,
    PercentOneDecimalNoNullFormatter,
    PercentTwoDecimalNoZeroFormatter,
    SharePriceFormatter,
    BoolToNoFlagFormatter,
    BoolToYesCheckFormatter,
    formatTwoDecimalPercentNoZero, NoDecimalNoNullFormatter
} from "../../../common/reactDataGrid/formatters";
import {CurrencySharePriceFormatter} from "../../../common/columns";
import {
    changeFromLastClosePriceCol, dailyDollarVolumeCol, dailyVolumeCol, exchangeIsOpenCol,
    lastTradeDateCol,
    median3moDollarVolumeCol,
    median3moVolumeCol
} from "../commonColumns";
import {companyOrEtfBacktestLinkColumn, NoMultipleExpansionIRRFormatter} from "../common";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";


export class BuySellPercentTwoDecimalNoZeroFormatter extends React.Component {
    render() {

        const color = this.props.value > 0 ? 'blue' : 'red';

        const style = {color: color};

        return <div style={style} className="align-right">{formatTwoDecimalPercentNoZero(this.props.value)}</div>
    }
}


export const targetPortfolioColumns = [
    CompanyLinkFromSecurityListing,
    {
        key: 'original_percent_of_equity',
        name: 'Current % of Equity',
        sortable: true,
        frozen: true,
        width: 70,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'incremental_percent_of_equity',
        name: 'Net Change % of Equity',
        sortable: true,
        frozen: true,
        width: 70,
        formatter: BuySellPercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'target_percent_of_equity',
        name: 'Target % of Equity',
        sortable: true,
        frozen: true,
        width: 70,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'irr',
        name: 'Weighted IRR',
        sortable: true,
        frozen: true,
        width: 70,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'original_irr',
        name: 'Original IRR',
        sortable: true,
        frozen: true,
        width: 70,
        formatter: NoMultipleExpansionIRRFormatter,
        _getValueFromRecord: r => {
            return {
                irr: r.no_multiple_expansion_irr,
                no_multiple_expansion_irr: r.original_irr
            }
        }
    },
    {
        key: 'no_multiple_expansion_irr',
        name: 'No Multiple Expansion IRR',
        sortable: true,
        frozen: true,
        width: 70,
        formatter: NoMultipleExpansionIRRFormatter,
        _getValueFromRecord: r => {
            return {
                irr: r.original_irr,
                no_multiple_expansion_irr: r.no_multiple_expansion_irr
            }
        }
    },
    {
        key: 'is_valid',
        name: 'Invalid Value?',
        sortable: true,
        width: 70,
        formatter: BoolToNoFlagFormatter
    },
    {
        key: 'is_locked',
        name: 'Locked Position?',
        formatter: <BoolToYesCheckFormatter color={'red'} iconName={'lock'}/>,
        width: 70,
        sortable: true,
    },
    {
        key: 'disable_short',
        name: 'Short Disabled?',
        formatter: <BoolToYesCheckFormatter color={'grey'} iconName={'circle'}/>,
        width: 70,
        sortable: true,
    },
    {
        key: 'max_long_percent_of_equity',
        name: 'Max Long % Equity',
        sortable: true,
        width: 70,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'd',
        name: '',
        width: 20
    },
    {
        key: 'price_per_share',
        name: 'Last Price $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 100,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.price_per_share,
                trading_currency: r.trading_currency
            }
        }
    },
    changeFromLastClosePriceCol,
    lastTradeDateCol,
    {
        key: 'j',
        name: '',
        width: 20
    },
    {
        key: 'original_return_score',
        name: 'Return Score',
        sortable: true,
        width: 70,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'assumed_implementation_shortfall_percent',
        name: 'Assumed Trade Cost %',
        sortable: true,
        width: 70,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'max_incremental_percent_of_equity',
        name: 'Max Trade % Equity',
        sortable: true,
        width: 70,
        formatter: PercentTwoDecimalNoZeroFormatter
    },

    {
        key: 'max_short_percent_of_equity',
        name: 'Max Short % Equity',
        sortable: true,
        width: 75,
        formatter: PercentTwoDecimalNoZeroFormatter
    },
    {
        key: 'e',
        name: '',
        width: 20
    },

    exchangeIsOpenCol,
    dailyVolumeCol,
    dailyDollarVolumeCol,
    median3moDollarVolumeCol,
    median3moVolumeCol,
    {
        key: 'market_value_usd',
        name: 'Trailing Market Value $USD',
        sortable: true,
        width: 70,
        formatter: NoDecimalNoNullFormatter,

        _getValueFromRecord: (r) => {
            return r.trailing_market_value_usd
        }

    }


]