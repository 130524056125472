import client from '../benchClient';

const companyNotesService = client.service('company_notes');
const targetPriceRecordsService = client.service('target_price_records');


export function fetchCompanyNote({dispatch, companyId}) {
    return dispatch({
        type: 'FETCH_COMPANY_NOTE',
        payload: companyNotesService.get(companyId)
    });

}


export function modifyCompanyNote({dispatch, fieldName, text}) {
    return dispatch({
        type: 'MODIFY_COMPANY_NOTE_FIELD',
        payload: {
            fieldName: fieldName,
            text: text
        }
    })
}

export function patchCompanyNote({dispatch, id, companyNote}) {

    return dispatch({
        type: 'PATCH_COMPANY_NOTE',
        payload: companyNotesService.patch(id, companyNote)
    });

}

export function fetchTargetPriceRecordsForCompany({dispatch, companyId, currencyType}) {

    dispatch({
        type: 'FETCH_TARGET_PRICE_RECORDS_FOR_COMPANY',
        payload: targetPriceRecordsService.find({
            query: {
                company_id: companyId,
                currency_type: currencyType,
                $sort: {
                    'last_modified': -1

                }
            }
        })
    });

}


export function editTargetPrice({dispatch, recordPatches}) {
    return dispatch({
        type: 'EDIT_TARGET_PRICE',
        payload: recordPatches
    });
}


export function createNewTargetPriceRecord({dispatch, record}) {
    return dispatch({
        type: 'NEW_TARGET_PRICE',
        payload: targetPriceRecordsService.create(record)
    });

}
