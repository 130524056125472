import React from 'react'
import { Form } from 'semantic-ui-react'


export default function RadioGroup({title, radioObjects, selectedValue, onChange, horizontal}){

  const radios = radioObjects.map(radioObject => {

    return (<Form.Radio
      label={radioObject.label}
      value={radioObject.value}
      checked={selectedValue === radioObject.value}
      key={radioObject.value}
      onChange={onChange}
    />)
  });

  if (horizontal) {
    return (
       <Form.Group inline>
          <label>{title}</label>
         {radios}

       </Form.Group>
    )
  }



  return (
    <Form.Group>
      <Form.Field>
        <label>{title}</label>
      </Form.Field>

      {radios}
    </Form.Group>
  )

}
