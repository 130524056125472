import React from 'react'
import {Header} from 'semantic-ui-react'


export default ({companyName, exchangeTicker, loading}) => {

    if (loading) {
        return <div/>
    }

    return (
        <Header as='h3'>
            {companyName}
            <Header.Subheader>
                {exchangeTicker}
            </Header.Subheader>
        </Header>
    );

}


