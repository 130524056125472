import React from "react";
import {
    getCompanyLink,
    NoDecimalNoNullOrZeroFormatter, ThreeDecimalNoNullFormatter,
    TwoDecimalNoNullFormatter,
    WeightFormatter
} from "../../../common/reactDataGrid/formatters";
import {divideValueBy1000000} from "../../../common/reactDataGrid/valuationColumns";
import {CurrencySharePriceFormatter} from "../common";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";


export const fieldSpecs = {
    portfolio_id: {
        parse: (v) => v == null ? null : parseInt(v)
    },
    portfolio_state_id: {
        parse: (v) => v == null || v === '' ? null : parseInt(v)
    },
}


export class HeaderFormatter extends React.Component {
    render() {
        if (this.props.value.isHeader) {
            return <div style={{'fontWeight': 'bold'}}>
                {this.props.value.name}
            </div>
        }
        return <div>
                {this.props.value.name}
            </div>


    }
}


export const columns = [
    CompanyLinkFromSecurityListing,
    {
        key: 'percent_of_net_asset_value',
        name: '% of NAV',
        formatter: WeightFormatter,
        width: 60,
    },
    {
        key: 'percent_of_securities_long_market_value',
        name: '% of Long Value',
        formatter: WeightFormatter,
        width: 60,
    },

    {
        key: 'market_value',
        name: 'Value $USD m',
        formatter: ThreeDecimalNoNullFormatter,
        width: 70,
        _getValueFromRecord: (r) => {
            return divideValueBy1000000(r.market_value)
        }
    },
    {
        key: 'price_per_share_trading',
        name: 'Price $/share',
        formatter: <CurrencySharePriceFormatter/>,
        width: 90,
        _getValueFromRecord: (r) => {
            return {
                price_per_share: r.price_per_share_trading,
                trading_currency: r.trading_currency
            }
        }
    },
    {
        key: 'net_shares',
        name: 'Shares',
        formatter: NoDecimalNoNullOrZeroFormatter,
        width: 90,
    },
    {
        key: 'security_listing_id',
        name: 'Security Listing ID',
        width: 90,
    },
    {
        key: 'id',
        name: 'ID',
        width: 90,
    },

]