import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Grid, Statistic, Header} from 'semantic-ui-react'
import ViewWithSidebar from '../../../components/ViewWithSidebar'
import {useReloadOnChange} from "../../../common/hooks";
import {fetchCompany} from "../../../actions/companyActions";
import {columns} from './debtCols'
import {cleanUpdated} from "../../../components/rdg/helpers";
import {fetchConvertibleDebtForCompany, patchConvertibleDebtRecord} from "../../../features/debtFeatureSlice";
import RdgTable from "../../../components/rdg/RdgTable";


export default () => {

    const dispatch = useDispatch();

    const {convertibleDebtRecords, loadingConvertibleDebtRecords} = useSelector(state => state.debt, shallowEqual);
    const {company, loadingCompany} = useSelector(state => state.companyStore, shallowEqual);

    useReloadOnChange({
        fieldSpecs: {},
        queryMatchFields: [],
        requestData
    });

    const sidebar = (
        <div>
            <Header>{loadingCompany ? null: company.company_name}</Header>
            <Grid centered columns={1} relaxed>
                <Grid.Row>
                    <Statistic horizontal value={convertibleDebtRecords.length} label='Records Count' size="tiny"/>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p>
                            Convertible debt records are autogenerated from CapIQ and commonly contain errors.
                            Before these events can be used, we must fix any errors and sign off that we have validated the record.
                        </p>
                        <p>
                            Note "Conversion Price" is the actual conversion price of the convertible debt.
                        </p>
                        <p>
                            "Modified Conversion Price" is the adjusted conversion price if the company has
                            executed a capped call transaction to effectively increase the conversion price.
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );

    const onGridRowsUpdated = ({fromRow, toRow, updated}) => {

        updated = cleanUpdated(updated);

        const debtRecord = convertibleDebtRecords[fromRow];

        dispatch(patchConvertibleDebtRecord({id: debtRecord.id, patchData: updated})).then(() => {
            dispatch(fetchConvertibleDebtForCompany({companyId: debtRecord.company_id}));
        });



    };

    return (
        <ViewWithSidebar mainView={<RdgTable columns={columns}
                                             records={convertibleDebtRecords}
                                             loading={loadingConvertibleDebtRecords}
                                             onGridRowsUpdated={onGridRowsUpdated}
        />} sidebarView={sidebar}/>
    )

};

const requestData = ({dispatch, searchProps, matchParams}) => {

    const {id} = matchParams;
    fetchCompany({dispatch, id});

    dispatch(fetchConvertibleDebtForCompany({companyId: id}));
};