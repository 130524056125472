import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Button, Container, Divider, Form, Grid, Header, Table} from 'semantic-ui-react'
import FinancialsComponent from './FinancialsComponent';
import {formatFullDate, validMoment} from "../../../common/format";
import PeriodTypeSelect from '../../../components/PeriodTypeSelect';
import ColorsPopup from '../../../components/ColorsPopup'
import {useReloadOnChange} from "../../../common/hooks";
import {fieldSpecs} from "./common";
import {fetchCompany, fetchHistoricalPeriodRecordsForCompany} from "../../../features/companyFeatureSlice";


export default () => {

    useReloadOnChange({
        fieldSpecs,
        queryMatchFields: ['currencyType', 'periodType'],
        matchFields: ['id'],
        requestData
    });

    useReloadOnChange({
        fieldSpecs,
        queryMatchFields: [],
        matchFields: ['id'],
        requestData: requestCompanyData
    });

    const {
        company,
        loadingCompany,
        historicalPeriodRecords,
        loadingHistoricalPeriodRecords,
        } = useSelector(state => state.company, shallowEqual);

    const loading = loadingCompany || loadingHistoricalPeriodRecords;

    const viewComponent = <FinancialsComponent financialRecords={historicalPeriodRecords}
                                               loading={loading}/>;

    const lastReportedPeriodText = loading ? null : company.last_reported_period  + ' (' + validMoment(company.last_reported_period_end_date, 'M/D/YYYY') + ')'

    return (
        <Container fluid>
            <Grid padded>

                <Grid.Column width={4}>
                    <Grid.Row>
                        <Grid>
                            <Grid.Column width={14}>
                                <Header as='h3'>
                                    {company.company_name}
                                    <Header.Subheader>
                                        {company.exchange_ticker}
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>

                            <Grid.Column width={2}>
                                <ColorsPopup/>
                            </Grid.Column>
                        </Grid>
                    </Grid.Row>

                    <br/>
                    <Grid.Row>
                        <Form>
                            <PeriodTypeSelect/>

                        </Form>
                        <Table basic='very' compact='very' collapsing>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Filing Currency:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {company.filing_currency}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Trading Currency:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {company.trading_currency}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Last Reported Period:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {lastReportedPeriodText}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        IPO Date:
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatFullDate(company.ipo_date)}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>


                        <br/>
                    </Grid.Row>

                </Grid.Column>

                <Grid.Column width={12}>
                    {viewComponent}


                </Grid.Column>

            </Grid>
        </Container>
    )
}



const requestCompanyData = ({dispatch, searchProps, matchParams}) => {
    const {id} = matchParams;
    dispatch(fetchCompany({companyId: id}));
};


const requestData = ({dispatch, searchProps, matchParams}) => {

    const {id} = matchParams;

    const periodType = searchProps.periodType;

    dispatch(fetchHistoricalPeriodRecordsForCompany({companyId: id, periodType}));

};
