import { createSlice } from '@reduxjs/toolkit'
import client from '../../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";
const tradeApprovalService = client.service('request_trade_approval');
const doradoSecurityOrderApprovalRequestService = client.service('dorado_security_order_approval_request');
const approveDoradoParentOrdersService = client.service('approve_dorado_parent_orders');
const approveDoradoStreetOrdersService = client.service('approve_dorado_street_orders');
const clearApprovalService = client.service('clear_dorado_security_order_approval_request');



const tradeApprovalFeatureSlice = createSlice({
    name: 'tradeApproval',
    initialState: {
        ...getInitialStateWithLoading({
            doradoSecurityOrderApprovalRequest: {
                dorado_security_awaiting_approval_orders: []
            },
            doradoOrderApproval: {},
            loadingDoradoOrderApproval: false,

        }),
        loadingApprovalRequestConfirmation: false,
        approvalRequestConfirmation: {},
    },
    reducers: {
        ...makeStandardReducers({
            prefix: 'requestApprovalForTradeOrders',
            destinationVariable: 'approvalRequestConfirmation'
        }),
        ...makeStandardReducers({
            prefix: 'fetchDoradoSecurityOrderApprovalRequest',
            destinationVariable: 'doradoSecurityOrderApprovalRequest'
        }),
        ...makeStandardReducers({
            prefix: 'approveDoradoParentSecurityOrders',
            destinationVariable: 'doradoOrderApproval'
        }),
        ...makeStandardReducers({
            prefix: 'approveDoradoStreetSecurityOrders',
            destinationVariable: 'doradoOrderApproval'
        }),
        ...makeStandardReducers({
            prefix: 'clearApprovalRequest',
            destinationVariable: 'doradoSecurityOrderApprovalRequest'
        }),
        patchDoradoSecurityOrderAwaitingApprovalPending: (state) => {
            state.loadingDoradoSecurityOrderApprovalRequest = true
        },
        patchDoradoSecurityOrderAwaitingApprovalFulfilled: (state, action) => {
            state.loadingDoradoSecurityOrderApprovalRequest = false;
            const result = action.payload.data;

            const originalOrders = state.doradoSecurityOrderApprovalRequest.dorado_security_awaiting_approval_orders;

            state.doradoSecurityOrderApprovalRequest.dorado_security_awaiting_approval_orders = originalOrders.map(r => {
                if (r.id === result.id) {
                    return result
                }
                return r
            })
        },

    }
});



export const requestApprovalForTradeOrders = ({orderIds, multiplier}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: tradeApprovalFeatureSlice,
        prefix: 'requestApprovalForTradeOrders',
        getPayload: () => {
            return tradeApprovalService.create({
                order_ids: orderIds,
                multiplier: multiplier
            })
        }
    })
};


export const fetchDoradoSecurityOrderApprovalRequest = () => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: tradeApprovalFeatureSlice,
        prefix: 'fetchDoradoSecurityOrderApprovalRequest',
        getPayload: () => {
            return doradoSecurityOrderApprovalRequestService.find()
        }
    })
};

export const patchDoradoSecurityOrderAwaitingApproval = (order) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: tradeApprovalFeatureSlice,
        prefix: 'patchDoradoSecurityOrderAwaitingApproval',
        getPayload: () => {return doradoSecurityOrderApprovalRequestService.patch(order.id, order)}
    })
};


export const approveDoradoParentSecurityOrders = ({uuid, email}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: tradeApprovalFeatureSlice,
        prefix: 'approveDoradoParentSecurityOrders',
        getPayload: () => {
            return approveDoradoParentOrdersService.find({
                query: {
                    uuid: uuid,
                    email: email
                }
            })
        }
    })
};

export const approveDoradoStreetSecurityOrders = ({uuid, email}) => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: tradeApprovalFeatureSlice,
        prefix: 'approveDoradoStreetSecurityOrders',
        getPayload: () => {
            return approveDoradoStreetOrdersService.find({
                query: {
                    uuid: uuid,
                    email: email
                }
            })
        }
    })
};




export const clearApprovalRequest = () => async (dispatch, getState) => {

    await executeWithPending({
        dispatch,
        featureSlice: tradeApprovalFeatureSlice,
        prefix: 'clearApprovalRequest',
        getPayload: () => {
            return clearApprovalService.find()
        }
    })
};








export default tradeApprovalFeatureSlice.reducer