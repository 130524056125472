import qs from "qs";

export const parseSearchProps = ({searchString, fieldSpecs}) => {
  // spec is of the format
  // fieldName:
  // parseValue -> func to parse value
  // defaultValue

  // goal is to extract

  fieldSpecs = fieldSpecs || {};
  const searchProps = qs.parse(searchString, {ignoreQueryPrefix: true, strictNullHandling: true});

  Object.keys(fieldSpecs).forEach(key => {
    const spec = fieldSpecs[key];
    const parseFunc = spec.parse ? spec.parse : parseString;
    const propValue = searchProps[key];
    searchProps[key] = parseFunc(propValue)

  });

  return searchProps
};
export const parseString = (value) => {
  return value
};
export const parseBoolean = (value) => {
  return (value === true || value === 'true' || value === 'True');
};
