import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import axios from 'axios';
import {auth0Client} from './index'


const baseUrl = process.env.REACT_APP_EMP_URL;

const hooks = {
    before: async (context) => {

        const headers = context.params.headers || {};

        const token = await auth0Client.getTokenSilently();

        headers['Authorization'] = 'Bearer ' + token;

        context.params.headers = headers;

        return context

    }
};



const app = feathers()
    .configure(rest(baseUrl).axios(axios, {
        headers: {
            'Content-Type': 'application/json'
        }
    })).hooks(hooks)
;


export default app;