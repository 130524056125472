import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Search, Form} from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import {serializeSearchPropsToQueryString, createAddValueToQuery} from '../common/query/add';
import {parseSearchProps} from "../common/query/parse";
import {useHistory, useLocation} from "react-router-dom";
import {searchForCompany} from "../features/companySearchFeatureSlice";


const fieldSpecs = {
    companySearchText: {
        parse: (value) => {
            if (value == null) {
                return ''
            }
            return value
        }
    }
};

export default () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const {searchResults, loadingSearchResults} = useSelector(state => state.companySearch, shallowEqual);

    const searchProps = parseSearchProps({searchString: location.search, fieldSpecs});

    let results = searchResults.map(result => {

        let title = null;
        if (result.highlight && result.highlight.company_name_and_ticker.length > 0) {

            // title is an html string
            title = result.highlight.company_name_and_ticker[0];

            title = title.split('<em>').join('<b>');
            title = title.split('</em>').join('</b>');
            title = ReactHtmlParser(title);

        } else {
            title = ReactHtmlParser(result._source.company_name_and_ticker);
        }

        title = title.map(t => {
            if (typeof t === "string") {
                return <span>{t}</span>
            }
            return t
        });

        // title must be one element to avoid bugs
        title = <span key={result._id}>{title}</span>;

        return {
            id: result._id,
            key: result._id,
            title: title,
            company_name: result._source.company_name
        }

    });

    const addSearchTextToQuery = createAddValueToQuery({history, searchProps, fieldName: 'companySearchText'});

    const handleSearchChange = (e, data) => {

        addSearchTextToQuery(e, data);

        dispatch(searchForCompany({text: data.value}));
    };

    const filters = searchProps.filters || {};
    const companyNameFilter = filters.company_name || {};
    const selectedValues = companyNameFilter.terms || [];

    const onResultSelect = (event, data) => {

        const company = data.result;

        // guarantee unique as search can allow duplicates
        const values = [...new Set(selectedValues.concat(company.company_name))];
        values.sort();

        searchProps.filters = filters;
        filters.company_name = {
            type: 'terms',
            terms: values
        };

        // clear search bar
        searchProps.companySearchText = '';

        serializeSearchPropsToQueryString({history, searchProps});

    };

    const selectedValuesOptions = selectedValues.map(name => {
        return {
            text: name,
            value: name,
            key: name,
        }
    });


    const onRemoveSelection = (event, data) => {

        const newSelectedValues = data.value;
        if (newSelectedValues.length === 0) {
            delete filters.company_name;
        } else {
            filters.company_name.terms = newSelectedValues;
        }

        serializeSearchPropsToQueryString({history, searchProps});

    };

    const resultRenderer = ({title}) => <span key={title}>{title}</span>;

    return (
        [
            <Form.Field key="a">
                <label>Company Search</label>
                <Search
                    fluid
                    loading={loadingSearchResults}
                    onSearchChange={handleSearchChange}
                    value={searchProps.companySearchText}
                    results={results}
                    onResultSelect={onResultSelect}
                    resultRenderer={resultRenderer}
                    minCharacters={0}
                />
            </Form.Field>,
            <Form.Dropdown key="b"
                           fluid
                           multiple
                           selection
                           search
                           label={'Selected Companies'}
                           options={selectedValuesOptions}
                           value={selectedValues}
                           onChange={onRemoveSelection}
                           icon={false}
            />


        ]
    )

}

