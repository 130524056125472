import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from "./store"
import * as serviceWorker from './serviceWorker';
import {Auth0Provider} from "./auth/react-auth0-wrapper";
import AppWrapper from "./AppWrapper";
import createAuth0Client from "@auth0/auth0-spa-js";


const config = {
    domain: 'app73111954.auth0.com',
    clientId: 'i2y-tyGP7reA2rqvaTnZC_E_iFOq3qL5',
    audience: 'https://akkr-bench.herokuapp.com/'
    // redirect_uri: window.location.origin
};

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

export let auth0Client = null;


const initAuth0Client = async () => {
    auth0Client = await createAuth0Client({
        domain: config.domain,
        client_id: config.clientId,
        redirect_uri: window.location.origin,
        onRedirectCallback: onRedirectCallback,
        audience: config.audience
    });
};



window.onload = async () => {
    await initAuth0Client();

    ReactDOM.render(
        <Auth0Provider
            auth0Client={auth0Client}
            domain={config.domain}
            client_id={config.clientId}
            redirect_uri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={config.audience}
        >
            <BrowserRouter>
                <Provider store={store}>
                    <AppWrapper/>
                </Provider>
            </BrowserRouter>
        </Auth0Provider>,
        document.getElementById('root')
    );
};


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
