import {formatNoDecimal} from "../../common/format";
import React from "react";
import {patchShareClassSecurity} from "../../actions/companyActions";


export const createRow = ({
                              headerText,
                              indent,
                              records,
                              getCellValue,
                              formatValue,
                              underline,
                              columnHeaderFieldName,
                              getCellClassName,
                              italic
                          }) => {
    // A row is defined as a text header followed by data cells


    if (!formatValue) {
        formatValue = formatNoDecimal;
    }

    if (!columnHeaderFieldName) {
        columnHeaderFieldName = 'period_end_date';
    }


    const row = {
        rowHeader: headerText
    };

    if (indent) {
        const indentLevel = indent === 2 ? 2 : 1;
        row['_indent'] = indentLevel;
    }

    if (underline) {
        row['_underline'] = true;
    }

    if (italic) {
      row['_italic'] = true;
    }

    for (let record of records) {
        row[record[columnHeaderFieldName]] = formatValue(getCellValue(record));
    }

    if (getCellClassName) {
        row['_cellClassNames'] = records.map(record => {
            return getCellClassName(record)
        });
    }


    return row

};

export class LeftAlignHeaderRenderer extends React.Component {
    render() {
        return (
            <div className="align-left">
                {this.props.column.name}
            </div>
        )
    }
}

export class HeaderContentRenderer extends React.Component {
    render() {

        let className = '';
        if (this.props.column._isForecast) {
            className = 'financials-forecast-header';
        }

        if (this.props.column._isPublicPeriod === false) {
            className = 'financials-pre-public-header';
        }

        return (
            <div className={className}>
                {this.props.column.name}
            </div>
        )
    }
}


export const cleanUpdated = (updated) => {

    // convert '' to null
    Object.keys(updated).map(key => {
        if (updated[key] === '') {
            updated[key] = null;
        }
        if (updated[key] === 'true') {
            updated[key] = true;
        }
        if (updated[key] === 'false') {
            updated[key] = false;
        }
        return key
    });

    return updated
}


