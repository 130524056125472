import { createSlice } from '@reduxjs/toolkit'

import client from '../../benchClient';
import {executeWithPending, getInitialStateWithLoading, makeStandardReducers} from "../common";
import {PortfolioType} from "../../common/constants";
import {fetchBacktestDoradoPortfolio} from "./doradoPortfolio";
const doradoTargetPortfolioStatesService = client.service('dorado_target_portfolio_states');
const doradoTargetPortfolioPositionsService = client.service('dorado_target_portfolio_positions');
const doradoTargetSecurityOrdersService = client.service('dorado_target_security_orders');

const doradoCurrentTargetDataService = client.service('current_dorado_target_data');
const doradoTargetDataService = client.service('dorado_target_data');


const doradoTargetPortfolioFeatureSlice = createSlice({
    name: 'doradoTargetPortfolio',
    initialState: {
        ...getInitialStateWithLoading({
            currentDoradoTargetData: {
                dorado_target_portfolio_state: {},
                dorado_target_portfolio_positions: [],
                dorado_target_security_orders: []
            },
            doradoTargetData: {
                dorado_target_portfolio_state: {},
                dorado_target_portfolio_positions: [],
                dorado_target_security_orders: [],
                security_trades: [],
                beginning_portfolio_positions: [],
                ending_portfolio_positions: [],
                beginning_portfolio_state: {}

            },
        }),

    },
    reducers: {
        ...makeStandardReducers({prefix: 'fetchCurrentDoradoTargetData', destinationVariable: 'currentDoradoTargetData'}),
        ...makeStandardReducers({prefix: 'fetchDoradoTargetData', destinationVariable: 'doradoTargetData'}),


        updateDoradoTargetSecurityOrder: (state, action) => {
            // update records without making a new request

            const record = action.payload;
            state.currentDoradoTargetData.dorado_target_security_orders = state.currentDoradoTargetData.dorado_target_security_orders.map(o => {
                if (o.id !== record.id) {
                    return o
                }
                return record
            })

        },

        setIncludeTradeOrderFalse: (state, action) => {



            state.currentDoradoTargetData.dorado_target_security_orders = state.currentDoradoTargetData.dorado_target_security_orders.map(o => {
                return {
                    ...o,
                    _include_trade_order: false
                }
            })
        }


    }
});

export const {
    updateDoradoTargetSecurityOrder,
    setIncludeTradeOrderFalse
} = doradoTargetPortfolioFeatureSlice.actions;


export const fetchCurrentDoradoTargetData = ({sort, filters}) => async (dispatch, getState) => {
    // convenience to grab everything at once and without knowing the exact portfolio id

    await executeWithPending({
        dispatch,
        featureSlice: doradoTargetPortfolioFeatureSlice,
        prefix: 'fetchCurrentDoradoTargetData',
        getPayload: () => {return doradoCurrentTargetDataService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}
    })
};


export const fetchDoradoTargetData = ({portfolioStateId, sort, filters}) => async (dispatch, getState) => {
    // convenience to grab everything at once and without knowing the exact portfolio id

    await executeWithPending({
        dispatch,
        featureSlice: doradoTargetPortfolioFeatureSlice,
        prefix: 'fetchDoradoTargetData',
        getPayload: () => {return doradoTargetDataService.get(portfolioStateId, {
            query: {
                $sort: sort,
                ...filters
            }
        })}
    })
};





export const fetchDoradoTargetPortfolioPositions = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoTargetPortfolioFeatureSlice,
        prefix: 'fetchDoradoTargetPortfolioPositions',
        getPayload: () => {return doradoTargetPortfolioPositionsService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}
    })
};


export const fetchDoradoTargetSecurityOrders = ({sort, filters}) => async (dispatch, getState) => {
    await executeWithPending({
        dispatch,
        featureSlice: doradoTargetPortfolioFeatureSlice,
        prefix: 'fetchDoradoTargetSecurityOrders',
        getPayload: () => {return doradoTargetSecurityOrdersService.find({
            query: {
                $sort: sort,
                ...filters
            }
        })}
    })
};





export default doradoTargetPortfolioFeatureSlice.reducer