import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {Form, Header, Progress} from 'semantic-ui-react'
import {parseSearchProps} from "../../../common/query/parse";
import {
  createAddCheckedValueToQuery,
  createAddTextFilterToQuery,
  createAddTermFilterToQuery

} from '../../../common/query/add';
import {
  getTextFilterFromSearchPropsForField,
  getFilteredTermFromSearchPropsForField
} from '../../../common/filter/add'
import {fieldSpecs} from './common'



const associationRadioSpecs = [
  {
    label: 'All'
  },
  {
    label: 'Associated'
  },
  {
    label: 'Not Associated'
  }
];



class EmployeeRecordSidebar extends Component {

  render() {

    const history = this.props.history;
    const searchProps = parseSearchProps({searchString: this.props.location.search, fieldSpecs});

    // const totalCount = this.props.records.length;
    //
    // const associatedCount = this.props.records.filter(r => r.role != null).length;

    const radios = associationRadioSpecs.map(spec => {

      return (<Form.Radio
        label={spec.label}
        name="status"
        value={spec.label}
        checked={getFilteredTermFromSearchPropsForField({searchProps, fieldName: 'status'}) === spec.label}
        key={spec.label}
        onChange={createAddTermFilterToQuery({history, searchProps, fieldName: 'status'})}
      />)
    });

    const roleOptions = [
        {
          key: -1,
          value: null,
          text: ''
        },
      ...this.props.roles.map(role => {return {key: role.id.toString(), value: role.id.toString(), text: role.name}})
    ];

    return (
      <div>
        <br/>
        <Progress value={this.props.associationCounts.associated} total={this.props.associationCounts.total} color='green' progress='ratio' label='Associated Employees'/>
        <Form>

          <Form.Checkbox label="Show Location"
                         checked={searchProps.showLocation}
                         onChange={createAddCheckedValueToQuery({
                           history,
                           searchProps,
                           fieldName: 'showLocation'
                         })}
          />
          <Form.Checkbox label="Show Date Associated"
                         checked={searchProps.showDateAssociated}
                         onChange={createAddCheckedValueToQuery({
                           history,
                           searchProps,
                           fieldName: 'showDateAssociated'
                         })}
          />
          <Header>Filters</Header>
          <Form.Input label="Description"
                      onChange={createAddTextFilterToQuery({
                        history,
                        searchProps,
                        fieldName: 'description'
                      })}
                      value={getTextFilterFromSearchPropsForField({
                        searchProps, fieldName: 'description'
                      })}
          />
          <Form.Group>
            <Form.Field>
              <label>Status</label>
            </Form.Field>
            {radios}
          </Form.Group>
          <Form.Dropdown label="Role"
                         selection
                         closeOnChange={true}
                         options={roleOptions}
                         value={getFilteredTermFromSearchPropsForField({searchProps, fieldName: 'role_id'})}
                         onChange={createAddTermFilterToQuery({history, searchProps, fieldName: 'role_id'})}
          />

        </Form>
      </div>
    )


  }
}



function mapStateToProps(state) {
  return {
    company: state.companyStore.company,
    loadingCompany: state.companyStore.loadingCompany,

    records: state.employeeRecordStore.employeeRecords,
    associationCounts: state.associationStore.associationCounts,

    roles: state.roleStore.roles,
    loadingRoles: state.roleStore.loadingRoles,
  }
}


export default withRouter(connect(mapStateToProps,
  {

  })(EmployeeRecordSidebar));
