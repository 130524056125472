import React from 'react'
import {getWindowHeightLessMenuBar} from '../../../common/common'
import moment from "moment";
import {formatSharePrice, formatFullDate} from "../../../common/format";
import {series1ChartSpecificationByName} from "./common";

const ReactHighcharts = require('react-highcharts');


class ChartComponent extends React.Component {

    componentDidMount() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    componentDidUpdate() {
        const chartHeight = getWindowHeightLessMenuBar();
        let chart = this.refs.chart.getChart();
        chart.setSize(undefined, chartHeight);
    }

    render() {

        const portfolioStates = this.props.portfolioStates;
        const loading = this.props.loading;

        const series1ChartSpec = series1ChartSpecificationByName[this.props.series1ChartName];

        const showSpyIndexedReturnRecords = this.props.showSpyIndexedReturnRecords;
        const spyIndexedReturnRecords = this.props.spyIndexedReturnRecords;

        const igvIndexedReturnRecords = this.props.igvIndexedReturnRecords;
        const showIgvIndexedReturnRecords = this.props.showIgvIndexedReturnRecords;

        const series = series1ChartSpec.chartSpecs.map(spec => {
           return {
               name: spec.name,
               data: portfolioStates.map(r => {
                   return {
                       x: moment(r.standard_date).valueOf(),
                       y: spec.getY(r)
                   }
               }),
               color: spec.color,
               dashStyle: spec.dashStyle
           }
        });

        const firstChartSpec = series1ChartSpec.chartSpecs[0];

        // const series = [
        //     {
        //         name: series1ChartSpec.name,
        //         data: portfolioStates.map(r => {
        //             return {
        //                 x: moment(r.standard_date).valueOf(),
        //                 y: series1ChartSpec.getY(r)
        //             }
        //         })
        //     }
        // ];

        const config = {
            chart: {
                type: firstChartSpec.type == null ? 'line' : series1ChartSpec.type,
                zoomType: 'xy'
            },
            title: {
                text: series1ChartSpec.name
            },
            xAxis: {
                title: {
                    text: 'Date'
                },
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return formatFullDate(this.value, false);
                    }
                }
            },
            yAxis: {
                min: series1ChartSpec.yMin,
                max: series1ChartSpec.yMax,
                title: {
                    text: series1ChartSpec.getYAxisLabel()
                },
                labels: {
                    formatter: function () {
                        return firstChartSpec.formatY(this.value);
                    }

                }
            },
            tooltip: {
                useHTML: true,
                shared: false,
                headerFormat: '',
                pointFormatter: function () {
                    return '<table>' +
                        '<tr><td> Date </td><td>' + formatFullDate(this.x) + '</td></tr>' +
                        '<tr><td>' + series1ChartSpec.getYAxisLabel() + '</td><td>' + firstChartSpec.formatY(this.y) + '</td></tr>' +
                        '</table>'
                },
            },
            plotOptions: {
                series: {
                    animation: false,
                },
                line: {
                    marker: {
                        enabled: false
                    },
                }
            },

            series: series,

        };

        return (
            <div ref={element => this.divRef = element}>
                <ReactHighcharts config={config} ref="chart"></ReactHighcharts>
            </div>
        )

    }
}


export default ChartComponent