import React from 'react';
import {
    DateFormatter, NoDecimalNoNullOrZeroFormatter, PercentOneDecimalNoNullFormatter, PercentTwoDecimalNoNullFormatter,
    PositiveNegativeTwoDecimalFormatter,
    SharePriceFormatter, SharePriceFourDecimalFormatter,
    UtcDateFormatter
} from "../../../common/reactDataGrid/formatters";
import {CompanyLinkFromSecurityListing} from "../../../common/reactDataGrid/columns";


export const columns = [
    CompanyLinkFromSecurityListing,
    {
        key: 'as_of_date',
        name: 'As of Date',
        formatter: DateFormatter,
        width: 90,
    },
    {
        key: 'loan_rate',
        name: 'Borrow Rate',
        formatter: PercentTwoDecimalNoNullFormatter,
        width: 90,
    },



];